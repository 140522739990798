<template>
  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
    <path class="cls-1" d="M32,3.08v21.56c0,1.83-1.48,3.32-3.3,3.32h-9.75c-.58,1.03-1.68,1.72-2.95,1.72s-2.37-.69-2.95-1.72H3.3c-1.82,0-3.3-1.48-3.3-3.32V3.08c0-.42.34-.76.76-.76h10.34c1.82,0,3.3,1.48,3.3,3.3v19.2h3.19V5.62c0-1.82,1.48-3.3,3.3-3.3h10.34c.42,0,.76.34.76.76Z"/>
  </svg>
</template>

<script lang="ts">
import {Component, Vue} from "vue-property-decorator";
import MainMenu from "@/_view/menu/MainMenu.vue";
import MainLogo from "@/_view/components/MainLogo.vue";
import MainStatus from "@/_view/components/MainStatus.vue";
import NetworkManager from "@/_controller/NetworkManager";

@Component({
  components: {}
})
export default class LibraryIcon extends Vue {
}
</script>
