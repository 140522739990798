import {io, Socket} from 'socket.io-client';

import {Operation} from "fast-json-patch";
import {ICobrowseUserDto} from "./cobrowse_user/_model/cobrowse_user.dto";
import Dictionary from "../_model/Dictionary";
import Room from "./room/Room";
import SharedObject from "./shared_object/SharedObject";
import {ICobrowseConnectionDto} from "./_model/cobrowse.dto";
import {IRoomDto} from "./room/_model/room.dto";
import {ISharedObjectDataDto, ISharedObjectDto} from "./shared_object/shared_object.dto";
import {CobrowseRoomType, RoomEvent, RoomStatusType} from "./room/_model/room.constants";
import Peer from 'peerjs';


class CobrowseManager
{



    // ---------------------------------
    // Public Static Properties
    // ---------------------------------



    //---------------------------------
    // Public Properties
    //---------------------------------

    public isConnected:boolean = false;

    public cobrowseUser!:ICobrowseUserDto;

    private _roomsDict:Dictionary<Room, string> = new Dictionary<Room, string>();
    public rooms:string[] = [];

    public activeRoom?:Room;
    public projectRoom?:Room;

    /*
    public myPeer?:Peer;
    public myStream?:MediaStream | void;
    */


    //---------------------------------
    // Private Properties
    //---------------------------------

    private _socket!:Socket;
    get socket():Socket
    {
        return this._socket;
    }

    private testSO?:SharedObject<any>;

    //---------------------------------
    // getter setters
    //---------------------------------



    //---------------------------------
    // Private Methods
    //---------------------------------



    private static _instance:CobrowseManager;

    constructor()
    {
        if (!CobrowseManager._instance)
        {
            CobrowseManager._instance = this;
        }
    }

    public static getInstance():CobrowseManager
    {
        if (!CobrowseManager._instance)
        {
            new CobrowseManager();
        }

        return CobrowseManager._instance;
    }



    //---------------------------------
    // Public Methods
    //---------------------------------

    public init(p_socketUri:string, p_cobrowseConnection:ICobrowseConnectionDto)
    {

        this.cobrowseUser = p_cobrowseConnection.cobrowseUser;


        //make connection
        this._socket = io(p_socketUri, {auth: p_cobrowseConnection});

        // ======  define handlers

        //connection
        this._socket.on("connect", () => this._onConnect());
        this._socket.on("disconnect", () => this._onDisConnect());

        this._socket.on("connect_error", (err) => {
            console.log("connect_error", err.message);
        });

        //room handlers
        this._socket.on("sSetsRoom", (p_room:IRoomDto) => this._sSetsRoom(p_room));
        this._socket.on("sLeavesRoom", (p_roomIdentifier:string) => this._sLeavesRoom(p_roomIdentifier));
        this._socket.on("sEmitsRoomEvent", (p_roomIdentifier:string, p_event:RoomEvent, p_data:any) => this._sEmitsRoomEvent(p_roomIdentifier, p_event, p_data));

        //SO handlers
        this._socket.on("sSoChange", (p_roomIdentifier:string, p_soIdentifier:string, p_patch:Operation[]) => {

            // console.log("sSoChange", p_roomIdentifier, p_soIdentifier, p_patch);

            const room:Room = this._roomsDict.getItem(p_roomIdentifier);
            if (room)
            {
                // console.log(p_roomIdentifier, room);
                const so:SharedObject<ISharedObjectDataDto> | undefined = room.getSharedObject(p_soIdentifier);
                // console.log(p_soIdentifier, so);
                if (so)
                {
                    so.patch(p_patch);
                }
            }
        });

        this._socket.on("sSetsSO", (p_roomIdentifier:string, p_soDto:ISharedObjectDto) => {

            console.log("sSetsSO", p_roomIdentifier, p_soDto);

            const room:Room = this._roomsDict.getItem(p_roomIdentifier);
            if (room)
            {
                room.setSharedObject(p_soDto);
            }
        });


    }

    private _onConnect()
    {
        // console.log("_onConnect", this._socket);
        this.isConnected = true;

        /*
        //init Peerjs
        this.myPeer = new Peer(this.cobrowseUser.cobrowseUserID);
        this.myPeer.on('open', (id:string) => { // When we first open the app, have us join a room
            console.log('myPeer open', id);
        });
        */

    }

    private _onDisConnect()
    {
        this.isConnected = false;
        //todo clear all SOs in all rooms, or clear everything? >> better

        // console.log("_onDisConnect", this._socket.id);
    }

    private _sSetsRoom(p_room:IRoomDto)
    {
        console.log("sSetsRoom", p_room);

        let room:Room;
        if (!this._roomsDict.keyExists(p_room.identifier))
        {
            room = new Room(p_room);
            this._roomsDict.setItem(room, p_room.identifier)
        }
        else
        {
            room = this._roomsDict.getItem(p_room.identifier);
        }
        room.roomStatus = RoomStatusType.JOINED;
        if (p_room.roomType === CobrowseRoomType.PROJECT)
        {
            this.projectRoom = room;
        }
        else
        {
            this.activeRoom = room;
        }
        this.rooms.push(p_room.identifier);
    }

    private _sLeavesRoom(p_roomIdentifier:string)
    {
        const room = this._roomsDict.getItem(p_roomIdentifier);
        if (room)
        {
            room.cleanup();
            this._roomsDict.deleteItem(p_roomIdentifier);
            if (room === this.activeRoom)
            {
                this.activeRoom = undefined;
            }
            else if (room === this.projectRoom)
            {
                this.projectRoom = undefined;
            }
            this.rooms.splice(this.rooms.indexOf(p_roomIdentifier), 1);
        }
    }

    private _sEmitsRoomEvent(p_roomIdentifier:string, p_event:RoomEvent, p_data:any)
    {
        const room = this._roomsDict.getItem(p_roomIdentifier);
        if (room)
        {
            room.roomEvent.dispatch(p_event, p_data); // dispatch signal passing custom parameters
        }
    }


    public askRoom(p_room:IRoomDto, p_roomUser:ICobrowseUserDto)
    {
        this._socket.emit("cAsksRoom", p_room, p_roomUser);
    }

    public leaveRoom(p_roomIdentifier:string)
    {
        this._socket.emit("cLeavesRoom", p_roomIdentifier);
    }

    public isInRoom(p_roomIdentifier:string):boolean
    {
        return this._roomsDict.keyExists(p_roomIdentifier);
    }

    public getRoom(p_roomIdentifier:string):Room
    {
        return this._roomsDict.getItem(p_roomIdentifier);
    }

/*
    public async getStream(): Promise<MediaStream>
    {
        this.myStream = await navigator.mediaDevices.getUserMedia({video: true, audio: true});
        return this.myStream;
    }
*/
}

export default CobrowseManager;


