<template>
  <div class="import-files">
    <transition name="fade">
      <div v-if="currentFormState === formState.SEARCH_INPUT">
        <h5 class="mb-3">{{ $t('ImportFrom') }}</h5>
        <div class="import-files__crms mb-4">
          <div class="import-files__crms__crm" v-for="dmsProvider in availableDmsProviders" :key="dmsProvider.provider"
               :class="{'active': isActive(dmsProvider)}"
               @click="_selectProvider(dmsProvider)">
            <img class="dms__image" width="60px" :src="'img/dms/' + dmsProvider.provider + '.png'"/>
          </div>
        </div>

        <div class="import-audience__filters mb-4">
          <h5 class="mb-3">{{ $t('AudienceImportFilter') }}</h5>
          <div class="sub-box mb-3">
            <b-form-group>
              <b-form-radio v-model="latestSearchByType" name="search-type" :value="byType.RECENT">
                {{ $t('ListRecentFiles') }}
              </b-form-radio>
              <b-form-radio v-model="latestSearchByType" name="search-type" :value="byType.NAME">
                {{ $t('SearchByName') }}
              </b-form-radio>
            </b-form-group>
            <div v-if="latestSearchByType === byType.NAME">
              <small>{{ $t('SearchByName') }}</small>
              <div class="import-files__filters__filter mb-2">
                <input type="text" v-model="searchInput" class="form-control flex-grow-1">
                <b-button variant="primary" :disabled="searchInput.length < 2" @click="_doSearch(byType.NAME)">
                  {{ $t('AudienceImportFilterName') }}
                </b-button>
              </div>
            </div>
          </div>
          <!--          <b-button @click="_resetFilter"><i class="fa-solid fa-arrow-rotate-right mr-2 fa-lg"></i>{{ $t('AudienceImportFilterReset') }}</b-button>-->
        </div>

        <hr>

        <div class="import-files__result mb-3">
          <h5 class="mb-3">{{ $t('FilesImportResult') }}</h5>
          <div v-if="isSearching" class="empty-loader">
            <b-spinner variant="dark" class="my-5"></b-spinner>
          </div>
          <div v-else>
            <div v-if="files.length === 0" class="empty-message">
              {{ $t('NoFilesFound') }}
            </div>
            <div v-else>
              <b-form-checkbox @input="_onSelectAllClick" v-model="allSelected" class="mb-3">
                <strong>{{ $t('AudienceImportSelectAll') }}</strong></b-form-checkbox>
              <div class="import-files__result__files">
                <b-form-checkbox v-for="file in files" :key="file.remoteIdentifier"
                                 :value="file.remoteIdentifier" @input="_onFileClick"
                                 v-model="selectedFiles" class="mr-3 mb-1">
                  {{ file.fileName }}<strong v-if="file.createdByName"> ({{ file.createdByName }})</strong><small
                    class="existing" v-if="file.isExisting">({{ $t('ContactExists') }})</small>
                </b-form-checkbox>
              </div>
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-end">
          <button class="btn btn-secondary mr-2" @click="_onCancelBtnClick">{{ $t('Cancel') }}</button>
          <button v-if="files.length > 0" class="btn btn-primary" :disabled="selectedFiles.length === 0"
                  @click="_onImportFilesBtnClick">
            {{ $t('ImportSelectedFiles') }}
          </button>
        </div>
      </div>
    </transition>

    <transition name="fade">
      <div v-if="currentFormState === formState.IMPORTING_FILES">
        <div class="text-center mt-5 mb-3">
          <b-spinner variant="dark"></b-spinner>
        </div>
        <div class="text-center mb-5 px-3">{{ $t('ImportingFiles') }}</div>
        <div class="d-flex justify-content-end">
          <button class="btn btn-primary" @click="_onCancelBtnClick">
            {{ $t('Ok') }}
          </button>
        </div>
      </div>
    </transition>


    <transition name="fade">
      <div v-if="currentFormState === formState.FILES_IMPORTED">
        <div class="success-message">{{ $t('FilesImported') }}</div>
        <div class="d-flex justify-content-end">
          <button class="btn btn-primary" @click="_onCancelBtnClick">{{ $t('Ok') }}</button>
        </div>
      </div>
    </transition>

    <transition name="fade">
      <div v-if="currentFormState === formState.IMPORT_ERROR" class="error-message">{{
          $t('AudienceImportFailed')
        }}
      </div>
    </transition>

  </div>

</template>

<script lang="ts">
import {Component, Vue, Watch} from 'vue-property-decorator';
import {DmsProvider} from "@/dms/_model/dms.constants";
import DmsModel from "@/dms/_model/DmsModel";
import {IDmsFileDto, IDmsProviderDto, IDmsUserDto} from "@/dms/_model/dms.dto";
import AppUserModel from "@/project/user/_model/AppUserModel";
import DatePicker from "vue2-datepicker";
import dmsController from "@/dms/_controller/DmsController";
import {IDmsProviderClientDto} from "@/dms/_model/dms.dto";
import AudienceModel from "@/audience/_model/AudienceModel";
import audienceController from "@/audience/_controller/AudienceController";

enum FormState {
    SEARCH_INPUT,
    IMPORTING_FILES,
    FILES_IMPORTED,
    IMPORT_ERROR
}

enum SearchByType {
    RECENT,
    NAME,
}

@Component({
    components: {DatePicker}
})
export default class ImportContentFilesBox extends Vue {

    public formState: typeof FormState = FormState;
    public byType: typeof SearchByType = SearchByType;
    public currentFormState: FormState = FormState.SEARCH_INPUT;
    public currentDmsProvider?: IDmsProviderClientDto;
    public fromDate?: Date = new Date();
    public searchInput: string = "";
    public isSearching: boolean = false;
    public latestSearchByType: SearchByType = SearchByType.RECENT;
    public files: IDmsFileDto[] = [];
    public selectedFiles: string[] = [];
    public allSelected: boolean = true;
    public alsoImportFileAccounts: boolean = true;

    get availableDmsProviders() {
        if (AppUserModel.getInstance().project.config.dmsProviders) {
            return AppUserModel.getInstance().project.config.dmsProviders
        }
        return []
    }

    mounted() {
        this._selectProvider(this.availableDmsProviders![0]);
    }

    public isActive(p_dmsProvider: IDmsProviderClientDto): boolean {
        return this.currentDmsProvider === p_dmsProvider;
    }

    public _selectProvider(p_dmsProvider: IDmsProviderClientDto) {
        this.currentDmsProvider = p_dmsProvider;
        this.selectedFiles = [];
        this.files = [];
        this._doSearch(this.latestSearchByType);
    }

    public async _doSearch(p_byType: SearchByType) {
        // this.latestSearchByType = p_byType;
        this.isSearching = true;
        this.files = [];
        if (p_byType === SearchByType.RECENT) {
            this.searchInput = "";
            this.files = await dmsController.searchFiles(this.currentDmsProvider!);
        } else {
            // this.fromDate = undefined;
            this.files = await dmsController.searchFiles(this.currentDmsProvider!, this.searchInput);
        }
        this.setSelection(true);
        this.isSearching = false;
    }

    // public async _resetFilter(): Promise<void> {
    //     this.fromDate = undefined;
    //     this.searchInput = "";
    //     await this._doSearch(SearchByType.NAME);
    // }

    public async _onImportFilesBtnClick(p_e: Event) {
        this.currentFormState = FormState.IMPORTING_FILES;
        const hasSucceeded: boolean = await dmsController.doBackendImport(this.currentDmsProvider!, this.selectedFiles, this.alsoImportFileAccounts);
        if (hasSucceeded) {
            this.currentFormState = FormState.FILES_IMPORTED;
        } else {
            this.currentFormState = FormState.IMPORT_ERROR;
        }
    }

    public async _onCancelBtnClick(p_e: Event) {
        this.$emit('onFinish', null);
    }

    public setSelection(p_selectAll: boolean = true) {
        const selectedFiles: string[] = [];
        if (p_selectAll) {
            for (let i = 0; i < this.files.length; i++) {
                selectedFiles.push(this.files[i].remoteIdentifier!)
            }
        }
        this.selectedFiles = selectedFiles;
        this.allSelected = p_selectAll;
    }


    public async _onSelectAllClick(p_e: Event) {
        this.setSelection(this.allSelected);
    }

    public async _onFileClick(p_e: Event) {
        if (this.selectedFiles.length === this.files.length) {
            this.allSelected = true;
        } else if (this.selectedFiles.length === 0) {
            this.allSelected = false;
        }
    }

    @Watch('latestSearchByType', {immediate: false, deep: false})
    private _onLatestSearchByTypeChagned() {
        if (this.latestSearchByType === this.byType.RECENT) {
            this._doSearch(this.byType.RECENT);
        }
    }

}
</script>
