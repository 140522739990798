import {ENTITY_CONFIG} from "@/entity/_model/entity.constants";
import EntityListController from "@/entity/_controller/EntityListController";
import AudienceListModel from "@/audience/_model/AudienceListModel";
import AudienceModel from "@/audience/_model/AudienceModel";
import audienceController from "@/audience/_controller/AudienceController";
import {IEntityMetaDto} from "@/entity/_model/entity.dto";
import {FormElement} from '@/data_tool/_model/data_tool.constants';
import {IDataFilter} from '@/data_tool/data_filter/_model/data-filter.dto';
import DataFilterModel from '@/data_tool/data_filter/_model/DataFilterModel';

class AudienceListController extends EntityListController<AudienceModel> {

    constructor() {
        super(ENTITY_CONFIG.AUDIENCE, AudienceListModel.getInstance(), audienceController)
    }

    public async fetchAllEntities() {
        await super.fetchAllEntities();
        AudienceListModel.getInstance().fullListBodyLoaded = true;
    }

    protected __newEntity(p_dto: IEntityMetaDto): AudienceModel {
        return new AudienceModel(p_dto);
    }

    public filterByCustomProps(p_audiences: AudienceModel[], p_appliedFilters: IDataFilter[], filterModel: DataFilterModel): AudienceModel[] {
        return p_audiences.filter(audience => {
            if (!audience.data || !audience.data[filterModel.schemaItemDefinitionId]) {
                return false;
            }

            const audiencePropertiesData = audience.data[filterModel.schemaItemDefinitionId];

            return p_appliedFilters.every(filter => {
                const audienceFilterValue = audiencePropertiesData[filter.propertyDefinition.identifier];
                if (!audienceFilterValue) {
                    return false;
                }

                switch (filter.propertyDefinition.formElement) {
                    case FormElement.TEXT_INPUT:
                        return audienceFilterValue.toLowerCase() === filter.value.toLowerCase();
                    default:
                        if (Array.isArray(filter.value)) {
                            return filter.value.includes(audienceFilterValue);
                        } else {
                            return audienceFilterValue === filter.value;
                        }
                }
            });
        });
    }

}

//Singleton export
export default new AudienceListController();
