<template>
  <div class="root-page player-page">

    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>

  </div>
</template>


<script lang="ts">
import {Component, Vue} from "vue-property-decorator";

@Component({
    components: {}
})
export default class PlayerPage extends Vue {

}
</script>
