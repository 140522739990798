import i18n from '@/__plugins/i18n';
import apiManager, {ApiRequestType, ApiResponse} from "@/_controller/ApiManager";
import {ICreateResultDto, IUpdateResultDto} from "@/entity/_model/entity.dto";
import toastManager, {ToastType} from "@/__libs/toast_manager/ToastManager";
import {LoadingStatus} from "@/entity/_model/entity.constants";
import {IRightOutlineDto, IRoleOutlineDto} from "@/team/_model/team.dto";
import RoleListModel from "@/team/_model/RoleListModel";

import {DtoType} from "@/_model/app.constants";
import RoleModel from "@/team/_model/RoleModel";

class RoleController
{

    //---------------------------------
    // Properties
    //---------------------------------

    protected _listModel:RoleListModel = RoleListModel.getInstance();


    //---------------------------------
    // Controller Methods
    //---------------------------------

    public async fetchAllRoles()
    {
        //first check if you are not fetching already and if enough time has passed between fetches
        if (!this._listModel.isFetchListAllowed)
        {
            return;
        }

        this._listModel.startListFetch();

        const response:ApiResponse<IRoleOutlineDto[]> = await apiManager.sendApiRequest(ApiRequestType.GET, `/client-api/roles/`);

        if (response.hasSucceeded)
        {
            this._listModel.empty();
            const roleOutlines:IRoleOutlineDto[] = response.result as IRoleOutlineDto[];
            for (let i = 0; i < roleOutlines.length; i++)
            {
                const roleModel:RoleModel = new RoleModel();
                roleModel.mapFromDto(roleOutlines[i]);
                this._listModel.list.push(roleModel);
            }
        }

        this._listModel.stopListFetch();
    }

    public async fetchAllRights()
    {

        const response:ApiResponse<IRightOutlineDto[]> = await apiManager.sendApiRequest(ApiRequestType.GET, `/client-api/roles/rights`);

        if (response.hasSucceeded)
        {
            this._listModel.rights = response.result as IRightOutlineDto[];
        }
    }

    public async saveRole(p_role:RoleModel):Promise<ApiResponse<IUpdateResultDto>>
    {

        const response:ApiResponse<IUpdateResultDto> = await apiManager.sendApiRequest(ApiRequestType.PUT, `/client-api/roles/${p_role.roleID}`, p_role.rights);

        if (response.hasSucceeded)
        {
            toastManager.showToast(i18n.t('RoleSaveSuccess') as string, ToastType.SUCCESS);
            p_role.hasChanges = false;
        }
        else
        {
            toastManager.showToast(i18n.t('RoleSaveFailed') as string, ToastType.DANGER);
        }
        return response;
    }

    //---------------------------------
    // Private Methods
    //---------------------------------

}

//Singleton export
export default new RoleController();
