import {
    IGamificationStepDto,
    IGamificationTargetConfigDto,
    IGamificationTargetValueDto
} from "@/gamification/_model/gamification.dto";
import {IMultiLangString} from "@/__libs/language_manager/LanguageManager";
import AppUserModel from "@/project/user/_model/AppUserModel";

class GamificationUtil {

    // STATUS

    public static getStatusIndex(coins: number, status: IGamificationStepDto[]): number {
        for (let i = 0; i < status.length; i++) {
            if (coins < status[i].coinValue) {
                return i - 1;
            }
        }
        return status.length - 1;
    }

    public static getStatusIcon(index: number): string {
        return `img/gamification/status/gamification_status_${index}.png`;
    }

    public static getStatusName(index: number, status: IGamificationStepDto[]): IMultiLangString {
        return status[index].name;
    }

    public static getStatusAmount(index: number, status: IGamificationStepDto[]): number {
        return status[index].coinValue;
    }

    public static getStatusPercentage(index: number, status: IGamificationStepDto[]): number {
        return index / (status.length - 1) * 90 + 5;
    }

    // LEVEL

    public static getLevelIndex(coins: number, levels: IGamificationStepDto[]): number {
        for (let i = 0; i < levels.length; i++) {
            if (coins < levels[i].coinValue) {
                return i - 1;
            }
        }
        return levels.length - 1;
    }

    public static getLevelImage(index: number, appUser: AppUserModel): string {
        return `${appUser.project.systemFolder}/gamification_level_${index}.jpg`;
    }

    public static getLevelName(index: number, levels: IGamificationStepDto[]): IMultiLangString {
        return levels[index].name;
    }

    // TARGET

    public static getUserTarget(target: IGamificationTargetConfigDto, appUser: AppUserModel): IGamificationTargetValueDto {
        const userTarget: IGamificationTargetValueDto | undefined = appUser.gamificationTargets.find((_userTarget: IGamificationTargetValueDto) => {
            return _userTarget.targetIdentifier === target.targetIdentifier;
        });
        if (userTarget) {
            return userTarget;
        } else {
            return <IGamificationTargetValueDto>{
                targetIdentifier: target.targetIdentifier,
                targetValue: 0,
                coinsValue: 0
            }
        }
    }
}



export default GamificationUtil;
