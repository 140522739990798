<template>
  <div class="project-font" v-if="fileUrl && fileName && name" :class="`project-font--${name}`">

    <div class="project-font__icon mb-3">
      <i class="fas fa-file-alt fa-3x"></i>
    </div>

    <div class="project-font__text mb-3" v-html="$t(`ProjectSettingsFont_${name}`)" :style="{ 'font-family': fontFamily }"></div>

    <label class="project-font__button btn btn-secondary btn-sm" :disabled="true">
      <b-spinner small v-if="isWorking"></b-spinner>
      {{ $t('ProjectSettingsFontButton') }}
      <input type="file" ref="font" v-on:change="changeFont()" hidden accept=".woff2">
    </label>

  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import ProjectModel from "@/project/_model/ProjectModel";
import AppUserModel from "@/project/user/_model/AppUserModel";
import {FONT_NAME_PREFIX} from "@/project/_model/project.constants";
import projectController from "@/project/_controller/ProjectController";

enum ProjectFontState {
  IDLE,
  WORKING,
  ERROR
}

@Component({
  components: {},
})
export default class ProjectFont extends Vue {

  public project: ProjectModel = AppUserModel.getInstance().project;
  private currentState: ProjectFontState = ProjectFontState.IDLE;

  @Prop() private fileUrl!: string;
  @Prop() private fileName!: string;
  @Prop() private name!: string;

  get fontFamily() {
    return `${FONT_NAME_PREFIX}${this.name}, 'Roboto', sans-serif`;
  }

  get isIdle(): boolean {
    return this.currentState === ProjectFontState.IDLE;
  }

  get isWorking(): boolean {
    return this.currentState === ProjectFontState.WORKING;
  }

  public mounted() {
    this.loadFont();
  }

  private async loadFont() {
    // @ts-ignore
    const font: FontFace = new FontFace(this.name, `url(${this.fileUrl})`);
    await font.load();
    // @ts-ignore
    document.fonts.add(font);
  }

  private async changeFont() {
    if (this.isIdle) {
      this.currentState = ProjectFontState.WORKING;
      const font: HTMLInputElement = this.$refs.font as HTMLInputElement;
      if (font.files && font.files.length > 0) {
        const file: File = font.files[0];
        await projectController.updateSystemFile(this.fileName, file);
        await this.loadFont();
      }
      this.currentState = ProjectFontState.IDLE;
    }
  }

}
</script>

