import axios, {AxiosInstance, AxiosRequestConfig, AxiosResponse, ResponseType} from 'axios';
import {StorageScope} from "@/_model/app.constants";
import {ENTITY_CONFIG} from "@/entity/_model/entity.constants";
import DataProviderListModel from "@/data_tool/data_provider/_model/DataProviderListModel";
import dataProviderController from "@/data_tool/data_provider/_controller/DataProviderController";

class FileManager {


    // ---------------------------------
    // Public Static Properties
    // ---------------------------------


    //---------------------------------
    // Public Properties
    //---------------------------------


    //---------------------------------
    // Private Properties
    //---------------------------------


    private _projectUrl!: string;
    set projectUrl(value: string) {
        this._projectUrl = value;
        this._projectCdn = axios.create({
            baseURL: this._projectUrl,
        });
    }

    private _projectCdn!: AxiosInstance;

    private _globalUrl!: string;
    set globalUrl(value: string) {
        this._globalUrl = value;
        this._globalCdn = axios.create({
            baseURL: this._globalUrl,
        });
    }

    private _globalCdn!: AxiosInstance;

    private _appCdn?: AxiosInstance;

    private _localhostCdn?: AxiosInstance;

    //---------------------------------
    // getter setters
    //---------------------------------


    constructor() {
        this._appCdn = axios.create();

        if (process.env.VUE_APP_DEV_STORAGE_URL && process.env.NODE_ENV === "development") {
            this._localhostCdn = axios.create({
                baseURL: process.env.VUE_APP_DEV_STORAGE_URL,
            });
        }
    }

    //---------------------------------
    // Private Methods
    //---------------------------------


    //---------------------------------
    // Public Methods
    //---------------------------------


    public async fetchFileFromCdn(p_filePath: string, p_scope: StorageScope = StorageScope.PROJECT, p_responseType?: ResponseType, p_forceLocalhost: boolean = false): Promise<FileResponse> {
        let cdn: AxiosInstance | undefined = this._projectCdn;

        switch (p_scope) {
            case StorageScope.GLOBAL:
                cdn = this._globalCdn;
                break;
            case StorageScope.APP:
                cdn = this._appCdn;
                break;
        }

        if (p_forceLocalhost && this._localhostCdn) {
            cdn = this._localhostCdn;
        }


        const response: FileResponse = {hasSucceeded: false};

        // let noCacheUrl;
        // if (p_filePath.indexOf("?") >= 0)
        // {
        //     noCacheUrl = p_filePath + "&ignoreCache=" + new Date().getTime().toString();
        // }
        // else
        // {
        //     noCacheUrl = p_filePath + "?ignoreCache=" + new Date().getTime().toString();
        // }

        if (cdn) {
            try {
                const config: AxiosRequestConfig = {};
                if (p_responseType) {
                    config.responseType = p_responseType;
                }
                const axiosResponse: AxiosResponse = await cdn.get(p_filePath, config);
                response.result = axiosResponse.data;
                response.hasSucceeded = true;

            } catch (e) {
                const code: number = e.response && e.response.status ? e.response.status : 400;
                response.error = {code: code, isOperational: false, message: e.message};
                response.hasSucceeded = false;
            }

        } else {
            console.log("ERROR: no cdn url provided for " + p_scope);
            response.error = {code: 400, isOperational: false, message: "no cdn url provided for " + p_scope};
        }
        return response;

    }

    public getFileUrl(p_fileUri: string): string {
        if (p_fileUri && this._projectUrl) {
            const basePath: string = p_fileUri.split("://")[0];
            const filePath: string = p_fileUri.split("://")[1];
            if (filePath) {
                return this._projectUrl + `/${basePath}s/` + filePath;
            }
        }
        return "";
    }


    public getFilePathFromUri(p_assetFileUri: string): string {
        return p_assetFileUri.split("/")[3];
    }

    public getAssetFolderIDFromUri(p_assetFileUri: string): string | null {
        return p_assetFileUri.split("/")[2];
    }

    public getAssetFileBasePath(p_assetFileUri: string): string {
        if (p_assetFileUri && p_assetFileUri.indexOf("assetFolder://") >= 0) {
            return p_assetFileUri.split("assetFolder://").join("/assetFolders/");
        } else {
            return "";
        }
    }
}

export default new FileManager();


export interface FileResponse {
    hasSucceeded: boolean;
    result?: any;
    error?: FileError;
}


export interface FileError {
    message: string;
    code: number;
    isOperational: boolean;
}

