<template>
  <div class="sub-page team-users">
    <div class="columns">
      <TeamUsersOverview :selState="selState"></TeamUsersOverview>
      <TeamUserDetail :selState="selState"></TeamUserDetail>
    </div>
  </div>
</template>

<script lang="ts">
import {Component, Vue} from "vue-property-decorator";
import TeamUserModel from "@/team/_model/TeamUserModel";
import SelectionState from "@/__libs/_model/SelectionState";
import TeamUserListModel from "@/team/_model/TeamUserListModel";
import TeamUserDetail from "@/team/_view/TeamUserDetail.vue";
import TeamUsersOverview from "@/team/_view/TeamUsersOverview.vue";

@Component({
    components: {TeamUsersOverview, TeamUserDetail}
})
export default class TeamUsersView extends Vue {
    public selState: SelectionState<TeamUserModel> = TeamUserListModel.getInstance().globalSelState;
}
</script>
