<template>

    <div class="share-with-audience">

        <transition name="fade">
            <div v-if="currentFormState === formState.TYPE_CHOICE">
                <b-form>

                    <p>{{ $t('LinkChooseType') }}</p>

                    <select class="form-control mb-3"
                            v-model="linkValue.apiAction"
                            @change="_chooseType" tabindex="5">
                        <option v-for="type in linkTypeOptions" :key="type"
                                :value="type">{{ $t(`linkType_${type}`) }}
                        </option>
                    </select>

                    <div class="d-flex justify-content-end mt-4">
                        <b-button variant="secondary" class="mr-2" @click="_onCancelBtnClick" tabindex="6">
                            {{ $t('Cancel') }}
                        </b-button>
                        <b-button @click="_onNextBtnClick" variant="primary">{{
                            $t('Next') }}
                        </b-button>
                    </div>

                </b-form>

            </div>
        </transition>

        <transition name="fade">
            <div v-if="currentFormState === formState.VALUE_CHOICE">
                <b-form>
                    <div>{{ $t(`linkValue_${linkValue.apiAction}`) }}</div>

                    <div v-if="valueOptions" class="text-center">
                        <select class="form-control mb-3"
                                v-model="linkValue.value">
                            <option v-for="option in valueOptions" :key="option.identifier"
                                    :value="option.identifier">{{ option.name }}
                            </option>
                        </select>
                    </div>

                    <div v-if="linkValue.apiAction === apiAction.URL" class="text-center">
                        <b-form-input v-model="linkValue.value"></b-form-input>
                    </div>

                    <div class="d-flex justify-content-end mt-4">
                        <b-button variant="secondary" class="mr-2" @click="_onCancelBtnClick" tabindex="6">
                            {{ $t('Cancel') }}
                        </b-button>
                        <b-button variant="secondary" class="mr-2" @click="_onBackBtnClick" tabindex="6">
                            {{ $t('Back') }}
                        </b-button>
                        <b-button @click="_onFinishBtnClick" :disabled="!linkValue.value" variant="primary">{{
                            $t('Finish') }}
                        </b-button>
                    </div>
                </b-form>
            </div>

        </transition>


    </div>

</template>

<script lang="ts">

    import {Component, Prop, Vue} from 'vue-property-decorator';
    import AudienceModel from "@/audience/_model/AudienceModel";
    // validation
    import AudienceListModel from "@/audience/_model/AudienceListModel";
    import {ILinkOptionDto, ILinkValueDto, ILinkWizardDto} from "@/presentation/_model/presentation.dto";
    import {ApiAction, NavigateType} from "@/presentation/player/_model/player.constants";
    import ContentModel from "@/content/_model/ContentModel";
    import ContentListModel from "@/content/_model/ContentListModel";
    import languageManager from "@/__libs/language_manager/LanguageManager";
    import PresentationModel from "@/presentation/_model/PresentationModel";
    import {EntityType} from "@/entity/_model/entity.constants";


    enum FormState
    {
        TYPE_CHOICE,
        VALUE_CHOICE
    }


    @Component({
        components: {},
    })
    export default class LinkWizardBox extends Vue
    {


        //---------------------------------
        // Vue Component props
        //---------------------------------

        @Prop() public linkWizardDto!:ILinkWizardDto;


        //---------------------------------
        // Vue Component data
        //---------------------------------

        private formState:typeof FormState = FormState;

        private currentFormState:FormState = FormState.TYPE_CHOICE;

        private apiAction:typeof ApiAction = ApiAction;

        private linkValue:ILinkValueDto = {apiAction: ApiAction.SLIDE, value: null};

        private presentations:ILinkOptionDto[] = [];
        private contentFiles:ILinkOptionDto[] = [];
        private contentApps:ILinkOptionDto[] = [];

        private valueOptions:ILinkOptionDto[] | null = null;
        //---------------------------------
        // Vue Computed properties
        //---------------------------------


        get audienceLangCode():string | null
        {
            const activeAudience:AudienceModel | null = AudienceListModel.getInstance()
                .globalSelState.selected;
            return activeAudience ? activeAudience.langCode : null;
        }

        get linkTypeOptions()
        {
            const options:ApiAction[] = [];
            if (this.linkWizardDto.slideOptions && this.linkWizardDto.slideOptions.length > 0)
            {
                options.push(ApiAction.SLIDE);
            }
            if (this.linkWizardDto.chapterOptions && this.linkWizardDto.chapterOptions.length > 1)
            {
                options.push(ApiAction.CHAPTER);
            }
            if (this.linkWizardDto.allowNavigate)
            {
                options.push(ApiAction.NAVIGATE);
            }
            if (this.linkWizardDto.deckOptions && this.linkWizardDto.deckOptions.length > 0)
            {
                options.push(ApiAction.DECK);
            }
            if (this.linkWizardDto.languageOptions && this.linkWizardDto.languageOptions.length > 1)
            {
                options.push(ApiAction.LANGUAGE);
            }
            if (this.linkWizardDto.actionOptions && this.linkWizardDto.actionOptions.length > 0)
            {
                options.push(ApiAction.ACTION);
            }
            if (this.linkWizardDto.allowUrl)
            {
                options.push(ApiAction.URL);
            }
            if (this.linkWizardDto.allowOpenContent)
            {
                options.push(ApiAction.OPEN_PRESENTATION);
                options.push(ApiAction.OPEN_CONTENT_FILE);
                options.push(ApiAction.OPEN_CONTENT_APP);
            }
            return options;
        }

        //---------------------------------
        // Public / lifecycle methods
        //---------------------------------


        mounted()
        {
            if (this.linkWizardDto.linkValue)
            {
                this.linkValue.apiAction = this.linkWizardDto.linkValue.apiAction;
                this.linkValue.value = this.linkWizardDto.linkValue.value;
            }
            this._chooseType();

            const contents:ContentModel[] = ContentListModel.getInstance().list;
            for (let i = 0; i < contents.length; i++)
            {
                const content = contents[i];
                const name:string = languageManager.getTranslationForValue(content.name, this.audienceLangCode);

                if (content.entityType === EntityType.PRESENTATION)
                {
                    this.presentations.push({identifier: content.ID, name: name})
                }
                else if (content.entityType === EntityType.CONTENT_FILE)
                {
                    this.contentFiles.push({identifier: content.ID, name: name})
                }
                else if (content.entityType === EntityType.CONTENT_APP)
                {
                    this.contentApps.push({identifier: content.ID, name: name})
                }
            }

        }

        //---------------------------------
        // Private / helper methods
        //---------------------------------

        private async _chooseType()
        {
            this.linkValue.value = null;
            switch (this.linkValue.apiAction)
            {
                case ApiAction.SLIDE:
                    this.valueOptions = this.linkWizardDto.slideOptions;
                    break;
                case ApiAction.CHAPTER:
                    this.valueOptions = this.linkWizardDto.chapterOptions!;
                    break;
                case ApiAction.LANGUAGE:
                    this.valueOptions = this.linkWizardDto.languageOptions!;
                    break;
                case ApiAction.DECK:
                    this.valueOptions = this.linkWizardDto.deckOptions!;
                    break;
                case ApiAction.ACTION:
                    this.valueOptions = this.linkWizardDto.actionOptions!;
                    break;
                case ApiAction.NAVIGATE:
                    this.valueOptions = [
                        {identifier: NavigateType.HOME, name: this.$t("navigateHome") as string},
                        {identifier: NavigateType.END, name: this.$t("navigateEnd") as string},
                        {identifier: NavigateType.PREVIOUS_SLIDE, name: this.$t("navigatePreviousSlide") as string},
                        {identifier: NavigateType.NEXT_SLIDE, name: this.$t("navigateNextSlide") as string}];
                    break;
                case ApiAction.OPEN_PRESENTATION:
                    this.valueOptions = this.presentations;
                    break;
                case ApiAction.OPEN_CONTENT_FILE:
                    this.valueOptions = this.contentFiles;
                    break;
                case ApiAction.OPEN_CONTENT_APP:
                    this.valueOptions = this.contentApps;
                    break;

                default:
                    this.valueOptions = null;
                    break;
            }
        }

        private async _onFinishBtnClick(p_e:Event)
        {
            if (this.linkValue.apiAction === ApiAction.URL)
            {
                if ((this.linkValue.value as string).indexOf("://") === -1)
                {
                    this.linkValue.value = "https://" + this.linkValue.value;
                }
            }
            this.$emit('onLinkChosen', this.linkValue);
        }

        private async _onNextBtnClick(p_e:Event)
        {
            this.currentFormState = FormState.VALUE_CHOICE;
        }

        private async _onBackBtnClick(p_e:Event)
        {
            this.currentFormState = FormState.TYPE_CHOICE;
        }

        private async _onCancelBtnClick(p_e:Event)
        {
            this.$emit('onLinkChosen', null);
        }



    }
</script>
