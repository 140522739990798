import {Quill} from "vue2-editor";

const Link = (Quill as any).import('formats/link');

class CustomLink extends Link {
    private static _isAudienceLink(url: string): boolean {
        return url.startsWith('javascript:window.parent.Audience');
    }

    static sanitize(url: string): string {
        if (!this._isAudienceLink(url)) {
            return super.sanitize(url);
        }

        // remove html tags from link (like <script> tags)
        return url.replace(/(<([^>]+)>)/ig,"").replace(/\r\n|\r|\n/g,"<br />");
    }

    static create(url: string): HTMLLinkElement {
        const node: HTMLLinkElement = super.create(url);
        url = this.sanitize(url);

        node.setAttribute('href', url);

        if(this._isAudienceLink(url)) {
            node.removeAttribute('target');
        }

        return node;
    }
}

Quill.register(CustomLink, true);
