import SyncProcess from "@/sync/_controller/process/SyncProcess";
import {ISyncFileDto, ISyncFileRecord} from "@/sync/_model/sync.dto";
import syncController from "@/sync/_controller/SyncController";
import {SyncFileRetrieveStatus} from "@/sync/_model/sync.constants";

class SyncFileGroupFileProcess extends SyncProcess
{

    //this process retrieves individual files from a filegroup (without necessarily retrieving the other files in that group, eg: asset files from an asset folder)

    //---------------------------------
    // Properties

    //---------------------------------

    public dependencyLevel:number = 3;


    private _fileGroupFiles:ISyncFileDto[] = [];

    //a dictionary with the file path as key, so we can check quickly if the file already exists when we add it to the _fileGroupFiles list
    private _fileGroupFilesDict:{ [index:string]:boolean } = {};


    //---------------------------------
    // Public Methods
    //---------------------------------



    public addFileGroupFile(p_file:ISyncFileDto)
    {
        if (!this._fileGroupFilesDict[p_file.path])
        {
            this._fileGroupFiles.push(p_file);
            this._fileGroupFilesDict[p_file.path] = true;
        }
    }


    //---------------------------------
    // Overridden Methods
    //---------------------------------

    public startProcess()
    {
        // console.log("starting SyncFileGroupFileProcess", this._fileGroupFiles);
        this._retrieveNextFile();

    }


    public resumeProcess()
    {
        this._retrieveNextFile();
    }



    //---------------------------------
    // Private Methods
    //---------------------------------

    private _retrieveNextFile()
    {

        if (this._syncModel.isSyncing && !this._syncModel.isPaused)
        {
            for (let i = 0; i < this._fileGroupFiles.length; i++)
            {
                const itemFile:ISyncFileDto = this._fileGroupFiles[i];
                if (itemFile.retrieveStatus === SyncFileRetrieveStatus.IDLE)
                {
                    this._computePercCompleted(i);
                    this._retrieveFile(itemFile);
                    return;
                }
            }
            this._finishProcess();
        }
    }


    private async _retrieveFile(p_itemFile:ISyncFileDto)
    {
        let needForFetch:boolean = true;

        if (p_itemFile.remoteVersion)
        {
            //check idb for local version to see if fetch is needed
            const fileRecord:ISyncFileRecord | null = await this._syncDb.getFileRecord(p_itemFile.path);

            if (fileRecord && fileRecord.localVersion && fileRecord.localVersion === p_itemFile.remoteVersion && fileRecord.body)
            {
                //console.log("FOUND in idb and up to date, extracting from idb:", p_itemFile.path);
                p_itemFile.retrieveStatus = SyncFileRetrieveStatus.RETRIEVED;
                needForFetch = false;
            }
        }

        if (needForFetch)
        {
            //console.log("fetching", p_itemFile.path);
            const fileBody:any = await syncController.fetchFile(p_itemFile);
            if (fileBody)
            {
                p_itemFile.body = fileBody;
                await syncController.storeFile(p_itemFile); //todo: try not awaiting this for faster sync
            }
        }
        this._retrieveNextFile();
    }

    private _computePercCompleted(p_currentFileIndex:number)
    {
        const percCompleted:number = Math.round(p_currentFileIndex / this._fileGroupFiles.length * 100);
        if (percCompleted > this.percCompleted)
        {
            this.percCompleted = percCompleted;
            syncController.computePercCompleted();
        }
    }

    //---------------------------------
    // Overridden Methods
    //---------------------------------



}

export default SyncFileGroupFileProcess;
