<template>
  <div class="root-page content-app-player-page">

    <ContentAppPlayer v-if="appReady"
                      :engineMode="engineMode"
                      :appID="appID"
                      :sessionIdentifier="sessionIdentifier"
                      ref="appPlayer"/>

  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import {EngineMode} from "@/presentation/player/_model/player.constants";
import {RoutingIdentifier} from "@/router";
import ContentAppPlayer from "@/content_app/player/_view/ContentAppPlayer.vue";
import ContentListModel from "@/content/_model/ContentListModel";
import {LoadingStatus} from "@/entity/_model/entity.constants";
import ContentAppModel from "@/content_app/_model/ContentAppModel";

@Component({
  components: {
    ContentAppPlayer
  }
})

//This page could also be shown in a popup (on desktops)
export default class ContentAppPlayerPage extends Vue {

  @Prop() private engineMode!: EngineMode;

  @Prop() private appID!: string;

  @Prop() private sessionIdentifier!: string;

  get appReady(): boolean {
    if (this.appID) {
      const app: ContentAppModel = ContentListModel.getInstance().getEntityByID(this.appID) as ContentAppModel;
      if (app && app.loadingStatus === LoadingStatus.BODY_LOADED) {
        return true;
      }

    }
    return false;
  }

}
</script>
