import {SyncPauseReason} from "@/sync/_model/sync.constants";

class SyncModel
{


    public isSyncing:boolean = false;
    public isPaused:boolean = false;
    public pauseReason:SyncPauseReason | null = null;

    public isOfflineAvailable:boolean  = false;
    public lastSuccessfulSync:Date | null = null;
    public isSyncEnabled:boolean  = true;
    public doAutoSync:boolean  = true;

    public hasStorageManagement:boolean = false;
    public hasPersistentStorage:boolean = false;
    public storageQuota:number = 0;
    public storageUsage:number =  0;



    public neededModules:string[]  = [];
    public neededSlides:string[]  = [];

    public percCompleted:number = 0;


    //---------------------------------
    // Singleton Constructor
    //---------------------------------

    private static _instance:SyncModel;

    constructor()
    {
        if (!SyncModel._instance)
        {
            SyncModel._instance = this;
        }
    }

    public static getInstance():SyncModel
    {
        if (!SyncModel._instance)
        {
            new SyncModel();
        }

        return SyncModel._instance;
    }


    //---------------------------------
    // Public Methods
    //---------------------------------



}

export default SyncModel;
