import SelectionState from "../../__libs/_model/SelectionState";
import RoleModel from "./RoleModel";
import ListModel from "../../__libs/_model/ListModel";
import {IRightOutlineDto} from "@/team/_model/team.dto";

class RoleListModel  extends ListModel<RoleModel>
{

    public globalSelState:SelectionState<RoleModel> = new SelectionState<RoleModel>();

    public rights:IRightOutlineDto[] = [];

    //---------------------------------
    // Singleton Constructor
    //---------------------------------

    private static _instance:RoleListModel;

    constructor()
    {
        super();
        if (!RoleListModel._instance)
        {
            RoleListModel._instance = this;
        }
    }

    public static getInstance():RoleListModel
    {
        if (!RoleListModel._instance)
        {
            new RoleListModel();
        }

        return RoleListModel._instance;
    }


    //---------------------------------
    // Public Methods
    //---------------------------------

    public getRoleByID(p_ID:number):RoleModel | null
    {
        for (let i = 0; i < this.list.length; i++)
        {
            const item = this.list[i];
            if (item.roleID === p_ID)
            {
                return item;
            }
        }
        return null;
    }

}

export default RoleListModel;
