<template>
  <div class="base-menu-bar">
    <div class="base-menu">
      <router-link :to="{ name: routingIdentifiers.CONTENT_OVERVIEW }" class="base-menu-item">
        <div class="icon">
          <i class="fas fa-book-open fa-2x"></i>
        </div>
        <h6 v-html="$t('DashboardTabTitleShow')"></h6>
      </router-link>
      <router-link :to="{ name: routingIdentifiers.SHARE }" class="base-menu-item" :class="{ disabled: !online }">
        <div class="icon">
          <i class="fas fa-share fa-2x"></i>
        </div>
        <h6 v-html="$t('DashboardTabTitleShared')"></h6>
      </router-link>
      <router-link :to="{ name: routingIdentifiers.TRACK }" class="base-menu-item" :class="{ disabled: !online }">
        <div class="icon">
          <i class="fas fa-chart-simple fa-2x"></i>
        </div>
        <h6 v-html="$t('DashboardTabTitleTrack')"></h6>
      </router-link>
      <router-link :to="{ name: routingIdentifiers.AUDIENCE_DETAIL }" class="base-menu-item">
        <div class="icon">
          <i class="fas fa-user fa-2x"></i>
        </div>
        <h6 v-html="$t('DashboardTabTitleAudience')"></h6>
      </router-link>
    </div>

    <Highlight></Highlight>
  </div>
</template>

<script lang="ts">
import {Component, Vue, Watch} from "vue-property-decorator";
import {RoutingIdentifier} from "@/router";
import NetworkManager, {NetworkEventType, NetworkState} from "@/_controller/NetworkManager";
import Highlight from "@/_view/components/Highlight.vue";
import Hint from "@/help/hint/_view/Hint.vue";
import {HintIds} from "@/help/hint/_model/hint.constants";

@Component({
    components: {Hint, Highlight}
})
export default class HomeMenu extends Vue {

    private hintIds: typeof HintIds = HintIds;

    private routingIdentifiers = RoutingIdentifier;

    public networkManager: NetworkManager = NetworkManager.getInstance();

    get online() {
        return this.networkManager.online;
    }

}
</script>
