<template>
  <div class="project-usage box box--full-height box--scrollable" v-if="project">

    <h4 class="title mt-1 mb-4">{{ $t('ProjectDetailUsageTitle') }}</h4>

    <div class="sub-box mb-3">
      <h5 class="title mt-1 mb-3">{{ $t('ProjectDetailUserTitle') }}</h5>
      <div class="mb-2">{{ $t('ProjectDetailUserAmount') }}: <strong>{{ userAmount }}</strong></div>
    </div>

    <div class="sub-box mb-3">
      <h5 class="title mt-1 mb-3">{{ $t('ProjectDetailTeamTitle') }}</h5>
      <div class="mb-1">{{ $t('ProjectDetailTeamAmount') }}: <strong>{{ teamAmount }}</strong></div>
      <div class="mb-3">{{ $t('ProjectDetailTeamMaximum') }}: <strong>{{ project.tierConfig.maxNrOfTeams }}</strong>
      </div>
      <b-progress :value="teamAmount" :max="project.tierConfig.maxNrOfTeams" class="mb-2"
                  :variant="teamVariant"></b-progress>
    </div>

    <div class="sub-box mb-4">
      <h5 class="title mt-1 mb-3">{{ $t('ProjectDetailStorageTitle') }}</h5>
      <div v-if="!storageAlreadyCalculated" class="mb-2">
        <b-button @click="calculateStorage" variant="primary" block :disabled="project.storageIsCalculating"><b-spinner class="mr-2" small v-if="project.storageIsCalculating"></b-spinner>{{ $t('ProjectDetailStorageButtonCalculate') }}</b-button>
      </div>
      <div v-if="storageAlreadyCalculated" class="mb-2">
        <div class="mb-1">{{ $t('ProjectDetailStorageAmount') }}: <strong>{{ storageAmountFileSize }}</strong></div>
        <div class="mb-3">{{ $t('ProjectDetailStorageMaximum') }}: <strong>{{ storageMaxFileSize }}</strong></div>
        <b-progress :value="storageAmount" :max="project.tierConfig.maxStorage" class="mb-4"
                    :variant="storageVariant"></b-progress>
        <b-button @click="calculateStorage" variant="primary" block :disabled="project.storageIsCalculating"><b-spinner class="mr-2" small v-if="project.storageIsCalculating"></b-spinner>{{ $t('ProjectDetailStorageButtonRecalculate') }}</b-button>
      </div>
    </div>

  </div>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import ProjectModel from "@/project/_model/ProjectModel";
import AppUserModel from "@/project/user/_model/AppUserModel";
import fileSize from "filesize";
import projectController from "@/project/_controller/ProjectController";

@Component({})
export default class ProjectUsage extends Vue {

  public project: ProjectModel = AppUserModel.getInstance().project;

  get userAmount(): number {
    return this.project.tierQuota.nrOfUsers;
  }

  get teamAmount(): number {
    return this.project.tierQuota.nrOfTeams;
  }

  get teamVariant(): string {
    if (this.teamAmount / this.project.tierConfig.maxNrOfTeams >= 0.8) {
      return 'danger';
    }
    return 'success';
  }

  get storageAmount(): number {
    return this.project.storageTaken;
  }

  get storageAmountFileSize(): string {
    return fileSize(this.storageAmount);
  }

  get storageMaxFileSize(): string {
    return fileSize(this.project.tierConfig.maxStorage);
  }

  get storageVariant(): string {
    if (this.storageAmount / this.project.tierConfig.maxStorage >= 0.8) {
      return 'danger';
    }
    return 'success';
  }

  get storageAlreadyCalculated(): boolean {
    return this.project.storageTaken >= 0;
  }

  private async calculateStorage(): Promise<void> {
    if (!this.project.storageIsCalculating) {
      await projectController.calculateStorage();
    }
  }

}
</script>

