<template>
  <div class="gamification-summary box box--full-height">
    <UserInfo></UserInfo>
    <UserStatus></UserStatus>
    <UserCoins></UserCoins>
    <UserRanking></UserRanking>
  </div>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import UserInfo from "@/gamification/summary/_view/UserInfo.vue";
import UserCoins from "@/gamification/summary/_view/UserCoins.vue";
import UserBadge from "@/gamification/summary/_view/UserStatus.vue";
import UserRanking from "@/gamification/summary/_view/UserRanking.vue";
import UserStatus from "@/gamification/summary/_view/UserStatus.vue";

@Component({
  components: {
    UserStatus,
    UserCoins,
    UserInfo,
    UserRanking
  }
})
export default class GamificationSummary extends Vue {

}
</script>

