import {
    ICompanyBodyDto,
    ICompanyMetaDto
} from "@/company/_model/company.dto";
import EntityModel from "@/entity/_model/EntityModel";
import {IModelDto} from "@/__libs/_model/model.dto";
import {EntityType, UploadStatus} from "@/entity/_model/entity.constants";
import {DtoType} from "@/_model/app.constants";
import {IMultiLangString, LanguageManager} from "@/__libs/language_manager/LanguageManager";
import DataProviderModel from "@/data_tool/data_provider/_model/DataProviderModel";
import {IDataProviderBodyDto} from "@/data_tool/_model/data_tool.dto";

export default class CompanyModel extends EntityModel implements ICompanyBodyDto, ICompanyMetaDto
{
    public entityType:EntityType = EntityType.COMPANY;


    //body
    public displayName:string = "";
    public companyLogo:string = "";
    public notes?:IMultiLangString;

    //custom properties
    public dataSchemaURI:string = "dataSchema://company_schema"; //can in the future be used to create or change to a different "type" of audience with different custom props
    public data:any = {};
    public dataDtp?:DataProviderModel;




    //meta


    //---------------------------------
    // Constructor
    //---------------------------------

    constructor(p_dto?:IModelDto)
    {
        super();
        if (p_dto)
        {
            this.mapFromDto(p_dto);
        }
    }

    //---------------------------------
    // Public Methods
    //---------------------------------

    public mapFromDto(p_dto:IModelDto)
    {
        super.mapFromDto(p_dto);
        this.dataDtp = new DataProviderModel({data: this.data, dataSchemaURI: this.dataSchemaURI, ID: this.ID}, this);
    }

    // until typescript has proper reflection, we will have to do it this way:
    public mapToDto(p_dtoType:DtoType):IModelDto
    {
        if (p_dtoType === DtoType.BODY)
        {

            const dto:ICompanyBodyDto = {
                ID         : this.ID,
                version    : this.version,
                displayName: this.displayName,
                companyLogo: this.companyLogo,
                notes      : this.notes,
                dataSchemaURI: this.dataSchemaURI,
            };
            if (this.dataDtp)
            {
                dto.data = (this.dataDtp.mapToDto(DtoType.BODY) as IDataProviderBodyDto).data;
            }


            return dto;
        }
        else
        {
            return super.mapToDto(p_dtoType);
        }
    }



}
