import SyncFileGroupProcess from "@/sync/_controller/process/SyncFileGroupProcess";
import {ISyncItemDto, ISyncItemFileDto} from "@/sync/_model/sync.dto";
import {IContentAppBodyDto} from "@/content_app/_model/content_app.dto";
import {IContentFileBodyDto} from "@/content_file/_model/content_file.dto";

class SyncContentFileProcess extends SyncFileGroupProcess
{

    //---------------------------------
    // Properties
    //---------------------------------

    //the content files are a mixed bag: the cfg (the actual body) is stored behind the client-api/content-files, and the actual files are on the project cdn under contentFiles/
    protected _storagePath:string = "contentFiles";
    protected _storedBehindApi:boolean = true;


    public dependencyLevel:number = 0;

    //---------------------------------
    // Controller Methods
    //---------------------------------




    //---------------------------------
    // Private Methods
    //---------------------------------

    protected _resolveDependencyProcesses() {

    }

    protected _resolveItemFilePath(p_syncItem:ISyncItemDto):string
    {
        return `/content-files/${p_syncItem.identifier}`

    }


    protected _parseRetrievedItemFile(p_itemFile:ISyncItemFileDto)
    {
        super._parseRetrievedItemFile(p_itemFile);

        const body:IContentFileBodyDto = p_itemFile.body;

        //poster asset files
        if (body.poster)
        {
            this._parseAssetFilesFromMultiLangString(body.poster);
        }
    }

}

//Singleton export
export default SyncContentFileProcess;
