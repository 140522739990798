<template>

    <div>
        <b-table striped :items="subjects" :fields="fields" :head-variant="'dark'" :borderless="true">
            <template v-slot:cell(percentage)="data">
                <span v-if="data.value === 0">-</span>
                <span v-if="data.value > 0" class="text-success">+{{ data.value }}%</span>
                <span v-if="data.value < 0" class="text-danger">{{ data.value }}%</span>
            </template>
            <template v-if="isComparison" v-slot:cell(loggedIn)="data">
                <span v-if="data.value > 0">+{{ data.value }}</span>
                <span v-if="data.value < 0">{{ data.value }}</span>
                <span v-if="data.value === 0">-</span>
            </template>
            <template v-if="isComparison" v-slot:cell(presented)="data">
                <span v-if="data.value > 0">+{{ data.value }}</span>
                <span v-if="data.value < 0">{{ data.value }}</span>
                <span v-if="data.value === 0">-</span>
            </template>
            <template v-if="isComparison" v-slot:cell(shared)="data">
                <span v-if="data.value > 0">+{{ data.value }}</span>
                <span v-if="data.value < 0">{{ data.value }}</span>
                <span v-if="data.value === 0">-</span>
            </template>
            <template v-if="isComparison" v-slot:cell(total)="data">
                <span v-if="data.value > 0">+{{ data.value }}</span>
                <span v-if="data.value < 0">{{ data.value }}</span>
                <span v-if="data.value === 0">-</span>
            </template>
        </b-table>

    </div>

</template>

<script lang="ts">
    import {Component, Prop, Vue} from 'vue-property-decorator';
    import {
        IAnalyticsActionCfgDto,
        IAnalyticsSubjectDto,
        IAnalyticsSubjectListWithActionsWidgetCfgDto,
        IAnalyticsSubjectListWithActionsWidgetDataDto, IAnalyticsUsageDto
    } from "@/analytics/_model/analytics.dto";
    import languageManager, {IMultiLangString} from "@/__libs/language_manager/LanguageManager";
    import AppUserModel from "@/project/user/_model/AppUserModel";
    import {AnalyticsValueDisplayType} from "@/analytics/_model/analytics.constants";
    import AnalyticsModel from "@/analytics/_model/AnalyticsModel";
    import i18n from "@/__plugins/i18n";


    @Component({
        components: {}
    })
    export default class AnalyticsUsageTable extends Vue
    {

        //---------------------------------
        // Vue Component props
        //---------------------------------


        @Prop() private usageData!:IAnalyticsUsageDto[];
        @Prop() private isComparison!:boolean;

        //---------------------------------
        // Vue Component data
        //---------------------------------



        //---------------------------------
        // Vue Computed properties
        //---------------------------------


        get fields():any[]
        {
            const fields:any[] = [{key: "displayName", label: "", sortable: true},
                {key: "loggedIn", label: i18n.tc("loggedIn"), sortable: true},
                {key: "presented", label: i18n.tc("presented"), sortable: true},
                {key: "shared", label: i18n.tc("shared"), sortable: true},
                {key: "total", label: i18n.tc("total"), sortable: true}];

            if (this.isComparison)
            {
                fields.push({key: "percentage", label: i18n.tc("TotalPerc"), sortable: true})
            }
            else
            {
                fields.push({key: "percentage", label: i18n.tc("evolution"), sortable: true})
            }


            return fields;
        }

        get subjects():any[]
        {
            return this.usageData;
        }

        //---------------------------------
        // Public / lifecycle methods
        //---------------------------------

        mounted()
        {
        }



        //---------------------------------
        // Private / helper methods
        //---------------------------------



    }
</script>

