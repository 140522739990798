<template>
  <div class="flow-level">
    <img :src="levelImage" class="flow-level__background" />
    <div class="flow-level__overlay"></div>
    <h6 v-html="levelName" class="flow-level__title mb-0"></h6>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import UserInfo from "@/gamification/summary/_view/UserInfo.vue";
import UserCoins from "@/gamification/summary/_view/UserCoins.vue";
import UserBadge from "@/gamification/summary/_view/UserStatus.vue";
import UserRanking from "@/gamification/summary/_view/UserRanking.vue";
import UserStatus from "@/gamification/summary/_view/UserStatus.vue";
import {IGamificationProjectConfigDto} from "@/gamification/_model/gamification.dto";
import AppUserModel from "@/project/user/_model/AppUserModel";
import AudienceModel from "@/audience/_model/AudienceModel";
import GamificationUtil from "@/gamification/_controller/GamificationUtil";
import languageManager from "@/__libs/language_manager/LanguageManager";

@Component({
  components: {}
})
export default class FlowLevel extends Vue {

  @Prop() public levelIndex!: number;

  private appUser: AppUserModel = AppUserModel.getInstance();

  get levelImage(): string {
    return GamificationUtil.getLevelImage(this.levelIndex, this.appUser);
  }

  get levelName(): string {
    return languageManager.getTranslationForValue(GamificationUtil.getStatusName(this.levelIndex, this.appUser.project.config.gamification.levels));
  }

}
</script>

