<template>
  <div class="gamification-leaderboard box box--full-height" :class="{ 'gamification-leaderboard--custom-month': isCustomMonth }">

    <transition name="fade">
      <div v-if="currentState === gamificationLeaderboardState.LOADING"
           class="empty-loader">
        <b-spinner variant="dark"></b-spinner>
      </div>
    </transition>

    <transition name="fade">
      <div v-if="currentState === gamificationLeaderboardState.ERROR_LOADING" class="error-message">
        {{ $t('ErrorConnectionRequired') }}
      </div>
    </transition>

    <transition name="fade">
      <div class="gamification-leaderboard__wrapper" v-if="currentState === gamificationLeaderboardState.LOADED">
        <div class="gamification-leaderboard__header mb-3">
          <h5 class="mb-0" v-html="$t('GamificationLeaderboardTitle')" v-line-clamp="1"></h5>
          <b-form-select class="gamification-leaderboard__header__teams" v-model="selectedTeamIds"
                         v-if="hasViewTeamGamification" @input="onTeamsChanged">
            <b-form-select-option v-for="team in availableTeams" :value="[team.teamID]" :key="team.teamID">
              {{ team.displayName }}
            </b-form-select-option>
            <b-form-select-option :value="availableTeamIds">{{
                $t('GamificationLeaderboardAllTeams')
              }}
            </b-form-select-option>
          </b-form-select>
          <b-form-select class="gamification-leaderboard__header__time" v-model="selectedTime" @input="onTimeChanged">
            <b-form-select-option :value="gamificationLeaderboardTime.ALL">{{
                $t('GamificationLeaderboardAllTime')
              }}
            </b-form-select-option>
            <b-form-select-option :value="gamificationLeaderboardTime.MONTH">{{
                $t('GamificationLeaderboardCustomMonth')
              }}
            </b-form-select-option>
          </b-form-select>
          <date-picker v-model="date" type="month" @input="onDateChanged" :clearable="false"
                       v-if="this.selectedTime === gamificationLeaderboardTime.MONTH"></date-picker>
        </div>

        <div class="gamification-leaderboard__top mb-3">
        </div>

        <div class="gamification-leaderboard__list" v-if="hasViewTeamGamification">
          <LeaderBoardItem v-for="(user, index) in leaderBoard" :user="user" :ranking="index + 1" :key="user.userID"></LeaderBoardItem>
        </div>

        <div class="gamification-leaderboard__list" v-if="!hasViewTeamGamification">
          <LeaderBoardItem v-for="(user, index) in leaderBoardTop3" :user="user" :ranking="index + 1" :key="user.userID"></LeaderBoardItem>
          <LeaderBoardItem v-if="!userIsInTop3" :user="leaderBoardUser" :ranking="userRanking" :key="appUser.ID"></LeaderBoardItem>
        </div>
      </div>
    </transition>

  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import gamificationController from "@/gamification/_controller/GamificationController";
import AppUserModel from "@/project/user/_model/AppUserModel";
import {
  IGamificationLeaderBoardUserDto,
  IGamificationProjectConfigDto,
  IGamificationTargetConfigDto
} from "@/gamification/_model/gamification.dto";
import Mission from "@/gamification/missions/_view/Mission.vue";
import DatePicker from "vue2-datepicker";
import Util from "@/__libs/utility/Util";
import DaysLeft from "@/gamification/_view/DaysLeft.vue";
import {ITeamOutlineDto} from "@/team/_model/team.dto";
import {RIGHTS} from "@/team/_model/role.constants";
import LeaderBoardItem from "@/gamification/leaderboard/_view/LeaderBoardItem.vue";
import AppModel from "@/_model/AppModel";

enum GamificationLeaderboardState {
  IDLE,
  LOADING,
  ERROR_LOADING,
  LOADED
}

enum GamificationLeaderboardTime {
  ALL = 0,
  MONTH = 1
}

@Component({
  components: {LeaderBoardItem, DatePicker}
})
export default class GamificationLeaderboard extends Vue {

  @Prop() public startDate!: Date;

  private gamificationLeaderboardState: typeof GamificationLeaderboardState = GamificationLeaderboardState;
  private currentState: GamificationLeaderboardState = GamificationLeaderboardState.IDLE;

  private gamificationLeaderboardTime: typeof GamificationLeaderboardTime = GamificationLeaderboardTime;

  private appUser: AppUserModel = AppUserModel.getInstance();

  private selectedTeamIds: number[] = [this.userTeamId];
  private selectedTime: number = GamificationLeaderboardTime.ALL;
  private date: Date = new Date();

  get isCustomMonth(): boolean {
    return this.selectedTime == GamificationLeaderboardTime.MONTH;
  }

  get hasViewTeamGamification(): boolean {
    return AppUserModel.getInstance().rights.indexOf(RIGHTS.VIEW_TEAM_GAMIFICATION.identifier) >= 0;
  }

  get availableTeams(): ITeamOutlineDto[] {
    return AppUserModel.getInstance().shareableTeams;
  }

  get availableTeamIds(): number[] {
    return this.availableTeams.map(team => team.teamID);
  }

  get userTeamId(): number {
    return this.appUser.teamID;
  }

  get userRanking(): number {
    return this.appUser.gamificationState.ranking;
  }

  get userIsInTop3(): boolean {
    return this.userRanking <= 3;
  }

  get leaderBoard(): IGamificationLeaderBoardUserDto[] {
    return this.appUser.gamificationLeaderboard
  }

  get leaderBoardTop3(): IGamificationLeaderBoardUserDto[] {
    return this.leaderBoard.slice(0, 3);
  }

  get leaderBoardUser(): IGamificationLeaderBoardUserDto | undefined {
    return this.leaderBoard.find(user => user.userID === this.appUser.ID);
  }

  private async onTeamsChanged() {
    await this.loadContent();
  }

  private async onTimeChanged() {
    await this.loadContent();
  }

  private async onDateChanged() {
    await this.loadContent();
  }

  private async loadContent() {
    this.currentState = GamificationLeaderboardState.LOADING;
    let hasSucceeded: boolean;
    if (this.selectedTime === GamificationLeaderboardTime.ALL) {
      hasSucceeded = await gamificationController.getLeaderBoard(this.selectedTeamIds);
    } else {
      hasSucceeded = await gamificationController.getLeaderBoard(this.selectedTeamIds, this.date.getFullYear(), this.date.getMonth());
    }
    if (hasSucceeded) {
      this.currentState = GamificationLeaderboardState.LOADED;
    } else {
      this.currentState = GamificationLeaderboardState.ERROR_LOADING;
    }
  }

  public async mounted() {
    if (this.startDate) {
      this.date = this.startDate;
      this.selectedTime = GamificationLeaderboardTime.MONTH;
    }
    await this.loadContent();
  }

}
</script>

