export enum DataProviderEntryViewStyle //
{
    TABS = "TABS",
    ACCORDEON = "ACCORDEON",
    ANCHOR_LIST = "ANCHOR_LIST",
    COLUMNS = "COLUMNS"
}

export enum DataProviderEntryType
{
    SINGLE_ITEM = "SINGLE_ITEM",
    ITEM_LIST = "ITEM_LIST"
}


export enum DataItemViewStyle     //when clicking the collapsed item(title): do you want to view/edit the item:
{
    INLINE = "INLINE",
    NEW_COLUMN = "NEW_COLUMN", //(like mac finder)
    NEW_WINDOW = "NEW_WINDOW",
}

export enum PropertyType
{
    // ==== primitives
    ONOFF = "ONOFF",
    TEXT = "TEXT",
    NUMBER = "NUMBER",
    DATE = "DATE",

    ASSET_FILE = "ASSET_FILE",
    COLOR = "COLOR",
    SYSTEM = "SYSTEM",

    FIXED_LIST = "FIXED_LIST", //a list of fixed items defined in the data schema

    // ==== NON primitives
    EXTERNAL_LIST = "EXTERNAL_LIST",  //a list to select from (like a dropdown or radio button group) coming from a external data source (this source returns a list of object with an __id and a name), only the selected ID(s) are stored in the dataprovider
    SELECT_LIST = "SELECT_LIST",  //a list to select from (like a dropdown or radio button group), coming from another dataprovider entry
    //SELECT_DICTIONARY //need for?

    SINGLE_ITEM = "SINGLE_ITEM",  //an ITEM is like a "single item list", it has its own definition
    //and makes N-dimensional data possible (an item can have an item property, which can have an item property, which...)
    //an item can also have a valueprovider, which makes the prop readonly
    ITEM_LIST = "ITEM_LIST", //a list of items defined by a definition that the user creates/deletes by clicking a add/delete button
    ITEM_DICTIONARY = "ITEM_DICTIONARY", // a dictionary of items (who's value can be of any datatype), accessible through a key (__reference) from another dataprovider
    //MIXED_ITEM_LIST = "MIXED_ITEM_LIST", //a list of items defined by a one or more definitions: need for?

    DATAPROVIDER_LINK = "DATAPROVIDER_LINK",  //a link to a whole dataprovider

    CONTENT_LINK = "CONTENT_LINK",  //a link to a piece of content
}


export enum InValidType
{
    REQUIRED_IN_AT_LEAST_ONE_LANGUAGE = "REQUIRED_IN_AT_LEAST_ONE_LANGUAGE",

    FILL_IN_MIN_CHARS = "FILL_IN_MIN_CHARS", //TEXT, will set isRequired = true (or when isRequired = true in schema, min chars will be set to 1)
    FILL_IN_MAX_CHARS = "FILL_IN_MAX_CHARS", //TEXT,
    NEED_EMAIL_FORMAT = "NEED_EMAIL_FORMAT", //TEXT,

    FILL_IN_NUMBER = "FILL_IN_NUMBER", //NUMBER, when isRequired = true

    FILL_IN_DATE = "FILL_IN_DATE", //NUMBER, when isRequired = true

    CHOOSE_A_FILE = "CHOOSE_A_FILE", //ASSET_FILE, when isRequired = true

    SELECT_A_COLOR = "SELECT_A_COLOR", //COLOR, when isRequired = true

    MAKE_A_SELECTION = "MAKE_A_SELECTION", //SELECT_LIST, when isRequired = true and isMultipleSelect = false

    MAKE_MIN_SELECTION = "MAKE_MIN_SELECTION", //SELECT_LIST, when isRequired = true and isMultipleSelect = true
    MAKE_MAX_SELECTION = "MAKE_MAX_SELECTION", //SELECT_LIST, when isRequired = true and isMultipleSelect = true

    CREATE_MIN_ITEMS = "CREATE_MIN_ITEMS", //ITEM_LIST, will set isRequired = true (or when isRequired = true in schema, minItems will be set to 1)
    CREATE_MAX_ITEMS = "CREATE_MAX_ITEMS", //ITEM_LIST

    REQUIRED_DICTIONARY = "REQUIRED_DICTIONARY", //ITEM_DICTIONARY, when no uri has yet been chosen

    REQUIRED_DATAPROVIDER = "REQUIRED_DATAPROVIDER", //DATAPROVIDER_LINK, when no dtp has yet been chosen

    REQUIRED_CONTENT = "REQUIRED_CONTENT", //CONTENT_LINK, when no contentID has yet been chosen

}

export enum FormElement
{
    LABEL = "LABEL",
    CHECKBOX = "CHECKBOX",
    DATE_PICKER = "DATE_PICKER",
    TEXT_INPUT = "TEXT_INPUT",
    TEXT_AREA = "TEXT_AREA",
    RICH_TEXT = "RICH_TEXT",
    NUMBER_INPUT = "NUMBER_INPUT",
    SLIDER = "SLIDER",
    LISTBOX = "LISTBOX",
    COMBOBOX = "COMBOBOX",
    CHECKBOX_GROUP = "CHECKBOX_GROUP",
    RADIOBUTTON_GROUP = "RADIOBUTTON_GROUP",
    ASSET_FILE_INPUT = "ASSET_FILE_INPUT",
    COLOR_PICKER = "COLOR_PICKER",
}


export enum StringDataFormat
{
    PASSWORD = "PASSWORD",
    EMAIL = "EMAIL",
    URI = "URI",
    IP_ADDRESS = "IP_ADDRESS",
    CUSTOM_FUNCTION = "CUSTOM_FUNCTION",
}

export enum DataSchemaScope
{
    PROJECT = "projectSchema",
    GLOBAL = "globalSchema",
}

export enum DataResourceScope
{
    CURRENT,     //it is available in the current dp/schema
    PROJECT,      //it resides in the project storage
    GLOBAL        //it resides in the global storage
}


export enum DataProviderViewMode
{
    PAGE,
    PICKER,
    EDITOR
}

export enum Delta
{
    IDLE,
    INITIAL,
    CHANGED,
    NEW,
    NEW_CHANGED
}

