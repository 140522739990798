<template>
  <div class="box box--full-height box--scrollable share-detail">

    <transition name="fade">
      <div v-if="!share"
           class="empty-message">
        {{ $t('DetailPageShareDetailPlaceholderMessage') }}
      </div>
    </transition>

    <transition name="fade">
      <div v-if="share" :key="shareId" class="box-wrapper">

        <div class="share-detail__title mt-1">
          <h4 class="">{{ contentName }}</h4>
          <b-button :disabled="!online" variant="outline-danger" size="sm" @click="_onStopShareBtnClick">{{
              $t('DashboardSharedRemoveShareButtonText')
            }}
          </b-button>
        </div>

        <div class="share-detail__date">
          <h6 class="mt-1 mb-3 font-weight-normal"
              v-html="$t('DashboardSharedShareDetailsSubTitlePresentation', [audienceName, $d(new Date(shareDate), 'short')])"></h6>
          <div v-if="isEntity">
            <div class="d-flex align-items-center flex-wrap mb-2">
              <b-form-checkbox @input="_onFormInput" v-model="share.doesExpire" class="mr-3" :disabled="!online">{{
                  $t('DashboardShowShareExpiryDate')
                }}
              </b-form-checkbox>
              <date-picker v-model="expirationDate" @input="_onFormInput"
                           :disabled="!share.doesExpire"></date-picker>
            </div>
            <b-form-checkbox @input="_onFormInput" class="mb-2" v-model="share.notifyViewed" :disabled="!online">{{
                $t('DashboardShowShareNotifyWhenViewed')
              }}
            </b-form-checkbox>
            <b-form-checkbox @input="_onFormInput" v-model="share.isShownInFolders" class="mb-2" :disabled="!online">{{
              $t('ShowContentInItsFolders')
              }}
            </b-form-checkbox>
          </div>
        </div>

        <div class="share-detail__text" v-if="isEntity && share.communication">
          <h6 class="mb-3 font-weight-normal" v-html="$t('DashboardSharedShareCommunicationTitle', [audienceName])"></h6>
          <div class="sub-box">{{ share.communication.payload.body }}</div>
        </div>

      </div>
    </transition>


  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import DatePicker from 'vue2-datepicker';
import {IEntityAudienceShareOutlineDto} from "@/entity/_model/entity.dto";
import SelectionState from "@/__libs/_model/SelectionState";
import AudienceModel from "@/audience/_model/AudienceModel";
import AudienceListModel from "@/audience/_model/AudienceListModel";
import languageManager from "@/__libs/language_manager/LanguageManager";
import Util from "@/__libs/utility/Util";
import contentController from "@/content/_controller/ContentController";
import ContentQuickLaunchItemRenderer from "@/content/_view/ContentQuickLaunchItemRenderer.vue";
import ContentOverviewItemRenderer from "@/content/_view/ContentOverviewItemRenderer.vue";
import {IAudienceFileDto} from "@/audience/_model/audience.dto";
import FileUtil from "@/__libs/utility/FileUtil";
import audienceController from "@/audience/_controller/AudienceController";
import NetworkManager from "@/_controller/NetworkManager";


@Component({
    components: {
        DatePicker
    }
})
export default class ShareDetail extends Vue {

    @Prop() private selState!: SelectionState<IEntityAudienceShareOutlineDto | IAudienceFileDto>

    public networkManager: NetworkManager = NetworkManager.getInstance();

    get online() {
        return this.networkManager.online;
    }

    get isEntity() {
        return (this.share as IEntityAudienceShareOutlineDto).entityType;
    }

    get share(): IEntityAudienceShareOutlineDto | IAudienceFileDto | null {
        return this.selState.selected;
    }

    get shareId(): string {
        if (this.share) {
            if (this.isEntity) {
                return (this.share as IEntityAudienceShareOutlineDto).shareID as unknown as string;
            } else {
                return (this.share as IAudienceFileDto).fileName;
            }
        }
        return "";
    }

    get contentName() {
        if (this.share) {
            if (this.isEntity) {
                return languageManager.getTranslationForValue((this.share as IEntityAudienceShareOutlineDto).name, this.audienceLangCode)
            } else {
                return FileUtil.getFriendlyFileName((this.share as IAudienceFileDto).fileName);
            }
        }
        return "";
    }

    get expirationDate() {
        if (this.share && this.isEntity) {
            return new Date((this.share as IEntityAudienceShareOutlineDto).expirationDate);
        }
        return new Date();
    }

    set expirationDate(p_date: Date) {
        if (this.share && this.isEntity) {
            (this.share as IEntityAudienceShareOutlineDto).expirationDate = p_date;
        }
    }

    get shareDate() {
        if (this.share) {
            if (this.isEntity) {
                return (this.share as IEntityAudienceShareOutlineDto).shareDate;
            } else {
                return (this.share as IAudienceFileDto).createdDate;
            }
        }
        return new Date();
    }

    get activeAudience(): AudienceModel | null {
        return AudienceListModel.getInstance().globalSelState.selected;
    }

    get audienceLangCode(): string | null {
        return (this.activeAudience) ? this.activeAudience.langCode : null;
    }

    get audienceName(): string | null {
        return (this.activeAudience) ? this.activeAudience.displayName : "?";
    }

    private _onFormInput(p_e: Event) {
        if (this.share) {
            contentController.updateAudienceShare((this.share as IEntityAudienceShareOutlineDto));
        }
    }

    private async _onStopShareBtnClick() {
        if (this.share && this.activeAudience) {
            const deleteConfirmed: boolean = await this.$bvModal.msgBoxConfirm(this.$t('DashboardShowStopSharingConfirmationMessage') as string, {centered: true});

            if (deleteConfirmed) {
                let isDeleted: boolean = false;
                if (this.isEntity) {
                    isDeleted = await contentController.deleteAudienceShare((this.share as IEntityAudienceShareOutlineDto), this.activeAudience);

                } else {
                    isDeleted = await audienceController.deleteFile((this.share as IAudienceFileDto), this.activeAudience);
                }

                if (isDeleted) {
                    this.selState.selected = null;
                }
            }
        }
    }

}
</script>
