<template>
  <div class="gamification-rulebook-box">
    <div class="gamification-rulebook-box__content px-5 py-3" v-html="$t('GamificationRulebookText', [displayName])"></div>
  </div>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import AppUserModel from "@/project/user/_model/AppUserModel";

@Component({
  components: {}
})
export default class GamificationRulebookBox extends Vue {

  private appUser: AppUserModel = AppUserModel.getInstance();

  get displayName():string {
    return this.appUser.displayName;
  }

}
</script>

