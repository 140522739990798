<template>

    <div class="aa-list-item share-with-teams-item d-flex align-items-center" :class="{ active: isSelected}" v-on:click="_onClick">
        <div class="share-with-teams-item__avatar">
            <AAProfilePicture :name="teamOutline.displayName" :imageUri="teamOutline.avatarFileUri"></AAProfilePicture>
        </div>
        <div class="share-with-teams-item__info flex-grow-1">
            <div v-line-clamp="1">{{ teamOutline.displayName }}</div>
        </div>
    </div>

</template>

<script lang="ts">
    import {Component, Prop, Vue} from "vue-property-decorator";

    import SelectionState from "@/__libs/_model/SelectionState";
    import {ITeamOutlineDto} from "@/team/_model/team.dto";
    import AAProfilePicture from "@/_view/components/AAProfilePicture.vue";

    @Component({
        components: {
            AAProfilePicture
        }
    })
    export default class ShareWithTeamsItemRenderer extends Vue
    {
        //---------------------------------
        // Vue Component props
        //---------------------------------

        @Prop() public teamOutline!:ITeamOutlineDto;

        @Prop() private selState!:SelectionState<ITeamOutlineDto>;

        //---------------------------------
        // Vue Component data
        //---------------------------------

        //---------------------------------
        // Vue Computed properties
        //---------------------------------

        get isSelected()
        {
            return this.selState.selected === this.teamOutline;
        }

        //---------------------------------
        // Public / lifecycle methods
        //---------------------------------

        //---------------------------------
        // Private / helper methods
        //---------------------------------

        public _onClick(p_e:Event)
        {
            this.$emit("onClick", this.teamOutline);
        }

    }
</script>
