<script lang="ts">
    import {Component, Mixins, Prop, Vue, Watch} from 'vue-property-decorator';
    import {
        IAnalyticsActionCfgDto,
        IAnalyticsActionEvolutionGraphWidgetCfgDto,
        IAnalyticsActionEvolutionGraphWidgetDataDto,
        IAnalyticsGraphDatePointDto,
        IAnalyticsSubjectDto,
        IAnalyticsSubjectListWithActionsWidgetCfgDto,
        IAnalyticsSubjectListWithActionsWidgetDataDto,
        IAnalyticsUsageOverviewGraphDto
    } from "@/analytics/_model/analytics.dto";
    import languageManager, {IMultiLangString} from "@/__libs/language_manager/LanguageManager";
    import AppUserModel from "@/project/user/_model/AppUserModel";
    import {
        ANALYTICS_LINE_GRAPH_OPTIONS,
        LINE_DATASET_COLORS,
        AnalyticsValueDisplayType
    } from "@/analytics/_model/analytics.constants";
    import {Line} from "vue-chartjs";
    import ChartJsPluginDataLabels from 'chartjs-plugin-datalabels';
    import {ChartDataSets, ChartOptions} from "chart.js";
    import AnalyticsModel from "@/analytics/_model/AnalyticsModel";
    import AssetFolderModel from "@/asset_folder/_model/AssetFolderModel";
    import assetFolderController from "@/asset_folder/_controller/AssetFolderController";
    import i18n from "@/__plugins/i18n";

    @Component({
        components: {ChartJsPluginDataLabels}
    })
    export default class AnalyticsUsageOverviewGraph extends Mixins(Line)
    {

        //---------------------------------
        // Vue Component props
        //---------------------------------

        @Prop() private usageOverviewDto!:IAnalyticsUsageOverviewGraphDto;

        //---------------------------------
        // Vue Component data
        //---------------------------------

        private isMounted:boolean = false;

        //---------------------------------
        // Vue Computed properties
        //---------------------------------



        //---------------------------------
        // Public / lifecycle methods
        //---------------------------------

        public mounted()
        {
            this.isMounted = true;
            this._renderChart();
        }



        //---------------------------------
        // Private / helper methods
        //---------------------------------

        private _renderChart()
        {
            const options:ChartOptions = {
                ...ANALYTICS_LINE_GRAPH_OPTIONS, scales: {
                    xAxes: [{
                        type        : 'time',
                        distribution: 'linear',
                        gridLines   : {
                            display: false
                        },
                        ticks       : {
                            maxTicksLimit: 20,
                            min          : AnalyticsModel.getInstance().range[0],
                            max          : AnalyticsModel.getInstance().range[1]
                        }
                    }],
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                            stepSize   : 1
                        }
                    }],
                }
            };

            const dataSets:ChartDataSets[] = [];
            let colorIndex:number = 0;
            for (let action in this.usageOverviewDto)
            {
                if (this.usageOverviewDto.hasOwnProperty(action))
                {
                    const points:IAnalyticsGraphDatePointDto[] = (this.usageOverviewDto as any)[action];
                    const dataSet:ChartDataSets = {
                        label      : i18n.tc(action),
                        data       : points,
                        borderColor: LINE_DATASET_COLORS[Math.min(colorIndex, LINE_DATASET_COLORS.length - 1)]
                    };
                    colorIndex++;
                    dataSets.push(dataSet);
                }
            }

            this.renderChart({datasets: dataSets}, options)
        }


        @Watch('usageOverviewDto', {immediate: true, deep: false})
        private _onUsageOverviewDtoChange()
        {
            if(this.isMounted)
            {
                this._renderChart();
            }
        }


    }
</script>

