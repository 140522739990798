<template>
  <div class="main-status" v-if="appUser">
    <AAProfilePicture @click.native="gotoAppUserPage" :class="{ disabled: !online }" :name="appUser.displayName" :imageUri="appUser.avatarFileUri" v-b-tooltip.hover.top :title="$t('MainMenuProfileSettings')"></AAProfilePicture>
    <SyncInternetWidget :small="true" :class="{ disabled: !online }" @click.native="gotoAppUserPage"></SyncInternetWidget>
    <SyncStatusWidget :small="true" :class="{ disabled: !online }" @click.native="gotoAppUserPage"></SyncStatusWidget>
    <RoadModeToggle></RoadModeToggle>
  </div>
</template>

<script lang="ts">
import {Component, Vue} from "vue-property-decorator";
import AAImage from "@/_view/components/AAImage.vue";
import {ISplashDto} from "@/_model/app.dto";
import AppModel from "@/_model/AppModel";
import AppUserModel from "@/project/user/_model/AppUserModel";
import AAProfilePicture from "@/_view/components/AAProfilePicture.vue";
import {RoutingIdentifier} from "@/router";
import SyncStatusWidget from "@/sync/_view/SyncStatusWidget.vue";
import SyncInternetWidget from "@/sync/_view/SyncInternetWidget.vue";
import NetworkManager from "@/_controller/NetworkManager";
import RoadModeToggle from "@/sync/_view/RoadModeToggle.vue";

@Component({
  components: {RoadModeToggle, AAProfilePicture, SyncStatusWidget, SyncInternetWidget}
})
export default class MainStatus extends Vue {

  private routingIdentifiers = RoutingIdentifier;
  public appUser: AppUserModel = AppUserModel.getInstance();
  public networkManager: NetworkManager = NetworkManager.getInstance();

  get online() {
    return this.networkManager.online;
  }

  private gotoAppUserPage() {
    if (this.$router.currentRoute.name !== RoutingIdentifier.APP_USER) {
      this.$router.push({name: RoutingIdentifier.ACCOUNT});
    }
  }

}
</script>
