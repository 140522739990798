
export enum GamificationTargetIdentifier
{
    LOGIN = "LOGIN",
    PRESENT_CONTENT = "PRESENT_CONTENT",
    PRESENT_PRESENTATION = "PRESENT_PRESENTATION",
    PRESENT_CONTENT_APP = "PRESENT_CONTENT_APP",
    PRESENT_CONTENT_FILE = "PRESENT_CONTENT_FILE",
    CREATE_AUDIENCE = "CREATE_AUDIENCE",
    SHARE_WITH_AUDIENCE = "SHARE_WITH_AUDIENCE"
}

