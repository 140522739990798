<template>
  <div class="import-audience">
    <transition name="fade">
      <div v-if="currentFormState === formState.SEARCH_INPUT">
        <div class="mb-3">
          <h5 class="mb-0">{{ $t('ImportFrom') }}</h5>
          <small class="import-audience__latest" v-if="hasLatestImport"
                 v-html="$t('LatestImportSince', [currentCrmProvider, $d(new Date(latestImportDate), 'short')])"></small>
        </div>
        <div class="import-audience__crms mb-4">
          <div class="import-audience__crms__crm" v-for="crmProvider in availableCrmProviders" :key="crmProvider"
               :class="{'active': crmProvider === currentCrmProvider}"
               @click="_selectProvider(crmProvider)">
            <img :src="'img/crm/' + crmProvider + '.png'"/>
          </div>
        </div>

        <div class="import-audience__filters mb-4">
          <h5 class="mb-3">{{ $t('AudienceImportFilter') }}</h5>
          <div class="sub-box mb-3">
            <b-form-group>
              <b-form-radio v-model="latestSearchByType" name="search-type" :value="byType.DATE">
                {{ $t('AudienceImportFilterDate') }}
              </b-form-radio>
              <b-form-radio v-model="latestSearchByType" name="search-type" :value="byType.NAME">
                {{ $t('AudienceImportFilterName') }}
              </b-form-radio>
            </b-form-group>
            <div v-if="latestSearchByType === byType.DATE">
              <small>{{ $t('ListContactsSince') }}</small>
              <div class="import-audience__filters__filter mb-2">
                <date-picker v-model="fromDate" class="flex-grow-1" :clearable="true"></date-picker>
                <b-button variant="primary" @click="_doSearch(byType.DATE)">{{
                    $t('AudienceImportFilterDate')
                  }}
                </b-button>
              </div>
            </div>
            <!--          <small>{{ $t('Or') }}</small>-->
            <div v-if="latestSearchByType === byType.NAME">
              <small>{{ $t('SearchByName') }}</small>
              <div class="import-audience__filters__filter mb-2">
                <input type="text" v-model="searchInput" class="form-control flex-grow-1">
                <b-button variant="primary" :disabled="searchInput.length < 2" @click="_doSearch(byType.NAME)">
                  {{ $t('AudienceImportFilterName') }}
                </b-button>
              </div>
            </div>
          </div>
          <b-form-checkbox @input="_onOnlyMyOwnContactClick" v-model="onlyShowMyOwnContacts">
            {{ $t('OnlyShowMyOwnContacts') }}
          </b-form-checkbox>
          <!--          <b-button @click="_resetFilter"><i class="fa-solid fa-arrow-rotate-right mr-2 fa-lg"></i>{{ $t('AudienceImportFilterReset') }}</b-button>-->
        </div>

        <hr>

        <div class="import-audience__result mb-3">
          <h5 class="mb-3">{{ $t('AudienceImportResult') }}</h5>
          <div v-if="isSearching" class="empty-loader">
            <b-spinner variant="dark" class="my-5"></b-spinner>
          </div>
          <div v-else>
            <div v-if="contacts.length === 0" class="empty-message">
              {{ $t('NoContactsFound') }}
            </div>
            <div v-else>
              <b-form-checkbox @input="_onSelectAllClick" v-model="allSelected" class="mb-3">
                <strong>{{ $t('AudienceImportSelectAll') }}</strong></b-form-checkbox>
              <div class="import-audience__result__files">
                <b-form-checkbox v-for="contact in contacts" :key="contact.remoteIdentifier"
                                 :value="contact.remoteIdentifier" @input="_onContactClick"
                                 v-model="selectedContacts" class="mr-3 mb-1">
                  {{ contact.displayName }}<strong v-if="contact.companyName"> ({{
                    contact.companyName
                  }})</strong><small
                    class="existing"
                    v-if=" contact.isExisting">({{ $t('ContactExists') }})</small>
                </b-form-checkbox>
              </div>
            </div>
          </div>
          <div v-if="contacts.length > 0">
            <hr>
            <b-form-checkbox v-model="alsoImportContactAccounts" class="mt-3">
              <strong>{{ $t('AlsoImportContactAccounts') }}</strong>
            </b-form-checkbox>
          </div>
        </div>

        <div class="d-flex justify-content-end">
          <button class="btn btn-secondary mr-2" @click="_onCancelBtnClick">{{ $t('Cancel') }}</button>
          <button v-if="contacts.length > 0" class="btn btn-primary" :disabled="selectedContacts.length === 0"
                  @click="_onImportAudiencesBtnClick">
            {{ $t('ImportSelectedContacts') }}
          </button>
        </div>
      </div>
    </transition>

    <transition name="fade">
      <div v-if="currentFormState === formState.IMPORTING_AUDIENCES">
        <div class="text-center mt-5 mb-3">
          <b-spinner variant="dark"></b-spinner>
        </div>
        <div class="text-center mb-5 px-3">{{ $t('ImportingContacts') }}</div>
        <div class="d-flex justify-content-end">
          <button class="btn btn-primary" @click="_onCancelBtnClick">
            {{ $t('Ok') }}
          </button>
        </div>
      </div>
    </transition>

    <transition name="fade">
      <div v-if="currentFormState === formState.AUDIENCES_IMPORTED">
        <div class="success-message">{{ $t('ContactsImported') }}</div>
        <div class="d-flex justify-content-end">
          <button class="btn btn-primary" @click="_onCancelBtnClick">{{ $t('Ok') }}</button>
        </div>
      </div>
    </transition>

    <transition name="fade">
      <div v-if="currentFormState === formState.IMPORT_ERROR" class="error-message">{{
          $t('AudienceImportFailed')
        }}
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import {CrmProvider} from "@/crm/_model/crm.constants";
import CrmModel from "@/crm/_model/CrmModel";
import {ICrmContactDto, ICrmProviderDto, ICrmUserDto} from "@/crm/_model/crm.dto";
import AppUserModel from "@/project/user/_model/AppUserModel";
import DatePicker from "vue2-datepicker";
import crmController from "@/crm/_controller/CrmController";

enum FormState {
    SEARCH_INPUT,
    IMPORTING_AUDIENCES,
    AUDIENCES_IMPORTED,
    IMPORT_ERROR
}

enum SearchByType {
    DATE,
    NAME,
}

@Component({
    components: {DatePicker}
})
export default class ImportAudiencesBox extends Vue {
    public formState: typeof FormState = FormState;
    public byType: typeof SearchByType = SearchByType;
    public currentFormState: FormState = FormState.SEARCH_INPUT;
    public currentCrmProvider: CrmProvider = CrmProvider.XLS_IMPORT;
    public latestImportDate?: Date;
    public hasLatestImport: boolean = false;
    public fromDate?: Date = new Date();
    public searchInput: string = "";
    public onlyShowMyOwnContacts: boolean = true;
    public isSearching: boolean = false;
    public latestSearchByType: SearchByType = SearchByType.DATE;
    public contacts: ICrmContactDto[] = [];
    public selectedContacts: string[] = [];
    public allSelected: boolean = true;
    public alsoImportContactAccounts: boolean = true;

    get availableCrmProviders(): CrmProvider[] {
        return CrmModel.getInstance().getAvailableCrmProvidersForUser();
    }

    mounted() {
        this._selectProvider(this.availableCrmProviders[0]);
    }

    public _selectProvider(p_crmProvider: CrmProvider) {
        this.currentCrmProvider = p_crmProvider;
        this.selectedContacts = [];
        this.contacts = [];
        const crmProviderDto: ICrmProviderDto = AppUserModel.getInstance().project.crmProviders[this.currentCrmProvider];
        const crmUserDto: ICrmUserDto = AppUserModel.getInstance().crmProviders[this.currentCrmProvider];
        if (crmUserDto && crmUserDto.latestImportDate) {
            this.fromDate = this.latestImportDate = new Date(crmUserDto.latestImportDate);
            this.hasLatestImport = true;
        } else {
            this.fromDate = new Date(Date.now() - 12096e5);
            this.latestImportDate = undefined;
            this.hasLatestImport = false;
        }
        this.latestSearchByType = SearchByType.DATE;
        this._doSearch(this.latestSearchByType);
    }

    public async _doSearch(p_byType: SearchByType) {
        // this.latestSearchByType = p_byType;
        this.isSearching = true;
        this.contacts = [];
        if (p_byType === SearchByType.DATE) {
            this.searchInput = "";
            if (!this.fromDate) {
                this.fromDate = new Date(Date.now() - 12096e5);
            }
            this.contacts = await crmController.searchContacts(this.currentCrmProvider, this.onlyShowMyOwnContacts, undefined, this.fromDate);
        } else {
            // this.fromDate = undefined;
            this.contacts = await crmController.searchContacts(this.currentCrmProvider, this.onlyShowMyOwnContacts, this.searchInput);
        }
        this.setSelection(true);
        this.isSearching = false;
    }

    // public async _resetFilter(): Promise<void> {
    //     this.fromDate = undefined;
    //     this.searchInput = "";
    //     await this._doSearch(SearchByType.NAME);
    // }

    public async _onImportAudiencesBtnClick(p_e: Event) {
        this.currentFormState = FormState.IMPORTING_AUDIENCES;
        const hasSucceeded: boolean = await crmController.doBackendImport(this.currentCrmProvider, this.selectedContacts, this.alsoImportContactAccounts);
        if (hasSucceeded) {
            this.currentFormState = FormState.AUDIENCES_IMPORTED;
        } else {
            this.currentFormState = FormState.IMPORT_ERROR;
        }
    }

    public async _onCancelBtnClick(p_e: Event) {
        this.$emit('onFinish', null);
    }

    public setSelection(p_selectAll: boolean = true) {
        const selectedContacts: string[] = [];
        if (p_selectAll) {
            for (let i = 0; i < this.contacts.length; i++) {
                selectedContacts.push(this.contacts[i].remoteIdentifier!)
            }
        }
        this.selectedContacts = selectedContacts;
        this.allSelected = p_selectAll;
    }

    public async _onOnlyMyOwnContactClick(p_e: Event) {
        if (this.fromDate || this.searchInput) {
            this._doSearch(this.latestSearchByType);
        }
    }

    public async _onSelectAllClick(p_e: Event) {
        this.setSelection(this.allSelected);
    }

    public async _onContactClick(p_e: Event) {
        if (this.selectedContacts.length === this.contacts.length) {
            this.allSelected = true;
        } else if (this.selectedContacts.length === 0) {
            this.allSelected = false;
        }
    }

}
</script>
