<template>
    <div class="analytics-presentation-evolution">

        <h5>{{ $t('AnalyticsPresentationEvolution') }}</h5>

        <AnalyticsPresentationEvolutionGraph></AnalyticsPresentationEvolutionGraph>

    </div>
</template>


<script lang="ts">
    import {Component, Vue} from 'vue-property-decorator';
    import AnalyticsModel from "@/analytics/_model/AnalyticsModel";
    import AnalyticsUserEvolutionGraph from "@/analytics/_view/AnalyticsUserEvolutionGraph.vue";
    import AnalyticsPresentationEvolutionGraph from "@/analytics/_view/AnalyticsPresentationEvolutionGraph.vue";

    @Component({
        components: { AnalyticsPresentationEvolutionGraph },
    })
    export default class AnalyticsPresentationEvolution extends Vue
    {
        //---------------------------------
        // Vue Component props
        //---------------------------------

        //---------------------------------
        // Vue Component data
        //---------------------------------

        //---------------------------------
        // Vue Computed properties
        //---------------------------------

        //---------------------------------
        // Public / lifecycle methods
        //---------------------------------

        //---------------------------------
        // Private / helper methods
        //---------------------------------

    }
</script>
