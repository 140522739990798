import {IContentFolderBodyDto, IContentFolderDto} from "@/content/_model/content.dto";
import {LanguageManager} from "@/__libs/language_manager/LanguageManager";
import Model from "@/__libs/_model/Model";
import ContentModel from "@/content/_model/ContentModel";
import ContentFolderListModel from "@/content/_model/ContentFolderListModel";
import {BreadCrumbType} from "@/content/_model/content.constants";
import {DtoType} from "@/_model/app.constants";
import {IModelDto} from "@/__libs/_model/model.dto";
import AppUserModel from "@/project/user/_model/AppUserModel";
import {RightAction, RIGHTS} from "@/team/_model/role.constants";
import {ENTITY_CONFIG, EntityType} from "@/entity/_model/entity.constants";
import ContentAppModel from "@/content_app/_model/ContentAppModel";
import EntityModel from "@/entity/_model/EntityModel";


export default class ContentFolderModel extends Model implements IContentFolderDto
{

    public folderID:number = 0;
    public createdDate:Date = new Date();
    public createdByUserID:number = 0;
    public createdByTeamID:number = 0;
    public parentFolderID:number | null = null;
    public viewableTeams?:number[];
    public body:IContentFolderBodyDto = {name: {}, portalName:{}, poster: {}, notes: {}};
    public userName:string = "";
    public teamName:string = "";

    public childFolders:ContentFolderModel[] = [];
    public childContents:ContentModel[] = [];

    private _contentFolderListModel:ContentFolderListModel;

    public activeBreadCrumbType:BreadCrumbType = BreadCrumbType.ACTIVE;

    public isEditable:boolean = false;
    public hasChanges:boolean = false;

    constructor(p_listModel:ContentFolderListModel)
    {
        super();
        this._contentFolderListModel = p_listModel;
    }

    //---------------------------------
    // Public Methods
    //---------------------------------



    public addChildFolder(p_contentFolderModel:ContentFolderModel)
    {

    }


    public deleteContentFromFolder(p_content:ContentModel)
    {
        const index:number = this.childContents.indexOf(p_content);
        if (index >= 0)
        {
            this.childContents.splice(index, 1);
        }
    }

    public mapToDto(p_dtoType:DtoType):IContentFolderDto
    {
        const folderDto = {
            folderID      : this.folderID,
            viewableTeams : this.viewableTeams,
            parentFolderID: this.parentFolderID === 0 ? null : this.parentFolderID,
            body          : this.body
        } as IContentFolderDto;



        return folderDto;
    }


    public renameAsCopy()
    {
        LanguageManager.renameAsCopy(this.body.name);
    }

    public clearChildren()
    {
        this.childFolders = [];
        this.childContents = [];
    }

    public getAllChildFolders():ContentFolderModel[]
    {
        return this._recurseChildFolders(this, []);
    }

    private _recurseChildFolders(p_parentModel:ContentFolderModel, p_children_array:ContentFolderModel[])
    {
        for (let i = 0; i < p_parentModel.childFolders.length; i++)
        {
            const child:ContentFolderModel = p_parentModel.childFolders[i];
            p_children_array.push(child);
            this._recurseChildFolders(child, p_children_array);
        }
        return p_children_array;
    }


    public getAllShareableContentInFolder():ContentModel[]
    {
        const shareableContent:ContentModel[] = [];
        const childFolders:ContentFolderModel[] = this._recurseChildFolders(this, [this]);

        for (let i = 0; i < childFolders.length; i++)
        {
            const contentFolder = childFolders[i];
            for (let j = 0; j < contentFolder.childContents.length; j++)
            {
                const content = contentFolder.childContents[j];
                if(content.isShareableWithAudience)
                {
                    shareableContent.push(content);
                }
            }
        }
        return shareableContent;
    }


    private _showShareWithAudience(p_entity:EntityModel):boolean
    {
        const entityConfig = ENTITY_CONFIG[p_entity.entityType];
        const showShareWithAudience:boolean = AppUserModel.getInstance().rights.indexOf(RIGHTS.SHARE_ENTITIES_WITH_AUDIENCES.identifier) >= 0 &&
            entityConfig.canBeSharedWithAudiences &&
            p_entity.rightActions.indexOf(RightAction.SHARE_WITH_AUDIENCES) >= 0;
        if (p_entity.entityType === EntityType.CONTENT_APP)
        {
            return showShareWithAudience && (p_entity as ContentAppModel).canBeSharedWithAudiences;
        }
        return showShareWithAudience;
    }

}
