<template>
  <div class="flow-status-item" :style="{ left: statusPercentage }" :id="id">
    <img :src="statusIcon" class="flow-status-item__icon" />

    <b-tooltip :target="id" triggers="hover" custom-class="flow-status-item-tooltip">
      <div class="flow-status-item__popover">
        <h6 class="mb-0">{{ statusName }}</h6>
        <CoinAmount :amount="statusAmount"></CoinAmount>
      </div>
    </b-tooltip>

  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import UserInfo from "@/gamification/summary/_view/UserInfo.vue";
import UserCoins from "@/gamification/summary/_view/UserCoins.vue";
import UserBadge from "@/gamification/summary/_view/UserStatus.vue";
import UserRanking from "@/gamification/summary/_view/UserRanking.vue";
import UserStatus from "@/gamification/summary/_view/UserStatus.vue";
import {IGamificationProjectConfigDto} from "@/gamification/_model/gamification.dto";
import AppUserModel from "@/project/user/_model/AppUserModel";
import AudienceModel from "@/audience/_model/AudienceModel";
import GamificationUtil from "@/gamification/_controller/GamificationUtil";
import languageManager from "@/__libs/language_manager/LanguageManager";
import CoinAmount from "@/gamification/_view/CoinAmount.vue";

@Component({
  components: {CoinAmount}
})
export default class FlowStatusItem extends Vue {

  @Prop() public statusIndex!: number;

  private appUser: AppUserModel = AppUserModel.getInstance();

  get id(): string {
    return `flow-status-item-${this.statusIndex}`;
  }

  get statusIcon(): string {
    return GamificationUtil.getStatusIcon(this.statusIndex);
  }

  get statusName(): string {
    return languageManager.getTranslationForValue(GamificationUtil.getStatusName(this.statusIndex, this.appUser.project.config.gamification.status));
  }

  get statusAmount(): number {
    return GamificationUtil.getStatusAmount(this.statusIndex, this.appUser.project.config.gamification.status);
  }

  get statusPercentage(): string {
    return `${GamificationUtil.getStatusPercentage(this.statusIndex, this.appUser.project.config.gamification.status)}%`;
  }

}
</script>

