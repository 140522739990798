<template>
  <div class="create-content-box">

    <transition name="fade">
      <div v-if="currentFormState === formState.TYPE_CHOICE">
        todo: choose type when we have multiple types
      </div>
    </transition>

    <!-- This is the new way: We are going to make a Component per type, this is dirty and buggy -->
    <transition name="fade">
      <div v-if="currentFormState === formState.CREATE_CONTENT_APP">
        <CreateContentAppBox @cancel="_onCancelBtnClick" @created="_onAppCreated"></CreateContentAppBox>
      </div>
    </transition>

    <transition name="fade">
      <div
          v-if="currentFormState === formState.CREATE_PRESENTATION || currentFormState === formState.TRY_SUBMIT">
        <b-form @submit.stop.prevent="_onCreateBtnClick" novalidate>

          <b-form-group :label="$t('DashboardShowAddPresentationNamePlaceholder')">
            <b-form-input v-model="contentName"
                          :state="_calculateInputState($v.contentName)"
                          tabindex="1"
                          autofocus></b-form-input>
            <b-form-invalid-feedback>
                        <span class="d-block"
                              v-if="!$v.contentName.required">{{ $t('FieldRequiredSimple') }}</span>
              <span class="d-block" v-if="!$v.contentName.minLength">{{ $t('MinimumFieldLengthSimple', [3]) }}</span>
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
              v-if="currentFormState === formState.CREATE_PRESENTATION || this.createContentType === entityType.PRESENTATION"
              :label="$t('PlaceholderConstruct')">
            <b-form-select v-model="construct">
              <option v-for="construct in availableConstructs" :key="construct.identifier"
                      :value="construct.identifier">{{ _getConstructName(construct) }}
              </option>
            </b-form-select>
          </b-form-group>

          <b-form-select v-else-if="currentFormState === formState.CREATE_CONTENT_APP"
                         v-model="contentAppResource" :options="availableContentAppResources"></b-form-select>

          <div class="modal-box-buttons">
            <b-button variant="secondary" @click="_onCancelBtnClick" tabindex="3">
              {{ $t('Cancel') }}
            </b-button>
            <b-button type="submit" variant="primary"
                      :disabled="currentFormState === formState.TRY_SUBMIT && $v.$invalid" tabindex="4">{{
                $t('EditorFeedback_PresentationSavedTitle')
              }}
            </b-button>
          </div>

        </b-form>
      </div>
    </transition>

    <transition name="fade">
      <div v-if="currentFormState === formState.CREATE_CONTENT_FILE" class="text-center">

        <h6 class="mt-2">{{ $t('ClickBrowse') }}</h6>

        <div class="mb-4">
          <label class="btn btn-primary">
            {{ $t('browse') }}
            <input type="file" ref="selectFileBtn" v-on:change="_onSelectFileBtn()" hidden>
          </label>
        </div>

        <h6>{{ $t('OrDragFileHere') }}</h6>

        <div class="create-content-box__drag-area" v-cloak @drop.prevent="_onDropFile" @dragover.prevent
             @dragover="_onDragOver" @dragleave="_onDragStop">
          <div class="create-content-box__drag-area__content">
            <i class="far fa-file-powerpoint fa-4x"></i>
            <i class="far fa-file-pdf fa-4x"></i>
            <i class="far fa-file-image fa-4x"></i>
          </div>
          <div class="aa-drop-area d-flex align-items-center justify-content-center"
               v-if="showDropIndication">
            <i class="fas fa-download fa-lg"></i>
          </div>
        </div>

        <div v-if="availableDmsProviders.length > 0" class="mt-4 mb-3">
          <h6>{{ $t('Or') }}</h6>
          <b-button v variant="primary" @click="_onImportContentFilesBtnClick">
            {{ $t('ImportFilesFromOtherSystem') }}
          </b-button>
        </div>

      </div>
    </transition>

    <transition name="fade">
      <div v-if="currentFormState === formState.LOADING" class="text-center">
        <div v-if="currentFormState === formState.LOADING" class="text-center">
          <b-spinner variant="dark" class="my-5"></b-spinner>
        </div>
      </div>
    </transition>

    <transition name="fade">
      <div v-if="currentFormState === formState.REMOTE_ERROR">
        <p class="mb-4 text-danger">{{ $t('ContentCreationFailed') }}</p>
        <div class="d-flex justify-content-end">
          <button class="btn btn-secondary mr-2" @click="_onCancelBtnClick">{{ $t('Cancel') }}</button>
          <button class="btn btn-primary" @click="_onCreateBtnClick">{{ $t('Retry') }}</button>
        </div>
      </div>
    </transition>

    <b-modal v-if="availableDmsProviders.length > 0 " id="import-content-files-box" size="lg"
             :title="$t('ImportFilesFromOtherSystem')" hide-footer>
      <ImportContentFilesBox v-on:onFinish="_ImportContentFilesFinish"></ImportContentFilesBox>
    </b-modal>

  </div>


</template>

<script lang="ts">

import {Component, Prop, Vue} from 'vue-property-decorator';
import fileSize from "filesize";
import {EntityType} from "@/entity/_model/entity.constants";
import PresentationModel from "@/presentation/_model/PresentationModel";
import AppUserModel from "@/project/user/_model/AppUserModel";
import EntityControllerFactory from "@/entity/_controller/EntityControllerFactory";
// validation
import {minLength, required} from 'vuelidate/lib/validators';
import {FileType} from "@/asset_folder/_model/asset_folder.constants";
import AssetFolderListModel from "@/asset_folder/_model/AssetFolderListModel";
import FileUtil from "@/__libs/utility/FileUtil";
import ContentFileModel from "@/content_file/_model/ContentFileModel";
import contentFileController from "@/content_file/_controller/ContentFileController";
import ContentAppModel from "@/content_app/_model/ContentAppModel";
import fileManager, {FileResponse} from "@/_controller/FileManager";
import ContentFolderListModel from "@/content/_model/ContentFolderListModel";
import {IContentAppResourceDto} from "@/content_app/resource/_model/content_app_resource.dto";
import CreateContentAppBox from "@/content_app/_view/CreateContentAppBox.vue";
import {IConstructDto} from "@/presentation/_model/presentation.dto";
import languageManager from "@/__libs/language_manager/LanguageManager";
import ImportContentFilesBox from "@/content_file/_view/ImportContentFilesBox.vue";


enum FormState {
    TYPE_CHOICE,
    CREATE_PRESENTATION,
    CREATE_CONTENT_FILE,
    CREATE_CONTENT_APP,
    TRY_SUBMIT,
    LOADING,
    REMOTE_CREATED,
    REMOTE_ERROR
}


@Component({
    computed: {
        EntityType() {
            return EntityType
        }
    },
    components: {CreateContentAppBox, ImportContentFilesBox},
    validations: {
        contentName: {
            required,
            minLength: minLength(3)
        }
    }
})
export default class CreateContentBox extends Vue {

    @Prop() public createContentType!: EntityType;

    private formState: typeof FormState = FormState;
    private entityType: typeof EntityType = EntityType;
    private currentFormState: FormState = FormState.CREATE_PRESENTATION;
    private contentName: string = ""; //todo
    private construct: string = AppUserModel.getInstance().project.constructs[0];
    private contentAppResource: string = AppUserModel.getInstance().project.contentAppResources[0];
    private showDropIndication: boolean = false;

    public availableConstructs: IConstructDto[] = [];


    get availableContentAppResources(): string[] {
        return AppUserModel.getInstance().project.contentAppResources;
    }

    get inputAccept(): string {
        return AssetFolderListModel.getExtensionsForFileTypes([]).join(', ');
    }

    get availableDmsProviders() {
        if (AppUserModel.getInstance().project.config.dmsProviders) {
            return AppUserModel.getInstance().project.config.dmsProviders
        }
        return []
    }

    public async mounted() {
        if (this.createContentType === EntityType.PRESENTATION) {
            this._loadConstructs();
        } else if (this.createContentType === EntityType.CONTENT_FILE) {
            this.currentFormState = FormState.CREATE_CONTENT_FILE;
        } else if (this.createContentType === EntityType.CONTENT_APP) {
            this.currentFormState = FormState.CREATE_CONTENT_APP;
        }
        // this.contentName = this.$t('DashboardShowNewButton').toString().toLowerCase();
    }

    private async _loadConstructs() {
        this.currentFormState = FormState.LOADING;
        this.availableConstructs = [];

        const constructIdentifiers: string[] = AppUserModel.getInstance().project.constructs;
        for (let i = 0; i < constructIdentifiers.length; i++) {

            const fileResponse: FileResponse = await fileManager.fetchFileFromCdn(`constructs/${constructIdentifiers[i]}/cfg.json`);
            if (fileResponse.hasSucceeded) {
                const construct: IConstructDto = fileResponse.result;
                construct.identifier = constructIdentifiers[i];
                this.availableConstructs.push(construct);
            } else {
                this.currentFormState = FormState.REMOTE_ERROR;
                return;
            }
        }
        this.currentFormState = FormState.CREATE_PRESENTATION;
    }

    private _getConstructName(p_construct: IConstructDto) {
        return p_construct.name ? languageManager.getTranslationForValue<string>(p_construct.name, AppUserModel.getInstance().langCode) : p_construct.identifier;
    }

    private _calculateInputState(inputValidator: any): any {
        return this.currentFormState === FormState.TRY_SUBMIT && inputValidator.$invalid ? false : null;
    }

    private async _onCreateBtnClick() {
        this.currentFormState = FormState.TRY_SUBMIT;

        if (!this.$v.$invalid) {
            if (this.createContentType) {
                if (this.createContentType === EntityType.PRESENTATION) {
                    const newPresentation: PresentationModel = new PresentationModel();
                    newPresentation.name[AppUserModel.getInstance().langCode] = this.contentName;
                    newPresentation.construct = this.construct;

                    this.currentFormState = FormState.LOADING;

                    const createSucceeded: boolean = await EntityControllerFactory.getListController(this.createContentType).createEntity(newPresentation, ContentFolderListModel.getInstance().activeContentFolder);

                    if (createSucceeded) {
                        this.$emit('onFinish', newPresentation);
                    } else {
                        this.currentFormState = FormState.REMOTE_ERROR;
                    }
                } else if (this.createContentType === EntityType.CONTENT_APP) {
                    const newContentApp: ContentAppModel = new ContentAppModel();
                    newContentApp.name[AppUserModel.getInstance().langCode] = this.contentName;
                    newContentApp.resourceIdentifier = this.contentAppResource;

                    this.currentFormState = FormState.LOADING;

                    const fileResponse: FileResponse = await fileManager.fetchFileFromCdn(`contentAppResources/${this.contentAppResource}/cfg.json`);
                    if (fileResponse.hasSucceeded) {
                        const appResource: IContentAppResourceDto = fileResponse.result;
                        newContentApp.appType = appResource.appType;
                        newContentApp.availableRuntimes = appResource.availableRuntimes;
                        newContentApp.canBeCopied = appResource.canBeCopied;
                        newContentApp.canBeCreated = appResource.canBeCreated;
                        newContentApp.canBeEdited = appResource.canBeEdited;
                        newContentApp.canBePrepared = appResource.canBePrepared;
                        newContentApp.canBePresented = appResource.canBePresented;
                        newContentApp.canBeTrained = appResource.canBeTrained;
                        newContentApp.canBeSharedWithAudiences = appResource.canBeSharedWithAudiences;
                        newContentApp.externalLaunchUrl = appResource.externalLaunchUrl;
                        newContentApp.hasCustomEditMode = appResource.hasCustomEditMode;
                        newContentApp.dataSchemaURI = appResource.dataSchemaURI;
                        newContentApp.poster[AppUserModel.getInstance().langCode] = appResource.defaultPoster;
                    } else {
                        this.currentFormState = FormState.REMOTE_ERROR;
                        return;
                    }

                    const createSucceeded: boolean = await EntityControllerFactory.getListController(this.createContentType).createEntity(newContentApp, ContentFolderListModel.getInstance().activeContentFolder);

                    if (createSucceeded) {
                        this.$emit('onFinish', newContentApp);
                    } else {
                        this.currentFormState = FormState.REMOTE_ERROR;
                    }
                }
            }
        }
    }

    private _onAppCreated(contentApp: ContentAppModel) {
        this.$emit('onFinish', contentApp);
    }

    private async _onCancelBtnClick() {
        this.$emit('onFinish', null);
    }

    private async _onImportContentFilesBtnClick() {
        this.$bvModal.show('import-content-files-box');
    }

    private _ImportContentFilesFinish() {
        this.$bvModal.hide('import-content-files-box');
    }

    private _onSelectFileBtn(p_e: Event) {
        const selectFileBtn: HTMLInputElement = this.$refs.selectFileBtn as HTMLInputElement;
        if (selectFileBtn.files && selectFileBtn.files.length > 0) {
            this.addFile(selectFileBtn.files[0]);
        }
    }

    private _onDropFile(e: DragEvent) {

        if (e.dataTransfer && e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            this.addFile(e.dataTransfer.files[0]);
        }
        this._onDragStop(e);

    }

    private async addFile(p_file: File) {
        if (await this.checkValidFileSelect(p_file)) {

            const newContentFile: ContentFileModel = new ContentFileModel();
            newContentFile.fileType = AssetFolderListModel.getFileTypeForExtension(FileUtil.getFileExtension(p_file.name));
            newContentFile.name[AppUserModel.getInstance().langCode] = FileUtil.getFriendlyFileName(p_file.name);
            newContentFile.poster[AppUserModel.getInstance().langCode] = AssetFolderListModel.getDefaultPreviewImageUriForFileType(newContentFile.fileType);
            newContentFile.size = p_file.size;

            this.currentFormState = FormState.LOADING;

            const createSucceeded: boolean = await EntityControllerFactory.getListController(this.createContentType).createEntity(newContentFile, ContentFolderListModel.getInstance().activeContentFolder);

            if (createSucceeded) {
                await contentFileController.setFile(newContentFile, p_file);
                this.$emit('onFinish', newContentFile);
            } else {
                this.currentFormState = FormState.REMOTE_ERROR;
            }

        }
    }

    private async checkValidFileSelect(p_file: File): Promise<boolean> {
        const fileType: FileType = AssetFolderListModel.getFileTypeForExtension(FileUtil.getFileExtension(p_file.name));
        if (fileType > FileType.UNKNOWN) {
            if (p_file.size > AppUserModel.getInstance().project.tierConfig.maxFileSize) {
                await this.$bvModal.msgBoxOk(this.$t('ErrorMaxSize', [fileSize(AppUserModel.getInstance().project.tierConfig.maxFileSize)]) as string, {centered: true});
                return false;
            }
            return true;
        } else {
            await this.$bvModal.msgBoxOk(this.$t('ErrorFileType', [this.inputAccept]) as string, {centered: true});
            return false;
        }
    }

    private _onDragOver(e: DragEvent) {

        this.showDropIndication = true;
    }

    private _onDragStop(e: DragEvent) {
        this.showDropIndication = false;
    }

    private _setInputAccept() {
        const selectFileBtn: HTMLInputElement = this.$refs.selectFileBtn as HTMLInputElement;
        if (selectFileBtn) {
            selectFileBtn.accept = this.inputAccept;
        }
    }


}
</script>
