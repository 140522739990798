<template>
    <div class=" team-item d-flex align-items-center">


        <div class="widget-item__info flex-grow-1">
            <h6>{{ widgetName}}</h6>

            <transition name="fade">
                <div v-if="currentLoadingState === loadingState.LOADING" class="text-center">
                    <b-spinner variant="dark" class="my-5"></b-spinner>
                </div>
            </transition>

            <transition name="fade">
                <div v-if="currentLoadingState === loadingState.ERROR_LOADING">
                    <p class="mb-4 text-danger">{{ $t('CouldntGetWidgetData') }}</p>
                </div>
            </transition>

            <transition name="fade">
                <div v-if="currentLoadingState === loadingState.LOADED">

                    <AnalyticsWidgetSubjectListWithActions
                            v-if="widgetCfg.widgetType === WidgetType.SUBJECT_LIST_WITH_ACTIONS"
                            :widgetCfg="widgetCfg"
                            :widgetData="widgetData"
                    />

                    <AnalyticsWidgetActionEvolutionGraph
                            v-if="widgetCfg.widgetType === WidgetType.ACTION_EVOLUTION_GRAPH"
                            :widgetCfg="widgetCfg"
                            :widgetData="widgetData"
                    />
                </div>
            </transition>


        </div>

    </div>
</template>

<script lang="ts">
    import {Component, Prop, Vue, Watch} from "vue-property-decorator";
    import {
        IAnalyticsSubjectTypeCfgDto,
        IAnalyticsWidgetCfgDto,
        IAnalyticsWidgetDataDto
    } from "@/analytics/_model/analytics.dto";
    import analyticsController from "@/analytics/_controller/AnalyticsController";
    import AnalyticsWidgetSubjectListWithActions
        from "@/analytics/_view/content/widget_types/AnalyticsWidgetSubjectListWithActions.vue";
    import {AnalyticsWidgetType} from "@/analytics/_model/analytics.constants";
    import languageManager, {IMultiLangString} from "@/__libs/language_manager/LanguageManager";
    import AppUserModel from "@/project/user/_model/AppUserModel";
    import AudienceModel from "@/audience/_model/AudienceModel";
    import AnalyticsModel from "@/analytics/_model/AnalyticsModel";
    import AnalyticsWidgetActionEvolutionGraph
        from "@/analytics/_view/content/widget_types/AnalyticsWidgetActionEvolutionGraph.vue";

    enum LoadingState
    {
        IDLE,
        LOADING,
        LOADED,
        ERROR_LOADING
    }


    @Component({
        components: {AnalyticsWidgetSubjectListWithActions, AnalyticsWidgetActionEvolutionGraph}
    })
    export default class AnalyticsWidgetItemRenderer extends Vue
    {
        //---------------------------------
        // Vue Component props
        //---------------------------------

        @Prop() public widgetCfg!:IAnalyticsWidgetCfgDto;


        //---------------------------------
        // Vue Component data
        //---------------------------------

        private WidgetType:typeof AnalyticsWidgetType = AnalyticsWidgetType;

        private loadingState:typeof LoadingState = LoadingState;

        private currentLoadingState:LoadingState = LoadingState.IDLE;

        private widgetData:IAnalyticsWidgetDataDto | null = null;




        //---------------------------------
        // Vue Computed properties
        //---------------------------------

        get widgetName()
        {
            if (typeof this.widgetCfg.name === 'string')
            {
                return this.$t(this.widgetCfg.name);
            }
            else
            {
                return languageManager.getTranslationForValue<string>(this.widgetCfg.name as IMultiLangString, AppUserModel.getInstance().langCode)
            }
        }

        get range()
        {
            return AnalyticsModel.getInstance().range;
        }

        //---------------------------------
        // Public / lifecycle methods
        //---------------------------------


        public mounted()
        {
        }


        //---------------------------------
        // Private / helper methods
        //---------------------------------


        @Watch('range', {immediate: true, deep: false})
        private async _getContentWidgetData()
        {
            this.currentLoadingState = LoadingState.LOADING;
            this.widgetData = await analyticsController.getContentWidgetData(this.widgetCfg.identifier);
            if (this.widgetData)
            {
                this.currentLoadingState = LoadingState.LOADED;
            }
            else
            {
                this.currentLoadingState = LoadingState.ERROR_LOADING;
            }

        }
    }
</script>
