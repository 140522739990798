import {ENTITY_CONFIG, EntityType} from "@/entity/_model/entity.constants";
import EntityController from "@/entity/_controller/EntityController";
import ContentAppModel from "@/content_app/_model/ContentAppModel";
import AppUserModel from "@/project/user/_model/AppUserModel";
import EntityControllerFactory from "@/entity/_controller/EntityControllerFactory";
import ContentFolderListModel from "@/content/_model/ContentFolderListModel";
import ContentAppResourceModel from "@/content_app/resource/_model/ContentAppResourceModel";
import {IContentAppResourceDto} from "@/content_app/resource/_model/content_app_resource.dto";
import ContentListModel from "@/content/_model/ContentListModel";
import fileManager, {FileResponse} from "@/_controller/FileManager";

class ContentAppController extends EntityController {

    public async create(name: string, contentAppResource: ContentAppResourceModel): Promise<ContentAppModel | null> {
        const contentApp: ContentAppModel = new ContentAppModel();
        contentApp.name[AppUserModel.getInstance().langCode] = name;
        contentApp.resourceIdentifier = contentAppResource.identifier;
        contentApp.appType = contentAppResource.appType;
        contentApp.availableRuntimes = contentAppResource.availableRuntimes;
        contentApp.canBeCopied = contentAppResource.canBeCopied;
        contentApp.canBeCreated = contentAppResource.canBeCreated;
        contentApp.canBeEdited = contentAppResource.canBeEdited;
        contentApp.canBePrepared = contentAppResource.canBePrepared;
        contentApp.canBePresented = contentAppResource.canBePresented;
        contentApp.canBeTrained = contentAppResource.canBeTrained;
        contentApp.canBeSharedWithAudiences = contentAppResource.canBeSharedWithAudiences;
        contentApp.externalLaunchUrl = contentAppResource.externalLaunchUrl;
        contentApp.hasCustomEditMode = contentAppResource.hasCustomEditMode;
        contentApp.dataSchemaURI = contentAppResource.dataSchemaURI;
        contentApp.poster[AppUserModel.getInstance().langCode] = contentAppResource.defaultPoster;

        const createSucceeded: boolean = await EntityControllerFactory.getListController(EntityType.CONTENT_APP).createEntity(contentApp, ContentFolderListModel.getInstance().activeContentFolder);
        if (createSucceeded) {
            return contentApp;
        } else {
            return null;
        }
    }

    public async getResourceConfigForApp(p_appID:string): Promise<IContentAppResourceDto | null>
    {
        const app: ContentAppModel | null = ContentListModel.getInstance().getEntityByID(p_appID) as ContentAppModel;
        if(app)
        {
            const appResourceResponse:FileResponse = await fileManager.fetchFileFromCdn(`contentAppResources/${app.resourceIdentifier}/cfg.json`);
            if (appResourceResponse.hasSucceeded)
            {
                return appResourceResponse.result;
            }
        }
        return null;
    }

}

//Singleton export
export default new ContentAppController(ENTITY_CONFIG.CONTENT_APP);
