<template>
  <div class="box box--full-height list-overview slides-overview" :id="hintIds.SLIDES1_LIBRARY">

    <div class="list-overview-filters">
      <b-input-group>
        <template #prepend>
          <b-input-group-text><i class="fas fa-search"></i></b-input-group-text>
        </template>
        <b-form-input v-model="selState.searchFilter"
                      :placeholder="$t('SearchSlide')"></b-form-input>
      </b-input-group>
    </div>

    <b-button block variant="primary" class="list-overview-button" @click="_onCreateSlideBtnClick">
      {{ $t('CreateSlide') }}
    </b-button>

    <div class="list-overview-items">
      <SlideItemRenderer
          v-for="slide in filteredSlides"
          :slide="slide"
          :key="slide.slideID"
          v-on:onClick="_onSlideClick"
          :selState="selState"
      />
    </div>

    <b-modal id="create-slide-box" :title="$t('CreateSlide')" hide-footer>
      <CreateSlideBox v-on:onFinish="_onCreateFinish"></CreateSlideBox>
    </b-modal>

    <b-popover custom-class="hint-popover" :target="hintIds.SLIDES1_LIBRARY"
               placement="right"
               triggers="manual"
               boundary="window">
      <Hint :id="hintIds.SLIDES1_LIBRARY"></Hint>
    </b-popover>

  </div>
</template>


<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import SlideItemRenderer from "@/slide/_view/SlideItemRenderer.vue";
import SlideModel from "@/slide/_model/SlideModel";
import slideListController from "@/slide/_controller/SlideListController";
import SlideListModel from "@/slide/_model/SlideListModel";
import {LoadingStatus} from "@/entity/_model/entity.constants";
import languageManager from "@/__libs/language_manager/LanguageManager";
import AppUserModel from "@/project/user/_model/AppUserModel";
import SlideSelectionState from "@/slide/_model/SlideSelectionState";
import CreateSlideBox from "@/slide/_view/CreateSlideBox.vue";
import {EventBusActions} from "@/_model/app.constants";
import {EventBus} from "@/__libs/vue/EventBus";
import {HintGroupIds, HintIds} from "@/help/hint/_model/hint.constants";
import Hint from "@/help/hint/_view/Hint.vue";
import HintListController from "@/help/hint/_controller/HintListController";

@Component({
    components: {
        Hint,
        SlideItemRenderer, CreateSlideBox
    },
})
export default class SlidesOverview extends Vue {

    @Prop() private selState!: SlideSelectionState;

    private hintIds: typeof HintIds = HintIds;
    private slideListModel: SlideListModel = SlideListModel.getInstance();
    private slides: SlideModel[] = this.slideListModel.list;
    private showCreateModal: boolean = false;

    get filteredSlides() {
        if (this.selState.searchFilter.length > 1) {
            return this.slides.filter(slide => {
                return (
                  languageManager
                    .getTranslationForValue<string>(slide.name, AppUserModel.getInstance().langCode)
                    .toLowerCase()
                    .indexOf(this.selState.searchFilter.toLowerCase()) > -1
                );
            });
        } else {
            return this.slides;
        }
    }

    public mounted() {
        slideListController.fetchAllEntities();
    }

    private async _onSlideClick(p_slide: SlideModel, p_e: Event) {
        if (p_slide.loadingStatus === LoadingStatus.BODY_LOADED) {
            this.selState.selected = p_slide;
            //
            HintListController.startGroup(HintGroupIds.SLIDES2);
        }
    }

    private _onCreateSlideBtnClick(p_e: Event) {
        this.$bvModal.show('create-slide-box');
    }

    private _onCreateFinish(p_createdSlide: SlideModel) {
        this.$bvModal.hide('create-slide-box');
        if (p_createdSlide) {
            this.selState.selected = p_createdSlide;
            EventBus.$emit(EventBusActions.ASK_EDIT_ENTITY, p_createdSlide);
        }
    }

}
</script>
