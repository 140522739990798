import IntroductionVideoListModel from "@/help/introduction/_model/IntroductionVideoListModel";
import {TierType} from "@/project/_model/project.constants";
import IntroductionVideoModel from "@/help/introduction/_model/IntroductionVideoModel";

class IntroductionBoxController {

    public init(tierType: string): IntroductionVideoListModel[] {
        const introductionVideoListModel: IntroductionVideoListModel[] = [];
        switch (tierType) {
            case TierType.EXPLORE:
                var item1: IntroductionVideoListModel = new IntroductionVideoListModel('IntroductionPart2Part1Title_EXPLORE');
                item1.videos.push(new IntroductionVideoModel('IntroductionPart2Part1Video1_EXPLORE', 'video/introduction-step1-a-explore.mp4', 'img/introduction/video-thumbs/introduction-step1-a-explore.jpg'));
                introductionVideoListModel.push(item1);
                var item2: IntroductionVideoListModel = new IntroductionVideoListModel('IntroductionPart2Part2Title');
                item2.videos.push(new IntroductionVideoModel('IntroductionPart2Part2Video1', 'video/introduction-step2-a.mp4', 'img/introduction/video-thumbs/introduction-step2-a.jpg'));
                item2.videos.push(new IntroductionVideoModel('IntroductionPart2Part2Video2', 'video/introduction-step2-b.mp4', 'img/introduction/video-thumbs/introduction-step2-b.jpg'));
                introductionVideoListModel.push(item2);
                break;
            case TierType.EXPLORE_PLUS:
                var item1: IntroductionVideoListModel = new IntroductionVideoListModel('IntroductionPart2Part1Title_EXPLORE_PLUS');
                item1.videos.push(new IntroductionVideoModel('IntroductionPart2Part1Video1_EXPLORE_PLUS', 'video/introduction-step1-a-explore-plus.mp4', 'img/introduction/video-thumbs/introduction-step1-a-explore-plus.jpg'));
                item1.videos.push(new IntroductionVideoModel('IntroductionPart2Part1Video2', 'video/introduction-step1-b.mp4', 'img/introduction/video-thumbs/introduction-step1-b.jpg'));
                introductionVideoListModel.push(item1);
                var item2: IntroductionVideoListModel = new IntroductionVideoListModel('IntroductionPart2Part2Title');
                item2.videos.push(new IntroductionVideoModel('IntroductionPart2Part2Video1', 'video/introduction-step2-a.mp4', 'img/introduction/video-thumbs/introduction-step2-a.jpg'));
                item2.videos.push(new IntroductionVideoModel('IntroductionPart2Part2Video2', 'video/introduction-step2-b.mp4', 'img/introduction/video-thumbs/introduction-step2-b.jpg'));
                introductionVideoListModel.push(item2);
                var item3: IntroductionVideoListModel = new IntroductionVideoListModel('IntroductionPart2Part3Title');
                item3.videos.push(new IntroductionVideoModel('IntroductionPart2Part3Video1', 'video/introduction-step3.mp4', 'img/introduction/video-thumbs/introduction-step3.jpg'));
                introductionVideoListModel.push(item3);
                break;
        }
        return introductionVideoListModel;
    }

}

export default new IntroductionBoxController();
