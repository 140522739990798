import {IModelDto} from "@/__libs/_model/model.dto";
import ContentModel from "@/content/_model/ContentModel";
import {EntityType} from "@/entity/_model/entity.constants";
import {DtoType, Runtime} from "@/_model/app.constants";
import {IContentAppBodyDto, IContentAppMetaDto} from "./content_app.dto";
import {ContentAppType} from "@/content_app/_model/content_app.constants";

export default class ContentAppModel extends ContentModel implements IContentAppBodyDto, IContentAppMetaDto
{

    public entityType:EntityType = EntityType.CONTENT_APP;

    public resourceIdentifier:string = "";
    public canBeCreated:boolean = true;
    public canBePresented:boolean = true;
    public canBeTrained:boolean = false;
    public canBePrepared:boolean = true;
    public canBeSharedWithAudiences:boolean = true;
    public canBeCopied:boolean = false;
    public canBeEdited:boolean = false;
    public availableRuntimes:Runtime[] = [Runtime.BROWSER];
    public appType:ContentAppType = ContentAppType.HTML_APP;
    public externalLaunchUrl:string = "";
    public hasCustomEditMode:boolean = false;
    public dataSchemaURI?:string;
    public dataProviderURI?:string;
    public iosInstallUrl?:string;


    // public data:any = {};

    //---------------------------------
    // Constructor
    //---------------------------------

    constructor(p_dto?:IModelDto)
    {
        super();
        if (p_dto)
        {
            this.mapFromDto(p_dto);

            //for backwards compatibility (otherwise existing apps will show the edit box popup)
            if ((p_dto as any).hasCustomEditMode === undefined)
            {
                this.hasCustomEditMode = true;
            }
        }
    }

    //---------------------------------
    // Public Methods
    //---------------------------------

    public mapToDto(p_dtoType:DtoType):IModelDto
    {
        if (p_dtoType === DtoType.BODY)
        {
            const dto:IContentAppBodyDto = {
                ID                      : this.ID,
                version                 : this.version,
                name                    : this.name,
                poster                  : this.poster,
                notes                   : this.notes,
                resourceIdentifier      : this.resourceIdentifier,
                canBeCreated            : this.canBeCreated,
                canBePresented          : this.canBePresented,
                canBeTrained            : this.canBeTrained,
                canBePrepared           : this.canBePrepared,
                canBeSharedWithAudiences: this.canBeSharedWithAudiences,
                canBeCopied             : this.canBeCopied,
                canBeEdited             : this.canBeEdited,
                availableRuntimes       : this.availableRuntimes,
                appType                 : this.appType,
                externalLaunchUrl       : this.externalLaunchUrl,
                hasCustomEditMode       : this.hasCustomEditMode,
                dataProviderURI         : this.dataProviderURI,
                dataSchemaURI           : this.dataSchemaURI,
                funnelStages            : this.funnelStages,
                // data                    : this.data

            };
            if (this.identifier)
            {
                dto.identifier = this.identifier;
            }
            if (this.portalName)
            {
                dto.portalName = this.portalName;
            }

            return dto;
        }
        else
        {
            return super.mapToDto(p_dtoType);
        }
    }

}
