<template>
  <div class="flow-status">
    <div class="flow-status__line"></div>
    <div class="flow-status__progress" :style="{ width: progressPercentage }"></div>
    <div class="flow-status__items">
      <FlowStatusItem v-for="(n, index) in 9" :statusIndex="index" :key="n"></FlowStatusItem>
    </div>
  </div>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import UserInfo from "@/gamification/summary/_view/UserInfo.vue";
import UserCoins from "@/gamification/summary/_view/UserCoins.vue";
import UserBadge from "@/gamification/summary/_view/UserStatus.vue";
import UserRanking from "@/gamification/summary/_view/UserRanking.vue";
import UserStatus from "@/gamification/summary/_view/UserStatus.vue";
import {IGamificationProjectConfigDto} from "@/gamification/_model/gamification.dto";
import AppUserModel from "@/project/user/_model/AppUserModel";
import FlowLevel from "@/gamification/flow/_view/FlowLevel.vue";
import FlowStatusItem from "@/gamification/flow/_view/FlowStatusItem.vue";
import GamificationUtil from "@/gamification/_controller/GamificationUtil";

@Component({
  components: {
    FlowStatusItem
  }
})
export default class FlowStatus extends Vue {

  private appUser: AppUserModel = AppUserModel.getInstance();

  get statusIndex(): number {
    return GamificationUtil.getStatusIndex(this.appUser.gamificationState.coins, this.appUser.project.config.gamification.status);
  }

  get coins(): number {
    return this.appUser.gamificationState.coins;
  }

  get progressPercentage(): string {
    let percentage: number = GamificationUtil.getStatusPercentage(this.statusIndex, this.appUser.project.config.gamification.status);
    if (this.statusIndex < this.appUser.project.config.gamification.status.length - 1) {
      const amount: number = GamificationUtil.getStatusAmount(this.statusIndex, this.appUser.project.config.gamification.status);
      const nextAmount: number = GamificationUtil.getStatusAmount(this.statusIndex + 1, this.appUser.project.config.gamification.status);
      const amountDifference: number = nextAmount - amount;
      const extraCoins: number = this.coins - amount;
      const extraPercentage: number = (extraCoins / amountDifference) / (this.appUser.project.config.gamification.status.length - 1) * 90;
      percentage += extraPercentage;
    }
    return `${percentage}%`;
  }

}
</script>

