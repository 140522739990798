import {ENTITY_CONFIG, EntityType, LoadingStatus} from "@/entity/_model/entity.constants";
import EntityController from "@/entity/_controller/EntityController";
import SlideModel from "@/slide/_model/SlideModel";
import apiManager, {ApiRequestType, ApiResponse} from "@/_controller/ApiManager";
import {IPresentationOutlineDto} from "@/presentation/_model/presentation.dto";
import PresentationModel from "@/presentation/_model/PresentationModel";
import {ITranslationGroupDto, ITranslationMultiLangDto} from "@/_model/app.dto";
import {SlideSource} from "@/presentation/_model/presentation.constants";
import {ISlideBodyDto} from "@/slide/_model/slide.dto";
import {IEntityBodyDto, IUpdateResultDto} from "@/entity/_model/entity.dto";
import ContentListModel from "@/content/_model/ContentListModel";
import EntityControllerFactory from "@/entity/_controller/EntityControllerFactory";



//contains all (static) controller methods that can be initiated on a single asset folder
class PresentationController extends EntityController
{


    //---------------------------------
    // Properties
    //---------------------------------


    //---------------------------------
    // Controller Methods
    //---------------------------------

    public async fetchSlideUsage(p_slide:SlideModel):Promise<boolean>
    {
        const response:ApiResponse<IPresentationOutlineDto[]> = await apiManager.sendApiRequest(ApiRequestType.GET, `/client-api/${ENTITY_CONFIG.SLIDE.apiPath}/${p_slide.ID}/presentations`);

        if (response.hasSucceeded)
        {
            p_slide.usedInPresentations = response.result as IPresentationOutlineDto[];
        }
        return response.hasSucceeded;
    }

    public async getTranslations(p_presentation:PresentationModel):Promise<ITranslationGroupDto[]>
    {
        const groups:ITranslationGroupDto[] = [];
        const presentationGroup:ITranslationGroupDto = {
            name        : "Presentation", identifier: `PRESENTATION:${p_presentation.ID}`,
            translations: p_presentation.getMultiLangTranslations()
        };
        groups.push(presentationGroup);

        for (let i = 0; i < p_presentation.slideOccurrences.length; i++)
        {
            const slideOccurrence = p_presentation.slideOccurrences[i];
            let slideBody:ISlideBodyDto | undefined;
            if (slideOccurrence.slideSource === SlideSource.INTERNAL)
            {
                slideBody = p_presentation.slides.find(slide => slide.ID === slideOccurrence.slideID)!;
            }
            else
            {
                const response:ApiResponse<IUpdateResultDto> = await apiManager.sendApiRequest(ApiRequestType.GET, `/client-api/slides/${slideOccurrence.slideID}`);
                if (response.hasSucceeded)
                {
                    slideBody = response.result as ISlideBodyDto;
                }
            }
            if (slideBody)
            {
                const slideTranslations:ITranslationMultiLangDto[] = new SlideModel(slideBody).getMultiLangTranslations();
                const slideGroup:ITranslationGroupDto = {
                    name        : `Slide ${i + 1}`,
                    identifier  : `${slideOccurrence.slideSource}:${slideOccurrence.slideID}`,
                    translations: slideTranslations
                };
                groups.push(slideGroup);
            }
        }
        return groups;
    }


    public async setTranslations(p_transGroups:ITranslationGroupDto[]):Promise<boolean>
    {
        let presentation:PresentationModel | undefined = undefined;
        let presentationHasChanges:boolean = false;
        for (let i = 0; i < p_transGroups.length; i++)
        {
            const group:ITranslationGroupDto = p_transGroups[i];

            const target:string = group.identifier.split(":")[0];
            const ID:string = group.identifier.split(":")[1];

            if (target === "PRESENTATION")
            {
                //get the presentation model
                presentation = ContentListModel.getInstance().getEntityByID(ID) as PresentationModel;
                if (presentation)
                {
                    presentationHasChanges = presentation.setMultiLangTranslations(group.translations);
                }
            }
            else
            {
                //set the slides
                let slideBody:ISlideBodyDto | undefined;
                if (target === SlideSource.INTERNAL && presentation)
                {
                    slideBody = presentation.slides.find(slide => slide.ID.toString() === ID);
                    if (slideBody)
                    {
                        const slideHasChanges:boolean = new SlideModel(slideBody).setMultiLangTranslations(group.translations);
                        if (slideHasChanges)
                        {
                            presentationHasChanges = true;
                        }
                    }
                }
                else
                {
                    const response:ApiResponse<IUpdateResultDto> = await apiManager.sendApiRequest(ApiRequestType.GET, `/client-api/slides/${ID}`);
                    if (response.hasSucceeded)
                    {
                        slideBody = response.result as ISlideBodyDto;
                        const slide:SlideModel = new SlideModel(slideBody);
                        const slideHasChanges:boolean = slide.setMultiLangTranslations(group.translations);
                        if (slideHasChanges)
                        {
                            await EntityControllerFactory.getController(EntityType.SLIDE).saveBody(slide);
                        }
                    }
                }

            }
            // console.log(group.identifier);
        }

        if (presentation && presentationHasChanges)
        {
            await this.saveBody(presentation);
        }

        return true;
    }

//---------------------------------
    // Private Methods
    //---------------------------------


}

//Singleton export
export default new PresentationController(ENTITY_CONFIG.PRESENTATION);
