<template>
  <div class="list-item data-provider-item"
       :class="{ active: isSelected, 'body-loaded': bodyLoaded }"
       v-on:click="_onClick">

    <div class="list-item__icon">
      <svg id="data" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" v-if="!isSelected">
        <defs>
          <radialGradient id="radial-gradient-data" cx="16" cy="16" fx="16" fy="16" r="23.34" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#b7c0c9"/>
            <stop offset="1" stop-color="#7497b0"/>
          </radialGradient>
        </defs>
        <rect class="cls-1" width="32" height="32" rx="7" ry="7"/>
      </svg>
      <svg id="data-open" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" v-if="isSelected">
        <defs>
          <radialGradient id="radial-gradient-data-open" cx="16" cy="16" fx="16" fy="16" r="23.34" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#6c8a98"/>
            <stop offset="1" stop-color="#3b4a52"/>
          </radialGradient>
        </defs>
        <rect class="cls-1" width="32" height="32" rx="7" ry="7"/>
      </svg>
      <div class="icon-wrapper">
        <i class="fa-lg" :class="dataProvider.icon"></i>
      </div>
    </div>

    <div class="list-item__info">
      <b-skeleton v-if="!bodyLoaded"></b-skeleton>
      <h6 class="list-item__info__primary mb-0" v-line-clamp="1" v-if="bodyLoaded">{{ dataProviderName }}</h6>
    </div>

    <MorePopup :id="`data-provider-${dataProviderID}`" placement="right">
      <EntityActionBox :entity="dataProvider" :editAllowed="false"/>
    </MorePopup>

  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from "vue-property-decorator";
import DataProviderModel from "@/data_tool/data_provider/_model/DataProviderModel";
import {DeltaStatus, LoadingStatus} from "@/entity/_model/entity.constants";
import MorePopup from "@/_view/components/MorePopup.vue";
import EntityActionBox from "@/entity/_view/EntityActionBox.vue";
import AppUserModel from "@/project/user/_model/AppUserModel";
import languageManager from "@/__libs/language_manager/LanguageManager";
import DataProviderSelectionState from "@/data_tool/data_provider/_model/DataProviderSelectionState";
import {RightAction} from "@/team/_model/role.constants";

@Component({
  components: {
    MorePopup, EntityActionBox
  }
})
export default class DataProviderItemRenderer extends Vue {

  @Prop() public dataProvider!: DataProviderModel;
  @Prop() private selState!: DataProviderSelectionState;

  get dataProviderName() {
    return languageManager.getTranslationForValue<string>(this.dataProvider.name, AppUserModel.getInstance().langCode)
  }

  get dataProviderID() {
    return this.dataProvider.ID;
  }

  get bodyLoaded() {
    return this.dataProvider.loadingStatus >= LoadingStatus.BODY_LOADED;
  }

  get isSelected() {
    return this.selState.selected === this.dataProvider;
  }

  get isNew() {
    return this.dataProvider.deltaStatus == DeltaStatus.NEW;
  }

  get isUpdated() {
    return this.dataProvider.deltaStatus == DeltaStatus.UPDATED;
  }

  get isDeleted() {
    return this.dataProvider.deltaStatus == DeltaStatus.DELETED;
  }

  get isEditable(): boolean {
    return this.dataProvider.rightActions.indexOf(RightAction.EDIT) >= 0;
  }

  public _onClick(p_e: Event) {
    this.$emit("onClick", this.dataProvider);
  }

}
</script>
