<template>
  <div class="sub-page data-providers-page">
    <DataProvidersView :selState="selState"></DataProvidersView>
  </div>
</template>


<script lang="ts">
import {Component, Vue} from "vue-property-decorator";
import DataProvidersOverview from "@/data_tool/data_provider/_view/DataProvidersOverview.vue";
import DataProvidersView from "@/data_tool/data_provider/_view/DataProvidersView.vue";
import DataProviderModel from "@/data_tool/data_provider/_model/DataProviderModel";
import DataProviderListModel from "@/data_tool/data_provider/_model/DataProviderListModel";
import DataProviderSelectionState from "@/data_tool/data_provider/_model/DataProviderSelectionState";

@Component({
  components: {DataProvidersView}
})
export default class DataProvidersPage extends Vue {

  public selState: DataProviderSelectionState = DataProviderListModel.getInstance().pageSelState;

}
</script>
