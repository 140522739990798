<template>
    <div class="data-item-select-list">

        <transition name="fade">
            <div v-if="currentState === selectListState.NO_DTP_SELECTED && showChooseList">
                <button class="btn btn-secondary btn-block btn-sm" @click="_onSelectDtpBtnClick">{{chooseListButtonLabel}}</button>
            </div>
        </transition>

        <transition name="fade">
            <div v-if="currentState === selectListState.LOADING"
                 class="loading d-flex justify-content-center align-items-center">
                <b-spinner variant="dark"></b-spinner>
            </div>
        </transition>

        <transition name="fade">
            <div v-if="currentState === selectListState.ERROR_LOADING"
                 class="placeholder-text text-danger">
                {{ $t('ErrorConnectionRequired') }}
            </div>
        </transition>

        <transition name="fade">

            <div v-if="currentState === selectListState.LOADED">

                <select v-if="property.propertyDefinition.formElement === componentType.COMBOBOX"
                        class="form-control mb-3"
                        v-model="selectList.selection" :disabled="!property.isEditable">
                    <option v-for="dataItem in this.property.dataItemList.list" :key="dataItem.__id"
                            :value="dataItem.__id">{{ dataItem.nameProp.displayedValue }}
                    </option>
                </select>

                <!--                todo: @TomA for some reason the b-form-checkbox does not work properly (doesn't register the right selected values), used regular input types for the time being
                                    maybe try out https://vue-multiselect.js.org/  or will this FU bootstrap? -->
                <!--                <b-form-group v-if="property.propertyDefinition.formElement === componentType.CHECKBOX_GROUP">-->
                <!--                    <b-form-checkbox-->
                <!--                            v-for="dataItem in this.property.dataItemList.list" :key="dataItem.__id"-->
                <!--                            :value="dataItem.__id" :disabled="!property.isEditable"-->
                <!--                            v-model="selectList.selection">-->
                <!--                        {{ dataItem.nameProp.displayedValue }}-->
                <!--                    </b-form-checkbox>-->
                <!--                </b-form-group>-->

                <div v-if="property.propertyDefinition.formElement === componentType.CHECKBOX_GROUP" class="mb-3">
                  <div v-for="dataItem in this.property.dataItemList.list" :key="dataItem.__id">
                    <label class="d-inline-flex align-items-center">
                        <input type="checkbox"
                               :value="dataItem.__id" :disabled="!property.isEditable"
                               v-model="selectList.selection"/>
                        <AAImage v-if="getPosterUrl(dataItem)" :imageUrl="getPosterUrl(dataItem)" class="list-image"/>
                        <span>{{ dataItem.nameProp.displayedValue }}</span>
                    </label>
                  </div>
                </div>

                <select v-if="property.propertyDefinition.formElement === componentType.LISTBOX"
                        class="form-control mb-3"
                        multiple
                        v-model="selectList.selection" :disabled="!property.isEditable">
                    <option v-for="dataItem in this.property.dataItemList.list" :key="dataItem.__id"
                            :value="dataItem.__id">{{ dataItem.nameProp.displayedValue }}
                    </option>
                </select>


                <div v-if="property.propertyDefinition.formElement === componentType.RADIOBUTTON_GROUP">
                    <b-form-radio v-for="dataItem in this.property.dataItemList.list" :key="dataItem.__id"
                                  v-model="selectList.selection" :name="property.propertyDefinition.identifier"
                                  :value="dataItem.__id">
                        <img v-if="getPosterUrl(dataItem)" :src="getPosterUrl(dataItem)">
                        {{ dataItem.nameProp.displayedValue }}
                    </b-form-radio>
                </div>

                <button v-if="showChooseList" class="btn btn-secondary btn-block btn-sm"
                        @click="_onSelectDtpBtnClick">{{chooseListButtonLabel}}
                </button>

            </div>

        </transition>

        <b-modal :id="modalID" :title="$t('SelectDataList')" hide-footer>
            <SelectDataItemListBox :itemDefinitionURI="this.property.propertyDefinition.itemDefinitionURI"
                                   :dataProviderURI="this.selectList.dataProviderURI"
                                   v-on:onFinish="_onSelectDtpFinish">
            </SelectDataItemListBox>
        </b-modal>
    </div>
</template>


<script lang="ts">
    import {Component, Prop, Vue, Watch} from "vue-property-decorator";
    import {FormElement} from "@/data_tool/_model/data_tool.constants";
    import DataItemSelectListModel from "@/data_tool/data_item/_model/DataItemSelectListModel";
    import dataProviderController from "@/data_tool/data_provider/_controller/DataProviderController";
    import PropertyModel from "@/data_tool/data_item/_model/PropertyModel";
    import SelectDataProviderBox from "@/data_tool/data_item/_view/SelectDataItemListBox.vue";
    import DataItemModel from "@/data_tool/data_item/_model/DataItemModel";
    import languageManager, {IMultiLangString} from "@/__libs/language_manager/LanguageManager";
    import fileManager from "@/_controller/FileManager";
    import {v4 as uuidv4} from "uuid";
    import AAImage from "@/_view/components/AAImage.vue";
    import AppUserModel from "@/project/user/_model/AppUserModel";
    import {RIGHTS} from "@/team/_model/role.constants";

    enum SelectListState
    {
        IDLE,
        NO_DTP_SELECTED,
        LOADING,
        ERROR_LOADING,
        LOADED
    }

    @Component({
        components: {AAImage, SelectDataItemListBox: SelectDataProviderBox}
    })
    export default class DataItemSelectList extends Vue
    {
        //---------------------------------
        // Vue Component props
        //---------------------------------

        @Prop() private property!:PropertyModel;

        @Prop() private selectList!:DataItemSelectListModel;


        //---------------------------------
        // Vue Component data
        //---------------------------------

        private componentType:typeof FormElement = FormElement;

        private selectListState:typeof SelectListState = SelectListState;
        private currentState:SelectListState = SelectListState.IDLE;

        private modalID:string = uuidv4();


        //---------------------------------
        // Vue Computed properties
        //---------------------------------

        get chooseListButtonLabel()
        {
            if (this.selectList.itemDefinition.chooseListButtonLabel)
            {
                return languageManager.getTranslationForValue<string>(this.selectList.itemDefinition.chooseListButtonLabel, AppUserModel.getInstance().langCode)
            }
            else
            {
                return this.$t("ChooseList");
            }
        }

        get showChooseList():boolean
        {
            return this.property.isEditable && AppUserModel.getInstance().rights.indexOf(RIGHTS.CHOOSE_DATAPROVIDER_LISTS.identifier) >= 0;
        }
        //---------------------------------
        // Public / lifecycle methods
        //---------------------------------



        public async created()
        {
            // console.log(this.property.propertyDefinition.identifier, this.property.propertyDefinition);
        }

        public async mounted()
        {

        }



        //---------------------------------
        // Private / helper methods
        //---------------------------------


        //this is the actual entrypoint of this component, is called after mount and whenever another property is set
        @Watch('property', {immediate: true, deep: false})
        private async _onActivePropertyChange(newProperty:PropertyModel | null, oldProperty:PropertyModel | null)
        {
            //check if a dtp needs to be selected or loaded
            if (this.selectList.dataProviderURI)
            {
                await this.loadDtp(this.selectList.dataProviderURI);
            }
            else
            {
                this.currentState = SelectListState.NO_DTP_SELECTED;
            }
        }


        private async loadDtp(p_dataProviderURI:string)
        {
            this.currentState = SelectListState.LOADING;
            const dependenciesFetched:boolean = await dataProviderController.fetchDataItemList(p_dataProviderURI, this.property);
            if (dependenciesFetched)
            {
                this.currentState = SelectListState.LOADED;
            }
            else
            {
                this.currentState = SelectListState.ERROR_LOADING;
            }
        }


        private async _onSelectDtpBtnClick(p_e:Event)
        {
            let needsConfirmation:boolean = false;
            if (this.property.propertyDefinition.isMultipleSelect)
            {
                if (this.selectList.selection && this.selectList.selection.length > 0)
                {
                    needsConfirmation = true;
                }
            }
            else if (this.selectList.selection)
            {
                needsConfirmation = true;
            }

            let changeConfirmed:boolean = true;
            if (needsConfirmation)
            {
                changeConfirmed = await this.$bvModal.msgBoxConfirm(this.$t("SureChangeData") as string, {centered: true});
            }
            if (changeConfirmed)
            {
                this.$bvModal.show(this.modalID!);
            }
        }


        private async _onSelectDtpFinish(p_selectedDtpURI:string)
        {
            this.$bvModal.hide(this.modalID!);

            if (p_selectedDtpURI && p_selectedDtpURI !== this.selectList.dataProviderURI)
            {
                this.selectList.dataProviderURI = p_selectedDtpURI;
                await this.loadDtp(p_selectedDtpURI);
            }
        }

        private getPosterUrl(p_dataItem:DataItemModel):string | null
        {
            if (p_dataItem.posterProp)
            {
                let posterURI:string;
                if (p_dataItem.posterProp.propertyDefinition.isMultiLingual)
                {
                    posterURI = languageManager.getTranslationForValue<string>(p_dataItem.posterProp.rawValue as IMultiLangString, p_dataItem.dataProvider.activeLangCode);
                }
                else
                {
                    posterURI = p_dataItem.posterProp.rawValue;
                }
                if (posterURI)
                {
                    return fileManager.getFileUrl(posterURI);
                }
            }
            return null;
        }

    }
</script>
