<template>
  <div class="sub-page project-settings-page">

    <ProjectSettings></ProjectSettings>
    <ProjectUsage></ProjectUsage>
    <ProjectDetail></ProjectDetail>

  </div>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import ProjectSettings from "@/project/_view/ProjectSettings.vue";
import ProjectDetail from "@/project/_view/ProjectDetail.vue";
import ProjectUsage from "@/project/_view/ProjectUsage.vue";


@Component({
  components: {ProjectUsage, ProjectDetail, ProjectSettings},
})
export default class ProjectSettingsPage extends Vue {
}
</script>

