import {ENTITY_CONFIG, LoadingStatus} from "@/entity/_model/entity.constants";
import EntityListController from "@/entity/_controller/EntityListController";

import {IEntityMetaDto} from "@/entity/_model/entity.dto";
import DataProviderModel from "@/data_tool/data_provider/_model/DataProviderModel";
import DataProviderListModel from "@/data_tool/data_provider/_model/DataProviderListModel";
import dataProviderController from "@/data_tool/data_provider/_controller/DataProviderController";
import DataProviderSelectionState from "@/data_tool/data_provider/_model/DataProviderSelectionState";


//contains all controller methods that can be applied on the list of dataProviders
class DataProviderListController extends EntityListController<DataProviderModel>
{

    constructor()
    {
        super(ENTITY_CONFIG.DATA_PROVIDER, DataProviderListModel.getInstance(), dataProviderController)
    }

    //---------------------------------
    // Properties
    //---------------------------------

    private _dataProviderListModel:DataProviderListModel = DataProviderListModel.getInstance();

    //---------------------------------
    // Controller Methods
    //---------------------------------

    public selectDtpByUri(p_selState:DataProviderSelectionState, p_selectedDtpUri:string)
    {
        //todo: problem, can only work when all dtp bodies are already loaded
        const dtpID:string | null = p_selectedDtpUri.split("/")[2];
        if (dtpID)
        {
            const dtp:DataProviderModel | null = this._dataProviderListModel.getEntityByID(dtpID);
            if (dtp && dtp.loadingStatus >= LoadingStatus.BODY_LOADED )
            {
                p_selState.selected = dtp;
            }
        }

    }

    //---------------------------------
    // Private Methods
    //---------------------------------

    protected __newEntity(p_dto:IEntityMetaDto):DataProviderModel
    {
        return new DataProviderModel(p_dto);
    }

}

//Singleton export
export default new DataProviderListController();
