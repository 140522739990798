<template>
  <div class="highlight" v-if="online">
    <GamificationHighlight v-if="showGamification"></GamificationHighlight>
    <YoutubeChannelHighlight v-if="!showGamification && isExploreOrExplorePlus"></YoutubeChannelHighlight>
  </div>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import GamificationHighlight from "@/gamification/_view/GamificationHighlight.vue";
import AppUserModel from "@/project/user/_model/AppUserModel";
import {RIGHTS} from "@/team/_model/role.constants";
import NetworkManager from "@/_controller/NetworkManager";
import {TierType} from "@/project/_model/project.constants";
import YoutubeChannelHighlight from "@/help/_view/YoutubeChannelHighlight.vue";

@Component({
    components: {YoutubeChannelHighlight, GamificationHighlight}
})
export default class Highlight extends Vue {

    public appUser: AppUserModel = AppUserModel.getInstance();
    public networkManager: NetworkManager = NetworkManager.getInstance();

    get showGamification(): boolean {
        return AppUserModel.getInstance().rights.indexOf(RIGHTS.VIEW_GAMIFICATION.identifier) >= 0;
    }

    get isExploreOrExplorePlus(): boolean {
        return AppUserModel.getInstance().project.tierConfig.tierType === TierType.EXPLORE || AppUserModel.getInstance().project.tierConfig.tierType === TierType.EXPLORE_PLUS;
    }

    get online() {
        return this.networkManager.online;
    }

}
</script>
