<template>
  <div class="sync-widget sync-widget--internet" :class="{ 'sync-widget--small' : small }">

    <div class="sync-widget__icon">
      <transition name="fade">
        <i v-if="online" class="fas fa-wifi fa-2x text-success"></i>
      </transition>
      <transition name="fade">
        <i v-if="!online" class="fas fa-wifi fa-2x text-danger"></i>
      </transition>
    </div>

    <div class="sync-widget__text mt-2">
      <transition name="fade">
        <div v-if="online">
          {{ $t('InternetOnline') }}
        </div>
      </transition>
      <transition name="fade">
        <div v-if="!online">
          {{ $t('InternetOffline') }}
        </div>
      </transition>
    </div>

  </div>
</template>


<script lang="ts">
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import NetworkManager from "@/_controller/NetworkManager";

@Component({
  components: {}
})
export default class SyncInternetWidget extends Vue {

  @Prop() public small!: boolean;

  public networkManager:NetworkManager = NetworkManager.getInstance();

  get online() {
    return this.networkManager.online;
  }

}
</script>
