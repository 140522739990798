<template>
    <div class="analytics-user-evolution">

        <h5>{{ $t('AnalyticsUserEvolution') }}</h5>

        <AnalyticsUserEvolutionGraph></AnalyticsUserEvolutionGraph>

    </div>
</template>


<script lang="ts">
    import {Component, Vue} from 'vue-property-decorator';
    import AnalyticsModel from "@/analytics/_model/AnalyticsModel";
    import AnalyticsUserEvolutionGraph from "@/analytics/_view/AnalyticsUserEvolutionGraph.vue";

    @Component({
        components: { AnalyticsUserEvolutionGraph },
    })
    export default class AnalyticsUserEvolution extends Vue
    {
        //---------------------------------
        // Vue Component props
        //---------------------------------

        //---------------------------------
        // Vue Component data
        //---------------------------------

        //---------------------------------
        // Vue Computed properties
        //---------------------------------

        //---------------------------------
        // Public / lifecycle methods
        //---------------------------------

        //---------------------------------
        // Private / helper methods
        //---------------------------------

    }
</script>
