import Model from "@/__libs/_model/Model";
import {ITeamUserBodyDto, ITeamUserStatsDto, IUserOutlineDto} from "@/team/_model/team.dto";
import {UserOrigin, UserStatus} from "@/team/_model/team.constants";
import {DtoType} from "@/_model/app.constants";
import {IModelDto} from "@/__libs/_model/model.dto";
import {LoadingStatus, SaveStatus} from "@/entity/_model/entity.constants";
import AppUserModel from "@/project/user/_model/AppUserModel";
import DataProviderModel from "@/data_tool/data_provider/_model/DataProviderModel";
import EntityModel from "@/entity/_model/EntityModel";
import {IDataProviderBodyDto} from "@/data_tool/_model/data_tool.dto";
import {RIGHTS} from "@/team/_model/role.constants";


class TeamUserModel extends Model implements IUserOutlineDto
{
    public userID:number = 0;
    public displayName:string = "";
    public email:string = "";
    public langCode:string = "en";
    public avatarFileUri:string = `assetFolder://asf-${AppUserModel.getInstance().project.identifier}-system-assets/user_avatar_default.png`;
    public teamName:string = "";

    public firstName:string = "";
    public lastName:string = "";
    public telNr:string = "";
    public mobileNr:string = "";
    public faxNr:string = "";
    public department:string = "";
    public jobTitle:string = "";
    public address:string = "";
    public website:string = "";
    public signature:string = "";

    public userOrigin:UserOrigin = UserOrigin.STATIC;
    public createdDate:Date = new Date();

    public teamID:number = 0;
    public roleID:number = 0;

    public stats?:ITeamUserStatsDto;

    //custom properties
    public dataSchemaURI:string = "dataSchema://user_schema"; //can in the future be used to create or change to a different "type" of audience with different custom props
    public customData:any = {};
    public dataDtp?:DataProviderModel;

    //status
    public status:UserStatus  = UserStatus.UNVERIFIED;

    public loadingStatus:LoadingStatus = LoadingStatus.ID_ONLY;

    public saveStatus:SaveStatus = SaveStatus.IDLE;

    public hasChanges:boolean = false;
    public isEditable:boolean = AppUserModel.getInstance().rights.indexOf(RIGHTS.EDIT_USERS.identifier) >= 0;

    //---------------------------------
    // Singleton Constructor
    //---------------------------------



    //---------------------------------
    // Public Methods
    //---------------------------------

    public mapFromDto(p_dto:IModelDto)
    {
        super.mapFromDto(p_dto);
        this.dataDtp = new DataProviderModel({data: this.customData, dataSchemaURI: this.dataSchemaURI, ID: this.userID}, this as any);
    }

    public mapToDto(p_dtoType:DtoType):IModelDto
    {
        if (p_dtoType === DtoType.BODY)
        {
            const dto:ITeamUserBodyDto = {
                email       : this.email,
                teamID       : this.teamID,
                roleID       : this.roleID,
                displayName  : this.displayName,
                langCode     : this.langCode,
                firstName    : this.firstName,
                lastName     : this.lastName,
                telNr        : this.telNr,
                mobileNr     : this.mobileNr,
                faxNr        : this.faxNr,
                department   : this.department,
                jobTitle     : this.jobTitle,
                avatarFileUri: this.avatarFileUri,
                address      : this.address,
                website      : this.website,
                signature    : this.signature
            };
            if (this.dataDtp)
            {
                dto.customData = (this.dataDtp.mapToDto(DtoType.BODY) as IDataProviderBodyDto).data;
            }
            return dto;
        }
        else
        {
            return super.mapToDto(p_dtoType);
        }
    }

}

export default TeamUserModel;
