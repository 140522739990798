import Dispatcher from "@/__libs/utility/Dispatcher";
import HintListModel from "@/help/hint/_model/HintListModel";
import HintModel from "@/help/hint/_model/HintModel";
import {HINT_LIST, HintEvents} from "@/help/hint/_model/hint.constants";
import {IHintDto} from "@/help/hint/_model/hint.dto";
import LocalStorageManager from "@/__libs/offline_storage/LocalStorageManager";
import {LocalStorageKey} from "@/_model/app.constants";
import JsonUtil from "@/__libs/utility/JsonUtil";

class HintListController extends Dispatcher {

    public list: HintListModel = HintListModel.getInstance();

    public init(): void {
        // get shown from localstorage
        const localStorageHints: any = JsonUtil.parse(LocalStorageManager.retrieveValue(LocalStorageKey.HINTS) as string);
        // fill list with hints
        for (const hintSource of HINT_LIST) {
            const hint: HintModel = new HintModel();
            hint.mapFromDto(hintSource as IHintDto);
            if (localStorageHints && localStorageHints[hint.id]) {
                hint.shown = localStorageHints[hint.id];
            }
            this.list.list.push(hint);
        }
    }

    public startGroup(groupId: string): void {
        this.dispatch(HintEvents.HIDE);
        const hint: HintModel | null = this.list.getFirstHintByGroupId(groupId);
        if (hint) {
            window.setTimeout(() => {
                this.dispatch(HintEvents.SHOW, hint.id);
            }, 2000);
        }
    }

    public showNext(id: string): void {
        const hint: HintModel | null = this.list.getHintById(id);
        if (hint) {
            this.dispatch(HintEvents.HIDE, hint.id);
            this.store();
            const nextHint: HintModel | null = this.list.getNextHintById(id);
            if (nextHint) {
                this.dispatch(HintEvents.SHOW, nextHint.id);
            }
        }
    }

    public skipAll() {
        this.list.skipAll();
        this.dispatch(HintEvents.HIDE);
        this.store();
    }

    public store() {
        LocalStorageManager.storeValue(LocalStorageKey.HINTS, JsonUtil.stringify(this.list.getModelForLocalStorage()));
    }

}

export default new HintListController();
