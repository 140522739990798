import SyncDataProcess from "@/sync/_controller/process/SyncDataProcess";
import syncController from "@/sync/_controller/SyncController";
import SyncModuleProcess from "@/sync/_controller/process/SyncModuleProcess";
import {SyncProcessType} from "@/sync/_model/sync.constants";
import SyncSlideProcess from "@/sync/_controller/process/SyncSlideProcess";
import {IEntityBodyDto} from "@/entity/_model/entity.dto";
import {IPresentationBodyDto} from "@/presentation/_model/presentation.dto";
import {ISyncItemFileDto} from "@/sync/_model/sync.dto";
import SyncConstructProcess from "@/sync/_controller/process/SyncConstructProcess";
import SyncSchemaProcess from "@/sync/_controller/process/SyncSchemaProcess";
import SyncDataProviderProcess from "@/sync/_controller/process/SyncDataProviderProcess";
import SyncContentAppResourceProcess from "@/sync/_controller/process/SyncContentAppResourceProcess";
import {IContentAppBodyDto} from "@/content_app/_model/content_app.dto";

class SyncContentAppProcess extends SyncDataProcess
{

    //---------------------------------
    // Properties
    //---------------------------------

    protected _storagePath:string = "content-apps";
    protected _storedBehindApi:boolean = true;

    public dependencyLevel:number = 0;

    private _contentAppResourceProcess!:SyncContentAppResourceProcess;
    private _dtpProcess!:SyncDataProviderProcess;

    //---------------------------------
    // Controller Methods
    //---------------------------------

    protected _resolveDependencyProcesses()
    {
        this._contentAppResourceProcess = syncController.resolveDependencyProcess(SyncProcessType.CONTENT_APP_RESOURCE) as SyncContentAppResourceProcess;
        this._dtpProcess = syncController.resolveDependencyProcess(SyncProcessType.DATA_PROVIDER) as SyncDataProviderProcess;

    }

    protected _parseRetrievedItemFile(p_itemFile:ISyncItemFileDto)
    {
        const body:IContentAppBodyDto = p_itemFile.body;

        //poster asset files
        if (body.poster)
        {
            this._parseAssetFilesFromMultiLangString(body.poster);
        }

        this._contentAppResourceProcess.addSyncItem({identifier: body.resourceIdentifier});

        if (body.dataProviderURI && body.dataProviderURI.length > 0)
        {
            this._dtpProcess.addSyncItem({identifier: body.dataProviderURI.split("://")[1]})
        }


    }

    //---------------------------------
    // Private Methods
    //---------------------------------


}


export default SyncContentAppProcess;
