<template>
  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
    <path class="cls-1" d="M15.08,0c-.39,0-.73.28-.79.67l-.45,2.73c-.78.13-1.53.34-2.26.61l-1.76-2.14c-.25-.3-.68-.38-1.02-.18l-1.59.92c-.34.2-.49.61-.35.97l.97,2.59c-.6.5-1.16,1.06-1.66,1.66l-2.59-.97c-.36-.14-.78.01-.97.35l-.92,1.59c-.2.34-.12.77.18,1.02l2.14,1.76c-.27.72-.48,1.48-.61,2.26l-2.73.45c-.39.07-.67.4-.67.79v1.84c0,.39.28.73.67.79l2.73.45c.13.78.34,1.53.61,2.26l-2.14,1.76c-.3.25-.38.68-.18,1.02l.92,1.59c.2.34.61.49.97.35l2.59-.97c.5.6,1.06,1.16,1.66,1.66l-.97,2.59c-.14.37.01.78.35.97l1.59.92c.34.2.77.12,1.02-.18l1.76-2.14c.73.27,1.48.48,2.26.61l.45,2.73c.07.39.4.67.79.67h1.84c.39,0,.73-.28.79-.67l.45-2.73c.78-.13,1.53-.34,2.26-.61l1.76,2.14c.25.3.68.38,1.02.18l1.59-.92c.34-.2.49-.61.35-.97l-.97-2.59c.6-.5,1.16-1.06,1.66-1.66l2.59.97c.37.14.78-.01.97-.35l.92-1.59c.2-.34.12-.77-.18-1.02l-2.14-1.76c.27-.73.48-1.48.61-2.26l2.73-.45c.39-.07.67-.4.67-.79v-1.84c0-.39-.28-.73-.67-.79l-2.73-.45c-.13-.78-.34-1.53-.61-2.26l2.14-1.76c.3-.25.38-.68.18-1.02l-.92-1.59c-.2-.34-.61-.49-.97-.35l-2.59.97c-.5-.6-1.06-1.16-1.66-1.66l.97-2.59c.14-.37-.01-.78-.35-.97l-1.59-.92c-.34-.2-.77-.12-1.02.18l-1.76,2.14c-.73-.27-1.48-.48-2.26-.61l-.45-2.73c-.07-.39-.4-.67-.79-.67h-1.84ZM14.4,6.54v4.93c-1.92.68-3.2,2.49-3.2,4.52,0,.29.03.59.08.88l-4.27,2.47c-.39-1.04-.61-2.16-.61-3.34,0-4.77,3.45-8.7,8-9.46ZM17.6,6.54c4.55.76,8,4.68,8,9.46,0,1.18-.22,2.3-.61,3.35l-4.27-2.47c.05-.29.08-.58.08-.88,0-2.03-1.28-3.84-3.2-4.52v-4.94ZM19.12,19.64l4.29,2.48c-1.76,2.13-4.42,3.48-7.41,3.48s-5.65-1.35-7.41-3.48l4.29-2.47c.87.74,1.97,1.15,3.12,1.15,1.14,0,2.25-.41,3.12-1.16Z"/>
  </svg>
</template>

<script lang="ts">
import {Component, Vue} from "vue-property-decorator";
import MainMenu from "@/_view/menu/MainMenu.vue";
import MainLogo from "@/_view/components/MainLogo.vue";
import MainStatus from "@/_view/components/MainStatus.vue";
import NetworkManager from "@/_controller/NetworkManager";

@Component({
  components: {}
})
export default class AccountIcon extends Vue {
}
</script>
