<template>
  <img class="aa-image" :class="{ loaded: imageIsLoaded }" :src="imageUrl" @load="imageLoadedHandler"
       @error="imageErrorHandler">
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from "vue-property-decorator";

@Component
export default class AAImage extends Vue {

  @Prop() public imageUrl!: string;

  private imageIsLoaded = false;

  private imageLoadedHandler(e: Event) {
    this.$emit('image-loaded');
    this.imageIsLoaded = true;
  }

  private imageErrorHandler(e: Event) {
    this.$emit('image-error');
    this.imageIsLoaded = false;
  }

  @Watch('imageUrl', {immediate: false, deep: false})
  private imageUrlChangedHandler(newValue: string, oldValue: string) {
    this.imageIsLoaded = false;
  }
}
</script>
