<template>
  <div class="more-popup">
    <div :id="id" class="more-button" tabindex="0">
      <i class="fas fa-ellipsis-v"></i>
    </div>
    <b-popover :target="id" triggers="click blur" :placement="placement" boundary="window">
      <slot></slot>
    </b-popover>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class MorePopup extends Vue {

  @Prop() public id!: string;
  @Prop({default: 'bottom'}) public placement!: string;

}
</script>
