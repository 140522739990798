<template>
  <div class="new-status-box">

    <div class="new-status-box__header">
      <AAImage class="new-status-box__header__icon" :imageUrl="statusIcon"></AAImage>
      <button type="button" aria-label="Close" class="close" @click="close">×</button>
      <div class="stars">
        <AAImage v-for="n in 11" :class="'star star--' + n" :imageUrl="`img/gamification/stars/star-${n}.png`" :key="n"></AAImage>
      </div>
    </div>

    <div class="new-status-box__content px-5 pb-4">
      <h4 class="mb-3" v-html="$t('GamificationBoxTitle')"></h4>
      <h6 class="mb-3 text-success" v-html="$t('GamificationBoxNewStatus')"></h6>
      <h1>{{ statusName }}</h1>
    </div>

  </div>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import AAImage from "@/_view/components/AAImage.vue";
import GamificationUtil from "@/gamification/_controller/GamificationUtil";
import AppUserModel from "@/project/user/_model/AppUserModel";
import languageManager from "@/__libs/language_manager/LanguageManager";

@Component({
  components: {AAImage}
})
export default class NewStatusBox extends Vue {

  public appUser: AppUserModel = AppUserModel.getInstance();

  get statusIndex(): number {
    return GamificationUtil.getStatusIndex(this.appUser.gamificationState.coins, this.appUser.project.config.gamification.status);
  }

  get statusIcon(): string {
    return GamificationUtil.getStatusIcon(this.statusIndex);
  }

  get statusName(): string {
    return languageManager.getTranslationForValue(GamificationUtil.getStatusName(this.statusIndex, this.appUser.project.config.gamification.status));
  }

  private close() {
    this.$bvModal.hide('gamification-new-status-box');
  }

}
</script>

