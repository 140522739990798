<template>
  <div class="list-item team-team-item"
       :class="{ active: isSelected, 'body-loaded': true }"
       v-on:click="_onClick">

    <div class="list-item__icon">
        <AAProfilePicture :name="team.displayName" :imageUri="team.avatarFileUri"></AAProfilePicture>
    </div>

    <div class="list-item__info">
        <h6 class="list-item__info__primary mb-0" v-line-clamp="1">{{ team.displayName }}</h6>
    </div>

  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

import MorePopup from "@/_view/components/MorePopup.vue";
import EntityActionBox from "@/entity/_view/EntityActionBox.vue";
import AAProfilePicture from "@/_view/components/AAProfilePicture.vue";
import TeamModel from "@/team/_model/TeamModel";
import SelectionState from "@/__libs/_model/SelectionState";

@Component({
  components: {
    AAProfilePicture, MorePopup, EntityActionBox
  }
})
export default class TeamItemRenderer extends Vue {
  //---------------------------------
  // Vue Component props
  //---------------------------------

  @Prop() public team!: TeamModel;

  @Prop() private selState!: SelectionState<TeamModel>;

  //---------------------------------
  // Vue Component data
  //---------------------------------

  //---------------------------------
  // Vue Computed properties
  //---------------------------------

  get teamID() {
    return this.team.teamID;
  }



  get isSelected() {
    return this.selState.selected === this.team;
  }



  //---------------------------------
  // Public / lifecycle methods
  //---------------------------------

  public _onClick(p_e: Event) {
      this.$emit("onClick", this.team);
  }

  //---------------------------------
  // Private / helper methods
  //---------------------------------
}
</script>
