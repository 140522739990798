<template>
  <div class="gamification-rulebook box box--full-height">
    <div class="gamification-rulebook__icon">
      <svg width="100%" height="100%" viewBox="0 0 92 92" version="1.1" xmlns="http://www.w3.org/2000/svg"
           xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/"
           style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
        <g transform="matrix(1,0,0,1,-1281.08,-88.891)">
              <g transform="matrix(0.666012,0,0,1.03891,-0.957756,-15.9389)">
                  <g id="icons8-rules.svg" transform="matrix(1.04071,0,0,0.667168,1993.63,144.937)">
                      <g transform="matrix(1,0,0,1,-66,-66)">
                          <path
                              d="M32.029,86.965L32.029,128.312L20.382,128.312L19.994,108.9L19.412,108.9L18.441,128.312L6.018,128.312C6.212,117.247 6.212,86.965 6.212,86.965L32.029,86.965Z"
                              style="fill:rgb(29,33,64);fill-rule:nonzero;"/>
                        <path
                            d="M128.118,13.2L128.118,116.471C128.118,118.412 126.565,119.965 124.624,119.965L51.441,119.965C49.5,119.965 47.947,118.412 47.947,116.471L47.947,13.2C47.947,11.259 49.5,9.706 51.441,9.706L124.624,9.706C126.565,9.9 128.118,11.453 128.118,13.2Z"
                            style="fill:rgb(29,33,64);fill-rule:nonzero;"/>
                        <path
                            d="M126.176,13.2L126.176,116.471C126.176,118.412 124.624,119.965 122.682,119.965L49.694,119.965C47.753,119.965 46.2,118.412 46.2,116.471L46.2,13.2C46.2,11.259 47.753,9.706 49.694,9.706L122.876,9.706C124.624,9.9 126.176,11.453 126.176,13.2Z"
                            style="fill:rgb(89,71,100);fill-rule:nonzero;"/>
                        <path
                            d="M51.829,114.335L51.829,15.529L120.547,15.529L120.547,107.153C120.547,111.035 117.441,114.141 113.559,114.141L51.829,114.141L51.829,114.335Z"
                            style="fill:white;fill-rule:nonzero;"/>
                        <path
                            d="M104.629,14.753C104.629,18.053 101.912,20.965 98.418,20.965L73.959,20.965C70.659,20.965 67.747,18.247 67.747,14.753C67.747,13.006 68.524,11.453 69.494,10.288C70.465,9.318 71.824,8.541 73.376,8.541L73.376,8.153C73.376,5.824 75.318,3.882 77.647,3.882L94.729,3.882C97.059,3.882 99,5.824 99,8.153L99,8.541C102.106,8.735 104.629,11.453 104.629,14.753Z"
                            style="fill:rgb(0,123,189);fill-rule:nonzero;"/>
                        <rect x="78.035" y="6.988" width="16.5" height="1.747" style="fill:white;"/>
                        <path
                            d="M109.482,103.659L43.482,103.659C41.153,107.347 42.512,112.976 47.365,114.335L113.365,114.335C108.706,112.976 107.347,107.347 109.482,103.659Z"
                            style="fill:rgb(218,230,246);fill-rule:nonzero;"/>
                        <path
                            d="M103.076,29.118L103.076,34.941C103.076,37.076 101.329,38.824 99.194,38.824L71.629,38.824C69.494,38.824 67.747,37.076 67.747,34.941L67.747,29.118C67.747,26.982 69.494,25.235 71.629,25.235L99.194,25.235C101.329,25.235 103.076,26.982 103.076,29.118Z"
                            style="fill:rgb(87,177,134);fill-rule:nonzero;"/>
                        <path
                            d="M74.347,34.747C74.153,34.747 74.153,34.553 74.347,34.747L74.153,29.506C74.153,29.312 74.153,29.312 74.347,29.312L76.288,29.312C77.453,29.312 78.229,29.894 78.229,31.059C78.229,31.835 77.841,32.418 77.259,32.612L78.424,34.553C78.424,34.747 78.424,34.747 78.229,34.747L77.453,34.747C77.259,34.553 77.065,34.165 76.288,32.806L75.124,32.806L75.124,34.747C75.124,34.941 75.124,34.941 74.929,34.941L74.347,34.941L74.347,34.747ZM75.318,31.641L76.482,31.641C77.065,31.641 77.453,31.447 77.453,30.865C77.453,30.282 77.065,30.088 76.482,30.088L75.318,30.088L75.318,31.641Z"
                            style="fill:white;fill-rule:nonzero;"/>
                        <path
                            d="M81.529,34.747C80.171,34.747 79.394,33.971 79.394,32.612L79.394,29.506C79.394,29.312 79.394,29.312 79.588,29.312L80.365,29.312C80.559,29.312 80.559,29.312 80.559,29.506L80.559,32.612C80.559,33.388 80.947,33.776 81.724,33.776C82.5,33.776 82.888,33.388 82.888,32.612L82.888,29.506C82.888,29.312 82.888,29.312 83.082,29.312L83.859,29.312C84.053,29.312 84.053,29.312 84.053,29.506L84.053,32.612C83.859,33.971 83.082,34.747 81.529,34.747Z"
                            style="fill:white;fill-rule:nonzero;"/>
                        <path
                            d="M85.218,34.747C85.024,34.747 85.024,34.553 85.218,34.747L85.024,29.506C85.024,29.312 85.024,29.312 85.218,29.312L85.994,29.312C86.188,29.312 86.188,29.312 86.188,29.506L86.188,33.776L88.712,33.776C88.906,33.776 88.906,33.776 88.906,33.971L88.906,34.553C88.906,34.553 88.906,34.747 88.712,34.747L85.218,34.747Z"
                            style="fill:white;fill-rule:nonzero;"/>
                        <path
                            d="M89.682,34.747C89.488,34.747 89.488,34.553 89.682,34.747L89.488,29.506C89.488,29.312 89.488,29.312 89.682,29.312L93.176,29.312C93.371,29.312 93.371,29.312 93.371,29.506L93.371,30.088C93.371,30.282 93.371,30.282 93.176,30.282L90.653,30.282L90.653,31.641L92.982,31.641C93.176,31.641 93.176,31.641 93.176,31.835L93.176,32.224C93.176,32.418 93.176,32.418 92.982,32.418L90.653,32.418L90.653,33.776L93.176,33.776C93.371,33.776 93.371,33.776 93.371,33.971L93.371,34.553C93.371,34.747 93.371,34.747 93.176,34.747L89.682,34.747Z"
                            style="fill:white;fill-rule:nonzero;"/>
                        <path
                            d="M96.282,34.747C94.729,34.747 94.147,33.971 94.147,33.194C94.147,33.194 94.147,33 94.341,33L95.118,33C95.312,33 95.312,33 95.312,33.194C95.506,33.582 95.7,33.776 96.476,33.776C97.059,33.776 97.641,33.582 97.641,33C97.641,32.418 96.865,32.224 96.282,32.224C95.312,32.029 94.535,31.641 94.535,30.671C94.535,29.7 95.506,29.118 96.476,29.118C97.835,29.118 98.612,29.894 98.612,30.671C98.612,30.671 98.612,30.865 98.418,30.865L97.641,30.865C97.641,30.865 97.447,30.865 97.447,30.671C97.447,30.282 96.865,30.088 96.476,30.088C95.894,30.088 95.506,30.282 95.506,30.671C95.506,31.253 96.088,31.253 96.865,31.447C98.029,31.641 98.806,32.029 98.806,33C98.418,34.165 97.447,34.747 96.282,34.747Z"
                            style="fill:white;fill-rule:nonzero;"/>
                        <g>
                              <circle cx="64.641" cy="50.859" r="7.182" style="fill:rgb(218,230,246);"/>
                          <rect x="75.318" y="44.841" width="6.6" height="4.076" style="fill:rgb(218,230,246);"/>
                          <rect x="75.318" y="51.247" width="39.406" height="1.747" style="fill:rgb(218,230,246);"/>
                          <rect x="75.318" y="55.324" width="10.482" height="1.747" style="fill:rgb(218,230,246);"/>
                          <rect x="88.906" y="55.324" width="7.959" height="1.747" style="fill:rgb(218,230,246);"/>
                          </g>
                        <g>
                              <circle cx="64.641" cy="69.494" r="7.182" style="fill:rgb(218,231,247);"/>
                          <rect x="75.318" y="63.476" width="6.6" height="4.076" style="fill:rgb(218,230,246);"/>
                          <rect x="75.318" y="69.882" width="39.406" height="1.747" style="fill:rgb(218,230,246);"/>
                          <rect x="75.318" y="73.959" width="10.482" height="1.747" style="fill:rgb(218,230,246);"/>
                          <rect x="88.906" y="73.959" width="7.959" height="1.747" style="fill:rgb(218,230,246);"/>
                          </g>
                        <g>
                              <circle cx="64.641" cy="88.129" r="7.182" style="fill:rgb(218,231,247);"/>
                          <rect x="75.318" y="82.112" width="6.6" height="4.076" style="fill:rgb(218,230,246);"/>
                          <rect x="75.318" y="88.518" width="39.406" height="1.747" style="fill:rgb(218,230,246);"/>
                          <rect x="75.318" y="92.594" width="10.482" height="1.747" style="fill:rgb(218,230,246);"/>
                          <rect x="88.906" y="92.594" width="7.959" height="1.747" style="fill:rgb(218,230,246);"/>
                          </g>
                        <g>
                              <path
                                  d="M64.447,54.547C64.253,54.547 64.253,54.353 64.253,54.353L64.253,48.918L62.7,50.082L62.312,50.082L61.924,49.5L61.924,49.112L64.253,47.365L65.418,47.365C65.612,47.365 65.612,47.559 65.612,47.559L65.612,54.353C65.612,54.547 65.418,54.547 65.418,54.547L64.447,54.547Z"
                                  style="fill:white;fill-rule:nonzero;"/>
                          </g>
                        <g>
                              <path
                                  d="M62.312,73.182C62.118,73.182 62.118,72.988 62.118,72.988L62.118,72.406C62.118,72.212 62.312,72.018 62.312,72.018C66,68.718 65.806,68.524 65.806,68.135C65.806,67.553 65.418,67.165 64.835,67.165C64.059,67.165 63.671,67.553 63.671,68.329C63.671,68.524 63.476,68.524 63.282,68.524L62.312,68.524C62.118,68.524 62.118,68.329 62.118,68.329C62.118,66.971 63.088,66 64.641,66C66.388,66 67.165,66.971 67.165,68.135C67.165,69.3 66.582,69.688 64.059,72.018L66.971,72.018C67.165,72.018 67.165,72.018 67.165,72.212L67.165,72.988C67.165,73.182 67.165,73.182 66.971,73.182L62.312,73.182Z"
                                  style="fill:white;fill-rule:nonzero;"/>
                          </g>
                        <g>
                              <path
                                  d="M64.835,91.818C62.894,91.818 62.118,90.847 61.924,89.876C61.924,89.682 62.118,89.682 62.118,89.682L63.088,89.682C63.282,89.682 63.282,89.682 63.476,89.876C63.671,90.459 64.253,90.653 64.835,90.653C65.612,90.653 66.194,90.265 66.194,89.488C66.194,88.906 65.806,88.518 64.835,88.518L63.865,88.518C63.671,88.518 63.671,88.518 63.671,88.324L63.671,87.935C63.671,87.741 63.671,87.741 63.865,87.547L65.806,85.8L62.894,85.8C62.7,85.8 62.7,85.606 62.7,85.606L62.7,85.024C62.7,84.829 62.7,84.829 62.894,84.829L67.359,84.829C67.553,84.829 67.553,85.024 67.553,85.024L67.553,85.412C67.553,85.412 67.553,85.606 67.359,85.606L65.612,87.353L65.806,87.353C67.165,87.547 67.941,88.129 67.941,89.488C67.553,91.235 66.194,91.818 64.835,91.818Z"
                                  style="fill:white;fill-rule:nonzero;"/>
                          </g>
                        <g>
                              <path
                                  d="M50.665,56.682L51.053,65.224L35.329,68.912C35.135,68.912 34.747,69.106 34.553,69.106L33.582,69.106C31.059,68.912 28.729,67.553 27.565,65.418L24.847,58.429L26.788,44.065L30.476,44.453C33.194,44.647 35.524,46.588 36.3,49.306L38.435,58.818L50.665,56.682Z"
                                  style="fill:rgb(200,239,161);fill-rule:nonzero;"/>
                          <path
                              d="M33.582,89.1C33.776,91.041 10.288,92.012 5.435,90.459C4.853,90.265 4.659,90.071 4.659,89.876L4.465,57.653L4.465,49.888C4.465,46.782 6.988,44.065 10.288,44.065L26.982,44.065C33.582,44.647 33.582,47.947 33.582,51.441L33.776,74.541L33.776,75.9L33.582,89.1Z"
                              style="fill:rgb(200,239,161);fill-rule:nonzero;"/>
                          <path
                              d="M33.582,76.094C33,76.288 32.029,76.094 31.253,76.094C27.176,76.094 23.488,76.288 17.276,75.9C16.694,75.9 12.424,74.541 11.065,75.318C7.765,77.453 7.571,85.024 5.824,89.876C5.824,90.071 5.629,90.265 5.629,90.459C5.047,90.265 4.853,90.071 4.853,89.876L4.465,57.653C6.212,57.071 8.347,58.041 9.9,59.206C13.588,62.312 15.529,66.971 19.024,70.076C21.353,72.212 24.653,73.571 27.759,73.765C28.924,73.765 30.282,73.765 31.447,73.959C31.641,73.959 32.806,74.347 33.582,74.735L33.582,76.094Z"
                              style="fill:rgb(87,177,134);fill-rule:nonzero;"/>
                          <path d="M23.876,44.065L23.294,50.082L15.724,44.065L23.876,44.065Z"
                                style="fill:rgb(255,191,173);fill-rule:nonzero;"/>
                          <rect x="15.724" y="35.524" width="8.153" height="8.541" style="fill:rgb(255,191,173);"/>
                          <path
                              d="M16.112,35.524C16.306,39.406 19.412,42.512 23.294,42.512L23.876,42.512L23.876,35.524L16.112,35.524Z"
                              style="fill:rgb(250,172,153);fill-rule:nonzero;"/>
                          <path
                              d="M29.894,24.071L30.088,29.7C30.088,30.476 29.894,31.059 29.312,31.447L26.788,26.788L26.788,24.071L29.894,24.071Z"
                              style="fill-rule:nonzero;"/>
                          <path
                              d="M29.506,29.894L29.506,34.553C29.506,38.435 26.4,41.347 22.712,41.347C18.829,41.347 15.918,38.241 15.918,34.553L15.918,29.894C15.918,26.012 19.024,23.1 22.712,23.1C26.4,23.1 29.506,26.206 29.506,29.894Z"
                              style="fill:rgb(255,191,173);fill-rule:nonzero;"/>
                          <ellipse cx="22.129" cy="32.418" rx="0.582" ry="0.971"/>
                          <ellipse cx="26.788" cy="32.418" rx="0.582" ry="0.971"/>
                          <path
                              d="M21.741,36.106C21.741,37.659 22.906,38.824 24.265,38.824C25.624,38.824 26.788,37.659 26.788,36.3L26.788,36.106L21.741,36.106Z"
                              style="fill:white;fill-rule:nonzero;"/>
                          <path
                              d="M13.588,33C13.588,33 11.647,30.476 11.647,26.012C11.647,21.159 13.006,20.382 17.082,19.024C17.082,19.024 18.441,17.276 22.129,17.471C25.818,17.665 27.953,20.188 31.253,20.188C31.253,20.188 31.253,22.129 30.088,22.518C30.088,22.518 30.476,23.876 32.029,23.488C32.029,23.488 32.224,30.476 18.053,26.4C18.053,26.4 18.635,28.147 16.694,29.118L16.694,31.835C16.694,32.418 16.306,32.806 15.724,32.806L15.724,31.447L13.588,33Z"
                              style="fill-rule:nonzero;"/>
                          <path
                              d="M15.724,31.253C14.559,31.253 13.588,32.224 13.588,33.388C13.588,34.553 14.559,35.524 15.724,35.524L15.724,31.253Z"
                              style="fill:rgb(255,191,173);fill-rule:nonzero;"/>
                          <g>
                                  <rect x="33.388" y="58.429" width="0.776" height="10.871"
                                        style="fill:rgb(87,177,134);fill-rule:nonzero;"/>
                              </g>
                          <g>
                                  <path d="M38.824,67.747L42.706,64.059L44.453,66L41.541,67.941"
                                        style="fill:rgb(255,191,173);fill-rule:nonzero;"/>
                            <g transform="matrix(0.961363,0.275285,-0.275285,0.961363,19.8194,-10.4523)">
                                      <rect x="42.318" y="45.035" width="9.9" height="40.571" style="fill:rgb(136,196,252);"/>
                                  </g>
                            <g transform="matrix(0.961363,0.275285,-0.275285,0.961363,25.2017,-8.05894)">
                                      <rect x="36.494" y="84.829" width="9.9" height="1.747" style="fill:rgb(218,231,247);"/>
                                  </g>
                            <path
                                d="M45.812,87.935L44.259,93.759C44.065,94.535 43.094,95.118 42.318,94.924L35.912,93.176C35.135,92.982 34.553,92.012 34.941,91.235L36.494,85.412L45.812,87.935Z"
                                style="fill:rgb(255,61,60);fill-rule:nonzero;"/>
                            <path d="M57.459,47.171L47.947,44.453L54.935,38.047L55.712,41.153L57.459,47.171Z"
                                  style="fill:rgb(255,191,173);fill-rule:nonzero;"/>
                            <path d="M55.712,41.153L52.606,40.182L54.935,38.047L55.712,41.153Z"
                                  style="fill:rgb(23,17,53);fill-rule:nonzero;"/>
                            <g transform="matrix(0.961363,0.275285,-0.275285,0.961363,19.8194,-10.4523)">
                                      <rect x="45.424" y="45.035" width="3.688" height="40.571"
                                            style="fill:rgb(106,179,239);"/>
                                  </g>
                              </g>
                          <g>
                                  <path
                                      d="M41.541,67.941L44.065,69.494C45.424,70.271 45.812,71.824 45.229,73.182C44.647,74.347 43.676,74.929 42.512,74.929L41.929,74.929L37.465,74.153L38.824,67.747"
                                      style="fill:rgb(255,191,173);fill-rule:nonzero;"/>
                              </g>
                          <g>
                                  <path
                                      d="M39.794,66.582L34.165,65.806L21.547,64.059L13.006,46.2C12.229,44.841 10.871,43.871 9.318,44.065C4.271,44.259 2.912,51.829 4.465,56.682L9.512,69.106C11.065,72.988 14.947,75.706 19.218,75.706L38.241,74.929L39.794,66.582Z"
                                      style="fill:rgb(200,239,161);fill-rule:nonzero;"/>
                              </g>
                          <g>
                                  <path
                                      d="M15.529,51.441C17.859,55.712 19.8,59.594 21.935,64.059L21.741,63.865C24.653,64.253 27.953,64.641 34.359,65.806C31.253,65.612 27.953,65.224 21.547,64.447C19.218,59.594 17.665,56.294 15.529,51.441Z"
                                      style="fill:rgb(87,177,134);fill-rule:nonzero;"/>
                              </g>
                          </g>
                      </g>
                  </g>
              </g>
          </g>
      </svg>
    </div>
    <div class="gamification-rulebook__texts">
      <h4 class="mb-2" v-html="$t('GamificationRulebookTitle')"></h4>
      <b-button variant="primary" size="sm" v-html="$t('GamificationRulebookButton')" @click="openBox"></b-button>
    </div>

    <b-modal id="gamification-rulebook" size="lg" :title="$t('GamificationRulebookTitle')" hide-footer>
      <GamificationRulebookBox></GamificationRulebookBox>
    </b-modal>
  </div>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import UserInfo from "@/gamification/summary/_view/UserInfo.vue";
import UserCoins from "@/gamification/summary/_view/UserCoins.vue";
import UserBadge from "@/gamification/summary/_view/UserStatus.vue";
import UserRanking from "@/gamification/summary/_view/UserRanking.vue";
import UserStatus from "@/gamification/summary/_view/UserStatus.vue";
import GamificationRulebookBox from "@/gamification/rulebook/_view/GamificationRulebookBox.vue";

@Component({
  components: {GamificationRulebookBox}
})
export default class GamificationRulebook extends Vue {

  private openBox(): void {
    this.$bvModal.show('gamification-rulebook');
  }

}
</script>

