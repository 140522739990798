<template>
  <div class="user-ranking">
    <div class="user-ranking__icon">
      <svg width="100%" height="100%" viewBox="0 0 44 48" version="1.1" xmlns="http://www.w3.org/2000/svg"
           xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/"
           style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
        <g transform="matrix(1,0,0,1,-833.606,-109.079)">
            <g transform="matrix(0.497251,0,0,0.495082,136.652,-17.8054)">
                <g transform="matrix(1.41622,0,0,1.99379,-787.91,-44.9092)">
                    <g transform="matrix(0.908177,0,0,0.510893,136.199,58.2309)">
                        <path
                            d="M1569.72,229.39C1569.72,228.132 1568.92,227.111 1567.93,227.111L1554.18,227.111C1553.19,227.111 1552.38,228.132 1552.38,229.39L1552.38,272.942C1552.38,274.2 1553.19,275.221 1554.18,275.221L1567.93,275.221C1568.92,275.221 1569.72,274.2 1569.72,272.942L1569.72,229.39Z"
                            style="fill:url(#_Linear1);"/>
                    </g>
                  <g transform="matrix(0.908177,0,0,0.763816,159.064,-11.379)">
                        <path
                            d="M1569.72,229.285C1569.72,228.085 1568.58,227.111 1567.16,227.111L1554.95,227.111C1553.53,227.111 1552.38,228.085 1552.38,229.285L1552.38,273.047C1552.38,274.247 1553.53,275.221 1554.95,275.221L1567.16,275.221C1568.58,275.221 1569.72,274.247 1569.72,273.047L1569.72,229.285Z"
                            style="fill:url(#_Linear2);"/>
                    </g>
                  <g transform="matrix(0.908177,0,0,0.992962,181.75,-74.4448)">
                        <path
                            d="M1569.72,228.784C1569.72,227.86 1568.58,227.111 1567.16,227.111L1554.95,227.111C1553.53,227.111 1552.38,227.86 1552.38,228.784L1552.38,273.548C1552.38,274.472 1553.53,275.221 1554.95,275.221L1567.16,275.221C1568.58,275.221 1569.72,274.472 1569.72,273.548L1569.72,228.784Z"
                            style="fill:url(#_Linear3);"/>
                    </g>
                </g>
            </g>
        </g>
        <defs>
              <linearGradient id="_Linear1" x1="0" y1="0" x2="1" y2="0" gradientUnits="userSpaceOnUse"
                              gradientTransform="matrix(3.2571e-15,67.4587,-116.371,9.03681e-15,1552.38,239.257)"><stop offset="0" style="stop-color:rgb(0,114,233);stop-opacity:1"/>
                <stop offset="1" style="stop-color:rgb(28,33,66);stop-opacity:1"/></linearGradient>
                <linearGradient id="_Linear2" x1="0" y1="0" x2="1" y2="0" gradientUnits="userSpaceOnUse"
                                gradientTransform="matrix(3.2571e-15,67.4587,-116.371,9.03681e-15,1552.38,239.257)"><stop offset="0" style="stop-color:rgb(0,114,233);stop-opacity:1"/>
                  <stop offset="1" style="stop-color:rgb(28,33,66);stop-opacity:1"/></linearGradient>
                <linearGradient id="_Linear3" x1="0" y1="0" x2="1" y2="0" gradientUnits="userSpaceOnUse"
                                gradientTransform="matrix(3.2571e-15,67.4587,-116.371,9.03681e-15,1552.38,239.257)"><stop offset="0" style="stop-color:rgb(0,114,233);stop-opacity:1"/>
                  <stop offset="1" style="stop-color:rgb(28,33,66);stop-opacity:1"/></linearGradient>
          </defs>
      </svg>
    </div>
    <div class="user-ranking__texts">
      <p class="mb-0" v-html="$t('GamificationRanking')"></p>
      <h5 class="mb-0" v-html="appUser.gamificationState.ranking"></h5>
    </div>
  </div>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import AppUserModel from "@/project/user/_model/AppUserModel";

@Component({
  components: {}
})
export default class UserRanking extends Vue {

  private appUser: AppUserModel = AppUserModel.getInstance();

}
</script>

