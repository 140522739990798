<template>
  <div class="new-level-box">

    <div class="new-level-box__header">
      <AAImage :imageUrl="levelImage"></AAImage>
      <button type="button" aria-label="Close" class="close" @click="close">×</button>
    </div>

    <div class="new-level-box__content p-5">
      <h4 class="mb-3" v-html="$t('GamificationBoxTitle')"></h4>
      <h6 class="mb-4 text-success" v-html="$t('GamificationBoxNewLevel')"></h6>
      <h1>{{ levelName }}</h1>
      <div class="stars">
        <AAImage v-for="n in 11" :class="'star star--' + n" :imageUrl="`img/gamification/stars/star-${n}.png`" :key="n"></AAImage>
      </div>
    </div>

  </div>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import AAImage from "@/_view/components/AAImage.vue";
import GamificationUtil from "@/gamification/_controller/GamificationUtil";
import AppUserModel from "@/project/user/_model/AppUserModel";
import languageManager from "@/__libs/language_manager/LanguageManager";

@Component({
  components: {AAImage}
})
export default class NewLevelBox extends Vue {

  public appUser: AppUserModel = AppUserModel.getInstance();

  get levelIndex(): number {
    return GamificationUtil.getStatusIndex(this.appUser.gamificationState.coins, this.appUser.project.config.gamification.levels);
  }

  get levelImage(): string {
    return GamificationUtil.getLevelImage(this.levelIndex, this.appUser);
  }

  get levelName(): string {
    return languageManager.getTranslationForValue(GamificationUtil.getStatusName(this.levelIndex, this.appUser.project.config.gamification.levels));
  }

  private close() {
    this.$bvModal.hide('gamification-new-level-box');
  }

}
</script>

