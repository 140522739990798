<template>
  <div class="box box--full-height room-widget">

    <transition name="fade">
      <div v-if="isConnected" class="box-wrapper">

        <div v-if="showLeaveSessionBtn" class="room-widget__header">
          <b-button variant="secondary" size="sm" @click="leaveRoom" v-html="$t('CobrowseLeave')"></b-button>
        </div>

        <CobrowseUsersBox :room="room"></CobrowseUsersBox>

        <ChatBox :chat-s-o="chatSO" :room="room"></ChatBox>

        <PresentedContentBox :room="room"/>

      </div>
    </transition>

    <transition name="fade">
      <div v-if="!isConnected" class="error-message">
        {{ $t('CobrowseErrorConnect') }}
      </div>
    </transition>

  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import ChatBox from "@/cobrowse/chat/_view/ChatBox.vue";
import CobrowseUsersBox from "@/cobrowse/cobrowse_user/_view/CobrowseUsersBox.vue";
import PresentedContentBox from "@/cobrowse/presented_content/_view/PresentedContentBox.vue";
import Room from "@sales-drive/audience-library/lib/cobrowse_manager/room/Room";
import CobrowseManager from "@sales-drive/audience-library/lib/cobrowse_manager/CobrowseManager";
import {
    CobrowseUserType
} from "@sales-drive/audience-library/lib/cobrowse_manager/cobrowse_user/_model/cobrowse_user.constants";

@Component({
    components: {PresentedContentBox, ChatBox, CobrowseUsersBox}
})
export default class RoomWidget extends Vue {

    @Prop() public room!: Room;

    public cobrowseManager: CobrowseManager = CobrowseManager.getInstance();

    mounted() {
    }

    get isConnected() {
        return this.cobrowseManager.isConnected;
    }

    get chatSO() {
        return this.room.roomChatSO;
    }

    get showLeaveSessionBtn(): boolean {
        return this.cobrowseManager.cobrowseUser.type === CobrowseUserType.USER;
    }

    private leaveRoom() {
        this.cobrowseManager.leaveRoom(this.room.identifier);
    }

}
</script>
