import {
    IDataItemDefinitionDto,
    IPropertyDefinitionDto,
    IXlsColumn,
    IXlsImportResult
} from "@/data_tool/_model/data_tool.dto";
import {DataModel} from "@/data_tool/_model/DataModel";
import DataProviderModel from "@/data_tool/data_provider/_model/DataProviderModel";
import {DtoType} from "@/_model/app.constants";
import DataItemModel from "@/data_tool/data_item/_model/DataItemModel";
import Dictionary from "@/__libs/_model/Dictionary";
import {ContentType} from "@/entity/_model/entity.constants";

export default class DataItemContentLinkModel extends DataModel
{
    public allowedContentTypes:ContentType[]; //the allowed content types

    protected includeInLogPath:boolean = false;

    private _contentID:string | null = null; // the linked content ID
    get contentID():string | null
    {
        return this._contentID;
    }

    set contentID(value:string | null)
    {
        if (value !== this._contentID)
        {
            this._contentID = value;
            //trigger validation
            if (this.__parent)
            {
                this.invalidChildren = [];
                this.__parent.childValidityChange(true, this);
                this.__parent.validate();
            }
            this.dataProvider.hasChanges = true;
        }
    }


    //---------------------------------
    // Constructor
    //---------------------------------

    constructor(p_allowedContentTypes:ContentType[], p_data:any, p_dataProvider:DataProviderModel, p_parent:DataModel | null)
    {
        super(p_dataProvider, p_parent);
        this.allowedContentTypes = p_allowedContentTypes;
        this.build(p_data);
    }

    //---------------------------------
    // Public Methods
    //---------------------------------

    public build(p_data:any)
    {
        if (p_data && p_data.contentID)
        {
            this._contentID = p_data.contentID;
        }
    }


    public mapToDto(p_dtoType:DtoType):any
    {
        if (p_dtoType === DtoType.BODY)
        {
            return {
                contentID: this._contentID,
            }
        }
    }

    public mapToRows(p_targetRow:any, p_columnPrefix:string)
    {
        if (this._contentID)
        {
            p_targetRow[`${p_columnPrefix}`] = this._contentID;
        }
    }


    public static mapFromRow(p_propDefinition:IPropertyDefinitionDto, p_row:any, p_columns:IXlsColumn[], p_result:IXlsImportResult)
    {
        const dto:any = {};
        const contentIDColumn:IXlsColumn = p_columns[0];

        dto.contentID = p_row[contentIDColumn.identifier];

        return dto;
    }

}
