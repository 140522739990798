<template>
  <div class="sync-widget sync-widget--road-mode">

    <div class="sync-widget__icon">
      <transition name="fade">
        <i v-if="roadMode" class="fas fa-car fa-2x text-success"></i>
      </transition>
      <transition name="fade">
        <i v-if="!roadMode" class="fas fa-car fa-2x"></i>
      </transition>
    </div>

    <div class="sync-widget__text mt-2">
      <transition name="fade">
        <div v-if="roadMode">
          {{ $t('RoadModeOn') }}
        </div>
      </transition>
      <transition name="fade">
        <div v-if="!roadMode">
          {{ $t('RoadModeOff') }}
        </div>
      </transition>
    </div>

  </div>
</template>


<script lang="ts">
import {Component, Prop, Vue} from "vue-property-decorator";
import SyncModel from "@/sync/_model/SyncModel";
import AppModel from "@/_model/AppModel";
import NetworkManager from "@/_controller/NetworkManager";

@Component({
    components: {}
})
export default class SyncStatusWidget extends Vue {

    @Prop() public small!: boolean;

    public networkManager: NetworkManager = NetworkManager.getInstance();

    get roadMode(): boolean {
        return this.networkManager.roadMode;
    }

}
</script>
