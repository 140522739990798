<template>
    <div class="cobrowse-user-box">

        <CobrowseUserBoxItemRenderer v-for="(user, i) in users" :key="i" :user="user" :roomUsersSO="roomUsersSO"/>

    </div>
</template>

<script lang="ts">
    import {Component, Prop, Vue} from "vue-property-decorator";
    import ChatBox from "@/cobrowse/chat/_view/ChatBox.vue";
    import CobrowseUserBoxItemRenderer from "@/cobrowse/cobrowse_user/_view/CobrowseUserBoxItemRenderer.vue";
    import Room from "@sales-drive/audience-library/lib/cobrowse_manager/room/Room";
    import {IRoomUsersSoDataDto} from "@sales-drive/audience-library/lib/cobrowse_manager/room/_model/room.dto";
    import SharedObject from "@sales-drive/audience-library/lib/cobrowse_manager/shared_object/SharedObject";

    @Component({
        components: {CobrowseUserBoxItemRenderer}
    })
    export default class UsersBox extends Vue
    {

        @Prop() public room!:Room;


        get roomUsersSO():SharedObject<IRoomUsersSoDataDto>
        {
            return this.room.roomUsersSO!;
        }

        get users()
        {
            return this.roomUsersSO.data.users;
        }


    }

</script>

