import i18n from '@/__plugins/i18n';

import {EntityType, IEntityTypeConfig, LoadingStatus, SaveStatus} from "@/entity/_model/entity.constants";
import apiManager, {ApiRequestType, ApiResponse} from "@/_controller/ApiManager";
import fileManager, {FileResponse} from "@/_controller/FileManager";
import {
    ICreateResultDto,
    IDeleteResultDto,
    IEntityBodyDto,
    IEntityTeamShareDto,
    IUpdateResultDto
} from "@/entity/_model/entity.dto";
import EntityModel from "@/entity/_model/EntityModel";
import {DtoType, StorageItemType} from "@/_model/app.constants";
import SyncDb from "@/sync/_model/SyncDb";
import toastManager, {ToastType} from "@/__libs/toast_manager/ToastManager";
import AudienceModel from "@/audience/_model/AudienceModel";
import {ITranslationMultiLangDto, ITranslationGroupDto} from "@/_model/app.dto";
import TeamUserModel from "@/team/_model/TeamUserModel";
import TeamUserListModel from "@/team/_model/TeamUserListModel";


//contains all (static) controller methods that can be initiated on a single entity
abstract class EntityController
{


    protected _entityTypeConfig:IEntityTypeConfig;


    constructor(p_entityTypeConfig:IEntityTypeConfig)
    {
        this._entityTypeConfig = p_entityTypeConfig;
    }

    //---------------------------------
    // Properties
    //---------------------------------



    //---------------------------------
    // Controller Methods
    //---------------------------------

    public async fetchBody(entity:EntityModel):Promise<boolean>
    {
        entity.loadingStatus = LoadingStatus.LOADING_BODY;

        const entityConfig:IEntityTypeConfig = this._getEntityConfig(entity.entityType);

        const response: ApiResponse<IUpdateResultDto> = await apiManager.sendApiRequest(ApiRequestType.GET, `/client-api/${this._getEntityConfig(entity.entityType).apiPath}/${entity.ID}`);

        if (response.hasSucceeded)
        {
            const bodyDto:IEntityBodyDto = response.result as IEntityBodyDto;
            if (entity.loadingStatus < LoadingStatus.BODY_LOADED || bodyDto.version >= entity.version) //only update body if the version is not older (because then the loaded body maybe cached somewhere)
            {
                entity.mapFromDto(bodyDto);
                entity.loadingStatus = LoadingStatus.BODY_LOADED;
            }
        }
        else
        {
            entity.loadingStatus = LoadingStatus.BODY_ERROR;
        }
        return response.hasSucceeded;
    }


    public async saveBody(entity:EntityModel, p_body?:IEntityBodyDto)
    {
        if (entity.saveStatus !== SaveStatus.SAVING_REMOTE) {
            let bodyDto: IEntityBodyDto;
            if (p_body) {
                bodyDto = p_body;
            } else {
                bodyDto = entity.mapToDto(DtoType.BODY) as IEntityBodyDto;
                // console.log(bodyDto);
            }

            //todo uncomment for actual saving

            entity.saveStatus = SaveStatus.SAVING_REMOTE;

            const response: ApiResponse<IUpdateResultDto> = await apiManager.sendApiRequest(ApiRequestType.PUT, `/client-api/${this._getEntityConfig(entity.entityType).apiPath}/${entity.ID}?action=BODY`, bodyDto);

            if (response.hasSucceeded) {
                if (p_body) {
                    entity.mapFromDto(bodyDto);
                }
                entity.version = (response.result as IUpdateResultDto).version as number;
                entity.saveStatus = SaveStatus.REMOTE_SAVED;
                entity.hasChanges = false;
                toastManager.showToast(i18n.t('EntitySaveSuccess', [entity.entityType]) as string, ToastType.SUCCESS);

            } else {
                entity.saveStatus = SaveStatus.REMOTE_ERROR;
                toastManager.showToast(i18n.t('EntitySaveFailed', [entity.entityType]) as string, ToastType.DANGER);

            }
        }

    }


    public async patchBody(entity:EntityModel, p_patch:any)
    {

        entity.saveStatus = SaveStatus.SAVING_REMOTE;

        const response:ApiResponse<IUpdateResultDto> = await apiManager.sendApiRequest(ApiRequestType.PATCH, `/client-api/${this._getEntityConfig(entity.entityType).apiPath}/${entity.ID}`, p_patch);

        if (response.hasSucceeded)
        {
            const updateResult:IUpdateResultDto = (response.result as IUpdateResultDto);
            entity.mapFromDto(updateResult.body);
            entity.saveStatus = SaveStatus.REMOTE_SAVED;
            entity.hasChanges = false;
            toastManager.showToast(i18n.t('EntitySaveSuccess', [entity.entityType]) as string, ToastType.SUCCESS);

        }
        else
        {
            entity.saveStatus = SaveStatus.REMOTE_ERROR;
            toastManager.showToast(i18n.t('EntitySaveFailed', [entity.entityType]) as string, ToastType.DANGER);

        }
    }

    public async startSharingWithTeam(p_entity:EntityModel, p_shareDto:IEntityTeamShareDto)
    {
        p_entity.sharedWithTeams.push(p_shareDto);


        const endpoint:string = `/client-api/${this._getEntityConfig(p_entity.entityType).apiPath}/${p_entity.ID}/team-share`;
        const response:ApiResponse<ICreateResultDto> = await apiManager.sendApiRequest(ApiRequestType.POST, endpoint, p_shareDto);

        if (response.hasSucceeded)
        {
            toastManager.showToast(i18n.t('ShareWithTeamsStartSuccess') as string, ToastType.SUCCESS);
        }
        else
        {
            toastManager.showToast(i18n.t('ShareWithTeamsStartFailed') as string, ToastType.DANGER);
        }
    }

    public async updateShareWithTeam(p_entity:EntityModel, p_shareDto:IEntityTeamShareDto)
    {

        const endpoint:string = `/client-api/${this._getEntityConfig(p_entity.entityType).apiPath}/${p_entity.ID}/team-share`;
        const response:ApiResponse<IUpdateResultDto> = await apiManager.sendApiRequest(ApiRequestType.PUT, endpoint, p_shareDto);

        if (response.hasSucceeded)
        {
            toastManager.showToast(i18n.t('ShareWithTeamsUpdateSuccess') as string, ToastType.SUCCESS);
        }
        else
        {
            toastManager.showToast(i18n.t('ShareWithTeamsUpdateFailed') as string, ToastType.DANGER);
        }


    }

    public async stopSharingWithTeam(p_entity:EntityModel, p_shareDto:IEntityTeamShareDto)
    {
        const index:number = p_entity.sharedWithTeams.indexOf(p_shareDto);
        if(index >= 0)
        {
            p_entity.sharedWithTeams.splice(index, 1);
        }

        const endpoint:string = `/client-api/${this._getEntityConfig(p_entity.entityType).apiPath}/${p_entity.ID}/team-share/${p_shareDto.teamID}`;
        const response:ApiResponse<IDeleteResultDto> = await apiManager.sendApiRequest(ApiRequestType.DELETE, endpoint);

        if (response.hasSucceeded)
        {
            toastManager.showToast(i18n.t('ShareWithTeamsStopSuccess') as string, ToastType.SUCCESS);
        }
        else
        {
            toastManager.showToast(i18n.t('ShareWithTeamsStopFailed') as string, ToastType.DANGER);
        }
    }

    public async transferToUser(p_entity:EntityModel, p_userID:number):Promise<boolean>
    {

        const endpoint:string = `/client-api/${this._getEntityConfig(p_entity.entityType).apiPath}/${p_entity.ID}/transfer`;
        const response:ApiResponse<IUpdateResultDto> = await apiManager.sendApiRequest(ApiRequestType.PUT, endpoint, {userID:p_userID});

        if (response.hasSucceeded)
        {
            toastManager.showToast(i18n.t('TransferComplete') as string, ToastType.SUCCESS);
            const user = TeamUserListModel.getInstance().getUserByID(p_userID);
            if(user)
            {
                p_entity.createdByUserName = user!.displayName
                p_entity.createdByTeamName = user!.teamName;
            }
        }
        else
        {
            toastManager.showToast(i18n.t('TransferFailed') as string, ToastType.DANGER);
        }
        return response.hasSucceeded;

    }


    //needs to be overridden
    public async getTranslations(p_entity:EntityModel):Promise<ITranslationGroupDto[]>
    {
        return [];
    }
    public async setTranslations(p_transGroups:ITranslationGroupDto[]):Promise<boolean>
    {
        return true;
    }

    //---------------------------------
    // Private Methods
    //---------------------------------

    //can be overridden (eg when you have to switch between the 3 types of content entities)
    protected _getEntityConfig(p_entityType:EntityType):IEntityTypeConfig
    {
        return this._entityTypeConfig;
    }

}

//Singleton export
export default EntityController;
