export enum CobrowseUserType
{
    USER = "USER",
    AUDIENCE = "AUDIENCE",
    GUEST = "GUEST",
    SYSTEM = "SYSTEM"
}

export enum CobrowseUserInteractionState
{
    NONE,
    SHOW_MOUSE,
    INTERACTIVE
}
