import {
    CacheStatus,
    DeltaStatus, ENTITY_CONFIG,
    EntityType,
    IEntityTypeConfig,
    LoadingStatus,
    SaveStatus
} from "@/entity/_model/entity.constants";
import Model from "@/__libs/_model/Model";
import {RightAction, RIGHTS} from "@/team/_model/role.constants";
import {IEntityBodyDto, IEntityMetaDto, IEntityTeamShareDto} from "@/entity/_model/entity.dto";
import {DtoType} from "@/_model/app.constants";
import {IModelDto} from "@/__libs/_model/model.dto";
import {IAudienceBodyDto} from "@/audience/_model/audience.dto";
import DataProviderSelectionState from "@/data_tool/data_provider/_model/DataProviderSelectionState";
import DataProviderListModel from "@/data_tool/data_provider/_model/DataProviderListModel";
import {DataProviderViewMode} from "@/data_tool/_model/data_tool.constants";
import AppUserModel from "@/project/user/_model/AppUserModel";
import ContentAppModel from "@/content_app/_model/ContentAppModel";
import {ITranslationMultiLangDto} from "@/_model/app.dto";
import {IMultiLangTransValue} from "@/__libs/language_manager/LanguageManager";

export default abstract class EntityModel extends Model
{


    public ID:string = "";
    public version:number = 1;
    public identifier?:string;

    public parentEntity?:EntityModel; //eg a DataProviderModel can have an AudienceModel as parent (for it's customProperties)

    public isAvailableOffline:boolean = false;

    public isEditable:boolean = false;

    private _hasChanges:boolean = false;
    get hasChanges():boolean
    {
        return this._hasChanges;
    }

    set hasChanges(value:boolean)
    {
        this._hasChanges = value;
        if (this._hasChanges && this.parentEntity)
        {
            this.parentEntity.hasChanges = true;
        }
    }

    get isShareableWithAudience():boolean
    {
        const entityConfig = ENTITY_CONFIG[this.entityType];
        const showShareWithAudience:boolean = AppUserModel.getInstance().rights.indexOf(RIGHTS.SHARE_ENTITIES_WITH_AUDIENCES.identifier) >= 0 &&
            entityConfig.canBeSharedWithAudiences &&
            this.rightActions.indexOf(RightAction.SHARE_WITH_AUDIENCES) >= 0;
        if (this.entityType === EntityType.CONTENT_APP)
        {
            return showShareWithAudience && (this as any).canBeSharedWithAudiences;
        }
        return showShareWithAudience;
    }

    //status
    public loadingStatus:LoadingStatus = LoadingStatus.ID_ONLY;

    public saveStatus:SaveStatus = SaveStatus.IDLE;

    public cacheStatus:CacheStatus = CacheStatus.UNKNOWN;

    public deltaStatus:DeltaStatus = DeltaStatus.UNCHANGED;

    public entityType!:EntityType;

    //meta
    public createdByUserID:number = 0;
    public createdDate:Date = new Date();
    public updatedDate:Date = new Date();
    public latestActivityDate:Date = new Date();
    public createdByUserName:string = "";
    public createdByTeamName:string = "";
    public rightActions:RightAction[] = [];
    public sharedWithTeams:IEntityTeamShareDto[] = [];
    public folderID?:number;


    constructor(p_parentEntity?:EntityModel)
    {
        super();
        if (p_parentEntity)
        {
            this.parentEntity = p_parentEntity;
        }
    }


    //---------------------------------
    // Public Methods
    //---------------------------------

    public mapFromDto(p_dto:IModelDto)
    {
        super.mapFromDto(p_dto);
        if (this.parentEntity)
        {
            this.isEditable = this.parentEntity.isEditable;
        }
        else
        {
            this.isEditable = this.rightActions.indexOf(RightAction.EDIT) >= 0;
        }
    }

    public mapToDto(p_dtoType:DtoType):IModelDto
    {
        if (p_dtoType === DtoType.BODY)
        {
            const dto:IEntityBodyDto = {
                ID     : this.ID,
                version: this.version
            };
            if (this.identifier)
            {
                dto.identifier = this.identifier;
            }
            return dto;
        }
        else if (p_dtoType === DtoType.META)
        {
            const dto:IEntityMetaDto = {
                ID                : this.ID,
                version           : this.version,
                entityType        : this.entityType,
                createdByUserID   : this.createdByUserID,
                createdDate       : this.createdDate,
                updatedDate       : this.updatedDate,
                latestActivityDate: this.latestActivityDate,
                createdByUserName : this.createdByUserName,
                createdByTeamName : this.createdByTeamName,
                rightActions      : this.rightActions,
                sharedWithTeams   : this.sharedWithTeams,
                folderID          : this.folderID,
            };
            return dto;
        }
        else
        {
            console.log("no mapping found for " + p_dtoType);
            return {};
        }
    }

    //should be overridden
    public renameAsCopy()
    {
    }

    public setMultiLangTranslations(p_translations:ITranslationMultiLangDto[]):boolean
    {
        let hasChanges:boolean = false;
        for (let i = 0; i < p_translations.length; i++)
        {
            const trans:ITranslationMultiLangDto = p_translations[i];
            for (let langCode in trans.multiLangTrans)
            {
                if (trans.multiLangTrans.hasOwnProperty(langCode))
                {
                    const transValue:IMultiLangTransValue = trans.multiLangTrans[langCode];
                    if (transValue.hasChanges)
                    {
                        hasChanges = true;
                        // console.log((this as any)[trans.identifier][langCode], ">" ,transValue.text);
                        (this as any)[trans.identifier][langCode] = transValue.text;
                    }
                }
            }
        }
        return hasChanges;
    }

}
