<template>
  <div class="gamification-introduction-box text-center py-2">

    <p class="mb-4" v-html="$t('GamificationIntroductionText')"></p>

    <div class="responsive responsive--16-9 mb-4">
      <div class="responsive__wrapper">
        <video autoplay>
          <source src="/video/gamification-introduction.mp4" type="video/mp4">
        </video>
      </div>
    </div>

    <b-button variant="success" v-html="$t('GamificationIntroductionButton')" @click="close"></b-button>

  </div>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import AAImage from "@/_view/components/AAImage.vue";

@Component({
  components: {}
})
export default class GamificationIntroductionBox extends Vue {

  private close() {
    this.$bvModal.hide('gamification-introduction-box');
  }

}
</script>
