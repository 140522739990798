<template>
  <div class="data-provider-picker">

    <DataProvidersView :selState="selState"></DataProvidersView>

    <div class="d-flex justify-content-end mt-3">
      <b-button variant="secondary" class="mr-2" @click="_onCancelBtnClick" tabindex="1">
        {{ $t('Cancel') }}
      </b-button>
      <b-button variant="primary" @click="_onPickDtpBtnClick" :disabled="!selState.selected" tabindex="2">
        {{ $t('Ok') }}
      </b-button>
    </div>

  </div>
</template>


<script lang="ts">
import {Component, Prop, Vue} from "vue-property-decorator";
import DataProvidersOverview from "@/data_tool/data_provider/_view/DataProvidersOverview.vue";
import DataProvidersView from "@/data_tool/data_provider/_view/DataProvidersView.vue";
import DataProviderModel from "@/data_tool/data_provider/_model/DataProviderModel";
import DataProviderListModel from "@/data_tool/data_provider/_model/DataProviderListModel";
import DataProviderSelectionState from "@/data_tool/data_provider/_model/DataProviderSelectionState";
import dataProviderListController from "@/data_tool/data_provider/_controller/DataProviderListController";
import dataProviderController from "@/data_tool/data_provider/_controller/DataProviderController";

@Component({
  components: {DataProvidersView}
})
export default class DataProvidersPicker extends Vue {

  @Prop() public allowedSchema!: string;
  @Prop() public selectedDtpUri!: string;

  public selState: DataProviderSelectionState = DataProviderListModel.getInstance().pickerSelState;

  public mounted() {
    //todo: can only work when picker is mounted every time when asked

    this.selState.allowedSchema = this.allowedSchema;
    this.selState.selectedDtpUri = this.selectedDtpUri;

    if (this.selectedDtpUri) {
      dataProviderListController.selectDtpByUri(this.selState, this.selectedDtpUri);
    }
  }

  private async _onCancelBtnClick(p_e: Event) {
    this.$emit('onDtpPicked', null);
  }

  private async _onPickDtpBtnClick(p_e: Event) {
    const pickedDtp: DataProviderModel = this.selState.selected!;
    if (pickedDtp && pickedDtp.hasChanges) {
      dataProviderController.saveBody(pickedDtp);
    }
    this.$emit('onDtpPicked', pickedDtp.ID);
  }

  private isPickAllowed() {
    return this.selState.selected !== null;
  }

}
</script>
