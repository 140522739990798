<template>
  <div class="flow-levels">
    <FlowLevel levelIndex="0"></FlowLevel>
    <FlowLevel levelIndex="1"></FlowLevel>
    <FlowLevel levelIndex="2"></FlowLevel>
  </div>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import UserInfo from "@/gamification/summary/_view/UserInfo.vue";
import UserCoins from "@/gamification/summary/_view/UserCoins.vue";
import UserBadge from "@/gamification/summary/_view/UserStatus.vue";
import UserRanking from "@/gamification/summary/_view/UserRanking.vue";
import UserStatus from "@/gamification/summary/_view/UserStatus.vue";
import {IGamificationProjectConfigDto} from "@/gamification/_model/gamification.dto";
import AppUserModel from "@/project/user/_model/AppUserModel";
import FlowLevel from "@/gamification/flow/_view/FlowLevel.vue";

@Component({
  components: {
    FlowLevel
  }
})
export default class FlowLevels extends Vue {

}
</script>

