<template>
  <div>
    <vue-editor
      v-model="property.displayedValue"
      :editorOptions="customToolbar"
      :disabled="!property.isEditable"
      ref="editor"
    />

    <div v-if="quillSelectionRange" ref="slideLinkToolTip" class="property-editor-tooltip">
      <form class="property-editor-tooltip__form" @submit.prevent="onInsertSlideLink">
        <label for="slideId" class="property-editor-tooltip__form__label">
          {{ $t('RichTextSlideIdentifier') }}
        </label>

        <input v-model="quillSlideId" name="slideId" type="text" />

        <a type="submit" class="property-editor-tooltip__form__action" @click="onInsertSlideLink">
          {{ $t('RichTextSave') }}
        </a>
      </form>
    </div>
  </div>
</template>

<script lang="ts">
import {Vue, Component, Prop} from "vue-property-decorator";
import PropertyModel from '@/data_tool/data_item/_model/PropertyModel';
import {VueEditor} from 'vue2-editor';
import AppUserModel from '@/project/user/_model/AppUserModel';

@Component({
  components: {VueEditor}
})
export default class RichTextEditor extends Vue {
  //---------------------------------
  // Vue Component props
  //---------------------------------
  @Prop({required: true}) public readonly property!: PropertyModel;

  //---------------------------------
  // Vue Component refs
  //---------------------------------
  public $refs!: {
    editor: any;
    slideLinkToolTip: HTMLDivElement;
  };

  //---------------------------------
  // Vue Component data
  //---------------------------------
  public quillSlideId: string = '';
  public quillSelectionRange: QuillSelectionRange | null = null;
  public customToolbar: any = {
    modules: {
      toolbar: {
        container: [
          ["bold", "italic", "underline", "strike", "link", "slideLink"],
          [
            { 'header': [1, 2, 3, 4, 5, 6, false] },
            {
              color: [
                AppUserModel.getInstance().project.config.ui.primaryColor,
                AppUserModel.getInstance().project.config.ui.secundaryColor,
                AppUserModel.getInstance().project.config.ui.tertiaryColor,
              ]
            }
          ],
          [{align: ""}, {align: "center"}, {align: "right"}, {align: "justify"}],
          ["blockquote"],
          [{list: "ordered"}, {list: "bullet"}, {list: "check"}],
          [{indent: "-1"}, {indent: "+1"}],
        ],
        handlers: {
          slideLink: this.onSlideLinkClick
        }
      }
    }
  };

  //---------------------------------
  // Public / lifecycle methods
  //---------------------------------
  public onSlideLinkClick() {
    const selection = window.getSelection();
    this.quillSelectionRange = this.$refs.editor.quill.getSelection();

    this.$nextTick(() => {
      if (selection) {
        const oRange = selection.getRangeAt(0);
        const oRect = oRange.getBoundingClientRect();

        // Get the position of the selected text relative to the parent Quill editor container
        let relativeTop = 20;
        let relativeLeft = 20;
        if (this.$refs.slideLinkToolTip.parentElement) {
          const parentRect = this.$refs.slideLinkToolTip.parentElement.getBoundingClientRect();
          relativeTop = oRect.top - parentRect.top;
          relativeLeft = oRect.left - parentRect.left;
        }

        this.$refs.slideLinkToolTip.style.top = `${relativeTop + 10}px`;
        this.$refs.slideLinkToolTip.style.left = `${relativeLeft}px`;
      }
    });
  }

  public onInsertSlideLink() {
    if (this.quillSelectionRange && this.quillSlideId) {
      const { index, length } = this.quillSelectionRange;
      const selectedText = this.$refs.editor.quill.getText(index, length);

      this.$refs.editor.quill.deleteText(index, length);

      const newHtml = `<a href="javascript:window.parent.Audience.triggerLink('SLIDE~${this.quillSlideId}~')">${selectedText}</a>`;

      this.$refs.editor.quill.clipboard.dangerouslyPasteHTML(index, newHtml);
      this.quillSelectionRange = null;
      this.quillSlideId = '';
    }
  }
}

interface QuillSelectionRange {
  index: number;
  length: number;
}
</script>

<style scoped lang="scss">
::v-deep {
  .ql-slideLink {
    background: url('/public/img/icons/salesdrive-icon-light.svg') no-repeat 3px 3px !important;
  }

  .ql-tooltip, .ql-tooltip input {
    border-radius: .25rem;
  }
}
</style>
