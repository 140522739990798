export enum ActivityCategoryType
{
    LIFE_LINE = "LIFE_LINE",
    COMMUNICATION = "COMMUNICATION",
    PORTAL = "PORTAL",
    MEETING = "MEETING"
}



export enum ActivityType
{

    //lifeline
    LIFE_SPAN = "LIFE_SPAN",
    PROPERTY_CHANGE = "PROPERTY_CHANGE",

    //communication
    SHARE_EMAIL_MESSAGE = "SHARE_EMAIL_MESSAGE", // when the audience is sent an email accompanying a content share, shows no poster and no name
    EMAIL_MESSAGE = "EMAIL_MESSAGE",
    AUTOMATED_EMAIL_MESSAGE = "AUTOMATED_EMAIL_MESSAGE", //eg: "a shared presentation will expire in 3 days"
    SMS_MESSAGE = "SMS_MESSAGE",
    AUTOMATED_SMS_MESSAGE = "AUTOMATED_SMS_MESSAGE",
    TEXT_CHAT = "TEXT_CHAT",
    VIDEO_CHAT = "VIDEO_CHAT",
    PHONE_CALL = "PHONE_CALL",

    //portal
    PORTAL_ENABLED_SPAN = "PORTAL_ENABLED_SPAN",
    PORTAL_VISIT_DENIED = "PORTAL_VISIT_DENIED", // when the audience is trying to log in to the portal, but is denied access, shows no poster and no name
    PORTAL_SESSION = "PORTAL_SESSION", // when the audience logs into the portal, shows no poster and no name
    SHARED_CONTENT = "SHARED_CONTENT",     //when content is shared to the portal, shows content poster and name
    UNSHARED_CONTENT = "UNSHARED_CONTENT", //when content is unshared from the portal, shows content poster and name
    FORWARDED = "FORWARDED", //when content is forwarded to a friend (visitor/audience?) in the portal
    LIKED = "LIKED", //when content is flagged as liked in the portal
    GUEST_SHARE = "GUEST_SHARE",

    //meeting
    MEETING_SESSION = "MEETING_SESSION",

    //these are sub sessions of a meeting or portal session
    PRESENTATION_SESSION = "PRESENTATION_SESSION", // when the audience is given/views a presentation during a meeting/portal session, shows content poster and name
    APP_SESSION = "APP_SESSION",
    FILE_SESSION = "FILE_SESSION",

    AUDIENCE_FILE_SESSION = "AUDIENCE_FILE_SESSION",

    //these are subsessions of a presentation session
    SLIDE_SESSION = 'SLIDE_SESSION',
    CHAPTER_SESSION = 'CHAPTER_SESSION',
    DECK_SESSION = 'DECK_SESSION',
    PAGE_SESSION = "PAGE_SESSION",

    //migrated from 1.X
    MIGRATED_INVITATION = 'MIGRATED_INVITATION',
    MIGRATED_PRESENTATION_SESSION = 'MIGRATED_PRESENTATION_SESSION',
    //MIGRATED_PROPOSAL = 'MIGRATED_PROPOSAL',
    MIGRATED_PROPOSAL_SENT = 'MIGRATED_PROPOSAL_SENT',
    MIGRATED_PROPOSAL_VIEWED = 'MIGRATED_PROPOSAL_VIEWED'

}

export enum ActivityActionTypeFilter {
    ALL = "ALL",
    PRESENTED = "PRESENTED",
    VIEWED = "VIEWED",
    VISITED = "VISITED",
}

/* use only when really needed
export enum ActivitySubjectType
{
    PRESENTATION =  "PRESENTATION",
    CONTENT_APP =  "CONTENT_APP",
    CONTENT_FILE =  "CONTENT_FILE",
    AR_IMAGE =  "AR_IMAGE",
    PROPOSAL =  "PROPOSAL",
    SMS_MESSAGE = "SMS_MESSAGE"
}

 */
