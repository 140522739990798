<template>
  <div class="start-box">
    <h4 class="start-box__title" v-html="$t('GamificationFlowTitle')"></h4>
    <img class="start-box__background" src="img/gamification/gamification-start-bg.png" />
    <img class="start-box__foreground" src="img/gamification/gamification-start-fg.png" />
  </div>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import UserInfo from "@/gamification/summary/_view/UserInfo.vue";
import UserCoins from "@/gamification/summary/_view/UserCoins.vue";
import UserBadge from "@/gamification/summary/_view/UserStatus.vue";
import UserRanking from "@/gamification/summary/_view/UserRanking.vue";
import UserStatus from "@/gamification/summary/_view/UserStatus.vue";
import {IGamificationProjectConfigDto} from "@/gamification/_model/gamification.dto";
import AppUserModel from "@/project/user/_model/AppUserModel";

@Component({
  components: {}
})
export default class StartBox extends Vue {

}
</script>

