import {IRoomDto, IRoomUsersSoDataDto} from "./_model/room.dto";
import {CobrowseRoomType, RoomEvent, RoomStatusType} from "./_model/room.constants";
import SharedObject from "../shared_object/SharedObject";
import {ISharedObjectDataDto, ISharedObjectDto} from "../shared_object/shared_object.dto";
import {IChatSoDataDto} from "../chat/_model/chat.dto";
import CobrowseManager from "../CobrowseManager";
import {SharedObjectType} from "../shared_object/shared_object.constants";
import {ICobrowseRoomUserDto, ICobrowseUserDto, ICobrowseUserMouseDto} from "../cobrowse_user/_model/cobrowse_user.dto";
import {MiniSignal} from "mini-signals";



class Room implements IRoomDto
{

    public identifier:string;
    public roomType:CobrowseRoomType;
    public roomStatus:RoomStatusType = RoomStatusType.IDLE;

    private _sharedObjects:SharedObject<ISharedObjectDataDto>[] = [];
    public roomUsersSO?:SharedObject<IRoomUsersSoDataDto>;
    public roomChatSO?:SharedObject<IChatSoDataDto>;

    public yourself?:ICobrowseRoomUserDto;

    private mouseCapturedTimeStamp:number = new Date().getTime();
    private mouseCaptureDelay:number = 50;

    public roomEvent = new MiniSignal();

    constructor(p_roomDto:IRoomDto)
    {
        this.identifier = p_roomDto.identifier;

        this.roomType = p_roomDto.roomType;

        if (p_roomDto.sharedObjectDtos)
        {
            for (let identifier in p_roomDto.sharedObjectDtos)
            {
                if (p_roomDto.sharedObjectDtos.hasOwnProperty(identifier))
                {
                    this.setSharedObject(p_roomDto.sharedObjectDtos[identifier]);
                }
            }
        }

        //set main SO's
        this.roomUsersSO = this.getSharedObject(SharedObjectType.ROOM_USERS) as SharedObject<IRoomUsersSoDataDto>;
        this.roomChatSO = this.getSharedObject(SharedObjectType.CHAT) as SharedObject<IChatSoDataDto>;

        //set yourself
        this.yourself = this.roomUsersSO.data.users.find(user => user.cobrowseUserID === CobrowseManager.getInstance().cobrowseUser.cobrowseUserID);
    }


    // set
    public setSharedObject(p_soDto:ISharedObjectDto)
    {
        if (!this.getSharedObject(p_soDto.identifier))
        {
            const so:SharedObject<ISharedObjectDataDto> = new SharedObject<ISharedObjectDataDto>(this, p_soDto.identifier, p_soDto.data);
            this._sharedObjects.push(so);
        }
    }



    public getSharedObject(p_identifier:string):SharedObject<ISharedObjectDataDto> | undefined
    {
        return this._sharedObjects.find(so => {return (so.identifier === p_identifier)});
    }

    //register a custom SO
    public registerSharedObject(p_identifier:string, p_data:ISharedObjectDataDto)
    {
        // console.log("registerSharedObject", p_identifier);
        if (!this.getSharedObject(p_identifier)) //if it doesn't exist yet
        {
            //register on server, the server will "sSetsSO" it for all users
            CobrowseManager.getInstance().socket.emit("cRegistersSO", this.identifier, p_identifier, p_data);
        }
    }


    public emitMouseMove(p_userMouse:ICobrowseUserMouseDto){
        // if (this.yourself && this.yourself.interactionState && this.yourself.interactionState > 0)
        if (this.yourself)
        {
            if (new Date().getTime() - this.mouseCapturedTimeStamp > this.mouseCaptureDelay)
            {
                this.mouseCapturedTimeStamp = new Date().getTime();

                this.yourself.userMouse = p_userMouse;
                this.roomUsersSO!.emitChanges();
            }
        }

    }


    //these "fire and forget" events don't keep any data on the server
    //these events are "perishable" and are sent back to the emitter also
    //eg a user raising a hand, or a click event that needs to be dispatched
    public emitEvent(p_event:string, p_data:any = undefined)
    {
        CobrowseManager.getInstance().socket.emit("cEmitsRoomEvent", this.identifier, p_event, p_data);
    }

    //cleanup before the room gets killed
    public cleanup()
    {
        this._sharedObjects = [];
        this.roomUsersSO = undefined;
        this.roomChatSO = undefined;
        this.yourself = undefined;
    }

}

export default Room;
