<template>
  <div class="main-sub-page home-page">

    <HomeHeader></HomeHeader>

    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>

  </div>
</template>


<script lang="ts">
import {Component, Vue} from "vue-property-decorator";
import AudiencesOverview from "@/audience/_view/AudiencesOverview.vue";
import HomeMenu from "@/_view/menu/HomeMenu.vue";
import IntroductionBox from "@/help/introduction/_view/IntroductionBox.vue";
import HomeHeader from "@/_view/menu/HomeHeader.vue";

@Component({
    components: {HomeHeader, IntroductionBox, AudiencesOverview, HomeMenu}
})
export default class HomePage extends Vue {
}
</script>
