export enum SessionEnvironment
{
    APP = "APP",
    PORTAL = "PORTAL",
    BROWSER_EMBEDDED = "BROWSER_EMBEDDED",
    STANDALONE = "STANDALONE"
}


export enum SessionMode
{
    VIEWER = "VIEWER",
    PREPARE = "PREPARE",
    PRESENT = "PRESENT",
    TRAIN = "TRAIN",
    PRESENTATION_EDITOR = "PRESENTATION_EDITOR",
    SLIDE_EDITOR = "SLIDE_EDITOR"

}

