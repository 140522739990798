import {
    IDataItemDefinitionDto,
    IPropertyDefinitionDto,
    IXlsColumn,
    IXlsImportResult
} from "@/data_tool/_model/data_tool.dto";
import PropertyModel from "@/data_tool/data_item/_model/PropertyModel";
import {DataModel} from "@/data_tool/_model/DataModel";
import DataProviderModel from "@/data_tool/data_provider/_model/DataProviderModel";
import {DtoType} from "@/_model/app.constants";
import languageManager, {IMultiLangString} from "@/__libs/language_manager/LanguageManager";
import AppUserModel from "@/project/user/_model/AppUserModel";
import DataProviderEntryModel from "@/data_tool/data_provider/_model/DataProviderEntryModel";

export default class DataItemModel extends DataModel
{

    public itemDefinition:IDataItemDefinitionDto;

    public properties:PropertyModel[] = [];

    public nameProp:PropertyModel | null = null;  //a reference to the name property

    public posterProp:PropertyModel | null = null;  //a reference to the poster property, if present


    // public idProp?:PropertyModel; //a reference to the id property

    public __id:string = ""; //needed when autonumbering is needed

    //---------------------------------
    // Constructor
    //---------------------------------

    constructor(p_itemDefinition:IDataItemDefinitionDto, p_data:any, p_dataProvider:DataProviderModel, p_parent:DataModel | null)
    {
        super(p_dataProvider, p_parent);
        this.itemDefinition = p_itemDefinition;
        if (p_parent instanceof PropertyModel || p_parent instanceof DataProviderEntryModel)
        {
            this.includeInLogPath = false;
        }
        this.build(p_data);
    }

    //---------------------------------
    // Public Methods
    //---------------------------------

    public build(p_data:any)
    {
        for (let j = 0; j < this.itemDefinition.properties.length; j++)
        {
            const propDefinition:IPropertyDefinitionDto = this.itemDefinition.properties[j];
            const propData:any = p_data ? p_data[propDefinition.identifier] : null;
            const propertyModel:PropertyModel = new PropertyModel(propDefinition, propData, this.dataProvider, this);
            if (propDefinition.identifier === this.itemDefinition.nameProperty)
            {
                this.nameProp = propertyModel;
            }
            if (propDefinition.identifier === this.itemDefinition.posterProperty)
            {
                this.posterProp = propertyModel;
            }
            //old idProperty code
            // if (propDefinition.identifier === this.itemDefinition.idProperty)
            // {
            //     this.idProp = propertyModel;
            // }
            this.properties.push(propertyModel);
        }
        if (p_data && p_data.__id)
        {
            this.__id = p_data.__id;
        }
    }


    public getPropertyByIdentifier(p_identifier:string):PropertyModel | null
    {
        for (let i = 0; i < this.properties.length; i++)
        {
            const prop = this.properties[i];
            if (prop.propertyDefinition.identifier === p_identifier)
            {
                return prop;
            }
        }
        return null;
    }


    public mapToDto(p_dtoType:DtoType):any
    {
        if (p_dtoType === DtoType.BODY)
        {
            const dto:any = {};
            for (let i = 0; i < this.properties.length; i++)
            {
                const property = this.properties[i];
                dto[property.propertyDefinition.identifier] = property.mapToDto(DtoType.BODY);
                if (this.__id.length > 0) //there is autonumbering
                {
                    dto.__id = this.__id;
                }
            }
            return dto;
        }
    }

    public getLogName():string
    {
        if (this.nameProp)
        {
            return this.nameProp.displayedValue;
        }
        else
        {
            return "unknown dataitem";
        }
    }


    public mapToRows(p_rows:any[], p_columnPrefix:string,  p_startRowIndex:number)
    {
        if (!p_rows[p_startRowIndex])
        {
            p_rows[p_startRowIndex] = {};
        }

        const targetRowIndex:number = p_startRowIndex;
        if (this.__id.length > 0) //there is autonumbering, so an __id column is needed
        {
            p_rows[p_startRowIndex][`${p_columnPrefix}${this.itemDefinition.identifier}__id`] = this.__id;
        }

        //fill columns for the properties
        for (let i = 0; i < this.properties.length; i++)
        {
            const property = this.properties[i];
            property.mapToRows(p_rows, p_columnPrefix, targetRowIndex);
        }
    }

    public getNeededRows():number
    {
        let neededRows:number = 1;
        //ask each property how many rows it needs
        for (let i = 0; i < this.properties.length; i++)
        {
            const property = this.properties[i];
            neededRows = Math.max(neededRows, property.getNeededRows());
        }
        // console.log(this.logModelPath(),neededRows);

        return neededRows;
    }



    public static mapFromRows(p_dataProvider:DataProviderModel,p_dataItemDefinition:IDataItemDefinitionDto,  p_rows:any[], p_columns:IXlsColumn[], p_level:number, p_columnPrefix:string, p_result:IXlsImportResult)
    {
        const dto:any = {};

        for (let j = 0; j < p_dataItemDefinition.properties.length; j++)
        {
            const propDefinition:IPropertyDefinitionDto = p_dataItemDefinition.properties[j];
            dto[propDefinition.identifier] = PropertyModel.mapFromRows(p_dataProvider, propDefinition,  p_rows, p_columns, p_level, p_columnPrefix, p_result);
        }

        // console.log(p_rows);
        return dto;

    }


}
