<template>
  <div class="sync-widget sync-widget--offline-status" :class="{ 'sync-widget--small' : small }">

    <div class="sync-widget__icon">
      <transition name="fade">
        <div v-if="syncModel.isSyncing && !syncModel.isPaused" class="d-flex align-items-center justify-content-center">
          <b-spinner variant="dark" type="grow"></b-spinner>
        </div>
      </transition>
      <transition name="fade">
        <i v-if="syncModel.isSyncing && syncModel.isPaused" class="fas fa-pause fa-2x"></i>
      </transition>
      <transition name="fade">
        <i v-if="!syncModel.isSyncing && !syncModel.isOfflineAvailable" class="fas fa-cloud fa-2x"></i>
      </transition>
      <transition name="fade">
        <i v-if="!syncModel.isSyncing && syncModel.isOfflineAvailable" class="fas fa-cloud fa-2x text-success"></i>
      </transition>
    </div>

    <div class="sync-widget__text mt-2">
      <transition name="fade">
        <div v-if="syncModel.isSyncing && !syncModel.isPaused">
          {{ $t('SyncStatusWidgetSyncing') }}
        </div>
      </transition>
      <transition name="fade">
        <div v-if="syncModel.isSyncing && syncModel.isPaused">
          {{ $t('SyncStatusWidgetSyncingPaused') }}
        </div>
      </transition>
      <transition name="fade">
        <div v-if="!syncModel.isSyncing && !syncModel.isOfflineAvailable">
          {{ $t('SyncStatusWidgetNotReady') }}
        </div>
      </transition>
      <transition name="fade">
        <div v-if="!syncModel.isSyncing && syncModel.isOfflineAvailable">
          {{ $t('SyncStatusWidgetReady') }}
        </div>
      </transition>
    </div>

  </div>
</template>


<script lang="ts">
import {Component, Prop, Vue} from "vue-property-decorator";
import SyncModel from "@/sync/_model/SyncModel";

@Component({
  components: {}
})
export default class SyncStatusWidget extends Vue {

  @Prop() public small!: boolean;

  private syncModel: SyncModel = SyncModel.getInstance();

}
</script>
