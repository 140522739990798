<template>
    <div class="sub-page custom-page">

        <iframe class="custom-page__frame" ref="pageFrame" v-on:load="_onIframeLoad()"></iframe>

    </div>
</template>

<script lang="ts">
    import {Component, Prop, Vue} from "vue-property-decorator";
    import appUserController from "@/project/user/_controller/AppUserController";
    import AppUserModel from "@/project/user/_model/AppUserModel";

    @Component({
        components: {}
    })
    export default class CustomPage extends Vue
    {

        @Prop() private pageIdentifier?:string;

        public mounted()
        {
            this._setPlayerUrl();
        }

        private async _setPlayerUrl()
        {
            const pageFrame:HTMLIFrameElement = this.$refs.pageFrame as HTMLIFrameElement;

            if (pageFrame)
            {
                const authToken:string = await appUserController.getAuthToken() as string;

                const appUser:AppUserModel = AppUserModel.getInstance();
                const storageUrl:string = appUser.project.storageUrl;

                let url:string;
                if (process.env.VUE_APP_DEV_STORAGE_URL && process.env.NODE_ENV === "development")
                {
                    url = `${process.env.VUE_APP_DEV_STORAGE_URL}${appUser.project.identifier}/pwaPages/${this.pageIdentifier}/index.html?storageurl=${appUser.project.storageUrl}&`
                }
                else
                {
                    url = `${storageUrl}/pwaPages/${this.pageIdentifier}/index.html?`
                }
                url += `authtoken=${authToken}&apiurl=${appUser.project.apiUrl}`;

                pageFrame.src = url;
            }

        }

        private _onIframeLoad()
        {
            const pageFrame:HTMLIFrameElement = this.$refs.pageFrame as HTMLIFrameElement;
            pageFrame.contentWindow!.focus();
        }

    }
</script>
