import {
    IDataItemDefinitionDto, IXlsColumn,
    IXlsImportParseMessage,
    IXlsImportResult,
    IXlsImportWorkbookResult
} from "@/data_tool/_model/data_tool.dto";
import PropertyModel from "@/data_tool/data_item/_model/PropertyModel";
import {DataModel} from "@/data_tool/_model/DataModel";
import DataProviderModel from "@/data_tool/data_provider/_model/DataProviderModel";
import {DtoType} from "@/_model/app.constants";
import DataItemModel from "@/data_tool/data_item/_model/DataItemModel";
import DataProviderEntryModel from "@/data_tool/data_provider/_model/DataProviderEntryModel";
import {v4 as uuidv4} from "uuid";
import SelectionState from "@/__libs/_model/SelectionState";
import languageManager, {IMultiLangString} from "@/__libs/language_manager/LanguageManager";
import AppUserModel from "@/project/user/_model/AppUserModel";
import DataItemListSelectionState from "@/data_tool/data_item/_model/DataItemListSelectionState";

export default class DataItemListModel extends DataModel
{


    public itemDefinition:IDataItemDefinitionDto; //the definition for all the items in this list

    public list:DataItemModel[] = [];

    public needForIds:boolean = false;

    // public useAutoIds:boolean = false;

    public selState:DataItemListSelectionState<DataItemModel> = new DataItemListSelectionState<DataItemModel>();

    protected includeInLogPath:boolean = false;

    //---------------------------------
    // Constructor
    //---------------------------------

    constructor(p_itemDefinition:IDataItemDefinitionDto, p_data:any, p_dataProvider:DataProviderModel, p_parent:DataModel | null)
    {
        super(p_dataProvider, p_parent);
        this.itemDefinition = p_itemDefinition;
        this.build(p_data);

        //if the itemlist is within an entry (and not within a property), and can be used as a dtp there is a need for Ids
        if (p_parent instanceof DataProviderEntryModel && this.itemDefinition.canBeUsedAsDataProvider)
        {
            this.needForIds = true;
            // if(!this.itemDefinition.idProperty)// and if there is  no idProperty, autonumbering is needed
            // {
            //     this.useAutoIds = true;
            // }
            this.dataProvider.dataItemListsDict.setItem(this, `${this.dataProvider.ID}#${this.itemDefinition.identifier}`); //for internal use of SELECT_LIST and ITEM_DICTIONARY
        }

    }

    //---------------------------------
    // Public Methods
    //---------------------------------

    public build(p_data:any)
    {
        if (p_data)
        {
            for (let i = 0; i < p_data.length; i++)
            {
                this.list.push(new DataItemModel(this.itemDefinition, p_data[i], this.dataProvider, this));
            }
        }
    }


    public addItem(p_dataItem:DataItemModel)
    {


        if (this.needForIds)
        {
            p_dataItem.__id = this.getNewAutoId();
        }

        //old idProp code
        // if (this.useAutoIds)
        // {
        //     p_dataItem.__id = this.getNewAutoId();
        // }
        // else if (this.needForIds && p_dataItem.idProp)
        // {
        //     p_dataItem.idProp.rawValue = this.getNewAutoId(); //disregarding a default value, a unique rawvalue is set, but the displayed value remains invalidated untill it is replaced by a proper unique value by the user
        // }
        this.list.push(p_dataItem);
        if (this.__parent)
        {
            this.__parent.childValidityChange(this.invalidChildren.length === 0, this);
            this.__parent.validate();
        }

        //trigger delta
        this.dataProvider.hasChanges = true;
    }


    public deleteItem(p_dataItem:DataItemModel):number
    {

        const entityIndex = this.list.indexOf(p_dataItem);
        if (entityIndex >= 0)
        {
            this.list.splice(entityIndex, 1);
        }

        const childIndex = this.invalidChildren.indexOf(p_dataItem);

        this.invalidChildren.splice(childIndex, 1); //remove it from the invalidChildren list
        if (this.__parent)
        {
            this.__parent.childValidityChange(this.invalidChildren.length === 0, this);
            this.__parent.validate();
        }

        //trigger delta
        this.dataProvider.hasChanges = true;

        return this.list.length;
    }


    public orderByProperty(p_property:PropertyModel)
    {


    }


    public setUniqueProperty(p_property:PropertyModel)
    {
    }

    public sort()
    {
        //vuedraggable has already sorted the array, just flag there are changes for now
        this.dataProvider.hasChanges = true;

    }

    public getNewAutoId():string
    {
        return uuidv4(); //because when an item gets deleted, its number can be retaken and therefore reference an older existing item.
        // Also easier when setting rawvalues for new items with an idProperty defined (so no create item popup needed, rawvalue is set to uuidv4: the raw value is set but not validated (so it can be used in select lists)


        // let autoId = 1;
        // for (let i = 0; i < this.list.length; i++)
        // {
        //     const item: DataItemModel = this.list[i];
        //     autoId = Math.max(autoId, item.__id + 1);
        //
        //     // const refNumber: number = parseInt(item.__id as string);
        //     // if (!isNaN(refNumber))  //if the reference is a number
        //     // {
        //     //     autoId = Math.max(autoId, refNumber + 1);
        //     // }
        // }
        // return autoId;

    }

    public mapToDto(p_dtoType:DtoType):any
    {
        if (p_dtoType === DtoType.BODY)
        {
            const dto:any[] = [];
            for (let i = 0; i < this.list.length; i++)
            {
                dto.push(this.list[i].mapToDto(DtoType.BODY));
            }
            return dto;
        }
    }

    public getLogName():string
    {
        return "list";
    }

    public mapToRows(p_rows:any[], p_columnPrefix:string, p_startRowIndex:number)
    {
        //p_startRowIndex is the index at which you should start inserting the first item row
        let nextRowIndex:number = p_startRowIndex;

        for (let i = 0; i < this.list.length; i++)
        {
            if (!p_rows[nextRowIndex])
            {
                //if the next row isn't created yet (by a previous prop on the same level), create it
                //problem, need to know how many rows where needed for the created item, to define the "nextRowIndex" for the next item...
                p_rows[nextRowIndex] = {};
            }

            const item = this.list[i];
            item.mapToRows(p_rows, p_columnPrefix, nextRowIndex);

            //...so ask the item how many rows it needed, to increase the nextRowIndex (the item will ask his properties, which in turn will go deeper recursively)
            const neededRows:number = item.getNeededRows();
            nextRowIndex += neededRows;
            // console.log(item.getLogName() , "needed", neededRows, "so next item starts on ", nextRowIndex);


        }
    }

    public getNeededRows():number
    {
        let neededRows:number = 0;
        for (let i = 0; i < this.list.length; i++)
        {
            const item = this.list[i];
            neededRows += Math.max(1, item.getNeededRows()); //each item in the row needs at least 1 row
        }
        // console.log(this.logModelPath(), neededRows);
        return neededRows;
    }

    public static mapFromRows(p_dataProvider:DataProviderModel, p_dataItemDefinition:IDataItemDefinitionDto, p_rows:any[], p_columns:IXlsColumn[], p_level:number, p_columnPrefix:string, p_result:IXlsImportResult)
    {

        const data:any[] = []
        // console.log(p_rows);

        //try to find out which rows consist of 1 item in this list, and whether it is an existing record(contains an __id), or a new one

        const items:any[] = []; //an array of objects to keep the rows of each item in
        let item:any;

        let targetColumns:IXlsColumn[] = p_columns.filter(p_column => p_column.level === p_level);

        if (p_level > 0)
        {
            //if its a deeper level (one with a property), than the property identifier (p_columnPrefix) is needed as differentiator
            // (because there can be 2 properties based on the same definition, eg a "redPictures" prop and a "greenPictures" prop which are both lists of a "officePicture" itemdefinition )
            targetColumns = targetColumns.filter(p_targetColumn => p_targetColumn.identifier.indexOf(p_columnPrefix) === 0);
        }
        // const logTargetColumns:string[] = [];
        // for (let i = 0; i < targetColumns.length; i++)
        // {
        //     logTargetColumns.push(targetColumns[i].identifier);
        // }
        // console.log(p_level, p_columnPrefix, logTargetColumns);

        for (let i = 0; i < p_rows.length; i++)
        {
            const row = p_rows[i];
            //if you find a primitive property cellvalue from this item (level) in the next row, which is filled in, we can assume that it is the start of a new item
            //so loop over the properties of this itemdefinition in each row, and check for undefineds


            for (let j = 0; j < targetColumns.length; j++)
            {
                const column:IXlsColumn = targetColumns[j];
                const cellValue:any = row[column.identifier];
                if (cellValue !== null && cellValue !== undefined) //if there is a value in this cell...
                {
                    const isEmptyString:boolean = cellValue.toString().trim().length === 0;
                    if (!isEmptyString)
                    {
                        item = {rows: []}; // it's the beginning of a new item
                        items.push(item);
                        // console.log(`starting new '${p_dataItemDefinition.identifier}' because found value '${cellValue}' in cell ${column.letter}:${row.___rowNr}`);
                        break; //found a value, no need to look for more cellvalues in this row
                    }
                }
            }
            // console.log(logColumnNames);
            if (item)
            {
                item.rows.push(row);
            }
            else
            {
                // p_result.isSuccessful = false;
                // p_result.fatalMessage = {type:"BLANK_ROW_FOUND"};
                // console.log("BLANK_ROW_FOUND", row, "was looking in columns:", logColumnNames, p_level);
                // return;
            }

        }

        //check if there is an id column
        const thisIDColumn:IXlsColumn | undefined = targetColumns.find(p_column => p_column.isIDcolumn);

        //for each item found, create a new DataItemModel and pass on the rows
        for (let i = 0; i < items.length; i++)
        {
            const itemDto:any = DataItemModel.mapFromRows(p_dataProvider, p_dataItemDefinition, items[i].rows, p_columns, p_level, p_columnPrefix, p_result);

            if (thisIDColumn)
            {
                let __id:string;
                const idCellValue:any = items[i].rows[0][thisIDColumn.identifier];
                if (idCellValue)
                {
                    __id = idCellValue; //todo check for doubles : if present more than once (probably copied and forgot to delete, get a new __id for the last item then)
                }
                else
                {   //__id not present(an item created in the excel itself), so this is a new item > add a new __id
                    p_result.parseMessages.push({type: "NEW_ITEM", columnIdentifier: thisIDColumn.identifier});
                    // console.log("new item:", itemDto);
                    __id = uuidv4();
                }
                itemDto.__id = __id;
            }
            data.push(itemDto);
        }
        return data;
    }



}
