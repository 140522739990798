<template>
  <div class="offline-pdf-viewer">
    <div v-if="currentViewerState === viewerState.LOADING" class="empty-loader">
      <b-spinner variant="dark"></b-spinner>
    </div>

    <div v-if="currentViewerState === viewerState.ERROR" class="empty-message">
      {{ $t('TrackPageActivityDetailPdfEmpty') }}
    </div>

    <div v-if="currentViewerState === viewerState.ANALYTICS" class="analytics-view">
      <div id="preview-render-container"></div>
      <div class="large-view">
        <canvas id="large-canvas"></canvas>
      </div>
    </div>

    <vue-pdf-app
      v-if="currentViewerState === viewerState.VIEW"
      :config="viewerConfig"
      :pdf="pdfUrl"
      :pageNumber="1"
      @pages-rendered="handleRendered"
    />
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import {EngineMode} from "@/presentation/player/_model/player.constants";
import VuePdfApp from 'vue-pdf-app';
// @ts-ignore;
import * as pdfjs from 'pdfjs-dist/es5/build/pdf.js';
// @ts-ignore;
import * as pdfjsworker from 'pdfjs-dist/es5/build/pdf.worker.entry.js';
import audienceSessionController from "@/audience/session/_controller/AudienceSessionController";
import {IAudienceSessionDto} from "@/audience/session/_model/audience_session.dto";
import fileManager, {FileResponse} from "@/_controller/FileManager";
import {IContentFileBodyDto} from "@/content_file/_model/content_file.dto";

enum ViewerState {
  LOADING,
  ANALYTICS,
  VIEW,
  ERROR
}

@Component({
  components: {VuePdfApp}
})
export default class OfflinePdfPlayer extends Vue {
  @Prop() private readonly pdfUrl!:string;
  @Prop() private readonly engMode!:EngineMode;
  @Prop() private readonly sessionIdentifier!:string;

  private lastPageChange:number = Date.now();
  private viewThreshold:number = 2;

  private loadedPDF:any = null;
  private currentPage = 0;
  private prevPage:number = 1;

  public readonly viewerConfig = {
    toolbar: {
      toolbarViewerRight: {
        presentationMode: false,
        openFile: false,
        print: true,
        download: false,
        viewBookmark: false,
      }
    }
  }

  public readonly viewerState:typeof ViewerState = ViewerState;

  private currentViewerState:ViewerState = ViewerState.LOADING;

  async mounted() {
    if (this.engMode == EngineMode.ANALYTICS)
    {
      this.currentViewerState = ViewerState.LOADING;

      const session:IAudienceSessionDto | null = await audienceSessionController.getAudienceSession(this.sessionIdentifier);

      if (session && session.sessions && session.sessions.length > 0)
      {
        const fileResponse:FileResponse = await fileManager.fetchFileFromCdn(`contentFiles/${session.subjectIdentifier}/cfg.json`);
        if (fileResponse.hasSucceeded)
        {
          this.currentViewerState = ViewerState.ANALYTICS;
          const pdfSrc:string = fileManager.getFileUrl((fileResponse.result as IContentFileBodyDto).fileUri);
          pdfjs.GlobalWorkerOptions.workerSrc = pdfjsworker;
          await this.loadPDF(pdfSrc);
          for (let i = 0; i < session.sessions!.length; i++)
          {
            const pageSession = session.sessions![i];
            await this.renderSmallPage(parseInt(pageSession.subjectIdentifier!), pageSession.length!);
          }
          this.currentPage = parseInt(session.sessions![0].subjectIdentifier!);
          this.renderLargePage(this.currentPage);
        }
      }
      else
      {
        this.currentViewerState = ViewerState.ERROR;
      }
    }
    else
    {
      this.currentViewerState = ViewerState.VIEW;
    }
  }

  private handleRendered(pdfApp:any)
  {
    pdfApp.pdfViewer.eventBus.on('pagechanging', ({pageNumber}:{ pageNumber:number }) => {
      this.handlePageChange();
      this.prevPage = pageNumber;
    });
  }

  private handlePageChange()
  {
    const currentTime:number = Date.now();
    const timeDiff:number = (currentTime - this.lastPageChange) / 1000;
    if (this.viewThreshold < timeDiff)
    {
      this.$emit('page-viewed', this.prevPage, timeDiff);
    }
    this.lastPageChange = currentTime;
  }

  async loadPDF(p_src:string)
  {
    await pdfjs.getDocument(p_src).promise.then((pdf:any) => {
      this.loadedPDF = pdf;
    });
  }

  async renderSmallPage(pageNumber:number, secondsViewed:number)
  {
    if (!this.loadedPDF) return;

    // console.log("loading page " + pageNumber + "...");
    await this.loadedPDF.getPage(pageNumber).then((page:any) => {
      const scale = 1;
      const viewport = page.getViewport({scale: scale});

      const container = document.getElementById('preview-render-container');
      const canvas = document.createElement("canvas") as HTMLCanvasElement;
      const context = canvas.getContext('2d');
      canvas.height = viewport.height;
      canvas.width = viewport.width;
      canvas.onclick = () => {
        this.currentPage = pageNumber;
        this.renderLargePage(pageNumber);
      }
      const title = document.createElement("h6");
      title.innerText = this.$t('TrackPageActivityDetailPdfViewed', [pageNumber, secondsViewed]) as string;
      container?.appendChild(canvas);
      container?.appendChild(title);

      const renderContext = {
        canvasContext: context,
        viewport     : viewport
      };
      page.render(renderContext);
      // console.log("Page " + pageNumber + " rendered");
    });
  }

  async renderLargePage(pageNumber:number)
  {
    if (!this.loadedPDF) return;

    // console.log("loading page " + pageNumber + "...");
    await this.loadedPDF.getPage(pageNumber).then((page:any) => {
      // Scale is 2 to increase quality
      const scale = 2;
      const viewport = page.getViewport({scale: scale});

      const canvas = document.getElementById('large-canvas') as HTMLCanvasElement;
      const context = canvas.getContext('2d');
      canvas.height = viewport.height;
      canvas.width = viewport.width;

      const renderContext = {
        canvasContext: context,
        viewport     : viewport
      };
      page.render(renderContext);
      // console.log("Page " + pageNumber + " rendered");
    });
  }
}
</script>
