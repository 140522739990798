<template>
  <div class="project-detail box box--full-height box--scrollable" v-if="project">

    <h4 class="title mt-1 mb-4">{{ $t('ProjectDetailTitle') }}</h4>

    <table class="table table-sm table-striped table-borderless mb-5">
      <tbody>
      <tr>
        <th scope="row">{{ $t('ProjectDetailAppVersion') }}</th>
        <td>{{ appVersion }}</td>
      </tr>
      <tr>
        <th scope="row">{{ $t('ProjectDetailIdentifier') }}</th>
        <td>{{ project.identifier }}</td>
      </tr>
      <tr>
        <th scope="row">{{ $t('ProjectTierTitle') }}</th>
        <td>{{ $t(`ProjectTier_${project.tierConfig.tierType}`) }}</td>
      </tr>
      <tr>
        <th scope="row">{{ $t('ProjectDetailMaxFileSize') }}</th>
        <td>{{ maxFileSizeFileSize }}</td>
      </tr>
      <tr>
        <th scope="row">{{ $t('ProjectDetailUserPackAmount') }}</th>
        <td>{{ project.tierConfig.userPackAmount }}</td>
      </tr>
      <tr>
        <th scope="row">{{ $t('ProjectDetailPresentations') }}</th>
        <td>{{ project.tierConfig.hasPresentations }}</td>
      </tr>
      <tr>
        <th scope="row">{{ $t('ProjectDetailUsageInsights') }}</th>
        <td>{{ project.tierConfig.hasUsageInsights }}</td>
      </tr>
      <tr>
        <th scope="row">{{ $t('ProjectDetailContentInsights') }}</th>
        <td>{{ project.tierConfig.hasContentInsights }}</td>
      </tr>
      <tr>
        <th scope="row">{{ $t('ProjectDetailQuickLaunch') }}</th>
        <td>{{ project.tierConfig.hasQuickLaunch }}</td>
      </tr>
      <tr>
        <th scope="row">{{ $t('ProjectDetailGuests') }}</th>
        <td>{{ project.tierConfig.hasGuests }}</td>
      </tr>
      <tr>
        <th scope="row">{{ $t('ProjectDetailBrandedPwa') }}</th>
        <td>{{ project.tierConfig.hasBrandedPwa }}</td>
      </tr>
      <tr>
        <th scope="row">{{ $t('ProjectDetailBrandedPortal') }}</th>
        <td>{{ project.tierConfig.hasBrandedPortal }}</td>
      </tr>
      <tr>
        <th scope="row">{{ $t('ProjectDetailBrandedEmails') }}</th>
        <td>{{ project.tierConfig.hasBrandedEmails }}</td>
      </tr>
      </tbody>
    </table>

<!--    <b-button variant="success" block>-->
<!--      {{ $t('ProjectUpgradeButton') }}-->
<!--    </b-button>-->
    <b-button variant="success" block @click="contactSupport">
      {{ $t('ProjectContactSupportButton') }}
    </b-button>

  </div>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import ProjectModel from "@/project/_model/ProjectModel";
import AppUserModel from "@/project/user/_model/AppUserModel";
import fileSize from "filesize";
import AppModel from "@/_model/AppModel";
import {SUPPORT_EMAIL} from "@/_model/app.constants";

@Component({})
export default class ProjectSettings extends Vue {

  public project: ProjectModel = AppUserModel.getInstance().project;
  public appVersion: string | undefined = AppModel.getInstance().version;

  get maxFileSizeFileSize(): string {
    return fileSize(this.project.tierConfig.maxFileSize);
  }

  private contactSupport() {
    window.open(`mailto:${SUPPORT_EMAIL}`);
  }

}
</script>

