

 import EntityListController from "@/entity/_controller/EntityListController";
 import {EntityType} from "@/entity/_model/entity.constants";
 import contentListController from "@/content/_controller/ContentListController";
 import contentController from "@/content/_controller/ContentController";

 import audienceListController from "@/audience/_controller/AudienceListController";
 import audienceController from "@/audience/_controller/AudienceController";

 import assetFolderController from "@/asset_folder/_controller/AssetFolderController";
 import assetFolderListController from "@/asset_folder/_controller/AssetFolderListController";

 import presentationController from "@/presentation/_controller/PresentationController";

 import slideController from "@/slide/_controller/SlideController";
 import slideListController from "@/slide/_controller/SlideListController";

 import companyController from "@/company/_controller/CompanyController";
 import companyListController from "@/company/_controller/CompanyListController";

 import dataProviderController from "@/data_tool/data_provider/_controller/DataProviderController";
 import dataProviderListController from "@/data_tool/data_provider/_controller/DataProviderListController";

 import contentFileController from "@/content_file/_controller/ContentFileController";

 import EntityController from "@/entity/_controller/EntityController";


export default class EntityControllerFactory
{

    public static getController(p_entityType:EntityType):EntityController
    {
        switch (p_entityType)
        {
            case EntityType.AUDIENCE:
                return audienceController;

            case EntityType.ASSET_FOLDER:
                return assetFolderController;

            case EntityType.PRESENTATION:
                return presentationController;

            case EntityType.SLIDE:
                return slideController;

            case EntityType.CONTENT_FILE:
                return contentFileController;

            case EntityType.DATA_PROVIDER:
                return dataProviderController;

            case EntityType.COMPANY:
                return companyController;

                default:
                return contentController;
        }

    }


    public static getListController(p_entityType:EntityType):EntityListController<any>
    {
        switch (p_entityType)
        {
            case EntityType.AUDIENCE:
                return audienceListController;

            case EntityType.ASSET_FOLDER:
                return assetFolderListController;

            case EntityType.SLIDE:
                return slideListController;

            case EntityType.DATA_PROVIDER:
                return dataProviderListController;

            case EntityType.COMPANY:
                return companyListController;

            default:
                return contentListController;
        }

    }

}

