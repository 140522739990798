<template>
  <div class="youtube-channel-highlight" :class="{ 'animated': showAnimation }" @click="openYoutubeChannel">
    <div class="visual-holder">
      <svg class="visual" width="100%" height="100%" viewBox="0 0 54 54" version="1.1"
           xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve"
           xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-miterlimit:10;">
    <g transform="matrix(1,0,0,1,-1214.26,-29.8767)">
        <g transform="matrix(0.5,0,0,0.457077,0,0)">
            <g id="icons8-youtube-tutorials.svg" transform="matrix(0.741529,0,0,0.811165,2481.9,123.769)">
                <g transform="matrix(1,0,0,1,-72,-72)">
                    <path
                        d="M129.306,101.7C128.109,108 123.021,112.8 116.736,113.7C106.863,115.2 90.405,117 71.85,117C53.595,117 37.137,115.2 26.961,113.7C20.676,112.8 15.588,108 14.391,101.7C13.197,94.8 12,84.6 12,72C12,59.4 13.197,49.2 14.394,42.3C15.591,36 20.679,31.2 26.964,30.3C36.837,28.8 53.298,27 71.85,27C90.402,27 106.563,28.8 116.739,30.3C123.024,31.2 128.112,36 129.309,42.3C130.503,49.2 132,59.4 132,72C131.7,84.6 130.503,94.8 129.306,101.7Z"
                        style="fill:rgb(255,61,0);fill-rule:nonzero;"/>
                  <path
                      d="M102,63C101.01,63.51 99.99,63.99 99,64.5C95.01,66.51 90.99,68.49 87,70.5C81.99,72.99 76.98,75.48 72,78C67.02,75.48 62.01,72.99 57,70.5C51.99,68.01 46.98,65.52 42,63C51.99,59.01 62.01,54.99 72,51C81.99,54.99 92.01,59.01 102,63Z"
                      style="fill:white;fill-rule:nonzero;"/>
                  <path d="M87,69L87,70.5C81.99,72.99 76.98,75.48 72,78C67.02,75.48 62.01,72.99 57,70.5L57,69L87,69Z"
                        style="fill:white;fill-rule:nonzero;"/>
                  <path d="M74.67,83.37L87,77.22L87,93L57,93L57,77.22L69.33,83.37L72,84.72L74.67,83.37Z"
                        style="fill:white;fill-rule:nonzero;"/>
                  <path d="M99,63L99,81" style="fill:none;fill-rule:nonzero;stroke:white;stroke-width:6px;"/>
                </g>
            </g>
        </g>
    </g>
</svg>
    </div>
    <b-button variant="success"><span v-html="$t('YoutubeChannelHighlight1')" class="line-1"></span><span
        v-html="$t('YoutubeChannelHighlight2')" class="line-2"></span></b-button>
  </div>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import {RoutingIdentifier} from "@/router";
import AAImage from "@/_view/components/AAImage.vue";
import AppUserModel from "@/project/user/_model/AppUserModel";
import AAProfilePicture from "@/_view/components/AAProfilePicture.vue";
import {ExternalUrls} from "@/_model/app.constants";
import {TierType} from "@/project/_model/project.constants";

@Component({
    components: {AAProfilePicture, AAImage}
})
export default class GamificationHighlight extends Vue {

    private showAnimation: boolean = false;
    public appUser: AppUserModel = AppUserModel.getInstance();

    private async openYoutubeChannel() {
        if (AppUserModel.getInstance().project.tierConfig.tierType === TierType.EXPLORE) {
            window.open(ExternalUrls.YOUTUBE_CHANNEL_EXPLORE, '_blank');
        } else {
            window.open(ExternalUrls.YOUTUBE_CHANNEL_EXPLORE_PLUS, '_blank');
        }
    }

    public mounted() {
        if (!this.appUser.highlightShown) {
            this.appUser.highlightShown = true;
            this.showAnimation = true;
        }
    }

}
</script>
