import {ENTITY_CONFIG} from "@/entity/_model/entity.constants";
import EntityListController from "@/entity/_controller/EntityListController";

import {IEntityMetaDto} from "@/entity/_model/entity.dto";
import AssetFolderModel from "@/asset_folder/_model/AssetFolderModel";
import AssetFolderListModel from "@/asset_folder/_model/AssetFolderListModel";
import assetFolderController from "@/asset_folder/_controller/AssetFolderController";
import AssetFolderSelectionState from "@/asset_folder/_model/AssetFolderSelectionState";
import fileManager from "@/_controller/FileManager";
import AssetFileModel from "@/asset_folder/_model/AssetFileModel";


//contains all controller methods that can be applied on the list of assetFolders
class AssetFolderListController extends EntityListController<AssetFolderModel>
{

    constructor()
    {
        super(ENTITY_CONFIG.ASSET_FOLDER, AssetFolderListModel.getInstance(), assetFolderController)
    }

    //---------------------------------
    // Properties
    //---------------------------------

    private _assetFolderListModel:AssetFolderListModel = AssetFolderListModel.getInstance();

    //---------------------------------
    // Controller Methods
    //---------------------------------

    public selectFileByUri(p_selState:AssetFolderSelectionState, p_selectedFileUri:string)
    {
        //todo: problem, can only work when all assetfolder bodies are already loaded
        const asfID:string | null = fileManager.getAssetFolderIDFromUri(p_selectedFileUri);
        if (asfID)
        {
            const assetFolder:AssetFolderModel | null = this._assetFolderListModel.getEntityByID(asfID);
            if (assetFolder)
            {
                p_selState.selected = assetFolder;
                const assetFile:AssetFileModel | null = assetFolder.getAssetFileByUri(p_selectedFileUri);
                if (assetFile)
                {
                    p_selState.selectedFile = assetFile;
                }
            }
        }

    }

    //---------------------------------
    // Private Methods
    //---------------------------------

    protected __newEntity(p_dto:IEntityMetaDto):AssetFolderModel
    {
        return new AssetFolderModel(p_dto);
    }

}

//Singleton export
export default new AssetFolderListController();
