import {IModelDto} from "@/__libs/_model/model.dto";
import ContentModel from "@/content/_model/ContentModel";
import {EntityType, UploadStatus} from "@/entity/_model/entity.constants";
import {DtoType} from "@/_model/app.constants";
import {IContentFileBodyDto, IContentFileMetaDto} from "./content_file.dto";
import {FileImportSource, FileType} from "@/asset_folder/_model/asset_folder.constants";
import {IFileOutlineDto} from "@/asset_folder/_model/asset_folder.dto";

export default class ContentFileModel extends ContentModel implements IContentFileBodyDto, IContentFileMetaDto
{

    public entityType:EntityType = EntityType.CONTENT_FILE;

    public size:number = 0;
    public fileUri:string = "";
    public fileType:FileType = FileType.UNKNOWN;
    public importSource:FileImportSource = FileImportSource.LOCAL_STORAGE;
    public files:IFileOutlineDto[] = [];
    public totalFileSize:number = 0;

    //meta
    public uploadStatus:UploadStatus = UploadStatus.IDLE;
    public importedFrom:string = "";
    public importedIdentifier:string = "";


    //---------------------------------
    // Constructor
    //---------------------------------

    constructor(p_dto?:IModelDto)
    {
        super();
        if (p_dto)
        {
            this.mapFromDto(p_dto);
        }
    }

    //---------------------------------
    // Public Methods
    //---------------------------------

    public mapToDto(p_dtoType:DtoType):IModelDto
    {
        if (p_dtoType === DtoType.BODY)
        {
            const dto:IContentFileBodyDto = {
                ID           : this.ID,
                version      : this.version,
                name         : this.name,
                poster       : this.poster,
                notes        : this.notes,
                fileUri      : this.fileUri,
                fileType     : this.fileType,
                importSource : this.importSource,
                size         : this.size,
                files        : this.files,
                totalFileSize: this.totalFileSize,
                funnelStages : this.funnelStages,
            };
            return dto;
        }
        else
        {
            return super.mapToDto(p_dtoType);
        }
    }

}
