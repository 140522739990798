<template>
  <div class="hint" v-if="hint">
    <h6 class="hint__title mb-1" v-html="title"></h6>
    <p class="hint__text mb-4" v-html="text"></p>
    <div class="hint__buttons">
      <a class="hint__skip" @click="onSkip">{{ $t('HintSkipButton') }}</a>
      <b-button variant="primary" pill size="sm" @click="onNext">
        <span v-if="hasNext">{{ $t('HintNextButton') }}</span>
        <span v-if="!hasNext">{{ $t('HintDoneButton') }}</span>
      </b-button>
    </div>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from "vue-property-decorator";
import HintModel from "@/help/hint/_model/HintModel";
import hintListController from "@/help/hint/_controller/HintListController";
import HintListModel from "@/help/hint/_model/HintListModel";
import AppUserModel from "@/project/user/_model/AppUserModel";

@Component({
  components: {}
})
export default class Hint extends Vue {

  @Prop() private id!: string;

  public hintList: HintListModel = HintListModel.getInstance();
  public hint: HintModel | null = null;
  public nextHint: HintModel | null = null;

    get tierType(): string {
        return AppUserModel.getInstance().project.tierConfig.tierType;
    }

  get title(): string {
    return this.$t(`HintTitle_${this.id}`) as string;
  }

  get text(): string {
      if (this.$te(`HintText_${this.id}_${this.tierType}`)) {
        return this.$t(`HintText_${this.id}_${this.tierType}`) as string;
      } else {
          return this.$t(`HintText_${this.id}`) as string;
      }
  }

  get hasNext(): boolean {
    return this.nextHint !== null;
  }

  public created() {
    this.hint = this.hintList.getHintById(this.id);
    this.nextHint = this.hintList.getNextHintById(this.id);
  }

  private onSkip() {
    hintListController.skipAll();
  }

  private onNext() {
    if (this.hint) {
      this.hint.shown = true;
    }
    hintListController.showNext(this.id);
  }

}
</script>


