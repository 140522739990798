import {ENTITY_CONFIG, UploadStatus} from "@/entity/_model/entity.constants";
import EntityController from "@/entity/_controller/EntityController";
import AssetFolderModel from "@/asset_folder/_model/AssetFolderModel";
import apiManager, {ApiRequestType, ApiResponse} from "@/_controller/ApiManager";
import {IAssetFileDto, IFileOutlineDto} from "@/asset_folder/_model/asset_folder.dto";
import {
    AssetFolderViewMode,
    FILE_TYPE_CONFIG,
    FileImportSource,
    FileTargetType,
    FileType
} from "@/asset_folder/_model/asset_folder.constants";
import AssetFolderListModel from "@/asset_folder/_model/AssetFolderListModel";
import FileUtil from "@/__libs/utility/FileUtil";
import AppUserModel from "@/project/user/_model/AppUserModel";
import {IFileDto, IUploadFileResultDto} from "@/_model/app.constants";
import AssetFileModel from "@/asset_folder/_model/AssetFileModel";
import AssetFolderSelectionState from "@/asset_folder/_model/AssetFolderSelectionState";
import {IDeleteResultDto} from "@/entity/_model/entity.dto";
import fileManager from "@/_controller/FileManager";
import ContentFileModel from "@/content_file/_model/ContentFileModel";



//contains all (static) controller methods that can be initiated on a single asset folder
class ContentFileController extends EntityController
{


    //---------------------------------
    // Properties
    //---------------------------------


    //---------------------------------
    // Controller Methods
    //---------------------------------

    public async setFile(p_contenFile:ContentFileModel, p_file:File)
    {

        p_contenFile.uploadStatus = UploadStatus.UPLOADING;


        //resize image clientside
        const fileType:FileType = AssetFolderListModel.getFileTypeForExtension(FileUtil.getFileExtension(p_file.name));
        if (fileType === FileType.IMAGE)
        {
            const resizedBlob = await FileUtil.resizeImage(p_file, 1920, 1920);
            p_file = new File([resizedBlob], p_file.name, {type: `${p_file.type}`})
        }


        const response:ApiResponse<IUploadFileResultDto> = await apiManager.uploadFile(ApiRequestType.POST, `/client-api/content-files/${p_contenFile.ID}/files`, p_file);

        if (response.hasSucceeded)
        {
            const mainFile:IFileDto = response.result!.mainFile;
            p_contenFile.fileUri = `contentFile://${p_contenFile.ID}/${encodeURIComponent(mainFile.fileName)}`;
            p_contenFile.size = mainFile.size;
            p_contenFile.createdDate = mainFile.lastModified;
            p_contenFile.files.push({
                path   : mainFile.fileName,
                version: mainFile.hash,
                size   : mainFile.size
            });
            p_contenFile.totalFileSize = mainFile.size;
            if (response.result!.previewFile)
            {
                const previewFile:IFileDto = response.result!.previewFile;
                p_contenFile.poster[AppUserModel.getInstance().langCode] = `contentFile://${p_contenFile.ID}/${encodeURIComponent(previewFile.fileName)}`;
                p_contenFile.files.push({
                    path   : previewFile.fileName,
                    version: previewFile.hash,
                    size   : previewFile.size
                });
                p_contenFile.totalFileSize += previewFile.size;
            }

            p_contenFile.uploadStatus = UploadStatus.IDLE;

            await super.saveBody(p_contenFile);
        }
        else
        {
            p_contenFile.uploadStatus = UploadStatus.ERROR_UPLOADING;

            //todo: show toast
            console.log(response.error);
        }
    }

    public async deleteFile(p_assetFolder:AssetFolderModel, p_assetFile:AssetFileModel):Promise<boolean>
    {
        const mainFilePath:string = fileManager.getFilePathFromUri(p_assetFile.fileURI);
        let endPoint:string = `/client-api/${ENTITY_CONFIG.ASSET_FOLDER.apiPath}/${p_assetFolder.ID}/files/${mainFilePath}`;
        let response:ApiResponse<IDeleteResultDto> = await apiManager.sendApiRequest(ApiRequestType.DELETE, endPoint);

        if (response.hasSucceeded)
        {
            //delete it from the assetFiles
            const index:number = p_assetFolder.assetFiles.indexOf(p_assetFile);
            if (index >= 0)
            {
                p_assetFolder.assetFiles.splice(index, 1);
            }
            //delete it from the files
            this._removeFileOutline(p_assetFolder, mainFilePath);

            if (p_assetFile.fileType === FileType.IMAGE)
            {
                //it also has a preview, so delete it too
                const previewImagePath:string = fileManager.getFilePathFromUri(p_assetFile.previewImageURI);
                let endPoint:string = `/client-api/${ENTITY_CONFIG.ASSET_FOLDER.apiPath}/${p_assetFolder.ID}/files/${previewImagePath}`;
                apiManager.sendApiRequest(ApiRequestType.DELETE, endPoint);
                this._removeFileOutline(p_assetFolder, previewImagePath);
            }

            super.saveBody(p_assetFolder);
        }
        else
        {
            //todo: show toast
        }

        return response.hasSucceeded;
    }

    public async replaceFile(p_assetFolder:AssetFolderModel, p_assetFile:AssetFileModel, p_file:File):Promise<boolean>
    {
        const rememberMainFileURI:string = p_assetFile.fileURI;
        const rememberPreviewImageURI:string = p_assetFile.previewImageURI;
        const mainFilePath:string = fileManager.getFilePathFromUri(p_assetFile.fileURI);
        const previewImagePath:string = fileManager.getFilePathFromUri(p_assetFile.previewImageURI);


        p_assetFile.uploadStatus = UploadStatus.UPLOADING;
        p_assetFile.fileURI = "";
        if (p_assetFile.fileType === FileType.IMAGE)
        {
            p_assetFile.previewImageURI = AssetFolderListModel.getDefaultPreviewImageUriForFileType(FileType.IMAGE);
        }


        const response:ApiResponse<IUploadFileResultDto> = await apiManager.uploadFile(ApiRequestType.PUT, `/client-api/asset-folders/${p_assetFolder.ID}/files/${mainFilePath}`, p_file);

        if (response.hasSucceeded)
        {
            const mainFileOutline:IFileOutlineDto = {
                path   : response.result!.mainFile.path,
                version: response.result!.mainFile.hash,
                size   : response.result!.mainFile.size
            };
            this._updateFileOutline(p_assetFolder, mainFileOutline);
            p_assetFile.fileURI = rememberMainFileURI; //todo: could get cached by cloudfront, so wait for cache invalidation
            if (response.result!.previewFile)
            {
                const previewFileOutline:IFileOutlineDto = {
                    path   : response.result!.previewFile.path,
                    version: response.result!.previewFile.hash,
                    size   : response.result!.previewFile.size
                };
                this._updateFileOutline(p_assetFolder, previewFileOutline);
                p_assetFile.previewImageURI = rememberPreviewImageURI;
            }
            p_assetFile.uploadStatus = UploadStatus.IDLE;

            super.saveBody(p_assetFolder);
        }

        //todo: p_assetFolder.totalFileSize

        return response.hasSucceeded;
    }

    private _updateFileOutline(p_assetFolder:AssetFolderModel, p_fileOutline:IFileOutlineDto)
    {
        for (let i = 0; i < p_assetFolder.files.length; i++)
        {
            if (p_assetFolder.files[i].path === p_fileOutline.path)
            {
                console.log("udpated ", p_fileOutline.path);
                p_assetFolder.files[i].size = p_fileOutline.size;
                p_assetFolder.files[i].version = p_fileOutline.version;
            }
        }
        return null;
    }

    private _removeFileOutline(p_assetFolder:AssetFolderModel, p_filePath:string)
    {
        for (let i = 0; i < p_assetFolder.files.length; i++)
        {
            if (p_assetFolder.files[i].path === p_filePath)
            {
                p_assetFolder.files.splice(i, 1);
                return;
            }
        }
    }



//---------------------------------
    // Private Methods
    //---------------------------------


}

//Singleton export
export default new ContentFileController(ENTITY_CONFIG.CONTENT_FILE);
