import DataFilterModel from '@/data_tool/data_filter/_model/DataFilterModel';
import fileManager, {FileResponse} from '@/_controller/FileManager';
import {IDataItemDefinitionDto, IDataSchemaDto, IPropertyDefinitionDto} from '@/data_tool/_model/data_tool.dto';
import DataUtil from '@/data_tool/_controller/DataUtil';
import AppUserModel from '@/project/user/_model/AppUserModel';
import {RIGHTS} from '@/team/_model/role.constants';

class DataFilterController {
  private readonly _appUserModel: AppUserModel = AppUserModel.getInstance();
  private _model: DataFilterModel = new DataFilterModel();

  constructor(model: DataFilterModel) {
    this._model = model;
  }

  public async initModel(schemaName: string, schemaItemDefinitionId?: string) {
    this._model.schemaName = schemaName;

    const fileResponse: FileResponse = await fileManager.fetchFileFromCdn(`schemas/${schemaName}.json`);
    if (fileResponse.hasSucceeded) {
      const schema: IDataSchemaDto = fileResponse.result;

      // get first item definition by default OR by id if provided
      let itemDef: IDataItemDefinitionDto | undefined = schema.itemDefinitions[0];
      this._model.schemaItemDefinitionId = itemDef.identifier;
      if (schemaItemDefinitionId) {
        const newItemDef = schema.itemDefinitions.find(id => id.identifier === schemaItemDefinitionId);
        if (!newItemDef) {
          console.warn("No item definition found for id '" + schemaItemDefinitionId + "', using default: '" + itemDef.identifier + "'");
        } else {
          this._model.schemaItemDefinitionId = schemaItemDefinitionId;
        }
      }

      // error if no item definition found
      if (!itemDef) {
        throw new Error(`No item definition found for schema ${schemaName}`);
      }

      // get default formElement for each property
      this._model.propertyDefinitions = itemDef.properties.map(pd => {
        if (!pd.formElement) {
          pd.formElement = DataUtil.getDefaultFormElement(pd);
        }
        return pd;
      });

      this._setFilterValues();
    }
  }

  private _setFilterValues() {
    // only show property definitions that have a valid element type | formElement
    let availablePropertyDefinitions: IPropertyDefinitionDto[] = this._model.propertyDefinitions.filter(this._filterValidProperties.bind(this));

    if (this._appUserModel.rights.indexOf(RIGHTS.HAS_DEVELOPER_ACCESS.identifier) >= 0) {
      this._model.filterValues = availablePropertyDefinitions.map(pd => ({
        propertyDefinition: pd,
        value: '',
      }));
    }

    // only show property definitions that are NOT only for developers
    availablePropertyDefinitions = this._model.propertyDefinitions.filter(pd => !pd.onlyForDevelopers);

    this._model.filterValues = availablePropertyDefinitions.map(pd => ({
      propertyDefinition: pd,
      value: '',
    }));
  }

  // array filter function that filters out properties that are not valid
  // (type does not match validPropertyTypes and (if any) formElement does not match validElementTypes)
  private _filterValidProperties(property: IPropertyDefinitionDto) {
    return this._model.validPropertyTypes.indexOf(property.type) >= 0
      && (!property.formElement || this._model.validElementTypes.indexOf(property.formElement) >= 0);
  }
}

export default DataFilterController;
