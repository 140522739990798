<template>
  <div class="user-coins">
    <CoinIcon></CoinIcon>
    <div class="user-coins__texts">
      <p class="mb-0" v-html="$t('GamificationEarnedCoins')"></p>
      <h5 class="mb-0" v-html="appUser.gamificationState.coins"></h5>
    </div>
  </div>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import CoinIcon from "@/gamification/_view/CoinIcon.vue";
import AppUserModel from "@/project/user/_model/AppUserModel";

@Component({
  components: {
    CoinIcon
  }
})
export default class UserCoins extends Vue {

  private appUser: AppUserModel = AppUserModel.getInstance();

}
</script>

