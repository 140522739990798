import {ChartOptions} from "chart.js";

export const ANALYTICS_DEFAULT_RANGE:number = 90;

export const LINE_DATASET_COLORS:string[] = ['#2e2e2e', '#c7a258', '#71601e', '#e3c457'];

export const PIE_DATASET_COLORS:string[] = ['#c7a258', '#71601e', '#e3c457', '#e3c457', '#e3c457'];

export const ANALYTICS_LINE_GRAPH_OPTIONS:ChartOptions = {
    responsive         : true,
    aspectRatio        : 4,
    maintainAspectRatio: false,
    legend             : {
        position: 'top',
        align   : 'end',
        labels  : {
            boxWidth  : 20,
            fontColor : '#2e2e2e',
            fontFamily: "'Roboto', sans-serif",
            padding   : 10,
        }
    },
    tooltips           : {
        titleFontFamily : "'Roboto', sans-serif",
        bodyFontFamily  : "'Roboto', sans-serif",
        footerFontFamily: "'Roboto', sans-serif",
        xPadding        : 14,
        yPadding        : 14
    },
    elements           : {
        point: {
            radius: 2,
        },
        line : {
            stepped    : false,
            fill       : false,
            borderWidth: 2
        }
    },
    scales             : {
        xAxes: [{
            gridLines: {
                display: false
            },
            ticks    : {
                fontColor    : '#d2d2d2',
                fontFamily   : "'Roboto', sans-serif",
                padding      : 5,
                maxTicksLimit: 20
            }
        }],
        yAxes: [{
            gridLines: {
                color: '#d2d2d2',
            },
            ticks    : {
                fontColor : '#d2d2d2',
                fontFamily: "'Roboto', sans-serif",
                padding   : 5,
            }
        }]
    },
    plugins            : {
        datalabels: {
            align  : 'top',
            display: false,
            color  : '#d2d2d2',
            font   : {
                family: "'Roboto', sans-serif",
            }
        }
    }
};

export enum AnalyticsWidgetType
{
    SUBJECT_LIST = "SUBJECT_LIST",
    SUBJECT_LIST_WITH_ACTIONS = "SUBJECT_LIST_WITH_ACTIONS",
    ACTION_EVOLUTION_GRAPH = "ACTION_EVOLUTION_GRAPH",
}

export enum AnalyticsActionQueryType
{
    ALL = "ALL", //eg all creates
    COUNT = "COUNT", //eg number of views

    LEAST = "LEAST",
    MOST = "MOST", //eg most viewed

    HIGHEST_PROPERTY = "HIGHEST_PROPERTY", //eg highest proposal deal size

    MIN_DATE = "MIN_DATE",  //eg "first time shared"
    MAX_DATE = "MAX_DATE",  //eg "last viewed"
    IF_SINGLE_FOUND = "IF_SINGLE_FOUND", //eg "approved"

    COMPARE_COUNT_PREVIOUS_PERIOD = "COMPARE_COUNT_PREVIOUS_PERIOD",
    AVG_PER_WEEK = "AVG_PER_WEEK",
}

export enum AnalyticsValueDisplayType
{
    DATE = "DATE",
    NUMBER = "NUMBER",
    DECIMAL = "DECIMAL",
    PERCENTAGE = "PERCENTAGE",
    EVOLUTION_PERCENTAGE = "EVOLUTION_PERCENTAGE", //green or red, also shows + and - prefix
    FLAG = "FLAG"
}

//the most common subject types, can be complemented with custom actions, their label is compiled within the app
export enum AnalyticsSubjectTypeIdentifier
{
    PRESENTATION = "PRESENTATION",
    CONTENT_APP = "CONTENT_APP",
    CONTENT_FILE = "CONTENT_FILE",
    PROPOSAL = "PROPOSAL",
}


//the most common actions, can be complemented with custom actions, their label is compiled within the app
export enum AnalyticsActionIdentifier
{
    ALL = "ALL",

    //these are reserved, and  have different behaviour:
    CREATE = "CREATE", // will check existence of subject identifier before creating record (will not overwrite the created date),
    UPDATE = "UPDATE", // will update the found subject identifier record
    DELETE = "DELETE", // will delete the found subject identifier record

    //the rest will always result in a new record
    SHARE = "SHARE",
    VIEW = "VIEW",
    APPROVE = "APPROVE"
}

export enum AnalyticsUsageBoxType
{
    USAGE_EVOLUTION_VS_LAST_PERIOD = "USAGE_EVOLUTION_VS_LAST_PERIOD",
    AVERAGE_USAGE = "AVERAGE_USAGE",
    NR_OF_USERS = "NR_OF_USERS",
    NR_OF_CREATED_USERS = "NR_OF_CREATED_USERS",
    NR_OF_DELETED_USERS = "NR_OF_DELETED_USERS",
    LAST_LOGIN = "LAST_LOGIN",
    USAGE_VS_ALL = "USAGE_VS_ALL",
    AVERAGE_LOGINS_PER_WEEK = "AVERAGE_LOGINS_PER_WEEK",
    AVERAGE_PRESENTATIONS_PER_WEEK = "AVERAGE_PRESENTATIONS_PER_WEEK",
    LOGGED_IN = "LOGGED_IN",
    PRESENTED = "PRESENTED",
    SHARED = "SHARED",
    TOTAL = "TOTAL",
}



export enum AnalyticsOrderVector
{
    ASC = "ASC",
    DESC = "DESC"
}

export enum AnalyticsTargetType
{
    MYSELF = "MYSELF",
    MY_TEAMS = "MY_TEAMS",
    ALL_TEAMS = "ALL_TEAMS",
}
