import {IAssetFileDto, IAssetFolderBodyDto} from "@/asset_folder/_model/asset_folder.dto";
import {IModelDto} from "@/__libs/_model/model.dto";
import {DtoType} from "@/_model/app.constants";
import {IMultiLangString} from "@/__libs/language_manager/LanguageManager";
import Model from "@/__libs/_model/Model";
import {FILE_TYPE_CONFIG, FileImportSource, FileTargetType, FileType} from "@/asset_folder/_model/asset_folder.constants";
import {UploadStatus} from "@/entity/_model/entity.constants";


export default class AssetFileModel extends Model implements IAssetFileDto
{

    public name:IMultiLangString = {};
    public description:IMultiLangString = {};
    public fileURI:string = "";
    public previewImageURI:string = "";
    public size:number = 0;
    public fileType:FileType = FileType.UNKNOWN;
    public targetTypes:FileTargetType[] = [];
    public importSource:FileImportSource = FileImportSource.LOCAL_STORAGE;
    public createdDate:Date = new Date();

    //meta
    public uploadStatus:UploadStatus = UploadStatus.IDLE;

    //---------------------------------
    // Constructor
    //---------------------------------

    constructor(p_dto?:IModelDto)
    {
        super();
        if (p_dto)
        {
            this.mapFromDto(p_dto);
        }
    }

    //---------------------------------
    // Public Methods
    //---------------------------------

    //untill typescript has proper reflection, we will have to do it this way:
    public mapToDto(p_dtoType:DtoType):IAssetFileDto
    {
        const dto:IAssetFileDto = {
            name           : this.name,
            description    : this.description,
            fileURI        : this.fileURI,
            previewImageURI: this.previewImageURI,
            size           : this.size,
            fileType       : this.fileType,
            targetTypes    : this.targetTypes,
            importSource   : this.importSource,
            createdDate    : this.createdDate,
        };
        return dto;

    }

}
