<template>
  <div tabindex="0" class="playlist-entry-item"
       :class="{ isBeingDragged: isBeingDragged, isBeingDraggedOver: isBeingDraggedOver, isBeingDraggedOverBefore: isBeingDraggedOverBefore, isBeingDraggedOverAfter: isBeingDraggedOverAfter, inEditMode: !inPlayerMode, noDropAllowed: showNoDropAllowed, dropAttract: showDropAttract, dropFinished: dropFinished , active: isActive, 'is-clicked': isClicked }"
       :draggable="isDraggable"
       @dragstart="_onDragStart" @dragend="_onDragEnd" @dragover="_onDragOver"
       @dragenter="_onDragEnter" @dragleave="_onDragLeave" @drop="_onDrop">
    <div class="playlist-entry-item__grip" v-if="!inPlayerMode">
      <i class="fas fa-grip-vertical fa-lg"></i>
    </div>
    <div class="playlist-entry-item__poster" @click="_onClick">
      <AAImage :image-url="posterUrl" v-if="content && content.loadingStatus === loadingStatus.BODY_LOADED"></AAImage>
      <div class="playlist-entry-item__poster__content">
        <div class="playlist-entry-item__poster__content__badges">
          <span class="badge aa-content-item-type" :class="contentItemTypeClass">{{ contentItemTypeLabel }}</span>
        </div>
        <div class="playlist-entry-item__poster__content__name" v-line-clamp="2">{{ contentName }}</div>
      </div>
      <div class="playlist-entry-item__poster__play">
        <i class="fas fa-play fa-lg"></i>
      </div>
    </div>
    <div class="playlist-entry-item__delete" @click="_onDeleteEntryBtnClick" v-if="!inPlayerMode">
      <i class="fas fa-trash-can fa-lg"></i>
    </div>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from "vue-property-decorator";
import ContentModel from "@/content/_model/ContentModel";
import {EntityType, LoadingStatus} from "@/entity/_model/entity.constants";
import fileManager from "@/_controller/FileManager";
import AudienceModel from "@/audience/_model/AudienceModel";
import AudienceListModel from "@/audience/_model/AudienceListModel";
import languageManager from "@/__libs/language_manager/LanguageManager";
import AAImage from "@/_view/components/AAImage.vue";
import {FILE_TYPE_CONFIG} from "@/asset_folder/_model/asset_folder.constants";
import ContentFileModel from "@/content_file/_model/ContentFileModel";
import contentFolderController from "@/content/_controller/ContentFolderController";
import ContentFolderListModel from "@/content/_model/ContentFolderListModel";
import {IPlaylistEntryDto} from "@/playlist/_model/playlist.dto";
import ContentListModel from "@/content/_model/ContentListModel";
import playlistController from "@/playlist/_controller/PlaylistController";
import PlaylistListModel from "@/playlist/_model/PlaylistListModel";

@Component({
    components: {
        AAImage,
    }
})
export default class PlaylistEntryItemRenderer extends Vue {

    @Prop() public playlistEntry!: IPlaylistEntryDto;
    @Prop() public inPlayerMode!: boolean;

    public loadingStatus: typeof LoadingStatus = LoadingStatus;
    public posterLoaded: Boolean = false;
    public isClicked: boolean = false;
    public dragCount: number = 0;
    public isBeingDraggedOver: boolean = false;
    public isBeingDraggedOverBefore: boolean = false;
    public isBeingDraggedOverAfter: boolean = false;
    public dropFinished: boolean = false;
    public playlistListModel: PlaylistListModel = PlaylistListModel.getInstance();
    public contentFolderListModel: ContentFolderListModel = ContentFolderListModel.getInstance();

    get isBeingDragged(): boolean {
        return this.contentFolderListModel.currentDragged === this.playlistEntry;
    }

    get content() {
        return ContentListModel.getInstance().getEntityByID(this.playlistEntry.contentID);
    }

    get showNoDropAllowed() {
        return ContentFolderListModel.getInstance().dragInProgress;
    }

    get contentName() {
        return languageManager.getTranslationForValue(
          this.content!.name,
          this.audienceLangCode
        );
    }

    get contentID() {
        return this.content!.ID;
    }

    get contentItemTypeClass() {
        if (this.content!.entityType === EntityType.CONTENT_FILE) {
            return `aa-file-type--${FILE_TYPE_CONFIG[(this.content as ContentFileModel).fileType].identifier.toLowerCase()}`;
        } else {
            return `aa-content-item-type--${this.content!.entityType.toLowerCase()}`;
        }
    }

    get contentItemTypeLabel(): string {
        if (this.content!.entityType === EntityType.CONTENT_FILE) {
            return this.$t('FileType_' + FILE_TYPE_CONFIG[(this.content as ContentFileModel).fileType].identifier) as string;
        } else {
            return this.$t(`ContentItemTypes_${this.content!.entityType}`) as string;
        }
    }

    get bodyLoaded() {
        return this.content!.loadingStatus >= LoadingStatus.BODY_LOADED;
    }

    get isActive() {
        return this.playlistEntry === this.playlistListModel.selectedQueueEntry;
    }

    get isDraggable() {
        return !this.inPlayerMode;
    }

    get showDropAttract() {
        return this.isBeingDraggedOver && !this.showNoDropAllowed;
    }

    get posterUrl() {
        const posterUri: string = languageManager.getTranslationForValue(
          this.content!.poster,
          this.audienceLangCode
        );
        return fileManager.getFileUrl(posterUri);
    }

    get audienceLangCode(): string | null {
        const activeAudience: AudienceModel | null = AudienceListModel.getInstance()
          .globalSelState.selected;
        return activeAudience ? activeAudience.langCode : null;
    }

    public posterLoad(e: Event) {
        this.posterLoaded = true;
    }

    public _onClick(p_e: Event) {
        this.$emit("onClick", this.playlistEntry);
    }

    // DRAGGING

    public _onDragStart() {
        contentFolderController.startDrag(this.playlistEntry);
    }

    public _onDragEnd() {
        contentFolderController.endDrag();
    }

    public _onDragOver(p_event: DragEvent) {
        let isDroppable: boolean = false;
        let currentDragged = ContentFolderListModel.getInstance().currentDragged;
        if (currentDragged instanceof ContentModel || (currentDragged as IPlaylistEntryDto).entryOrder !== undefined) {
            isDroppable = true;
            if (this.playlistEntry.entryOrder! < (currentDragged as IPlaylistEntryDto).entryOrder!) {
                this.isBeingDraggedOverBefore = true;
                this.isBeingDraggedOverAfter = false;
            } else if (this.playlistEntry.entryOrder! > (currentDragged as IPlaylistEntryDto).entryOrder!) {
                this.isBeingDraggedOverBefore = false;
                this.isBeingDraggedOverAfter = true;
            } else if (currentDragged instanceof ContentModel) {
                this.isBeingDraggedOverBefore = true;
                this.isBeingDraggedOverAfter = false;
            } else {
                this.isBeingDraggedOverBefore = false;
                this.isBeingDraggedOverAfter = false;
            }
        }
        if (isDroppable) {
            p_event.preventDefault();
            if (p_event.dataTransfer) {
                p_event.dataTransfer.dropEffect = "move";
            }
        }
    }

    public _onDragEnter(p_event: DragEvent) {
        this.dropFinished = false;
        this.dragCount++;
        this.isBeingDraggedOver = true;
    }

    public _onDragLeave(p_event: DragEvent) {
        this.dragCount--;
        if (this.dragCount == 0) {
            this.isBeingDraggedOver = false;
        }
    }

    public _onDrop() {
        let currentDragged = ContentFolderListModel.getInstance().currentDragged;
        if (currentDragged instanceof ContentModel) {
            playlistController.addContentToPlaylist(PlaylistListModel.getInstance().selectedPlaylist!, currentDragged, this.playlistEntry.entryOrder);
        } else if ((currentDragged as IPlaylistEntryDto).entryOrder !== undefined) //ugly way to determine type of IPlaylistEntryDto
        {
            playlistController.reorderEntryInPlaylist(PlaylistListModel.getInstance().selectedPlaylist!, currentDragged as IPlaylistEntryDto, this.playlistEntry.entryOrder!);
        }
        contentFolderController.endDrag();

        // reset drop count hack
        this.dragCount = 0;
        this.isBeingDraggedOver = false;

        // do animation
        this.dropFinished = true;
    }

    public async _onDeleteEntryBtnClick(p_e: Event) {
        p_e.stopPropagation();
        const deleteConfirmed: boolean = await this.$bvModal.msgBoxConfirm(this.$t("SureDeletePlaylistEntry") as string, {centered: true});
        if (deleteConfirmed) {
            await playlistController.deletePlaylistEntry(PlaylistListModel.getInstance().selectedPlaylist!, this.playlistEntry);
        }
    }

}
</script>
