import DataProviderModel from "@/data_tool/data_provider/_model/DataProviderModel";
import {DataProviderViewMode} from "@/data_tool/_model/data_tool.constants";
import EntitySelectionState from "@/entity/_model/EntitySelectionState";
import {Prop} from "vue-property-decorator";

export default class DataProviderSelectionState extends EntitySelectionState<DataProviderModel>
{

    protected _selected:DataProviderModel | null = null;
    get selected():DataProviderModel | null
    {
        return this._selected;
    }

    set selected(value:DataProviderModel | null)
    {
        this._previousSelected = this._selected;
        this._selected = value;
    }

    public viewMode:DataProviderViewMode;


    public allowedSchema:string | null = null;
    public selectedDtpUri:string | null = null;

    public fileSearchFilter:string = "";


    constructor(viewMode:DataProviderViewMode)
    {
        super();
        this.viewMode = viewMode;
    }

    //---------------------------------
    // Public Methods
    //---------------------------------


}
