<template>
  <div class="box box--full-height box--scrollable app-user-detail" v-if="appUser">

    <div class="detail-header">
      <div class="detail-header__person">
        <AAProfilePicture :name="appUser.displayName"
                          :imageUri="appUser.avatarFileUri"
                          :editable="true"
                          :huge="true"></AAProfilePicture>
        <div>
          <h2 class="mb-2 title">{{ appUser.displayName }}</h2>
          <h5>{{ appUser.email }}</h5>
          <div>
            {{ appUser.roleIdentifier }} <i><small>{{ $t('AppUserInTeam') }}</small></i> {{
              appUser.teamName
            }}<br/><i><small>({{ appVersion }})</small></i>
          </div>
        </div>
      </div>
      <div class="detail-header__buttons">
        <b-button variant="outline-secondary" block class="action-btn profile-picture-button"
                  @click="_onOpenAssetPickerBtnClick">
          <i class="fas fa-image fa-lg"></i>{{ $t('AddProfilePicture') }}
        </b-button>
        <template v-if="notificationsAvailable">
          <b-button v-if="hasNotificationPermission" variant="outline-secondary"
                    @click="_onDisablePushNotificationsBtnClick" block class="action-btn"><i
              class="fas fa-bell-slash fa-lg"></i>{{ $t('DisablePushNotifications') }}
          </b-button>
          <b-button v-else variant="outline-secondary" @click="_onEnablePushNotificationsBtnClick" block
                    class="action-btn">
            <i class="fas fa-bell fa-lg"></i>{{ $t('EnablePushNotifications') }}
          </b-button>
        </template>
        <b-button variant="outline-secondary" @click="_onLogoutBtnClick" class="action-btn" block><i
            class="fas fa-right-from-bracket fa-lg"></i>{{ $t('MainMenuLogout') }}
        </b-button>
      </div>
    </div>

    <div class="detail-inputs">
      <b-form-group :label="$t('PlaceholderDisplayName')">
        <b-form-input v-model="validationAppUser.displayName"
                      :state="_calculateInputState($v.validationAppUser.displayName)"
                      @input="appUser.displayName = _onFormValidationInput($v.validationAppUser.displayName, appUser.displayName)"
                      autofocus></b-form-input>
        <b-form-invalid-feedback>
          <span class="d-block"
                v-if="!$v.validationAppUser.displayName.required">{{
              $t('FieldRequiredSimple')
            }}</span>
          <span class="d-block"
                v-if="!$v.validationAppUser.displayName.minLength">{{ $t('MinimumFieldLengthSimple', [3]) }}</span>
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group :label="$t('PlaceholderPersonLanguage')">
        <b-form-select v-model="appUser.langCode"
                       @change="_onChangeUserLangCode">
          <option v-for="langCode in availableLanguages" :key="langCode"
                  :value="langCode">{{ langCode }}
          </option>
        </b-form-select>
      </b-form-group>

      <b-form-group :label="$t('PlaceholderFirstName')">
        <b-form-input v-model="appUser.firstName"
                      @input="_onFormInput"></b-form-input>
      </b-form-group>

      <b-form-group :label="$t('PlaceholderLastName')">
        <b-form-input v-model="appUser.lastName"
                      @input="_onFormInput"></b-form-input>
      </b-form-group>

      <b-form-group :label="$t('PlaceholderTelNr')">
        <b-form-input v-model="appUser.telNr"
                      @input="_onFormInput"></b-form-input>
      </b-form-group>

      <b-form-group :label="$t('PlaceholderMobileNr')">
        <b-form-input v-model="appUser.mobileNr"
                      @input="_onFormInput"></b-form-input>
      </b-form-group>

      <b-form-group :label="$t('PlaceholderFaxNr')">
        <b-form-input v-model="appUser.faxNr"
                      @input="_onFormInput"></b-form-input>
      </b-form-group>

      <b-form-group :label="$t('PlaceholderDepartment')">
        <b-form-input v-model="appUser.department"
                      @input="_onFormInput"></b-form-input>
      </b-form-group>

      <b-form-group :label="$t('PlaceholderJobTitle')">
        <b-form-input v-model="appUser.jobTitle"
                      @input="_onFormInput"></b-form-input>
      </b-form-group>

      <b-form-group :label="$t('PlaceholderAddress')">
        <b-form-input v-model="appUser.address"
                      @input="_onFormInput"></b-form-input>
      </b-form-group>

      <b-form-group :label="$t('PlaceholderWebsite')">
        <b-form-input v-model="appUser.website"
                      @input="_onFormInput"></b-form-input>
      </b-form-group>

      <b-form-group :label="$t('PlaceholderSignature')">
        <b-form-input v-model="appUser.signature"
                      @input="_onFormInput"></b-form-input>
      </b-form-group>
    </div>

    <b-modal id="app-user-asset-picker-box" size="xl" :title="$t('PickUserAvatarOrUploadNewOne')" hide-footer>
      <AssetFoldersPicker :selectedFileUri="appUser.avatarFileUri"
                          :allowedTargetType="avatarAllowedTargetType"
                          :allowedFileTypes="avatarAllowedFileTypes"
                          v-on:onAssetFilePicked="_onAssetFilePicked"></AssetFoldersPicker>
    </b-modal>

  </div>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import languageManager from "@/__libs/language_manager/LanguageManager";
import AppUserModel from "@/project/user/_model/AppUserModel";
import appUserController from "@/project/user/_controller/AppUserController";
import {minLength, required} from "vuelidate/lib/validators";
import {RoutingIdentifier} from "@/router";
import AssetFileModel from "@/asset_folder/_model/AssetFileModel";
import AppModel from "@/_model/AppModel";
import {FileTargetType, FileType} from "@/asset_folder/_model/asset_folder.constants";
import AAProfilePicture from "@/_view/components/AAProfilePicture.vue";
import AssetFoldersPicker from "@/asset_folder/_view/AssetFoldersPicker.vue";


@Component({
    components: {AAProfilePicture, AssetFoldersPicker},
    validations: {
        validationAppUser: {
            displayName: {
                required,
                minLength: minLength(3)
            }
        }
    }
})
export default class AppUserDetail extends Vue {

    public avatarAllowedTargetType: FileTargetType = FileTargetType.USER_AVATAR;
    public avatarAllowedFileTypes: FileType[] = [FileType.IMAGE];

    public appUser: AppUserModel = AppUserModel.getInstance();
    public appVersion: string | undefined = AppModel.getInstance().version;
    private validationAppUser: AppUserModel = new AppUserModel();

    get notificationsAvailable(): boolean {
        return ('Notification' in window);
    }

    get availableLanguages(): string[] {
        return languageManager.availableLangCodes;
    }

    get hasChanges() {
        return this.appUser && this.appUser.hasChanges;
    }

    mounted() {
        this._createValidationAppUser();
        window.addEventListener('beforeunload', this._beforeUnload);
    }

    beforeDestroy() {
        this._saveUser();
        window.removeEventListener('beforeunload', this._beforeUnload);
    }

    private _calculateInputState(inputValidator: any): any {
        return inputValidator.$invalid ? false : null;
    }

    private _beforeUnload(e: BeforeUnloadEvent) {
        this._saveUser();
    }

    private _createValidationAppUser(): void {
        if (this.appUser) {

            this.validationAppUser = new AppUserModel();
            this.validationAppUser.displayName = this.appUser.displayName;
        }
    }

    private _onFormValidationInput(inputValidator: any, value: any): void {
        if (!inputValidator.$invalid && this.appUser) {
            value = inputValidator.$model;
            this._onFormInput(null);
        }
        console.log(value);
        return value;
    }

    private _onFormInput(p_e: Event | null) {
        this.appUser.hasChanges = true;
    }

    private _onChangeUserLangCode(p_e: Event) {
        this.appUser.hasChanges = true;
        this.$i18n.locale = this.appUser.langCode;
    }

    private _saveUser() {
        if (this.appUser.hasChanges) {
            appUserController.saveUser();
        }
    }

    public get hasNotificationPermission(): boolean {
        return this.appUser.hasNotificationPermission;
    }

    private async _onLogoutBtnClick() {
        const logoutConfirmed: boolean = await this.$bvModal.msgBoxConfirm(this.$t('ConfirmLogOut') as string, {centered: true});
        if (logoutConfirmed) {
            await this.$router.push({name: RoutingIdentifier.LOGOUT});
        }
    }

    private _onOpenAssetPickerBtnClick(p_e: Event) {
        this.$bvModal.show('app-user-asset-picker-box');
    }

    private _onAssetFilePicked(p_assetFile: AssetFileModel) {
        this.$bvModal.hide('app-user-asset-picker-box');
        if (p_assetFile && this.appUser) {
            this.appUser.avatarFileUri = p_assetFile.fileURI;
            this.appUser.hasChanges = true;
        }
    }

    private async _onEnablePushNotificationsBtnClick(p_e: Event) {
        await appUserController.requestPushNotifications();
    }

    private async _onDisablePushNotificationsBtnClick(p_e: Event) {
        await appUserController.disablePushNotifications();
    }

}
</script>

