<template>
  <div class="main-logo" @click="gotoHomePage">
    <AAImage :imageUrl="url"></AAImage>
  </div>
</template>

<script lang="ts">
import {Component, Vue, Watch} from "vue-property-decorator";
import AAImage from "@/_view/components/AAImage.vue";
import {ISplashDto} from "@/_model/app.dto";
import AppModel from "@/_model/AppModel";
import AppUserModel from "@/project/user/_model/AppUserModel";
import {RoutingIdentifier} from "@/router";
import ProjectModel from "@/project/_model/ProjectModel";
import {RIGHTS} from "@/team/_model/role.constants";
import NetworkManager from "@/_controller/NetworkManager";
import AudienceModel from "@/audience/_model/AudienceModel";
import audienceController from "@/audience/_controller/AudienceController";
import ContentFolderListModel from "@/content/_model/ContentFolderListModel";

@Component({
  components: {AAImage}
})
export default class MainLogo extends Vue {

  private routingIdentifiers = RoutingIdentifier;

  public project: ProjectModel = AppUserModel.getInstance().project;
  public url: string = "";

  mounted() {
    this.url = this.project.logoDarkBgUrl;
  }

  @Watch('project.projectLogoFile', {immediate: false, deep: false})
  private onProjectLogoFileChange(newFile: File | null, oldFile: File | null) {
    if (newFile) {
      this.url = URL.createObjectURL(newFile);
    }
  }

    private gotoHomePage() {
        if (this.$router.currentRoute.name !== RoutingIdentifier.CONTENT_OVERVIEW) {
            this.$router.push({name: RoutingIdentifier.CONTENT_OVERVIEW});
        } else {
            ContentFolderListModel.getInstance().goToRoot();
        }
    }

}
</script>
