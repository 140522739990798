import {ENTITY_CONFIG} from "@/entity/_model/entity.constants";
import EntityListController from "@/entity/_controller/EntityListController";

import {IEntityMetaDto} from "@/entity/_model/entity.dto";
import CompanyModel from "@/company/_model/CompanyModel";
import CompanyListModel from "@/company/_model/CompanyListModel";
import companyController from "@/company/_controller/CompanyController";
import fileManager from "@/_controller/FileManager";


//contains all controller methods that can be applied on the list of companies
class CompanyListController extends EntityListController<CompanyModel>
{

    constructor()
    {
        super(ENTITY_CONFIG.COMPANY, CompanyListModel.getInstance(), companyController)
    }

    //---------------------------------
    // Properties
    //---------------------------------

    private _companyListModel:CompanyListModel = CompanyListModel.getInstance();

    //---------------------------------
    // Controller Methods
    //---------------------------------



    //---------------------------------
    // Private Methods
    //---------------------------------

    protected __newEntity(p_dto:IEntityMetaDto):CompanyModel
    {
        return new CompanyModel(p_dto);
    }

}

//Singleton export
export default new CompanyListController();
