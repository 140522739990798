<template>
  <div class="days-left">
    <div class="days-left__first">{{ firstCharacter }}</div>
    <div class="days-left__second">{{ secondCharacter }}</div>
    <div class="days-left__text" v-html="$t('GamificationMissionsDaysLeft')"></div>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import CoinIcon from "@/gamification/_view/CoinIcon.vue";
import AppUserModel from "@/project/user/_model/AppUserModel";
import Util from "@/__libs/utility/Util";

@Component({
  components: {

  }
})
export default class DaysLeft extends Vue {

  get firstCharacter(): string {
    return Util.setLeadingZero(Util.getDaysInThisMonth()).charAt(0);
  }

  get secondCharacter(): string {
    return Util.setLeadingZero(Util.getDaysInThisMonth()).charAt(1);
  }

}
</script>

