<template>
  <div class="project-color" v-if="name">

    <div class="project-color__circle mb-3" :style="{ 'background-color': hex }" :id="`project-color-${name}`" tabindex="0">
      <div class="project-color__circle__change">
        <i class="fas fa-eye-dropper"></i>
      </div>
    </div>

    <h6 class="project-color__title" v-html="$t(`ProjectSettingsColor_${name}`)"></h6>

    <b-popover :target="`project-color-${name}`" triggers="click blur" placement="right" boundary="window">
      <Chrome v-model="color" @input="change()" />
    </b-popover>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import {Chrome} from "vue-color";

@Component({
  components: {Chrome},
})
export default class ProjectColor extends Vue {

  @Prop() private name!: string;
  @Prop() private hex!: string;

  public color: any = {};

  public mounted() {
    this.color = this.hex;
  }

  private change() {
    this.$emit("onChange", this.color.hex);
  }

}
</script>

