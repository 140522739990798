<template>
  <div class="gamification-missions box box--full-height">

    <transition name="fade">
      <div v-if="currentState === gamificationMissionsState.LOADING"
           class="empty-loader">
        <b-spinner variant="dark"></b-spinner>
      </div>
    </transition>

    <transition name="fade">
      <div v-if="currentState === gamificationMissionsState.ERROR_LOADING" class="error-message">
        {{ $t('ErrorConnectionRequired') }}
      </div>
    </transition>

    <transition name="fade">
      <div class="gamification-missions__wrapper" v-if="currentState === gamificationMissionsState.LOADED">
        <div class="gamification-missions__header mb-3">
          <h5 class="mb-0" v-html="$t('GamificationMissionsTitle')" v-line-clamp="1"></h5>
<!--          <b-button v-if="!isCurrentMonth" variant="secondary" size="sm" v-html="$t('GamificationMissionsBackToCurrentMonth')" @click="backToCurrentMonth"></b-button>-->
          <date-picker v-model="date" type="month" @input="onDateChanged" :clearable="false"></date-picker>
          <DaysLeft v-if="isCurrentMonth"></DaysLeft>
        </div>

        <div class="gamification-missions__index mb-3">
          <div v-html="$t('GamificationMissionsTargets')"></div>
          <div v-html="$t('GamificationMissionsTargetMonth')"></div>
          <div v-html="$t('GamificationMissionsEarnedCoinsMonth')"></div>
          <div v-html="$t('GamificationMissionsProgress')"></div>
        </div>

        <div class="gamification-missions__targets">
          <Mission v-for="target in targets" :target="target" :key="target.targetIdentifier"></Mission>
        </div>
      </div>
    </transition>

  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import gamificationController from "@/gamification/_controller/GamificationController";
import AppUserModel from "@/project/user/_model/AppUserModel";
import {IGamificationProjectConfigDto, IGamificationTargetConfigDto} from "@/gamification/_model/gamification.dto";
import Mission from "@/gamification/missions/_view/Mission.vue";
import DatePicker from "vue2-datepicker";
import Util from "@/__libs/utility/Util";
import DaysLeft from "@/gamification/_view/DaysLeft.vue";
import AppModel from "@/_model/AppModel";

enum GamificationMissionsState {
  IDLE,
  LOADING,
  ERROR_LOADING,
  LOADED
}

@Component({
  components: {DaysLeft, Mission, DatePicker}
})
export default class GamificationMissions extends Vue {

  @Prop() public startDate!: Date;

  private gamificationMissionsState: typeof GamificationMissionsState = GamificationMissionsState;
  private currentState: GamificationMissionsState = GamificationMissionsState.IDLE;

  private targets: IGamificationTargetConfigDto[] = AppUserModel.getInstance().project.config.gamification.targets;
  private date: Date = new Date();

  get isCurrentMonth(): boolean {
    const now = new Date();
    return now.getFullYear() === this.date.getFullYear() && now.getMonth() === this.date.getMonth();
  }

  private async backToCurrentMonth() {
    this.date = new Date();
    await this.loadContent();
  }

  private async onDateChanged() {
    await this.loadContent();
  }

  private async loadContent() {
    this.currentState = GamificationMissionsState.LOADING;
    const hasSucceeded: boolean = await gamificationController.getUserTargetValues(this.date.getFullYear(), this.date.getMonth());
    if (hasSucceeded) {
      this.currentState = GamificationMissionsState.LOADED;
    } else {
      this.currentState = GamificationMissionsState.ERROR_LOADING;
    }
  }

  public async mounted() {
    if (this.startDate) {
      this.date = this.startDate;
    }
    await this.loadContent();
  }

}
</script>

