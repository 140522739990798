import Dexie from "dexie";
import {IBackgroundSyncRecord, ISyncFileRecord} from "@/sync/_model/sync.dto";
import Util from "@/__libs/utility/Util";


class SyncDb extends Dexie
{


    //---------------------------------
    // Public Properties
    //---------------------------------


    //---------------------------------
    // Private Properties
    //---------------------------------

    private _files!:Dexie.Table<ISyncFileRecord, string>;

    private _background!:Dexie.Table<IBackgroundSyncRecord, string>;

    //---------------------------------
    // Singleton Constructor
    //---------------------------------

    private static _instance:SyncDb;

    constructor()
    {
        super("SyncDb");
        if (!SyncDb._instance)
        {
            SyncDb._instance = this;
        }

        this.version(1).stores({
            files     : '&path',
            background: '++ID,latestTryDate'
        });

        this._files = this.table("files");
        this._background = this.table("background");
    }

    public static getInstance():SyncDb
    {
        if (!SyncDb._instance)
        {
            new SyncDb();
        }

        return SyncDb._instance;
    }


    //---------------------------------
    // getter setters
    //---------------------------------


    //---------------------------------
    // Private Methods
    //---------------------------------


    //---------------------------------
    // Public Methods
    //---------------------------------

    public async setFileRecord(p_fileRecord:ISyncFileRecord):Promise<boolean>
    {
        try
        {
            await this._files.put(p_fileRecord);
            return true;

        } catch (e)
        {
            console.log("DEXIE says NO:", e);
            return false;
        }
    }

    public async getFileRecord(p_path:string):Promise<ISyncFileRecord | null>
    {
        try
        {
            const fileRecord:ISyncFileRecord | undefined = await this._files.get(p_path);

            return fileRecord ? fileRecord : null

        } catch (e)
        {
            return null;
        }
    }

    public async clearFiles()
    {
        try
        {
            await this._files.clear();
        } catch (e)
        {
            console.log("couldn't clear files:", e.message);
        }
    }

    public async addBackgroundSyncRecord(p_bgSyncRecord:IBackgroundSyncRecord):Promise<boolean>
    {
        try
        {
            p_bgSyncRecord.requestDate = new Date();
            p_bgSyncRecord.latestTryDate = new Date();
            await this._background.add(p_bgSyncRecord);
            return true;

        } catch (e)
        {
            return false;
        }
    }

    public async updateBgSyncTry(p_ID:number):Promise<boolean>
    {
        try
        {
            await this._background.update(p_ID as any, {latestTryDate: new Date()});
            return true;

        } catch (e)
        {
            return false;
        }
    }

    public async getNextBackgroundSyncRecord():Promise<IBackgroundSyncRecord | null>
    {

        const maxTryDate:Date = new Date(Date.now() - 1000 * 30); //only try the same record every X seconds
        try
        {
            const openRecords:IBackgroundSyncRecord[] = await this._background
                .where("latestTryDate").below(maxTryDate).sortBy("ID");

            return openRecords.length > 0 ? openRecords[0] : null;
        } catch (e)
        {
            return null;
        }
    }


    public async deleteBgSync(p_ID:number):Promise<boolean>
    {
        try
        {
            await this._background.delete(p_ID as any);
            return true;

        } catch (e)
        {
            return false;
        }
    }
}

export default SyncDb;
