<template>
    <div class="data-provider-entry h-100" :class="`data-provider-entry--${dataProviderEntry.itemDefinition.type}`">

<!--      Let's for now say: no titles-->
<!--        <div v-if="showTitle">-->
<!--            <h4 v-if="!dataProviderEntry.isValid" class="text-danger">-->
<!--                {{ getEntryName(dataProviderEntry) }}-->
<!--                <b-badge variant="danger">{{ dataProviderEntry.getNrOfInvalidChildren() }}</b-badge>-->
<!--            </h4>-->
<!--            <h4 v-else>{{ getEntryName(dataProviderEntry) }}</h4>-->
<!--        </div>-->

      <DataItem v-if="dataProviderEntry.itemDefinition.type === dataProviderEntryType.SINGLE_ITEM" :dataItem="dataProviderEntry.singleItem"/>

      <DataItemList v-if="dataProviderEntry.itemDefinition.type === dataProviderEntryType.ITEM_LIST" :dataItemList="dataProviderEntry.itemList"/>

    </div>
</template>


<script lang="ts">
    import {Component, Prop, Vue, Watch} from "vue-property-decorator";
    import DataProviderModel from "@/data_tool/data_provider/_model/DataProviderModel";
    import dataProviderController from "@/data_tool/data_provider/_controller/DataProviderController";
    import DataProviderEntryModel from "@/data_tool/data_provider/_model/DataProviderEntryModel";
    import {DataProviderEntryType} from "@/data_tool/_model/data_tool.constants";
    import DataItem from "@/data_tool/data_item/_view/DataItem.vue";
    import DataItemList from "@/data_tool/data_item/_view/DataItemList.vue";
    import DataItemDictionary from "@/data_tool/data_item/_view/DataItemDictionary.vue";
    import languageManager, {IMultiLangString} from "@/__libs/language_manager/LanguageManager";



    @Component({
        components: {DataItem, DataItemList, DataItemDictionary}
    })
    export default class DataProviderEntry extends Vue
    {
        //---------------------------------
        // Vue Component props
        //---------------------------------

        @Prop() private dataProviderEntry!:DataProviderEntryModel;

        @Prop() private showTitle!:boolean;

        //---------------------------------
        // Vue Component data
        //---------------------------------

        private dataProviderEntryType:typeof DataProviderEntryType = DataProviderEntryType;


        //---------------------------------
        // Vue Computed properties
        //---------------------------------



        //---------------------------------
        // Public / lifecycle methods
        //---------------------------------

        public mounted()
        {
            // console.log(this.dataProviderEntry);
        }



        //---------------------------------
        // Private / helper methods
        //---------------------------------

        public getEntryName(p_entry:DataProviderEntryModel)
        {
            return languageManager.getTranslationForValue<string>(p_entry.itemDefinition.name as IMultiLangString, this.dataProviderEntry.dataProvider.activeLangCode);
        }

    }
</script>
