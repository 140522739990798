<template>
  <div class="modal-box create-content-app-box">

    <transition name="fade">
      <div v-if="currentState === createContentAppBoxState.LOADING" class="empty-loader">
        <b-spinner></b-spinner>
      </div>
    </transition>

    <transition name="fade">
      <div v-if="currentState === createContentAppBoxState.REMOTE_ERROR">
        <div class="error-message">{{ $t('ContentAppCreationFailed') }}</div>
        <div class="modal-box-buttons">
          <b-button variant="secondary" @click="cancel" tabindex="3">{{ $t('Cancel') }}</b-button>
          <b-button variant="success" @click="create" :disabled="$v.$invalid" tabindex="4">
            {{ $t('Retry') }}
          </b-button>
        </div>
      </div>
    </transition>

    <transition name="fade">
      <div v-if="currentState === createContentAppBoxState.STEP_1">
        <label>{{ $t('ContentAppType') }}</label>
        <div class="grid content-app-type-overview mb-3">
          <ContentAppResourceItemRenderer
              v-for="contentAppResource in contentAppResources.list"
              :content-app-resource="contentAppResource"
              :key="contentAppResource.identifier"
              v-on:onClick="onContentAppResourceClick"></ContentAppResourceItemRenderer>
        </div>
        <div class="modal-box-buttons">
          <b-button variant="secondary" @click="cancel" tabindex="3">{{ $t('Cancel') }}</b-button>
        </div>
      </div>
    </transition>

    <transition name="fade">
      <div v-if="currentState === createContentAppBoxState.STEP_2 || currentState === createContentAppBoxState.TRY_SUBMIT">
        <div class="instance-preview mb-3">
          <ContentAppResourceItemRenderer
              :content-app-resource="contentAppResource" :override-name="contentName"></ContentAppResourceItemRenderer>

          <div>
            <b-form-group :label="$t('ContentAppName')">
              <b-form-input v-model="contentName"
                            :placeholder="$t('DashboardShowAddPresentationNamePlaceholder')"
                            :state="_calculateInputState($v.contentName)"
                            tabindex="1"
                            autofocus></b-form-input>
              <b-form-invalid-feedback>
                <span class="d-block" v-if="!$v.contentName.required">{{ $t('FieldRequiredSimple') }}</span>
                <span class="d-block" v-if="!$v.contentName.minLength">{{ $t('MinimumFieldLengthSimple', [3]) }}</span>
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
        </div>

        <div class="modal-box-buttons">
          <b-button variant="secondary" @click="cancel" tabindex="3">{{ $t('Cancel') }}</b-button>
          <b-button variant="success" @click="create" :disabled="currentState === createContentAppBoxState.TRY_SUBMIT && $v.$invalid" tabindex="4">
            {{ $t('ContentAppCreateButton') }}
          </b-button>
        </div>
      </div>
    </transition>

  </div>
</template>


<script lang="ts">
import {Component, Vue} from "vue-property-decorator";
import ContentAppResourceModel from "@/content_app/resource/_model/ContentAppResourceModel";
import {minLength, required} from "vuelidate/lib/validators";
import contentAppResourceListController from "@/content_app/resource/_controller/ContentAppResourceListController";
import AppUserModel from "@/project/user/_model/AppUserModel";
import ContentAppResourceListModel from "@/content_app/resource/_model/ContentAppResourceListModel";
import ContentAppResourceItemRenderer from "@/content_app/resource/_view/ContentAppResourceItemRenderer.vue";
import languageManager from "@/__libs/language_manager/LanguageManager";
import contentAppController from "@/content_app/_controller/ContentAppController";
import ContentAppModel from "@/content_app/_model/ContentAppModel";

enum CreateContentAppBoxState {
    STEP_1,
    STEP_2,
    TRY_SUBMIT,
    LOADING,
    REMOTE_ERROR
}

@Component({
    components: {ContentAppResourceItemRenderer},
    validations: {
        contentName: {
            required,
            minLength: minLength(3)
        }
    }
})
export default class CreateContentAppBox extends Vue {

    private createContentAppBoxState: typeof CreateContentAppBoxState = CreateContentAppBoxState;
    private currentState: CreateContentAppBoxState = CreateContentAppBoxState.LOADING;
    private contentAppResources: ContentAppResourceListModel = ContentAppResourceListModel.getInstance();
    private contentName: string = "";
    private contentAppResource: ContentAppResourceModel = new ContentAppResourceModel();

    public async mounted() {
        await contentAppResourceListController.fetchAll(AppUserModel.getInstance().project.contentAppResources);
        this.currentState = CreateContentAppBoxState.STEP_1;
    }

    private onContentAppResourceClick(contentAppResource: ContentAppResourceModel) {
        this.contentAppResource = contentAppResource;
        this.contentName = ""; //this.$t('ContentAppDefaultName', [languageManager.getTranslationForValue(this.contentAppResource.name, AppUserModel.getInstance().langCode)]).toString();
        this.currentState = CreateContentAppBoxState.STEP_2;
    }

    private _calculateInputState(inputValidator: any): any {
        return this.currentState === CreateContentAppBoxState.TRY_SUBMIT && inputValidator.$invalid ? false : null;
    }

    private cancel() {
        this.$emit("cancel");
    }

    private async create() {
        this.currentState = CreateContentAppBoxState.TRY_SUBMIT;
        if (!this.$v.$invalid) {
            this.currentState = CreateContentAppBoxState.LOADING;
            const contentApp: ContentAppModel | null = await contentAppController.create(this.contentName, this.contentAppResource);
            if (contentApp) {
                this.$emit("created", contentApp);
            } else {
                this.currentState = CreateContentAppBoxState.REMOTE_ERROR;
            }
        }
    }

}
</script>
