import EntityModel from "@/entity/_model/EntityModel";
import SelectionState from "@/__libs/_model/SelectionState";
import {DeltaStatus} from "@/entity/_model/entity.constants";
import AssetFolderModel from "@/asset_folder/_model/AssetFolderModel";
import {AssetFolderViewMode, FileTargetType, FileType} from "@/asset_folder/_model/asset_folder.constants";
import AssetFileModel from "@/asset_folder/_model/AssetFileModel";
import EntitySelectionState from "@/entity/_model/EntitySelectionState";

export default class AssetFolderSelectionState extends EntitySelectionState<AssetFolderModel>
{

    protected _selected:AssetFolderModel | null = null;
    get selected():AssetFolderModel | null
    {
        return this._selected;
    }

    set selected(value:AssetFolderModel | null)
    {
        this._previousSelected = this._selected;
        this._selected = value;
        if (this._previousSelected != this._selected)
        {
            this.selectedFile = null;
        }

    }

    public viewMode:AssetFolderViewMode;

    public allowedTargetType:FileTargetType | null = null;

    public allowedFileTypes:FileType[] = [];

    // public selectedFileURI:string | null = null;

    public selectedFile:AssetFileModel | null = null;

    public fileSearchFilter:string = "";


    constructor(viewMode:AssetFolderViewMode)
    {
        super();
        this.viewMode = viewMode;
    }

    //---------------------------------
    // Public Methods
    //---------------------------------


}
