import {
    IDataItemDefinitionDto,
    IPropertyDefinitionDto,
    IXlsColumn,
    IXlsImportResult,
    IXlsSheet
} from "@/data_tool/_model/data_tool.dto";
import {DataProviderEntryType, PropertyType} from "@/data_tool/_model/data_tool.constants";
import DataItemModel from "@/data_tool/data_item/_model/DataItemModel";
import {DataModel} from "@/data_tool/_model/DataModel";
import DataProviderModel from "@/data_tool/data_provider/_model/DataProviderModel";
import {DtoType} from "@/_model/app.constants";
import DataItemListModel from "@/data_tool/data_item/_model/DataItemListModel";
import AppUserModel from "@/project/user/_model/AppUserModel";
import languageManager, {IMultiLangString} from "@/__libs/language_manager/LanguageManager";
import DataUtil from "@/data_tool/_controller/DataUtil";


export default class DataProviderEntryModel extends DataModel
{

    public itemDefinition:IDataItemDefinitionDto;

    //public name:string = ""; //todo: choice: do these labels switch alongside the dtp active language or are the dependent on the app user language

    //an entry can be a single item or a list
    public singleItem?:DataItemModel;
    public itemList?:DataItemListModel = {} as DataItemListModel;

    public columns:IXlsColumn[] = [];

    //---------------------------------
    // Constructor
    //---------------------------------

    constructor(p_itemDefinition:IDataItemDefinitionDto, p_data:any, p_dataProvider:DataProviderModel, p_parent:DataModel | null)
    {
        super(p_dataProvider, p_parent);
        this.itemDefinition = p_itemDefinition;
        this._resolveColumnNames();
        this.build(p_data);
    }

    //---------------------------------
    // Public Methods
    //---------------------------------

    public build(p_data:any)
    {
        //this.name =  languageManager.getTranslationForValue<string>(this.itemDefinition.name as IMultiLangString, AppUserModel.getInstance().langCode);

        if (this.itemDefinition.type === DataProviderEntryType.SINGLE_ITEM)
        {
            this.singleItem = new DataItemModel(this.itemDefinition, p_data, this.dataProvider, this);
        }
        else
        {
            this.itemList = new DataItemListModel(this.itemDefinition, p_data, this.dataProvider, this);
        }
    }



    public mapToDto(p_dtoType:DtoType):any
    {
        if (p_dtoType === DtoType.BODY)
        {
            if (this.itemDefinition.type === DataProviderEntryType.SINGLE_ITEM)
            {
                return this.singleItem!.mapToDto(DtoType.BODY);
            }
            else
            {
                return this.itemList!.mapToDto(DtoType.BODY);
            }
        }

    }

    public getLogName():string
    {
        return languageManager.getTranslationForValue<string>(this.itemDefinition.name as IMultiLangString, AppUserModel.getInstance().langCode);
    }

    private _resolveColumnNames()
    {
        this.columns = [];
        this._recursePropertiesForColumns(this.columns, this.itemDefinition, this.itemDefinition.type === DataProviderEntryType.ITEM_LIST, "");
        // console.log(JSON.stringify(this.columns, null, 3));
    }

    private _recursePropertiesForColumns(p_columns:IXlsColumn[], p_itemDefinition:IDataItemDefinitionDto, p_addIDcolumn:boolean, p_prefix:string = "", p_level:number = 0)
    {
        if (p_addIDcolumn)
        {
            const __idColumn:IXlsColumn = {
                identifier: p_prefix === "" ? `${p_itemDefinition.identifier}__id` : `${p_prefix}${p_itemDefinition.identifier}__id`,
                level     : p_level, isIDcolumn: true
            }
            p_columns.push(__idColumn);
        }

        for (let i = 0; i < p_itemDefinition.properties.length; i++)
        {
            const propertyDefinition:IPropertyDefinitionDto = p_itemDefinition.properties[i];

            //if its a select list or dict, we need the selected dataprovider uri
            if (propertyDefinition.type === PropertyType.SELECT_LIST || propertyDefinition.type === PropertyType.ITEM_DICTIONARY)
            {
                const __chosenDataColumn:IXlsColumn = {
                    identifier        : `${p_prefix}${propertyDefinition.identifier}:chosen list`, level: p_level,
                    propertyDefinition: propertyDefinition
                };
                p_columns.push(__chosenDataColumn); //or do this in the value eg: dtp://[uri]]/[__id]
            }

            //it's a non-primitive (but not a select list), go deeper, because there are more props to recurse
            if (propertyDefinition.itemDefinitionURI && (propertyDefinition.type === PropertyType.ITEM_LIST || propertyDefinition.type === PropertyType.ITEM_DICTIONARY || propertyDefinition.type === PropertyType.SINGLE_ITEM))
            {
                const itemDefinition:IDataItemDefinitionDto | null = this.dataProvider.getItemDefinitionByURI(propertyDefinition.itemDefinitionURI);
                if (itemDefinition)
                {
                    //if an __id is asked by the schema designer, or if it is a dictionary, add an ID column
                    const addIDcolumn:boolean = propertyDefinition.generateID! || propertyDefinition.type === PropertyType.ITEM_DICTIONARY; //
                    this._recursePropertiesForColumns(p_columns, itemDefinition, addIDcolumn, p_prefix + propertyDefinition.identifier + "-", p_level + 1);
                }
            }
            else
            {
                const columnIdentifier:string = p_prefix + propertyDefinition.identifier;

                if (propertyDefinition.isMultiLingual)
                {
                    const langCodes:string[] = languageManager.availableLangCodes;
                    for (let j = 0; j < langCodes.length; j++)
                    {
                        const langCode = langCodes[j];
                        const __propColumn:IXlsColumn = {
                            identifier        : `${columnIdentifier}(${langCode})`, level: p_level,
                            propertyDefinition: propertyDefinition
                        };
                        p_columns.push(__propColumn);

                    }
                }
                else
                {
                    const __propColumn:IXlsColumn = {
                        identifier: columnIdentifier, level: p_level, propertyDefinition: propertyDefinition
                    };
                    p_columns.push(__propColumn);

                }
            }
        }
    }


    public async exportToSheet():Promise<IXlsSheet>
    {
        // console.log("export");
        // console.log(this.mapToDto(DtoType.BODY));

        const rows:any[] = [];

        if (this.itemDefinition.type === DataProviderEntryType.SINGLE_ITEM)
        {
            this.singleItem!.mapToRows(rows, "", 0);
        }
        else
        {
            this.itemList!.mapToRows(rows, "", 0);
        }

        console.log(rows);
        return {identifier: this.itemDefinition.identifier, columns: this.columns, rows: rows}
    }


    public importFromSheet(p_dataProvider:DataProviderModel, p_sheet:IXlsSheet, p_data:any):IXlsImportResult
    {
        const result:IXlsImportResult = {isSuccessful: true, parseMessages: []};

        //check if columns match
        for (let i = 0; i < this.columns.length; i++)
        {
            if (this.columns[i].identifier !== p_sheet.columns[i].identifier)
            {
                result.isSuccessful = false;
                result.fatalMessage = {type: "COLUMN_MISMATCH", columnIdentifier: this.columns[i].identifier};
                return result;
            }
        }

        //give each row the xls nr and column letter
        for (let i = 0; i < p_sheet.rows.length; i++)
        {
            p_sheet.rows[i].___rowNr = i + 2;
        }
        for (let i = 0; i < p_sheet.columns.length; i++)
        {
            this.columns[i].letter = DataUtil.columnToLetter(i + 1);
        }

        if (this.itemDefinition.type === DataProviderEntryType.SINGLE_ITEM)
        {
            p_data[this.itemDefinition.identifier] = DataItemModel.mapFromRows(p_dataProvider, this.itemDefinition, p_sheet.rows, this.columns, 0, "", result);
        }
        else
        {
            p_data[this.itemDefinition.identifier] = DataItemListModel.mapFromRows(p_dataProvider, this.itemDefinition, p_sheet.rows, this.columns, 0, "", result);
        }

        return result;
    }




}
