import AudienceModel from "@/audience/_model/AudienceModel";
import EntityListModel from "@/entity/_model/EntityListModel";
import EntitySelectionState from "@/entity/_model/EntitySelectionState";
import SelectionState from "../../__libs/_model/SelectionState";
import TeamUserModel from "./TeamUserModel";
import ListModel from "../../__libs/_model/ListModel";

class TeamUserListModel  extends ListModel<TeamUserModel>
{

    public globalSelState:SelectionState<TeamUserModel> = new SelectionState<TeamUserModel>();

    protected _minTimeBetweenFetchMs:number = 10000 * 60 * 1000; //don't allow the list to be fetched

    //---------------------------------
    // Singleton Constructor
    //---------------------------------

    private static _instance:TeamUserListModel;

    constructor()
    {
        super();
        if (!TeamUserListModel._instance)
        {
            TeamUserListModel._instance = this;
        }
    }

    public static getInstance():TeamUserListModel
    {
        if (!TeamUserListModel._instance)
        {
            new TeamUserListModel();
        }

        return TeamUserListModel._instance;
    }


    //---------------------------------
    // Public Methods
    //---------------------------------

    public getUserByID(p_userID:number):TeamUserModel | null
    {
        for (let i = 0; i < this.list.length; i++)
        {
            const teamUserModel = this.list[i];
            if (teamUserModel.userID === p_userID)
            {
                return teamUserModel;
            }
        }
        return null;
    }

}

export default TeamUserListModel;
