<template>

  <div class="create-audience">

    <transition name="fade">
      <div v-if="currentFormState === formState.CREATE_INPUT || currentFormState === formState.TRY_SUBMIT">
        <b-form novalidate>

          <b-form-group :label="$t('PlaceholderDisplayName')" tabindex="1">
            <b-form-input v-model="audience.displayName"
                          :state="_calculateInputState($v.audience.displayName)"
                          autofocus></b-form-input>
            <b-form-invalid-feedback>
                        <span class="d-block"
                              v-if="!$v.audience.displayName.required">{{ $t('FieldRequiredSimple') }}</span>
              <span class="d-block" v-if="!$v.audience.displayName.minLength">{{
                  $t('MinimumFieldLengthSimple', [3])
                }}</span>
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group :label="$t('PlaceholderEmail')" tabindex="2">
            <b-form-input type="email"
                          v-model="audience.email"
                          :state="_calculateInputState($v.audience.email)"></b-form-input>
            <b-form-invalid-feedback>
                            <span class="d-block"
                                  v-if="!$v.audience.email.required">{{ $t('FieldRequiredSimple') }}</span>
              <span class="d-block"
                    v-if="!$v.audience.email.email">{{ $t('ErrorInvalidEmailAddress') }}</span>
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group :label="$t('AudienceDetailCompany')" tabindex="3">
            <div class="d-flex">
              <select class="form-control mr-3 h-auto" v-model="audience.companyID">
                <option v-for="company in companies" :key="company.ID"
                        :value="company.ID">{{ company.displayName }}
                </option>
              </select>
              <b-button v-if="showCreateCompany" variant="success" class="flex-shrink-0 px-3"
                        @click="_onCreateCompanyBtnClick">
                <i class="fas fa-plus mr-0"></i>
              </b-button>
            </div>
          </b-form-group>

          <b-form-group :label="$t('PlaceholderPersonLanguage')" tabindex="4">
            <select class="form-control" v-model="audience.langCode">
              <option v-for="langCode in availableLanguages" :key="langCode"
                      :value="langCode">{{ langCode }}
              </option>
            </select>
          </b-form-group>

          <div class="modal-box-buttons">
            <b-button variant="secondary" class="mr-2" @click="_onCancelBtnClick" tabindex="6">
              {{ $t('Cancel') }}
            </b-button>
            <b-button @click="_onCreateAudienceBtnClick" variant="primary"
                      :disabled="currentFormState === formState.TRY_SUBMIT && $v.$invalid" tabindex="5">{{
                $t('DashboardMasterAddAudienceButton')
              }}
            </b-button>
          </div>
        </b-form>

        <b-modal id="create-company-box" :title="$t('AddCompany')" hide-footer>
          <CreateCompanyBox v-on:onFinish="_onCreateCompanyFinish"></CreateCompanyBox>
        </b-modal>
      </div>
    </transition>

    <transition name="fade">
      <div v-if="currentFormState === formState.CREATING_REMOTE" class="text-center">
        <b-spinner variant="dark" class="my-5"></b-spinner>
      </div>
    </transition>

    <transition name="fade">
      <div v-if="currentFormState === formState.REMOTE_ERROR">
        <p class="mb-4 text-danger">{{ $t('AudienceCreationFailed') }}</p>
        <div class="d-flex justify-content-end">
          <button class="btn btn-secondary mr-2" @click="_onCancelBtnClick">{{ $t('Cancel') }}</button>
          <button class="btn btn-primary" @click="_onCreateAudienceBtnClick">{{ $t('Retry') }}</button>
        </div>
      </div>
    </transition>

  </div>

</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import AudienceModel from "@/audience/_model/AudienceModel";
import languageManager from "@/__libs/language_manager/LanguageManager";
import AppUserModel from "@/project/user/_model/AppUserModel";
import audienceListController from "@/audience/_controller/AudienceListController";
import {email, minLength, required} from 'vuelidate/lib/validators';
import CompanyModel from "@/company/_model/CompanyModel";
import CompanyListModel from "@/company/_model/CompanyListModel";
import CreateCompanyBox from "@/company/_view/CreateCompanyBox.vue";
import {RIGHTS} from "@/team/_model/role.constants";

enum FormState {
    CREATE_INPUT,
    TRY_SUBMIT,
    CREATING_REMOTE,
    REMOTE_CREATED,
    REMOTE_ERROR
}

@Component({
    components: {CreateCompanyBox},
    validations: {
        audience: {
            displayName: {
                required,
                minLength: minLength(3)
            },
            email: {
                required,
                email
            }
        }
    }
})
export default class CreateAudienceBox extends Vue {

    //in this wizard we can gather the mandatory props for an audience before we send it to the server
    //future: import also

    private audience: AudienceModel = new AudienceModel();
    private formState: typeof FormState = FormState;
    private currentFormState: FormState = FormState.CREATE_INPUT;
    private companies: CompanyModel[] = [];

    //---------------------------------
    // Vue Computed properties
    //---------------------------------


    get availableLanguages(): string[] {
        return languageManager.availableLangCodes;
    }

    get showCreateCompany(): boolean {
        return AppUserModel.getInstance().rights.indexOf(RIGHTS.CREATE_COMPANIES.identifier) >= 0;
    }


    //---------------------------------
    // Public / lifecycle methods
    //---------------------------------

    mounted() {
        this.audience = new AudienceModel();
        this.audience.displayName = "";
        this.audience.email = "";
        this.audience.langCode = AppUserModel.getInstance().langCode;
        this.currentFormState = FormState.CREATE_INPUT;

        const noCompany: CompanyModel = new CompanyModel({displayName: this.$t('NoCompany')});
        this.companies = [noCompany, ...CompanyListModel.getInstance().list];
    }

    //---------------------------------
    // Private / helper methods
    //---------------------------------

    private _calculateInputState(inputValidator: any): any {
        return this.currentFormState === FormState.TRY_SUBMIT && inputValidator.$invalid ? false : null;
    }

    private async _onCreateAudienceBtnClick(p_e: Event) {
        this.currentFormState = FormState.TRY_SUBMIT;

        if (!this.$v.$invalid) {
            this.currentFormState = FormState.CREATING_REMOTE;

            const createSucceeded: boolean = await audienceListController.createEntity(this.audience);
            if (createSucceeded) {
                this.$emit('onFinish', this.audience);
            } else {
                this.currentFormState = FormState.REMOTE_ERROR;
            }
        }
    }

    private async _onCancelBtnClick(p_e: Event) {
        this.$emit('onFinish', null);
    }

    private _onCreateCompanyBtnClick(p_e: Event) {
        this.$bvModal.show('create-company-box');
    }

    private async _onCreateCompanyFinish(p_createdCompany: CompanyModel) {
        this.$bvModal.hide('create-company-box');
        if (p_createdCompany) {
            this.companies.push(p_createdCompany);
            this.audience.companyID = p_createdCompany.ID;
        }
    }

}
</script>
