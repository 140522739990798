<template>
  <div class="create-asset-folder">

    <transition name="fade">
      <div v-if="currentFormState === formState.CREATE_INPUT || currentFormState === formState.TRY_SUBMIT">
        <b-form @submit.stop.prevent="_onCreateDataProviderBtnClick" novalidate>

          <b-form-group :label="$t('PlaceholderDataProviderName')">
            <b-form-input v-model="dataProviderName"
                          :state="_calculateInputState($v.dataProviderName)"
                          tabindex="1"
                          autofocus></b-form-input>
            <b-form-invalid-feedback>
                        <span class="d-block"
                              v-if="!$v.dataProviderName.required">{{ $t('FieldRequiredSimple') }}</span>
              <span class="d-block"
                    v-if="!$v.dataProviderName.minLength">{{ $t('MinimumFieldLengthSimple', [3]) }}</span>
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group :label="$t('PlaceholderDataProviderSchema')">
            <b-form-select v-if="allowedSchema === null" v-model="dataSchemaIdentifier">
              <option v-for="schema in availableSchemas" :key="schema.identifier"
                      :value="schema.identifier">{{ _getSchemaName(schema) }}
              </option>
            </b-form-select>
          </b-form-group>

          <b-form-group :label="$t('PlaceholderIdentifier')" v-if="showIdentifier">
            <b-input-group :prepend="`${projectIdentifier}-`">
              <b-form-input v-model="identifierInput"></b-form-input>
            </b-input-group>
          </b-form-group>

          <div class="modal-box-buttons">
            <b-button variant="secondary" @click="_onCancelBtnClick" tabindex="4">
              {{ $t('Cancel') }}
            </b-button>
            <b-button type="submit" variant="primary"
                      :disabled="currentFormState === formState.TRY_SUBMIT && $v.$invalid" tabindex="5">{{
                $t('CreateDataProvider')
              }}
            </b-button>
          </div>

        </b-form>
      </div>
    </transition>

    <transition name="fade">
      <div v-if="currentFormState === formState.LOADING" class="text-center">
        <b-spinner variant="dark" class="my-5"></b-spinner>
      </div>
    </transition>

    <transition name="fade">
      <div v-if="currentFormState === formState.REMOTE_ERROR">
        <p class="text-danger">{{ $t('DataProviderCreationFailed') }}</p>
        <div class="modal-box-buttons">
          <button class="btn btn-secondary" @click="_onCancelBtnClick">{{ $t('Cancel') }}</button>
          <button class="btn btn-primary" @click="_onCreateDataProviderBtnClick">{{ $t('Retry') }}</button>
        </div>
      </div>
    </transition>

  </div>

</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import languageManager from "@/__libs/language_manager/LanguageManager";
import AppUserModel from "@/project/user/_model/AppUserModel";
import dataProviderListController from "@/data_tool/data_provider/_controller/DataProviderListController";
import DataProviderModel from "@/data_tool/data_provider/_model/DataProviderModel";
import {email, minLength, required} from "vuelidate/lib/validators";
import {RIGHTS} from "@/team/_model/role.constants";
import fileManager, {FileResponse} from "@/_controller/FileManager";
import {IConstructDto} from "@/presentation/_model/presentation.dto";
import {IDataSchemaDto} from "@/data_tool/_model/data_tool.dto";

enum FormState {

    CREATE_INPUT,
    TRY_SUBMIT,
    LOADING,
    REMOTE_CREATED,
    REMOTE_ERROR
}

@Component({
    validations: {
        dataProviderName: {
            required,
            minLength: minLength(3)
        }
    }
})
export default class CreateDataProviderBox extends Vue {
    @Prop() public allowedSchema!: string | null;

    private dataProvider: DataProviderModel = new DataProviderModel();

    private formState: typeof FormState = FormState;

    private currentFormState: FormState = FormState.LOADING;

    private dataSchemaIdentifier: string = AppUserModel.getInstance().project.dataSchemas[0];

    private showIdentifier: boolean = AppUserModel.getInstance().rights.indexOf(RIGHTS.HAS_DEVELOPER_ACCESS.identifier) >= 0;

    private projectIdentifier: string = AppUserModel.getInstance().project.identifier;

    private identifierInput: string = "";

    public availableSchemas: IDataSchemaDto[] = [];

    get dataProviderName() {
        return languageManager.getTranslationForValue<string>(this.dataProvider.name, AppUserModel.getInstance().langCode)
    }

    set dataProviderName(value: string) {
        //this.dataProvider.name[AppUserModel.getInstance().langCode] = value;
        Vue.set(this.dataProvider.name, AppUserModel.getInstance().langCode, value);
    }

    async mounted() {
        this.currentFormState = FormState.LOADING;
        this.availableSchemas = [];

        const schemaIdentifiers: string[] = AppUserModel.getInstance().project.dataSchemas;
        for (let i = 0; i < schemaIdentifiers.length; i++) {

            const fileResponse: FileResponse = await fileManager.fetchFileFromCdn(`schemas/${schemaIdentifiers[i]}.json`);
            if (fileResponse.hasSucceeded) {
                const schema: IDataSchemaDto = fileResponse.result;
                schema.identifier = schemaIdentifiers[i];
                this.availableSchemas.push(schema);
            } else {
                this.currentFormState = FormState.REMOTE_ERROR;
                return;
            }
        }
        this.currentFormState = FormState.CREATE_INPUT;
    }


    //---------------------------------
    // Private / helper methods
    //---------------------------------

    private _calculateInputState(inputValidator: any): any {
        return this.currentFormState === FormState.TRY_SUBMIT && inputValidator.$invalid ? false : null;
    }

    private async _onCreateDataProviderBtnClick(p_e: Event) {
        this.currentFormState = FormState.TRY_SUBMIT;

        if (!this.$v.$invalid) {
            this.currentFormState = FormState.LOADING;
            this.dataProvider.dataSchemaURI = this.allowedSchema ? this.allowedSchema : "dataSchema://" + this.dataSchemaIdentifier;
            this.dataProvider.isEditable = true;
            if (this.identifierInput.length > 0) {
                this.dataProvider.identifier = this.projectIdentifier + "-" + this.identifierInput;
            }
            const createSucceeded: boolean = await dataProviderListController.createEntity(this.dataProvider);
            if (createSucceeded) {
                this.$emit('onFinish', this.dataProvider);
            } else {
                this.currentFormState = FormState.REMOTE_ERROR;
            }
        }
    }

    private async _onCancelBtnClick(p_e: Event) {
        this.$emit('onFinish', null);
    }

    private _getSchemaName(p_schema: IDataSchemaDto) {
        return p_schema.name ? languageManager.getTranslationForValue<string>(p_schema.name, AppUserModel.getInstance().langCode) : p_schema.identifier;
    }
}
</script>
