<template>
  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
    <path class="cls-1" d="M27,0H5C2.24,0,0,2.24,0,5v22c0,2.76,2.24,5,5,5h22c2.76,0,5-2.24,5-5V5C32,2.24,29.76,0,27,0ZM18.33,26.63h-4.66v-13.15h4.66v13.15ZM17.89,9.5c-.48.45-1.11.67-1.89.67s-1.41-.22-1.89-.67c-.48-.45-.71-1.03-.71-1.73s.23-1.27.7-1.72c.47-.45,1.1-.67,1.9-.67s1.42.22,1.9.67c.47.45.71,1.02.71,1.72s-.24,1.28-.71,1.73Z"/>
  </svg>
</template>

<script lang="ts">
import {Component, Vue} from "vue-property-decorator";

@Component({
  components: {}
})
export default class HelpIcon extends Vue {
}
</script>
