import {DataProviderEntryType, PropertyType} from "@/data_tool/_model/data_tool.constants";
import {IDataItemDefinitionDto, IDataSchemaDto, IPropertyDefinitionDto} from "@/data_tool/_model/data_tool.dto";
import DataUtil from "@/data_tool/_controller/DataUtil";
import {FileTargetType, FileType} from "@/asset_folder/_model/asset_folder.constants";
import {ContentType} from "@/entity/_model/entity.constants";

class DataSchemaUtil
{
    //---------------------------------
    // Public Static Methods
    //---------------------------------



    public static validateSchema(p_schema:IDataSchemaDto)
    {
        //validate all item definitions
        for (let i = 0; i < p_schema.itemDefinitions.length; i++)
        {
            DataSchemaUtil.validateDataItemDefinition(p_schema.itemDefinitions[i], p_schema.identifier);
        }
    }


    public static validateDataItemDefinition(p_itemDefinition:IDataItemDefinitionDto, p_logPath:string)
    {

        const logPath = p_logPath + "#" + p_itemDefinition.identifier;

        if (!p_itemDefinition.type)
        {
            p_itemDefinition.type = DataProviderEntryType.ITEM_LIST; //p_itemDefinition defaults to ITEM_LIST
        }

        if (p_itemDefinition.hasDataProviderEntry === undefined)
        {
            p_itemDefinition.hasDataProviderEntry = true; //hasDataProviderEntry defaults to true
        }


        for (let i = 0; i < p_itemDefinition.properties.length; i++)
        {
            const propDefinition:IPropertyDefinitionDto = p_itemDefinition.properties[i];
            DataSchemaUtil.validatePropertyDefinition(propDefinition, logPath);

            //set the nameProperty
            if (propDefinition.identifier === "name" || propDefinition.identifier === p_itemDefinition.nameProperty)
            {
                p_itemDefinition.nameProperty = propDefinition.identifier;
                propDefinition.isRequired = true; //the name property should always be required
            }


            //todo: could we check for recurring item definitions here and block them (eg a person itemdefinition where a person itemdefinition is used as one of the properties: eg father property)
        }

        //check if a name property is needed and present (//if this itemdef can be used as a dataprovider, it needs a name and id prop)
        if (p_itemDefinition.type === DataProviderEntryType.ITEM_LIST && p_itemDefinition.hasDataProviderEntry)
        {
            if (!p_itemDefinition.nameProperty)
            {
                console.log(`ERROR: the item definition "${logPath}" needs a property with identifier 'name' or have a nameProperty defined in it's definition`);
            }

            if (p_itemDefinition.canBeUsedAsDataProvider === undefined)
            {
                p_itemDefinition.canBeUsedAsDataProvider = true; //canBeUsedAsDataProvider defaults to true
            }

        }
        else
        {
            p_itemDefinition.canBeUsedAsDataProvider = false;
        }

        // if(p_itemDefinition.idProperty)
        // {
        //       //todo: check if idProperty isPrimitive, not multilingual, ...
        // }



    }


    public static validatePropertyDefinition(p_propDefinition:IPropertyDefinitionDto, p_logPath:string)
    {
        const logPath = p_logPath + ">" + p_propDefinition.identifier;

        if (!p_propDefinition.type)
        {
            console.log(`ERROR: the property definition "${logPath}" needs a "type" defined`);
        }


        if (!p_propDefinition.name)
        {
            // console.log(`WARNING: the property definition "${logPath}" needs a "name" defined. Taking identifier as name`);
            p_propDefinition.name = {en: p_propDefinition.identifier};
        }


        if (p_propDefinition.type === PropertyType.SINGLE_ITEM || p_propDefinition.type === PropertyType.ITEM_LIST || p_propDefinition.type === PropertyType.ITEM_DICTIONARY || p_propDefinition.type === PropertyType.SELECT_LIST || p_propDefinition.type === PropertyType.EXTERNAL_LIST || p_propDefinition.type === PropertyType.DATAPROVIDER_LINK|| p_propDefinition.type === PropertyType.CONTENT_LINK)
        {
            p_propDefinition.isPrimitive = false;
            p_propDefinition.isMultiLingual = false;
        }
        else
        {
            p_propDefinition.isPrimitive = true;
            //clear everything that can mess things up when wrongly filled in
            delete p_propDefinition.itemDefinitionURI;
            delete p_propDefinition.itemKeyDefinitionURI;
            if (p_propDefinition.isRequired === undefined)
            {
                p_propDefinition.isRequired = false;
            }
        }

        /*
        if (p_propDefinition.isMultiLingual)
        {
            if (p_propDefinition.defaultValue === undefined || p_propDefinition.defaultValue === null)
            {
                p_propDefinition.defaultValue = {"en": null}; //needed for Vue to wrap it's observers around the object
            }
            else
            {
                //check if it isn't a multilangdict as default value, because we need one
                if (typeof p_propDefinition.defaultValue !== "object")
                {
                    p_propDefinition.defaultValue = {"en": p_propDefinition.defaultValue};
                }
            }
        }
        */

        if (!p_propDefinition.formElement && (p_propDefinition.isPrimitive || p_propDefinition.type === PropertyType.SELECT_LIST || p_propDefinition.type === PropertyType.EXTERNAL_LIST))
        {
            p_propDefinition.formElement = DataUtil.getDefaultFormElement(p_propDefinition);
        }

        //set some necessary defaults for primitives (for vue observers and logic)
        if (p_propDefinition.defaultValue === undefined)
        {
            if (p_propDefinition.type === PropertyType.ASSET_FILE)
            {
                p_propDefinition.defaultValue = "";
            }
            else if (p_propDefinition.type === PropertyType.ONOFF)
            {
                p_propDefinition.defaultValue = false;
            }
        }


        if (p_propDefinition.type === PropertyType.ASSET_FILE)
        {
            if (!p_propDefinition.allowedFileTypes)
            {
                p_propDefinition.allowedFileTypes = [FileType.IMAGE, FileType.AR_MODEL, FileType.ARCHIVE, FileType.AUDIO, FileType.PORTABLE_DOCUMENT, FileType.PRESENTATION, FileType.SPREADSHEET, FileType.TEXT, FileType.UNKNOWN, FileType.VIDEO]
            }
            if (!p_propDefinition.allowedTargetType)
            {
                p_propDefinition.allowedTargetType = FileTargetType.GENERAL_DOCUMENT;
            }
        }

        if (p_propDefinition.type === PropertyType.CONTENT_LINK)
        {
            if (!p_propDefinition.allowedContentTypes)
            {
                p_propDefinition.allowedContentTypes = [ContentType.PRESENTATION, ContentType.CONTENT_APP, ContentType.CONTENT_FILE]
            }

        }


        //::::: validation

        if (p_propDefinition.type === PropertyType.TEXT)
        {
            if (p_propDefinition.minLength !== undefined)
            {
                p_propDefinition.isRequired = true;
            }
            if (p_propDefinition.isRequired)
            {
                if (p_propDefinition.minLength === undefined)
                {
                    p_propDefinition.minLength = 1;
                }
            }
        }
        else if (p_propDefinition.type === PropertyType.SELECT_LIST || p_propDefinition.type === PropertyType.EXTERNAL_LIST || p_propDefinition.type === PropertyType.ITEM_LIST)
        {
            if (p_propDefinition.minItems !== undefined)
            {
                p_propDefinition.isRequired = true;
            }
            if (p_propDefinition.isRequired)
            {
                if (p_propDefinition.minItems === undefined)
                {
                    p_propDefinition.minItems = 1;
                }
            }
        }
        if (p_propDefinition.generateID && p_propDefinition.type === PropertyType.ITEM_LIST)
        {
            p_propDefinition.generateID = true;
        }
        else
        {
            p_propDefinition.generateID = false;
        }

    }

    //get the schema URI's this schema is referring to
    public static resolveDependentSchemas(p_schema:IDataSchemaDto):string[]
    {
        const schemaURIs:string[] = [];

        //todo: schema's for data entries

        for (let i = 0; i < p_schema.itemDefinitions.length; i++)
        {
            const itemDefinition:IDataItemDefinitionDto = p_schema.itemDefinitions[i];

            for (let j = 0; j < itemDefinition.properties.length; j++)
            {
                const propDefinition:IPropertyDefinitionDto = itemDefinition.properties[j];
                let neededItemDefinitionURI:string | null = null;
                if (propDefinition.itemDefinitionURI)
                {
                    neededItemDefinitionURI = propDefinition.itemDefinitionURI;
                }
                if (propDefinition.itemKeyDefinitionURI)
                {
                    neededItemDefinitionURI = propDefinition.itemKeyDefinitionURI;
                }
                if (neededItemDefinitionURI)
                {
                    const neededSchemaURI:string = neededItemDefinitionURI.split("#")[0];
                    if (schemaURIs.indexOf(neededSchemaURI) < 0)
                    {
                        schemaURIs.push(neededSchemaURI);
                    }
                }
            }
        }
        return schemaURIs;
    }

    public static getItemDefinitionByIdentifier(p_identifier:string, p_dataSchema:IDataSchemaDto):IDataItemDefinitionDto | null
    {
        for (let i = 0; i < p_dataSchema.itemDefinitions.length; i++)
        {
            const itemDefinition:IDataItemDefinitionDto = p_dataSchema.itemDefinitions[i];
            if (itemDefinition.identifier === p_identifier)
            {
                return itemDefinition;
            }
        }
        return null;
    }
}

export default DataSchemaUtil;
