<template>
  <div class="root-page presentation-player-page">
    <PlaylistPlayerEntries v-if="showEntries" />
    <PlaylistPlayerContentItemRenderer v-for="(entry, index) in playlistListModel.entryQueue"
                                       :playlistEntry="entry"
                                       :key="index"/>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import PresentationPlayer from "@/presentation/player/_view/PresentationPlayer.vue";
import {EngineMode} from "@/presentation/player/_model/player.constants";
import {RoutingIdentifier} from "@/router";
import {Route} from "vue-router";
import ContentListModel from "@/content/_model/ContentListModel";
import PresentationModel from "@/presentation/_model/PresentationModel";
import {LoadingStatus} from "@/entity/_model/entity.constants";
import PlaylistListModel from "@/playlist/_model/PlaylistListModel";
import {IPlaylistEntryDto} from "@/playlist/_model/playlist.dto";
import PlaylistPlayerEntries from "@/playlist/player/_view/PlaylistPlayerEntries.vue";
import PlaylistPlayerContentItemRenderer from "@/playlist/player/_view/PlaylistPlayerContentItemRenderer.vue";
import Util from "@/__libs/utility/Util";

@Component({
    components: {
        PresentationPlayer, PlaylistPlayerEntries, PlaylistPlayerContentItemRenderer
    }
})

export default class PlaylistPlayerPage extends Vue {

    @Prop() private contentID?: string;

    public playlistListModel: PlaylistListModel = PlaylistListModel.getInstance();

    get showEntries(): boolean {
        return this.playlistListModel.entryQueue.length > 1;
    }

    mounted() {
        if (this.contentID) {
            this.playlistListModel.entryQueue = [{contentID: this.contentID}]; //if it's a single piece of content, build a queue with that content only
        } else if (this.playlistListModel.selectedPlaylist) {
            this.playlistListModel.entryQueue = Util.deepCopy(this.playlistListModel.selectedPlaylist.entries); //if it's a playlist, duplicate the playlist's entries
        }

        if (this.playlistListModel.entryQueue[0]) {
            //start playing the first entry
            this.playlistListModel.selectedQueueEntry = this.playlistListModel.entryQueue[0];
        }

    }

    get playlist() {
        return this.playlistListModel.selectedPlaylist;
    }

}
</script>
