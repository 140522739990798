<template>
  <div class="user-info">
    <AAProfilePicture :name="appUser.displayName" :imageUri="appUser.avatarFileUri" :big="true"></AAProfilePicture>
    <div class="user-info__texts">
      <h4 class="mb-1" v-html="appUser.displayName"></h4>
      <p class="mb-0"><span v-if="appUser.jobTitle">{{ appUser.jobTitle }} | </span>{{ appUser.teamName }}</p>
    </div>
  </div>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import AppUserModel from "@/project/user/_model/AppUserModel";
import AAProfilePicture from "@/_view/components/AAProfilePicture.vue";

@Component({
  components: {
    AAProfilePicture
  }
})
export default class UserInfo extends Vue {

  private appUser: AppUserModel = AppUserModel.getInstance();

}
</script>

