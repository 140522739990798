<template>

    <div class="analytics-block">
      <div class="aa-responsive">
        <div class="aa-responsive__wrapper aa-responsive__wrapper--square d-flex align-items-center justify-content-center">
          <h4 class="aa-title">{{ boxValue }}</h4>
        </div>
      </div>
      <div class="analytics-block__label">{{ boxLabel }}</div>
    </div>

</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {ITeamAnalyticsDto, IUserAnalyticsDto} from "@/analytics/_model/analytics.dto";
import {AnalyticsUsageBoxType} from "@/analytics/_model/analytics.constants";
import i18n from "@/__plugins/i18n";


@Component({
    components: {}
})
export default class AnalyticsUsageDetailBox extends Vue
{

    //---------------------------------
    // Vue Component props
    //---------------------------------

    @Prop() private activeAnalytics!:ITeamAnalyticsDto | IUserAnalyticsDto;
    @Prop() private boxType!:AnalyticsUsageBoxType;

    //---------------------------------
    // Vue Component data
    //---------------------------------

    private boxValue:string = "";
    private boxLabel:string = "";

    //---------------------------------
    // Vue Computed properties
    //---------------------------------



    // get subjects():any[]
    // {
    //     return this.usageData;
    // }

    //---------------------------------
    // Public / lifecycle methods
    //---------------------------------

    mounted()
    {
        this.setBlock();
    }



    //---------------------------------
    // Private / helper methods
    //---------------------------------

    @Watch('activeAnalytics', {immediate: true, deep: false})
    private setBlock()
    {

        if (this.boxType === AnalyticsUsageBoxType.USAGE_EVOLUTION_VS_LAST_PERIOD)
        {
            this.boxLabel = i18n.tc("UsageEvolutionVsLastPeriod");
            this.boxValue = this.activeAnalytics.usageEvolution > 0 ? "+" + this.activeAnalytics.usageEvolution + "%" : this.activeAnalytics.usageEvolution + "%";
        }
        else if (this.boxType === AnalyticsUsageBoxType.AVERAGE_USAGE)
        {
            this.boxLabel = i18n.tc("AverageUsagePerUser");
            this.boxValue = (Math.round(this.activeAnalytics.averageUsagePerUser * 100) / 100).toString();
        }
        else if (this.boxType === AnalyticsUsageBoxType.NR_OF_USERS)
        {
            this.boxLabel = i18n.tc("NrOfUsers");
            this.boxValue = (this.activeAnalytics as ITeamAnalyticsDto).nrOfUsers.toString();
        }
        else if (this.boxType === AnalyticsUsageBoxType.NR_OF_DELETED_USERS)
        {
            this.boxLabel = i18n.tc("NrOfDeletedUsers");
            this.boxValue = this.activeAnalytics.nrOfDeletedUsers.toString();
        }
        else if (this.boxType === AnalyticsUsageBoxType.NR_OF_CREATED_USERS)
        {
            this.boxLabel = i18n.tc("NrOfCreatedUsers");
            this.boxValue = this.activeAnalytics.nrOfCreatedUsers.toString();
        }
        else if (this.boxType === AnalyticsUsageBoxType.LAST_LOGIN)
        {
            this.boxLabel = i18n.tc("LastLogin");
            this.boxValue = this.$d(new Date((this.activeAnalytics as IUserAnalyticsDto).lastLogin), 'short');
        }
        else if (this.boxType === AnalyticsUsageBoxType.USAGE_VS_ALL)
        {
            this.boxLabel = i18n.tc("BenchmarkedUsageVsAllUsers");
            this.boxValue = (this.activeAnalytics as IUserAnalyticsDto).usageVsAllUsers > 0 ? "+" + (this.activeAnalytics as IUserAnalyticsDto).usageVsAllUsers + "%" : (this.activeAnalytics as IUserAnalyticsDto).usageVsAllUsers + "%";
        }
        else if (this.boxType === AnalyticsUsageBoxType.AVERAGE_LOGINS_PER_WEEK)
        {
            this.boxLabel = i18n.tc("AverageLoginsPerWeek");
            this.boxValue = (Math.round((this.activeAnalytics as IUserAnalyticsDto).avgLoginsPerWeek * 100) / 100).toString();
        }
        else if (this.boxType === AnalyticsUsageBoxType.AVERAGE_PRESENTATIONS_PER_WEEK)
        {
            this.boxLabel = i18n.tc("AveragePresentationsPerWeek");
            this.boxValue = (Math.round((this.activeAnalytics as IUserAnalyticsDto).avgPresentationsPerWeek * 100) / 100).toString();
        }
        else if (this.boxType === AnalyticsUsageBoxType.LOGGED_IN)
        {
            this.boxLabel = i18n.tc("loggedIn");
            this.boxValue = (this.activeAnalytics as IUserAnalyticsDto).loggedIn.toString();
        }
        else if (this.boxType === AnalyticsUsageBoxType.PRESENTED)
        {
            this.boxLabel = i18n.tc("presented");
            this.boxValue = (this.activeAnalytics as IUserAnalyticsDto).presented.toString();
        }
        else if (this.boxType === AnalyticsUsageBoxType.SHARED)
        {
            this.boxLabel = i18n.tc("shared");
            this.boxValue = (this.activeAnalytics as IUserAnalyticsDto).shared.toString();
        }
        else if (this.boxType === AnalyticsUsageBoxType.TOTAL)
        {
            this.boxLabel = i18n.tc("total");
            this.boxValue = (this.activeAnalytics as IUserAnalyticsDto).total.toString();
        }
    }


}
</script>

