import SyncDataProcess from "@/sync/_controller/process/SyncDataProcess";
import syncController from "@/sync/_controller/SyncController";
import SyncModuleProcess from "@/sync/_controller/process/SyncModuleProcess";
import {SyncProcessType} from "@/sync/_model/sync.constants";
import SyncSlideProcess from "@/sync/_controller/process/SyncSlideProcess";
import {IEntityBodyDto} from "@/entity/_model/entity.dto";
import {IPresentationBodyDto} from "@/presentation/_model/presentation.dto";
import {ISyncItemDto, ISyncItemFileDto} from "@/sync/_model/sync.dto";
import SyncConstructProcess from "@/sync/_controller/process/SyncConstructProcess";
import {IDataSchemaDto} from "@/data_tool/_model/data_tool.dto";
import DataSchemaUtil from "@/data_tool/_controller/DataSchemaUtil";

class SyncSchemaProcess extends SyncDataProcess
{

    //---------------------------------
    // Properties
    //---------------------------------

    protected _storagePath:string = "schemas";
    public dependencyLevel:number = 2;



    //---------------------------------
    // Controller Methods
    //---------------------------------

    protected _resolveItemFilePath(p_syncItem:ISyncItemDto):string
    {
        return `/${this._storagePath}/${p_syncItem.identifier}.json`;
    }

    protected _parseRetrievedItemFile(p_itemFile:ISyncItemFileDto)
    {
        const schema:IDataSchemaDto = p_itemFile.body as IDataSchemaDto;//IDataSchemaDto;

        const dependentSchemaURIs = DataSchemaUtil.resolveDependentSchemas(schema);
        for (let i = 0; i < dependentSchemaURIs.length; i++)
        {
            this.addSyncItem({identifier: dependentSchemaURIs[i].split("://")[1]});
        }

    }

    //---------------------------------
    // Private Methods
    //---------------------------------


}


export default SyncSchemaProcess;
