<template>
    <div class="aa-list-item analytics-content-item d-flex align-items-center"
         :class="{ active: isSelected, 'body-loaded': true }"
         v-on:click="_onClick">

        <div class="analytics-content-item__avatar">
            <i class="fa-2x" :class="iconClass"></i>
        </div>

        <div class="analytics-content-item__info flex-grow-1">
            <h6 class="team-item__info__display-name mb-0" v-line-clamp="1">{{ name }}</h6>
        </div>

    </div>
</template>

<script lang="ts">
    import {Component, Prop, Vue} from "vue-property-decorator";

    import SelectionState from "@/__libs/_model/SelectionState";
    import {IAnalyticsSubjectTypeCfgDto, IContentTypeAnalyticsDto} from "@/analytics/_model/analytics.dto";
    import languageManager from "@/__libs/language_manager/LanguageManager";
    import AppUserModel from "@/project/user/_model/AppUserModel";

    @Component({
        components: {}
    })
    export default class AnalyticsSubjectTypeItemRenderer extends Vue
    {
        //---------------------------------
        // Vue Component props
        //---------------------------------

        @Prop() public subjectType!:IContentTypeAnalyticsDto;

        @Prop() private selState!:SelectionState<IContentTypeAnalyticsDto>;

        //---------------------------------
        // Vue Component data
        //---------------------------------

        //---------------------------------
        // Vue Computed properties
        //---------------------------------

        get iconClass(){

            return this.subjectType.cfg.icon;
            // return "fa-folder";
        }

        get isSelected()
        {
            return this.selState.selected === this.subjectType;
        }

        get name()
        {
            if (typeof this.subjectType.cfg.name === 'string')
            {
                return this.$t(this.subjectType.cfg.name);
            }
            else
            {
                return languageManager.getTranslationForValue<string>(this.subjectType.cfg.name, AppUserModel.getInstance().langCode)
            }
        }

        //---------------------------------
        // Public / lifecycle methods
        //---------------------------------

        public _onClick(p_e:Event)
        {
            this.$emit("onClick", this.subjectType);
        }

        //---------------------------------
        // Private / helper methods
        //---------------------------------
    }
</script>
