<template>
  <div class="main-sub-page main-sub-page--with-submenu library-page">

    <div class="sub-menu-bar">
      <div class="sub-menu">
        <router-link :to="{ name: routingIdentifiers.ASSETS }" class="sub-menu-item">{{ $t('MainMenuAssetManagement') }}</router-link>
        <router-link :to="{ name: routingIdentifiers.SLIDES }" v-if="showSlides" class="sub-menu-item">{{ $t('MainMenuSlides') }}</router-link>
        <router-link :to="{ name: routingIdentifiers.DATA_PROVIDERS }" v-if="showDataTool" class="sub-menu-item">{{ $t('MainMenuDataProviders') }}</router-link>
      </div>
    </div>

    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>

  </div>
</template>

<script lang="ts">
import {Component, Vue} from "vue-property-decorator";
import CreateTeamUserBox from "@/team/_view/CreateTeamUserBox.vue";
import AppUserModel from "@/project/user/_model/AppUserModel";
import {RoutingIdentifier} from "@/router";
import roleController from "@/team/_controller/RoleController";
import {RIGHTS} from "@/team/_model/role.constants";

@Component({
  components: {}
})
export default class LibraryPage extends Vue {

  public appUser: AppUserModel = AppUserModel.getInstance();
  private routingIdentifiers = RoutingIdentifier;

  get showSlides(): boolean {
    return AppUserModel.getInstance().rights.indexOf(RIGHTS.VIEW_SLIDES.identifier) >= 0 && AppUserModel.getInstance().project.tierConfig.hasPresentations;
  }

  get showDataTool(): boolean {
    return AppUserModel.getInstance().rights.indexOf(RIGHTS.VIEW_DATA_PROVIDERS.identifier) >= 0 && AppUserModel.getInstance().project.tierConfig.hasContentApps;
  }

  public mounted() {
    roleController.fetchAllRoles();
  }

}
</script>
