<template>
  <div class="leaderboard-item" :class="{ active: isAppUser }">
    <div class="leaderboard-item__ranking">{{ ranking }}.</div>
    <div class="leaderboard-item__name">
      <AAProfilePicture :name="user.userDisplayName" :imageUri="user.userAvatarFileUri"></AAProfilePicture>
      <h6 v-html="user.userDisplayName" class="mb-0" v-line-clamp="1"></h6>
    </div>
    <div class="leaderboard-item__status">
      <AAImage :image-url="statusIcon"></AAImage>
      <h6 v-html="statusName" class="mb-0" v-line-clamp="1"></h6>
    </div>
    <div class="leaderboard-item__team">{{ user.teamDisplayName }}</div>
    <div class="leaderboard-item__coins">
      <CoinAmount :amount="user.totalCoins"></CoinAmount>
    </div>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import AppUserModel from "@/project/user/_model/AppUserModel";
import {IGamificationLeaderBoardUserDto} from "@/gamification/_model/gamification.dto";
import languageManager from "@/__libs/language_manager/LanguageManager";
import GamificationUtil from "@/gamification/_controller/GamificationUtil";
import CoinAmount from "@/gamification/_view/CoinAmount.vue";
import AAProfilePicture from "@/_view/components/AAProfilePicture.vue";
import AAImage from "@/_view/components/AAImage.vue";

@Component({
  components: {CoinAmount, AAProfilePicture, AAImage}
})
export default class LeaderBoardItem extends Vue {

  @Prop() public user!: IGamificationLeaderBoardUserDto;
  @Prop() public ranking!: number;

  private appUser: AppUserModel = AppUserModel.getInstance();

  get statusIndex(): number {
    return GamificationUtil.getStatusIndex(this.user.totalCoins, this.appUser.project.config.gamification.status);
  }

  get statusIcon(): string {
    return GamificationUtil.getStatusIcon(this.statusIndex);
  }

  get statusName(): string {
    return languageManager.getTranslationForValue(GamificationUtil.getStatusName(this.statusIndex, this.appUser.project.config.gamification.status));
  }

  get isAppUser(): boolean {
    return this.appUser.ID === this.user.userID;
  }

}
</script>

