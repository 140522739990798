import SyncProcess from "@/sync/_controller/process/SyncProcess";
import {ISyncFileDto, ISyncFileRecord} from "@/sync/_model/sync.dto";
import syncController from "@/sync/_controller/SyncController";
import {SyncFileRetrieveStatus} from "@/sync/_model/sync.constants";
import ContentFolderListModel from "@/content/_model/ContentFolderListModel";
import ContentFolderModel from "@/content/_model/ContentFolderModel";
import AppUserModel from "@/project/user/_model/AppUserModel";
import fileManager from "@/_controller/FileManager";

class SyncMetaProcess extends SyncProcess
{

    //this process retrieves meta info from the pwa and parses it to reveal files that are used in there (like posters in content folders, or project skin files, user avatar...)

    //---------------------------------
    // Properties

    //---------------------------------

    public dependencyLevel:number = 3;




    //---------------------------------
    // Public Methods
    //---------------------------------






    //---------------------------------
    // Overridden Methods
    //---------------------------------

    public startProcess()
    {
        //get the posters for the content folders
        const contentFolders:ContentFolderModel[] = ContentFolderListModel.getInstance().contentFoldersDict.items;
        for (let i = 0; i < contentFolders.length; i++)
        {
            const contentFolder:ContentFolderModel = contentFolders[i];
            if(contentFolder.body.poster)
            {
                this._parseAssetFilesFromMultiLangString(contentFolder.body.poster);
            }
        }

        //logos
        const systemAssetFolderPath:string = `/assetFolders/asf-${AppUserModel.getInstance().project.identifier}-system-assets/`;
        const systemAssets:string[] = ["logo-dark-bg.png","logo-light-bg.png" ];
        for (let i = 0; i < systemAssets.length; i++)
        {
            const filePath:string = systemAssetFolderPath + systemAssets[i];
            const syncFile:ISyncFileDto = {
                path          : filePath,
                storageScope  : this._storageScope,
                retrieveStatus: SyncFileRetrieveStatus.IDLE,
                nrOfAttempts  : 0
            };
            syncController.fileGroupFileProcess.addFileGroupFile(syncFile);
        }

        //user avatar
        const filePath:string = fileManager.getAssetFileBasePath(AppUserModel.getInstance().avatarFileUri);
        if (filePath.length > 0)
        {
            const syncFile:ISyncFileDto = {
                path          : filePath,
                storageScope  : this._storageScope,
                retrieveStatus: SyncFileRetrieveStatus.IDLE,
                nrOfAttempts  : 0
            };
            syncController.fileGroupFileProcess.addFileGroupFile(syncFile);
        }


        this._finishProcess();
    }




    //---------------------------------
    // Private Methods
    //---------------------------------



    //---------------------------------
    // Overridden Methods
    //---------------------------------



}

export default SyncMetaProcess;
