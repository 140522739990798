<template>
  <div class=""
       :class="{ dropAttract: showDropAttract }"
       :draggable="contentFolder.isEditable"
       @dragstart="_onDragStart" @dragend="_onDragEnd" @dragover="_onDragOver"
       @dragenter="_onDragEnter" @dragleave="_onDragLeave" @drop="_onDrop" :id="`folder-${folderID}`" @click="_onClick">

    <div tabindex="0" class="content-item folder-item"
         :class="{ 'poster-loaded': posterLoaded, noDropAllowed: showNoDropAllowed, dropFinished: dropFinished }"
         >

      <div class="content-item__background">
        <b-skeleton-img v-if="!posterLoaded"></b-skeleton-img>
        <AAImage class="content-item__background__image" :imageUrl="posterUrl"
                 v-on:image-loaded="posterLoad"/>
      </div>

      <div class="content-item__content">
        <div class="content-item__content__bar">
          <p class="content-item__content__name" v-line-clamp="1">{{ contentFolderName }}</p>
          <div class="content-item__content__dots" @click="_onClickDotsBtn" v-if="online">
            <i class="fas fa-ellipsis-v"></i>
          </div>
        </div>
        <AAImage image-url="img/folder-icon.png"></AAImage>

        <!--                    todo: check with TomA: can only work if not under @click-->
        <!--                    <MorePopup v-if="contentFolder.isEditable"  :id="`folder-${folderID}`" placement="right" class="content-item__content__dots">-->
        <!--                        <FolderActionBox/>-->
        <!--                    </MorePopup>-->

      </div>

      <div class="content-item__popover-position"></div>

    </div>

    <!--        <b-popover :target="`overview-${folderID}`" triggers="click blur" placement="right" boundary="window">-->
    <!--            <EntityActionBox :entity="contentFolder" :editAllowed="true"/>-->
    <!--        </b-popover>-->

    <b-popover :target="`folder-${folderID}`" triggers="click blur" placement="left"
               boundary="window">
      <FolderActionBox :content-folder="contentFolder"></FolderActionBox>
    </b-popover>

  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from "vue-property-decorator";
import ContentModel from "@/content/_model/ContentModel";
import {DeltaStatus, EntityType, LoadingStatus} from "@/entity/_model/entity.constants";
import fileManager from "@/_controller/FileManager";
import AudienceModel from "@/audience/_model/AudienceModel";
import AudienceListModel from "@/audience/_model/AudienceListModel";
import languageManager from "@/__libs/language_manager/LanguageManager";
import EntityActionBox from "@/entity/_view/EntityActionBox.vue";
import EntitySelectionState from "@/entity/_model/EntitySelectionState";
import AAImage from "@/_view/components/AAImage.vue";
import {FILE_TYPE_CONFIG} from "@/asset_folder/_model/asset_folder.constants";
import ContentFileModel from "@/content_file/_model/ContentFileModel";
import ContentFolderModel from "@/content/_model/ContentFolderModel";
import contentFolderController from "@/content/_controller/ContentFolderController";
import ContentFolderListModel from "@/content/_model/ContentFolderListModel";
import contentController from "@/content/_controller/ContentController";
import {IEntityAudienceShareOutlineDto} from "@/entity/_model/entity.dto";
import {EventBus} from "@/__libs/vue/EventBus";
import {EventBusActions} from "@/_model/app.constants";
import MorePopup from "@/_view/components/MorePopup.vue";
import toastManager, {ToastType} from "@/__libs/toast_manager/ToastManager";
import i18n from "@/__plugins/i18n";
import FolderActionBox from "@/content/_view/FolderActionBox.vue";
import ContentOverviewSelectionState from "@/content/_model/ContentOverviewSelectionState";
import NetworkManager from "@/_controller/NetworkManager";
import {RightAction} from "@/team/_model/role.constants";

@Component({
    components: {
        FolderActionBox,
        AAImage,
        EntityActionBox,
        MorePopup
    }
})
export default class ContentFolderItemRenderer extends Vue {
    //---------------------------------
    // Vue Component props
    //---------------------------------

    @Prop() public contentFolder!: ContentFolderModel;
    @Prop() private selState!: ContentOverviewSelectionState;


    //---------------------------------
    // Vue Component data
    //---------------------------------

    public networkManager: NetworkManager = NetworkManager.getInstance();


    public posterLoaded: boolean = false;

    public dragCount: number = 0;
    public isBeingDraggedOver: boolean = false;
    public dropFinished: boolean = false;


    //---------------------------------
    // Vue Computed properties
    //---------------------------------

    get online() {
        return this.networkManager.online;
    }

    get contentFolderName() {
        return languageManager.getTranslationForValue(
          this.contentFolder.body.name,
          this.audienceLangCode
        );
    }

    get folderID() {
        return this.contentFolder.folderID;
    }


    get posterUrl() {
        const posterUri: string = languageManager.getTranslationForValue(
          this.contentFolder.body.poster,
          this.audienceLangCode
        );
        return fileManager.getFileUrl(posterUri);
    }

    get audienceLangCode(): string | null {
        const activeAudience: AudienceModel | null = AudienceListModel.getInstance()
          .globalSelState.selected;
        return activeAudience ? activeAudience.langCode : null;
    }


    get showNoDropAllowed() {
        // check if currentDragged is folder or content  (when it is content, you should not take isEditable into account)
        let currentDragged= ContentFolderListModel.getInstance().currentDragged;
        if (currentDragged instanceof ContentFolderModel) {
            return ContentFolderListModel.getInstance().dragInProgress && !this.contentFolder.isEditable;
        }else if (currentDragged instanceof ContentModel){
            const isEditable: boolean = currentDragged!.rightActions.indexOf(RightAction.EDIT) >= 0;
            return ContentFolderListModel.getInstance().dragInProgress && !isEditable;
        }
        return ContentFolderListModel.getInstance().dragInProgress;
    }

    get showDropAttract() {
        return this.isBeingDraggedOver && !this.showNoDropAllowed;
    }

    get nrOfFilesAndFolders() {
        return this.contentFolder.childContents.length + this.contentFolder.childFolders.length;
    }

    private posterLoad(e: Event) {
        this.posterLoaded = true;
    }

    private _onClick(p_e: Event) {
        this.$emit("onClick", this.contentFolder);
    }

    private _onClickDotsBtn(p_e: Event) {
        p_e.stopPropagation();
        this.selState.id = `folder-${this.folderID}`;
        this.$root.$emit('bv::show::popover', `folder-${this.folderID}`);
        this.$emit("onClickDots", this.contentFolder);
    }

    // DRAGGING

    private _onDragStart() {
        contentFolderController.startDrag(this.contentFolder);
    }

    private _onDragEnd() {
        contentFolderController.endDrag();
    }

    private _onDragOver(p_event: DragEvent) {
        let isDroppable: boolean = true;
        //check if it is a content or a folder that is being dragged, because you can only drop another folder here if this folder is editable
        // (and if the dragged folder is editable, but the targetfolder draggable is only true when it is editable itself)
        let currentDragged= ContentFolderListModel.getInstance().currentDragged;
        if (currentDragged instanceof ContentFolderModel) {
            isDroppable = this.contentFolder.isEditable;
        }else if (currentDragged instanceof ContentModel){
            const isEditable: boolean = currentDragged!.rightActions.indexOf(RightAction.EDIT) >= 0;
            isDroppable =  isEditable;
        }
        if (isDroppable) {
            p_event.preventDefault();
            if (p_event.dataTransfer) {
                p_event.dataTransfer.dropEffect = "move";
            }
        }
    }

    private _onDragEnter(p_event: DragEvent) {
        this.dropFinished = false;
        this.dragCount++;
        this.isBeingDraggedOver = true;
    }

    private _onDragLeave(p_event: DragEvent) {
        this.dragCount--;
        if (this.dragCount == 0) {
            this.isBeingDraggedOver = false;
        }
    }

    private _onDrop() {
        let currentDragged = ContentFolderListModel.getInstance().currentDragged;
        if (currentDragged instanceof ContentModel) {
            contentController.moveContentToFolder(currentDragged, this.contentFolder);

        } else if (currentDragged instanceof ContentFolderModel) {
            if (currentDragged !== this.contentFolder) {
                contentFolderController.moveFolderToFolder(currentDragged, this.contentFolder)
            }
        }
        contentFolderController.endDrag();

        // reset drop count hack
        this.dragCount = 0;
        this.isBeingDraggedOver = false;


        // do animation
        this.dropFinished = true;
    }
}
</script>
