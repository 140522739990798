<template>
  <div class="root-page init-page">

    <AAImage class="init-page__background" :imageUrl="splash.bgUrl"></AAImage>

    <AAImage class="init-page__logo" :imageUrl="splash.logoUrl"></AAImage>

    <div class="box init-page__box">
      <h3 v-html="$t('InitBoxTitle')"></h3>

      <transition name="fade">
        <div v-if="currentType === initPageType.INIT && currentState === initPageState.NOT_PWA" class="box-wrapper">
          <p v-html="$t('InitBoxText')" class="init-text"></p>

          <div class="buttons">
            <b-button variant="secondary" :disabled="pwaInstalled || !pwaInstallAvailable" @click="installPWA"><i
                class="fa-solid fa-download mr-2"></i><span v-html="$t('InitBoxButtonInstallApplication')"></span>
            </b-button>
            <span v-html="$t('InitBoxButtonOr')"></span>
            <b-button variant="secondary" @click="startLogin"><i class="fa-solid fa-window-maximize mr-2"></i><span
                v-html="$t('InitBoxButtonContinueBrowser')"></span></b-button>
          </div>

          <div class="info" v-if="pwaInstallAvailable && !pwaInstalled">
            <i class="bulb fa-regular fa-lightbulb"></i>
            <p> {{ $t('InitInstallText') }}</p>
          </div>

          <div class="info" v-if="pwaInstalled">
            <i class="bulb fa-regular fa-lightbulb"></i>
            <p> {{ $t('InitAlreadyInstalled') }}</p>
          </div>

          <div class="info info--error"
               v-if="!pwaInstallAvailable && ($browserDetect.isSafari || $browserDetect.isFirefox || $browserDetect.isOpera || $browserDetect.isIE)">
            <i class="bulb fa-regular fa-lightbulb"></i>
            <p v-html="$t('InitInstallNotChrome')"></p>
          </div>

          <div class="install-ios" v-if="isIpad">
            <h6 v-html="$t('InitBoxInstallIosTitle')"></h6>
            <div class="install-ios__item mb-2">
              <i class="fa-solid fa-arrow-up-from-bracket"></i>
              <p>{{ $t('InitBoxInstallIos1') }}</p>
            </div>
            <div class="install-ios__item mb-2">
              <i class="fa-regular fa-square-plus"></i>
              <p>{{ $t('InitBoxInstallIos2') }}</p>
            </div>
            <div class="install-ios__item">
              <i class="fa-solid fa-table-cells-large"></i>
              <p>{{ $t('InitBoxInstallIos3') }}</p>
            </div>
          </div>
        </div>
      </transition>

      <transition name="fade">
        <div v-if="currentState === initPageState.WORKING" class="box-wrapper">
          <b-spinner v-if="currentState === initPageState.WORKING" class="mb-3"></b-spinner>
          <p v-if="workingMessage !== ''">{{ workingMessage }}</p>
        </div>
      </transition>

      <transition name="fade">
        <div v-if="currentState === initPageState.ERROR && errorMessage !== ''" class="box-wrapper">
          <p class="text-danger" v-html="errorMessage"></p>
        </div>
      </transition>
    </div>

  </div>
</template>


<script lang="ts">
import {Component, Vue} from "vue-property-decorator";
import pwaController from "@/_controller/PwaController";
import appController from "@/_controller/AppController";
import appUserController from "@/project/user/_controller/AppUserController";
import {RoutingIdentifier} from "@/router";
import contentListController from "@/content/_controller/ContentListController";
import audienceListController from "@/audience/_controller/AudienceListController";
import assetFolderListController from "@/asset_folder/_controller/AssetFolderListController";
import slideListController from "@/slide/_controller/SlideListController";
import companyListController from "@/company/_controller/CompanyListController";
import dataProviderListController from "@/data_tool/data_provider/_controller/DataProviderListController";
import AppModel from "@/_model/AppModel";
import syncController from "@/sync/_controller/SyncController";
import AppUserModel from "@/project/user/_model/AppUserModel";
import AAImage from "@/_view/components/AAImage.vue";
import Util from "@/__libs/utility/Util";
import NetworkManager from "@/_controller/NetworkManager";
import {IDeepLinkParamsDto, ISplashDto} from "@/_model/app.dto";
import queryString from "query-string";
import LocalStorageManager from "@/__libs/offline_storage/LocalStorageManager";
import {LocalStorageKey, ServiceWorkerEvents, ServiceWorkerState} from "@/_model/app.constants";
import JsonUtil from "@/__libs/utility/JsonUtil";
import serviceWorkerController from "@/_controller/ServiceWorkerController";
import ProjectModel from "@/project/_model/ProjectModel";
import {RIGHTS} from "@/team/_model/role.constants";
import {TierType} from "@/project/_model/project.constants";

enum InitPageState {
    IDLE,
    WORKING,
    ERROR,
    NOT_PWA,
}

enum InitPageType {
    INIT,
    LOGIN_CALLBACK,
    LOGOUT,
}

@Component({
    components: {AAImage}
})
export default class InitPage extends Vue {

    public initPageState: typeof InitPageState = InitPageState;
    public initPageType: typeof InitPageType = InitPageType;

    public currentState: InitPageState = InitPageState.IDLE;
    public currentType: InitPageType = InitPageType.INIT;

    public workingMessage: string = "";
    public errorMessage: string | undefined = "";

    public pwaInstallAvailable: boolean = false;
    private pwaInstallPrompt!: any;

    get splash(): ISplashDto {

        return AppModel.getInstance().splash;
    }

    get pwaInstalled(): boolean | null {
        return pwaController.isPWAInstalled();
    }

    get isIpad(): boolean {
        const isIpad: boolean = navigator.userAgent.toLowerCase().indexOf('ipad') !== -1;
        if (!isIpad && navigator.userAgent.match(/Mac/) && navigator.maxTouchPoints && navigator.maxTouchPoints > 2) {
            return true;
        }
        return isIpad;
    }

    public created() {
        window.addEventListener('beforeinstallprompt', this._onBeforePWAPrompt);
        window.addEventListener('appinstalled', this._onPWAInstalled);
    }

    public beforeDestroy() {
        window.removeEventListener('beforeinstallprompt', this._onBeforePWAPrompt);
        window.removeEventListener('appinstalled', this._onPWAInstalled);
    }

    public async mounted() {
        await this.startApp();
    }

    private async startApp() {
        // OK HERE WE START THE APP.

        this.currentState = InitPageState.WORKING;

        // service worker init
        console.log('STEP 1: init service worker');
        await serviceWorkerController.init();
        // disable road mode if active
        // serviceWorkerController.disableRoadMode();

        // start network manager (begins to poll to check online/offline) to check if we are online
        await NetworkManager.getInstance().init();

        // distribute flow
        switch (this.$router.currentRoute.name) {
            case RoutingIdentifier.INIT:
                this.currentType = InitPageType.INIT;
                await this._doInit();
                break;
            case RoutingIdentifier.LOGIN_CALLBACK:
                this.currentType = InitPageType.LOGIN_CALLBACK;
                await this._doLoginCallback();
                break;
            case RoutingIdentifier.LOGOUT:
                this.currentType = InitPageType.LOGOUT;
                await this._doLogout();
                break;
        }
    }

    private _onBeforePWAPrompt(e: any) {
        this.pwaInstallAvailable = true;
        this.pwaInstallPrompt = e;
    }

    private async _onPWAInstalled(e: any) {
        pwaController.onPWAInstalled();
        await this.$bvModal.msgBoxOk(this.$t('InitInstallComplete') as string, {centered: true});
        await this.startLogin();
    }

    private async _doInit() {

        // register service worker
        this.workingMessage = this.$t('InitServiceWorker') as string;
        console.log('STEP 2: register service worker');
        // const serviceWorkerSuccess: boolean = await serviceWorkerController.register();
        serviceWorkerController.register();
        if (!serviceWorkerController.serviceWorkerAvailable()) {
            this.currentState = InitPageState.ERROR;
            this.errorMessage = this.$t('InitErrorServiceWorker') as string;
            return;
        }

        // setup app environment
        this.workingMessage = this.$t('InitSettingUp') as string;
        await appController.setAppEnvironment();

        // check deeplink params
        this._checkDeepLinkParams();
        const urlParams: any = queryString.parse(window.location.search);
        if (urlParams.it) {
            appUserController.setImpersonationMode(urlParams.it, urlParams.user);
            await this._startOnlineFlow();
        } else {

            // check if we are online
            const isOnline: boolean = NetworkManager.getInstance().online;
            if (isOnline) {

                // check if enough info available to start online flow (domain check)
                const infoAvailable: boolean = await appController.fetchDomainInfo();
                if (!infoAvailable) {
                    this.errorMessage = this.$t('InitErrorInfo') as string;
                    this.currentState = InitPageState.ERROR;
                    return;
                }

                // check for update (if update found, wait 10 seconds, if no refresh its not for today the update...)
                this.workingMessage = this.$t('InitCheckForUpdates') as string;
                const updateAvailable: boolean = await appController.checkUpdateAvailable();
                if (!updateAvailable) {
                    await this._doInitFinish();
                } else {
                    await Util.delay(20000);
                    await this._doInitFinish();
                }

            } else {
                await this._startOfflineFlow();
            }

        }
    }

    private async _doInitFinish() {

        // check if pwa mode
        if (!pwaController.isPWAMode()) {
            this.currentState = InitPageState.NOT_PWA;
        } else {
            await this.startLogin();
        }

        //handy for when developing, just run it
        if (process.env.NODE_ENV === "development") {
            // this._startLogin();
        }

    }

    public installPWA() {
        // install PWA
        if (this.pwaInstallAvailable && this.pwaInstallPrompt) {
            this.pwaInstallPrompt.prompt();
        }
    }

    public async startLogin() {
        this.currentState = InitPageState.WORKING;
        this.workingMessage = this.$t('InitLoggingIn') as string;

        const loginSucceeded: boolean = await appUserController.loginUser();
        if (loginSucceeded) {
            await this._startOnlineFlow();
        } else {
            this.errorMessage = this.$t('InitErrorLogin') as string;
            this.currentState = InitPageState.ERROR;
            await Util.timeOut(10000);
            await appUserController.logoutUser();
            return;
        }
    }

    private async _startOnlineFlow() {
        this.workingMessage = this.$t('InitStartingOnline') as string;

        //start user session
        const sessionStarted: boolean = await appUserController.startRemoteAppUserSession();

        if (!sessionStarted) {
            this.errorMessage = this.splash.aaUserNotFoundMessage;
            this.currentState = InitPageState.ERROR;
            await Util.timeOut(14000);
            await appUserController.logoutUser();
            return;
        }

        // set routes for service worker
        serviceWorkerController.setRoutes();

        // set interval for checking for updates
        // appController.setUpdateCheckInterval();

        // check if first time in update
        await appController.checkFirstTimeInUpdate();

        // set interface language
        this.$i18n.locale = AppUserModel.getInstance().langCode;

        if (!appUserController.inImpersonationMode) {
            this._getMetaFromCache();
            await syncController.initialize();
        }

        // if first time in project Explore go to project welcome flow
        if ((AppUserModel.getInstance().project.tierConfig.tierType === TierType.EXPLORE
            || AppUserModel.getInstance().project.tierConfig.tierType === TierType.EXPLORE_PLUS
          ) &&
          AppUserModel.getInstance().rights.indexOf(RIGHTS.EDIT_PROJECT_SETTINGS.identifier) >= 0 &&
          !AppUserModel.getInstance().project.config.welcomeFinished) {
            await this.$router.push({name: RoutingIdentifier.WELCOME_START});
        } else {
            await this.$router.push({name: RoutingIdentifier.CONTENT_OVERVIEW});
        }
    }

    //prefetch all meta content
    private _getMetaFromCache() {
        audienceListController.getAllEntitiesMetaFromCache();
        contentListController.getAllEntitiesMetaFromCache();
        assetFolderListController.getAllEntitiesMetaFromCache();
        slideListController.getAllEntitiesMetaFromCache();
        dataProviderListController.getAllEntitiesMetaFromCache();
        companyListController.getAllEntitiesMetaFromCache();
    }

    private async _startOfflineFlow() {
        this.workingMessage = this.$t('InitStartingOffline') as string;
        const isOfflineAvailable: boolean = await appController.checkOfflineAvailability();
        if (!isOfflineAvailable) {
            {
                this.errorMessage = this.$t('InitErrorOffline') as string;
                this.currentState = InitPageState.ERROR;
                return;
            }
        }

        const sessionStarted: boolean = appUserController.setAppUserSession();
        if (!sessionStarted) {
            {
                this.errorMessage = this.$t('InitErrorUserSession') as string;
                this.currentState = InitPageState.ERROR;
                return;
            }
        }

        serviceWorkerController.setRoutes();

        // set interface language
        this.$i18n.locale = AppUserModel.getInstance().langCode;

        //prefetch all meta content
        this._getMetaFromCache();

        await syncController.initialize();

        await this.$router.push({name: RoutingIdentifier.CONTENT_OVERVIEW});

    }

    private async _doLoginCallback() {
        this.currentState = InitPageState.WORKING;
        this.workingMessage = this.$t('InitLoggingIn') as string;

        const code = decodeURIComponent(this.$route.params.code);

        // register service worker
        this.workingMessage = this.$t('InitServiceWorker') as string;
        // const serviceWorkerSuccess: boolean = await serviceWorkerController.register();
        // if (!serviceWorkerSuccess) {
        //     this.currentState = InitPageState.ERROR;
        //     this.errorMessage = this.$t('InitErrorServiceWorker') as string;
        //     return;
        // }
        // const serviceWorkerSuccess: boolean = await serviceWorkerController.register();
        serviceWorkerController.register();
        if (!serviceWorkerController.serviceWorkerAvailable()) {
            this.currentState = InitPageState.ERROR;
            this.errorMessage = this.$t('InitErrorServiceWorker') as string;
            return;
        }

        const setAppEnvironmentSuccess: boolean = await appController.setAppEnvironment();
        if (!setAppEnvironmentSuccess) {
            this.errorMessage = this.$t('InitErrorInfo') as string;
            this.currentState = InitPageState.ERROR;
            return;
        }

        const parseSucceeded: boolean = await appUserController.loginUserParseResponse(code);
        if (!parseSucceeded) {
            this.errorMessage = this.splash.aaUserNotFoundMessage;
            this.currentState = InitPageState.ERROR;
            await Util.timeOut(10000);
            await appUserController.logoutUser();
            return;
        }

        this._checkDeepLinkParams();
        await this._startOnlineFlow();
    }

    private async _doLogout() {
        this.workingMessage = this.$t('InitLoggingOut') as string;
        this.currentState = InitPageState.WORKING;
        await appUserController.logoutUser();
    }

    private _checkDeepLinkParams() {
        const urlParams: any = queryString.parse(window.location.search);
        if (urlParams.contentid || urlParams.route || urlParams.folderid) {
            const deepLinkParams: IDeepLinkParamsDto = {
                route: urlParams.route,
                routeParams: urlParams.routeparams,
                contentID: urlParams.contentid,
                audienceID: urlParams.audienceid,
                engineMode: urlParams.enginemode,
                folderID: parseInt(urlParams.folderid),
                timeStamp: new Date()
            };
            LocalStorageManager.storeValue(LocalStorageKey.DEEPLINK_PARAMS, JsonUtil.stringify(deepLinkParams));
            window.location.href = window.location.origin;
        } else {
            const deepLinkParams: IDeepLinkParamsDto | null = JsonUtil.parse(LocalStorageManager.retrieveValue(LocalStorageKey.DEEPLINK_PARAMS) as string);
            LocalStorageManager.deleteValue(LocalStorageKey.DEEPLINK_PARAMS);
            if (deepLinkParams && deepLinkParams.timeStamp && (deepLinkParams.contentID || deepLinkParams.route || deepLinkParams.folderID)) {
                const deepLinkTimeOut: number = new Date().getTime() - new Date(deepLinkParams.timeStamp).getTime();
                if (deepLinkTimeOut < (60 * 1000)) //if it is under 1 minute
                {
                    AppModel.getInstance().deepLinkParams = deepLinkParams;
                }
            }
        }
    }

}
</script>
