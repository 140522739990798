<template>
  <div class="aa-profile-picture" :class="{ 'aa-profile-picture--editable': editable, 'aa-profile-picture--big': big, 'aa-profile-picture--huge': huge }">
    <div class="aa-responsive aa-responsive--square">
      <div class="aa-responsive__wrapper">
        <AAImage v-if="showImage" :imageUrl="imageUrl" class="rounded-circle"
                 @image-error="imageErrorHandler"></AAImage>
        <transition name="fade">
          <avatar v-if="name && !showImage" :username="name"></avatar>
        </transition>
      </div>
    </div>
    <div class="aa-profile-picture__change" v-if="editable">
      <i class="fas fa-camera"></i>
    </div>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from "vue-property-decorator";
import Avatar from "vue-avatar";
import AAImage from "@/_view/components/AAImage.vue";
import fileManager from "@/_controller/FileManager";
import AppUserModel from "@/project/user/_model/AppUserModel";

@Component({
  components: {
    AAImage, Avatar
  }
})
export default class AAProfilePicture extends Vue {

  @Prop() public imageUri!: string;
  @Prop() public name!: string;
  @Prop() public editable!: boolean;
  @Prop() public big!: boolean;
  @Prop() public huge!: boolean;

  private imageError: boolean = false;

  get showImage() {
    return this.imageUri && this.imageUri !== '' && !this.imageUri.includes('_avatar_default.png') && !this.imageError;
  }

  get imageUrl() {
    return fileManager.getFileUrl(this.imageUri);
  }

  private imageErrorHandler(e: Event) {
    this.imageError = true;
  }

}
</script>
