import AnalyticsModel from "@/analytics/_model/AnalyticsModel";
import apiManager, {ApiRequestScope, ApiRequestType, ApiResponse} from "@/_controller/ApiManager";
import {
    IOldTeamAnalyticsDto,
    IAnalyticsWidgetDataDto,
    ITeamAnalyticsDto,
    IAnalyticsUsageDto,
    IContentTypeAnalyticsDto,
    IUserAnalyticsDto,
    ITeamUserLicencesDto,
    IProjectUserLicencesDto
} from "@/analytics/_model/analytics.dto";
import TeamModel from "@/team/_model/TeamModel";
import Util from "@/__libs/utility/Util";
import i18n from "@/__plugins/i18n";
import TeamUserModel from "@/team/_model/TeamUserModel";
import {Column, Workbook} from "exceljs";
import {IXlsSheet} from "@/data_tool/_model/data_tool.dto";
import languageManager from "@/__libs/language_manager/LanguageManager";
import AppUserModel from "@/project/user/_model/AppUserModel";
import FileSaver from "file-saver";

class AnalyticsController
{

    //---------------------------------
    // Properties
    //---------------------------------



    private analyticsModel:AnalyticsModel = AnalyticsModel.getInstance();


    //---------------------------------
    // Controller Methods
    //---------------------------------

    public async fetchAnalytics(analytics:AnalyticsModel):Promise<boolean>
    {
        const response:ApiResponse<IOldTeamAnalyticsDto> = await apiManager.sendApiRequest(ApiRequestType.GET, `/client-api/analytics/project`, {
            start: analytics.range[0], end: analytics.range[1]
        });

        if (response.hasSucceeded)
        {
            analytics.mapFromDto(response.result as IOldTeamAnalyticsDto);
        }

        return response.hasSucceeded;

    }

    public async fetchProjectAnalyticsForExport(analytics:AnalyticsModel):Promise<boolean>
    {
        const response:ApiResponse<IOldTeamAnalyticsDto> = await apiManager.sendApiRequest(ApiRequestType.GET, `/client-api/analytics/project/export`, {
            start: analytics.range[0], end: analytics.range[1]
        });

        const columnHeaders:string[] = ["id", "displayName", "email", "team", "market", "presented", "shared", "viewed", "loggedIn", "lastLogin"];

        if (response.hasSucceeded)
        {
            const workbook = new Workbook();

            const newSheet = workbook.addWorksheet("project usage");
            const columns:Partial<Column>[] = [];
            for (let j = 0; j < columnHeaders.length; j++)
            {
                const dtColumn = columnHeaders[j];
                const xlsColumn:Partial<Column> = {
                    header: dtColumn,
                    key   : dtColumn,
                    width : 12,
                };
                columns.push(xlsColumn);
            }
            newSheet.columns = columns;
            newSheet.addRows(response.result as any);

            //styling header
            newSheet.getRow(1).font = {bold: true};
            newSheet.getRow(1).border = {bottom: {style: "double"}};
            newSheet.getRow(1).fill = {type: 'pattern', pattern: 'solid', fgColor: {argb: 'FFD9D9D9'}};

            const buffer = await workbook.xlsx.writeBuffer();
            const blob = new Blob([buffer], {type: "applicationi/xlsx"});

            FileSaver.saveAs(blob, `project_usage.xlsx`);
        }

        return response.hasSucceeded;

    }
    public async fetchProjectLicencesForExport(analytics:AnalyticsModel):Promise<boolean>
    {
        const response:ApiResponse<IProjectUserLicencesDto> = await apiManager.sendApiRequest(ApiRequestType.GET, `/client-api/analytics/project/user-licences`, {
            start: analytics.range[0], end: analytics.range[1]
        });


        if (response.hasSucceeded)
        {
            const projectUserLicences:IProjectUserLicencesDto = response.result as IProjectUserLicencesDto
            const overviewColumnHeaders:string[] = ["teamName", ...projectUserLicences.months, "teamTotal"];
            const userColumnHeaders:string[] = ["displayName","email","teamName","teamLicenseType","paidBy","countryIdentifier","createdDate","licenceStartDate","activeFromDate","isDeleted","deletedDate","retentionEndDate","licenseEndDate","userID"];


            const workbook = new Workbook();

            const overviewSheet = workbook.addWorksheet("teams overview");
            const columns:Partial<Column>[] = [];
            for (let j = 0; j < overviewColumnHeaders.length; j++)
            {
                const dtColumn = overviewColumnHeaders[j];
                const xlsColumn:Partial<Column> = {
                    header: dtColumn,
                    key   : dtColumn,
                    width : 18,
                };
                columns.push(xlsColumn);
            }
            overviewSheet.columns = columns;
            overviewSheet.addRows(projectUserLicences.teams as any);

            //styling header
            overviewSheet.getRow(1).font = {bold: true};
            overviewSheet.getRow(1).border = {bottom: {style: "double"}};
            overviewSheet.getRow(1).fill = {type: 'pattern', pattern: 'solid', fgColor: {argb: 'FFD9D9D9'}};

            //user details
            const userSheet = workbook.addWorksheet("users");

            const userColumns:Partial<Column>[] = [];
            for (let j = 0; j < userColumnHeaders.length; j++)
            {
                const dtColumn = userColumnHeaders[j];
                const xlsColumn:Partial<Column> = {
                    header: dtColumn,
                    key   : dtColumn,
                    width : 22,
                };
                userColumns.push(xlsColumn);
            }
            userSheet.columns = userColumns;
            for (let i = 0; i < projectUserLicences.teams.length; i++)
            {
                const team = projectUserLicences.teams[i];
                userSheet.addRows(team.users as any);
            }
            //styling header
            userSheet.getRow(1).font = {bold: true};
            userSheet.getRow(1).border = {bottom: {style: "double"}};
            userSheet.getRow(1).fill = {type: 'pattern', pattern: 'solid', fgColor: {argb: 'FFD9D9D9'}}


            const buffer = await workbook.xlsx.writeBuffer();
            const blob = new Blob([buffer], {type: "application/xlsx"});

            FileSaver.saveAs(blob, `project_licenses.xlsx`);
        }

        return response.hasSucceeded;

    }

    public async getContentWidgetData(p_widgetIdentifier:string):Promise<IAnalyticsWidgetDataDto | null>
    {
        const response:ApiResponse<IAnalyticsWidgetDataDto> = await apiManager.sendApiRequest(ApiRequestType.GET, `/client-api/analytics/content/widget`, {
            identifier: p_widgetIdentifier, start: this.analyticsModel.range[0], end: this.analyticsModel.range[1]
        });

        if (response.hasSucceeded)
        {
            return response.result as IAnalyticsWidgetDataDto;
        }

        return null;
    }

    public async getContentTypeData(p_typeIdentifier:string):Promise<IContentTypeAnalyticsDto | null>
    {
        const response:ApiResponse<IContentTypeAnalyticsDto> = await apiManager.sendApiRequest(ApiRequestType.GET, `/client-api/analytics/content/${p_typeIdentifier.toLowerCase()}`, {
            target: this.analyticsModel.activeTargetType, start: this.analyticsModel.range[0],
            end   : this.analyticsModel.range[1]
        });

        if (response.hasSucceeded)
        {
            return response.result as IContentTypeAnalyticsDto;
        }

        return null;
    }



    //team
    public async getAllTeamsAnalytics():Promise<boolean>
    {
        const response:ApiResponse<ITeamAnalyticsDto> = await apiManager.sendApiRequest(ApiRequestType.GET, `/client-api/analytics/team`, {
            start: this.analyticsModel.range[0], end: this.analyticsModel.range[1]
        });

        if (response.hasSucceeded)
        {
            this.analyticsModel.allTeamsAnalytics = response.result as ITeamAnalyticsDto;

            //build the teamlisting
            this.analyticsModel.teamListing.empty();
            for (let i = 0; i < this.analyticsModel.allTeamsAnalytics.teams.length; i++)
            {
                const teamUsage = this.analyticsModel.allTeamsAnalytics.teams[i];
                const team:TeamModel = new TeamModel();
                team.teamID = teamUsage.id;
                team.displayName = teamUsage.displayName;
                team.avatarFileUri = teamUsage.avatarFileUri;
                this.analyticsModel.teamListing.list.push(team);
            }
            this.analyticsModel.activeTeamAnalytics = this.analyticsModel.allTeamsAnalytics;

        }

        return response.hasSucceeded;

    }

    public async getSingleTeamAnalytics(p_teamID:number):Promise<boolean>
    {
        const response:ApiResponse<ITeamAnalyticsDto> = await apiManager.sendApiRequest(ApiRequestType.GET, `/client-api/analytics/team/${p_teamID}`, {
            start: this.analyticsModel.range[0], end: this.analyticsModel.range[1]
        });

        if (response.hasSucceeded)
        {
            const singleTeam:IAnalyticsUsageDto = this.analyticsModel.allTeamsAnalytics.teams.find((team:IAnalyticsUsageDto) => team.id === p_teamID) as IAnalyticsUsageDto;

            const allTeamsBenchMark:IAnalyticsUsageDto = {} as IAnalyticsUsageDto;
            allTeamsBenchMark.displayName = i18n.tc("AllTeamBenchmark");
            allTeamsBenchMark.loggedIn = 0;
            allTeamsBenchMark.presented = 0;
            allTeamsBenchMark.shared = 0;
            allTeamsBenchMark.total = 0;

            // build the teams comparison array by comparing the single team with the other teams in this.analyticsModel.allTeamsAnalytics.teams
            const teams:IAnalyticsUsageDto[] = [];
            const nrOfTeams:number = this.analyticsModel.allTeamsAnalytics.teams.length
            for (let i = 0; i < nrOfTeams; i++)
            {
                const refTeam:IAnalyticsUsageDto = this.analyticsModel.allTeamsAnalytics.teams[i];
                if (refTeam.id !== p_teamID) //don't compare to yourself
                {
                    teams.push(this._compareUsage(refTeam, singleTeam));

                    //all teams benchmark totals:
                    allTeamsBenchMark.loggedIn += refTeam.loggedIn;
                    allTeamsBenchMark.presented += refTeam.presented;
                    allTeamsBenchMark.shared += refTeam.shared;
                    allTeamsBenchMark.total += refTeam.total;
                }
            }

            //all teams benchmark average per team:
            allTeamsBenchMark.loggedIn = Math.round(allTeamsBenchMark.loggedIn / nrOfTeams);
            allTeamsBenchMark.presented = Math.round(allTeamsBenchMark.presented / nrOfTeams);
            allTeamsBenchMark.shared = Math.round(allTeamsBenchMark.shared / nrOfTeams);
            allTeamsBenchMark.total = Math.round(allTeamsBenchMark.total / nrOfTeams);

            teams.push(this._compareUsage(allTeamsBenchMark, singleTeam));

            this.analyticsModel.activeTeamAnalytics = response.result as ITeamAnalyticsDto;
            this.analyticsModel.activeTeamAnalytics.teams = teams;

        }

        return response.hasSucceeded;

    }



    //user
    public async getAllUsersAnalytics():Promise<boolean>
    {
        const response:ApiResponse<IUserAnalyticsDto> = await apiManager.sendApiRequest(ApiRequestType.GET, `/client-api/analytics/user`, {
            start: this.analyticsModel.range[0], end: this.analyticsModel.range[1]
        });

        if (response.hasSucceeded)
        {
            this.analyticsModel.allUsersAnalytics = response.result as IUserAnalyticsDto;

            //build the userlisting
            this.analyticsModel.userListing.empty();
            for (let i = 0; i < this.analyticsModel.allUsersAnalytics.users.length; i++)
            {
                const userUsage = this.analyticsModel.allUsersAnalytics.users[i];
                const user:TeamUserModel = new TeamUserModel();
                user.userID = userUsage.id;
                user.displayName = userUsage.displayName;
                user.avatarFileUri = userUsage.avatarFileUri;
                this.analyticsModel.userListing.list.push(user);
            }
            this.analyticsModel.activeUserAnalytics = this.analyticsModel.allUsersAnalytics;

        }

        return response.hasSucceeded;

    }

    public async getSingleUserAnalytics(p_userID:number):Promise<boolean>
    {
        const response:ApiResponse<IUserAnalyticsDto> = await apiManager.sendApiRequest(ApiRequestType.GET, `/client-api/analytics/user/${p_userID}`, {
            start: this.analyticsModel.range[0], end: this.analyticsModel.range[1]
        });

        if (response.hasSucceeded)
        {
            const singleUser:IAnalyticsUsageDto = this.analyticsModel.allUsersAnalytics.users.find((user:IAnalyticsUsageDto) => user.id === p_userID) as IAnalyticsUsageDto;

            const allUsersBenchMark:IAnalyticsUsageDto = {} as IAnalyticsUsageDto;
            allUsersBenchMark.displayName = i18n.tc("AllUserBenchmark");
            allUsersBenchMark.loggedIn = 0;
            allUsersBenchMark.presented = 0;
            allUsersBenchMark.shared = 0;
            allUsersBenchMark.total = 0;

            // build the users comparison array by comparing the single user with the other users in this.analyticsModel.allUsersAnalytics.users
            const users:IAnalyticsUsageDto[] = [];
            const nrOfUsers:number = this.analyticsModel.allUsersAnalytics.users.length
            for (let i = 0; i < nrOfUsers; i++)
            {
                const refUser:IAnalyticsUsageDto = this.analyticsModel.allUsersAnalytics.users[i];
                if (refUser.id !== p_userID) //don't compare to yourself
                {
                    users.push(this._compareUsage(refUser, singleUser));

                    //all users benchmark totals:
                    allUsersBenchMark.loggedIn += refUser.loggedIn;
                    allUsersBenchMark.presented += refUser.presented;
                    allUsersBenchMark.shared += refUser.shared;
                    allUsersBenchMark.total += refUser.total;
                }
            }

            //all users benchmark average per user:
            allUsersBenchMark.loggedIn = Math.round(allUsersBenchMark.loggedIn / nrOfUsers);
            allUsersBenchMark.presented = Math.round(allUsersBenchMark.presented / nrOfUsers);
            allUsersBenchMark.shared = Math.round(allUsersBenchMark.shared / nrOfUsers);
            allUsersBenchMark.total = Math.round(allUsersBenchMark.total / nrOfUsers);


            const comparedUsage:IAnalyticsUsageDto = this._compareUsage(allUsersBenchMark, singleUser);
            users.push(comparedUsage);

            this.analyticsModel.activeUserAnalytics = response.result as IUserAnalyticsDto;
            this.analyticsModel.activeUserAnalytics.users = users;

            this.analyticsModel.activeUserAnalytics.usageVsAllUsers = comparedUsage.percentage;

            const differenceInWeeks = (this.analyticsModel.range[1].getTime() - this.analyticsModel.range[0].getTime()) / (1000 * 3600 * 24 * 7);
            this.analyticsModel.activeUserAnalytics.avgLoginsPerWeek = singleUser.loggedIn / differenceInWeeks;
            this.analyticsModel.activeUserAnalytics.avgPresentationsPerWeek = singleUser.presented / differenceInWeeks;

            this.analyticsModel.activeUserAnalytics.loggedIn = singleUser.loggedIn;
            this.analyticsModel.activeUserAnalytics.presented = singleUser.presented;
            this.analyticsModel.activeUserAnalytics.shared = singleUser.shared;
            this.analyticsModel.activeUserAnalytics.viewed = singleUser.viewed;
            this.analyticsModel.activeUserAnalytics.total = singleUser.total;

        }

        return response.hasSucceeded;

    }



    //---------------------------------
    // Private Methods
    //---------------------------------

    private _compareUsage(p_sourceUsage:IAnalyticsUsageDto, p_targetUsage:IAnalyticsUsageDto):IAnalyticsUsageDto
    {
        const comparedTeam:IAnalyticsUsageDto = {} as IAnalyticsUsageDto;
        comparedTeam.displayName = p_sourceUsage.displayName;
        comparedTeam.loggedIn = p_targetUsage.loggedIn - p_sourceUsage.loggedIn;
        comparedTeam.presented = p_targetUsage.presented - p_sourceUsage.presented;
        comparedTeam.shared = p_targetUsage.shared - p_sourceUsage.shared;
        comparedTeam.total = p_targetUsage.total - p_sourceUsage.total;
        comparedTeam.percentage = Util.calculatePercentage(p_sourceUsage.total, comparedTeam.total);
        return comparedTeam;
    }
}

//Singleton export
export default new AnalyticsController();
