class FileUtil
{
    //---------------------------------
    // Public Static Methods
    //---------------------------------


    public static getFileExtension(p_fileName:string):string
    {
        const dotIndex = p_fileName.lastIndexOf(".");
        return p_fileName.substr(dotIndex);
    }


    public static getFriendlyFileName(p_fileName:string):string
    {
        const dotIndex = p_fileName.lastIndexOf(".");
        if (dotIndex < 0)
        {
            return p_fileName;
        }
        let fileName:string = p_fileName.substring(0, dotIndex);
        fileName = fileName.split("_").join(" ");
        fileName = fileName.split("-").join(" ");
        return fileName;
    }

    public static async readFileAsync(file:File)
    {
        return new Promise((resolve, reject) => {
            let reader = new FileReader();

            reader.onload = () => {
                resolve(reader.result);
            };

            reader.onerror = reject;

            reader.readAsArrayBuffer(file);
        })
    }

    public static async resizeImage(file:File, maxWidth:number, maxHeight:number):Promise<Blob>
    {
        return new Promise((resolve, reject) => {
            let image = new Image();
            image.src = URL.createObjectURL(file);
            image.onload = () => {
                let width = image.width;
                let height = image.height;

                if (width <= maxWidth && height <= maxHeight)
                {
                    resolve(file);
                }

                let newWidth;
                let newHeight;

                if (width > height)
                {
                    newHeight = height * (maxWidth / width);
                    newWidth = maxWidth;
                }
                else
                {
                    newWidth = width * (maxHeight / height);
                    newHeight = maxHeight;
                }

                let canvas = document.createElement('canvas');
                canvas.width = newWidth;
                canvas.height = newHeight;

                let context = canvas.getContext('2d');

                context!.drawImage(image, 0, 0, newWidth, newHeight);

                canvas.toBlob(resolve as any, file.type);
            };
            image.onerror = reject;
        });
    }

}

export default FileUtil;
