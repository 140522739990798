<template>
  <div class="chat-box-item" :class="{self: isSelf}">
    <div class="chat-box-item__message">{{ message.message }}</div>
    <div class="chat-box-item__time">{{ timeStamp }}</div>
    <AAProfilePicture :name="userName" :imageUri="userAvatarUri" v-if="user"></AAProfilePicture>
<!--    <div>{{ userName }}</div>-->
<!--    {{ message.message }}-->
<!--    <div class="timeStamp"> {{ timeStamp }}</div>-->
  </div>

</template>

<script lang="ts">
import {Component, Prop, Vue} from "vue-property-decorator";

import Util from "@/__libs/utility/Util";
import {IChatMessageDto} from "@sales-drive/audience-library/lib/cobrowse_manager/chat/_model/chat.dto";
import {
    ICobrowseRoomUserDto
} from "@sales-drive/audience-library/lib/cobrowse_manager/cobrowse_user/_model/cobrowse_user.dto";
import CobrowseManager from "@sales-drive/audience-library/lib/cobrowse_manager/CobrowseManager";
import AAProfilePicture from "@/_view/components/AAProfilePicture.vue";

@Component({
    components: {AAProfilePicture}
})
export default class ChatBoxItemRenderer extends Vue {

    @Prop() public user!: ICobrowseRoomUserDto;
    @Prop() public message!: IChatMessageDto;


    get isSelf(): boolean {
        return this.user && CobrowseManager.getInstance().cobrowseUser.cobrowseUserID === this.user.cobrowseUserID;
    }

    get userName(): string {
        if (this.user) {
            return this.user.displayName;
        }
        return "unknown user";
    }

    get userAvatarUri(): string | undefined {
        if (this.user) {
            return this.user.avatarUri;
        }
        return undefined;
    }

    get timeStamp() {
        const timeStamp = new Date(this.message.timeStamp);
        return `${Util.setLeadingZero(timeStamp.getHours())}:${Util.setLeadingZero(timeStamp.getMinutes())}`;
    }
}

</script>
