import i18n from '@/__plugins/i18n';
import TeamUserModel from "@/team/_model/TeamUserModel";
import apiManager, {ApiRequestType, ApiResponse} from "@/_controller/ApiManager";
import {ICreateResultDto, IDeleteResultDto, IUpdateResultDto} from "@/entity/_model/entity.dto";
import toastManager, {ToastType} from "@/__libs/toast_manager/ToastManager";
import {LoadingStatus} from "@/entity/_model/entity.constants";
import TeamUserListModel from "@/team/_model/TeamUserListModel";
import {
    ITeamUserBodyDto,
    ITeamUserDto,
    IUserDto,
    IUserOutlineDto,
    IUserTransferTypeResultDto
} from "@/team/_model/team.dto";
import {DtoType} from "@/_model/app.constants";
import {UserTransferType} from "@/team/_model/team.constants";
import AppUserModel from "@/project/user/_model/AppUserModel";

class TeamUserController
{

    //---------------------------------
    // Properties
    //---------------------------------

    protected _listModel:TeamUserListModel = TeamUserListModel.getInstance();


    //---------------------------------
    // Controller Methods
    //---------------------------------

    public async fetchAllTeamUsers()
    {
        //first check if you are not fetching already and if enough time has passed between fetches
        if (!this._listModel.isFetchListAllowed)
        {
            return;
        }
        //all good, start fetch
        this._listModel.startListFetch();

        //get new meta info from server
        const response:ApiResponse<IUserOutlineDto[]> = await apiManager.sendApiRequest(ApiRequestType.GET, `/client-api/users/`);

        if (response.hasSucceeded)
        {
            // this._listModel.list = [];

            const userOutlines:IUserOutlineDto[] = response.result as IUserOutlineDto[];
            for (let i = 0; i < userOutlines.length; i++)
            {
                const teamUser:TeamUserModel = new TeamUserModel();
                teamUser.mapFromDto(userOutlines[i]);
                this._listModel.list.push(teamUser);
            }
        }
        this._listModel.stopListFetch();
    }

    public async fetchTeamUserBody(p_teamUser:TeamUserModel)
    {
        p_teamUser.loadingStatus = LoadingStatus.LOADING_BODY;

        //get new meta info from server
        const response:ApiResponse<ITeamUserDto[]> = await apiManager.sendApiRequest(ApiRequestType.GET, `/client-api/users/${p_teamUser.userID}`);

        if (response.hasSucceeded)
        {
            p_teamUser.mapFromDto(response.result!);
            p_teamUser.loadingStatus = LoadingStatus.BODY_LOADED;
        }
        else
        {
            p_teamUser.loadingStatus = LoadingStatus.BODY_ERROR;
        }
        this._listModel.stopListFetch();
    }

    public async getImpersonationToken(p_teamUser:TeamUserModel):Promise<string | null>
    {

        const response:ApiResponse<any> = await apiManager.sendApiRequest(ApiRequestType.GET, `/client-api/users/${p_teamUser.userID}/impersonation`);

        if (response.hasSucceeded)
        {
            return response.result.token;
        }
        else
        {
            toastManager.showToast(i18n.t('TeamUserImpersonationFailed') as string, ToastType.DANGER);
        }

        return null;
    }

    public async createTeamUser(p_teamUser:TeamUserModel):Promise<ApiResponse<ICreateResultDto>>
    {
        const teamUserBody:ITeamUserBodyDto = p_teamUser.mapToDto(DtoType.BODY) as ITeamUserBodyDto;

        const response:ApiResponse<ICreateResultDto> = await apiManager.sendApiRequest(ApiRequestType.POST, `/client-api/users/`, teamUserBody);

        if (response.hasSucceeded)
        {
            p_teamUser.userID = (response.result as any).ID;
            toastManager.showToast(i18n.t('TeamUserCreationSuccess') as string, ToastType.SUCCESS);
            this._listModel.list.push(p_teamUser);
            AppUserModel.getInstance().project.tierQuota.nrOfUsers++;

        }
        return response;
    }


    public async saveTeamUser(p_teamUser:TeamUserModel):Promise<ApiResponse<IUpdateResultDto>>
    {
        const teamUserBody:ITeamUserBodyDto = p_teamUser.mapToDto(DtoType.BODY) as ITeamUserBodyDto;

        const response:ApiResponse<ICreateResultDto> = await apiManager.sendApiRequest(ApiRequestType.PUT, `/client-api/users/${p_teamUser.userID}`, teamUserBody);

        if (response.hasSucceeded)
        {
            toastManager.showToast(i18n.t('TeamUserSaveSuccess') as string, ToastType.SUCCESS);
            p_teamUser.hasChanges = false;
        }
        else
        {
            toastManager.showToast(i18n.t('TeamUserSaveFailed') as string, ToastType.DANGER);
        }
        return response;
    }


    public async deleteUser(p_teamUser:TeamUserModel):Promise<boolean>
    {

        const response:ApiResponse<IDeleteResultDto> = await apiManager.sendApiRequest(ApiRequestType.DELETE, `/client-api/users/${p_teamUser.userID}`);

        if (response.hasSucceeded)
        {
            this._listModel.removeItem(p_teamUser);

            toastManager.showToast(i18n.t('TeamUserDeleteSuccess') as string, ToastType.SUCCESS);
        }
        else
        {
            toastManager.showToast(i18n.t('TeamUserDeleteFailed') as string, ToastType.DANGER);
        }
        return response.hasSucceeded;
    }

    public async transferUser(p_sourceUserID:number, p_targetUserID:number, p_transferTypes:UserTransferType[]):Promise<IUserTransferTypeResultDto[] | null>
    {
        const response:ApiResponse<ICreateResultDto> = await apiManager.sendApiRequest(ApiRequestType.PUT, `/client-api/users/${p_sourceUserID}/transfer`,
            {
                targetUserID: p_targetUserID, transferTypes: p_transferTypes
            });

        if (response.hasSucceeded)
        {
            return response.result as any;
        }
        else
        {
            return null;
        }

    }

    //---------------------------------
    // Private Methods
    //---------------------------------

}

//Singleton export
export default new TeamUserController();
