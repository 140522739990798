import HintModel from "@/help/hint/_model/HintModel";

class HintListModel {

    public list: HintModel[] = [];

    private static _instance: HintListModel;

    constructor() {
        if (!HintListModel._instance) {
            HintListModel._instance = this;
        }
    }

    public static getInstance(): HintListModel {
        if (!HintListModel._instance) {
            new HintListModel();
        }
        return HintListModel._instance;
    }

    public getFirstHintByGroupId(groupId: string): HintModel | null {
        for (const hint of this.list) {
            if (hint.groupId === groupId && !hint.shown) {
                return hint;
            }
        }
        return null;
    }

    public getHintById(id: string): HintModel | null {
        for (const hint of this.list) {
            if (hint.id === id) {
                return hint;
            }
        }
        return null;
    }

    public getNextHintById(id: string): HintModel | null {
        for (const [i, hint] of this.list.entries()) {
            if (hint.id === id && i < this.list.length - 1 && this.list[i + 1].groupId === hint.groupId) {
                return this.list[i + 1];
            }
        }
        return null;
    }

    public skipAll() {
        for (const hint of this.list) {
            hint.shown = true;
        }
    }

    public getModelForLocalStorage(): any {
        const model: any = {};
        for (const hint of this.list) {
            model[hint.id] = hint.shown;
        }
        return model;
    }

}

export default HintListModel;
