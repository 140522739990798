import EntitySelectionState from "@/entity/_model/EntitySelectionState";
import ContentModel from "@/content/_model/ContentModel";

export default class ContentOverviewSelectionState extends EntitySelectionState<ContentModel>
{
    protected _selected:ContentModel | null = null;
    get selected():ContentModel | null
    {
        return this._selected;
    }

    set selected(value:ContentModel | null)
    {
        this._previousSelected = this._selected;
        this._selected = value;
    }

    public id: string = "";

    //---------------------------------
    // Public Methods
    //---------------------------------

}
