import SyncDataProcess from "@/sync/_controller/process/SyncDataProcess";
import syncController from "@/sync/_controller/SyncController";
import SyncModuleProcess from "@/sync/_controller/process/SyncModuleProcess";
import {SyncProcessType} from "@/sync/_model/sync.constants";
import SyncPresentationProcess from "@/sync/_controller/process/SyncPresentationProcess";
import {ISyncItemFileDto} from "@/sync/_model/sync.dto";
import {IPresentationBodyDto} from "@/presentation/_model/presentation.dto";
import {ISlideBodyDto} from "@/slide/_model/slide.dto";

class SyncSlideProcess extends SyncPresentationProcess
{

    //---------------------------------
    // Properties
    //---------------------------------

    protected _storagePath:string = "slides";
    protected _storedBehindApi:boolean = true;

    public dependencyLevel:number = 1;



    //---------------------------------
    // Controller Methods
    //---------------------------------



    //---------------------------------
    // Private Methods
    //---------------------------------

    protected _parseRetrievedItemFile(p_itemFile:ISyncItemFileDto)
    {
        this._parseSlide(p_itemFile.body);
    }


}


export default SyncSlideProcess;
