<template>
  <div class="sub-page audience-detail-page">
    <AudienceDetail></AudienceDetail>
  </div>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import AudienceSummary from "@/audience/_view/AudienceSummary.vue";
import AudienceDetail from "@/audience/_view/AudienceDetail.vue";

@Component({
    components: {AudienceDetail}
})
export default class AudienceDetailPage extends Vue {
}
</script>
