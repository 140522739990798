<template>
  <div>

    <transition name="fade">
      <div>
        <b-form>

          <b-form-textarea v-model="containerPropertyValueDto.value"
                           rows="5" id="container-property-value"></b-form-textarea>

          <div class="d-flex justify-content-end mt-4">
            <b-button variant="secondary" class="mr-2" @click="_onCancelBtnClick" tabindex="6">
              {{ $t('Cancel') }}
            </b-button>
            <b-button @click="_onFinishBtnClick" variant="primary">{{
                $t('Ok')
              }}
            </b-button>
          </div>
        </b-form>
      </div>
    </transition>

  </div>
</template>

<script lang="ts">

import {Component, Prop, Vue} from 'vue-property-decorator';
import {IContainerPropertyValueDto} from "@/presentation/player/_model/player.dto";
import CodeMirror from "codemirror";

enum FormState {
    TYPE_CHOICE,
    VALUE_CHOICE
}

@Component({
    components: {},
})
export default class EditContainerPropertyBox extends Vue {

    @Prop() public containerPropertyValueDto!: IContainerPropertyValueDto;

    public editor?: CodeMirror.EditorFromTextArea;

    public cmOptions: any = {
        // codemirror options
        tabSize: 4,
        mode: 'text/html',
        theme: 'base16-dark',
        lineNumbers: true,
        line: true,
    };

    mounted() {
        const editor: CodeMirror.EditorFromTextArea = this.editor = CodeMirror.fromTextArea(document.getElementById('container-property-value') as HTMLTextAreaElement, this.cmOptions);
        setTimeout(function () {
            editor.refresh();
            editor.focus();
            editor.setCursor({line: editor.lineCount(), ch: 0});
        }, 100);

    }

    private async _onFinishBtnClick(p_e: Event) {
        this.containerPropertyValueDto.value = this.editor!.getValue();
        this.$emit('onContainerPropertyValueSet', this.containerPropertyValueDto);
    }

    private async _onCancelBtnClick(p_e: Event) {
        this.$emit('onContainerPropertyValueSet', null);
    }
}
</script>
