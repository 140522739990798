import {ENTITY_CONFIG, EntityType, IEntityTypeConfig} from "@/entity/_model/entity.constants";
import EntityController from "@/entity/_controller/EntityController";
import EntityModel from "@/entity/_model/EntityModel";
import ContentModel from "@/content/_model/ContentModel";
import {
    ICreateResultDto,
    ICreateShareResultDto, IDeleteResultDto,
    IEntityAudienceShareDto, IEntityAudienceShareOutlineDto,
    IUpdateResultDto
} from "@/entity/_model/entity.dto";
import apiManager, {ApiRequestType, ApiResponse} from "@/_controller/ApiManager";
import AudienceModel from "@/audience/_model/AudienceModel";
import SyncDb from "@/sync/_model/SyncDb";
import ContentFolderModel from "@/content/_model/ContentFolderModel";
import ContentFolderListModel from "@/content/_model/ContentFolderListModel";
import toastManager, {ToastType} from "@/__libs/toast_manager/ToastManager";
import i18n from "@/__plugins/i18n";
import {CobrowseUserInteractionState} from "@sales-drive/audience-library/lib/cobrowse_manager/cobrowse_user/_model/cobrowse_user.constants";
import {SharedObjectType} from "@sales-drive/audience-library/lib/cobrowse_manager/shared_object/shared_object.constants";
import SharedObject from "@sales-drive/audience-library/lib/cobrowse_manager/shared_object/SharedObject";
import {IPresentedContentSoDataDto} from "@sales-drive/audience-library/lib/cobrowse_manager/presented_content/_model/presented_content.dto";
import CobrowseManager from "@sales-drive/audience-library/lib/cobrowse_manager/CobrowseManager";


//contains all (static) controller methods that can be initiated on a single presentation
class ContentController extends EntityController
{


    //---------------------------------
    // Properties
    //---------------------------------

    private _syncDb:SyncDb = SyncDb.getInstance();

    private _contentFolderListModel:ContentFolderListModel = ContentFolderListModel.getInstance();

    private _cobrowseManager:CobrowseManager = CobrowseManager.getInstance();

    //---------------------------------
    // Controller Methods
    //---------------------------------

    public async shareContentWithAudience(content:ContentModel, shareDto:IEntityAudienceShareDto):Promise<ApiResponse<ICreateShareResultDto>>
    {

        const endPoint:string = `/client-api/${this._getEntityConfig(content.entityType).apiPath}/${content.ID}/audience-share`;
        const response:ApiResponse<ICreateShareResultDto> = await apiManager.sendApiRequest(ApiRequestType.POST, endPoint, shareDto);

        if (!response.hasSucceeded)
        {
            await this._syncDb.addBackgroundSyncRecord({
                requestType: ApiRequestType.POST, endpoint: endPoint, data: shareDto
            });
        }
        return response;
    }


    public async updateAudienceShare(shareDto:IEntityAudienceShareOutlineDto):Promise<boolean>
    {
        //todo: check response: not updating, maybe refactor to content/audience-share/shareID
        const endPoint:string = `/client-api/${this._getEntityConfig(shareDto.entityType).apiPath}/${shareDto.ID}/audience-share/${shareDto.shareID}`;
        const response:ApiResponse<IUpdateResultDto> = await apiManager.sendApiRequest(ApiRequestType.PUT, endPoint, shareDto);
        return response.hasSucceeded;
    }


    public async deleteAudienceShare(shareDto:IEntityAudienceShareOutlineDto, p_audience:AudienceModel):Promise<boolean>
    {
        const endPoint:string = `/client-api/${this._getEntityConfig(shareDto.entityType).apiPath}/${shareDto.ID}/audience-share/${shareDto.shareID}`;
        const response:ApiResponse<IDeleteResultDto> = await apiManager.sendApiRequest(ApiRequestType.DELETE, endPoint, shareDto);

        if (response.hasSucceeded)
        {
            const index:number = p_audience.contentShares.indexOf(shareDto as any);
            if (index >= 0)
            {
                p_audience.contentShares.splice(index, 1);
            }
        }

        return response.hasSucceeded;
    }


    public async moveContentToFolder(p_content:ContentModel, p_folder:ContentFolderModel):Promise<boolean>
    {

        // todo BUG: dragging content to root only comes through after second refresh (metacache not updated straight away???) >> yes, because the home folder is rendered first
        //  would be solved by updating meta cache(LocalStorageKey.CONTENTS_META) after each move (best option), or by checking if content is still in the same folder when new meta info is downloaded

        if (p_content.folderID !== p_folder.folderID) //not trying to move to the folder the content is already in
        {
            const folderID = p_folder.folderID === 0 ? null : p_folder.folderID;


            this._contentFolderListModel.moveContentToFolder(p_content, p_folder);

            const endPoint:string = `/client-api/${this._getEntityConfig(p_content.entityType).apiPath}/${p_content.ID}/folders`;
            const response:ApiResponse<IUpdateResultDto> = await apiManager.sendApiRequest(ApiRequestType.PUT, endPoint, {folderID: folderID});
            if (response.hasSucceeded)
            {
                toastManager.showToast(i18n.t('ContentDropInFolderSuccess') as string, ToastType.SUCCESS);
            }

            return response.hasSucceeded;
        }
        else
        {
            return false;
        }
    }

    public startRemotePresent(p_contentID:string){
        //if cobrowse enabled, remote present
        if (this._cobrowseManager.activeRoom && this._cobrowseManager.activeRoom.yourself) //if you are in a cobrowse session
        {
            //set yourself interactive if not yet
            if(this._cobrowseManager.activeRoom.yourself.interactionState !== CobrowseUserInteractionState.INTERACTIVE)
            {
                this._cobrowseManager.activeRoom.yourself.interactionState = CobrowseUserInteractionState.INTERACTIVE;
                this._cobrowseManager.activeRoom.roomUsersSO?.emitChanges();
            }
            //emit the presented content ID to the portal users
            const presentedContentSO = this._cobrowseManager.activeRoom.getSharedObject(SharedObjectType.PRESENTED_CONTENT) as SharedObject<IPresentedContentSoDataDto>;
            if (presentedContentSO)
            {
                presentedContentSO.data.presentedContentID = p_contentID;
                presentedContentSO.emitChanges();
            }
        }

    }

    public stopRemotePresent(){

        //if cobrowse enabled, stop remote present
        if (this._cobrowseManager.activeRoom && this._cobrowseManager.activeRoom.yourself) //if you are in a cobrowse session
        {
            //set yourself interactive if not yet
            if(this._cobrowseManager.activeRoom.yourself.interactionState !== CobrowseUserInteractionState.NONE)
            {
                this._cobrowseManager.activeRoom.yourself.interactionState = CobrowseUserInteractionState.NONE;
                this._cobrowseManager.activeRoom.roomUsersSO?.emitChanges();
            }
            //emit the presented content ID to the portal users
            const presentedContentSO = this._cobrowseManager.activeRoom.getSharedObject(SharedObjectType.PRESENTED_CONTENT) as SharedObject<IPresentedContentSoDataDto>;
            if (presentedContentSO)
            {
                presentedContentSO.data.presentedContentID = "";
                presentedContentSO.emitChanges();
            }
        }
    }

    //---------------------------------
    // Private Methods
    //---------------------------------



    protected _getEntityConfig(p_entityType:EntityType):IEntityTypeConfig
    {
        //return the subentities apiPath, not the apiPath of the controller (eg "PRESENTATION" vs "CONTENT")
        return ENTITY_CONFIG[p_entityType];
    }



}

//Singleton export
export default new ContentController(ENTITY_CONFIG.CONTENT);
