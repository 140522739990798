import {LocalStorageKey, StorageItemType} from "@/_model/app.constants";
import {RightAction} from "@/team/_model/role.constants";

export enum LoadingStatus
{
    ID_ONLY,
    LOADING_META,
    META_LOADED,
    LOADING_BODY,
    BODY_ERROR,
    BODY_LOADED
}

export enum UploadStatus
{
    IDLE,
    QUEUED,
    UPLOADING,
    ERROR_UPLOADING
}


export enum SaveStatus
{
    IDLE,
    IN_MEMORY,
    SAVING_REMOTE,
    REMOTE_SAVED,
    REMOTE_ERROR,
    SAVING_LOCAL,
    LOCAL_SAVED,
    LOCAL_ERROR
}

export enum CacheStatus
{
    UNKNOWN,
    NOT_CACHED,
    CACHING_IN_PROGRESS,
    CACHED_BUT_OUT_OF_DATE,
    CACHED_LATEST
}

//the status of the entity since the last time you visited the app
export enum DeltaStatus
{
    DELETED,
    UNCHANGED,
    UPDATED,
    NEW
}

export enum EntityType
{
    AUDIENCE = "AUDIENCE",
    CONTENT = "CONTENT",
    PRESENTATION = "PRESENTATION",
    CONTENT_APP = "CONTENT_APP",
    CONTENT_FILE = "CONTENT_FILE",
    ASSET_FOLDER = "ASSET_FOLDER",
    SLIDE = "SLIDE",
    DATA_PROVIDER = "DATA_PROVIDER",
    COMPANY = "COMPANY",
}

export enum ContentType
{
    PRESENTATION = "PRESENTATION",
    CONTENT_APP = "CONTENT_APP",
    CONTENT_FILE = "CONTENT_FILE",
}

export interface IEntityTypeConfig
{
    apiPath:string;
    localStorageKey:LocalStorageKey;
    storageFolder:string;
    storageItemType:StorageItemType;
    shorthand:string;
    canBePresented:boolean; //used in EntityActionBox
    canBeTrained:boolean; //used in EntityActionBox
    canBePrepared:boolean; //used in EntityActionBox
    canBeSharedWithAudiences:boolean; //used in EntityActionBox
    canBeCopied:boolean; //used in EntityActionBox
    canBeTranslated:boolean; //used in EntityActionBox
    hasInfo:boolean; //used in EntityActionBox
    shareWithTeamAllowActions:RightAction[];
    shareWithTeamDefaults:RightAction[];
}


export const ENTITY_CONFIG:{ [key in EntityType]:IEntityTypeConfig } = {

    [EntityType.AUDIENCE]: {

        apiPath                  : 'audiences',
        localStorageKey          : LocalStorageKey.AUDIENCES_META,
        storageFolder            : 'audiences',
        storageItemType          : StorageItemType.DATA,
        shorthand                : "aud",
        canBePresented           : false,
        canBeTrained             : false,
        canBePrepared            : false,
        canBeSharedWithAudiences : false,
        canBeCopied              : false,
        canBeTranslated          : false,
        hasInfo                  : true,
        shareWithTeamAllowActions: [RightAction.EDIT, RightAction.DELETE, RightAction.SHARE_WITH_TEAMS],
        shareWithTeamDefaults    : []
    },
    [EntityType.COMPANY] : {

        apiPath                  : 'companies',
        localStorageKey          : LocalStorageKey.COMPANIES_META,
        storageFolder            : 'companies',
        storageItemType          : StorageItemType.DATA,
        shorthand                : "cmp",
        canBePresented           : false,
        canBeTrained             : false,
        canBePrepared            : false,
        canBeSharedWithAudiences : false,
        canBeCopied              : false,
        canBeTranslated          : false,
        hasInfo                  : true,
        shareWithTeamAllowActions: [RightAction.EDIT, RightAction.DELETE, RightAction.SHARE_WITH_TEAMS],
        shareWithTeamDefaults    : []
    },
    [EntityType.CONTENT] : {
        apiPath                  : 'content',
        localStorageKey          : LocalStorageKey.CONTENTS_META,
        storageFolder            : '',
        storageItemType          : StorageItemType.DATA,
        shorthand                : "cnt",
        canBePresented           : true,
        canBeTrained             : false,
        canBePrepared            : false,
        canBeSharedWithAudiences : true,
        canBeCopied              : true,
        canBeTranslated          : false,
        hasInfo                  : true,
        shareWithTeamAllowActions: [RightAction.PRESENT, RightAction.COPY, RightAction.EDIT, RightAction.DELETE, RightAction.SHARE_WITH_AUDIENCES, RightAction.SHARE_WITH_TEAMS],
        shareWithTeamDefaults    : [RightAction.PRESENT, RightAction.COPY, RightAction.SHARE_WITH_AUDIENCES]
    },

    //content entity types (presentations, apps, files)
    [EntityType.PRESENTATION]: {

        apiPath                  : 'presentations',
        localStorageKey          : LocalStorageKey.CONTENTS_META,
        storageFolder            : 'presentations',
        storageItemType          : StorageItemType.DATA,
        shorthand                : "prs",
        canBePresented           : true,
        canBeTrained             : true,
        canBePrepared            : false,
        canBeSharedWithAudiences : true,
        canBeCopied              : true,
        canBeTranslated          : true,
        hasInfo                  : true,
        shareWithTeamAllowActions: [RightAction.PRESENT, RightAction.COPY, RightAction.EDIT, RightAction.DELETE, RightAction.SHARE_WITH_AUDIENCES, RightAction.SHARE_WITH_TEAMS],
        shareWithTeamDefaults    : [RightAction.PRESENT, RightAction.COPY, RightAction.SHARE_WITH_AUDIENCES]

    },
    [EntityType.CONTENT_APP] : {

        apiPath                  : 'content-apps',
        localStorageKey          : LocalStorageKey.CONTENTS_META,
        storageFolder            : 'contentApps',
        storageItemType          : StorageItemType.DATA,
        shorthand                : "app",
        canBePresented           : true,
        canBeTrained             : true,
        canBePrepared            : true,
        canBeSharedWithAudiences : true,
        canBeCopied              : true,
        canBeTranslated          : false,
        hasInfo                  : true,
        shareWithTeamAllowActions: [RightAction.PRESENT, RightAction.COPY, RightAction.EDIT, RightAction.DELETE, RightAction.SHARE_WITH_AUDIENCES, RightAction.SHARE_WITH_TEAMS],
        shareWithTeamDefaults    : [RightAction.PRESENT, RightAction.COPY, RightAction.SHARE_WITH_AUDIENCES]

    },
    [EntityType.CONTENT_FILE]: {

        apiPath                  : 'content-files',
        localStorageKey          : LocalStorageKey.CONTENTS_META,
        storageFolder            : 'contentFiles',
        storageItemType          : StorageItemType.FILEGROUP,
        shorthand                : "fil",
        canBePresented           : true,
        canBeTrained             : false,
        canBePrepared            : false,
        canBeSharedWithAudiences : true,
        canBeCopied              : false,
        canBeTranslated          : false,
        hasInfo                  : true,
        shareWithTeamAllowActions: [RightAction.PRESENT, RightAction.EDIT, RightAction.DELETE, RightAction.SHARE_WITH_AUDIENCES, RightAction.SHARE_WITH_TEAMS],
        shareWithTeamDefaults    : [RightAction.PRESENT, RightAction.SHARE_WITH_AUDIENCES]

    },

    //library entity types (assets, slides, data)
    [EntityType.ASSET_FOLDER] : {

        apiPath                  : 'asset-folders',
        localStorageKey          : LocalStorageKey.ASSET_FOLDERS_META,
        storageFolder            : 'assetFolders',
        storageItemType          : StorageItemType.FILEGROUP,
        shorthand                : "asf",
        canBePresented           : false,
        canBeTrained             : false,
        canBePrepared            : false,
        canBeSharedWithAudiences : false,
        canBeCopied              : false,
        canBeTranslated          : false,
        hasInfo                  : true,
        shareWithTeamAllowActions: [RightAction.EDIT, RightAction.DELETE, RightAction.SHARE_WITH_TEAMS],
        shareWithTeamDefaults    : []
    },
    [EntityType.SLIDE]        : {

        apiPath                  : 'slides',
        localStorageKey          : LocalStorageKey.SLIDES_META,
        storageFolder            : 'slides',
        storageItemType          : StorageItemType.DATA,
        shorthand                : "sld",
        canBePresented           : false,
        canBeTrained             : false,
        canBePrepared            : false,
        canBeSharedWithAudiences : false,
        canBeCopied              : true,
        canBeTranslated          : true,
        hasInfo                  : true,
        shareWithTeamAllowActions: [RightAction.EDIT, RightAction.COPY, RightAction.DELETE, RightAction.SHARE_WITH_TEAMS],
        shareWithTeamDefaults    : []
    },
    [EntityType.DATA_PROVIDER]: {

        apiPath                  : 'data-providers',
        localStorageKey          : LocalStorageKey.DATA_PROVIDERS_META,
        storageFolder            : 'dataProviders',
        storageItemType          : StorageItemType.DATA,
        shorthand                : "dtp",
        canBePresented           : false,
        canBeTrained             : false,
        canBePrepared            : false,
        canBeSharedWithAudiences : false,
        canBeCopied              : true,
        canBeTranslated          : false,
        hasInfo                  : true,
        shareWithTeamAllowActions: [RightAction.EDIT, RightAction.COPY, RightAction.DELETE, RightAction.SHARE_WITH_TEAMS],
        shareWithTeamDefaults    : []
    }
};

