import DataProviderModel from "@/data_tool/data_provider/_model/DataProviderModel";
import EntityListModel from "@/entity/_model/EntityListModel";
import DataProviderSelectionState from "@/data_tool/data_provider/_model/DataProviderSelectionState";
import {
    DataProviderViewMode
} from "@/data_tool/_model/data_tool.constants";
import {IDataProviderOutlineDto} from "@/data_tool/_model/data_tool.dto";

class DataProviderListModel extends EntityListModel<DataProviderModel>
{

    public pageSelState:DataProviderSelectionState = new DataProviderSelectionState(DataProviderViewMode.PAGE);

    public pickerSelState:DataProviderSelectionState = new DataProviderSelectionState(DataProviderViewMode.PICKER);


    //---------------------------------
    // Singleton Constructor
    //---------------------------------

    private static _instance:DataProviderListModel;

    constructor()
    {
        super();
        if (!DataProviderListModel._instance)
        {
            DataProviderListModel._instance = this;
        }
    }

    public static getInstance():DataProviderListModel
    {
        if (!DataProviderListModel._instance)
        {
            new DataProviderListModel();
        }

        return DataProviderListModel._instance;
    }


    //---------------------------------
    // Public Methods
    //---------------------------------

    //get the data providers that are built following the provided item definition URI
    public getDataProvidersBySchemaURI(p_schemaURI:string):IDataProviderOutlineDto[]
    {
        const dataSchemaURI:string = p_schemaURI.split("#")[0];
        // const itemIdentifier:string = p_itemDefinitionURI.split("#")[1];

        const dtpList:IDataProviderOutlineDto[] = [];
        for (let i = 0; i < this.list.length; i++)
        {
            const dtp:DataProviderModel = this.list[i];
            if (dtp.dataSchemaURI === dataSchemaURI)
            {
                dtpList.push({name: dtp.name, ID: dtp.ID});
            }
        }
        return dtpList;
    }

}

export default DataProviderListModel;
