import {
    IAssetFileDto,
    IAssetFolderBodyDto,
    IAssetFolderMetaDto,
    IFileOutlineDto
} from "@/asset_folder/_model/asset_folder.dto";
import EntityModel from "@/entity/_model/EntityModel";
import {IModelDto} from "@/__libs/_model/model.dto";
import {EntityType, UploadStatus} from "@/entity/_model/entity.constants";
import {DtoType} from "@/_model/app.constants";
import {IMultiLangString} from "@/__libs/language_manager/LanguageManager";
import AssetFileModel from "@/asset_folder/_model/AssetFileModel";

export default class AssetFolderModel extends EntityModel implements IAssetFolderBodyDto, IAssetFolderMetaDto
{
    public entityType:EntityType = EntityType.ASSET_FOLDER;


    //body
    public name:IMultiLangString = {"en": ""};
    public assetFiles:AssetFileModel[] = [];
    public description:IMultiLangString = {"en": ""};
    public files:IFileOutlineDto[] = [];
    public previewImageURI:string = "";
    public subFolders:any[] = [];
    public totalFileSize:number = 0;

    //meta


    //---------------------------------
    // Constructor
    //---------------------------------

    constructor(p_dto?:IModelDto)
    {
        super();
        if (p_dto)
        {
            this.mapFromDto(p_dto);
        }
    }

    //---------------------------------
    // Public Methods
    //---------------------------------

    public mapFromDto(p_dto:IModelDto)
    {
        super.mapFromDto(p_dto);

        //assume it is an IAssetFolderBodyDto and map the IAssetFileDtos to AssetFileModels
        const assetFolderBody:IAssetFolderBodyDto = p_dto as IAssetFolderBodyDto;
        if (assetFolderBody.assetFiles)
        {
            const assetFiles:AssetFileModel[] = [];
            for (let i = 0; i < assetFolderBody.assetFiles.length; i++)
            {
                assetFiles.push(new AssetFileModel(assetFolderBody.assetFiles[i]));
            }
            this.assetFiles = assetFiles;
        }

    }

    //until typescript has proper reflection, we will have to do it this way:
    public mapToDto(p_dtoType:DtoType):IModelDto
    {
        if (p_dtoType === DtoType.BODY)
        {
            //map the asset file models back to dto's
            const assetFiles:IAssetFileDto[] = [];
            for (let i = 0; i < this.assetFiles.length; i++)
            {
                const assetFile:AssetFileModel = this.assetFiles[i];
                //only add files if the upload has succeeded (or if there was no upload)
                if (assetFile.uploadStatus === UploadStatus.IDLE)
                {
                    assetFiles.push(assetFile.mapToDto(DtoType.BODY));
                }
            }
            const dto:IAssetFolderBodyDto = {
                ID             : this.ID,
                version        : this.version,
                name           : this.name,
                assetFiles     : assetFiles,
                description    : this.description,
                files          : this.files,
                previewImageURI: this.previewImageURI,
                subFolders     : this.subFolders,
                totalFileSize  : this.totalFileSize
            };
            if(this.identifier)
            {
                dto.identifier = this.identifier;
            }

            return dto;
        }
        else
        {
            return super.mapToDto(p_dtoType);
        }
    }

    public getAssetFileByUri(p_fileUri:string):AssetFileModel | null
    {
        for (let i = 0; i < this.assetFiles.length; i++)
        {
            if (this.assetFiles[i].fileURI === p_fileUri)
            {
                return this.assetFiles[i];
            }
        }
        return null;
    }

}
