import AudienceModel from "@/audience/_model/AudienceModel";
import EntityListModel from "@/entity/_model/EntityListModel";
import EntitySelectionState from "@/entity/_model/EntitySelectionState";

class AudienceListModel extends EntityListModel<AudienceModel>
{

    public globalSelState:EntitySelectionState<AudienceModel> = new EntitySelectionState<AudienceModel>();
    public fullListBodyLoaded:boolean = false;


    //---------------------------------
    // Singleton Constructor
    //---------------------------------

    private static _instance:AudienceListModel;

    constructor()
    {
        super();
        if (!AudienceListModel._instance)
        {
            AudienceListModel._instance = this;
        }
    }

    public static getInstance():AudienceListModel
    {
        if (!AudienceListModel._instance)
        {
            new AudienceListModel();
        }

        return AudienceListModel._instance;
    }


    //---------------------------------
    // Public Methods
    //---------------------------------


    public getAudiencesForCompany(p_companyID:string)
    {
        const audiences:AudienceModel[] = [];
        for (let i = 0; i < this.list.length; i++)
        {
            if (this.list[i].companyID === p_companyID)
            {
                audiences.push(this.list[i])
            }
        }
        return audiences;
    }

    public getAudienceByEmail(p_email:string):AudienceModel | null
    {
        const audiences:AudienceModel[] = [];
        for (let i = 0; i < this.list.length; i++)
        {
            if (this.list[i].email === p_email)
            {
                return this.list[i]
            }
        }
        return null;
    }

    public getAudienceByRemoteID(p_remoteID:string):AudienceModel | null
    {
        const audiences:AudienceModel[] = [];
        for (let i = 0; i < this.list.length; i++)
        {
            if (this.list[i].importedIdentifier === p_remoteID)
            {
                return this.list[i]
            }
        }
        return null;
    }
}

export default AudienceListModel;
