import SyncDataProcess from "@/sync/_controller/process/SyncDataProcess";
import syncController from "@/sync/_controller/SyncController";
import {SyncFileRetrieveStatus, SyncProcessType} from "@/sync/_model/sync.constants";
import {IAudienceBodyDto} from "@/audience/_model/audience.dto";
import {ISyncFileDto, ISyncItemFileDto} from "@/sync/_model/sync.dto";
import fileManager from "@/_controller/FileManager";
import {StorageScope} from "@/_model/app.constants";
import SyncContentAppResourceProcess from "@/sync/_controller/process/SyncContentAppResourceProcess";
import SyncDataProviderProcess from "@/sync/_controller/process/SyncDataProviderProcess";
import SyncCompanyProcess from "@/sync/_controller/process/SyncCompanyProcess";

class SyncAudienceProcess extends SyncDataProcess
{

    //---------------------------------
    // Properties
    //---------------------------------

    protected _storagePath:string = "audiences";
    protected _storedBehindApi:boolean = true;

    public dependencyLevel:number = 0;

    private _companyProcess!:SyncCompanyProcess;


    //---------------------------------
    // Controller Methods
    //---------------------------------



    //---------------------------------
    // Private Methods
    //---------------------------------


    protected _resolveDependencyProcesses()
    {
        this._companyProcess = syncController.resolveDependencyProcess(SyncProcessType.COMPANY) as SyncCompanyProcess;
    }


    protected _parseRetrievedItemFile(p_itemFile:ISyncItemFileDto)
    {

        const body:IAudienceBodyDto = p_itemFile.body;

        if (body.avatarFileUri)
        {
            const filePath:string = fileManager.getAssetFileBasePath(body.avatarFileUri);

            if (filePath.length > 0)
            {
                const syncFile:ISyncFileDto = {
                    path          : filePath,
                    storageScope : this._storageScope,
                    retrieveStatus: SyncFileRetrieveStatus.IDLE,
                    nrOfAttempts  : 0
                };
                syncController.fileGroupFileProcess.addFileGroupFile(syncFile);
            }
        }

        if (body.companyID)
        {
            this._companyProcess.addSyncItem({identifier: body.companyID});
        }



    }
}


export default SyncAudienceProcess;
