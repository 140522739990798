import ContentAppResourceModel from "@/content_app/resource/_model/ContentAppResourceModel";

export default class ContentAppResourceListModel {

    public list: ContentAppResourceModel[] = [];

    private static _instance: ContentAppResourceListModel;

    constructor() {
        if (!ContentAppResourceListModel._instance) {
            ContentAppResourceListModel._instance = this;
        }
    }

    public static getInstance(): ContentAppResourceListModel {
        if (!ContentAppResourceListModel._instance) {
            new ContentAppResourceListModel();
        }
        return ContentAppResourceListModel._instance;
    }

}
