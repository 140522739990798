<template>
  <div class="list-item slide-item"
       :class="{ active: isSelected, 'body-loaded': bodyLoaded }"
       v-on:click="_onClick">

    <div class="list-item__icon">
      <svg id="slide-closed" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" v-if="!isSelected">
        <defs>
          <linearGradient id="linear-gradient-slide-closed" x1="13.36" y1="29.75" x2="13.36" y2="14.29" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#94aec1"/>
            <stop offset="1" stop-color="#d0d9e1"/>
          </linearGradient>
          <linearGradient id="linear-gradient-slide-closed-2" x1="19.59" y1="17.78" x2="19.59" y2="2.25" xlink:href="#linear-gradient-slide-closed"/>
        </defs>
        <path class="cls-1" d="M25.11,25.17l-20.23,4.53c-1.1.25-2.2-.44-2.44-1.54L.05,17.46c-.25-1.1.45-2.2,1.55-2.44l3.2-.72c.13-.03.25.07.25.2v1.65c0,2.08,1.68,3.76,3.76,3.76h16.9c.19,0,.35.13.39.32l.56,2.5c.25,1.1-.45,2.2-1.55,2.44Z"/>
        <rect class="cls-2" x="7.18" y="2.25" width="24.82" height="15.53" rx="2.08" ry="2.08"/>
      </svg>
      <svg id="slide-open" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" v-if="isSelected">
        <defs>
          <linearGradient id="linear-gradient-slide-open" x1="14.95" y1="13.65" x2="11.59" y2="27.29" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#3a4951"/>
            <stop offset=".29" stop-color="#536a75"/>
            <stop offset=".57" stop-color="#668290"/>
            <stop offset=".81" stop-color="#7290a0"/>
            <stop offset="1" stop-color="#7696a6"/>
          </linearGradient>
          <linearGradient id="linear-gradient-slide-open-2" x1="19.59" y1="26.48" x2="19.59" y2="-6.99" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#3a4951"/>
            <stop offset=".29" stop-color="#536a75"/>
            <stop offset=".57" stop-color="#668290"/>
            <stop offset=".81" stop-color="#7290a0"/>
            <stop offset="1" stop-color="#7696a6"/>
          </linearGradient>
        </defs>
        <path class="cls-1" d="M25.11,25.17l-20.23,4.53c-1.1.25-2.2-.44-2.44-1.54L.05,17.46c-.25-1.1.45-2.2,1.55-2.44l3.2-.72c.13-.03.25.07.25.2v1.65c0,2.08,1.68,3.76,3.76,3.76h16.9c.19,0,.35.13.39.32l.56,2.5c.25,1.1-.45,2.2-1.55,2.44Z"/>
        <rect class="cls-2" x="7.18" y="2.25" width="24.82" height="15.53" rx="2.08" ry="2.08"/>
      </svg>
    </div>

    <div class="list-item__info">
      <b-skeleton v-if="!bodyLoaded"></b-skeleton>
      <h6 class="list-item__info__primary mb-0" v-line-clamp="1" v-if="bodyLoaded">{{ slideName }}</h6>
    </div>

    <MorePopup :id="`slide-${slideID}`" placement="right">
      <EntityActionBox :entity="slide" :editAllowed="true"/>
    </MorePopup>

  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from "vue-property-decorator";

import SlideModel from "@/slide/_model/SlideModel";
import {DeltaStatus, LoadingStatus} from "@/entity/_model/entity.constants";
import MorePopup from "@/_view/components/MorePopup.vue";
import EntityActionBox from "@/entity/_view/EntityActionBox.vue";
import AppUserModel from "@/project/user/_model/AppUserModel";
import languageManager from "@/__libs/language_manager/LanguageManager";
import SlideSelectionState from "@/slide/_model/SlideSelectionState";
import slideController from "@/slide/_controller/SlideController";
import {RightAction} from "@/team/_model/role.constants";

@Component({
  components: {
    MorePopup, EntityActionBox
  }
})
export default class SlideItemRenderer extends Vue {
  //---------------------------------
  // Vue Component props
  //---------------------------------

  @Prop() public slide!: SlideModel;

  @Prop() private selState!: SlideSelectionState;

  //---------------------------------
  // Vue Component data
  //---------------------------------

  //---------------------------------
  // Vue Computed properties
  //---------------------------------

  get slideName() {
    return languageManager.getTranslationForValue<string>(this.slide.name, AppUserModel.getInstance().langCode)
  }

  get slideID() {
    return this.slide.ID;
  }

  get bodyLoaded() {
    return this.slide.loadingStatus >= LoadingStatus.BODY_LOADED;
  }

  get isSelected() {
    return this.selState.selected === this.slide;
  }

  get isNew() {
    return this.slide.deltaStatus == DeltaStatus.NEW;
  }

  get isUpdated() {
    return this.slide.deltaStatus == DeltaStatus.UPDATED;
  }

  get isDeleted() {
    return this.slide.deltaStatus == DeltaStatus.DELETED;
  }

  get isEditable(): boolean {
    return this.slide.rightActions.indexOf(RightAction.EDIT) >= 0;
  }

  //---------------------------------
  // Public / lifecycle methods
  //---------------------------------

  //---------------------------------
  // Private / helper methods
  //---------------------------------

  public _onClick(p_e: Event) {
    this.$emit("onClick", this.slide);
  }

}
</script>
