<template>
  <div class="content-quicklaunch-item" :class="{ 'body-loaded': bodyLoaded }" @click="_onClick">

    <div class="content-quicklaunch-item__icon">
      <i class="fas fa-paper-plane fa-lg"></i>
    </div>

    <div class="content-quicklaunch-item__info">
      <div class="content-quicklaunch-item__info__skeleton" v-if="!bodyLoaded">
        <div class="bar mb-1"></div>
        <div class="bar"></div>
      </div>
      <h6 class="content-quicklaunch-item__info__type mb-1" v-html="$t('DashboardQuicklaunch')" v-if="bodyLoaded"></h6>
      <h6 class="content-quicklaunch-item__info__name mb-0" v-line-clamp="1" v-if="bodyLoaded">{{ contentName }}</h6>
    </div>

  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from "vue-property-decorator";
import ContentModel from "@/content/_model/ContentModel";
import AudienceModel from "@/audience/_model/AudienceModel";
import AudienceListModel from "@/audience/_model/AudienceListModel";
import languageManager from "@/__libs/language_manager/LanguageManager";
import {EventBus} from "@/__libs/vue/EventBus";
import {EventBusActions} from "@/_model/app.constants";
import {LoadingStatus} from "@/entity/_model/entity.constants";

@Component({
  components: {}
})
export default class ContentQuickLaunchItemRenderer extends Vue {

  @Prop() public content!: ContentModel;

  get contentName() {
    return languageManager.getTranslationForValue(
        this.content.name,
        this.audienceLangCode
    );
  }

  get contentID() {
    return this.content.ID;
  }

  get bodyLoaded() {
    return this.content.loadingStatus >= LoadingStatus.BODY_LOADED;
  }

  get audienceLangCode(): string | null {
    const activeAudience: AudienceModel | null = AudienceListModel.getInstance()
        .globalSelState.selected;
    return activeAudience ? activeAudience.langCode : null;
  }

  public _onClick(p_e: Event) {
    EventBus.$emit(EventBusActions.ASK_PRESENT_CONTENT, this.content);
  }
}
</script>
