<template>
  <div class="audience-banner">
    <div class="audience-banner__title">
      {{ $t('AudienceBannerTitle') }}<span v-if="isMultiple"> ({{ $t('AudienceBannerTitleMultiple')}})</span>:
    </div>
    <div class="audience-banner__active" id="change-audience" :class="{ active: popupOpen }">
      <div class="audience-banner__avatar">
        <b-skeleton v-if="!bodyLoaded" width="100%" height="100%" type="avatar"/>
        <transition name="fade">
          <AAProfilePicture :name="audience.displayName" :imageUri="audience.avatarFileUri"
                            v-if="bodyLoaded"></AAProfilePicture>
        </transition>
      </div>
      <div class="audience-banner__info" v-if="!noAudiences">
        <b-skeleton v-if="!bodyLoaded" class="mt-1"></b-skeleton>
        <b-skeleton v-if="!bodyLoaded"></b-skeleton>
        <h6 class="audience-banner__info__name" v-line-clamp="1" v-if="bodyLoaded">{{ audience.displayName }}</h6>
        <p class="audience-banner__info__company" v-line-clamp="1" v-if="bodyLoaded && hasCompany">{{ companyName }}</p>
      </div>
      <div class="audience-banner__info" v-if="noAudiences">
        <h6 class="audience-banner__info__no-audiences" v-html="$t('AudienceBannerNoAudiences')"></h6>
      </div>
      <b-button variant="light" size="sm" class="audience-banner__change" :id="hintIds.HOME_CREATE_AUDIENCE">
        <i class="fas fa-chevron-down fa-lg mr-0"></i>
      </b-button>
    </div>
    <b-button variant="light" size="sm" @click="openShare" :class="{active: onSharePage}" :id="hintIds.HOME_SHARED_CONTENT"><i
        class="fas fa-share fa-lg"></i>{{ $t('DashboardTabTitleShared') }}
    </b-button>
    <b-button variant="light" size="sm" @click="openTrack" :class="{active: onTrackPage}" :id="hintIds.HOME_TRACKING_DATA"><i
        class="fas fa-chart-simple fa-lg"></i>{{ $t('DashboardTabTitleTrack') }}
    </b-button>

    <b-popover ref="popover" target="change-audience" triggers="click" placement="bottom" custom-class="audience-banner-popup" boundary="window" @shown="onPopupOpen"
               @hidden="onPopupHidden">
      <AudiencesOverview v-on:close="overviewClose"></AudiencesOverview>
    </b-popover>

    <b-popover custom-class="hint-popover" :target="hintIds.HOME_CREATE_AUDIENCE"
               placement="right"
               triggers="manual"
               boundary="window">
      <Hint :id="hintIds.HOME_CREATE_AUDIENCE"></Hint>
    </b-popover>

    <b-popover custom-class="hint-popover" :target="hintIds.HOME_SHARED_CONTENT"
               placement="right"
               boundary="window"
               triggers="manual">
      <Hint :id="hintIds.HOME_SHARED_CONTENT"></Hint>
    </b-popover>

    <b-popover custom-class="hint-popover" :target="hintIds.HOME_TRACKING_DATA"
               placement="right"
               boundary="window"
               triggers="manual">
      <Hint :id="hintIds.HOME_TRACKING_DATA"></Hint>
    </b-popover>
  </div>
</template>

<script lang="ts">
import {Component, Vue} from "vue-property-decorator";
import AudienceModel from "@/audience/_model/AudienceModel";
import AudienceListModel from "@/audience/_model/AudienceListModel";
import {LoadingStatus} from "@/entity/_model/entity.constants";
import AAProfilePicture from "@/_view/components/AAProfilePicture.vue";
import CompanyListModel from "@/company/_model/CompanyListModel";
import CompanyModel from "@/company/_model/CompanyModel";
import AudiencesOverview from "@/audience/_view/AudiencesOverview.vue";
import {RoutingIdentifier} from "@/router";
import {EventBus} from "@/__libs/vue/EventBus";
import {EventBusActions} from "@/_model/app.constants";
import {BPopover} from "bootstrap-vue";
import AppModel from "@/_model/AppModel";
import Hint from "@/help/hint/_view/Hint.vue";
import {HintIds} from "@/help/hint/_model/hint.constants";

@Component({
    components: {Hint, AudiencesOverview, AAProfilePicture}
})
export default class AudienceBanner extends Vue {

    public appModel: AppModel = AppModel.getInstance();
    public popupOpen: boolean = false;

    public hintIds: typeof HintIds = HintIds;

    get audience(): AudienceModel | null {
        return AudienceListModel.getInstance().globalSelState.selected;
    }

    get noAudiences(): boolean {
        return AudienceListModel.getInstance().list.length === 0;
    }

    get isMultiple(): boolean {
        return AudienceListModel.getInstance().globalSelState.isMultiSelect && AudienceListModel.getInstance().globalSelState.selecteds.length > 1;
    }

    get bodyLoaded() {
        return this.audience && this.audience.loadingStatus >= LoadingStatus.BODY_LOADED;
    }

    get hasCompany(): boolean {
        return this.audience !== null && CompanyListModel.getInstance().getEntityByID(this.audience.companyID) !== null;
    }

    get companyName() {
        if (this.audience !== null) {
            const company: CompanyModel | null = CompanyListModel.getInstance().getEntityByID(this.audience.companyID);
            return company ? company.displayName : "";
        }
        return "";
    }

    get onSharePage() {
        return this.$route.name === RoutingIdentifier.SHARE;
    }

    get onTrackPage() {
        return this.$route.name === RoutingIdentifier.TRACK;
    }

    public async openShare() {
        if (this.$route.name === RoutingIdentifier.SHARE) {
            await this.$router.push({name: RoutingIdentifier.CONTENT_OVERVIEW});
        } else {
            if (this.appModel.playlistOverviewOpen) {
                this.appModel.togglePlaylistOverviewOpen();
            }
            await this.$router.push({name: RoutingIdentifier.SHARE});
        }
    }

    public async openTrack() {
        if (this.$route.name === RoutingIdentifier.TRACK) {
            await this.$router.push({name: RoutingIdentifier.CONTENT_OVERVIEW});
        } else {
            if (this.appModel.playlistOverviewOpen) {
                this.appModel.togglePlaylistOverviewOpen();
            }
            await this.$router.push({name: RoutingIdentifier.TRACK});
        }
    }

    private onAudienceChanged() {
        if (this.$refs.popover && !AudienceListModel.getInstance().globalSelState.isMultiSelect) {
            (this.$refs.popover as BPopover).$emit('close');
        }
    }

    private mounted() {
        EventBus.$on(EventBusActions.AUDIENCE_CHANGED, this.onAudienceChanged);
        EventBus.$on(EventBusActions.CREATE_AUDIENCE_FINISHED, this.onAudienceChanged);
    }

    private beforeDestroy() {
        EventBus.$off(EventBusActions.AUDIENCE_CHANGED, this.onAudienceChanged);
        EventBus.$off(EventBusActions.CREATE_AUDIENCE_FINISHED, this.onAudienceChanged);
    }

    private onPopupOpen() {
        this.popupOpen = true;
    }

    private onPopupHidden() {
        this.popupOpen = false;
    }

    private overviewClose() {
        (this.$refs.popover as BPopover).$emit('close');
    }

}
</script>
