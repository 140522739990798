<template>
    <div v-show="isShown">

        <!--        <b>{{ playlistEntry.entryOrder }}</b>:{{ playlistEntry.contentID }}-->

        <div v-if="isRendered">
            <div v-if="isContentReady">
                <PresentationPlayer v-if="content.entityType === 'PRESENTATION'" engineMode="PRESENT" :inPlaylistMode="true"
                                    :presentationID="playlistEntry.contentID"/>
                <ContentAppPlayer v-if="content.entityType === 'CONTENT_APP'" engineMode="PRESENT" :inPlaylistMode="true"
                                  :appID="playlistEntry.contentID"/>
                <ContentFilePlayer v-if="content.entityType === 'CONTENT_FILE'" engineMode="PRESENT" :inPlaylistMode="true"
                                  :contentFileID="playlistEntry.contentID"/>
            </div>
            <div v-else>
                <b-spinner variant="dark"></b-spinner>
            </div>
        </div>


    </div>
</template>


<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import PlaylistListModel from "@/playlist/_model/PlaylistListModel";
import {IPlaylistEntryDto} from "@/playlist/_model/playlist.dto";
import PresentationPlayer from "@/presentation/player/_view/PresentationPlayer.vue";
import ContentListModel from "@/content/_model/ContentListModel";
import {EntityType, LoadingStatus} from "@/entity/_model/entity.constants";
import ContentAppPlayer from "@/content_app/player/_view/ContentAppPlayer.vue";
import ContentFilePlayer from "@/content_file/player/_view/ContentFilePlayer.vue";



@Component({
    components: {PresentationPlayer, ContentAppPlayer, ContentFilePlayer},
})
export default class PlaylistPlayerContentItemRenderer extends Vue
{

    @Prop() public playlistEntry!:IPlaylistEntryDto;

    public playlistListModel:PlaylistListModel = PlaylistListModel.getInstance();
    private isRendered:boolean = false; //content only gets rendered when selected, when other content is selected, it remains but is put on display:none (v-show)


    get content()
    {
        return ContentListModel.getInstance().getEntityByID(this.playlistEntry.contentID);
    }

    get isShown():boolean
    {
        const isSelectedEntry:boolean = this.playlistEntry === this.playlistListModel.selectedQueueEntry;
        if (isSelectedEntry)
        {
            this.isRendered = true;
        }
        return isSelectedEntry;
    }

    get isContentReady()
    {

        if (this.content && this.content.loadingStatus === LoadingStatus.BODY_LOADED)
        {
            return true;
        }
        return false;
    }

    //---------------------------------
    // Public / lifecycle methods
    //---------------------------------

    public async mounted()
    {

    }



}
</script>


<style>

</style>
