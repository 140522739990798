

//
export enum ContainerType
{
    TEXT = "TEXT",
    IMAGE = "IMAGE",
    VIDEO = "VIDEO",
    MODULE = "MODULE"

}


export enum SlideViewMode
{
    PAGE,
    PICKER
}
