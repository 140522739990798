<template>
  <div class="d-none">
    <!--    <b-popover custom-class="hint-popover" :target="hintIds.CREATE_AUDIENCE"-->
    <!--               placement="right"-->
    <!--               boundary="window">-->
    <!--      <Hint :id="hintIds.CREATE_AUDIENCE"></Hint>-->
    <!--    </b-popover>-->
  </div>
</template>

<script lang="ts">
import {Component, Vue} from "vue-property-decorator";
import hintListController from "@/help/hint/_controller/HintListController";
import {HintEvents, HintIds} from "@/help/hint/_model/hint.constants";

@Component({
    components: {}
})
export default class HintHolder extends Vue {

    private hintIds: typeof HintIds = HintIds;

    public created() {
        if (!this.$device.mobile) {
            hintListController.init();
        }
    }

    public mounted() {
        hintListController.addListener(HintEvents.SHOW, (event) => {
            this.$root.$emit('bv::show::popover', event);
        });
        hintListController.addListener(HintEvents.HIDE, (event) => {
            if (event) {
                this.$root.$emit('bv::hide::popover', event);
            } else {
                this.$root.$emit('bv::hide::popover');
            }
        });
        document.getElementsByTagName('body')[0].addEventListener('click', e => {
            // @ts-ignore
            // this.$refs.popover.$emit('close');
        });
    }

}
</script>


