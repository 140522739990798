<template>
  <div class="translate-box">

    <transition name="fade">
      <div v-if="currentState === entityTranslationBoxState.LOADING_TRANSLATIONS" class="empty-loader">
        <div class="text-center">
          <h6 class="mb-4">{{ $t('LoadingTranslations') }}</h6>
          <b-spinner variant="dark"></b-spinner>
        </div>
      </div>
    </transition>

    <transition name="fade">
      <div v-if="currentState === entityTranslationBoxState.SAVING_TRANSLATIONS"
           class="empty-loader">
        <div class="text-center">
          <h6 class="mb-4">{{ $t('SavingTranslations') }}</h6>
          <b-spinner variant="dark"></b-spinner>
        </div>
      </div>
    </transition>

    <transition name="fade">
      <div v-if="currentState === entityTranslationBoxState.TRANSLATIONS_INPUT">

        <div class="translate-box__header">
          <div class="translate-box__header__actions">
            <b-form-select class="source-lang-select" v-model="sourceLanguage">
              <b-form-select-option v-for="langCode in availableLanguages" :key="langCode"
                                    :value="langCode">{{ langCode }}
              </b-form-select-option>
            </b-form-select>
            <b-button :disabled="isAutoTranslating" variant="success" @click="_onAutoTranslateBtnClick">
              <i class="fa-solid fa-minus pr-1"></i>{{ $t('AutoTranslate') }}<i
                class="fa-solid fa-arrow-right-long pl-2"></i>
            </b-button>
            <b-form-select class="target-lang-select" v-model="targetLanguage">
              <b-form-select-option v-for="langCode in availableLanguages" :key="langCode"
                                    :value="langCode">{{ langCode }}
              </b-form-select-option>
            </b-form-select>
          </div>
          <div class="translate-box__header__buttons">
            <b-button variant="primary" @click="_onExportBtnClick">
              {{ $t('Export') }}
            </b-button>
            <label class="btn btn-primary">
              {{ $t('ImportFromXls') }} <input type="file" ref="importFileBtn" accept=".xls,.xlsx"
                                               v-on:change="_onImportBtnClick()" hidden>
            </label>
          </div>
        </div>

        <transition name="fade">
          <div class="translate-box__progress" v-if="isAutoTranslating">
            <div class="mb-1"><b>{{ this.currentTranslationIndex }}</b>/{{ this.totalTranslations }}</div>
            <b-progress variant="secondary" :value="percCompleted"
                        class="progress mb-3"></b-progress>
          </div>
        </transition>

        <div class="translate-box__items">
          <div v-for="group in transGroups" :key="group.identifier" class="translate-box__items__group">
            <h6 class="translate-box__items__group__title">{{ group.name }}</h6>
            <div class="translate-box__items__group__items">
              <EntityTranslationItemRenderer class="trans" v-for="trans in group.translations"
                                             :key="group.identifier + trans.identifier"
                                             :trans="trans" :sourceLanguage="sourceLanguage"
                                             :targetLanguage="targetLanguage"/>
            </div>

          </div>
        </div>
        <div class="translate-box__footer">
          <b-button variant="secondary" @click="_onCloseBtnClick">
            {{ $t('Cancel') }}
          </b-button>
          <b-button variant="primary" @click="_onSaveBtnClick">
            {{ $t('Save') }}
          </b-button>
        </div>
      </div>
    </transition>

    <transition name="fade">
      <div v-if="currentState === entityTranslationBoxState.TRANSLATIONS_SAVED" class="empty-loader">
        <div class="text-center">
          <h6 class="mb-4">{{ $t('TranslationsSaved') }}</h6>
          <b-button variant="primary" @click="_onCloseBtnClick">
            {{ $t('Ok') }}
          </b-button>
        </div>
      </div>
    </transition>
  </div>

</template>

<script lang="ts">
import ContentModel from '@/content/_model/ContentModel';
import {Component, Prop, Vue} from 'vue-property-decorator';
import EntityModel from "@/entity/_model/EntityModel";
import languageManager, {IMultiLangTransValue} from "@/__libs/language_manager/LanguageManager";
import EntityControllerFactory from "@/entity/_controller/EntityControllerFactory";
import {ITranslationGroupDto, ITranslationMultiLangDto} from "@/_model/app.dto";
import EntityTranslationItemRenderer from "@/entity/_view/EntityTranslationItemRenderer.vue";
import {TranslationStatus} from "@/_model/app.constants";
import projectController from "@/project/_controller/ProjectController";
import dataProviderController from "@/data_tool/data_provider/_controller/DataProviderController";

enum EntityTranslationBoxState {
    IDLE,
    LOADING_TRANSLATIONS,
    ERROR_LOADING,
    TRANSLATIONS_INPUT,
    OPTIONS_INPUT,
    SAVING_TRANSLATIONS,
    TRANSLATIONS_SAVED
}

@Component({
    components: {EntityTranslationItemRenderer}
})
export default class EntityTranslationBox extends Vue {
    //---------------------------------
    // Vue Component props
    //---------------------------------

    @Prop() public entity!: EntityModel;

    //---------------------------------
    // Vue Component data
    //---------------------------------

    private entityTranslationBoxState: typeof EntityTranslationBoxState = EntityTranslationBoxState;
    private currentState: EntityTranslationBoxState = EntityTranslationBoxState.IDLE;

    private isAutoTranslating: boolean = false;

    public transGroups: ITranslationGroupDto[] = [];
    public sourceLanguage: string = "en";

    public targetLanguage: string = "en";

    private totalTranslations: number = 0;
    private currentTranslationIndex: number = 0;
    private percCompleted: number = 0;

    //---------------------------------
    // Vue Computed properties
    //---------------------------------

    get entityAsContent(): ContentModel {
        return this.entity as ContentModel;
    }

    get availableLanguages(): string[] {
        return languageManager.availableLangCodes;
    }

    //---------------------------------
    // Public / lifecycle methods
    //---------------------------------

    mounted() {
        this.getTranslations();
    }

    //---------------------------------
    // Private / helper methods
    //---------------------------------


    private async getTranslations() {
        this.currentState = EntityTranslationBoxState.LOADING_TRANSLATIONS;
        this.transGroups = await EntityControllerFactory.getController(this.entity.entityType).getTranslations(this.entity);
        this.currentState = EntityTranslationBoxState.TRANSLATIONS_INPUT;

    }

    private async _onCloseBtnClick(p_e: Event) {
        this.$emit('onFinish', null);
    }

    private async _onSaveBtnClick(p_e: Event) {
        this.currentState = EntityTranslationBoxState.SAVING_TRANSLATIONS;
        const saveSucceeded: boolean = await EntityControllerFactory.getController(this.entity.entityType).setTranslations(this.transGroups);
        this.currentState = EntityTranslationBoxState.TRANSLATIONS_SAVED;
    }

    private async _onExportBtnClick(p_e: Event) {
        await projectController.exportTranslation(this.transGroups, this.sourceLanguage, this.targetLanguage);

    }

    private async _onImportBtnClick(p_e: Event) {
        const importFileBtn: HTMLInputElement = this.$refs.importFileBtn as HTMLInputElement;
        if (importFileBtn.files && importFileBtn.files.length === 1) {
            const result = await projectController.importTranslation(importFileBtn.files[0], this.transGroups);

            // let msg: string;
            // if (result.isSuccessful) {
            //     msg = "Import successful";
            // } else {
            //     msg = "Import failed: " + result.fatalMessage!.type;
            // }
            // await this.$bvModal.msgBoxOk(msg, {centered: true});
        }
    }

    private async _onAutoTranslateBtnClick(p_e: Event) {
        this.isAutoTranslating = true;
        const transQueue: ITranslationMultiLangDto[] = [];

        for (let i = 0; i < this.transGroups.length; i++) {
            const group: ITranslationGroupDto = this.transGroups[i];
            for (let j = 0; j < group.translations.length; j++) {
                const trans: ITranslationMultiLangDto = group.translations[j];

                const transValue: IMultiLangTransValue = trans.multiLangTrans[this.targetLanguage];
                if (transValue.text.length === 0 && trans.multiLangTrans[this.sourceLanguage].text.length > 1) {
                    transValue.translationStatus = TranslationStatus.AUTO_TRANSLATION_PENDING;
                    transQueue.push(trans);
                }
            }
        }

        this.percCompleted = 0;
        for (let i = 0; i < transQueue.length; i++) {
            const trans: ITranslationMultiLangDto = transQueue[i];

            await projectController.translateText(trans, this.sourceLanguage, this.targetLanguage);
            this.totalTranslations = transQueue.length;
            this.currentTranslationIndex = i + 1;
            this.percCompleted = Math.round(100 / (this.totalTranslations / this.currentTranslationIndex));

        }
        this.isAutoTranslating = false;

    }

}
</script>

<!--<style>-->
<!--.source-lang-select {-->
<!--    width: 50px;-->
<!--}-->

<!--.target-lang-select {-->
<!--    width: 50px;-->
<!--    position: absolute;-->
<!--    right: 10px;-->
<!--}-->

<!--.trans-list {-->
<!--    max-height: 500px;-->
<!--    overflow: scroll;-->
<!--}-->

<!--.source-trans {-->
<!--    width: 250px;-->
<!--    background-color: #F0F0F0;-->
<!--    margin: 5px;-->
<!--    display: inline-block;-->
<!--    vertical-align: top;-->
<!--}-->

<!--.source-trans-text {-->
<!--    font-size: small;-->
<!--    overflow: hidden;-->
<!--    padding: 5px;-->
<!--}-->

<!--.trans-identifier {-->
<!--    font-size: x-small;-->
<!--    font-weight: bold;-->
<!--}-->

<!--.target-trans {-->
<!--    width: 280px;-->
<!--    display: inline-block;-->
<!--}-->

<!--.progress {-->
<!--    width: 150px;-->

<!--}-->
<!--</style>-->
