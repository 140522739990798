<template>
  <div class="push-notifications-popup">
    <h6 class="push-notifications-popup__subtitle" v-html="$t('GetPushNotificationsSubtitle')"></h6>
    <h3 class="push-notifications-popup__title mb-4" v-html="$t('GetPushNotificationsTitle')"></h3>
    <AAImage class="push-notifications-popup__image mb-4" imageUrl="img/push-notifications.png"></AAImage>
    <p class="push-notifications-popup__text mb-5" v-html="$t('GetPushNotificationsText')"></p>
    <div class="push-notifications-popup__buttons">
      <b-button variant="success" @click="requestPushNotifications">{{ $t('RequestPushNotificationsText') }}</b-button>
      <a class="skip" v-html="$t('DenyPushNotificationsText')" @click="denyNotifications"></a>
    </div>
  </div>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import AAImage from "@/_view/components/AAImage.vue";
import AppModel from "@/_model/AppModel";
import LocalStorageManager from "@/__libs/offline_storage/LocalStorageManager";
import {LocalStorageKey} from "@/_model/app.constants";
import appUserController from "@/project/user/_controller/AppUserController";
import JsonUtil from "@/__libs/utility/JsonUtil";

@Component({
    components: {AAImage}
})
export default class PushNotificationsPopup extends Vue {

    public appModel: AppModel = AppModel.getInstance();

    private async requestPushNotifications() {
        this.$bvModal.hide('push-notifications-popup');
        await appUserController.requestPushNotifications();
    }

    private denyNotifications() {
        LocalStorageManager.storeValue(LocalStorageKey.PUSH_NOTIFICATIONS_DENIED, JsonUtil.stringify(true));
        this.$bvModal.hide('push-notifications-popup');
    }

}
</script>
