<template>
  <div class="sub-page team-companies">
    <div class="columns">
      <CompaniesOverview :selState="selState"></CompaniesOverview>
      <CompanyDetail :selState="selState"></CompanyDetail>
    </div>
  </div>
</template>

<script lang="ts">
import {Component, Vue} from "vue-property-decorator";
import CreateCompanyBox from "@/company/_view/CreateCompanyBox.vue";
import companyController from "@/company/_controller/CompanyController";
import CompanyModel from "@/company/_model/CompanyModel";
import SelectionState from "@/__libs/_model/SelectionState";
import CompanyListModel from "@/company/_model/CompanyListModel";
import CompanyItemRenderer from "@/company/_view/CompanyItemRenderer.vue";
import CompanyDetail from "@/company/_view/CompanyDetail.vue";
import AppUserModel from "@/project/user/_model/AppUserModel";
import {RIGHTS} from "@/team/_model/role.constants";
import companyListController from "@/company/_controller/CompanyListController";
import {HintGroupIds, HintIds} from "@/help/hint/_model/hint.constants";
import Hint from "@/help/hint/_view/Hint.vue";
import HintListController from "@/help/hint/_controller/HintListController";
import CompaniesOverview from "@/company/_view/CompaniesOverview.vue";

@Component({
    components: {CompanyDetail, CompaniesOverview}
})
export default class CompaniesView extends Vue {
    public selState: SelectionState<CompanyModel> = CompanyListModel.getInstance().globalSelState;
}
</script>
