import SyncFileGroupProcess from "@/sync/_controller/process/SyncFileGroupProcess";

class SyncConstructProcess extends SyncFileGroupProcess
{

    //---------------------------------
    // Properties
    //---------------------------------

    protected _storagePath:string = "constructs";

    public dependencyLevel:number = 1;

    //---------------------------------
    // Controller Methods
    //---------------------------------




    //---------------------------------
    // Private Methods
    //---------------------------------

    protected _resolveDependencyProcesses() {

    }


}

//Singleton export
export default SyncConstructProcess;
