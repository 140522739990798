import {EntityType} from "@/entity/_model/entity.constants";
import {ApiAction} from "@/presentation/player/_model/player.constants";
<template>
    <div class="data-item-dictionary">

        <transition name="fade">
            <div v-if="currentState === dataProviderPickerState.NO_CONTENT_SELECTED && property.isEditable">
                <button class="btn btn-secondary btn-block btn-sm" @click="_onSelectContentBtnClick">Select content
                </button>
            </div>
        </transition>

        <transition name="fade">
            <div v-if="currentState === dataProviderPickerState.LOADING"
                 class="loading d-flex justify-content-center align-items-center">
                <b-spinner variant="dark"></b-spinner>
            </div>
        </transition>

        <transition name="fade">
            <div v-if="currentState === dataProviderPickerState.ERROR_LOADING"
                 class="placeholder-text text-danger">
                {{ $t('ContentNotAvailableToPresent') }}
            </div>
        </transition>

        <transition name="fade">
            <div v-if="currentState === dataProviderPickerState.LOADED">
                <h5 class="mb-3">{{contentName()}}</h5>
                <!--                todo @TomA implement AAimage correctly-->
                <AAImage class="content-item__background__image" :imageUrl="contentPoster"/>
                <button class="btn btn-secondary btn-block btn-sm" @click="_onSelectContentBtnClick">Change content
                </button>
            </div>
        </transition>

        <b-modal id="link-wizard-box" size="xl" :title="$t('LinkWizard')" hide-footer>
            <LinkWizardBox
                    :linkWizardDto="linkWizardDto"
                    v-on:onLinkChosen="_onLinkChosen"></LinkWizardBox>
        </b-modal>
    </div>
</template>


<script lang="ts">
    import {Component, Prop, Vue, Watch} from "vue-property-decorator";
    import PropertyModel from "@/data_tool/data_item/_model/PropertyModel";
    import SelectDataProviderBox from "@/data_tool/data_item/_view/SelectDataItemListBox.vue";
    import languageManager, {IMultiLangString} from "@/__libs/language_manager/LanguageManager";
    import DataItemContentLinkModel from "@/data_tool/data_item/_model/DataItemContentLinkModel";
    import {ILinkValueDto, ILinkWizardDto} from "@/presentation/_model/presentation.dto";
    import LinkWizardBox from "@/content/_view/LinkWizardBox.vue";
    import ContentModel from "@/content/_model/ContentModel";
    import ContentListModel from "@/content/_model/ContentListModel";
    import AAImage from "@/_view/components/AAImage.vue";
    import {StorageScope} from "@/_model/app.constants";
    import {ApiAction} from "@/presentation/player/_model/player.constants";
    import {EntityType} from "@/entity/_model/entity.constants";

    enum ContentPickerState
    {
        IDLE,
        NO_CONTENT_SELECTED,
        LOADING,
        ERROR_LOADING,
        LOADED
    }

    @Component({
        components: {
            SelectDataItemListBox: SelectDataProviderBox,
            LinkWizardBox        : LinkWizardBox,
            AAImage              : AAImage,
            DataItem             : () => import('@/data_tool/data_item/_view/DataItem.vue')
        }
    })
    export default class ContentPicker extends Vue
    {
        //---------------------------------
        // Vue Component props
        //---------------------------------

        @Prop() private property!:PropertyModel;

        @Prop() private dataItemContentLinkModel!:DataItemContentLinkModel;


        //---------------------------------
        // Vue Component data
        //---------------------------------


        private dataProviderPickerState:typeof ContentPickerState = ContentPickerState;
        private currentState:ContentPickerState = ContentPickerState.IDLE;

        public linkWizardDto:ILinkWizardDto = {allowOpenContent: true} as ILinkWizardDto;

        private content:ContentModel | null = null;

        //---------------------------------
        // Vue Computed properties
        //---------------------------------


        //---------------------------------
        // Public / lifecycle methods
        //---------------------------------


        public async created()
        {
            // console.log(this.property.propertyDefinition.identifier, this.property.propertyDefinition);
        }

        public async mounted()
        {

        }

        //---------------------------------
        // Private / helper methods
        //---------------------------------


        //this is the actual entrypoint of this component, is called after mount and whenever another property is set
        @Watch('property', {immediate: true, deep: false})
        private async _onActivePropertyChange(newProperty:PropertyModel | null, oldProperty:PropertyModel | null)
        {
            //check if content needs to be selected or loaded
            if (this.dataItemContentLinkModel.contentID)
            {
                await this.loadContent(this.dataItemContentLinkModel.contentID);
            }
            else
            {
                this.currentState = ContentPickerState.NO_CONTENT_SELECTED;
            }
        }


        private async loadContent(p_contentID:string)
        {
            this.currentState = ContentPickerState.LOADING;
            this.content = ContentListModel.getInstance().getEntityByID(p_contentID);
            if (this.content)
            {
                this.currentState = ContentPickerState.LOADED;
            }
            else
            {
                this.currentState = ContentPickerState.ERROR_LOADING;
            }
        }


        private async _onSelectContentBtnClick(p_e:Event)
        {
            if (this.content)
            {
                // set the link value in the format the link wizard understands
                let apiAction:ApiAction | null = null;
                switch (this.content.entityType)
                {
                    case EntityType.PRESENTATION:
                        apiAction = ApiAction.OPEN_PRESENTATION;
                        break;
                    case EntityType.CONTENT_FILE:
                        apiAction = ApiAction.OPEN_CONTENT_FILE;
                        break;
                    case EntityType.CONTENT_APP:
                        apiAction = ApiAction.OPEN_CONTENT_APP;
                        break;
                }
                if (apiAction)
                {
                    this.linkWizardDto.linkValue = {value: this.content.ID, apiAction: apiAction}
                }
                else
                {
                    this.linkWizardDto.linkValue = undefined;
                }
            }
            this.$bvModal.show('link-wizard-box');
        }


        private async _onLinkChosen(p_linkValue:ILinkValueDto)
        {
            this.$bvModal.hide("link-wizard-box");

            if (p_linkValue && p_linkValue.value !== this.dataItemContentLinkModel.contentID)
            {
                this.dataItemContentLinkModel.contentID = p_linkValue.value as string | null;
                if (this.dataItemContentLinkModel.contentID)
                {
                    await this.loadContent(this.dataItemContentLinkModel.contentID);
                }
            }
        }



        public contentName()
        {
            if (this.content)
            {
                return languageManager.getTranslationForValue<string>(this.content.name as IMultiLangString, this.property.dataProvider.activeLangCode);
            }
        }

        public contentPoster()
        {
            if (this.content)
            {
                return languageManager.getTranslationForValue<string>(this.content.poster as IMultiLangString, this.property.dataProvider.activeLangCode);
            }
        }

    }
</script>
