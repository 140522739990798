import Model from "@/__libs/_model/Model";
import {IHintDto} from "@/help/hint/_model/hint.dto";

class HintModel extends Model implements IHintDto {

    public id: string = "";
    public groupId: string = "";
    public shown: boolean = false;

}

export default HintModel;
