<template>
  <div class="entity-action-box playlist-action-box text-center">

    <div v-if="audience">
      <AAProfilePicture :name="audience.displayName"
                        :imageUri="audience.avatarFileUri" :big="true"></AAProfilePicture>
      <h6 class="title mb-4" v-line-clamp="2">{{ audience.displayName }}</h6>
      <b-button v-if="showPresent && isAudienceOnline" :disabled="!hasJoinedRoom" block variant="success"
                @click="_onPresentContentBtnClick">
        <i class="fas fa-chalkboard-teacher fa-lg"></i>{{ $t('EntityActionBoxPresentRemote') }}
      </b-button>
      <template v-else>
        <b-button v-if="showPresent" block variant="success" @click="_onPresentContentBtnClick">
          <i class="fas fa-chalkboard-teacher fa-lg"></i>{{ $t('EntityActionBoxPresent') }}
        </b-button>
      </template>
      <b-button block variant="primary"
                @click="_onShareWithAudiencesBtnClick">
        <i class="fas fa-external-link-alt fa-lg"></i>{{ $t('EntityActionBoxShare') }}
      </b-button>
    </div>
    <b-button v-if="showEdit" block variant="outline-secondary" class="mt-2" @click="_onEditPlaylistBtnClick">
      <i class="fas fa-pen-to-square fa-lg"></i>{{ $t('DashboardShowEditButton') }}
    </b-button>
    <b-button v-if="showDelete" block variant="outline-secondary" @click="_onDeletePlaylistBtnClick">
      <i class="fas fa-trash-can fa-lg"></i>{{ $t('DashboardShowDeleteButton') }}
    </b-button>
    <!--    <b-button v-if="showEdit" block variant="outline-secondary" @click="_onShareWithTeamsBtnClick">-->
    <!--      <i class="fas fa-people-group fa-lg"></i>{{ $t('DashboardShowShareWithTeamButton') }}-->
    <!--    </b-button>-->
    <!--    <b-button block variant="outline-secondary" @click="_onInfoBtnClick">-->
    <!--      <i class="fas fa-circle-info fa-lg"></i>{{ $t('Info') }}-->
    <!--    </b-button>-->
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import {EventBus} from "@/__libs/vue/EventBus";
import {EventBusActions} from "@/_model/app.constants";
import PlaylistModel from "@/playlist/_model/PlaylistModel";
import EntitySelectionState from "@/entity/_model/EntitySelectionState";
import AudienceModel from "@/audience/_model/AudienceModel";
import AudienceListModel from "@/audience/_model/AudienceListModel";
import AppUserModel from "@/project/user/_model/AppUserModel";
import {RightAction, RIGHTS} from "@/team/_model/role.constants";
import playlistController from "@/playlist/_controller/PlaylistController";
import ContentModel from "@/content/_model/ContentModel";
import ContentListModel from "@/content/_model/ContentListModel";
import {ENTITY_CONFIG, EntityType, IEntityTypeConfig} from "@/entity/_model/entity.constants";
import ContentAppModel from "@/content_app/_model/ContentAppModel";
import {
    CobrowseUserType
} from "@sales-drive/audience-library/lib/cobrowse_manager/cobrowse_user/_model/cobrowse_user.constants";
import {CobrowseRoomType} from "@sales-drive/audience-library/lib/cobrowse_manager/room/_model/room.constants";
import NetworkManager from "@/_controller/NetworkManager";
import CobrowseManager from "@sales-drive/audience-library/lib/cobrowse_manager/CobrowseManager";
import AAProfilePicture from "@/_view/components/AAProfilePicture.vue";

@Component({
    components: {
        AAProfilePicture
    }
})
export default class PlaylistActionBox extends Vue {

    @Prop() public playlist!: PlaylistModel;

    public networkManager: NetworkManager = NetworkManager.getInstance();
    public cobrowseManager: CobrowseManager = CobrowseManager.getInstance();
    public appUserModel: AppUserModel = AppUserModel.getInstance();

    get showShareWithAudience(): boolean {
        return AppUserModel.getInstance().rights.indexOf(RIGHTS.SHARE_ENTITIES_WITH_AUDIENCES.identifier) >= 0;
    }

    get showShareWithMultipleAudiences(): boolean {
        const globalSelState: EntitySelectionState<AudienceModel> = AudienceListModel.getInstance().globalSelState;
        const shareWithMultiple: boolean = globalSelState.hasMultipleSelected && globalSelState.selecteds.length > 0;
        return this.showShareWithAudience && shareWithMultiple;
    }

    get showEdit(): boolean {
        return this.playlist.isEditable;
    }

    get showDelete(): boolean {
        return this.playlist.isEditable;
    }


    get online() {
        return this.networkManager.online;
    }

    get audience(): AudienceModel | null {
        return AudienceListModel.getInstance().globalSelState.selected;
    }


    get showPresent(): boolean {
        return true;
    }


    get isAudienceOnline(): boolean {
        if (this.cobrowseManager.projectRoom && this.audience) {
            const users = this.cobrowseManager.projectRoom!.roomUsersSO!.data.users;
            return users.findIndex((user) => user.cobrowseUserID === `${CobrowseUserType.AUDIENCE}-${this.audience!.ID}`) > -1;
        }
        return false;
    }

    get hasJoinedRoom(): boolean {
        if (this.audience) {
            const roomIdentifier: string = `${this.appUserModel.project.identifier}:${CobrowseRoomType.PORTAL}:${this.audience?.ID}`;
            return this.cobrowseManager.rooms.indexOf(roomIdentifier) >= 0;
        }
        return false;
    }


    // private _onInfoBtnClick(p_e: Event) {
    //     EventBus.$emit(EventBusActions.ASK_SHOW_PLAYLIST_INFO, this.playlist);
    // }

    private _onShareWithAudiencesBtnClick(p_e: Event) {
        if (this.showShareWithMultipleAudiences) {
            EventBus.$emit(EventBusActions.ASK_SHARE_PLAYLIST_WITH_AUDIENCES, this.playlist);

        } else {
            EventBus.$emit(EventBusActions.ASK_SHARE_PLAYLIST_WITH_AUDIENCE, this.playlist);

        }
    }

    // private _onShareWithTeamsBtnClick(p_e: Event) {
    //     EventBus.$emit(EventBusActions.ASK_SHARE_PLAYLIST_WITH_TEAMS, this.playlist);
    // }

    private _onEditPlaylistBtnClick(p_e: Event) {
        EventBus.$emit(EventBusActions.ASK_CREATE_EDIT_PLAYLIST, this.playlist);
    }

    private async _onDeletePlaylistBtnClick(p_e: Event) {
        EventBus.$emit(EventBusActions.ASK_DELETE_PLAYLIST, this.playlist);
    }

    private async _onPresentContentBtnClick(p_e: Event) {
        EventBus.$emit(EventBusActions.ASK_PRESENT_PLAYLIST, this.playlist);
    }


}
</script>
