<template>
  <div class="min-width-keeper">
    <div class="wrapper">
      <p class="min-width-keeper__title" v-html="$t('MinWidthText1')"></p>
      <p class="min-width-keeper__text" v-html="$t('MinWidthText2')"></p>
      <p class="min-width-keeper__adding"><span v-html="$t('MinWidthText3')"></span><i class="fas fa-expand-alt"></i></p>
      <AAImage image-url="img/min-width.png"></AAImage>
    </div>
  </div>
</template>

<script lang="ts">
import {Component, Vue} from "vue-property-decorator";
import AAImage from "@/_view/components/AAImage.vue";

@Component({
  components: {AAImage}
})
export default class MinWidthKeeper extends Vue {
}
</script>


