
import Model from "@/__libs/_model/Model";
import SelectionState from "@/__libs/_model/SelectionState";

class ListModel<T extends Model>
{

    public list:T[] = [];

    // private _selectionStates:ItemSelectionState<T>[] = [];
    public globalSelState:SelectionState<T> = new SelectionState<T>();


    private _isFetchingList:boolean = false;
    get isFetchingList():boolean
    {
        return this._isFetchingList;
    }

    private _lastListFetch:Date = new Date(0);

    protected _minTimeBetweenFetchMs:number = 1 * 60 * 1000; //don't allow the list to be fetched

    private _isFetchListAllowed:boolean = false;
    get isFetchListAllowed():boolean
    {
        return !this.isFetchingList && new Date().getTime() - this._lastListFetch.getTime() > this._minTimeBetweenFetchMs;
    }




    //---------------------------------
    // Constructor
    //---------------------------------

    constructor()
    {
        // this.registerItemSelectionState(this.globalSelState);
    }


    //---------------------------------
    // Private Methods
    //---------------------------------



    //---------------------------------
    // Public Methods
    //---------------------------------
    public empty()
    {
        this.list.splice(0, this.list.length);
    }

    public removeItem(p_item:T):boolean
    {
        const index:number = this.list.indexOf(p_item);
        if(index >= 0)
        {
            this.list.splice(index, 1);
            //todo: we should do this for all _selectionStates (but then we need registerItemSelectionState)
            if(this.globalSelState.selected === p_item)
            {
                this.globalSelState.selected = null;
            }
            return true;
        }
        return false;
    }


    public startListFetch()
    {
        this._isFetchingList = true;
    }

    public stopListFetch()
    {
        this._lastListFetch = new Date();
        this._isFetchingList = false;
    }

    /*
    public getItemByID(p_ID:string):T | null
    {
        for (let i = 0; i < this.list.length; i++)
        {
            const item = this.list[i];
            if (item.ID === p_ID)
            {
                return item;
            }
        }
        return null;
    }*/

    // public registerItemSelectionState(p_selState:ItemSelectionState<T>)
    // {
    //     this._selectionStates.push(p_selState);
    // }



}

export default ListModel;
