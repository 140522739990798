<template>

  <div class="share-with-audience">

    <transition name="fade">

      <div v-if="currentFormState === formState.CHOOSE_SHAREABLE_FILES">

        <p class="mb-3" v-if="shareFolder">{{ $t('ChooseFilesYouWantToShareFromFolder') }}:</p>
          <p class="mb-3" v-if="sharePlaylist">{{ $t('ChooseFilesYouWantToShareFromPlaylist') }}:</p>

          <div class="viewable-teams mb-4">
          <b-form-checkbox v-for="content in shareableContent" :key="content.ID"
                           :value="content.ID"
                           v-model="sharedContent" class="mr-3 mb-1">
            {{ getTranslation(content.name) }}
          </b-form-checkbox>
        </div>
        <div class="d-flex justify-content-end">
          <b-button variant="primary" @click="_onNextBtnClick"
                    :disabled="sharedContent.length === 0">{{
              $t('Next')
            }}
          </b-button>
        </div>

      </div>

      <div v-if="currentFormState === formState.FORM_INPUT || currentFormState === formState.TRY_SUBMIT">
        <b-form @submit.stop.prevent="_onShareBtnClick" novalidate>

          <b-form-group v-if="!shareWithMultiple" class="mt-2 mb-4">
            <b-form-radio-group v-model="shareBy" name="share-by">
              <b-form-radio :value="shareByChoice.SHARE_BY_LINK">
                {{ $t('AudienceShareTypes_Link') }}
              </b-form-radio>
              <b-form-radio :value="shareByChoice.SHARE_BY_EMAIL">
                {{ $t('AudienceShareTypes_Email') }}
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>

          <div v-if="shareBy === shareByChoice.SHARE_BY_EMAIL" class="mb-4">
            <b-form-group>
              <b-form-input v-model="shareDto.communication.payload.subject"
                            :placeholder="$t('MessageSubjectTitle')"
                            :state="_calculateInputState($v.shareDto.communication.payload.subject)"
                            tabindex="1"></b-form-input>
              <b-form-invalid-feedback>
                        <span class="d-block"
                              v-if="!$v.shareDto.communication.payload.subject.required">{{
                            $t('FieldRequiredSimple')
                          }}</span>
                <span class="d-block" v-if="!$v.shareDto.communication.payload.subject.minLength">{{
                    $t('MinimumFieldLengthSimple', [3])
                  }}</span>
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group>
              <b-form-textarea v-model="shareDto.communication.payload.body"
                               :placeholder="$t('MessageBodyTitle')"
                               :state="_calculateInputState($v.shareDto.communication.payload.body)"
                               rows="7"
                               tabindex="2"></b-form-textarea>
              <b-form-invalid-feedback>
                        <span class="d-block"
                              v-if="!$v.shareDto.communication.payload.body.required">{{
                            $t('FieldRequiredSimple')
                          }}</span>
                <span class="d-block" v-if="!$v.shareDto.communication.payload.body.minLength">{{
                    $t('MinimumFieldLengthSimple', [3])
                  }}</span>
              </b-form-invalid-feedback>
            </b-form-group>
          </div>

          <div class="d-flex align-items-center">
            <b-form-checkbox v-model="shareDto.doesExpire" class="mr-3" tabindex="3">{{
                $t('DashboardShowShareExpiryDate')
              }}
            </b-form-checkbox>
            <date-picker v-model="shareDto.expirationDate" :disabled="!shareDto.doesExpire"></date-picker>
          </div>

          <b-form-checkbox v-model="shareDto.notifyViewed" class="mb-1" tabindex="4">{{
              $t('DashboardShowShareNotifyWhenViewed')
            }}
          </b-form-checkbox>

          <b-form-checkbox v-model="shareDto.isShownInFolders" class="mb-1" tabindex="5">{{
              $t('ShowContentInItsFolders')
            }}
          </b-form-checkbox>

          <b-form-checkbox v-if="shareBy === shareByChoice.SHARE_BY_EMAIL"
                           v-model="shareDto.communication.payload.putMeInCC" tabindex="6">{{
              $t('DashboardShowShareCcMe')
            }}
          </b-form-checkbox>

          <div class="d-flex justify-content-end mt-4">
            <b-button variant="secondary" class="mr-2" @click="_onCancelBtnClick" tabindex="7">
              {{ $t('Cancel') }}
            </b-button>
            <b-button v-if="shareFolder" variant="secondary" class="mr-2" @click="_onPreviousBtnClick"
                      tabindex="8">
              {{ $t('Previous') }}
            </b-button>
            <b-button type="submit" variant="primary"
                      :disabled="currentFormState === formState.TRY_SUBMIT && $v.$invalid" tabindex="9">{{
                $t('DashboardShowShareWithAudienceButton')
              }}
            </b-button>
          </div>

        </b-form>

      </div>
    </transition>

    <transition name="fade">
      <div v-if="currentFormState === formState.REMOTE_CALL" class="text-center">
        <b-spinner variant="dark" class="my-5"></b-spinner>
      </div>
    </transition>

    <transition name="fade">
      <div v-if="currentFormState === formState.REMOTE_CALL_PROGRESS" class="text-center">
        <b-progress variant="secondary" :value="percCompleted"
                    class="mb-3"></b-progress>
      </div>
    </transition>

    <transition name="fade">
      <div v-if="currentFormState === formState.REMOTE_EMAIL_SHARED">
        <p>{{ $t('ShareByEmailSuccess') }}</p>
        <div class="d-flex justify-content-end">
          <b-button variant="primary" @click="_onCancelBtnClick" tabindex="1">
            {{ $t('Ok') }}
          </b-button>
        </div>
      </div>
    </transition>

    <transition name="fade">
      <div v-if="currentFormState === formState.REMOTE_EMAIL_SHARED_MULTIPLE">
        <p>{{ $t('ShareMultipleByEmailSuccess') }}</p>
        <ul>
          <li v-for="aud in audiences" :key="aud.ID">
            {{ aud.displayName }} <i>({{ aud.email }})</i>
          </li>
        </ul>

        <div class="d-flex justify-content-end">
          <b-button variant="primary" @click="_onCancelBtnClick" tabindex="1">
            {{ $t('Ok') }}
          </b-button>
        </div>
      </div>
    </transition>

    <transition name="fade">
      <div v-if="currentFormState === formState.REMOTE_LINK_SHARED">
        <p>{{ $t('ShareByLinkSuccess') }}</p>

        <b-input-group class="mb-3">
          <b-form-input :value="portalLink" ref="portalLink"></b-form-input>
          <b-input-group-append>
            <b-button variant="success" @click="_onCopyLinkBtnClick">{{
                $t('DashboardShowShareWithAudienceLinkConfirmationCopyButton')
              }}
            </b-button>
          </b-input-group-append>
        </b-input-group>

        <transition name="fade">
          <div v-if="currentCopyToClipboardState === copyToClipboardState.SUCCESS"
               class="text-success small mb-3">{{ $t('ClipboardSuccess') }}
          </div>
        </transition>
        <transition name="fade">
          <div v-if="currentCopyToClipboardState === copyToClipboardState.ERROR"
               class="text-danger small mb-3">{{ $t('ClipboardError') }}
          </div>
        </transition>

        <a :href="`mailto:${audienceEMail}?subject=${$t('MessageSubjectTitle')}&body=${encodeURIComponent(portalLink)}`"
           class="btn btn-secondary btn-block mb-3">Open e-mail client</a>

        <div class="d-flex justify-content-end">
          <b-button variant="primary" @click="_onCancelBtnClick" tabindex="1">
            {{ $t('Ok') }}
          </b-button>
        </div>
      </div>
    </transition>

    <transition name="fade">
      <div v-if="currentFormState === formState.REMOTE_ERROR">
        <p class="mb-4 text-danger">We couldn't send the share at this moment, we will remember and try again as
          soon as we have internet connection!</p>
        <div class="d-flex justify-content-end">
          <button class="btn btn-secondary mr-2" @click="_onCancelBtnClick">{{ $t('Ok') }}</button>
        </div>
      </div>

    </transition>

  </div>

</template>

<script lang="ts">

import {Component, Prop, Vue} from 'vue-property-decorator';
import AudienceModel from "@/audience/_model/AudienceModel";
import AppUserModel from "@/project/user/_model/AppUserModel";
import contentController from "@/content/_controller/ContentController";
import {ICreateShareResultDto, IEntityAudienceShareDto} from "@/entity/_model/entity.dto";
import {ApiResponse} from "@/_controller/ApiManager";
import AppModel from "@/_model/AppModel";
import ContentModel from "@/content/_model/ContentModel";
import DatePicker from "vue2-datepicker";
import {minLength, required} from 'vuelidate/lib/validators';
import AudienceListModel from "@/audience/_model/AudienceListModel";
import languageManager, {IMultiLangString} from "@/__libs/language_manager/LanguageManager";
import EntitySelectionState from "@/entity/_model/EntitySelectionState";
import ContentFolderModel from "@/content/_model/ContentFolderModel";
import ContentListModel from "@/content/_model/ContentListModel";
import PlaylistModel from "@/playlist/_model/PlaylistModel";

enum ShareByChoice {
    SHARE_BY_EMAIL,
    SHARE_BY_LINK
}

enum FormState {
    UNMOUNTED,
    CHOOSE_SHAREABLE_FILES,
    FORM_INPUT,
    TRY_SUBMIT,
    REMOTE_CALL,
    REMOTE_CALL_PROGRESS,
    REMOTE_EMAIL_SHARED,
    REMOTE_EMAIL_SHARED_MULTIPLE,
    REMOTE_LINK_SHARED,
    REMOTE_ERROR
}

enum CopyToClipboardState {
    IDLE,
    SUCCESS,
    ERROR
}

@Component({
    components: {
        DatePicker
    },
    validations() {
        return {
            shareDto: {
                communication: {
                    payload: {
                        subject: {
                            required,
                            minLength: minLength(3)
                        },
                        body: {
                            required,
                            minLength: minLength(3)
                        }
                    }
                }
            }
        }
    }
})
export default class ShareWithAudienceBox extends Vue {


    //---------------------------------
    // Vue Component props
    //---------------------------------

    @Prop() public content!: ContentModel;
    @Prop() public folder!: ContentFolderModel;
    @Prop() public playlist!: PlaylistModel;
    @Prop() public shareMessage?: string;
    @Prop() public shareWithMultiple?: boolean;
    @Prop() public shareFolder?: boolean;
    @Prop() public sharePlaylist?: boolean;

    //---------------------------------
    // Vue Component data
    //---------------------------------

    private formState: typeof FormState = FormState;

    private shareByChoice: typeof ShareByChoice = ShareByChoice;

    private copyToClipboardState: typeof CopyToClipboardState = CopyToClipboardState;

    private shareBy: ShareByChoice = ShareByChoice.SHARE_BY_LINK;

    private shareDto: IEntityAudienceShareDto = {
        audienceID: "",
        userID: AppUserModel.getInstance().ID,
        doesExpire: false,
        expirationDate: new Date(Date.now() + 12096e5),
        notifyExpiration: false,
        notifyViewed: true,
        isShownInFolders: false,
        communication: {
            payload: {
                subject: '',
                body: '',
                putMeInCC: false,
                langCode: ""
            }
        }
    };

    private currentFormState: FormState = FormState.FORM_INPUT;
    private currentCopyToClipboardState: CopyToClipboardState = CopyToClipboardState.IDLE;

    private portalLink: string = "";


    private audience!: AudienceModel;
    private audiences: AudienceModel[] = [];

    private sharedContent: string[] = [];

    private percCompleted: number = 0;
    //---------------------------------
    // Vue Computed properties
    //---------------------------------

    get contentName() {
        return languageManager.getTranslationForValue(
          this.content.name,
          this.audienceLangCode
        );
    }

    get folderName() {
        return languageManager.getTranslationForValue(
          this.folder.body.name,
          this.audienceLangCode
        );
    }

    get playlistName() {
        return languageManager.getTranslationForValue(
            this.playlist.body.name,
            this.audienceLangCode
        );
    }
    get audienceLangCode(): string | null {
        return this.audience ? this.audience.langCode : null;
    }

    get audienceEMail(): string | null {
        return this.audience ? this.audience.email : '';
    }

    get shareableContent() {
        if(this.shareFolder)
        {
            return this.folder.getAllShareableContentInFolder();
        }else if(this.sharePlaylist){
            return this.playlist.getAllShareableContentInPlaylist();
        }
        return [];
    }

    //---------------------------------
    // Public / lifecycle methods
    //---------------------------------


    mounted() {
        if(this.shareWithMultiple)
        {
          //if you share with multiple audiences, you can only send by email (because you can't show multiple links)
               this.shareBy = ShareByChoice.SHARE_BY_EMAIL;
        }
        if (this.shareFolder) {
            this.currentFormState = FormState.CHOOSE_SHAREABLE_FILES;
            const shareableContent = this.folder.getAllShareableContentInFolder();
            for (let i = 0; i < shareableContent.length; i++) {
                this.sharedContent.push(shareableContent[i].ID);
            }
            this.shareDto.sharedThroughFolderID = this.folder.folderID;
            this.shareDto.isShownInFolders = true;
        }else  if (this.sharePlaylist) {
            this.currentFormState = FormState.CHOOSE_SHAREABLE_FILES;
            const shareableContent = this.playlist.getAllShareableContentInPlaylist();
            for (let i = 0; i < shareableContent.length; i++) {
                this.sharedContent.push(shareableContent[i].ID);
            }
        }
        else {
            this.currentFormState = FormState.FORM_INPUT;
        }

        const globalSelState: EntitySelectionState<AudienceModel> = AudienceListModel.getInstance().globalSelState;

        this.audiences = globalSelState.selecteds;

        //needed as a "default" audience to choose eg language for body translation and contentName
        this.audience = globalSelState.selected ? globalSelState.selected : this.audiences[0];

        this.shareDto.communication!.payload!.langCode = this.audience.langCode;


        this.shareDto.communication!.payload!.subject = this.$t('DashboardShowShareWithAudienceSubjectPreFill', [AppUserModel.getInstance().displayName]) as string;
        if (this.shareMessage && this.shareMessage.length > 0) {
            this.shareDto.communication!.payload!.body = this.shareMessage;
        } else {
            const audiencePlaceHolder: string = this.shareWithMultiple ? "[[audience]]" : this.audience.displayName;
            let subjectName:string = "";
            if(this.shareFolder){
                subjectName = this.folderName;
            }else if(this.sharePlaylist){
                subjectName = this.playlistName;
            }else{
                subjectName = this.contentName;
            }

            this.shareDto.communication!.payload!.body = this.$t('DashboardShowShareWithAudienceBodyPreFill', [audiencePlaceHolder, subjectName, AppUserModel.getInstance().displayName]) as string;
        }

    }

    //---------------------------------
    // Private / helper methods
    //---------------------------------

    private _calculateInputState(inputValidator: any): any {
        return this.currentFormState === FormState.TRY_SUBMIT && inputValidator.$invalid ? false : null;
    }

    private async _doSingleContentShare() {

        if (this.shareWithMultiple) {

            this.currentFormState = FormState.REMOTE_CALL_PROGRESS;
            this.percCompleted = 0;
            const body: string = this.shareDto.communication!.payload!.body;

            let allShared: boolean = true;
            for (let i = 0; i < this.audiences.length; i++) {
                const audience = this.audiences[i];

                this.shareDto.audienceID = audience.ID;
                this.shareDto.communication!.payload!.body = body.split("[[audience]]").join(audience.displayName);

                // await Util.timeOut(1000);
                const response: ApiResponse<ICreateShareResultDto> = await contentController.shareContentWithAudience(this.content, this.shareDto);

                if (!response.hasSucceeded) {
                    allShared = false;
                    break;
                }
                this.percCompleted = 100 / (this.audiences.length / (i + 1));
            }
            this.currentFormState = allShared ? FormState.REMOTE_EMAIL_SHARED_MULTIPLE : FormState.REMOTE_ERROR;

        } else {

            this.shareDto.audienceID = this.audience.ID;

            if (this.shareBy === ShareByChoice.SHARE_BY_LINK) {
                delete this.shareDto.communication;
            }

            this.currentFormState = FormState.REMOTE_CALL;
            const response: ApiResponse<ICreateShareResultDto> = await contentController.shareContentWithAudience(this.content, this.shareDto);

            if (response.hasSucceeded) {
                if (this.shareBy === ShareByChoice.SHARE_BY_EMAIL) {
                    this.currentFormState = FormState.REMOTE_EMAIL_SHARED;
                } else {
                    this.portalLink = `${AppModel.getInstance().global.storageUrl}/portal/index.html?apiUrl=${AppUserModel.getInstance().project.apiUrl}&portalToken=${this.audience.portalToken}`;

                    if (response.result && response.result.shortLinkID) {
                        this.portalLink = `${AppUserModel.getInstance().project.apiUrl}/sl/${response.result.shortLinkID}`;
                    }
                    this.currentFormState = FormState.REMOTE_LINK_SHARED;
                }
            } else {
                this.currentFormState = FormState.REMOTE_ERROR;
            }

        }
    }

    private async _doMultipleContentShare() {
        if (this.shareWithMultiple) {
            this.currentFormState = FormState.REMOTE_CALL_PROGRESS;
            this.percCompleted = 0;
            const body: string = this.shareDto.communication!.payload!.body;

            let allShared: boolean = true;
            let sentShares: number = 0;
            audienceLoop:
              for (let i = 0; i < this.audiences.length; i++) {
                  const audience = this.audiences[i];

                  this.shareDto.audienceID = audience.ID;
                  this.shareDto.communication!.payload!.body = body.split("[[audience]]").join(audience.displayName);
                  delete this.shareDto.communication?.communicationID;

                  for (let j = 0; j < this.sharedContent.length; j++) {
                      const content = ContentListModel.getInstance().getEntityByID(this.sharedContent[j]);
                      // await Util.timeOut(300);
                      const response: ApiResponse<ICreateShareResultDto> = await contentController.shareContentWithAudience(content as ContentModel, this.shareDto);
                      if (!response.hasSucceeded) {
                          allShared = false;
                          break audienceLoop;
                      }
                      if (j === 0) {
                          //if it's the first share and it's by email, take it's communicationID, and send it with the following shares to this audience, so those shares get linked to that comm on the backend
                          this.shareDto.communication!.communicationID = response.result!.communicationID;
                      }
                      // console.log("sharing ", audience.displayName, content!.name["en"], this.shareDto.communication);
                      sentShares++;
                      this.percCompleted = 100 / ((this.audiences.length * this.sharedContent.length) / sentShares);
                  }
              }
            this.currentFormState = allShared ? FormState.REMOTE_EMAIL_SHARED_MULTIPLE : FormState.REMOTE_ERROR;
        } else {
            this.shareDto.audienceID = this.audience.ID;
            if (this.shareBy === ShareByChoice.SHARE_BY_LINK) {
                delete this.shareDto.communication;
            }

            this.currentFormState = FormState.REMOTE_CALL_PROGRESS;
            this.percCompleted = 0;
            let allShared: boolean = true;
            for (let i = 0; i < this.sharedContent.length; i++) {
                const content = ContentListModel.getInstance().getEntityByID(this.sharedContent[i]);
                const response: ApiResponse<ICreateShareResultDto> = await contentController.shareContentWithAudience(content as ContentModel, this.shareDto);

                if (!response.hasSucceeded) {
                    allShared = false;
                    break;
                }
                if (i === 0) {
                    //if it's the first share and it's by email, take it's communicationID, and send it with the following shares, so those shares get linked to that comm on the backend
                    if (this.shareBy === ShareByChoice.SHARE_BY_EMAIL) {
                        this.shareDto.communication!.communicationID = response.result!.communicationID;
                    }
                    //and also set the portal link
                    this.portalLink = `${AppModel.getInstance().global.storageUrl}/portal/index.html?apiUrl=${AppUserModel.getInstance().project.apiUrl}&portalToken=${this.audience.portalToken}&folderID=${this.shareDto.sharedThroughFolderID}`;
                    if (response.result && response.result.shortLinkID) {
                        this.portalLink = `${AppUserModel.getInstance().project.apiUrl}/sl/${response.result.shortLinkID}`;
                    }
                }
                this.percCompleted = 100 / (this.sharedContent.length / (i + 1));
            }

            if (allShared) {
                if (this.shareBy === ShareByChoice.SHARE_BY_EMAIL) {
                    this.currentFormState = FormState.REMOTE_EMAIL_SHARED;
                } else {
                    this.currentFormState = FormState.REMOTE_LINK_SHARED;
                }
            } else {
                this.currentFormState = FormState.REMOTE_ERROR;
            }
        }
    }

    private async _doShare() {
        if (this.shareFolder || this.sharePlaylist) {
            await this._doMultipleContentShare();
        } else {
            await this._doSingleContentShare();
        }
    }

    private async _onShareBtnClick(p_e: Event) {
        this.currentFormState = FormState.TRY_SUBMIT;
        if (!this.$v.$invalid || this.shareBy === ShareByChoice.SHARE_BY_LINK) {
            this._doShare();
        }
    }

    private async _onRetryBtnClick(p_e: Event) {
        this._doShare();
    }

    private async _onCancelBtnClick(p_e: Event) {
        this.$emit('onFinish', null);
    }

    private _onCopyLinkBtnClick(p_e: Event) {
        // @ts-ignore
        this.$refs.portalLink.select();
        try {
            document.execCommand('copy');
            this.currentCopyToClipboardState = CopyToClipboardState.SUCCESS;
        } catch (err) {
            this.currentCopyToClipboardState = CopyToClipboardState.ERROR;
        }
        // @ts-ignore
        this.$refs.portalLink.blur();
    }

    private getTranslation(p_value: IMultiLangString) {
        return languageManager.getTranslationForValue<string>(p_value, AppUserModel.getInstance().langCode)
    }

    private async _onNextBtnClick(p_e: Event) {
        this.currentFormState = FormState.FORM_INPUT;
    }

    private async _onPreviousBtnClick(p_e: Event) {
        this.currentFormState = FormState.CHOOSE_SHAREABLE_FILES;
    }
}
</script>
