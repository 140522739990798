<template>
  <div class="welcome-sub-page welcome-start-page">
    <h1 class="welcome-start-page__title mb-5" v-html="$t('WelcomeStartTitle')"></h1>
    <b-button variant="success" v-html="$t('WelcomeStartButton')" @click="next()"></b-button>
    <AAImage imageUrl="img/welcome/welcome-start.jpg"></AAImage>
  </div>
</template>

<script lang="ts">
import {Component, Vue} from "vue-property-decorator";
import AAImage from "@/_view/components/AAImage.vue";
import {RoutingIdentifier} from "@/router";

@Component({
  components: {AAImage}
})
export default class WelcomeStartPage extends Vue {

  private async next() {
    await this.$router.push({name: RoutingIdentifier.WELCOME_LOGO});
  }

}
</script>
