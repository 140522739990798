<template>
  <div class="main-sub-page help-page">
    <iframe :src="helpUrl"></iframe>
  </div>
</template>


<script lang="ts">
import {Component, Vue} from "vue-property-decorator";
import {ExternalUrls} from "@/_model/app.constants";
import AppUserModel from "@/project/user/_model/AppUserModel";
import ProjectModel from "@/project/_model/ProjectModel";

@Component({
  components: {}
})
export default class HelpPage extends Vue {

    public project: ProjectModel = AppUserModel.getInstance().project;

    get helpUrl(): string {
        const helpType: string = this.project.config.helpType || this.project.config.tierType;
        return `${ExternalUrls.HELP}/?help-type=${helpType}`;
    }

}
</script>
