<template>
  <div class="list-item team-role-item"
       :class="{ active: isSelected, 'body-loaded': true }"
       v-on:click="_onClick">

    <div class="list-item__info">
        <h6 class="list-item__info__primary mb-0" v-line-clamp="1">{{ $t(`RoleName_${role.identifier}`) }}</h6>
    </div>

  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

import MorePopup from "@/_view/components/MorePopup.vue";
import EntityActionBox from "@/entity/_view/EntityActionBox.vue";
import AAProfilePicture from "@/_view/components/AAProfilePicture.vue";
import TeamModel from "@/team/_model/TeamModel";
import SelectionState from "@/__libs/_model/SelectionState";
import RoleModel from "@/team/_model/RoleModel";

@Component({
  components: {
  }
})
export default class RoleItemRenderer extends Vue {
  //---------------------------------
  // Vue Component props
  //---------------------------------

  @Prop() public role!: RoleModel;

  @Prop() private selState!: SelectionState<RoleModel>;

  //---------------------------------
  // Vue Component data
  //---------------------------------

  //---------------------------------
  // Vue Computed properties
  //---------------------------------


  get isSelected() {
    return this.selState.selected === this.role;
  }



  //---------------------------------
  // Public / lifecycle methods
  //---------------------------------

  public _onClick(p_e: Event) {
      this.$emit("onClick", this.role);
  }

  //---------------------------------
  // Private / helper methods
  //---------------------------------


}
</script>
