<template>
  <div class="list-item asset-folder-item"
       :class="{ active: isSelected, 'body-loaded': bodyLoaded }"
       v-cloak @drop.prevent="_onDropFile" @dragover.prevent @dragenter="_onDragOver" @dragleave="_onDragStop"
       v-on:click="_onClick">

    <div class="list-item__icon">
      <svg id="folder" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 35 30" v-if="!isSelected">
        <defs>
          <linearGradient id="linear-gradient-folder" x1="72.42" y1="4.19" x2="72.42" y2="-2.86" gradientTransform="translate(-344.6 25) scale(5 -5)" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#d5dce3"/>
            <stop offset="1" stop-color="#7497b0"/>
          </linearGradient>
        </defs>
        <path class="cls-1" d="M17.84,3.68l-2.11-2.11c-.47-.47-1.1-.73-1.77-.73H1.67c-.92,0-1.67.75-1.67,1.67v25c0,.92.75,1.67,1.67,1.67h31.67c.92,0,1.67-.75,1.67-1.67V5.83c0-.92-.75-1.67-1.67-1.67h-14.31c-.44,0-.87-.18-1.18-.49Z"/>
        <path class="cls-2" d="M15.49,7.01l2.72-2.72c.08-.08.18-.12.29-.12h14.83c.92,0,1.67.75,1.67,1.67v21.67c0,.92-.75,1.67-1.67,1.67H1.67c-.92,0-1.67-.75-1.67-1.67V7.92c0-.23.19-.42.42-.42h13.89c.44,0,.87-.18,1.18-.49Z"/>
      </svg>
      <svg id="folder-open" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 35 30" v-if="isSelected">
        <defs>
          <linearGradient id="linear-gradient-folder-open" x1="17.5" y1="9.06" x2="17.5" y2="29.76" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#7696a6"/>
            <stop offset=".08" stop-color="#6d8b9a"/>
            <stop offset=".33" stop-color="#576e7a"/>
            <stop offset=".57" stop-color="#475963"/>
            <stop offset=".8" stop-color="#3d4d55"/>
            <stop offset="1" stop-color="#3a4951"/>
          </linearGradient>
        </defs>
        <path class="cls-1" d="M14.86,3.68l-1.76-2.11c-.39-.47-.92-.73-1.47-.73H1.39C.62.83,0,1.58,0,2.5v25c0,.92.62,1.67,1.39,1.67h26.36c.77,0,1.39-.75,1.39-1.67V5.83c0-.92-.62-1.67-1.39-1.67h-11.91c-.37,0-.72-.18-.98-.49Z"/>
        <path class="cls-2" d="M34.94,12.55l-4.79,15.36c-.23.75-.89,1.26-1.63,1.26H1.28C.56,29.17,0,28.31,0,27.49v-12.91c-.02-.25.14-.47.36-.47l14.06.04c.39,0,.75-.17,1.02-.48l1.94-2.26c.33-.38.79-.6,1.27-.6h15.14c.83,0,1.42.87,1.16,1.72Z"/>
      </svg>
    </div>

    <div class="list-item__info">
      <b-skeleton v-if="!bodyLoaded"></b-skeleton>
      <h6 class="list-item__info__primary mb-0" v-line-clamp="1" v-if="bodyLoaded">{{ assetFolderName }}</h6>
    </div>

    <MorePopup :id="`asset-folder-${assetFolderID}`" placement="right">
      <EntityActionBox :entity="assetFolder" :editAllowed="false"/>
    </MorePopup>

    <transition name="fade">
      <div class="drop-area" v-if="showDropIndication">
        <i class="fas fa-plus-circle fa-lg"></i>
      </div>
    </transition>

  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from "vue-property-decorator";

import AssetFolderModel from "@/asset_folder/_model/AssetFolderModel";
import {DeltaStatus, LoadingStatus} from "@/entity/_model/entity.constants";
import MorePopup from "@/_view/components/MorePopup.vue";
import EntityActionBox from "@/entity/_view/EntityActionBox.vue";
import AppUserModel from "@/project/user/_model/AppUserModel";
import languageManager from "@/__libs/language_manager/LanguageManager";
import AssetFolderSelectionState from "@/asset_folder/_model/AssetFolderSelectionState";
import assetFolderController from "@/asset_folder/_controller/AssetFolderController";
import {RightAction} from "@/team/_model/role.constants";
import AssetFolderListModel from "@/asset_folder/_model/AssetFolderListModel";
import {FileType} from "@/asset_folder/_model/asset_folder.constants";
import FileUtil from "@/__libs/utility/FileUtil";
import fileSize from "filesize";

@Component({
  components: {
    MorePopup, EntityActionBox
  }
})
export default class AssetFolderItemRenderer extends Vue {
  //---------------------------------
  // Vue Component props
  //---------------------------------

  @Prop() public assetFolder!: AssetFolderModel;

  @Prop() private selState!: AssetFolderSelectionState;

  //---------------------------------
  // Vue Component data
  //---------------------------------


  private showDropIndication: boolean = false;
  private dropAreaCounter: number = 0;


  //---------------------------------
  // Vue Computed properties
  //---------------------------------

  get assetFolderName() {
    return languageManager.getTranslationForValue<string>(this.assetFolder.name, AppUserModel.getInstance().langCode)
  }

  get assetFolderID() {
    return this.assetFolder.ID;
  }

  get bodyLoaded() {
    return this.assetFolder.loadingStatus >= LoadingStatus.BODY_LOADED;
  }

  get isSelected() {
    return this.selState.selected === this.assetFolder;
  }


  get isNew() {
    return this.assetFolder.deltaStatus == DeltaStatus.NEW;
  }

  get isUpdated() {
    return this.assetFolder.deltaStatus == DeltaStatus.UPDATED;
  }

  get isDeleted() {
    return this.assetFolder.deltaStatus == DeltaStatus.DELETED;
  }

  get isEditable(): boolean {
    return this.assetFolder.rightActions.indexOf(RightAction.EDIT) >= 0;
  }

  get inputAccept(): string {
    return AssetFolderListModel.getExtensionsForFileTypes(this.selState.allowedFileTypes).join();
  }

  //---------------------------------
  // Public / lifecycle methods
  //---------------------------------


  //---------------------------------
  // Private / helper methods
  //---------------------------------

  public _onClick(p_e: Event) {
    this.$emit("onClick", this.assetFolder);
  }


  private async _onDropFile(e: DragEvent) {
    if (this.isEditable) {
      if (e.dataTransfer && e.dataTransfer.files && e.dataTransfer.files.length > 0) {
        //
        if (await this.checkValidFilesSelect(e.dataTransfer.files)) {
          await assetFolderController.addFile(this.assetFolder, e.dataTransfer.files, this.selState);
        }
      }
      this._onDragStop(e);
    }
  }

  private _onDragOver(e: DragEvent) {
    this.dropAreaCounter++;
    if (this.isEditable) {
      this.showDropIndication = true;
    }
  }

  private _onDragStop(e: DragEvent) {
    this.dropAreaCounter--;
    if (this.dropAreaCounter === 0) {
      this.showDropIndication = false;
    }
  }

  //todo: WARNING: this code is duplicated in AssetFilesOverview
  private async checkValidFilesSelect(p_files: FileList): Promise<boolean> {
    if (p_files.length > AssetFolderListModel.MAX_SIMULTANEOUS_UPLOADS) {
      await this.$bvModal.msgBoxOk(this.$t('MaxSimultaneousUploads', [AssetFolderListModel.MAX_SIMULTANEOUS_UPLOADS]) as string, {centered: true});

      return false;
    } else {
      for (let i = 0; i < p_files.length; i++) {
        const file: File | null = p_files.item(i);

        if (file) {
          const fileType: FileType = AssetFolderListModel.getFileTypeForExtension(FileUtil.getFileExtension(file.name));
          if (fileType > FileType.UNKNOWN) {
            if (file.size > AppUserModel.getInstance().project.tierConfig.maxFileSize) {
              await this.$bvModal.msgBoxOk(this.$t('ErrorMaxSize', [fileSize(AppUserModel.getInstance().project.tierConfig.maxFileSize)]) as string, {centered: true});
              return false;
            }
          } else {
            await this.$bvModal.msgBoxOk(this.$t('ErrorFileType', [this.inputAccept]) as string, {centered: true});
            return false;
          }
        }
      }
    }
    return true;
  }

}
</script>
