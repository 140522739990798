import SyncModel from "@/sync/_model/SyncModel";
import {ISyncFileDto, ISyncItemDto, ISyncItemFileDto} from "@/sync/_model/sync.dto";
import {SyncFileRetrieveStatus, SyncProcessStatus, SyncProcessType} from "@/sync/_model/sync.constants";
import syncController from "@/sync/_controller/SyncController";
import SyncDb from "@/sync/_model/SyncDb";
import {IMultiLangString, LanguageManager} from "@/__libs/language_manager/LanguageManager";
import fileManager from "@/_controller/FileManager";
import {StorageScope} from "@/_model/app.constants";

abstract class SyncProcess
{


    //---------------------------------
    // Properties

    //---------------------------------

    protected _syncDb:SyncDb = SyncDb.getInstance();

    protected _syncModel:SyncModel = SyncModel.getInstance();

    public processType:SyncProcessType;

    public dependencyLevel:number = 0;

    protected _storageScope:StorageScope = StorageScope.PROJECT;

    private _processStatus:SyncProcessStatus = SyncProcessStatus.IDLE;
    get processStatus():SyncProcessStatus
    {
        return this._processStatus;
    }

    public percCompleted:number = 0;

    constructor(p_processType:SyncProcessType, p_dependencyLevel?:number)
    {
        this.processType = p_processType;
        if(p_dependencyLevel)
        {
            this.dependencyLevel = p_dependencyLevel;
        }


    }

    //---------------------------------
    // Public Methods
    //---------------------------------

    protected _parseAssetFilesFromMultiLangString(p_mlValue:IMultiLangString)
    {
        const assetFiles:string[] = LanguageManager.getUniqueValuesFromMultiLangValue(p_mlValue);
        for (let i = 0; i < assetFiles.length; i++)
        {
            const filePath:string = fileManager.getAssetFileBasePath(assetFiles[i]);

            if (filePath.length > 0)
            {
                const syncFile:ISyncFileDto = {
                    path          : filePath,
                    storageScope  : this._storageScope,
                    retrieveStatus: SyncFileRetrieveStatus.IDLE,
                    nrOfAttempts  : 0
                };
                syncController.fileGroupFileProcess.addFileGroupFile(syncFile);
            }
        }
    }

    //---------------------------------
    // Overridden Methods
    //---------------------------------

    public startProcess() {}

    public resumeProcess() {}



    //---------------------------------
    // Private Methods
    //---------------------------------



    protected _finishProcess()
    {
        this._processStatus = SyncProcessStatus.FINISHED;
        this.percCompleted = 100;
        syncController.startNextProcess();
    }


    //---------------------------------
    // Overridden Methods
    //---------------------------------



}

export default SyncProcess;
