<template>

    <div class="share-with-audience">

        <transition name="fade">
            <div v-if="currentFormState === formState.TRANSFER_WHAT">
                <b-form>

                    <p>{{ $t('ChooseWhatToTransfer') }}</p>

                    <div class="mb-3">
                        <b-form-checkbox v-for="transferType in transferTypes" :key="transferType" :value="transferType"
                                         v-model="chosenTypes" class="mb-1">
                            {{ $t(`TransferType_${transferType}`) }}
                        </b-form-checkbox>
                    </div>

                    <div class="d-flex justify-content-end mt-4">
                        <b-button variant="secondary" class="mr-2" @click="_onCloseBtnClick" tabindex="6">
                            {{ $t('Cancel') }}
                        </b-button>
                        <b-button @click="_onNextBtnClick" :disabled="chosenTypes.length === 0" variant="primary">{{
                            $t('Next') }}
                        </b-button>
                    </div>

                </b-form>

            </div>
        </transition>

        <transition name="fade">
            <div v-if="currentFormState === formState.TRANSFER_TO_WHO">
                <b-form>
                    <p>{{ $t('ChooseWhoToTransferTo') }}</p>

                    <select class="form-control mb-3"
                            v-model="targetUserID">
                        <option v-for="teamUser in teamUsers" :key="teamUser.userID"
                                :value="teamUser.userID">{{ teamUser.displayName }}
                        </option>
                    </select>


                    <div class="d-flex justify-content-end mt-4">
                        <b-button variant="secondary" class="mr-2" @click="_onCloseBtnClick" tabindex="6">
                            {{ $t('Cancel') }}
                        </b-button>
                        <b-button variant="secondary" class="mr-2" @click="_onBackBtnClick" tabindex="6">
                            {{ $t('Back') }}
                        </b-button>
                        <b-button @click="_onFinishBtnClick" :disabled="!targetUserID" variant="primary">{{
                            $t('Finish') }}
                        </b-button>
                    </div>
                </b-form>
            </div>

        </transition>

        <transition name="fade">
            <div v-if="currentFormState === formState.TRANSFERRING" class="text-center">
                <b-spinner variant="dark" class="my-5"></b-spinner>
            </div>
        </transition>

        <transition name="fade">
            <div v-if="currentFormState === formState.TRANSFER_ERROR">
                <p class="mb-4 text-danger">{{ errorMsg}}</p>
                <div class="d-flex justify-content-end">
                    <button class="btn btn-secondary mr-2" @click="_onCloseBtnClick">{{ $t('Cancel') }}</button>
                </div>
            </div>
        </transition>

        <transition name="fade">
            <div v-if="currentFormState === formState.TRANSFER_RESULT">
                <b-form>

                    <p>{{ $t('TheseAssetsWhereTransferred') }}</p>

                    <div class="mb-3">
                        <div v-for="transferResult in transferResults" :key="transferResult.type" :value="transferResult.type" class="mb-1">
                            <b>{{ $t(`TransferType_${transferResult.type}`) }}</b>: {{ $t('TransferAmount', [transferResult.amountTransfered]) }}
                        </div>
                    </div>

                    <div class="d-flex justify-content-end mt-4">
                        <b-button @click="_onCloseBtnClick" variant="primary">{{
                            $t('Ok') }}
                        </b-button>
                    </div>

                </b-form>

            </div>
        </transition>

    </div>

</template>

<script lang="ts">

    import {Component, Prop, Vue} from 'vue-property-decorator';
    import AudienceModel from "@/audience/_model/AudienceModel";
    // validation
    import AudienceListModel from "@/audience/_model/AudienceListModel";
    import {ILinkOptionDto, ILinkValueDto, ILinkWizardDto} from "@/presentation/_model/presentation.dto";
    import {ApiAction, NavigateType} from "@/presentation/player/_model/player.constants";
    import ContentModel from "@/content/_model/ContentModel";
    import ContentListModel from "@/content/_model/ContentListModel";
    import languageManager from "@/__libs/language_manager/LanguageManager";
    import PresentationModel from "@/presentation/_model/PresentationModel";
    import {EntityType} from "@/entity/_model/entity.constants";
    import TeamUserModel from "@/team/_model/TeamUserModel";
    import TeamUserListModel from "@/team/_model/TeamUserListModel";
    import {FILE_TYPE_CONFIG, FileTargetType} from "@/asset_folder/_model/asset_folder.constants";
    import {UserTransferType} from "@/team/_model/team.constants";
    import {ApiResponse} from "@/_controller/ApiManager";
    import {ICreateResultDto} from "@/entity/_model/entity.dto";
    import teamController from "@/team/_controller/TeamController";
    import {IUserTransferTypeResultDto} from "@/team/_model/team.dto";
    import teamUserController from "@/team/_controller/TeamUserController";


    enum FormState
    {
        TRANSFER_WHAT,
        TRANSFER_TO_WHO,
        TRANSFERRING,
        TRANSFER_ERROR,
        TRANSFER_RESULT,
    }


    @Component({
        components: {},
    })
    export default class UserTransferBox extends Vue
    {


        //---------------------------------
        // Vue Component props
        //---------------------------------

        @Prop() public teamUser!:TeamUserModel;


        //---------------------------------
        // Vue Component data
        //---------------------------------

        private formState:typeof FormState = FormState;

        private currentFormState:FormState = FormState.TRANSFER_WHAT;

        private targetUserID:number | null = null;

        private chosenTypes:UserTransferType[] = [];

        private transferTypes:UserTransferType[] = Object.values(UserTransferType);

        private errorMsg:string = "";

        private transferResults:IUserTransferTypeResultDto[] | null = null;
        //---------------------------------
        // Vue Computed properties
        //---------------------------------



        get teamUsers()
        {

            const teamUsers:TeamUserModel[] = [...TeamUserListModel.getInstance().list];
            teamUsers.sort((a:TeamUserModel, b:TeamUserModel) => {
                if (a.displayName.toLowerCase() < b.displayName.toLowerCase())
                {
                    return -1;
                }
                if (a.displayName.toLowerCase() > b.displayName.toLowerCase())
                {
                    return 1;
                }
                return 0;
            });
            return teamUsers;
        }



        //---------------------------------
        // Public / lifecycle methods
        //---------------------------------


        mounted()
        {


        }

        //---------------------------------
        // Private / helper methods
        //---------------------------------



        private async _onFinishBtnClick(p_e:Event)
        {
            if(this.targetUserID)
            {
                this.transferResults = null;
                this.currentFormState = FormState.TRANSFERRING;

                this.transferResults = await teamUserController.transferUser(this.teamUser.userID, this.targetUserID, this.chosenTypes);

                if (this.transferResults)
                {
                    this.currentFormState = FormState.TRANSFER_RESULT;
                }
                else
                {
                    this.errorMsg = this.$t('UserTransferFailed') as string;
                    this.currentFormState = FormState.TRANSFER_ERROR;
                }
            }


        }

        private async _onNextBtnClick(p_e:Event)
        {
            this.currentFormState = FormState.TRANSFER_TO_WHO;
        }

        private async _onBackBtnClick(p_e:Event)
        {
            this.currentFormState = FormState.TRANSFER_WHAT;
        }

        private async _onCloseBtnClick(p_e:Event)
        {
            this.$emit('onUserTransfered', null);
        }



    }
</script>
