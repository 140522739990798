<template>
    <div class="content-prepare">

        <div class="content-prepare__header">
            <div class="d-flex align-items-center">
                <div class="btn btn-primary" @click="_onBackButtonClick">
                    <i class="fas fa-chevron-left fa-lg"></i>
                    {{ $t('back') }}
                </div>
                <p class="mb-0 ml-4 flex-grow-1" v-line-clamp="2" v-html="$t('ContentPrepareTitle', [contentName, activeAudience.displayName])"></p>
            </div>
        </div>

        <div class="content-prepare__content">

            <transition name="fade">
                <div v-if="!activeContent || !activeAudience" class="placeholder-text text-danger">
                    {{ $t('ContentPrepareError') }}
                </div>
            </transition>

            <transition name="fade">
                <template v-if="activeContent && activeAudience">
                    <template v-if="activeContent.entityType === entityType.CONTENT_APP">
                        <ContentAppPlayer class="content-app-player"
                                          engineMode="PREPARE"
                                          appType="HTML_APP"
                                          :appID="activeContent.ID"/>
                        <!--                //todo: appType should come from appResource cfg.json-->
                    </template>
                </template>
            </transition>

        </div>

    </div>
</template>


<script lang="ts">
    import {Component, Prop, Vue} from "vue-property-decorator";
    import ContentModel from "@/content/_model/ContentModel";
    import ContentListModel from "@/content/_model/ContentListModel";
    import AudienceModel from "@/audience/_model/AudienceModel";
    import AudienceListModel from "@/audience/_model/AudienceListModel";
    import EntitySelectionState from "@/entity/_model/EntitySelectionState";
    import {EngineMode} from "@/presentation/player/_model/player.constants";
    import languageManager from "@/__libs/language_manager/LanguageManager";
    import {EntityType} from "@/entity/_model/entity.constants";
    import ContentAppPlayer from "@/content_app/player/_view/ContentAppPlayer.vue";
    import {RoutingIdentifier} from "@/router";

    @Component({
        components: {ContentAppPlayer}
    })
    export default class ContentPrepare extends Vue
    {
        //---------------------------------
        // Vue Component props
        //---------------------------------

        //---------------------------------
        // Vue Component data
        //---------------------------------


        public selState:EntitySelectionState<ContentModel> = ContentListModel.getInstance().globalSelState;

        private entityType:typeof EntityType = EntityType;

        //---------------------------------
        // Vue Computed properties
        //---------------------------------

        get activeContent():ContentModel | null
        {
            return this.selState.selected;
        }

        get activeAudience():AudienceModel | null
        {
            return AudienceListModel.getInstance().globalSelState.selected;
        }

        get audienceLangCode():string | null
        {
            return (this.activeAudience) ? this.activeAudience.langCode : null;
        }

        get contentName()
        {
            return languageManager.getTranslationForValue(
                this.activeContent!.name,
                this.audienceLangCode
            );
        }

        //---------------------------------
        // Public / lifecycle methods
        //---------------------------------

        public mounted()
        {
            // console.log(this.activeContent);
        }

        //---------------------------------
        // Private / helper methods
        //---------------------------------

        private async _onBackButtonClick(p_e:Event)
        {
            this.$router.push({name: RoutingIdentifier.CONTENT_OVERVIEW});
        }

    }
</script>
