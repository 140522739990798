export enum UserOrigin
{
    STATIC = "STATIC",
    FEDERATED = "FEDERATED"
}

export enum UserStatus
{
    UNVERIFIED = "UNVERIFIED",
    VERIFIED   = "VERIFIED",
    MIGRATED   = "MIGRATED"
}

export enum UserTransferType
{
    AUDIENCE = "AUDIENCE",
    COMPANY = "COMPANY",
    PRESENTATION = "PRESENTATION",
    CONTENT_FILE = "CONTENT_FILE",
    CONTENT_APP = "CONTENT_APP",
    ASSET_FOLDER = "ASSET_FOLDER",
    SLIDE = "SLIDE",
    DATA_PROVIDER = "DATA_PROVIDER",
    CONTENT_FOLDER = "CONTENT_FOLDER",

}
