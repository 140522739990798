import i18n from '@/__plugins/i18n';

import apiManager, {ApiRequestType, ApiResponse} from "@/_controller/ApiManager";
import SyncModel from "@/sync/_model/SyncModel";
import {
    IBackgroundSyncRecord,
    IOfflineAvailabilityDto,
    ISyncCategoryDto,
    ISyncFileDto,
    ISyncFileRecord
} from "@/sync/_model/sync.dto";
import SyncItemProcess from "@/sync/_controller/process/SyncItemProcess";
import SyncItemProcessFactory from "@/sync/_controller/process/SyncItemProcessFactory";
import {
    SyncFileRetrieveStatus,
    SyncPauseReason,
    SyncProcessStatus,
    SyncProcessType
} from "@/sync/_model/sync.constants";
import fileManager, {FileResponse} from "@/_controller/FileManager";
import SyncFileGroupFileProcess from "@/sync/_controller/process/SyncFileGroupFileProcess";
import SyncProcess from "@/sync/_controller/process/SyncProcess";
import {LocalStorageKey} from "@/_model/app.constants";
import LocalStorageManager from "@/__libs/offline_storage/LocalStorageManager";
import JsonUtil from "@/__libs/utility/JsonUtil";
import SyncDb from "@/sync/_model/SyncDb";
import toastManager, {ToastType} from "@/__libs/toast_manager/ToastManager";
import axios, {ResponseType} from "axios";
import NetworkManager, {NetworkEventType, NetworkState} from "@/_controller/NetworkManager";
import appUserController from "@/project/user/_controller/AppUserController";
import SyncMetaProcess from "@/sync/_controller/process/SyncMetaProcess";


class SyncController
{

    /*
    * todo:
    *  2way sync
    *  idb mimetype storage/retrieval
    *  entity save in idb
    *  auto sync every x minutes
    *  delayed start
    *  sync log after finish
    * */


    //---------------------------------
    // Properties
    //---------------------------------

    private _networkManager:NetworkManager = NetworkManager.getInstance();

    private _syncModel:SyncModel = SyncModel.getInstance();

    private _syncDb:SyncDb = SyncDb.getInstance();


    private _processList:SyncProcess[] = [];

    private _currentProcess:SyncProcess | null = null;

    private _failedFiles:ISyncFileDto[] = [];


    private MAX_NR_OF_FILE_FETCH_ATTEMPTS:number = 3;

    public metaProcess:SyncMetaProcess = new SyncMetaProcess(SyncProcessType.META);
    public fileGroupFileProcess:SyncFileGroupFileProcess = new SyncFileGroupFileProcess(SyncProcessType.FILE_GROUP_FILE); //the final process, when all filegroup files are retrieved



    //---------------------------------
    // Controller Methods
    //---------------------------------

    public async initialize()
    {

        this._networkManager.addListener(NetworkEventType.STATE_CHANGED, (p_e) => this._networkStateChangedHandler(p_e));


        this._readOfflineAvailability();

        const isOnline:boolean = this._networkManager.online;

        //todo: delayed start
        if (this._syncModel.isSyncEnabled && this._syncModel.doAutoSync && isOnline)
        {
            this.startSync();
        }
        if (isOnline)
        {
            this.doNextBackgroundSync();
        }

        this._setStorageOptions();

        //this.$dev_putTestFileInIdb("/constructs/default/index.html");

    }


    public startSync()
    {
        if (this._syncModel.isSyncing || !this._networkManager.online)
        {
            return;
        }
        this._resetState();
        this._syncModel.isSyncing = true;
        this._syncModel.percCompleted = 1; // show some progress as feedback
        this._fetchCategories();

    }

    public async doNextBackgroundSync()
    {
        const nextRecord:IBackgroundSyncRecord | null = await this._syncDb.getNextBackgroundSyncRecord();
        if (nextRecord)
        {
            const response:ApiResponse<any> = await apiManager.sendApiRequest(nextRecord.requestType, nextRecord.endpoint, nextRecord.data);
            if (response.hasSucceeded)
            {
                await this._syncDb.deleteBgSync(nextRecord.ID as number);
            }
            else
            {
                await this._syncDb.updateBgSyncTry(nextRecord.ID as number);
            }
            this.doNextBackgroundSync();
        }
    }


    public pauseSync(p_pauseReason:SyncPauseReason = SyncPauseReason.USER_DEMAND)
    {
        if (this._syncModel.isPaused)
        {
            return;
        }
        this._syncModel.isPaused = true;
        this._syncModel.pauseReason = p_pauseReason;
        this._setStorageOptions();
    }


    public resumeSync()
    {
        if (!this._syncModel.isPaused)
        {
            return;
        }
        if (this._currentProcess)
        {
            this._syncModel.isPaused = false;
            this._syncModel.pauseReason = null;
            this._currentProcess.resumeProcess();
        }
        else
        {
            //something went terribly wrong
            toastManager.showToast("Can't resume sync, please try again later", ToastType.DANGER);

            this.cancelSync();
        }
    }


    public cancelSync()
    {
        if (!this._syncModel.isSyncing)
        {
            return;
        }
        this._resetState();
    }


    //either return if one exists, or add and return if not (dependency processes are always known by processType, not by storagePath)
    public resolveDependencyProcess(p_processType:SyncProcessType):SyncProcess
    {
        for (let i = 0; i < this._processList.length; i++)
        {
            if (this._processList[i].processType === p_processType)
            {
                return this._processList[i];
            }
        }
        const dependencyProcess:SyncItemProcess = SyncItemProcessFactory.create(p_processType);
        this._processList.push(dependencyProcess);
        this._processList.sort((a, b) => a.dependencyLevel - b.dependencyLevel);

        return dependencyProcess;
    }


    //called by individual processes
    public startNextProcess()
    {
        this.computePercCompleted();

        for (let i = 0; i < this._processList.length; i++)
        {
            const process:SyncProcess = this._processList[i];
            if (process.processStatus !== SyncProcessStatus.FINISHED)
            {
                this._currentProcess = process;
                process.startProcess();
                return;
            }
        }

        this._finishSync();
    }


    public computePercCompleted()
    {
        //startup has a weight of 10
        //an item process has a weight of 5
        //the fileGroupFileProcess has a weight of 150

        let currentCompleted:number = 10;
        let totalCompleted:number = 10;

        for (let i = 0; i < this._processList.length - 1; i++)
        {
            const process:SyncProcess = this._processList[i];
            currentCompleted += process.percCompleted * 5 / 100;
            totalCompleted += 5;
        }

        currentCompleted += this.fileGroupFileProcess.percCompleted * 150 / 100;
        totalCompleted += 150;

        const percCompleted = currentCompleted / totalCompleted * 100;
        if (this._syncModel.percCompleted < percCompleted)
        {
            this._syncModel.percCompleted = Math.round(percCompleted);
        }
    }


    public async fetchFile(p_file:ISyncFileDto, p_storedBehindApi:boolean = false)
    {
        // console.log("fetchFile", p_storedBehindApi, p_file.path,);
        if (p_file.nrOfAttempts >= this.MAX_NR_OF_FILE_FETCH_ATTEMPTS)
        {
            p_file.retrieveStatus = SyncFileRetrieveStatus.ERROR;
            this._failedFiles.push(p_file);
            return null;
        }

        p_file.retrieveStatus = SyncFileRetrieveStatus.RETRIEVING;
        p_file.nrOfAttempts++;


        let fileResponse:FileResponse | ApiResponse<any>;
        if (p_storedBehindApi)
        {
            fileResponse = await apiManager.sendApiRequest(ApiRequestType.GET, `/client-api${p_file.path}`);
        }
        else
        {
            const responseType:ResponseType = p_file.path.endsWith('.json') ? "json" : "blob";
            fileResponse = await fileManager.fetchFileFromCdn(p_file.path + "?forceFetch=1", p_file.storageScope, responseType); //by setting the forceFetch flag, the service worker knows he has to fetch the file online
        }


        if (fileResponse.hasSucceeded)
        {
            p_file.retrieveStatus = SyncFileRetrieveStatus.RETRIEVED;
            return fileResponse.result;
        }
        else
        {
            //check connectivity, pause if offline
            if (!this._networkManager.online)
            {
                this.pauseSync(SyncPauseReason.NO_CONNECTIVITY);
                p_file.nrOfAttempts--;
            }
            else
            {
                p_file.errorMsg = fileResponse.error!.message;
            }
            p_file.retrieveStatus = SyncFileRetrieveStatus.IDLE;
            return null;
        }
    }


    public async storeFile(p_file:ISyncFileDto)
    {
        //store file object in idb (remote/local version, body )
        const fileRecord:ISyncFileRecord = {path: p_file.path.split(" ").join("%20"), body: p_file.body};
        if (p_file.remoteVersion)
        {
            fileRecord.remoteVersion = p_file.remoteVersion;
            fileRecord.localVersion = p_file.remoteVersion;
        }

        const storeSucceeded:boolean = await this._syncDb.setFileRecord(fileRecord);

        if (!storeSucceeded)
        {
            //pause on error, show error prompting to clear more space?
            this.pauseSync(SyncPauseReason.STORAGE_ERROR);
        }
    }

    public disableSync()
    {
        this._syncModel.isSyncEnabled = false;
        this.clearStorage();
    }

    public async enableSync()
    {
        this._syncModel.isSyncEnabled = true;
        this._storeOfflineAvailability();
        this.startSync();

        await this.askPersistence();
    }

    public async clearStorage()
    {
        this.cancelSync();
        this._syncModel.lastSuccessfulSync = null;
        this._syncModel.isOfflineAvailable = false;
        this._storeOfflineAvailability();
        await this._syncDb.clearFiles();
        this._setStorageOptions();
        toastManager.showToast(i18n.t('SyncClearComplete') as string, ToastType.PRIMARY);

    }

    public updateAutoSync(p_doAutoSync:boolean)
    {
        this._syncModel.doAutoSync = p_doAutoSync;
        this._storeOfflineAvailability();
    }



    public async askPersistence():Promise<boolean>
    {
        if (navigator.storage && navigator.storage.persist)
        {
            return await navigator.storage.persist();
        }
        return false;
    }

    //---------------------------------
    // Private Methods
    //---------------------------------

    private async _fetchCategories()
    {

        const response:ApiResponse<ISyncCategoryDto[]> = await apiManager.sendApiRequest(ApiRequestType.GET, `/client-api/sync-categories/?level=0`);

        if (response.hasSucceeded)
        {

            const syncCategories:ISyncCategoryDto[] = response.result!;
            this._processList.push(this.metaProcess);

            for (let i = 0; i < syncCategories.length; i++)
            {
                //create all (level 0) processes
                const syncCategory:ISyncCategoryDto = syncCategories[i];
                this._processList.push(SyncItemProcessFactory.create(syncCategory.processType, syncCategory.dependencyLevel, syncCategory.syncItems, syncCategory.storagePath, syncCategory.storageScope));
            }
            this._processList.push(this.fileGroupFileProcess);
            this.startNextProcess();
        }
        else
        {
            toastManager.showToast("Can't get sync data, please try again later", ToastType.DANGER);
            this.cancelSync();
        }
    }


    private _networkStateChangedHandler(p_e:any)
    {
        if (p_e.state === NetworkState.OFFLINE && this._syncModel.isSyncing)
        {
            this.pauseSync(SyncPauseReason.NO_CONNECTIVITY);
        }
        else if (p_e.state === NetworkState.ONLINE && this._syncModel.isPaused && this._syncModel.pauseReason === SyncPauseReason.NO_CONNECTIVITY)
        {
            this.resumeSync();
        }
        if (p_e.state === NetworkState.ONLINE)
        {
            this.doNextBackgroundSync();
        }

    }

    private _resetState()
    {
        this._processList = [];
        this._failedFiles = [];
        this._currentProcess = null;
        this._syncModel.pauseReason = null;
        this._syncModel.isSyncing = false;
        this._syncModel.isPaused = false;
        this._syncModel.percCompleted = 0;
        this.metaProcess = new SyncMetaProcess(SyncProcessType.META);
        this.fileGroupFileProcess = new SyncFileGroupFileProcess(SyncProcessType.FILE_GROUP_FILE);
        this._setStorageOptions();
    }

    private async _finishSync()
    {
        console.log("ALL DONE");

        //todo: when do we consider it to be a successful sync?

        this._syncModel.lastSuccessfulSync = new Date();
        this._syncModel.isOfflineAvailable = true;

        this._storeOfflineAvailability();

        //todo log sync on server (eg _failedFiles)
        if (this._failedFiles.length > 0)
        {
            console.log(this._failedFiles);
        }

        toastManager.showToast(i18n.t('SyncComplete') as string, ToastType.SUCCESS);

        this._resetState();
    }


    private _readOfflineAvailability()
    {
        const offlineAvailability:IOfflineAvailabilityDto | null = JsonUtil.parse(LocalStorageManager.retrieveValue(LocalStorageKey.OFFLINE_AVAILABILITY) as string);

        if (offlineAvailability)
        {
            this._syncModel.isSyncEnabled = offlineAvailability.isSyncEnabled;
            this._syncModel.doAutoSync = offlineAvailability.doAutoSync;
            this._syncModel.lastSuccessfulSync = offlineAvailability.lastSuccessfulSync;
            if (this._syncModel.lastSuccessfulSync)
            {
                this._syncModel.isOfflineAvailable = true;
            }

        }
    }


    private _storeOfflineAvailability()
    {
        const offlineAvailability:IOfflineAvailabilityDto = {
            lastSuccessfulSync: this._syncModel.lastSuccessfulSync,
            isSyncEnabled     : this._syncModel.isSyncEnabled,
            doAutoSync        : this._syncModel.doAutoSync
        };
        if (!appUserController.inImpersonationMode)
        {
            LocalStorageManager.storeValue(LocalStorageKey.OFFLINE_AVAILABILITY, JsonUtil.stringify(offlineAvailability));
        }
    }


    private async _setStorageOptions()
    {
        if ('storage' in navigator && 'estimate' in navigator.storage)
        {
            this._syncModel.hasStorageManagement = true;
            if (await navigator.storage.persisted)
            {
                this._syncModel.hasPersistentStorage = await navigator.storage.persisted();
            }
            if (navigator.storage.estimate)
            {
                const estimate:StorageEstimate = await navigator.storage.estimate();
                this._syncModel.storageQuota = estimate.quota ? estimate.quota : 0;
                this._syncModel.storageUsage = estimate.usage ? estimate.usage : 0;
            }
        }
        else
        {
            this._syncModel.hasStorageManagement = false;
        }

    }

    public async $dev_putTestFileInIdb(p_path:string, p_responseType:ResponseType = "blob")
    {

        const result = await axios.get("storage/" + p_path, {responseType: "blob"});
        const fileRecord:ISyncFileRecord = {path: p_path, body: result.data};
        const storeSucceeded:boolean = await this._syncDb.setFileRecord(fileRecord);
        if (storeSucceeded)
        {
            console.log(p_path, "stored");
        }
    }
}

//Singleton export
export default new SyncController();
