import {ENTITY_CONFIG, UploadStatus} from "@/entity/_model/entity.constants";
import EntityController from "@/entity/_controller/EntityController";



class CompanyController extends EntityController
{


    //---------------------------------
    // Properties
    //---------------------------------


    //---------------------------------
    // Controller Methods
    //---------------------------------



//---------------------------------
    // Private Methods
    //---------------------------------


}

//Singleton export
export default new CompanyController(ENTITY_CONFIG.COMPANY);
