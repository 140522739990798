
export enum ContentViewType
{
    FLAT = "FLAT",
    FOLDER = "FOLDER",
}


export enum BreadCrumbType
{
    HOME = "HOME",
    LEVEL = "LEVEL",
    ACTIVE = "ACTIVE"
}
