<template>
  <div class="mission">
    <div class="mission__name">
      <h6 v-html="name" class="mb-0" v-line-clamp="1"></h6>
      <CoinAmount :amount="coinsPerTarget"></CoinAmount>
    </div>
    <div class="mission__target">
      <img src="img/gamification/gamification_targetnotreached.png" v-if="!isTargetReached"/>
      <img src="img/gamification/gamification_targetreached.png" v-if="isTargetReached"/>
      <div>{{ userTarget.targetValue }}/{{ target.targetPerMonth }}</div>
    </div>
    <div class="mission__coins">
      <CoinAmount :max="isMax" :amount="userTarget.coinsValue"></CoinAmount>
    </div>
    <div class="mission__progress" v-b-tooltip.hover.top :title="progressText">
      <b-progress :value="userTarget.targetValue" :max="target.targetPerMonth" :variant="progressVariant" striped
                  animated></b-progress>
    </div>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import gamificationController from "@/gamification/_controller/GamificationController";
import AppUserModel from "@/project/user/_model/AppUserModel";
import {
    IGamificationProjectConfigDto,
    IGamificationTargetConfigDto,
    IGamificationTargetValueDto
} from "@/gamification/_model/gamification.dto";
import languageManager from "@/__libs/language_manager/LanguageManager";
import GamificationUtil from "@/gamification/_controller/GamificationUtil";
import CoinAmount from "@/gamification/_view/CoinAmount.vue";

@Component({
    components: {CoinAmount}
})
export default class Mission extends Vue {

    @Prop() public target!: IGamificationTargetConfigDto;

    private appUser: AppUserModel = AppUserModel.getInstance();

    get userTarget(): IGamificationTargetValueDto {
        return GamificationUtil.getUserTarget(this.target, this.appUser);
    }

    get name(): string {
        return languageManager.getTranslationForValue(this.target.name);
    }

    get coinsPerTarget(): number {
        return this.target.coinsPerTarget;
    }

    get isMax(): boolean {
        if (this.userTarget.coinsValue) {
            return this.userTarget.coinsValue >= this.target.maxCoinsPerMonth;
        }
        return false;
    }

    get isTargetReached(): boolean {
        return this.userTarget.targetValue >= this.target.targetPerMonth;
    }

    get progressVariant(): string {
        const now: Date = new Date();
        const percentage: number = this.userTarget.targetValue / this.target.targetPerMonth;
        if (now.getFullYear() === this.userTarget.yearNr && now.getMonth() === this.userTarget.monthNr) {
            const daysInCurrentMonth: number = new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate();
            const datePercentage: number = now.getDate() / daysInCurrentMonth;
            if (percentage >= datePercentage) {
                return 'success';
            } else if (datePercentage - percentage < 0.25) {
                return 'warning';
            } else {
                return 'danger';
            }
        } else {
            if (percentage >= 1) {
                return 'success';
            } else {
                return 'danger';
            }
        }
    }

    get progressText(): string {
        if (this.progressVariant === 'success') {
            return this.$t('GamificationMissionPopoverHigh') as string;
        }
        return this.$t('GamificationMissionPopoverLow') as string;
    }

}
</script>

