<template>
  <div class="sub-page slides-page">
    <SlidesView :selState="selState"></SlidesView>
  </div>
</template>


<script lang="ts">
import {Component, Vue} from "vue-property-decorator";
import SlidesOverview from "@/slide/_view/SlidesOverview.vue";
import SlidesView from "@/slide/_view/SlidesView.vue";
import SlideModel from "@/slide/_model/SlideModel";
import SlideListModel from "@/slide/_model/SlideListModel";
import SlideSelectionState from "@/slide/_model/SlideSelectionState";

@Component({
  components: {SlidesView}
})
export default class SlidesPage extends Vue {

  public selState: SlideSelectionState = SlideListModel.getInstance().pageSelState;

}
</script>
