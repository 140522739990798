<template>
  <div class="edit-content-file-box">

    <transition name="fade">
      <div :key="contentFile.ID">

        <h4 class="mb-3">{{ contentFileName }}</h4>

        <!--                <MorePopup :id="`content-file-${contentFile.ID}`" placement="right">-->
        <!--                    <EntityActionBox :entity="contentFile" :editAllowed="false"/>-->
        <!--                </MorePopup>-->

        <div class="aa-container">
          <div class="row">

            <div class="col-4">
              <AAImage class="mb-3" :imageUrl="posterUrl" v-on:image-loaded="previewLoaded = true"></AAImage>
              <button v-if="contentFile.fileType !== fileType.IMAGE" class="btn btn-primary btn-block"
                      @click="_onChoosePosterBtnClick">
                {{ $t('ChoosePoster') }}
              </button>
            </div>

            <div class="col-8">
              <span class="aa-file-type badge mb-3" :class="fileTypeClass">{{ fileTypeLabel }}</span>

              <transition name="fade">
                <div :key="contentFile.ID">

                  <b-form-group :label="$t('Name')">
                    <b-form-input v-model="validationContentFileName"
                                  :state="_calculateInputState($v.validationContentFileName)"
                                  @input="contentFileName = _onFormValidationInput($v.validationContentFileName, contentFileName)"
                                  tabindex="1"
                                  autofocus></b-form-input>
                    <b-form-invalid-feedback>
                      <span class="d-block" v-if="!$v.validationContentFileName.required">{{
                          $t('FieldRequiredSimple')
                        }}</span>
                      <span class="d-block" v-if="!$v.validationContentFileName.minLength">{{
                          $t('MinimumFieldLengthSimple', [3])
                        }}</span>
                    </b-form-invalid-feedback>
                  </b-form-group>

                  <b-form-group :label="$t('Notes')">
                    <b-form-textarea v-model="contentFileNotes"
                                     @input="_onFormInput"
                                     rows="5"
                                     tabindex="2" class="mb-3"></b-form-textarea>
                  </b-form-group>

                  <label class="btn btn-outline-secondary mb-0 mr-3"
                         v-if="contentFile.uploadStatus === uploadStatus.IDLE">
                    {{ $t('AssetManagementReplaceFile') }} <input type="file" ref="replaceFileBtn"
                                                                  v-on:change="_onReplaceFileBtnClick()" hidden>
                  </label>

<!--                  <button class="btn btn-primary mb-0" @click="_onOpenFileBtnClick"-->
<!--                          v-if="contentFile.uploadStatus === uploadStatus.IDLE">-->
<!--                    {{ $t('AssetManagementFileOpen') }}-->
<!--                  </button>-->
                  <button class="btn btn-primary mb-0" @click="_onOkBtnClick"
                          v-if="contentFile.uploadStatus === uploadStatus.IDLE">
                    {{ $t('Ok') }}
                  </button>

                  <!--                        <button  class="btn btn-outline-secondary btn-block" @click="_onDeleteFileBtnClick">-->
                  <!--                            {{ $t('DashboardShowDeleteButton') }}-->
                  <!--                        </button>-->
                </div>
              </transition>

              <transition name="fade">
                <b-spinner v-if="contentFile.uploadStatus === uploadStatus.UPLOADING"></b-spinner>
              </transition>

              <transition name="fade">
                <b-spinner type="grow" v-if="contentFile.uploadStatus === uploadStatus.QUEUED"></b-spinner>
              </transition>

            </div>

          </div>
        </div>

      </div>
    </transition>

    <b-modal id="poster-picker-box" size="xl" :title="$t('PickPosterOrUploadNewOne')" hide-footer>
      <AssetFoldersPicker :selectedFileUri="posterUrl"
                          :allowedTargetType="posterAllowedTargetType"
                          :allowedFileTypes="avatarAllowedFileTypes"
                          v-on:onAssetFilePicked="_onPosterFilePicked"></AssetFoldersPicker>
    </b-modal>
  </div>
</template>


<script lang="ts">
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import AssetFolderListModel from "@/asset_folder/_model/AssetFolderListModel";
import languageManager from "@/__libs/language_manager/LanguageManager";
import AppUserModel from "@/project/user/_model/AppUserModel";
import {UploadStatus} from "@/entity/_model/entity.constants";
import {FILE_TYPE_CONFIG, FileTargetType, FileType} from "@/asset_folder/_model/asset_folder.constants";
import fileManager from "@/_controller/FileManager";
import FileUtil from "@/__libs/utility/FileUtil";
import {minLength, required} from "vuelidate/lib/validators";
import AAImage from "@/_view/components/AAImage.vue";
import ContentFileModel from "@/content_file/_model/ContentFileModel";
import AssetFoldersPicker from "@/asset_folder/_view/AssetFoldersPicker.vue";
import AssetFileModel from "@/asset_folder/_model/AssetFileModel";
import contentFileController from "@/content_file/_controller/ContentFileController";
import EntityActionBox from "@/entity/_view/EntityActionBox.vue";
import MorePopup from "@/_view/components/MorePopup.vue";
import fileSize from "filesize";


@Component({
    components: {
        AAImage,
        AssetFoldersPicker,
        MorePopup,
        EntityActionBox
    },
    validations: {
        validationContentFileName: {
            required,
            minLength: minLength(3)
        }
    }
})
export default class EditContentFileBox extends Vue {
    //---------------------------------
    // Vue Component props
    //---------------------------------


    @Prop() private contentFile!: ContentFileModel;

    //---------------------------------
    // Vue Component data
    //---------------------------------

    private validationContentFileName: string = '';

    private uploadStatus: typeof UploadStatus = UploadStatus;

    private fileType: typeof FileType = FileType;

    private previewLoaded: boolean = false;

    public posterAllowedTargetType: FileTargetType = FileTargetType.PRESENTATION_POSTER;
    public avatarAllowedFileTypes: FileType[] = [FileType.IMAGE];
    //---------------------------------
    // Vue Computed properties
    //---------------------------------

    get contentFileName() {
        return languageManager.getTranslationForValue<string>(this.contentFile.name, AppUserModel.getInstance().langCode)
    }

    set contentFileName(value: string) {
        this.contentFile.name[AppUserModel.getInstance().langCode] = value;
    }

    get contentFileNotes() {
        return languageManager.getTranslationForValue<string>(this.contentFile.notes, AppUserModel.getInstance().langCode)
    }

    set contentFileNotes(value: string) {
        this.contentFile.notes[AppUserModel.getInstance().langCode] = value;
    }

    get contentFileUrl() {
        return fileManager.getFileUrl(this.contentFile.fileUri);
    }

    get posterUrl() {
        const posterUri: string = languageManager.getTranslationForValue(
          this.contentFile.poster,
          AppUserModel.getInstance().langCode
        );
        return fileManager.getFileUrl(posterUri);
    }

    // get filePreviewImageUrl()
    // {
    //     return fileManager.getFileUrl(this.contentFile.previewImageURI);
    // }


    get inputAccept(): string {
        return FILE_TYPE_CONFIG[this.contentFile.fileType].fileExtensions.join(",");
    }


    get fileTypeLabel() {
        return this.$t('FileType_' + FILE_TYPE_CONFIG[this.contentFile.fileType].identifier) as string;
    }

    get fileTypeClass() {
        return `aa-file-type--${FILE_TYPE_CONFIG[this.contentFile.fileType].identifier.toLowerCase()}`;
    }

    //---------------------------------
    // Public / lifecycle methods
    //---------------------------------

    public mounted() {
        this.validationContentFileName = this.contentFileName;
        this._setInputAccept();
    }

    //---------------------------------
    // Private / helper methods
    //---------------------------------

    private _calculateInputState(inputValidator: any): any {
        return inputValidator.$invalid ? false : null;
    }

    private _onFormValidationInput(inputValidator: any, value: any): void {
        if (!inputValidator.$invalid && this.contentFileName) {
            value = inputValidator.$model;
            this._onFormInput(null);
        }
        return value;
    }

    private _onFormInput(p_e: Event | null) {
        if (this.contentFile) {
            this.contentFile.hasChanges = true;
        }
    }


    private async _onReplaceFileBtnClick(p_e: Event) {
        const replaceFileBtn: HTMLInputElement = this.$refs.replaceFileBtn as HTMLInputElement;
        if (replaceFileBtn.files && replaceFileBtn.files.length > 0) {
            if (await this.checkValidFileSelect(replaceFileBtn.files[0])) {
                await contentFileController.setFile(this.contentFile, replaceFileBtn.files[0]);
            }
        }
    }

    private async checkValidFileSelect(p_file: File): Promise<boolean> {
        const fileType: FileType = AssetFolderListModel.getFileTypeForExtension(FileUtil.getFileExtension(p_file.name));
        if (fileType === this.contentFile.fileType) {
            if (p_file.size > AppUserModel.getInstance().project.tierConfig.maxFileSize) {
                await this.$bvModal.msgBoxOk(this.$t('ErrorMaxSize', [fileSize(AppUserModel.getInstance().project.tierConfig.maxFileSize)]) as string, {centered: true});
                return false;
            }
            return true;
        } else {
            await this.$bvModal.msgBoxOk(this.$t('ErrorFileType', [this.inputAccept]) as string, {centered: true});
            return false;
        }
    }


    private async _onOpenFileBtnClick(p_e: Event) {
        window.open(this.contentFileUrl, "_blank");
    }


    private async _onChoosePosterBtnClick(p_e: Event) {
        this.$bvModal.show('poster-picker-box');
    }


    private _setInputAccept() {
        const replaceFileBtn: HTMLInputElement = this.$refs.replaceFileBtn as HTMLInputElement;
        if (this.contentFile && replaceFileBtn) {
            replaceFileBtn.accept = this.inputAccept;
        }
    }

    private _onPosterFilePicked(p_assetFile: AssetFileModel) {
        this.$bvModal.hide('poster-picker-box');
        if (p_assetFile && this.contentFile) {
            this.contentFile.poster[AppUserModel.getInstance().langCode] = p_assetFile.fileURI;
            this.contentFile.hasChanges = true;
        }
    }

    private _onOkBtnClick(p_e: Event) {
        this.$emit('onFinish');
    }

}
</script>
