import ContentModel from "@/content/_model/ContentModel";
import PlaylistListModel from "@/playlist/_model/PlaylistListModel";
import PlaylistModel from "@/playlist/_model/PlaylistModel";
import {DtoType} from "@/_model/app.constants";
import apiManager, {ApiRequestType, ApiResponse} from "@/_controller/ApiManager";
import {ICreateResultDto, IUpdateResultDto} from "@/entity/_model/entity.dto";
import toastManager, {ToastType} from "@/__libs/toast_manager/ToastManager";
import i18n from "@/__plugins/i18n";
import {IPlaylistEntryDto} from "@/playlist/_model/playlist.dto";


class PlaylistController {


    //---------------------------------
    // Properties
    //---------------------------------

    private _playlistListModel: PlaylistListModel = PlaylistListModel.getInstance();


    //---------------------------------
    // Controller Methods
    //---------------------------------

    public async getViewableTeams(p_playlist: PlaylistModel): Promise<boolean> {
        const endPoint: string = `/client-api/playlists/${p_playlist.playlistID}`;
        const response: ApiResponse<PlaylistModel> = await apiManager.sendApiRequest(ApiRequestType.GET, endPoint);

        return response.hasSucceeded;
    }

    public async createPlaylist(p_playlist: PlaylistModel): Promise<boolean> {

        const endPoint: string = `/client-api/playlists`;
        const response: ApiResponse<ICreateResultDto> = await apiManager.sendApiRequest(ApiRequestType.POST, endPoint, p_playlist.mapToDto(DtoType.BODY));
        if (response.hasSucceeded) {
            p_playlist.isEditable = true;
            p_playlist.playlistID = (response.result as any).ID;
            this._playlistListModel.playlists.unshift(p_playlist)

        }
        return response.hasSucceeded;
    }


    public async savePlaylist(p_playlist: PlaylistModel, noToast: boolean = false): Promise<boolean> {

        const endPoint: string = `/client-api/playlists/${p_playlist.playlistID}`;
        const response: ApiResponse<ICreateResultDto> = await apiManager.sendApiRequest(ApiRequestType.PUT, endPoint, p_playlist.mapToDto(DtoType.BODY));
        if (!noToast) {
            if (response.hasSucceeded) {
                toastManager.showToast(i18n.t('PlaylistSaveSuccess') as string, ToastType.SUCCESS);
            } else {
                toastManager.showToast(i18n.t('PlaylistSaveFailed') as string, ToastType.DANGER);
            }
        }
        return response.hasSucceeded;
    }

    public async deletePlaylist(p_playlist: PlaylistModel): Promise<boolean> {
        const endPoint: string = `/client-api/playlists/${p_playlist.playlistID}`;
        const response: ApiResponse<ICreateResultDto> = await apiManager.sendApiRequest(ApiRequestType.DELETE, endPoint);
        if (response.hasSucceeded) {
            this._playlistListModel.deletePlaylist(p_playlist);
        }
        return response.hasSucceeded;
    }

    public async addContentToPlaylist(p_playlist: PlaylistModel, p_content: ContentModel, p_index: number = -1) {
        const entry: IPlaylistEntryDto = {contentID: p_content.ID, entryOrder: p_index};
        if (p_index === -1) {
            p_playlist.entries.push(entry);
        } else {
            p_playlist.entries.splice(p_index, 0, entry);
        }
        this.reassingEntryIndices(p_playlist);
        await this.savePlaylist(p_playlist);

    }

    public async reorderEntryInPlaylist(p_playlist: PlaylistModel, p_entry: IPlaylistEntryDto, p_toIndex: number) {

        const fromIndex = p_playlist.entries.indexOf(p_entry);
        if (fromIndex >= 0 && p_toIndex < p_playlist.entries.length) {
            if (p_toIndex >= p_playlist.entries.length) {
                let k = p_toIndex - p_playlist.entries.length + 1;
                while (k--) {
                    p_playlist.entries.push(undefined as any);
                }
            }
            p_playlist.entries.splice(p_toIndex, 0, p_playlist.entries.splice(fromIndex, 1)[0]);
        }

        this.reassingEntryIndices(p_playlist);
        await this.savePlaylist(p_playlist, true);

    }

    public async deletePlaylistEntry(p_playlist: PlaylistModel, p_entry: IPlaylistEntryDto) {
        const deleteIndex = p_playlist.entries.indexOf(p_entry);
        p_playlist.entries.splice(deleteIndex, 1);

        this.reassingEntryIndices(p_playlist);
        await this.savePlaylist(p_playlist);

    }


    private reassingEntryIndices(p_playlist: PlaylistModel) {
        for (let i = 0; i < p_playlist.entries.length; i++) {
            p_playlist.entries[i].entryOrder = i;
        }
    }

    //---------------------------------
    // Private Methods
    //---------------------------------


}

//Singleton export
export default new PlaylistController();
