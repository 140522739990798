<template>
  <div class="base-page analytics-content">

    <div class="analytics-content__filter d-flex justify-content-end align-items-end flex-column">
      <div class="small mb-1">{{ $t('WhosContentAnalyticsToShow') }}</div>
      <select v-if="showTargetSelector" class="form-control mb-3"
              v-model="analyticsModel.activeTargetType"
              @change="_onFormInput" tabindex="5">
        <option v-for="type in targetOptions" :key="type"
                :value="type">{{ $t(type) }}
        </option>
      </select>
    </div>

    <div class="aa-container-fluid h-100">
      <div class="row h-100">

        <div class="col-3 h-100">
          <div class="box h-100" :id="hintIds.CONTENTINSIGHTS_CONTENT">

            <div class="subjectTypes-overview">

              <transition name="fade">
                <div>

                  <AnalyticsSubjectTypeItemRenderer
                      v-for="subjectType in contentTypes"
                      :subjectType="subjectType"
                      :key="subjectType.identifier"
                      v-on:onClick="_onSubjectTypeClick"
                      :selState="selState"
                  />
                </div>
              </transition>


            </div>

          </div>
        </div>

        <div class="col-9 h-100">

          <transition name="fade" v-if="currentLoadingState === loadingState.IDLE">
            <div class="box h-100">
              <div class="placeholder-text text-secondary d-flex align-items-center justify-content-center h-100">
                {{ $t('PleaseSelectAnalytics') }}
              </div>
            </div>
          </transition>

          <transition name="fade" v-if="currentLoadingState === loadingState.LOADING">
            <div class="placeholder-text text-secondary d-flex align-items-center justify-content-center h-100">
              <b-spinner variant="dark" class="my-5"></b-spinner>
            </div>
          </transition>

          <transition name="fade" v-if="currentLoadingState === loadingState.LOADED">
            <AnalyticsContentDetailPage :contentData="contentData"></AnalyticsContentDetailPage>
          </transition>

        </div>
      </div>
    </div>

    <b-popover custom-class="hint-popover" :target="hintIds.CONTENTINSIGHTS_CONTENT"
               placement="right"
               triggers="manual"
               boundary="window">
      <Hint :id="hintIds.CONTENTINSIGHTS_CONTENT"></Hint>
    </b-popover>
  </div>
</template>

<script lang="ts">
import {Component, Vue, Watch} from "vue-property-decorator";
import TeamListModel from "@/team/_model/TeamListModel";
import SelectionState from "@/__libs/_model/SelectionState";
import TeamModel from "@/team/_model/TeamModel";
import {
    IAnalyticsContentTypeCfgDto,
    IAnalyticsSubjectTypeCfgDto, IAnalyticsWidgetDataDto,
    IContentTypeAnalyticsDto
} from "@/analytics/_model/analytics.dto";
import AnalyticsModel from "@/analytics/_model/AnalyticsModel";
import AppUserModel from "@/project/user/_model/AppUserModel";
import AnalyticsSubjectTypeItemRenderer from "@/analytics/_view/content/AnalyticsSubjectTypeItemRenderer.vue";
import AnalyticsContentDetailPage from "@/analytics/_view/content/AnalyticsContentDetailPage.vue";
import {AnalyticsTargetType} from "@/analytics/_model/analytics.constants";
import analyticsController from "@/analytics/_controller/AnalyticsController";
import {RIGHTS} from "@/team/_model/role.constants";
import {HintGroupIds, HintIds} from "@/help/hint/_model/hint.constants";
import Hint from "@/help/hint/_view/Hint.vue";
import HintListController from "@/help/hint/_controller/HintListController";

enum LoadingState {
    IDLE,
    LOADING,
    LOADED,
    ERROR_LOADING
}


@Component({
    components: {
        Hint,
        AnalyticsSubjectTypeItemRenderer, AnalyticsContentDetailPage
    }
})
export default class AnalyticsContentPage extends Vue {
    private hintIds: typeof HintIds = HintIds;

    private loadingState: typeof LoadingState = LoadingState;

    private currentLoadingState: LoadingState = LoadingState.IDLE;

    private contentData: IContentTypeAnalyticsDto | null = null;

    public selState: SelectionState<IContentTypeAnalyticsDto> = AnalyticsModel.getInstance().subjectTypeSelState;

    //---------------------------------
    // Vue Computed properties
    //---------------------------------

    get showTargetSelector() {
        return AppUserModel.getInstance().rights.indexOf(RIGHTS.VIEW_TEAM_ANALYTICS.identifier) >= 0;
    }

    get targetOptions() {
        return [AnalyticsTargetType.MYSELF, AnalyticsTargetType.MY_TEAMS];
    }

    get contentTypes(): IContentTypeAnalyticsDto[] {
        return AppUserModel.getInstance().project.analyticsCfg.contentTypes;
    }

    get analyticsModel() {

        return AnalyticsModel.getInstance();
    }

    get range() {
        return AnalyticsModel.getInstance().range;
    }

    //---------------------------------
    // Public / lifecycle methods
    //---------------------------------

    public mounted() {
        HintListController.startGroup(HintGroupIds.CONTENTINSIGHTS);
    }

    //---------------------------------
    // Private / helper methods
    //---------------------------------

    private async _onSubjectTypeClick(p_subjectType: IContentTypeAnalyticsDto, p_e: Event) {
        this.selState.selected = p_subjectType;
        await this._getContentTypeData();
    }

    private async _onFormInput(p_e: Event | null) {
        await this._getContentTypeData();
    }


    @Watch('range', {immediate: true, deep: false})
    private async _getContentTypeData() {
        if (this.selState.selected) {
            this.currentLoadingState = LoadingState.LOADING;
            this.contentData = null;
            this.contentData = await analyticsController.getContentTypeData(this.selState.selected.cfg.identifier);
            if (this.contentData) {
                this.currentLoadingState = LoadingState.LOADED;
            } else {
                this.currentLoadingState = LoadingState.ERROR_LOADING;
            }
        }
    }

}
</script>
