export enum SyncStage
{
    CATEGORY_RETRIEVAL,
    CATEGORY_PROCESS,
    FILE_RETRIEVAL
    // MANIFEST,
    // RETRIEVAL,
    // CLEANUP
}


export enum SyncStatus
{
    IDLE,
    SYNCING,
    PAUSING,
    PAUSED,
    CANCELLING,
    CANCELLED,
    SYNCED_WITH_ERRORS
}

export enum SyncProcessStatus
{
    IDLE,
    PROCESSING,
    PAUSING,
    PAUSED,
    CANCELLING,
    CANCELLED,
    FINISHED
}


export enum SyncPauseReason
{
    USER_DEMAND,
    NO_CONNECTIVITY,
    ON_MOBILE_DATA,
    STORAGE_ERROR,
    PRESENTATION_IN_PROGRESS
}


export enum SyncProcessStep
{
    FETCHING_ITEM_FILES,
    PARSING_ITEM_FILES
}

//
export enum SyncProcessType
{
    PRESENTATION = "PRESENTATION",
    CONTENT_APP = "CONTENT_APP",
    CONTENT_FILE = "CONTENT_FILE",
    AUDIENCE = "AUDIENCE",
    COMPANY = "COMPANY",
    SLIDE = "SLIDE",
    CONSTRUCT = "CONSTRUCT",
    CONTENT_APP_RESOURCE = "CONTENT_APP_RESOURCE",
    MODULE = "MODULE",
    SCHEMA = "SCHEMA",
    DATA_PROVIDER = "DATA_PROVIDER",

    DATA = "DATA",
    FILE_GROUP = "FILE_GROUP",

    META = "META",
    FILE_GROUP_FILE = "FILE_GROUP_FILE",


}

export enum SyncFileRetrieveStatus
{
    IDLE,
    RETRIEVING,
    RETRIEVED,
    ERROR
}
