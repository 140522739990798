

class Dictionary<I, K>
{

    //---------------------------------
    // Public Properties
    //---------------------------------


    public get length() : number
    {
        let ln:number = 0;
        for (let key in this._dict) {
            if (this._dict.hasOwnProperty(key)) {
                ln++
            }
        }

        return ln;
    }


    public get keys() : K[]
    {
        let keys:K[] = [];
        for (let key in this._dict) {
            if (this._dict.hasOwnProperty(key)) {
                keys.push(key as any);
            }
        }
        return keys;
    }


    public get items() : I[]
    {
        let items = [];
        for (let key in this._dict) {
            if (this._dict.hasOwnProperty(key)) {
                items.push(this._dict[key]);
            }
        }
        return items;
    }



    //---------------------------------
    // dict
    //---------------------------------



    private _dict : any = {};

    public get dict() : any
    {
        return this._dict;
    }

    public set dict(value : any)
    {
        if (this._dict !== value)
        {
            this._dict = value;
        }
    }




    //---------------------------------
    // private properties
    //---------------------------------


    //---------------------------------
    // Singleton Constructor
    //---------------------------------


    constructor(p_dict:any = {})
    {
        this.dict = p_dict;

    }



    //---------------------------------
    // public properties
    //---------------------------------


    public getItem(key:K):I
    {
        return this._dict[key as any] as I;
    }

    public setItem(item:I, key:K):boolean
    {
        const keyExists:boolean = this.keyExists(key);
        this._dict[key as any]  = item;
        return keyExists;
    }


    public deleteItem(key:K):boolean
    {
        if(this.keyExists(key)){
            this._dict[key as any] = null;
            delete this._dict[key as any];
            return true;
        }
        return false;
    }


    public keyExists(key:K):boolean
    {
        return this._dict.hasOwnProperty(key);
    }


    public itemExists(item:I):boolean
    {
        for (let key in this._dict) {
            if (this._dict.hasOwnProperty(key)) {
                if(this._dict[key] === item){
                    return true;
                }
            }
        }
        return false;
    }

}

export default Dictionary;
