<template>
    <div class="row h-100 content__detail">


        <div class="col-7 h-100">
            <div class="box h-100">


                <!--                        old widget style-->
                <!--                        <AnalyticsWidgetItemRenderer-->
                <!--                                v-for="widgetCfg in subjectType.widgets"-->
                <!--                                :widgetCfg="widgetCfg"-->
                <!--                                :key="widgetCfg.identifier"-->
                <!--                        />-->


                <h5 class="aa-title mt-1 mb-3">{{ overviewTitle }}</h5>

              <div class="row mb-4">
                <div class="col-5">
                  <AnalyticsContentPrimeBox :primeBox="contentData.primeBox"/>
                </div>
                <div class="col-7">
                  <div class="row">
                    <div class="col-4">
                      <AnalyticsContentSubBox :subBox="contentData.subBox1"/>
                    </div>
                    <div class="col-4">
                      <AnalyticsContentSubBox :subBox="contentData.subBox2"/>
                    </div>
                    <div class="col-4">
                      <AnalyticsContentSubBox :subBox="contentData.subBox3"/>
                    </div>
                  </div>
                </div>
              </div>

                <h5 class="aa-title mb-3">{{ actionTotalsTitle }}</h5>
                <AnalyticsContentActionTotalsGraph :actionTotals="contentData.actionTotals"/>

            </div>
        </div>

        <div class="col-5 h-100">
            <div class="box h-100">
                <h5 class="aa-title mt-1 mb-3">{{ subjectListTitle }}</h5>
                <AnalyticsContentSubjectList :subjectList="contentData.subjectList"/>

            </div>
        </div>

    </div>

</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';

import SelectionState from "@/__libs/_model/SelectionState";
import {
    IAnalyticsSubjectTypeCfgDto,
    IAnalyticsWidgetDataDto,
    IContentTypeAnalyticsDto
} from "@/analytics/_model/analytics.dto";
import AnalyticsWidgetItemRenderer from "@/analytics/_view/content/AnalyticsWidgetItemRenderer.vue";
import languageManager from "@/__libs/language_manager/LanguageManager";
import AppUserModel from "@/project/user/_model/AppUserModel";
import analyticsController from "@/analytics/_controller/AnalyticsController";
import AnalyticsModel from "@/analytics/_model/AnalyticsModel";
import AnalyticsContentPrimeBox from "@/analytics/_view/content/AnalyticsContentPrimeBox.vue";
import AnalyticsContentSubBox from "@/analytics/_view/content/AnalyticsContentSubBox.vue";
import AnalyticsContentActionTotalsGraph from "@/analytics/_view/content/AnalyticsContentActionTotalsGraph.vue";
import AnalyticsContentSubjectList from "@/analytics/_view/content/AnalyticsContentSubjectList.vue";



@Component({
    components: {AnalyticsWidgetItemRenderer, AnalyticsContentPrimeBox, AnalyticsContentSubBox,  AnalyticsContentActionTotalsGraph, AnalyticsContentSubjectList}
})
export default class AnalyticsContentDetailPage extends Vue
{



    //---------------------------------
    // Vue Component props
    //---------------------------------

    @Prop() private contentData!:IContentTypeAnalyticsDto;

    //---------------------------------
    // Vue Component data
    //---------------------------------



    //---------------------------------
    // Vue Computed properties
    //---------------------------------

    get analyticsModel()
    {

        return AnalyticsModel.getInstance();
    }


    get subjectTypeName()
    {
        return AnalyticsModel.resolveLabelTranslation(this.contentData.cfg.name)
    }

    get overviewTitle()
    {
        return AnalyticsModel.resolveLabelTranslation(this.contentData.cfg.overViewTitle)
    }

    get actionTotalsTitle()
    {
        return AnalyticsModel.resolveLabelTranslation(this.contentData.actionTotals.cfg.title as string)
    }

    get subjectListTitle()
    {
        return AnalyticsModel.resolveLabelTranslation(this.contentData.subjectList.cfg.title as string)
    }

    //---------------------------------
    // Public / lifecycle methods
    //---------------------------------

    mounted()
    {
    }



    //---------------------------------
    // Private / helper methods
    //---------------------------------


}
</script>

