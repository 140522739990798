<template>
  <div class="sub-page gamification-page">

    <transition name="fade">
      <div v-if="currentState === gamificationPageState.LOADING"
           class="empty-loader">
        <b-spinner variant="dark"></b-spinner>
      </div>
    </transition>

    <transition name="fade">
      <div v-if="currentState === gamificationPageState.ERROR_LOADING" class="error-message">
        {{ $t('ErrorConnectionRequired') }}
      </div>
    </transition>

    <transition name="fade">
      <div v-if="currentState === gamificationPageState.LOADED"
           class="gamification-page__content">
        <GamificationSummary></GamificationSummary>
        <GamificationRulebook></GamificationRulebook>
        <GamificationMissions :start-date="startDate"></GamificationMissions>
        <GamificationLeaderboard :start-date="startDate"></GamificationLeaderboard>
        <GamificationFlow></GamificationFlow>
      </div>
    </transition>

    <b-modal id="gamification-new-level-box" hide-header hide-footer centered size="lg">
      <NewLevelBox></NewLevelBox>
    </b-modal>

    <b-modal id="gamification-new-status-box" hide-header hide-footer centered>
      <NewStatusBox></NewStatusBox>
    </b-modal>

    <b-modal id="gamification-introduction-box" hide-footer centered size="lg" :title="$t('GamificationIntroductionTitle')">
      <GamificationIntroductionBox></GamificationIntroductionBox>
    </b-modal>

  </div>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import AppUserModel from "@/project/user/_model/AppUserModel";
import {IGamificationProjectConfigDto} from "@/gamification/_model/gamification.dto";
import gamificationController from "@/gamification/_controller/GamificationController";
import {RIGHTS} from "@/team/_model/role.constants";
import {ITeamOutlineDto} from "@/team/_model/team.dto";
import GamificationSummary from "@/gamification/summary/_view/GamificationSummary.vue";
import analyticsController from "@/analytics/_controller/AnalyticsController";
import GamificationRulebook from "@/gamification/rulebook/_view/GamificationRulebook.vue";
import GamificationFlow from "@/gamification/flow/_view/GamificationFlow.vue";
import GamificationMissions from "@/gamification/missions/_view/GamificationMissions.vue";
import GamificationLeaderboard from "@/gamification/leaderboard/_view/GamificationLeaderboard.vue";
import AppModel from "@/_model/AppModel";
import {IDeepLinkParamsDto} from "@/_model/app.dto";
import NewLevelBox from "@/gamification/_view/NewLevelBox.vue";
import JsonUtil from "@/__libs/utility/JsonUtil";
import LocalStorageManager from "@/__libs/offline_storage/LocalStorageManager";
import {LocalStorageKey} from "@/_model/app.constants";
import GamificationUtil from "@/gamification/_controller/GamificationUtil";
import NewStatusBox from "@/gamification/_view/NewStatusBox.vue";
import GamificationIntroductionBox from "@/gamification/_view/GamificationIntroductionBox.vue";

enum GamificationPageState {
  IDLE,
  LOADING,
  ERROR_LOADING,
  LOADED
}

@Component({
  components: {
    GamificationIntroductionBox,
    NewStatusBox,
    NewLevelBox,
    GamificationLeaderboard,
    GamificationMissions, GamificationFlow, GamificationSummary, GamificationRulebook
  },
})
export default class GamificationPage extends Vue {

  private gamificationPageState: typeof GamificationPageState = GamificationPageState;
  private currentState: GamificationPageState = GamificationPageState.IDLE;
  private startDate: Date = new Date();

  public appUser: AppUserModel = AppUserModel.getInstance();

  public async mounted() {
    this.currentState = GamificationPageState.LOADING;
    const hasSucceeded: boolean = await gamificationController.getUserState();
    if (hasSucceeded) {

      // Direct link with custom month: Show this month
      if (AppModel.getInstance().deepLinkParams && AppModel.getInstance().deepLinkParams?.routeParams) {
        const targetMonthParam: string = AppModel.getInstance().deepLinkParams?.routeParams.split('|')[1];
        this.startDate = new Date(parseInt(targetMonthParam.split('-')[0]), parseInt(targetMonthParam.split('-')[1]));
        AppModel.getInstance().deepLinkParams = null;
      }

      // Check evolution status
      const statusIndexBefore: number | null = LocalStorageManager.retrieveValue(LocalStorageKey.GAMIFICATION_STATUS_INDEX) as number | null;
      const statusIndexCurrent: number = GamificationUtil.getStatusIndex(this.appUser.gamificationState.coins, this.appUser.project.config.gamification.status);
      if (statusIndexBefore) {
        if (statusIndexCurrent > statusIndexBefore) {
          this.$bvModal.show('gamification-new-status-box');
        }
      }
      LocalStorageManager.storeValue(LocalStorageKey.GAMIFICATION_STATUS_INDEX, statusIndexCurrent as unknown as string);

      // Check evolution level
      const levelIndexBefore: number | null = LocalStorageManager.retrieveValue(LocalStorageKey.GAMIFICATION_LEVEL_INDEX) as number | null;
      const levelIndexCurrent: number = GamificationUtil.getLevelIndex(this.appUser.gamificationState.coins, this.appUser.project.config.gamification.levels);
      if (levelIndexBefore) {
        if (levelIndexCurrent > levelIndexBefore) {
          this.$bvModal.show('gamification-new-level-box');
        }
      }
      LocalStorageManager.storeValue(LocalStorageKey.GAMIFICATION_LEVEL_INDEX, levelIndexCurrent as unknown as string);

      this.currentState = GamificationPageState.LOADED;
    } else {
      this.currentState = GamificationPageState.ERROR_LOADING;
    }

    // introduction box
    const gamificationIntroductionBoxShown: boolean = JsonUtil.parse(LocalStorageManager.retrieveValue(LocalStorageKey.GAMIFICATION_INTRODUCTION_BOX_SHOWN) as string) ? true : false;
    if (!gamificationIntroductionBoxShown) {
      this.$bvModal.show('gamification-introduction-box');
      LocalStorageManager.storeValue(LocalStorageKey.GAMIFICATION_INTRODUCTION_BOX_SHOWN, JsonUtil.stringify(true));
    }
  }
}
</script>

