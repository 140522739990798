import SyncItemProcess from "@/sync/_controller/process/SyncItemProcess";
import {ISyncCategoryDto, ISyncItemDto} from "@/sync/_model/sync.dto";
import {SyncProcessType} from "@/sync/_model/sync.constants";
import SyncPresentationProcess from "@/sync/_controller/process/SyncPresentationProcess";
import SyncFileGroupProcess from "@/sync/_controller/process/SyncFileGroupProcess";
import SyncDataProcess from "@/sync/_controller/process/SyncDataProcess";
import SyncModuleProcess from "@/sync/_controller/process/SyncModuleProcess";
import SyncSlideProcess from "@/sync/_controller/process/SyncSlideProcess";
import SyncAudienceProcess from "@/sync/_controller/process/SyncAudienceProcess";
import SyncConstructProcess from "@/sync/_controller/process/SyncConstructProcess";
import SyncSchemaProcess from "@/sync/_controller/process/SyncSchemaProcess";
import {StorageScope} from "@/_model/app.constants";
import SyncContentAppProcess from "@/sync/_controller/process/SyncContentAppProcess";
import SyncContentFileProcess from "@/sync/_controller/process/SyncContentFileProcess";
import SyncDataProviderProcess from "@/sync/_controller/process/SyncDataProviderProcess";
import SyncContentAppResourceProcess from "@/sync/_controller/process/SyncContentAppResourceProcess";
import SyncCompanyProcess from "@/sync/_controller/process/SyncCompanyProcess";


export default class SyncItemProcessFactory
{


    public static create(p_processType:SyncProcessType, p_dependencyLevel?:number, p_syncItems?:ISyncItemDto[], p_storagePath?:string, p_storageScope?:StorageScope):SyncItemProcess
    {
        switch (p_processType)
        {
            case SyncProcessType.PRESENTATION:
                return new SyncPresentationProcess(p_processType, p_dependencyLevel, p_syncItems, p_storagePath, p_storageScope);

            case SyncProcessType.CONTENT_APP:
                return new SyncContentAppProcess(p_processType, p_dependencyLevel, p_syncItems, p_storagePath, p_storageScope);

            case SyncProcessType.CONTENT_FILE:
                return new SyncContentFileProcess(p_processType, p_dependencyLevel, p_syncItems, p_storagePath, p_storageScope);


            case SyncProcessType.SLIDE:
                return new SyncSlideProcess(p_processType, p_dependencyLevel, p_syncItems, p_storagePath, p_storageScope);

            case SyncProcessType.DATA_PROVIDER:
                return new SyncDataProviderProcess(p_processType, p_dependencyLevel, p_syncItems, p_storagePath, p_storageScope);

            case SyncProcessType.MODULE:
                return new SyncModuleProcess(p_processType, p_dependencyLevel, p_syncItems, p_storagePath, p_storageScope);

            case SyncProcessType.CONSTRUCT:
                return new SyncConstructProcess(p_processType, p_dependencyLevel, p_syncItems, p_storagePath, p_storageScope);

            case SyncProcessType.CONTENT_APP_RESOURCE:
                return new SyncContentAppResourceProcess(p_processType, p_dependencyLevel, p_syncItems, p_storagePath, p_storageScope);

            case SyncProcessType.AUDIENCE:
                return new SyncAudienceProcess(p_processType, p_dependencyLevel, p_syncItems, p_storagePath, p_storageScope);

            case SyncProcessType.COMPANY:
                return new SyncCompanyProcess(p_processType, p_dependencyLevel, p_syncItems, p_storagePath, p_storageScope);

            case SyncProcessType.SCHEMA:
                return new SyncSchemaProcess(p_processType, p_dependencyLevel, p_syncItems, p_storagePath, p_storageScope);


            case SyncProcessType.DATA:
                return new SyncDataProcess(p_processType, p_dependencyLevel, p_syncItems, p_storagePath, p_storageScope);

            case SyncProcessType.FILE_GROUP:
                return new SyncFileGroupProcess(p_processType, p_dependencyLevel, p_syncItems, p_storagePath, p_storageScope);

            default:
                return new SyncFileGroupProcess( p_processType, p_dependencyLevel, p_syncItems, p_storagePath, p_storageScope);
        }

    }

}

