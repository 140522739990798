<template>
  <div class="project-logo" v-if="fileUrl && fileName" :class="{ 'project-logo--dark': dark, 'project-logo--idle': isIdle }">

    <transition name="fade">
      <AAImage :image-url="url" :key="fileName"></AAImage>
    </transition>

    <div class="project-logo__change">
      <i class="fas fa-exchange-alt"></i>
    </div>

    <input type="file" ref="logo" v-on:change="changeLogo()" accept=".png">

    <transition name="fade">
      <div v-if="isWorking" class="project-logo__working">
        <b-spinner></b-spinner>
      </div>
    </transition>

  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import ProjectModel from "@/project/_model/ProjectModel";
import AppUserModel from "@/project/user/_model/AppUserModel";
import projectController from "@/project/_controller/ProjectController";
import {minLength, required} from "vuelidate/lib/validators";
import assetFolderController from "@/asset_folder/_controller/AssetFolderController";
import SelectionState from "@/__libs/_model/SelectionState";
import TeamModel from "@/team/_model/TeamModel";
import AAImage from "@/_view/components/AAImage.vue";

enum ProjectLogoState {
  IDLE,
  WORKING,
  ERROR
}

@Component({
  components: {AAImage},
})
export default class ProjectLogo extends Vue {

  public project: ProjectModel = AppUserModel.getInstance().project;
  private currentState: ProjectLogoState = ProjectLogoState.IDLE;
  public url: string = '';

  @Prop() private fileUrl!: string;
  @Prop() private fileName!: string;
  @Prop() private dark!: boolean;

  mounted() {
    this.url = this.fileUrl;
  }

  get isIdle(): boolean {
    return this.currentState === ProjectLogoState.IDLE;
  }

  get isWorking(): boolean {
    return this.currentState === ProjectLogoState.WORKING;
  }

  private async changeLogo() {
    if (this.isIdle) {
      this.currentState = ProjectLogoState.WORKING;
      const logo: HTMLInputElement = this.$refs.logo as HTMLInputElement;
      if (logo.files && logo.files.length > 0) {
        const file: File = logo.files[0];
        await projectController.updateSystemFile(this.fileName, file);
        this.project.refreshPaths();
        this.url = URL.createObjectURL(file);
        // DIRTY a bit. Sorry TomV. If dark = true we know we have to change the main pwa icon so lets change the file.
        if (this.dark) {
          this.project.projectLogoFile = file;
        }
      }
      this.currentState = ProjectLogoState.IDLE;

      // await assetFolderController.replaceFile(this.selState.selected, this.assetFile, replaceFileBtn.files[0]);
    }
  }

}
</script>

