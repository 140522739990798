<template>
  <div class="box box--full-height list-overview asset-folders-overview" :id="hintIds.FILES_DOCUMENTATION">

    <div class="list-overview-filters">
      <b-input-group>
        <template #prepend>
          <b-input-group-text><i class="fas fa-search"></i></b-input-group-text>
        </template>
        <b-form-input v-model="selState.searchFilter"
                      :placeholder="$t('AssetManagementSearchFolder')"></b-form-input>
      </b-input-group>
    </div>

    <b-button block variant="primary" class="list-overview-button" @click="_onCreateAssetFolderBtnClick">
      {{ $t('AssetManagementCreateFolder') }}
    </b-button>

    <div class="list-overview-items">
      <AssetFolderItemRenderer
          v-for="assetFolder in filteredAssetFolders"
          :assetFolder="assetFolder"
          :key="assetFolder.assetFolderID"
          v-on:onClick="_onAssetFolderClick"
          :selState="selState"
      />
    </div>

    <b-modal id="create-asset-folder-box" :title="$t('AssetManagementCreateFolder')" hide-footer>
      <CreateAssetFolderBox v-on:onFinish="_onCreateFinish"></CreateAssetFolderBox>
    </b-modal>

    <b-popover custom-class="hint-popover" :target="hintIds.FILES_DOCUMENTATION"
               placement="right"
               triggers="manual"
               boundary="window">
      <Hint :id="hintIds.FILES_DOCUMENTATION"></Hint>
    </b-popover>

  </div>
</template>


<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import AssetFolderItemRenderer from "@/asset_folder/_view/AssetFolderItemRenderer.vue";
import AssetFolderModel from "@/asset_folder/_model/AssetFolderModel";
import assetFolderListController from "@/asset_folder/_controller/AssetFolderListController";
import AssetFolderListModel from "@/asset_folder/_model/AssetFolderListModel";
import {LoadingStatus} from "@/entity/_model/entity.constants";
import languageManager from "@/__libs/language_manager/LanguageManager";
import AppUserModel from "@/project/user/_model/AppUserModel";
import AssetFolderSelectionState from "@/asset_folder/_model/AssetFolderSelectionState";
import CreateAssetFolderBox from "@/asset_folder/_view/CreateAssetFolderBox.vue";
import {HintIds} from "@/help/hint/_model/hint.constants";
import Hint from "@/help/hint/_view/Hint.vue";

@Component({
    components: {
        Hint,
        AssetFolderItemRenderer, CreateAssetFolderBox
    },
})
export default class AssetFoldersOverview extends Vue {

    @Prop() private selState!: AssetFolderSelectionState;

    private hintIds: typeof HintIds = HintIds;
    private assetFolderListModel: AssetFolderListModel = AssetFolderListModel.getInstance();
    private assetFolders: AssetFolderModel[] = this.assetFolderListModel.list;
    private showCreateModal: boolean = false;

    get filteredAssetFolders() {
        if (this.selState.searchFilter.length > 1) {
            return this.assetFolders.filter(assetFolder => {
                return (
                  languageManager
                    .getTranslationForValue<string>(assetFolder.name, AppUserModel.getInstance().langCode)
                    .toLowerCase()
                    .indexOf(this.selState.searchFilter.toLowerCase()) > -1
                );
            });
        } else {
            return this.assetFolders;
        }
    }

    public mounted() {
        assetFolderListController.fetchAllEntities();
    }

    private async _onAssetFolderClick(p_assetFolder: AssetFolderModel, p_e: Event) {
        if (p_assetFolder.loadingStatus === LoadingStatus.BODY_LOADED) {
            this.selState.selected = p_assetFolder;
        }
    }

    private _onCreateAssetFolderBtnClick(p_e: Event) {
        this.$bvModal.show('create-asset-folder-box');
    }

    private _onCreateFinish(p_createdAssetFolder: AssetFolderModel) {
        this.$bvModal.hide('create-asset-folder-box');
        if (p_createdAssetFolder) {
            this.selState.selected = p_createdAssetFolder;
        }
    }

}
</script>
