import AppUserModel from "@/project/user/_model/AppUserModel";
import apiManager, {ApiRequestType, ApiResponse} from "@/_controller/ApiManager";
import {IUpdateResultDto} from "@/entity/_model/entity.dto";
import ProjectModel from "@/project/_model/ProjectModel";
import {DtoType, IUploadFileResultDto, TranslationStatus} from "@/_model/app.constants";
import {ITranslateTextJobDto, ITranslationDto, ITranslationGroupDto, ITranslationMultiLangDto} from "@/_model/app.dto";
import {Workbook, Worksheet} from "exceljs";
import {IMultiLangTransValue} from "@/__libs/language_manager/LanguageManager";
import FileSaver from "file-saver";
import FileUtil from "@/__libs/utility/FileUtil";
import Util from "@/__libs/utility/Util";

class ProjectController
{

    public project:ProjectModel = AppUserModel.getInstance().project;

    public async saveProject():Promise<boolean>
    {
        const response:ApiResponse<IUpdateResultDto> = await apiManager.sendApiRequest(ApiRequestType.PUT, `/client-api/project`, this.project.mapToDto(DtoType.BODY));
        return response.hasSucceeded;
    }

    public async saveProjectConfig():Promise<boolean>
    {
        const response:ApiResponse<IUpdateResultDto> = await apiManager.sendApiRequest(ApiRequestType.PUT, `/client-api/project/config`, this.project.config);
        return response.hasSucceeded;
    }

    public async updateSystemFile(name:string, file:File):Promise<boolean>
    {

        const response:ApiResponse<IUploadFileResultDto> = await apiManager.uploadFile(ApiRequestType.PUT, `/client-api/asset-folders/${this.project.systemFolderId}/files/${name}`, file);
        return response.hasSucceeded;
    }

    public async calculateStorage():Promise<void>
    {
        this.project.storageIsCalculating = true;
        const response:ApiResponse<any> = await apiManager.sendApiRequest(ApiRequestType.GET, `/client-api/project/status`);
        if (response.hasSucceeded)
        {
            this.project.storageTaken = response.result.storageTaken;
        }
        this.project.storageIsCalculating = false;
    }


    public async translateText(p_trans:ITranslationMultiLangDto, p_sourceLanguage:string, p_targetLanguage:string, p_context:string = ""):Promise<boolean>
    {
        const transJob:ITranslateTextJobDto = {
            translations  : [{text: p_trans.multiLangTrans[p_sourceLanguage].text, identifier: p_trans.identifier}],
            sourceLanguage: p_sourceLanguage,
            targetLanguage: p_targetLanguage,
            context       : p_context
        };

        const response:ApiResponse<ITranslationDto[]> = await apiManager.sendApiRequest(ApiRequestType.POST, `/client-api/project/translate/text`, transJob);
        if (response.hasSucceeded && (response.result as ITranslationDto[]).length > 0)
        {
            p_trans.multiLangTrans[p_targetLanguage].translationStatus = TranslationStatus.AUTO_TRANSLATED;
            p_trans.multiLangTrans[p_targetLanguage].text = (response.result as ITranslationDto[])[0].text;
            p_trans.multiLangTrans[p_targetLanguage].hasChanges = true;
        }
        else
        {
            p_trans.multiLangTrans[p_targetLanguage].translationStatus = TranslationStatus.AUTO_TRANSLATION_ERROR;
        }
        return response.hasSucceeded;
    }

    public async exportTranslation(p_transGroups:ITranslationGroupDto[], p_sourceLanguage:string, p_targetLanguage:string):Promise<boolean>
    {

        const workbook = new Workbook();

        const newSheet = workbook.addWorksheet();

        newSheet.columns = [
            {header: 'identifier', key: 'identifier', width: 5, protection: {locked: true}},
            {header: p_sourceLanguage.toLocaleUpperCase(), key: "SOURCE", width: 20},
            {header: p_targetLanguage.toLocaleUpperCase(), key: "TARGET", width: 32}
        ];

        const rows:any[] = [];

        for (let i = 0; i < p_transGroups.length; i++)
        {
            const group:ITranslationGroupDto = p_transGroups[i];
            for (let j = 0; j < group.translations.length; j++)
            {
                const trans:ITranslationMultiLangDto = group.translations[j];
                const row:any = {
                    identifier: `${group.identifier}>${trans.identifier}`,
                    SOURCE    : trans.multiLangTrans[p_sourceLanguage].text,
                    TARGET    : trans.multiLangTrans[p_targetLanguage].text
                };
                rows.push(row)
            }
        }

        newSheet.addRows(rows);

        //styling header
        newSheet.getRow(1).alignment = {wrapText: true};
        newSheet.getRow(1).font = {bold: true};
        newSheet.getRow(1).border = {bottom: {style: "double"}};
        newSheet.getRow(1).fill = {type: 'pattern', pattern: 'solid', fgColor: {argb: 'FFD9D9D9'}};

        //styling rows
        newSheet.eachRow({includeEmpty: true}, function (row, rowNumber) {
            if (rowNumber > 1) //don't include the header
            {
                row.height = 20;
                row.alignment = {wrapText: true};
            }
        });

        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], {type: "applicationi/xlsx"});

        const xlsName:string = `translation_${p_targetLanguage.toLocaleUpperCase()}`;
        FileSaver.saveAs(blob, `${xlsName}.xlsx`);

        return true;
    }



    public async importTranslation(p_file:File, p_transGroups:ITranslationGroupDto[])
    {
        let contentBuffer = await FileUtil.readFileAsync(p_file);
        const workbook = new Workbook();
        await workbook.xlsx.load(contentBuffer as any);
        const worksheet:Worksheet = workbook.getWorksheet(1) as Worksheet;

        const targetLanguage:string = worksheet.getRow(1).getCell(3).value!.toString().toLocaleLowerCase();

        worksheet.eachRow({includeEmpty: true}, function (row, rowNumber) {
            if (rowNumber > 1) //don't include the header
            {
                if (row.getCell(1).value)
                {
                    const identifier:string = row.getCell(1).value!.toString();

                    if (row.getCell(3).value)
                    {
                        let text:string;
                        if (Util.excelJsIsRichValue(row.getCell(3).value))
                        {
                            text = Util.excelJsRichToString(row.getCell(3).value).trim();
                        }
                        else
                        {
                            text = row.getCell(3).value!.toString().trim();
                        }
                        const groupIdentifier:string = identifier.split(">")[0];
                        const transIdentifier:string = identifier.split(">")[1];

                        //first find group
                        const group:ITranslationGroupDto | undefined = p_transGroups.find(group => group.identifier === groupIdentifier);
                        if (group)
                        {
                            //then find trans
                            const trans:ITranslationMultiLangDto | undefined = group.translations.find(trans => trans.identifier === transIdentifier);
                            if (trans)
                            {
                                const transValue:IMultiLangTransValue = trans.multiLangTrans[targetLanguage];
                                if (transValue)
                                {
                                    //set target lang in trans
                                    if (transValue.text !== text)
                                    {
                                        transValue.hasChanges = true;
                                    }
                                    transValue.text = text;
                                    //set status
                                    transValue.translationStatus = TranslationStatus.IMPORTED;
                                }
                            }
                        }
                    }


                }

            }
        });
        return true;
    }

}

//Singleton export
export default new ProjectController();
