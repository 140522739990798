<template>
  <div class="sync-view box box--full-height box--scrollable">

    <h4 class="title mt-1 mb-4">{{ $t('SyncWidgetTitle') }}</h4>

    <div class="sync-view-widgets mb-4">
      <SyncInternetWidget></SyncInternetWidget>
      <SyncStatusWidget></SyncStatusWidget>
      <SyncRoadModeWidget></SyncRoadModeWidget>
    </div>

    <transition name="fade">
      <div v-if="syncModel.isSyncEnabled">

        <transition name="fade">
          <div class="sync-view-progress sub-box mb-4" v-if="syncModel.isSyncing">
            <h6>{{ $t('SyncWidgetProgress') }}</h6>
            <b-progress variant="secondary" :value="syncModel.percCompleted"></b-progress>
          </div>
        </transition>

        <b-form-group>
          <b-form-checkbox @change="_onAutoCbChange"
                           v-model="syncModel.doAutoSync">{{ $t('SyncWidgetAuto') }}
          </b-form-checkbox>
        </b-form-group>

        <h6 class="title mb-2" v-if="syncModel.hasStorageManagement || syncModel.isOfflineAvailable || syncModel.isPaused">{{ $t('SyncWidgetExtraInfo') }}</h6>

        <table class="table table-sm table-striped table-borderless mb-5">
          <tbody>
          <tr v-if="syncModel.hasStorageManagement">
            <th scope="row">{{ $t('SyncWidgetStorageAvailable') }}</th>
            <td>{{ storageAvailable }}</td>
          </tr>
          <tr v-if="syncModel.hasStorageManagement">
            <th scope="row">{{ $t('SyncWidgetStorageUsed') }}</th>
            <td>{{ storageUsed }}</td>
          </tr>
          <tr v-if="syncModel.isOfflineAvailable">
            <th scope="row">{{ $t('SyncWidgetLast') }}</th>
            <td>{{ $d(new Date(syncModel.lastSuccessfulSync), 'short') }}</td>
          </tr>
          <tr v-if="syncModel.isPaused">
            <th scope="row">{{ $t('SyncWidgetStatus') }}</th>
            <td>{{ $t(`SyncWidgetPauseReason_${syncModel.pauseReason}`) }}</td>
          </tr>
          </tbody>
        </table>

      </div>
    </transition>

    <div class="sync-view-buttons">
      <b-button variant="success" block v-if="syncModel.isSyncEnabled && !syncModel.isSyncing"
                @click="_onStartSyncBtnClick">
        {{ $t('SyncWidgetStart') }}
      </b-button>
      <b-button variant="outline-secondary" block
                v-if="syncModel.isSyncEnabled && syncModel.isSyncing && !syncModel.isPaused"
                @click="_onPauseSyncBtnClick">
        {{ $t('SyncWidgetPause') }}
      </b-button>
      <b-button variant="primary" block v-if="syncModel.isSyncEnabled && syncModel.isSyncing && syncModel.isPaused"
                @click="_onResumeSyncBtnClick">
        {{ $t('SyncWidgetResume') }}
      </b-button>
      <b-button variant="outline-secondary" block v-if="syncModel.isSyncEnabled && syncModel.isSyncing"
                @click="_onCancelSyncBtnClick">
        {{ $t('SyncWidgetCancel') }}
      </b-button>
      <b-button variant="outline-secondary" block v-if="syncModel.isSyncEnabled" @click="_onClearStorageBtnClick">
        {{ $t('SyncWidgetStorageClear') }}
      </b-button>
      <b-button variant="outline-secondary" block v-if="syncModel.isSyncEnabled"
                @click="_onDisableSyncBtnClick">
        {{ $t('SyncWidgetDisable') }}
      </b-button>
      <b-button variant="primary" block v-if="!syncModel.isSyncEnabled" @click="_onEnableSyncBtnClick">
        {{ $t('SyncWidgetEnable') }}
      </b-button>
    </div>

  </div>
</template>


<script lang="ts">
import {Component, Vue} from "vue-property-decorator";
import syncController from "@/sync/_controller/SyncController";
import SyncModel from "@/sync/_model/SyncModel";
import fileSize from "filesize";
import SyncStatusWidget from "@/sync/_view/SyncStatusWidget.vue";
import SyncInternetWidget from "@/sync/_view/SyncInternetWidget.vue";
import AppModel from "@/_model/AppModel";
import NetworkManager from "@/_controller/NetworkManager";
import serviceWorkerController from "@/_controller/ServiceWorkerController";
import SyncRoadModeWidget from "@/sync/_view/SyncRoadModeWidget.vue";

@Component({
  components: {SyncRoadModeWidget, SyncStatusWidget, SyncInternetWidget}
})
export default class SyncWidget extends Vue {

  private syncModel: SyncModel = SyncModel.getInstance();
  private appModel: AppModel = AppModel.getInstance();

  public get storageAvailable(): string {
    return fileSize(this.syncModel.storageQuota);
  }

  public get storageUsed(): string {
    return fileSize(this.syncModel.storageUsage);
  }

  private _onStartSyncBtnClick(p_e: Event) {
    syncController.startSync();
  }

  private _onPauseSyncBtnClick(p_e: Event) {
    syncController.pauseSync();
  }

  private _onResumeSyncBtnClick(p_e: Event) {
    syncController.resumeSync();
  }

  private _onCancelSyncBtnClick(p_e: Event) {
    syncController.cancelSync();
  }

  private async _onDisableSyncBtnClick(p_e: Event) {
    const disableConfirmed: boolean = await this.$bvModal.msgBoxConfirm(`Are you sure you want to disable offline availability? The app will no longer work offline!`, {centered: true});

    if (disableConfirmed) {
      syncController.disableSync();
    }
  }

  private async _onClearStorageBtnClick(p_e: Event) {
    const clearConfirmed: boolean = await this.$bvModal.msgBoxConfirm(`Are you sure you want to clear all storage? You will have to do a full sync to make the app work offline again!`, {centered: true});

    if (clearConfirmed) {
      syncController.clearStorage();
    }
  }

  private async _onEnableSyncBtnClick(p_e: Event) {
    syncController.enableSync();
  }

  private async _onAutoCbChange(p_isChecked: boolean) {
    syncController.updateAutoSync(p_isChecked);
  }

}
</script>
