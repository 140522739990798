import SyncItemProcess from "@/sync/_controller/process/SyncItemProcess";
import {ISyncItemDto, ISyncItemFileDto} from "@/sync/_model/sync.dto";
import {SyncFileRetrieveStatus} from "@/sync/_model/sync.constants";
import syncController from "@/sync/_controller/SyncController";
import {IEntityBodyDto} from "@/entity/_model/entity.dto";
import Util from "@/__libs/utility/Util";

class SyncDataProcess extends SyncItemProcess
{

    //---------------------------------
    // Properties
    //---------------------------------



    //---------------------------------
    // Controller Methods
    //---------------------------------



    //---------------------------------
    // Private Methods
    //---------------------------------


    protected _resolveItemFilePath(p_syncItem:ISyncItemDto):string
    {
        return `/${this._storagePath}/${p_syncItem.identifier}`;
    }




    //could be overridden if needed
    protected _parseRetrievedItemFile(p_itemFile:ISyncItemFileDto) {}


}


export default SyncDataProcess;
