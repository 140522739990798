import {LanguageManager} from "@/__libs/language_manager/LanguageManager";
import Model from "@/__libs/_model/Model";
import ContentModel from "@/content/_model/ContentModel";
import PlaylistListModel from "@/playlist/_model/PlaylistListModel";
import {DtoType} from "@/_model/app.constants";
import AppUserModel from "@/project/user/_model/AppUserModel";
import {RightAction, RIGHTS} from "@/team/_model/role.constants";
import {ENTITY_CONFIG, EntityType} from "@/entity/_model/entity.constants";
import ContentAppModel from "@/content_app/_model/ContentAppModel";
import EntityModel from "@/entity/_model/EntityModel";
import {IPlaylistBodyDto, IPlaylistDto, IPlaylistEntryDto} from "@/playlist/_model/playlist.dto";
import ContentListModel from "@/content/_model/ContentListModel";


export default class PlaylistModel extends Model implements IPlaylistDto
{

    public playlistID:number = 0;
    public createdDate:Date = new Date();
    public createdByUserID:number = 0;
    public entries:IPlaylistEntryDto[] = [];

    public body:IPlaylistBodyDto = {name: {}, poster: {}, notes: {}};


    public isEditable:boolean = true;
    public hasChanges:boolean = false;

    constructor(p_playlistDto?:IPlaylistDto)
    {
        super();
        if (p_playlistDto)
        {
            this.mapFromDto(p_playlistDto);
        }
    }

    //---------------------------------
    // Public Methods
    //---------------------------------



    public deleteContentFromPlaylist(p_content:ContentModel)
    {

    }

    public mapToDto(p_dtoType:DtoType):IPlaylistDto
    {
        const playlistDto = {
            playlistID: this.playlistID,
            body      : this.body,
            entries   : this.entries
        } as IPlaylistDto;

        return playlistDto;
    }


    public renameAsCopy()
    {
        LanguageManager.renameAsCopy(this.body.name);
    }


    public getAllShareableContentInPlaylist():ContentModel[]
    {
        const shareableContent:ContentModel[] = [];
        for (let j = 0; j < this.entries.length; j++)
        {
            const content = ContentListModel.getInstance().getEntityByID(this.entries[j].contentID);
            if (content && content.isShareableWithAudience && shareableContent.indexOf(content) < 0)
            {
                shareableContent.push(content);
            }
        }
        return shareableContent;
    }


    private _showShareWithAudience(p_entity:EntityModel):boolean
    {
        const entityConfig = ENTITY_CONFIG[p_entity.entityType];
        const showShareWithAudience:boolean = AppUserModel.getInstance().rights.indexOf(RIGHTS.SHARE_ENTITIES_WITH_AUDIENCES.identifier) >= 0 &&
            entityConfig.canBeSharedWithAudiences &&
            p_entity.rightActions.indexOf(RightAction.SHARE_WITH_AUDIENCES) >= 0;
        if (p_entity.entityType === EntityType.CONTENT_APP)
        {
            return showShareWithAudience && (p_entity as ContentAppModel).canBeSharedWithAudiences;
        }
        return showShareWithAudience;
    }

}
