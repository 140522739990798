import SyncFileGroupProcess from "@/sync/_controller/process/SyncFileGroupProcess";
import syncController from "@/sync/_controller/SyncController";
import {SyncProcessType} from "@/sync/_model/sync.constants";
import SyncSchemaProcess from "@/sync/_controller/process/SyncSchemaProcess";
import {ISyncItemFileDto} from "@/sync/_model/sync.dto";
import {IContentAppResourceDto} from "@/content_app/resource/_model/content_app_resource.dto";

class SyncContentAppResourceProcess extends SyncFileGroupProcess
{

    //---------------------------------
    // Properties
    //---------------------------------

    protected _storagePath:string = "contentAppResources";

    public dependencyLevel:number = 1;

    private _schemaProcess!:SyncSchemaProcess;


    //---------------------------------
    // Controller Methods
    //---------------------------------



    //---------------------------------
    // Private Methods
    //---------------------------------

    protected _resolveDependencyProcesses()
    {
        this._schemaProcess = syncController.resolveDependencyProcess(SyncProcessType.SCHEMA) as SyncSchemaProcess;

    }

    protected _parseRetrievedItemFile(p_itemFile:ISyncItemFileDto)
    {
        super._parseRetrievedItemFile(p_itemFile);

        const contentAppResource:IContentAppResourceDto = p_itemFile.body as IContentAppResourceDto;//IDataSchemaDto;

        if (contentAppResource.dataSchemaURI && contentAppResource.dataSchemaURI.length > 0)
        {
            this._schemaProcess.addSyncItem({identifier: contentAppResource.dataSchemaURI.split("://")[1]});
        }


        //

    }


}

//Singleton export
export default SyncContentAppResourceProcess;
