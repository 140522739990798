import i18n from '@/__plugins/i18n';
import TeamModel from "@/team/_model/TeamModel";
import apiManager, {ApiRequestType, ApiResponse} from "@/_controller/ApiManager";
import {ICreateResultDto, IDeleteResultDto, IUpdateResultDto} from "@/entity/_model/entity.dto";
import toastManager, {ToastType} from "@/__libs/toast_manager/ToastManager";
import {LoadingStatus} from "@/entity/_model/entity.constants";
import TeamListModel from "@/team/_model/TeamListModel";
import {ITeamBodyDto, ITeamDto, IUserDto, IUserOutlineDto} from "@/team/_model/team.dto";
import {DtoType} from "@/_model/app.constants";

class TeamController
{

    //---------------------------------
    // Properties
    //---------------------------------

    protected _listModel:TeamListModel = TeamListModel.getInstance();


    //---------------------------------
    // Controller Methods
    //---------------------------------

    public async fetchAllTeams()
    {
        //first check if you are not fetching already and if enough time has passed between fetches
        if (!this._listModel.isFetchListAllowed)
        {
            return;
        }
        //all good, start fetch
        this._listModel.startListFetch();



        //get new meta info from server
        const response:ApiResponse<ITeamBodyDto[]> = await apiManager.sendApiRequest(ApiRequestType.GET, `/client-api/teams/`);

        if (response.hasSucceeded)
        {
            // this._listModel.list = [];

            const teamBodyDtos:ITeamBodyDto[] = response.result as ITeamBodyDto[];
            for (let i = 0; i < teamBodyDtos.length; i++)
            {
                const team:TeamModel = new TeamModel();
                team.mapFromDto(teamBodyDtos[i]);
                this._listModel.list.push(team);
            }
        }
        this._listModel.stopListFetch();
    }

    // public async fetchTeamBody(p_team:TeamModel)
    // {
    //     p_team.loadingStatus = LoadingStatus.LOADING_BODY;
    //
    //     //get new meta info from server
    //     const response:ApiResponse<ITeamDto[]> = await apiManager.sendApiRequest(ApiRequestType.GET, `/client-api/users/${p_team.userID}`);
    //
    //     if (response.hasSucceeded)
    //     {
    //         p_team.mapFromDto(response.result!);
    //         p_team.loadingStatus = LoadingStatus.BODY_LOADED;
    //     }
    //     else
    //     {
    //         p_team.loadingStatus = LoadingStatus.BODY_ERROR;
    //     }
    //     this._listModel.stopListFetch();
    // }


    public async createTeam(p_team:TeamModel):Promise<ApiResponse<ICreateResultDto>>
    {
        const teamBody:ITeamBodyDto = p_team.mapToDto(DtoType.BODY) as ITeamBodyDto;

        const response:ApiResponse<ICreateResultDto> = await apiManager.sendApiRequest(ApiRequestType.POST, `/client-api/teams/`, teamBody);

        if (response.hasSucceeded)
        {
            p_team.teamID = (response.result as any).ID;
            toastManager.showToast(i18n.t('TeamCreationSuccess') as string, ToastType.SUCCESS);
            this._listModel.list.push(p_team);

        }
        else
        {
            toastManager.showToast(i18n.t('TeamCreationFailed') as string, ToastType.DANGER);
        }
        return response;
    }


    public async saveTeam(p_team:TeamModel):Promise<ApiResponse<IUpdateResultDto>>
    {
        const teamBody:ITeamBodyDto = p_team.mapToDto(DtoType.BODY) as ITeamBodyDto;

        const response:ApiResponse<IUpdateResultDto> = await apiManager.sendApiRequest(ApiRequestType.PUT, `/client-api/teams/${p_team.teamID}`, teamBody);

        if (response.hasSucceeded)
        {
            toastManager.showToast(i18n.t('TeamSaveSuccess') as string, ToastType.SUCCESS);
            p_team.hasChanges = false;
        }
        else
        {
            toastManager.showToast(i18n.t('TeamSaveFailed') as string, ToastType.DANGER);
        }
        return response;
    }


    public async deleteTeam(p_team:TeamModel):Promise<boolean>
    {

        const response:ApiResponse<IDeleteResultDto> = await apiManager.sendApiRequest(ApiRequestType.DELETE, `/client-api/teams/${p_team.teamID}`);

        if (response.hasSucceeded)
        {
            this._listModel.removeItem(p_team);

            toastManager.showToast(i18n.t('TeamDeleteSuccess') as string, ToastType.SUCCESS);
        }
        else
        {
            toastManager.showToast(i18n.t('TeamDeleteFailed') as string, ToastType.DANGER);
        }
        return response.hasSucceeded;
    }

    //---------------------------------
    // Private Methods
    //---------------------------------

}

//Singleton export
export default new TeamController();
