<template>

    <div class="analytics-block">
      <div class="aa-responsive">
        <div class="aa-responsive__wrapper aa-responsive__wrapper--square d-flex align-items-center justify-content-center">
          <h4 class="aa-title">{{ value }}</h4>
        </div>
      </div>
      <div class="analytics-block__label">{{ label }}</div>
    </div>

</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {IAnalyticsPrimeBoxDto, IAnalyticsSubBoxDto, ITeamAnalyticsDto} from "@/analytics/_model/analytics.dto";
import {AnalyticsUsageBoxType, AnalyticsValueDisplayType} from "@/analytics/_model/analytics.constants";
import i18n from "@/__plugins/i18n";
import languageManager, {IMultiLangString} from "@/__libs/language_manager/LanguageManager";
import fileManager from "@/_controller/FileManager";
import AppUserModel from "@/project/user/_model/AppUserModel";
import AnalyticsModel from "@/analytics/_model/AnalyticsModel";


@Component({
    components: {}
})
export default class AnalyticsContentSubBox extends Vue
{

    //---------------------------------
    // Vue Component props
    //---------------------------------

    @Prop() private subBox!:IAnalyticsSubBoxDto;

    //---------------------------------
    // Vue Component data
    //---------------------------------


    //---------------------------------
    // Vue Computed properties
    //---------------------------------


    get label()
    {
        return AnalyticsModel.resolveLabelTranslation(this.subBox.cfg.label as string);
    }

    get value()
    {
        let valueStr:string = "";
        if (this.subBox.cfg.valueDisplayType === AnalyticsValueDisplayType.EVOLUTION_PERCENTAGE)
        {
            if (!this.subBox.boxValue)
            {
                return "-";
            }

            if (this.subBox.boxValue > 0)
            {
                valueStr = "+";
            }
            else if (this.subBox.boxValue < 0)
            {
                valueStr = "-";
            }
            return valueStr += this.subBox.boxValue + "%";
        }
        else if (this.subBox.cfg.valueDisplayType === AnalyticsValueDisplayType.DECIMAL)
        {
            return Math.round(this.subBox.boxValue * 100) / 100;
        }
        return this.subBox.boxValue
    }


    //---------------------------------
    // Public / lifecycle methods
    //---------------------------------

    mounted()
    {
    }



    //---------------------------------
    // Private / helper methods
    //---------------------------------



}
</script>

