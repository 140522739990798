<template>
  <div class="welcome-sub-page welcome-finish-page">
    <h1 class="welcome-finish-page__title mb-5" v-html="$t('WelcomeFinishTitle')"></h1>
    <b-button variant="success" v-html="$t('WelcomeFinishButton')" @click="next()"></b-button>
    <AAImage imageUrl="img/welcome/welcome-finish.jpg"></AAImage>
  </div>
</template>

<script lang="ts">
import {Component, Vue} from "vue-property-decorator";
import AAImage from "@/_view/components/AAImage.vue";
import {RoutingIdentifier} from "@/router";
import ProjectModel from "@/project/_model/ProjectModel";
import AppUserModel from "@/project/user/_model/AppUserModel";
import projectController from "@/project/_controller/ProjectController";

@Component({
  components: {AAImage}
})
export default class WelcomeFinishPage extends Vue {

  public project: ProjectModel = AppUserModel.getInstance().project;

  private async next() {
    this.project.config.welcomeFinished = true;
    await projectController.saveProjectConfig();
    await this.$router.push({name: RoutingIdentifier.CONTENT_OVERVIEW});
  }

}
</script>
