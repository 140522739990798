import {LocalStorageKey, StorageItemType} from "@/_model/app.constants";

export enum FileType
{
    UNKNOWN,
    IMAGE,
    VIDEO,
    AUDIO,
    TEXT,
    SPREADSHEET,
    PORTABLE_DOCUMENT,
    PRESENTATION,
    ARCHIVE,
    AR_MODEL,
    FONT
}

export enum FileTargetType
{
    GENERAL_DOCUMENT,
    PRESENTATION_CONTENT,
    PRESENTATION_POSTER,
    SLIDE_BACKGROUND,
    AUDIENCE_AVATAR,
    AUDIENCE_PERSONAL_FILE,
    USER_AVATAR,
    TEAM_AVATAR,
    FILE_DESKTOP_BACKGROUND,
    FILE_FOLDER_ICON,
    FILE_ICON,
    CONTENT_APP_POSTER
}

export interface IFileTypeConfig
{
    identifier:string,
    allowedTargetTypes:FileTargetType[],
    defaultTargetTypes:FileTargetType[],
    fileExtensions:string[],
    posterUrl:string;
    isMobileOnly:boolean;
    showInPlayer:boolean;
}

export const FILE_TYPE_CONFIG:{ [key in FileType]:IFileTypeConfig } =
    {
        [FileType.UNKNOWN]          : {
            identifier        : "UNKNOWN",
            allowedTargetTypes: [],
            defaultTargetTypes: [],
            fileExtensions    : [],
            posterUrl         : "img/file_type/abstract-0.jpg",
            isMobileOnly: false,
            showInPlayer:false
        },
        [FileType.IMAGE]            : {
            identifier        : "IMAGE",
            allowedTargetTypes: [
                FileTargetType.AUDIENCE_AVATAR,
                FileTargetType.AUDIENCE_PERSONAL_FILE,
                FileTargetType.FILE_DESKTOP_BACKGROUND,
                FileTargetType.FILE_FOLDER_ICON,
                FileTargetType.FILE_ICON,
                FileTargetType.GENERAL_DOCUMENT,
                FileTargetType.PRESENTATION_CONTENT,
                FileTargetType.PRESENTATION_POSTER,
                FileTargetType.SLIDE_BACKGROUND,
                FileTargetType.TEAM_AVATAR,
                FileTargetType.USER_AVATAR
            ],
            defaultTargetTypes: [FileTargetType.PRESENTATION_CONTENT, FileTargetType.GENERAL_DOCUMENT],
            fileExtensions    : [".png", ".jpg", ".jpeg", ".gif"],
            posterUrl         : "img/file_type/abstract-1.jpg",
            isMobileOnly: false,
            showInPlayer:true
        },
        [FileType.VIDEO]            : {
            identifier        : "VIDEO",
            allowedTargetTypes: [
                FileTargetType.AUDIENCE_PERSONAL_FILE,
                FileTargetType.FILE_DESKTOP_BACKGROUND,
                FileTargetType.GENERAL_DOCUMENT,
                FileTargetType.PRESENTATION_CONTENT,
                FileTargetType.SLIDE_BACKGROUND
            ],
            defaultTargetTypes: [FileTargetType.PRESENTATION_CONTENT, FileTargetType.GENERAL_DOCUMENT],
            fileExtensions    : [".mp4"],
            posterUrl         : "img/file_type/abstract-2.jpg",
            isMobileOnly: false,
            showInPlayer:true
        },
        [FileType.AUDIO]            : {
            identifier        : "AUDIO",
            allowedTargetTypes: [
                FileTargetType.AUDIENCE_PERSONAL_FILE,
                FileTargetType.GENERAL_DOCUMENT,
                FileTargetType.PRESENTATION_CONTENT
            ],
            defaultTargetTypes: [FileTargetType.PRESENTATION_CONTENT, FileTargetType.GENERAL_DOCUMENT],
            fileExtensions    : [".mp3"],
            posterUrl         : "img/file_type/abstract-3.jpg",
            isMobileOnly: false,
            showInPlayer:true
        },
        [FileType.TEXT]             : {
            identifier        : "TEXT",
            allowedTargetTypes: [
                FileTargetType.AUDIENCE_PERSONAL_FILE,
                FileTargetType.GENERAL_DOCUMENT
            ],
            defaultTargetTypes: [FileTargetType.GENERAL_DOCUMENT],
            fileExtensions    : [".doc", ".docx", ".txt"],
            posterUrl         : "img/file_type/abstract-0.jpg",
            isMobileOnly: false,
            showInPlayer:false
        },
        [FileType.SPREADSHEET]      : {
            identifier        : "SPREADSHEET",
            allowedTargetTypes: [
                FileTargetType.AUDIENCE_PERSONAL_FILE,
                FileTargetType.GENERAL_DOCUMENT
            ],
            defaultTargetTypes: [FileTargetType.GENERAL_DOCUMENT],
            fileExtensions    : [".xls", ".xlsx"],
            posterUrl         : "img/file_type/abstract-1.jpg",
            isMobileOnly: false,
            showInPlayer:false
        },
        [FileType.PORTABLE_DOCUMENT]: {
            identifier        : "PORTABLE_DOCUMENT",
            allowedTargetTypes: [
                FileTargetType.AUDIENCE_PERSONAL_FILE,
                FileTargetType.GENERAL_DOCUMENT
            ],
            defaultTargetTypes: [FileTargetType.GENERAL_DOCUMENT],
            fileExtensions    : [".pdf"],
            posterUrl         : "img/file_type/abstract-2.jpg",
            isMobileOnly: false,
            showInPlayer:true
        },
        [FileType.PRESENTATION]     : {
            identifier        : "PRESENTATION",
            allowedTargetTypes: [
                FileTargetType.AUDIENCE_PERSONAL_FILE,
                FileTargetType.GENERAL_DOCUMENT
            ],
            defaultTargetTypes: [FileTargetType.GENERAL_DOCUMENT],
            fileExtensions    : [".ppt", ".pptx"],
            posterUrl         : "img/file_type/abstract-3.jpg",
            isMobileOnly: false,
            showInPlayer:false
        },
        [FileType.ARCHIVE]          : {
            identifier        : "ARCHIVE",
            allowedTargetTypes: [
                FileTargetType.AUDIENCE_PERSONAL_FILE,
                FileTargetType.GENERAL_DOCUMENT
            ],
            defaultTargetTypes: [FileTargetType.GENERAL_DOCUMENT],
            fileExtensions    : [".zip", ".rar"],
            posterUrl         : "img/file_type/abstract-0.jpg",
            isMobileOnly: false,
            showInPlayer:false
        },
        [FileType.AR_MODEL]          : {
            identifier        : "AR_MODEL",
            allowedTargetTypes: [
                FileTargetType.GENERAL_DOCUMENT
            ],
            defaultTargetTypes: [FileTargetType.GENERAL_DOCUMENT],
            fileExtensions    : [".usdz", ".glb"],
            posterUrl         : "img/file_type/abstract-1.jpg",
            isMobileOnly: true,
            showInPlayer:false
        },
        [FileType.FONT]          : {
            identifier        : "FONT",
            allowedTargetTypes: [
                FileTargetType.GENERAL_DOCUMENT
            ],
            defaultTargetTypes: [FileTargetType.GENERAL_DOCUMENT],
            fileExtensions    : [".woff2"],
            posterUrl         : "img/file_type/abstract-1.jpg",
            isMobileOnly: true,
            showInPlayer:false
        }
    }



export enum FileImportSource
{
    LOCAL_STORAGE,
    ONEDRIVE,
    GOOGLE_DRIVE,
    DROPBOX
}

export enum AssetFolderViewMode
{
    PAGE,
    PICKER
}


