import apiManager, {ApiRequestType, ApiResponse} from "@/_controller/ApiManager";
import {IUpdateResultDto} from "@/entity/_model/entity.dto";
import {CrmProvider} from "@/crm/_model/crm.constants";
import toastManager, {ToastType} from "@/__libs/toast_manager/ToastManager";
import i18n from "@/__plugins/i18n";
import audienceListController from "@/audience/_controller/AudienceListController";
import companyListController from "@/company/_controller/CompanyListController";
import AudienceListModel from "@/audience/_model/AudienceListModel";
import FileUtil from "@/__libs/utility/FileUtil";
import {Row, Workbook, Worksheet} from "exceljs";
import Util from "@/__libs/utility/Util";
import AudienceModel from "@/audience/_model/AudienceModel";
import audienceController from "@/audience/_controller/AudienceController";
import {DeltaStatus} from "@/entity/_model/entity.constants";
import languageManager from "@/__libs/language_manager/LanguageManager";
import AppUserModel from "@/project/user/_model/AppUserModel";
import CrmModel from "@/crm/_model/CrmModel";
import {ICrmContactDto} from "@/crm/_model/crm.dto";
import CompanyListModel from "@/company/_model/CompanyListModel";


class CrmController
{


    //---------------------------------
    // Properties
    //---------------------------------

    private _audienceListModel:AudienceListModel = AudienceListModel.getInstance();


    //---------------------------------
    // Controller Methods
    //---------------------------------


    public async searchContacts(p_crmPovider:CrmProvider, p_onlyMyContacts:boolean, p_searchInput:string = "", p_fromDate?:Date):Promise<ICrmContactDto[]>
    {
        const onlyMyContacts:number = p_onlyMyContacts ? 1 : 0;
        let url:string = `/client-api/crm/contacts?provider=${p_crmPovider}&onlyMyContacts=${onlyMyContacts}`;

        if (p_fromDate)
        {
            url += `&fromDate=${Util.dateFormat(p_fromDate, "SQL")}`;
        }
        else
        {
            url += `&searchInput=${p_searchInput}`;
        }

        const response:ApiResponse<ICrmContactDto[]> = await apiManager.sendApiRequest(ApiRequestType.GET, url);
        if (response.hasSucceeded)
        {
            const contacts:ICrmContactDto[] = response.result as ICrmContactDto[]
            for (let i = 0; i < contacts.length; i++)
            {
                const contact = contacts[i];
                if(contact.remoteIdentifier)
                {
                    contact.isExisting =  this._audienceListModel.getAudienceByRemoteID(contact.remoteIdentifier) !== null;
                }
            }
            return response.result as ICrmContactDto[];
        }
        else
        {
            return [];
        }
    }

    public async doBackendImport(p_crmPovider:CrmProvider, p_contactRemoteIDs:string[], p_alsoImportParentCompanies:boolean):Promise<boolean>
    {
        const response:ApiResponse<IUpdateResultDto> = await apiManager.sendApiRequest(ApiRequestType.POST, `/client-api/crm/contacts/import`, {
            provider                 : p_crmPovider,
            contactRemoteIDs: p_contactRemoteIDs,
            alsoImportParentCompanies: p_alsoImportParentCompanies
        });
        if (response.hasSucceeded)
        {

            CompanyListModel.getInstance().invalidateFetch();
            await companyListController.fetchAllEntities();
            AudienceListModel.getInstance().invalidateFetch();
            await audienceListController.fetchAllEntities();
            toastManager.showToast(i18n.t('ContactsImported') as string, ToastType.SUCCESS);
        }
        else
        {
            toastManager.showToast(i18n.t('AudienceImportFailed') as string, ToastType.DANGER);
        }
        return response.hasSucceeded;
    }



    public async saveApiKey(p_crmPovider:CrmProvider, p_apiKey:string):Promise<boolean>
    {
        const response:ApiResponse<IUpdateResultDto> = await apiManager.sendApiRequest(ApiRequestType.PUT, `/client-api/crm/apikey`, {
            provider: p_crmPovider, apiKey: p_apiKey
        });
        if (response.hasSucceeded)
        {
            AudienceListModel.getInstance().invalidateFetch();
            await audienceListController.fetchAllEntities();
            toastManager.showToast(i18n.t('ApiKeySaved') as string, ToastType.SUCCESS);
        }
        else
        {
            toastManager.showToast(i18n.t('CouldntSaveApiKey') as string, ToastType.DANGER);
        }
        return response.hasSucceeded;

    }


    public async refreshContacts(p_crmPovider:CrmProvider):Promise<boolean>
    {
        const response:ApiResponse<IUpdateResultDto> = await apiManager.sendApiRequest(ApiRequestType.POST, `/client-api/crm/contacts`, {
            provider: p_crmPovider, scope: "ALL"
        });
        if (response.hasSucceeded)
        {
            AudienceListModel.getInstance().invalidateFetch();
            await audienceListController.fetchAllEntities();
            toastManager.showToast(i18n.t('ContactsRefreshed') as string, ToastType.SUCCESS);
        }
        else
        {
            toastManager.showToast(i18n.t('CouldntRefreshContacts') as string, ToastType.DANGER);
        }
        return response.hasSucceeded;

    }


    public async connectOauthUser(p_crmPovider:CrmProvider, p_code:string):Promise<boolean>
    {
        const response:ApiResponse<IUpdateResultDto> = await apiManager.sendApiRequest(ApiRequestType.PUT, `/client-api/crm/user/code`, {
            provider: p_crmPovider, code: p_code
        });
        if (response.hasSucceeded)
        {
            AudienceListModel.getInstance().invalidateFetch();
            await audienceListController.fetchAllEntities();
            toastManager.showToast(i18n.t('ApiKeySaved') as string, ToastType.SUCCESS);
        }
        else
        {
            toastManager.showToast(i18n.t('CouldntSaveApiKey') as string, ToastType.DANGER);
        }
        return response.hasSucceeded;

    }


    public async importFromXls(p_file:File)
    {
        CrmModel.getInstance().isImportingXls = true;
        let contentBuffer = await FileUtil.readFileAsync(p_file);

        const workbook = new Workbook();
        await workbook.xlsx.load(contentBuffer as any);
        const worksheet:Worksheet = workbook.worksheets[0];

        const columns:string[] = [];
        const header:Row = worksheet.getRow(1);
        header.eachCell(function (cell, cellNumber) {
            if (cell.value)
            {
                columns.push(cell.value.toString())
            }
        });

        const audiencesImportData:any[] = [];
        worksheet.eachRow({includeEmpty: true}, function (row, rowNumber) {
            if (rowNumber > 1) //don't include the columnnames
            {
                const audienceImportData:any = {};
                row.eachCell(function (cell, cellNumber) {
                    const column:string = columns[cellNumber - 1];
                    audienceImportData[column] = cell.value;
                });
                audiencesImportData.push(audienceImportData);
            }
        });
        await this.importAudiences(audiencesImportData);

        CrmModel.getInstance().isImportingXls = false;

    }


    private async importAudiences(p_audiencesImportData:any[])
    {
        const basicProps:string[] = ["email", "displayName", "firstName", "lastName", "langCode"];

        for (let i = 0; i < p_audiencesImportData.length; i++)
        {
            const audienceImportData:any = p_audiencesImportData[i];

            let isUpdate:boolean = false;

            //xls converts it to an object
            audienceImportData.email = (audienceImportData.email && audienceImportData.email.text) ? audienceImportData.email.text : audienceImportData.email;
            audienceImportData.email = audienceImportData.email.trim();
            //validate email and check if existing
            if (!Util.validateEmail(audienceImportData.email))
            {
                continue;
            }
            let audience:AudienceModel | null = this._audienceListModel.getAudienceByEmail(audienceImportData.email);
            if (audience)
            {
                isUpdate = true;
            }
            else
            {
                audience = new AudienceModel();
            }
            //validate displayName
            if (audienceImportData.displayName)
            {
                if (audienceImportData.displayName.toString().length < 2)
                {
                    continue;
                }
            }
            else
            {
                continue;
            }
            //validate langcode
            if (languageManager.availableLangCodes.indexOf(audienceImportData.langCode) === -1)
            {
                audienceImportData.langCode = AppUserModel.getInstance().langCode;
            }
            //

            for (let j = 0; j < basicProps.length; j++)
            {
                const basicProp:string = basicProps[j];
                if (audienceImportData[basicProp] !== undefined)
                {
                    (audience as any)[basicProp] = audienceImportData[basicProp];
                }
            }

            if (isUpdate)
            {
                await audienceController.saveBody(audience);
                audience.deltaStatus = DeltaStatus.UPDATED;
            }
            else
            {
                await audienceListController.createEntity(audience);
                audience.deltaStatus = DeltaStatus.NEW;
            }
        }
    }


}

//Singleton export
export default new CrmController();
