<template>
  <div class="box box--full-height list-overview team-roles-overview">
    <div class="list-overview-items">
      <transition name="fade">
        <div v-if="currentState === rolesViewState.LOADING"
             class="empty-loader">
          <b-spinner variant="dark"></b-spinner>
        </div>
      </transition>
      <transition name="fade">
        <div v-if="currentState === rolesViewState.LOADED">
          <RoleItemRenderer
              v-for="role in roles"
              :role="role"
              :key="role.roleID"
              v-on:onClick="_onRoleClick"
              :selState="selState"
          />
        </div>
      </transition>
    </div>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from "vue-property-decorator";
import roleController from "@/team/_controller/RoleController";
import RoleModel from "@/team/_model/RoleModel";
import SelectionState from "@/__libs/_model/SelectionState";
import RoleListModel from "@/team/_model/RoleListModel";
import RoleItemRenderer from "@/team/_view/RoleItemRenderer.vue";
import AppUserModel from "@/project/user/_model/AppUserModel";
import {RIGHTS} from "@/team/_model/role.constants";

enum RolesViewState {
    IDLE,
    LOADING,
    ERROR_LOADING,
    LOADED,
    NO_USERS
}

@Component({
    components: {RoleItemRenderer}
})
export default class RolesOverview extends Vue {

    @Prop() private selState!: SelectionState<RoleModel>;

    private rolesViewState: typeof RolesViewState = RolesViewState;
    private currentState: RolesViewState = RolesViewState.IDLE;

    private roleListModel: RoleListModel = RoleListModel.getInstance();

    private roles: RoleModel[] = this.roleListModel.list;

    private orderedAlphabet: boolean = false;

    get activeRole(): RoleModel | null {
        return this.selState.selected;
    }

    get showCreateRole(): boolean {
        return AppUserModel.getInstance().rights.indexOf(RIGHTS.CREATE_TEAMS.identifier) >= 0;
    }

    public async mounted() {
        this.currentState = RolesViewState.LOADING;
        await roleController.fetchAllRoles();
        await roleController.fetchAllRights();
        this.currentState = RolesViewState.LOADED;
    }

    private async _onRoleClick(p_role: RoleModel, p_e: Event) {
        this.selState.selected = p_role;
    }


}
</script>
