import ContentModel from "@/content/_model/ContentModel";
import EntityListModel from "@/entity/_model/EntityListModel";
import EntitySelectionState from "@/entity/_model/EntitySelectionState";
import {ContentViewType} from "@/content/_model/content.constants";
import ContentOverviewSelectionState from "@/content/_model/ContentOverviewSelectionState";
import {ApiAction} from "@/presentation/player/_model/player.constants";

class ContentListModel extends EntityListModel<ContentModel>
{

    public globalSelState:ContentOverviewSelectionState = new ContentOverviewSelectionState();

    // public activeViewType:ContentViewType = ContentViewType.FOLDER;

    //---------------------------------
    // Singleton Constructor
    //---------------------------------

    public liked: string[] = [];

    private static _instance:ContentListModel;

    constructor()
    {
        super();
        if (!ContentListModel._instance)
        {
            ContentListModel._instance = this;
        }
    }

    public static getInstance():ContentListModel
    {
        if (!ContentListModel._instance)
        {
            new ContentListModel();
        }

        return ContentListModel._instance;
    }


    //---------------------------------
    // Public Methods
    //---------------------------------





}

export default ContentListModel;
