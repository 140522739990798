import EntityModel from "@/entity/_model/EntityModel";
import EntitySelectionState from "@/entity/_model/EntitySelectionState";
import AudienceModel from "@/audience/_model/AudienceModel";
import AssetFolderSelectionState from "@/asset_folder/_model/AssetFolderSelectionState";
import {
    AssetFolderViewMode,
    FILE_TYPE_CONFIG,
    FileType,
    IFileTypeConfig
} from "@/asset_folder/_model/asset_folder.constants";
import {ENTITY_CONFIG, EntityType, IEntityTypeConfig} from "@/entity/_model/entity.constants";

abstract class EntityListModel<T extends EntityModel>
{

    public list:T[] = [];

    // private _selectionStates:EntitySelectionState<T>[] = [];
    public globalSelState:EntitySelectionState<T> = new EntitySelectionState<T>();

    public pageSelState?:EntitySelectionState<T>;

    public pickerSelState?:EntitySelectionState<T>;

    private _isFetchingList:boolean = false;
    get isFetchingList():boolean
    {
        return this._isFetchingList;
    }

    private _lastListFetch:Date = new Date(0);

    protected _minTimeBetweenFetchMs:number = 60 * 60 * 1000; //don't allow the list to be fetched: 60 minutes

    private _isFetchListAllowed:boolean = false;
    get isFetchListAllowed():boolean
    {
        return !this.isFetchingList && new Date().getTime() - this._lastListFetch.getTime() > this._minTimeBetweenFetchMs;
    }

    //used to check if we should flag entities as new
    public noMetaCache:boolean = true;


    //---------------------------------
    // Constructor
    //---------------------------------

    protected constructor()
    {
        // this.registerEntitySelectionState(this.globalSelState);
    }


    //---------------------------------
    // Private Methods
    //---------------------------------

    //get the nr of updated and new entities
    private _setDeltas()
    {
        //loop list and count DeltaStatus.UPDATED / DeltaStatus.NEW

    }


    //---------------------------------
    // Public Methods
    //---------------------------------

    public invalidateFetch():void
    {
        this._lastListFetch = new Date(0);
    }

    public removeEntity(p_entity:T):boolean
    {
        const index:number = this.list.indexOf(p_entity);
        if(index >= 0)
        {
            this.list.splice(index, 1);
            //todo: we should do this for all _selectionStates (but then we need registerEntitySelectionState)
            if(this.globalSelState.selected === p_entity)
            {
                this.globalSelState.selected = null;
            }
            if(this.pageSelState && this.pageSelState.selected === p_entity)
            {
                this.pageSelState.selected = null;
            }
            if(this.pickerSelState && this.pickerSelState.selected === p_entity)
            {
                this.pickerSelState.selected = null;
            }
            this._setDeltas();
            return true;
        }
        return false;
    }


    public startListFetch()
    {
        this._isFetchingList = true;
    }

    public stopListFetch()
    {
        this._lastListFetch = new Date();
        this._isFetchingList = false;
        this._setDeltas();
    }

    public getEntityByID(p_ID:string):T | null
    {
        for (let i = 0; i < this.list.length; i++)
        {
            const entity = this.list[i];
            if (entity.ID === p_ID)
            {
                return entity;
            }
        }
        return null;
    }

    // public registerEntitySelectionState(p_selState:EntitySelectionState<T>)
    // {
    //     this._selectionStates.push(p_selState);
    // }


    public static getEntityTypeForContentID(p_contentID:string):EntityType | null
    {
        for (let entityType in ENTITY_CONFIG)
        {
            const entityTypeConfig:IEntityTypeConfig = (ENTITY_CONFIG as any)[entityType] as IEntityTypeConfig;
            if (p_contentID.split("-")[0]  === entityTypeConfig.shorthand)
            {
                return entityType as EntityType;
            }
        }
        return null;
    }


}

export default EntityListModel;
