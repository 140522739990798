import AudienceModel from "@/audience/_model/AudienceModel";
import EntityListModel from "@/entity/_model/EntityListModel";
import EntitySelectionState from "@/entity/_model/EntitySelectionState";
import SelectionState from "../../__libs/_model/SelectionState";
import TeamModel from "./TeamModel";
import ListModel from "../../__libs/_model/ListModel";

class TeamListModel  extends ListModel<TeamModel>
{

    public globalSelState:SelectionState<TeamModel> = new SelectionState<TeamModel>();

    protected _minTimeBetweenFetchMs:number = 10000 * 60 * 1000; //don't allow the list to be fetched

    //---------------------------------
    // Singleton Constructor
    //---------------------------------

    private static _instance:TeamListModel;

    constructor()
    {
        super();
        if (!TeamListModel._instance)
        {
            TeamListModel._instance = this;
        }
    }

    public static getInstance():TeamListModel
    {
        if (!TeamListModel._instance)
        {
            new TeamListModel();
        }

        return TeamListModel._instance;
    }


    //---------------------------------
    // Public Methods
    //---------------------------------



}

export default TeamListModel;
