import Vue from 'vue';
import App from './_view/App.vue';
import i18n from './__plugins/i18n';
import router from "@/router";
// import './registerServiceWorker';
import './__plugins/bootstrap';
import './__plugins/line-clamp';
import './__plugins/vuelidate';
import './__plugins/browser-detect';
import VueGtag from "vue-gtag";

Vue.config.productionTip = false;

Vue.use(VueGtag, {
    config: { id: process.env.VUE_APP_GOOGLE_TRACKING_ID as string }
}, router);

function startApp() {
    new Vue({
        router,
        i18n,
        render: h => h(App)
    }).$mount('#app');
}

window.addEventListener('load', function() {
    startApp();
});
