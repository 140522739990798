<template>
  <div class="present-content-box">
<!--    Currently Presenting Content:-->
<!--    <div v-if="presentedContentSO">-->
<!--      {{ presentedContentSO.data.presentedContentID }}-->
<!--    </div>-->
  </div>

</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import AppModel from "@/_model/AppModel";
import {EventBus} from "@/__libs/vue/EventBus";
import {EventBusActions} from "@/_model/app.constants";
import Room from "@sales-drive/audience-library/lib/cobrowse_manager/room/Room";
import {SharedObjectType} from "@sales-drive/audience-library/lib/cobrowse_manager/shared_object/shared_object.constants";
import {
    IPresentedContentSoDataDto
} from "@sales-drive/audience-library/lib/cobrowse_manager/presented_content/_model/presented_content.dto";
import SharedObject from "@sales-drive/audience-library/lib/cobrowse_manager/shared_object/SharedObject";

@Component({
    components: {}
})
export default class PresentedContentBox extends Vue {

    @Prop() public room!: Room;

    mounted() {
        //this is an example of client code initializing a SharedObject (without the server)
        const presentedContent: IPresentedContentSoDataDto = {presentedContentID: ""};
        this.room.registerSharedObject(SharedObjectType.PRESENTED_CONTENT, presentedContent);
    }

    get presentedContentSO(): SharedObject<IPresentedContentSoDataDto> {
        return this.room.getSharedObject(SharedObjectType.PRESENTED_CONTENT) as SharedObject<IPresentedContentSoDataDto>;
    }

    get presentedContentID() {
        if (this.presentedContentSO) {
            return this.presentedContentSO.data.presentedContentID;
        }
        return null;
    }

    @Watch('presentedContentID', {immediate: true, deep: true})
    private async _onContentChanged() {
        /*
        if (this.presentedContentID)
        {
            //todo this can only work when the content exists as a share
            // (so we need a totally different model for loading in content, based on ID only)
            // or do the preloading here?
            const content = AppModel.getInstance().contentList.find(content => {return (content.ID === this.presentedContentID)});
            if (!content)
            {
                alert("content not available");
                return;
            }
            AppModel.getInstance().activeContent = content;
            EventBus.$emit(EventBusActions.OPEN_CONTENT, content);
        }
        else
        {
            EventBus.$emit(EventBusActions.CLOSE_CONTENT);
        }

        */
    }

}

</script>

