<template>
  <div :style="{ left: pointerXY.x, top:pointerXY.y}" class="mouse-pointer" v-if="isShown">

    <div v-if="flash" class="mouse-pointer__flash"></div>


    <img class="mouse-pointer__pointer" src="img/cobrowse/pointer.png" />

    <div class="mouse-pointer__user">
      <AAProfilePicture :name="user.displayName" :imageUri="user.avatarUri"></AAProfilePicture>
<!--      <div class="mouse-pointer__user__name">{{ user.displayName }}</div>-->

<!--      myStageScale:{{myScale}}<br>-->
<!--      pointerStageScale:{{this.user.userMouse.scale}}<br>-->
<!--      pointerX:{{this.user.userMouse.mouseX}}<br>-->
<!--      pointerY:{{this.user.userMouse.mouseY}}<br>-->
    </div>

<!--    <span class="userColor" :style="{ background: user.color}"></span>-->

<!--    <span class="userName">{{ user.displayName }}</span>-->

  </div>

</template>

<script lang="ts">
import {
    ICobrowseRoomUserDto
} from "@sales-drive/audience-library/lib/cobrowse_manager/cobrowse_user/_model/cobrowse_user.dto";
import {Component, Prop, Vue} from "vue-property-decorator";
import Room from "@sales-drive/audience-library/lib/cobrowse_manager/room/Room";
import {RoomEvent} from "@sales-drive/audience-library/lib/cobrowse_manager/room/_model/room.constants";
import AAProfilePicture from "@/_view/components/AAProfilePicture.vue";


@Component({
    components: {AAProfilePicture}
})
export default class MousePointerItemRenderer extends Vue {

    @Prop() public user!: ICobrowseRoomUserDto;
    @Prop() public room!: Room;
    @Prop() public myScale!: number; //the scale of your own presentation stage

    public eventBinding: any;
    public flash: boolean = false;

    get isShown() {
        return this.user.interactionState && this.user.interactionState > 0;
    }

    get pointerXY() {
        if (this.user.userMouse) {
            const x:number = (this.user.userMouse.mouseX * (1 / this.user.userMouse.scale)) * this.myScale;
            const y:number = (this.user.userMouse.mouseY * (1 / this.user.userMouse.scale)) * this.myScale;
            return {x: x + "px", y: y + "px"}
        } else {
            return {x: "0px", y: "0px"}
        }
    }

    mounted() {
        this.eventBinding = this.room.roomEvent.add((p_event: RoomEvent, p_cobrowseUserID: any) => {
            if (p_event === RoomEvent.MOUSE_DOWN && p_cobrowseUserID === this.user.cobrowseUserID) {
                this.flash = true;
                setTimeout(() => {
                    this.flash = false;
                }, 500);
            }
        });
    }

    beforeDestroy() {
        this.room.roomEvent.detach(this.eventBinding);
    }

}

</script>
