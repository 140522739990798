import CompanyModel from "@/company/_model/CompanyModel";
import EntityListModel from "@/entity/_model/EntityListModel";



class CompanyListModel extends EntityListModel<CompanyModel>
{



    //---------------------------------
    // Singleton Constructor
    //---------------------------------

    private static _instance:CompanyListModel;

    constructor()
    {
        super();
        if (!CompanyListModel._instance)
        {
            CompanyListModel._instance = this;
        }
    }

    public static getInstance():CompanyListModel
    {
        if (!CompanyListModel._instance)
        {
            new CompanyListModel();
        }

        return CompanyListModel._instance;
    }


    //---------------------------------
    // Public Methods
    //---------------------------------





}

export default CompanyListModel;
