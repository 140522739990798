<template>
  <div class="gamification-highlight" :class="{ 'animated': showAnimation }" @click="gotoGamification">
    <div class="gamification-highlight__visual">
      <div class="holder bg-holder">
        <AAImage class="bg" imageUrl="img/gamification/highlight/highlight-bg.png"></AAImage>
      </div>
      <div class="holder clouds-holder">
        <AAImage class="clouds" imageUrl="img/gamification/highlight/highlight-clouds.png"></AAImage>
      </div>
      <div class="holder wave-holder">
        <AAImage class="wave" imageUrl="img/gamification/highlight/highlight-wave.png"></AAImage>
      </div>
      <div class="holder rocket-holder">
        <AAImage class="rocket" imageUrl="img/gamification/highlight/highlight-rocket.png"></AAImage>
      </div>
      <div class="holder status-holder">
        <AAImage class="status" imageUrl="img/gamification/highlight/highlight-status.png"></AAImage>
      </div>
      <div class="holder profile-holder">
        <AAProfilePicture :name="appUser.displayName" :imageUri="appUser.avatarFileUri"></AAProfilePicture>
      </div>
    </div>
    <b-button variant="success"><span v-html="$t('HighlightDiscover')" class="line-1"></span><span v-html="$t('MainMenuGamification')" class="line-2"></span></b-button>
  </div>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import {RoutingIdentifier} from "@/router";
import AAImage from "@/_view/components/AAImage.vue";
import AppUserModel from "@/project/user/_model/AppUserModel";
import AAProfilePicture from "@/_view/components/AAProfilePicture.vue";

@Component({
  components: {AAProfilePicture, AAImage}
})
export default class GamificationHighlight extends Vue {

  private showAnimation: boolean = false;
  public appUser: AppUserModel = AppUserModel.getInstance();

  private async gotoGamification() {
    await this.$router.push({name: RoutingIdentifier.GAMIFICATION});
  }

  public mounted() {
    if (!this.appUser.highlightShown) {
      this.appUser.highlightShown = true;
      this.showAnimation = true;
    }
  }

}
</script>
