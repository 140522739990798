export enum RightsCategory
{
    AUDIENCE = "AUDIENCE",
    LIBRARY = "LIBRARY",
    CONTENT = "CONTENT",
    TEAM = "TEAM",
    ANALYTICS = "ANALYTICS"
}

export enum RightAction
{
    VIEW = 'VIEW',
    PREVIEW = 'PREVIEW',
    PREPARE = 'PREPARE',
    PRESENT = 'PRESENT',
    CREATE = 'CREATE',
    EDIT = 'EDIT',
    COPY = 'COPY',
    DELETE = 'DELETE',
    TRANSLATE = 'TRANSLATE',
    PRINT = 'PRINT',
    IMPERSONATE = 'IMPERSONATE',
    ASSIGN = 'ASSIGN',
    SEND_MESSAGE = 'SEND_MESSAGE',
    SHARE_WITH_TEAMS = 'SHARE_WITH_TEAMS',
    SHARE_WITH_AUDIENCES = 'SHARE_WITH_AUDIENCES',
    FLAG_AS_QUICKLAUNCH = 'FLAG_AS_QUICKLAUNCH',
    SET_PRIORITY = 'SET_PRIORITY'
}


export const RIGHTS = {
    VIEW_CONTENT_APPS            : {
        identifier: "VIEW_CONTENT_APPS",
        category  : RightsCategory.CONTENT
    },
    CREATE_CONTENT_APPS          : {
        identifier: "CREATE_CONTENT_APPS",
        category  : RightsCategory.CONTENT
    },
    DEEPLINK_TO_CONTENT       : {
        identifier: "DEEPLINK_TO_CONTENT",
        category  : RightsCategory.CONTENT
    },
    VIEW_ASSET_FOLDERS           : {
        identifier: "VIEW_ASSET_FOLDERS",
        category  : RightsCategory.LIBRARY
    },
    CREATE_ASSET_FOLDERS         : {
        identifier: "CREATE_ASSET_FOLDERS",
        category  : RightsCategory.LIBRARY
    },
    VIEW_AUDIENCES               : {
        identifier: "VIEW_AUDIENCES",
        category  : RightsCategory.AUDIENCE
    },
    CREATE_AUDIENCES             : {
        identifier: "CREATE_AUDIENCES",
        category  : RightsCategory.AUDIENCE
    },
    VIEW_COMPANIES               : {
        identifier: "VIEW_COMPANIES",
        category  : RightsCategory.AUDIENCE
    },
    CREATE_COMPANIES             : {
        identifier: "CREATE_COMPANIES",
        category  : RightsCategory.AUDIENCE
    },
    VIEW_CONTENT_FILES           : {
        identifier: "VIEW_CONTENT_FILES",
        category  : RightsCategory.CONTENT
    },
    CREATE_CONTENT_FILES         : {
        identifier: "CREATE_CONTENT_FILES",
        category  : RightsCategory.CONTENT
    },
    VIEW_PRESENTATIONS           : {
        identifier: "VIEW_PRESENTATIONS",
        category  : RightsCategory.CONTENT
    },
    CREATE_PRESENTATIONS         : {
        identifier: "CREATE_PRESENTATIONS",
        category  : RightsCategory.CONTENT
    },
    CREATE_PERSONAL_FOLDERS      : {
        identifier: "CREATE_PERSONAL_FOLDERS",
        category  : RightsCategory.CONTENT
    },
    MANAGE_TEAM_FOLDERS          : {
        identifier: "MANAGE_TEAM_FOLDERS",
        category  : RightsCategory.CONTENT
    },
    VIEW_SLIDES                  : {
        identifier: "VIEW_SLIDES",
        category  : RightsCategory.LIBRARY
    },
    CREATE_SLIDES                : {
        identifier: "CREATE_SLIDES",
        category  : RightsCategory.LIBRARY
    },
    VIEW_DATA_PROVIDERS          : {
        identifier: "VIEW_DATA_PROVIDERS",
        category  : RightsCategory.LIBRARY
    },
    CREATE_DATA_PROVIDERS        : {
        identifier: "CREATE_DATA_PROVIDERS",
        category  : RightsCategory.LIBRARY
    },
    EXPORT_IMPORT_DATAPROVIDERS: {
        identifier: "EXPORT_IMPORT_DATAPROVIDERS",
        category  : RightsCategory.LIBRARY
    },
    CHOOSE_DATAPROVIDER_LISTS: {
        identifier: "CHOOSE_DATAPROVIDER_LISTS",
        category  : RightsCategory.LIBRARY
    },
    SHARE_ENTITIES_WITH_TEAMS    : {
        identifier: "SHARE_ENTITIES_WITH_TEAMS",
        category  : RightsCategory.CONTENT
    },
    SHARE_ENTITIES_WITH_AUDIENCES: {
        identifier: "SHARE_ENTITIES_WITH_AUDIENCES",
        category  : RightsCategory.CONTENT
    },
    FLAG_AS_QUICKLAUNCH          : {
        identifier: "FLAG_AS_QUICKLAUNCH",
        category  : RightsCategory.CONTENT
    },
    SET_PRIORITY                 : {
        identifier: "SET_PRIORITY",
        category  : RightsCategory.CONTENT
    },
    TRANSLATE_CONTENT                 : {
        identifier: "TRANSLATE_CONTENT",
        category  : RightsCategory.CONTENT
    },
    VIEW_PROJECT_ANALYTICS       : {
        identifier: "VIEW_PROJECT_ANALYTICS",
        category  : RightsCategory.ANALYTICS
    },
    VIEW_TEAM_ANALYTICS          : {
        identifier: "VIEW_TEAM_ANALYTICS",
        category  : RightsCategory.ANALYTICS
    },
    VIEW_USER_ANALYTICS          : {
        identifier: "VIEW_USER_ANALYTICS",
        category  : RightsCategory.ANALYTICS
    },
    VIEW_CONTENT_ANALYTICS       : {
        identifier: "VIEW_CONTENT_ANALYTICS",
        category  : RightsCategory.ANALYTICS
    },
    IMPERSONATE_USERS            : {
        identifier: "IMPERSONATE_USERS",
        category  : RightsCategory.TEAM
    },
    VIEW_USERS                   : {
        identifier: "VIEW_USERS",
        category  : RightsCategory.TEAM
    },
    EDIT_USERS                   : {
        identifier: "EDIT_USERS",
        category  : RightsCategory.TEAM
    },
    TRANSFER_USERS                   : {
        identifier: "TRANSFER_USERS",
        category  : RightsCategory.TEAM
    },
    TRANSFER_ENTITIES                   : {
        identifier: "TRANSFER_ENTITIES",
        category  : RightsCategory.CONTENT
    },
    MANAGE_TEAMS                 : {
        identifier: "MANAGE_TEAMS",
        category  : RightsCategory.TEAM
    },
    MANAGE_ROLES                 : {
        identifier: "MANAGE_ROLES",
        category  : RightsCategory.TEAM
    },
    CREATE_TEAMS                 : {
        identifier: "CREATE_TEAMS",
        category  : RightsCategory.TEAM
    },
    HAS_DEVELOPER_ACCESS          : {
        identifier: "HAS_DEVELOPER_ACCESS",
        category  : RightsCategory.LIBRARY
    },
    EDIT_PROJECT_SETTINGS          : {
        identifier: "EDIT_PROJECT_SETTINGS",
        category  : RightsCategory.LIBRARY
    },
    VIEW_GAMIFICATION            : {
        identifier: "VIEW_GAMIFICATION",
        category  : RightsCategory.ANALYTICS
    },
    VIEW_TEAM_GAMIFICATION       : {
        identifier: "VIEW_TEAM_GAMIFICATION",
        category  : RightsCategory.ANALYTICS
    },
    RECEIVE_GAMIFICATION_PERSONAL_REPORT       : {
        identifier: "RECEIVE_GAMIFICATION_PERSONAL_REPORT",
        category  : RightsCategory.ANALYTICS
    }
};



export const RIGHTS_LIST = [
    RIGHTS.VIEW_CONTENT_APPS,
    RIGHTS.CREATE_CONTENT_APPS,
    RIGHTS.VIEW_ASSET_FOLDERS,
    RIGHTS.CREATE_ASSET_FOLDERS,
    RIGHTS.VIEW_AUDIENCES,
    RIGHTS.CREATE_AUDIENCES,
    RIGHTS.VIEW_COMPANIES,
    RIGHTS.CREATE_COMPANIES,
    RIGHTS.VIEW_CONTENT_FILES,
    RIGHTS.CREATE_CONTENT_FILES,
    RIGHTS.VIEW_PRESENTATIONS,
    RIGHTS.CREATE_PRESENTATIONS,
    RIGHTS.VIEW_SLIDES,
    RIGHTS.CREATE_SLIDES,
    RIGHTS.VIEW_DATA_PROVIDERS,
    RIGHTS.CREATE_DATA_PROVIDERS,
    RIGHTS.EXPORT_IMPORT_DATAPROVIDERS,
    RIGHTS.CHOOSE_DATAPROVIDER_LISTS,
    RIGHTS.SHARE_ENTITIES_WITH_TEAMS,
    RIGHTS.SHARE_ENTITIES_WITH_AUDIENCES,
    RIGHTS.FLAG_AS_QUICKLAUNCH,
    RIGHTS.SET_PRIORITY,
    RIGHTS.TRANSLATE_CONTENT,
    RIGHTS.VIEW_PROJECT_ANALYTICS,
    RIGHTS.VIEW_TEAM_ANALYTICS,
    RIGHTS.VIEW_USER_ANALYTICS,
    RIGHTS.VIEW_CONTENT_ANALYTICS,
    RIGHTS.IMPERSONATE_USERS,
    RIGHTS.VIEW_USERS,
    RIGHTS.EDIT_USERS,
    RIGHTS.TRANSFER_USERS,
    RIGHTS.TRANSFER_ENTITIES,
    RIGHTS.MANAGE_TEAMS,
    RIGHTS.MANAGE_ROLES,
    RIGHTS.CREATE_TEAMS,
    RIGHTS.CREATE_PERSONAL_FOLDERS,
    RIGHTS.MANAGE_TEAM_FOLDERS,
    RIGHTS.DEEPLINK_TO_CONTENT,
    RIGHTS.HAS_DEVELOPER_ACCESS,
    RIGHTS.EDIT_PROJECT_SETTINGS,
    RIGHTS.VIEW_GAMIFICATION,
    RIGHTS.VIEW_TEAM_GAMIFICATION,
    RIGHTS.RECEIVE_GAMIFICATION_PERSONAL_REPORT
];





