<template>
  <div class="translate-item">
    <h6 class="translate-item__header">{{ trans.identifier }}</h6>
    <div class="translate-item__values">
      <b-textarea v-model="sourceText" disabled class="form-control"></b-textarea>
      <b-textarea v-model="targetText" class="form-control" :class="{ 'has-changes': hasChanges}"></b-textarea>
    </div>
    <div class="translate-item__status mt-2" v-if="hasChanges && translationStatus !== TranslationStatus.IDLE">
      <div></div>
      <div class="translate-item__status__wrapper">
        <div class="translate-item__status__text">
          <span v-if="hasChanges">{{ $t('Translate_changes') }}</span>
        </div>
        <div class="translate-item__status__icon">
          <i v-if="translationStatus === TranslationStatus.AUTO_TRANSLATED || translationStatus === TranslationStatus.AUTO_TRANSLATED_AND_CORRECTED" class="fa-solid fa-circle-check fa-lg text-success"></i>
          <i v-if="translationStatus === TranslationStatus.AUTO_TRANSLATION_ERROR" class="fa-solid fa-circle-exclamation fa-lg text-danger"></i>
          <i v-if="translationStatus === TranslationStatus.AUTO_TRANSLATED_AND_REVIEW_NEEDED" class="fa-solid fa-circle-question fa-lg text-warning"></i>
          <i v-if="translationStatus === TranslationStatus.IMPORTED" class="fa-solid fa-file-import fa-lg text-secondary"></i>
          <b-spinner v-if="translationStatus === TranslationStatus.AUTO_TRANSLATION_PENDING" small variant="secondary"></b-spinner>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from "vue-property-decorator";
import {ITranslationMultiLangDto} from "@/_model/app.dto";
import {IMultiLangTransValue} from "@/__libs/language_manager/LanguageManager";
import {TranslationStatus} from "@/_model/app.constants";

@Component({
    computed: {
        TranslationStatus() {
            return TranslationStatus;
        }
    },
    components: {}
})
export default class EntityTranslationItemRenderer extends Vue {

    @Prop() public trans!: ITranslationMultiLangDto;
    @Prop() public sourceLanguage!: string;
    @Prop() public targetLanguage!: string;


    get sourceText(): string {
        if (this.trans.multiLangTrans.hasOwnProperty(this.sourceLanguage)) {
            return this.trans.multiLangTrans[this.sourceLanguage].text;
        }
        return ""
    }

    get targetText(): string {
        if (this.trans.multiLangTrans.hasOwnProperty(this.targetLanguage)) {
            return this.trans.multiLangTrans[this.targetLanguage].text;
        }
        return ""
    }

    set targetText(p_text: string) {
        if (!this.trans.multiLangTrans.hasOwnProperty(this.targetLanguage)) {
            this.trans.multiLangTrans[this.targetLanguage] = {} as IMultiLangTransValue;
        }
        this.trans.multiLangTrans[this.targetLanguage].text = p_text;
        this.trans.multiLangTrans[this.targetLanguage].hasChanges = true;
        if (this.trans.multiLangTrans[this.targetLanguage].translationStatus === TranslationStatus.AUTO_TRANSLATED) {
            this.trans.multiLangTrans[this.targetLanguage].translationStatus = TranslationStatus.AUTO_TRANSLATED_AND_CORRECTED;
        }
    }

    get hasChanges() {
        if (this.trans.multiLangTrans.hasOwnProperty(this.targetLanguage)) {
            return this.trans.multiLangTrans[this.targetLanguage].hasChanges;
        }
        return false;
    }

    get translationStatus() {
        if (this.trans.multiLangTrans.hasOwnProperty(this.targetLanguage)) {
            return this.trans.multiLangTrans[this.targetLanguage].translationStatus;
        }
        return TranslationStatus.IDLE;
    }

}
</script>
