<template>
  <div class="user-status">
    <AAImage :image-url="statusIcon"></AAImage>
    <div class="user-status__texts">
      <p class="mb-0" v-html="$t('GamificationStatus')"></p>
      <h5 class="mb-0" v-html="statusName"></h5>
    </div>
  </div>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import gamificationController from "@/gamification/_controller/GamificationController";
import AppUserModel from "@/project/user/_model/AppUserModel";
import GamificationUtil from "@/gamification/_controller/GamificationUtil";
import languageManager from "@/__libs/language_manager/LanguageManager";
import AAImage from "@/_view/components/AAImage.vue";

@Component({
  components: {
    AAImage
  }
})
export default class UserStatus extends Vue {

  private appUser: AppUserModel = AppUserModel.getInstance();

  get statusIndex(): number {
    return GamificationUtil.getStatusIndex(this.appUser.gamificationState.coins, this.appUser.project.config.gamification.status);
  }

  get statusIcon(): string {
    return GamificationUtil.getStatusIcon(this.statusIndex);
  }

  get statusName(): string {
    return languageManager.getTranslationForValue(GamificationUtil.getStatusName(this.statusIndex, this.appUser.project.config.gamification.status));
  }
}
</script>
