<template>
  <div class="sub-page team-roles">
    <div class="columns">

      <RolesOverview :selState="selState"></RolesOverview>
      <RoleDetail :selState="selState"></RoleDetail>

    </div>
  </div>
</template>

<script lang="ts">
import {Component, Vue} from "vue-property-decorator";
import roleController from "@/team/_controller/RoleController";
import RoleModel from "@/team/_model/RoleModel";
import SelectionState from "@/__libs/_model/SelectionState";
import RoleListModel from "@/team/_model/RoleListModel";
import RoleItemRenderer from "@/team/_view/RoleItemRenderer.vue";
import RoleDetail from "@/team/_view/RoleDetail.vue";
import AppUserModel from "@/project/user/_model/AppUserModel";
import {RIGHTS} from "@/team/_model/role.constants";
import RolesOverview from "@/team/_view/RolesOverview.vue";

enum RolesViewState {
    IDLE,
    LOADING,
    ERROR_LOADING,
    LOADED,
    NO_USERS
}

@Component({
    components: {RolesOverview, RoleDetail}
})
export default class RolesView extends Vue {
    public selState: SelectionState<RoleModel> = RoleListModel.getInstance().globalSelState;
}
</script>
