<template>
  <div class="root-page welcome-page">

    <AAImage class="welcome-page__logo" imageUrl="img/init/init-logo.png"></AAImage>

    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>

  </div>
</template>

<script lang="ts">
import {Component, Vue} from "vue-property-decorator";
import AAImage from "@/_view/components/AAImage.vue";

@Component({
  components: {AAImage}
})
export default class WelcomePage extends Vue {
}
</script>
