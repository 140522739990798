<template>
  <div class="welcome-navigation">

    <div class="welcome-navigation__bars mb-4">
      <div class="bar"
           v-for="index in pageAmount"
           :key="index"
           :class="{ active: pageIndex === index }"
      />
    </div>

    <div class="welcome-navigation__buttons">
      <a class="skip" v-html="$t('WelcomeNavigationSkip')" @click="next()"></a>
      <b-button variant="success" v-html="$t('WelcomeNavigationNext')" @click="next()"></b-button>
    </div>

  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from "vue-property-decorator";
import AAImage from "@/_view/components/AAImage.vue";
import {RoutingIdentifier} from "@/router";

@Component({
  components: {AAImage}
})
export default class WelcomeStartPage extends Vue {

  @Prop() private pageIndex!: number;

  private pageAmount: number = 3;
  private pages: string[] = [ RoutingIdentifier.WELCOME_LOGO, RoutingIdentifier.WELCOME_COLOR, RoutingIdentifier.WELCOME_FONT, RoutingIdentifier.WELCOME_FINISH ];

  private async next() {
    await this.$router.push({name: this.pages[this.pageIndex]});
  }

}
</script>
