import Util from "@/__libs/utility/Util";
import axios, {AxiosInstance, AxiosRequestConfig, AxiosResponse, Method} from 'axios';
import appUserController from "@/project/user/_controller/AppUserController";
import NetworkManager from "@/_controller/NetworkManager";
import AppUserModel from "@/project/user/_model/AppUserModel";


class ApiManager
{



    // ---------------------------------
    // Public Static Properties
    // ---------------------------------



    //---------------------------------
    // Public Properties
    //---------------------------------


    //---------------------------------
    // Private Properties
    //---------------------------------



    private _projectApiUrl!:string;
    set projectApiUrl(value:string)
    {
        this._projectApiUrl = value;
        this._projectApi = axios.create({
            baseURL: this._projectApiUrl,
        });
    }

    private _projectApi!:AxiosInstance;


    private _masterApiUrl!:string;
    set masterApiUrl(value:string)
    {
        this._masterApiUrl = value;
        this._masterApi = axios.create({
            baseURL: this._masterApiUrl,
        });
    }

    private _masterApi!:AxiosInstance;

    private _appUserModel:AppUserModel = AppUserModel.getInstance();

    private _registeredUsageTimeStamp:number = 0;
    private _minRegisterUsageSeconds:number = 3600; //if the user stays idle for more than 1 hour, a new usage session is created

    //---------------------------------
    // getter setters
    //---------------------------------



    //---------------------------------
    // Private Methods
    //---------------------------------



    //---------------------------------
    // Public Methods
    //---------------------------------



    public async sendApiRequest<T>(p_requestType:ApiRequestType, p_endpoint:string, p_data:any = null, p_scope:ApiRequestScope = ApiRequestScope.PROJECT):Promise<ApiResponse<T>>
    {

        let api:AxiosInstance;
        if (p_scope === ApiRequestScope.PROJECT)
        {
            api = this._projectApi;
            if (NetworkManager.getInstance().online)
            {
                await this._checkAuthorization();
            }

            const timeStamp:number = Math.round(new Date().getTime() / 1000);

            if (timeStamp - this._registeredUsageTimeStamp > this._minRegisterUsageSeconds && !appUserController.inImpersonationMode && this._appUserModel.sessionID > 0)
            {
                this._registeredUsageTimeStamp = timeStamp;
                appUserController.registerUsageSession();
            }

        }
        else
        {
            api = this._masterApi;
        }

        const response:ApiResponse<T> = {hasSucceeded: false};

        try
        {
            const requestConfig:AxiosRequestConfig = {
                method: p_requestType, url: p_endpoint
            };
            if (p_requestType === ApiRequestType.GET && p_data)
            {
                requestConfig.params = p_data;
            }
            else
            {
                requestConfig.data = p_data;
            }

            const axiosResponse:AxiosResponse = await api.request(requestConfig);

            response.result = axiosResponse.data;
            response.hasSucceeded = true;

        } catch (e)
        {
            // console.log("ERROR:", e);
            response.error = {message: e.message, isOperational: false, code: 400};
            if (e.response && e.response.status)
            {
                response.error.code = e.response.status;
            }
            response.hasSucceeded = false;
        }

        return response;
    }


    public async sendMockRequest<T>(p_mockResponse:any = null, p_duration:number = 100, hasSucceeded:boolean = true):Promise<ApiResponse<T>>
    {
        const response:ApiResponse<T> = {hasSucceeded: hasSucceeded};

        await Util.timeOut(p_duration);

        return response;
    }


    public async uploadFile<T>(p_requestType:ApiRequestType, p_endpoint:string, p_file:File, p_data:any = null):Promise<ApiResponse<T>>
    {
        if (NetworkManager.getInstance().online)
        {
            await this._checkAuthorization();
        }

        const response:ApiResponse<T> = {hasSucceeded: false};

        const formData:FormData = new FormData();
        formData.append('file', p_file);
        if (p_data)
        {
            formData.append('data', JSON.stringify(p_data));
        }

        try
        {
            const axiosResponse:AxiosResponse = await this._projectApi.request({
                method          : p_requestType, url: p_endpoint, data: formData,
                onUploadProgress: function (progressEvent:ProgressEvent) {
                    // console.log(progressEvent.loaded);
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                }
            });

            response.result = axiosResponse.data;
            response.hasSucceeded = true;

        } catch (e)
        {
            //todo
            console.log("ERROR:", e);
            response.hasSucceeded = false;
        }


        return response;
    }

    private async _checkAuthorization()
    {
        const authToken:string | null = await appUserController.getAuthToken();
        if (authToken && this._projectApi)
        {
            this._projectApi.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
        }
    }

}

export default new ApiManager();



export interface ApiResponse<T>
{
    hasSucceeded:boolean;
    result?:T;
    error?:ApiError;
}


export interface ApiError
{
    message:string;
    code:number;
    isOperational:boolean;
}


export enum ApiRequestType
{
    GET = "get",
    POST = "post",
    PUT = "put",
    PATCH = "patch",
    DELETE = "delete",
}


export enum ApiRequestScope
{
    PROJECT = "PROJECT",
    MASTER = "MASTER"
}
