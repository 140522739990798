import {
    IChapterData,
    IDeckData,
    IPresentationBodyDto,
    IPresentationMetaDto,
    ISlideOccurrenceData
} from "@/presentation/_model/presentation.dto";
import {IModelDto} from "@/__libs/_model/model.dto";
import ContentModel from "@/content/_model/ContentModel";
import {ISlideBodyDto} from "@/slide/_model/slide.dto";
import {EntityType} from "@/entity/_model/entity.constants";
import {DtoType} from "@/_model/app.constants";
import {IAudienceBodyDto} from "@/audience/_model/audience.dto";
import {IMultiLangString} from "@/__libs/language_manager/LanguageManager";
import AppUserModel from "@/project/user/_model/AppUserModel";

export default class PresentationModel extends ContentModel implements IPresentationBodyDto, IPresentationMetaDto
{

    public entityType:EntityType = EntityType.PRESENTATION;

    public poster:IMultiLangString = {"en": `assetFolder://asf-${AppUserModel.getInstance().project.identifier}-system-assets/presentation_poster_default.png`};
    public internalName:IMultiLangString = {"en": ""};
    public construct:string = "default";
    public notes:IMultiLangString = {"en": ""};
    public languages:string[] = ["en"];
    public slideOccurrences:ISlideOccurrenceData[] = [];
    public slides:ISlideBodyDto[] = [];
    public decks:IDeckData[] = [];
    public chapters:IChapterData[] = [{
        ID              : 1,
        identifier      : null,
        index           : 0,
        level           : 0,
        name            : {
            en: "home"
        },
        parentID        : 0,
        slideOccurrences: []
    }];
    public theme:any = null;
    public customParamsURI:string = "project://custom_presentation_params#wall";
    public customParams:any = null;


    //---------------------------------
    // Constructor
    //---------------------------------

    constructor(p_dto?:IModelDto)
    {
        super();
        if (p_dto)
        {
            this.mapFromDto(p_dto);
        }
    }

    //---------------------------------
    // Public Methods
    //---------------------------------

    public mapToDto(p_dtoType:DtoType):IModelDto
    {
        if (p_dtoType === DtoType.BODY)
        {
            const dto:IPresentationBodyDto = {
                ID              : this.ID,
                version         : this.version,
                name            : this.name,
                poster          : this.poster,
                internalName    : this.internalName,
                construct       : this.construct,
                notes           : this.notes,
                languages       : this.languages,
                slideOccurrences: this.slideOccurrences,
                slides          : this.slides,
                decks           : this.decks,
                chapters        : this.chapters,
                customParamsURI : this.customParamsURI,
                customParams    : this.customParams,
                funnelStages    : this.funnelStages,
            };
            if(this.identifier)
            {
                dto.identifier = this.identifier;
            }
            return dto;
        }
        else
        {
            return super.mapToDto(p_dtoType);
        }
    }

}
