import SyncFileGroupProcess from "@/sync/_controller/process/SyncFileGroupProcess";
import {ISyncItemFileDto} from "@/sync/_model/sync.dto";
import {IDataSchemaDto} from "@/data_tool/_model/data_tool.dto";
import DataSchemaUtil from "@/data_tool/_controller/DataSchemaUtil";
import {IModuleCfg} from "@/slide/_model/slide.dto";
import syncController from "@/sync/_controller/SyncController";
import {SyncProcessType} from "@/sync/_model/sync.constants";
import SyncSchemaProcess from "@/sync/_controller/process/SyncSchemaProcess";

class SyncModuleProcess extends SyncFileGroupProcess
{

    //---------------------------------
    // Properties
    //---------------------------------

    protected _storagePath:string = "modules";
    public dependencyLevel:number = 2;

    private _schemaProcess!:SyncSchemaProcess;


    //---------------------------------
    // Controller Methods
    //---------------------------------



    //---------------------------------
    // Private Methods
    //---------------------------------

    protected _resolveDependencyProcesses()
    {
        this._schemaProcess = syncController.resolveDependencyProcess(SyncProcessType.SCHEMA) as SyncSchemaProcess;

    }

    protected _parseRetrievedItemFile(p_itemFile:ISyncItemFileDto)
    {
        super._parseRetrievedItemFile(p_itemFile);

        const moduleCfg:IModuleCfg = p_itemFile.body as IModuleCfg;//IDataSchemaDto;

        if (moduleCfg.dataSchemaURI)
        {
            this._schemaProcess.addSyncItem({identifier: moduleCfg.dataSchemaURI.split("://")[1]});
        }


        //

    }

}

//Singleton export
export default SyncModuleProcess;
