<template>
  <div class="box box--full-height box--scrollable team-teams-detail">
    <transition name="fade">
      <div v-if="!selState.selected"
           class="empty-message">
        {{ $t('PleaseSelectATeam') }}
      </div>
    </transition>

    <transition name="fade">
      <div v-if="team" :key="`${team.teamID}_primary`" class="box-wrapper">
        <div class="detail-header">
          <div class="detail-header__person">
            <AAProfilePicture :name="team.displayName"
                              :imageUri="team.avatarFileUri"
                              :editable="true"
                              :big="true"
                              v-on:click.native="_onOpenAssetPickerBtnClick"></AAProfilePicture>
            <div>
              <h4 class="title">{{ team.displayName }}</h4>
            </div>
          </div>
          <div class="detail-header__buttons">
            <b-button variant="outline-secondary" block class="action-btn"
                      @click="_onOpenAssetPickerBtnClick">
              <i class="fas fa-image fa-lg"></i>{{ $t('AddProfilePicture') }}
            </b-button>
          </div>
        </div>

        <div class="detail-inputs">
          <b-form-group :label="$t('PlaceholderDisplayName')">
            <b-form-input :disabled="!isEditable"
                          v-model="validationTeam.displayName"
                          :state="_calculateInputState($v.validationTeam.displayName)"
                          @input="team.displayName = _onFormValidationInput($v.validationTeam.displayName, team.displayName)"
                          tabindex="1"></b-form-input>
            <b-form-invalid-feedback>
              <span class="d-block"
                    v-if="!$v.validationTeam.displayName.required">{{
                  $t('FieldRequiredSimple')
                }}</span>
              <span class="d-block"
                    v-if="!$v.validationTeam.displayName.minLength">{{
                  $t('MinimumFieldLengthSimple', [2])
                }}</span>
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group v-if="showParentTeam">
            <b-select v-model="team.parentTeamID"
                      :disabled="!isEditable"
                      @change="_onFormInput" tabindex="5">
              <option v-for="team in availableTeams" :key="team.teamID"
                      :value="team.teamID">{{ team.displayName }}
              </option>
            </b-select>
          </b-form-group>

          <b-form-group :label="$t('CountryIdentifier')">
            <b-input v-model="team.countryIdentifier" :disabled="!isEditable" @input="_onFormInput"
                     tabindex="6"></b-input>
          </b-form-group>

          <b-form-group>
            <b-form-checkbox @input="_onFormInput"
                             v-model="team.allowPersonalFromMail">{{
                $t('AllowPersonalFromMail')
              }}
            </b-form-checkbox>
          </b-form-group>
        </div>

        <h6 class="title" v-html="$t('PlaceholderPersonLanguage')"></h6>
        <div class="sub-box team-teams-detail__box">
          <b-form-group>
            <label>
              <i class="fas fa-globe"></i>
              {{ $t('LanguagesForTeamUsers') }}
            </label>
            <b-form-checkbox v-for="langCode in projectLanguages" :key="langCode"
                             :value="langCode" @input="_onFormInput"
                             v-model="team.availableLanguages">
              {{ langCode }}
            </b-form-checkbox>
          </b-form-group>
        </div>

        <h6 class="title" v-html="$t('SyncWidgetExtraInfo')" v-if="team.dataDtp"></h6>
        <DataProviderEditor :dataProvider="team.dataDtp" :isStandalone="true" v-if="team.dataDtp" />

        <h6 class="title" v-html="$t('UserDetails')"></h6>
        <table class="table table-sm table-striped table-borderless">
          <tbody>
          <tr>
            <th scope="row">{{ $t('CreatedDate') }}</th>
            <td>{{ $d(new Date(team.createdDate), 'short') }}</td>
          </tr>
          </tbody>
        </table>

      </div>
    </transition>

    <b-modal v-if="team" id="app-user-asset-picker-box" size="xl" :title="$t('PickTeamAvatarOrUploadNewOne')"
             hide-footer>
      <AssetFoldersPicker :selectedFileUri="team.avatarFileUri"
                          :allowedTargetType="avatarAllowedTargetType"
                          :allowedFileTypes="avatarAllowedFileTypes"
                          v-on:onAssetFilePicked="_onAssetFilePicked"></AssetFoldersPicker>
    </b-modal>

  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import AssetFileModel from "@/asset_folder/_model/AssetFileModel";
import AssetFoldersPicker from "@/asset_folder/_view/AssetFoldersPicker.vue";
import {FileTargetType, FileType} from "@/asset_folder/_model/asset_folder.constants";
import SyncWidget from "@/sync/_view/SyncView.vue";
import AAProfilePicture from "@/_view/components/AAProfilePicture.vue";
import {minLength, required} from "vuelidate/lib/validators";
import SelectionState from "@/__libs/_model/SelectionState";
import TeamModel from "@/team/_model/TeamModel";
import AppUserModel from "@/project/user/_model/AppUserModel";
import {ITeamOutlineDto} from "@/team/_model/team.dto";
import teamController from "@/team/_controller/TeamController";
import {RIGHTS} from "@/team/_model/role.constants";
import TeamListModel from "@/team/_model/TeamListModel";
import DataProviderEditor from "@/data_tool/data_provider/_view/DataProviderEditor.vue";

@Component({
    components: {
        AAProfilePicture, AssetFoldersPicker, SyncWidget, DataProviderEditor
    },
    validations: {
        validationTeam: {
            displayName: {
                required,
                minLength: minLength(2)
            }
        }
    }
})
export default class TeamDetail extends Vue {

    //---------------------------------
    // Vue Component props
    //---------------------------------

    @Prop() private selState!: SelectionState<TeamModel>;

    //---------------------------------
    // Vue Component data
    //---------------------------------

    public avatarLoaded: Boolean = false;

    public avatarAllowedTargetType: FileTargetType = FileTargetType.TEAM_AVATAR;
    public avatarAllowedFileTypes: FileType[] = [FileType.IMAGE];

    private validationTeam: TeamModel = new TeamModel();


    //---------------------------------
    // Vue Computed properties
    //---------------------------------


    get availableTeams(): ITeamOutlineDto[] {
        return TeamListModel.getInstance().list.filter((p_team: ITeamOutlineDto) => {
            if (p_team.teamID !== this.team!.teamID) {
                return true;
            }
        })
    }

    get projectLanguages(): string[] {
        return AppUserModel.getInstance().project.availableLangCodes;
    }


    get hasChanges() {
        return this.team && this.team.hasChanges;
    }


    get team() {
        return this.selState.selected;
    }


    get isEditable(): boolean {
        return AppUserModel.getInstance().rights.indexOf(RIGHTS.EDIT_USERS.identifier) >= 0;
    }

    get showParentTeam(): boolean {
        if (this.team) {
            //don't show the parent team if it is null or not in your list of available teams
            if (!this.team.parentTeamID) {
                return false;
            }
            for (let i = 0; i < TeamListModel.getInstance().list.length; i++) {
                if (this.team.teamID === TeamListModel.getInstance().list[i].teamID) {
                    return true;
                }
            }
        }
        return false;
    }

    //---------------------------------
    // Public / lifecycle methods
    //---------------------------------

    mounted() {
        // this._createValidationTeam();
        window.addEventListener('beforeunload', this._beforeUnload);
    }

    beforeDestroy() {
        if (this.team && this.team.hasChanges) {
            this._saveTeam(this.team);
        }

        window.removeEventListener('beforeunload', this._beforeUnload)
    }

    //---------------------------------
    // Private / helper methods
    //---------------------------------

    private _calculateInputState(inputValidator: any): any {
        return inputValidator.$invalid ? false : null;
    }

    private _beforeUnload(e: BeforeUnloadEvent) {
        //this._saveUser();
        // e.returnValue = "Are you sure you want to leave, changes you made will not be saved.";
        // return e.returnValue;
    }

    private _createValidationTeam(p_team: TeamModel): void {
        if (this.team) {
            this.validationTeam = new TeamModel();
            this.validationTeam.displayName = p_team.displayName;
        }
    }

    private _onFormValidationInput(inputValidator: any, value: any): void {
        if (!inputValidator.$invalid && this.team) {
            value = inputValidator.$model;
            this._onFormInput(null);
        }
        return value;
    }

    private _onFormInput(p_e: Event | null) {
        this.team!.hasChanges = true;
    }


    private _saveTeam(p_team: TeamModel) {
        teamController.saveTeam(p_team);
    }


    private _onOpenAssetPickerBtnClick(p_e: Event) {
        this.$bvModal.show('app-user-asset-picker-box');
    }

    private _onAssetFilePicked(p_assetFile: AssetFileModel) {
        this.$bvModal.hide('app-user-asset-picker-box');
        if (this.isEditable && p_assetFile && this.team) {
            this.team.avatarFileUri = p_assetFile.fileURI;
            this.team.hasChanges = true;
        }
    }

    @Watch('team', {immediate: true, deep: false})
    private _onActiveTeamChange(newTeam: TeamModel | null, oldTeam: TeamModel | null) {
        if (oldTeam && oldTeam.hasChanges) {
            this._saveTeam(oldTeam);
        }
        if (newTeam) {
            this._createValidationTeam(newTeam);
        }
    }

    private async _onDeleteUserBtnClick(p_e: Event) {
        const deleteConfirmed: boolean = await this.$bvModal.msgBoxConfirm(this.$t("SureDeleteTeam") as string, {centered: true});

        if (deleteConfirmed) {
            const isDeleted: boolean = await teamController.deleteTeam(this.team!);
        }
    }

    private async _onImpersonateUserBtnClick(p_e: Event) {

    }

}
</script>

