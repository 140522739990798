<template>
  <div class="columns asset-browser">

    <AssetFoldersOverview :selState="selState"></AssetFoldersOverview>
    <AssetFolderDetail :selState="selState"></AssetFolderDetail>
    <AssetFileDetail :selState="selState"></AssetFileDetail>

  </div>
</template>


<script lang="ts">
import {Component, Prop, Vue} from "vue-property-decorator";
import AssetFoldersOverview from "@/asset_folder/_view/AssetFoldersOverview.vue";
import AssetFolderModel from "@/asset_folder/_model/AssetFolderModel";
import AssetFolderSelectionState from "@/asset_folder/_model/AssetFolderSelectionState";
import AssetFolderDetail from "@/asset_folder/_view/AssetFolderDetail.vue";
import AssetFileDetail from "@/asset_folder/_view/AssetFileDetail.vue";
import HintListController from "@/help/hint/_controller/HintListController";
import {HintGroupIds} from "@/help/hint/_model/hint.constants";

@Component({
  components: {AssetFoldersOverview, AssetFolderDetail, AssetFileDetail}
})
export default class AssetFoldersView extends Vue {
  //---------------------------------
  // Vue Component props
  //---------------------------------

  @Prop() private selState!: AssetFolderSelectionState;

  //---------------------------------
  // Vue Component data
  //---------------------------------

  //---------------------------------
  // Vue Computed properties
  //---------------------------------

  //---------------------------------
  // Public / lifecycle methods
  //---------------------------------

  public mounted() {
      HintListController.startGroup(HintGroupIds.FILES);
  }

  //---------------------------------
  // Private / helper methods
  //---------------------------------
}
</script>
