<template>
    <div class="base-page analytics-project">

        <div class="aa-container h-100">
            <div class="row justify-content-center h-100">
                <div class="col-12 h-100">

                    <div class="box aa-padding-wrapper">

                        <h1 class="mb-4">{{ $t('AnalyticsTitle') }}</h1>



<!--                        <date-picker class="mb-5" v-model="analytics.range" type="date" range :clearable="false"-->
<!--                                     :disabled="currentState === analyticsPageState.LOADING"-->
<!--                                     @input="_onRangeChanged"></date-picker>-->

                      <b-button variant="outline-secondary" class="mb-4" :disabled="!exportAllowed" @click="_onExportBtnClick">Export project usage</b-button>
                        <b-button variant="outline-secondary" class="ml-4 mb-4" :disabled="!exportAllowed" @click="_onExportLicencesBtnClick">Export project licences</b-button>


                      <transition name="fade">
                            <div v-if="currentState === analyticsPageState.LOADING"
                                 class="loading d-flex justify-content-center align-items-center">
                                <b-spinner variant="dark"></b-spinner>
                            </div>
                        </transition>

                        <transition name="fade">
                            <div v-if="currentState === analyticsPageState.ERROR_LOADING"
                                 class="placeholder-text text-danger">
                                {{ $t('ErrorConnectionRequired') }}
                            </div>
                        </transition>

                        <transition name="fade">
                            <div v-if="currentState === analyticsPageState.LOADED">

                                <h2 class="mb-4">{{ $t('AnalyticsSubtitleUsers') }}</h2>

                                <AnalyticsUserTotals class="mb-5"></AnalyticsUserTotals>

                                <AnalyticsUserEvolution class="mb-5"></AnalyticsUserEvolution>

                                <AnalyticsTopTeams class="mb-5"></AnalyticsTopTeams>

                                <h2 class="mb-4">{{ $t('AnalyticsSubtitleUsage') }}</h2>

                                <AnalyticsPresentationEvolution class="mb-5"></AnalyticsPresentationEvolution>

                                <AnalyticsTopUsage></AnalyticsTopUsage>

                            </div>
                        </transition>

                    </div>

                </div>
            </div>
        </div>

    </div>
</template>


<script lang="ts">
    import {Component, Vue} from "vue-property-decorator";
    import DatePicker from "vue2-datepicker";
    import AnalyticsModel from "@/analytics/_model/AnalyticsModel";
    import analyticsController from "@/analytics/_controller/AnalyticsController";
    import AnalyticsUserTotals from "@/analytics/_view/AnalyticsUserTotals.vue";
    import AnalyticsUserEvolution from "@/analytics/_view/AnalyticsUserEvolution.vue";
    import AnalyticsTopTeams from "@/analytics/_view/AnalyticsTopTeams.vue";
    import AnalyticsPresentationEvolution from "@/analytics/_view/AnalyticsPresentationEvolution.vue";
    import AnalyticsTopUsage from "@/analytics/_view/AnalyticsTopUsage.vue";
    import {RoutingIdentifier} from "@/router";

    enum AnalyticsPageState
    {
        IDLE,
        LOADING,
        ERROR_LOADING,
        LOADED
    }

    @Component({
        components: {
            DatePicker,
            AnalyticsUserTotals,
            AnalyticsUserEvolution,
            AnalyticsTopTeams,
            AnalyticsPresentationEvolution,
            AnalyticsTopUsage
        }
    })
    export default class AnalyticsProjectPage extends Vue
    {
        //---------------------------------
        // Vue Component props
        //---------------------------------

        //---------------------------------
        // Vue Component data
        //---------------------------------

        private analyticsPageState:typeof AnalyticsPageState = AnalyticsPageState;
        private currentState:AnalyticsPageState = AnalyticsPageState.IDLE;

        private exportAllowed:boolean = true;

        //---------------------------------
        // Vue Computed properties
        //---------------------------------

        get analytics()
        {
            return AnalyticsModel.getInstance();
        }

        //---------------------------------
        // Public / lifecycle methods
        //---------------------------------

        mounted()
        {
            this._loadAnalytics();
        }

        //---------------------------------
        // Private / helper methods
        //---------------------------------

        private _onRangeChanged()
        {
            this._loadAnalytics();
        }

        private async _loadAnalytics()
        {
            this.currentState = AnalyticsPageState.LOADING;
            const hasSucceeded:boolean = await analyticsController.fetchAnalytics(this.analytics);
            if (hasSucceeded)
            {
                this.currentState = AnalyticsPageState.LOADED;
            }
            else
            {
                this.currentState = AnalyticsPageState.ERROR_LOADING;
            }
        }

        private async _onExportBtnClick()
        {
          this.exportAllowed = false;
          const hasSucceeded:boolean = await analyticsController.fetchProjectAnalyticsForExport(this.analytics);
          this.exportAllowed = true;

        }

        private async _onExportLicencesBtnClick()
        {
            this.exportAllowed = false;
            const hasSucceeded:boolean = await analyticsController.fetchProjectLicencesForExport(this.analytics);
            this.exportAllowed = true;

        }
    }
</script>
