


export enum CobrowseConnectionIdentifier
{
    PWA = "PWA",
    PORTAL = "PORTAL"
}


