import {IContentAppResourceDto} from "@/content_app/resource/_model/content_app_resource.dto";
import {ContentAppType} from "@/content_app/_model/content_app.constants";
import {Runtime} from "@/_model/app.constants";
import {IMultiLangString} from "@/__libs/language_manager/LanguageManager";
import {IFileOutlineDto} from "@/asset_folder/_model/asset_folder.dto";
import Model from "@/__libs/_model/Model";

export default class ContentAppResourceModel extends Model implements IContentAppResourceDto {
    public identifier: string = "";
    public name: IMultiLangString = {};
    public defaultPoster: string = "";
    public dataSchemaURI: string = "";
    public hasCustomEditMode: boolean = false;
    public hasCustomAnalyticsMode: boolean = false;
    public version: number = 0;
    public createdBy: string = "";
    public canBeCreated: boolean = false;
    public canBePresented: boolean = false;
    public canBeTrained: boolean = false;
    public canBePrepared: boolean = false;
    public canBeSharedWithAudiences: boolean = false;
    public canBeCopied: boolean = false;
    public  canBeEdited: boolean = false;
    public availableRuntimes: Runtime[] = [];
    public appType: ContentAppType = ContentAppType.HTML_APP;
    public externalLaunchUrl: string = "";
    public files: IFileOutlineDto[] = [];
    public totalFileSize: number = 0;
}
