<template>
  <div class="home-header">
    <div class="home-header__left">
      <AudienceBanner></AudienceBanner>
      <transition name="fade">
        <b-button variant="secondary" size="md" @click="toHome" v-if="!onHomePage">
          <i class="fa-solid fa-chevron-left mr-2"></i>
          {{ $t('PlaylistBack') }}
        </b-button>
      </transition>
    </div>
    <Highlight></Highlight>
  </div>
</template>

<script lang="ts">
import {Component, Vue} from "vue-property-decorator";
import AudienceBanner from "@/audience/_view/AudienceBanner.vue";
import Highlight from "@/_view/components/Highlight.vue";
import {RoutingIdentifier} from "@/router";

@Component({
    components: {AudienceBanner, Highlight}
})
export default class HomeHeader extends Vue {
    get onHomePage(): boolean {
        return this.$route.name === RoutingIdentifier.CONTENT_OVERVIEW;
    }

    public async toHome() {
        await this.$router.push({name: RoutingIdentifier.CONTENT_OVERVIEW});
    }
}
</script>
