<script lang="ts">
    import {Component, Mixins, Prop, Vue} from 'vue-property-decorator';
    import {
        IAnalyticsActionCfgDto, IAnalyticsActionEvolutionGraphWidgetCfgDto, IAnalyticsActionEvolutionGraphWidgetDataDto,
        IAnalyticsSubjectDto,
        IAnalyticsSubjectListWithActionsWidgetCfgDto,
        IAnalyticsSubjectListWithActionsWidgetDataDto
    } from "@/analytics/_model/analytics.dto";
    import languageManager, {IMultiLangString} from "@/__libs/language_manager/LanguageManager";
    import AppUserModel from "@/project/user/_model/AppUserModel";
    import {
        ANALYTICS_LINE_GRAPH_OPTIONS,
        LINE_DATASET_COLORS,
        AnalyticsValueDisplayType
    } from "@/analytics/_model/analytics.constants";
    import {Line} from "vue-chartjs";
    import ChartJsPluginDataLabels from 'chartjs-plugin-datalabels';
    import {ChartDataSets, ChartOptions} from "chart.js";
    import AnalyticsModel from "@/analytics/_model/AnalyticsModel";

    @Component({
        components: {ChartJsPluginDataLabels}
    })
    export default class AnalyticsWidgetActionEvolutionGraph extends Mixins(Line)
    {

        //---------------------------------
        // Vue Component props
        //---------------------------------

        @Prop() private widgetCfg!:IAnalyticsActionEvolutionGraphWidgetCfgDto;
        @Prop() private widgetData!:IAnalyticsActionEvolutionGraphWidgetDataDto;

        //---------------------------------
        // Vue Component data
        //---------------------------------



        //---------------------------------
        // Vue Computed properties
        //---------------------------------



        //---------------------------------
        // Public / lifecycle methods
        //---------------------------------

        public mounted()
        {
            const options:ChartOptions = {
                ...ANALYTICS_LINE_GRAPH_OPTIONS, scales: {
                    xAxes: [{
                        type        : 'time',
                        distribution: 'linear',
                        gridLines   : {
                            display: false
                        },
                        ticks       : {
                            maxTicksLimit: 20,
                            min          : AnalyticsModel.getInstance().range[0],
                            max          : AnalyticsModel.getInstance().range[1]
                        }
                    }],
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                            stepSize   : 1
                        }
                    }],
                }
            };

            const dataSets:ChartDataSets[] = [];
            for (let i = 0; i < this.widgetCfg.actions.length; i++)
            {
                const action = this.widgetCfg.actions[i];
                const dataSet:ChartDataSets = {
                    label      : AnalyticsModel.resolveLabelTranslation(action.name as any),
                    data       : this.widgetData.actions[action.identifier],
                    borderColor: LINE_DATASET_COLORS[Math.min(i, LINE_DATASET_COLORS.length - 1)]
                };
                dataSets.push(dataSet);
            }

            this.renderChart({datasets: dataSets}, options)

        }



        //---------------------------------
        // Private / helper methods
        //---------------------------------



    }
</script>

