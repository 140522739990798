<template>
    <div class="app-user-summary box box--full-height box--scrollable" v-if="appUser">

        <div class="app-user-summary__content">
            <AAProfilePicture :name="appUser.displayName"
                              :imageUri="appUser.avatarFileUri"
                              :editable="true"
                              :huge="true"
                              class="mb-4"
                              v-on:click.native="_onOpenAssetPickerBtnClick"></AAProfilePicture>
            <h2 class="mb-2 title">{{ appUser.displayName }}</h2>
            <h5 class="mb-5">{{ appUser.email }}</h5>
            <div class="mb-5">
                {{ appUser.roleIdentifier }}<br/><i><small>{{ $t('AppUserInTeam') }}</small></i><br/>{{
                    appUser.teamName
                }}<br/><i><small>({{ appVersion }})</small></i>
            </div>

            <b-button variant="outline-secondary" @click="_onLogoutBtnClick" class="action-btn" block><i class="fas fa-right-from-bracket fa-lg"></i>{{ $t('MainMenuLogout') }}</b-button>
            <b-button v-if="hasNotificationPermission" variant="outline-secondary"
                      @click="_onDisablePushNotificationsBtnClick" block class="action-btn"><i class="fas fa-bell-slash fa-lg"></i>{{ $t('DisablePushNotifications') }}
            </b-button>
            <b-button v-else variant="outline-secondary" @click="_onEnablePushNotificationsBtnClick" block class="action-btn">
              <i class="fas fa-bell fa-lg"></i>{{ $t('EnablePushNotifications') }}
            </b-button>

        </div>



    </div>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import AssetFileModel from "@/asset_folder/_model/AssetFileModel";
import AssetFoldersPicker from "@/asset_folder/_view/AssetFoldersPicker.vue";
import {FileTargetType, FileType} from "@/asset_folder/_model/asset_folder.constants";
import {RoutingIdentifier} from "@/router";
import AppUserModel from "@/project/user/_model/AppUserModel";
import AAProfilePicture from "@/_view/components/AAProfilePicture.vue";
import AppModel from "@/_model/AppModel";
import serviceWorkerController from "@/_controller/ServiceWorkerController";
import Util from "@/__libs/utility/Util";
import appUserController from "@/project/user/_controller/AppUserController";


@Component({
    components: {

    }
})
export default class AppUserSummary extends Vue
{

    public avatarAllowedTargetType:FileTargetType = FileTargetType.USER_AVATAR;
    public avatarAllowedFileTypes:FileType[] = [FileType.IMAGE];

    public appUser:AppUserModel = AppUserModel.getInstance();
    public appVersion:string | undefined = AppModel.getInstance().version;



}
</script>

