import {ENTITY_CONFIG} from "@/entity/_model/entity.constants";
import EntityListController from "@/entity/_controller/EntityListController";

import {IEntityMetaDto} from "@/entity/_model/entity.dto";
import SlideModel from "@/slide/_model/SlideModel";
import SlideListModel from "@/slide/_model/SlideListModel";
import slideController from "@/slide/_controller/SlideController";
import SlideSelectionState from "@/slide/_model/SlideSelectionState";
import fileManager from "@/_controller/FileManager";


//contains all controller methods that can be applied on the list of slides
class SlideListController extends EntityListController<SlideModel>
{

    constructor()
    {
        super(ENTITY_CONFIG.SLIDE, SlideListModel.getInstance(), slideController)
    }

    //---------------------------------
    // Properties
    //---------------------------------

    private _slideListModel:SlideListModel = SlideListModel.getInstance();

    //---------------------------------
    // Controller Methods
    //---------------------------------



    //---------------------------------
    // Private Methods
    //---------------------------------

    protected __newEntity(p_dto:IEntityMetaDto):SlideModel
    {
        return new SlideModel(p_dto);
    }

}

//Singleton export
export default new SlideListController();
