<template>
  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
    <path class="cls-1" d="M9.7,16.49c-3.67,1.17-5.07,3.4-5.54,5.39H1.09c-.6,0-1.08-.48-1.08-1.08v-1.29c0-2.31,1.89-3.58,5.95-3.58,1.13,0,2.1.1,2.9.3.3.07.59.16.84.26ZM6.64,13.88c1.09-.24,1.97-1.12,2.21-2.21.48-2.17-1.41-4.05-3.57-3.57-1.09.24-1.97,1.12-2.21,2.21-.48,2.16,1.4,4.05,3.57,3.57ZM28.95,16.23c-.81-.2-1.77-.3-2.91-.3-1.55,0-2.79.19-3.72.54,3.69,1.18,5.06,3.46,5.51,5.41h3.08c.6,0,1.08-.48,1.08-1.08v-1.29c0-1.66-.98-2.78-3.04-3.28ZM26.72,13.88c1.09-.24,1.97-1.12,2.21-2.21.48-2.17-1.4-4.05-3.57-3.57-1.09.24-1.97,1.12-2.21,2.21-.48,2.16,1.41,4.05,3.57,3.57ZM20.9,18.15c-.16-.04-.32-.07-.48-.11-1.26-.26-2.73-.39-4.42-.39s-3.16.13-4.42.39c-2.96.61-4.71,1.91-5.34,3.84-.19.55-.28,1.16-.28,1.81v2.18c0,1.01.81,1.82,1.82,1.82h16.44c1.01,0,1.82-.81,1.82-1.82v-2.18c0-.65-.09-1.26-.28-1.81-.59-1.82-2.19-3.09-4.86-3.73ZM16,4.31c-2.77,0-5.01,2.24-5.01,5.01s2.24,5.01,5.01,5.01,5.01-2.24,5.01-5.01-2.24-5.01-5.01-5.01Z"/>
  </svg>
</template>

<script lang="ts">
import {Component, Vue} from "vue-property-decorator";
import MainMenu from "@/_view/menu/MainMenu.vue";
import MainLogo from "@/_view/components/MainLogo.vue";
import MainStatus from "@/_view/components/MainStatus.vue";
import NetworkManager from "@/_controller/NetworkManager";

@Component({
  components: {}
})
export default class TeamIcon extends Vue {
}
</script>
