<template>
  <div class="main-menu">
    <router-link :to="{ name: routingIdentifiers.HOME }" class="main-menu-item"
                 :class="{active: isActive(routingIdentifiers.CONTENT_OVERVIEW)}">
      <ContentIcon class="main-menu-item__icon"></ContentIcon>
      <div class="main-menu-item__name">{{ $t("MainMenuDashboard") }}</div>
    </router-link>
    <router-link :to="{ name: routingIdentifiers.TARGETS }" class="main-menu-item"
                 :class="{active: isActive(routingIdentifiers.TARGETS)}">
      <AudiencesIcon class="main-menu-item__icon"></AudiencesIcon>
      <div class="main-menu-item__name">{{ $t("MainMenuAudiences") }}</div>
    </router-link>
    <router-link :to="{ name: routingIdentifiers.LIBRARY }"
                 :class="{ disabled: !online, active: isActive(routingIdentifiers.LIBRARY) }" class="main-menu-item">
      <LibraryIcon class="main-menu-item__icon"></LibraryIcon>
      <div class="main-menu-item__name">{{ $t("MainMenuLibrary") }}</div>
    </router-link>
    <router-link :to="{ name: routingIdentifiers.PEOPLE }"
                 :class="{ disabled: !online, active: isActive(routingIdentifiers.PEOPLE) }" class="main-menu-item"
                 v-if="showTeam">
      <TeamIcon class="main-menu-item__icon"></TeamIcon>
      <div class="main-menu-item__name">{{ $t("MainMenuTeam") }}</div>
    </router-link>
    <router-link :to="{ name: routingIdentifiers.ANALYTICS }"
                 :class="{ disabled: !online, active: isActive(routingIdentifiers.ANALYTICS) }" class="main-menu-item"
                 v-if="showAnalytics">
      <InsightsIcon class="main-menu-item__icon"></InsightsIcon>
      <div class="main-menu-item__name">{{ $t("MainMenuAnalytics") }}</div>
    </router-link>
    <router-link :to="{ name: routingIdentifiers.ACCOUNT }"
                 :class="{ disabled: !online, active: isActive(routingIdentifiers.ACCOUNT) }" class="main-menu-item">
      <AccountIcon class="main-menu-item__icon"></AccountIcon>
      <div class="main-menu-item__name">{{ $t("MainMenuProfileSettings") }}</div>
    </router-link>
    <router-link :to="{ name: routingIdentifiers.HELP }"
                 :class="{ disabled: !online, active: isActive(routingIdentifiers.HELP) }" class="main-menu-item">
      <HelpIcon class="main-menu-item__icon"></HelpIcon>
      <div class="main-menu-item__name">{{ $t("MainMenuHelp") }}</div>
    </router-link>
  </div>
</template>

<script lang="ts">
import {Component, Vue, Watch} from "vue-property-decorator";
import {RoutingIdentifier} from "@/router";
import AppUserModel from '../../project/user/_model/AppUserModel';
import AAProfilePicture from "@/_view/components/AAProfilePicture.vue";
import SyncStatusWidget from "@/sync/_view/SyncStatusWidget.vue";
import SyncInternetWidget from "@/sync/_view/SyncInternetWidget.vue";
import App from "@/_view/App.vue";
import {RightAction, RIGHTS} from "@/team/_model/role.constants";
import {ENTITY_CONFIG} from "@/entity/_model/entity.constants";
import {Route} from "vue-router";
import NetworkManager from "@/_controller/NetworkManager";
import ContentIcon from "@/_view/menu/icons/ContentIcon.vue";
import HelpIcon from "@/_view/menu/icons/HelpIcon.vue";
import InsightsIcon from "@/_view/menu/icons/InsightsIcon.vue";
import AudiencesIcon from "@/_view/menu/icons/AudiencesIcon.vue";
import LibraryIcon from "@/_view/menu/icons/LibraryIcon.vue";
import TeamIcon from "@/_view/menu/icons/TeamIcon.vue";
import AccountIcon from "@/_view/menu/icons/AccountIcon.vue";

@Component({
    components: {AccountIcon, TeamIcon, LibraryIcon, AudiencesIcon, InsightsIcon, HelpIcon, ContentIcon}
})
export default class MainMenu extends Vue {

    private routingIdentifiers = RoutingIdentifier;
    public appUser: AppUserModel = AppUserModel.getInstance();
    // @ts-ignore
    public currentRoute: Route = {};
    public networkManager: NetworkManager = NetworkManager.getInstance();

    get online() {
        return this.networkManager.online;
    }

    get showAnalytics(): boolean {
        return AppUserModel.getInstance().rights.indexOf(RIGHTS.VIEW_PROJECT_ANALYTICS.identifier) >= 0 ||
          (AppUserModel.getInstance().rights.indexOf(RIGHTS.VIEW_TEAM_ANALYTICS.identifier) >= 0 && AppUserModel.getInstance().project.tierConfig.hasUsageInsights) ||
          (AppUserModel.getInstance().rights.indexOf(RIGHTS.VIEW_USER_ANALYTICS.identifier) >= 0 && AppUserModel.getInstance().project.tierConfig.hasUsageInsights) ||
          (AppUserModel.getInstance().rights.indexOf(RIGHTS.VIEW_CONTENT_ANALYTICS.identifier) >= 0 && AppUserModel.getInstance().project.tierConfig.hasContentInsights);
    }

    get showTeam(): boolean {
        return AppUserModel.getInstance().rights.indexOf(RIGHTS.VIEW_USERS.identifier) >= 0;
    }

    @Watch('$route', {immediate: true, deep: true})
    onUrlChange(route: Route) {
        this.currentRoute = route;
    }

    public isActive(routeIdentifier: string) {
        if (this.currentRoute) {
            return this.currentRoute.matched.some(route => route.name === routeIdentifier);
        }
        return false;
    }

}
</script>
