import LocalStorageManager from "@/__libs/offline_storage/LocalStorageManager";
import {LocalStorageKey} from "@/_model/app.constants";
import JsonUtil from "@/__libs/utility/JsonUtil";

class PwaController {

    public isPWAMode(): boolean {
        // @ts-ignore
        return window && (window.matchMedia('(display-mode: standalone)').matches || (window.navigator && window.navigator.standAlone === true) || (window.navigator && window.navigator.standalone === true));
    }

    public isPWAInstalled(): boolean | null {
        const {PWA_INSTALLED} = LocalStorageKey;
        return JsonUtil.parse(LocalStorageManager.retrieveValue(PWA_INSTALLED) as string);
    }

    public onPWAInstalled() {
        LocalStorageManager.storeValue(LocalStorageKey.PWA_INSTALLED, JsonUtil.stringify(true));
    }

}

//Singleton export
export default new PwaController();
