import Dispatcher from '../utility/Dispatcher';

class ToastManager extends Dispatcher
{

    public showToast(text:string, type:ToastType = ToastType.PRIMARY):void
    {
        this.dispatch(ToastEventType.SHOW, {text, type});
    }

}
export default new ToastManager();

export enum ToastEventType
{
    SHOW = "show-toast",
}

export enum ToastType
{
    PRIMARY = "primary",
    SECONDARY = "secondary",
    SUCCESS = "success",
    DANGER = "danger",
}
