<template>

    <div class="share-with-teams">

        <div class="share-with-teams__wrapper">
            <div class="aa-container-fluid h-100">
                <div class="row h-100">

                    <div class="col-5 h-100">
                        <div class="box h-100">

                            <div class="left-column">
                                <ShareWithTeamsItemRenderer
                                    v-for="team in shareableTeams"
                                    :teamOutline="team"
                                    :key="team.teamID"
                                    :selState="selState"
                                    v-on:onClick="_onTeamClick"
                                />
                            </div>

                        </div>
                    </div>

                    <div class="col-7 h-100">
                        <div class="box h-100">

                            <transition name="fade">
                                <div v-if="selState.selected">

                                    <transition name="fade">
                                        <div v-if="activeShare">
                                            <h5>{{ $t('ShareWithTeamsWhat') }}</h5>

                                            <b-form-checkbox v-for="rightAction in shareWithTeamAllowActions" :key="rightAction"
                                                             :value="rightAction" v-model="activeShare.rightActions"
                                                             @change="_onShareChange" class="mb-1">
                                                {{ $t(`ShareWithTeamsWhat_${rightAction}`) }}
                                            </b-form-checkbox>

                                            <div v-if="showQuickLaunch || showPriority">
                                                <hr>
                                                <h5>{{ $t('ShareWithTeamsHow') }}</h5>
                                                <div v-if="showQuickLaunch">
                                                    <b-form-checkbox v-model="activeShare.isQuickLaunch"
                                                                     @change="_onShareChange" class="mb-2">
                                                        {{ $t('ShareWithTeamsQuicklaunch') }}
                                                    </b-form-checkbox>
                                                </div>
                                                <div v-if="showPriority" class="d-flex align-items-center justify-content-center">
                                                    <label for="priority" class="mr-3 mb-0">{{ $t('ShareWithTeamsPriority') }}:</label>
                                                    <b-form-input id="priority" v-model="activeShare.priority" type="range" min="0" max="5" step="1" @change="_onShareChange"></b-form-input>
                                                </div>
                                            </div>

                                            <hr>

                                            <button class="btn btn-outline-secondary" @click="_onStopSharingBtnClick">
                                                {{ $t('ShareWithTeamsStopSharing') }}
                                            </button>
                                        </div>
                                    </transition>

                                    <transition name="fade">
                                        <div v-if="!activeShare">
                                            <button class="btn btn-outline-secondary" @click="_onStartSharingBtnClick">
                                                {{ $t('ShareWithTeamsStartSharing') }}
                                            </button>
                                        </div>
                                    </transition>

                                </div>
                            </transition>

                            <transition name="fade">
                                <div v-if="!selState.selected" class="placeholder-text text-secondary d-flex align-items-center justify-content-center h-100">
                                    {{ $t('ShareWithTeamsPleaseSelect') }}
                                </div>
                            </transition>

                        </div>
                    </div>

                </div>
            </div>
        </div>

        <div class="d-flex justify-content-end mt-3">
            <b-button variant="secondary" @click="_onCloseBtnClick" tabindex="5">
                {{ $t('Close') }}
            </b-button>
        </div>

    </div>

</template>

<script lang="ts">

    import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
    import EntityModel from "@/entity/_model/EntityModel";
    import AppUserModel from "@/project/user/_model/AppUserModel";
    import {ITeamOutlineDto} from "@/team/_model/team.dto";
    import ShareWithTeamsItemRenderer from "@/entity/_view/ShareWithTeamsItemRenderer.vue";
    import SelectionState from "@/__libs/_model/SelectionState";
    import {IEntityTeamShareDto} from "@/entity/_model/entity.dto";
    import EntityControllerFactory from "@/entity/_controller/EntityControllerFactory";
    import {RightAction, RIGHTS} from "@/team/_model/role.constants";
    import {ENTITY_CONFIG} from "@/entity/_model/entity.constants";
    import Util from "@/__libs/utility/Util";



    @Component({
        components: {ShareWithTeamsItemRenderer}
    })
    export default class ShareWithTeamsBox extends Vue
    {
        //---------------------------------
        // Vue Component props
        //---------------------------------

        @Prop() public entity!:EntityModel;

        //---------------------------------
        // Vue Component data
        //---------------------------------

        private selState:SelectionState<ITeamOutlineDto> = new SelectionState<ITeamOutlineDto>();

        private shareableTeams:ITeamOutlineDto[] = AppUserModel.getInstance().shareableTeams;

        private activeShare:IEntityTeamShareDto | null = null;

        //---------------------------------
        // Vue Computed properties
        //---------------------------------

      get shareWithTeamAllowActions():RightAction[] {
        return ENTITY_CONFIG[this.entity.entityType].shareWithTeamAllowActions;
      }

      get shareWithTeamDefaults():RightAction[] {
        return ENTITY_CONFIG[this.entity.entityType].shareWithTeamDefaults;
      }

        get showQuickLaunch():boolean
        {
            return AppUserModel.getInstance().rights.indexOf(RIGHTS.FLAG_AS_QUICKLAUNCH.identifier) >= 0 &&
                ENTITY_CONFIG[this.entity.entityType].canBePresented && AppUserModel.getInstance().project.tierConfig.hasQuickLaunch;
        }

        get showPriority():boolean
        {
            return AppUserModel.getInstance().rights.indexOf(RIGHTS.SET_PRIORITY.identifier) >= 0 &&
                ENTITY_CONFIG[this.entity.entityType].canBePresented;
        }

        //---------------------------------
        // Public / lifecycle methods
        //---------------------------------

        mounted()
        {
        }

        //---------------------------------
        // Private / helper methods
        //---------------------------------

        private _onTeamClick(p_team:ITeamOutlineDto, p_e:Event)
        {
            this._selectTeam(p_team);
        }

        private async _onShareChange(p_e:any)
        {
            if (this.activeShare)
            {
                await Util.timeOut(1); //todo ugly hack, v-model hasn't changed yet, ask @tomA
                EntityControllerFactory.getController(this.entity.entityType).updateShareWithTeam(this.entity, this.activeShare);
            }
        }

        private _selectTeam(p_team:ITeamOutlineDto)
        {
            this.selState.selected = p_team;

            for (let i = 0; i < this.entity.sharedWithTeams.length; i++)
            {
                if (this.entity.sharedWithTeams[i].teamID === p_team.teamID)
                {
                    this.activeShare = this.entity.sharedWithTeams[i];
                    return;
                }
            }
            this.activeShare = null;
        }

        private _onStartSharingBtnClick(p_e:Event)
        {
            const shareDto:IEntityTeamShareDto = {
                teamID       : this.selState.selected!.teamID,
                rightActions : [...this.shareWithTeamDefaults],
                priority     : 0,
                isQuickLaunch: false
            };
            EntityControllerFactory.getController(this.entity.entityType).startSharingWithTeam(this.entity, shareDto);
            this.activeShare = shareDto;
        }

        private async _onStopSharingBtnClick(p_e:Event)
        {
            if (this.activeShare)
            {
                const deleteConfirmed:boolean = await this.$bvModal.msgBoxConfirm(this.$t('DashboardShowStopSharingConfirmationMessage') as string, {centered: true});

                if (deleteConfirmed)
                {
                    EntityControllerFactory.getController(this.entity.entityType).stopSharingWithTeam(this.entity, this.activeShare);
                    this.activeShare = null;
                }
            }
        }

        private async _onCloseBtnClick(p_e:Event)
        {
            this.$emit('onFinish', null);
        }

    }
</script>
