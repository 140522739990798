import {
    IAnalyticsActionCfgDto,
    IAnalyticsPresentationEvolutionDto,
    IAnalyticsTopTeamDto,
    IAnalyticsTopUsageDto,
    IAnalyticsUserEvolutionDto,
    IContentTypeAnalyticsDto,
    IOldTeamAnalyticsDto,
    ITeamAnalyticsDto, IUserAnalyticsDto
} from "@/analytics/_model/analytics.dto";
import {ANALYTICS_DEFAULT_RANGE, AnalyticsTargetType} from "@/analytics/_model/analytics.constants";
import Model from "@/__libs/_model/Model";
import SelectionState from "@/__libs/_model/SelectionState";
import i18n from "@/__plugins/i18n";
import languageManager, {IMultiLangString} from "@/__libs/language_manager/LanguageManager";
import AppUserModel from "@/project/user/_model/AppUserModel";
import ListModel from "@/__libs/_model/ListModel";
import TeamModel from "@/team/_model/TeamModel";
import TeamUserModel from "@/team/_model/TeamUserModel";

export default class AnalyticsModel extends Model implements IOldTeamAnalyticsDto
{

    public range:Date[] = [new Date(new Date().getTime() - (1000 * 60 * 60 * 24 * ANALYTICS_DEFAULT_RANGE)), new Date()];

    public totalUsers:number = 0;
    public totalTeams:number = 0;
    public totalMarkets:number = 0;
    public userEvolution:IAnalyticsUserEvolutionDto = {
        dates  : [],
        users  : [],
        teams  : [],
        markets: []
    };
    public topTeams:IAnalyticsTopTeamDto[] = [];
    public presentationEvolution:IAnalyticsPresentationEvolutionDto = {
        dates    : [],
        presented: [],
        shared   : [],
        viewed   : []
    };
    public topUsage:IAnalyticsTopUsageDto[] = [];

    //::::::::: content
    public subjectTypeSelState:SelectionState<IContentTypeAnalyticsDto> = new SelectionState<IContentTypeAnalyticsDto>();

    public activeTargetType:AnalyticsTargetType = AnalyticsTargetType.MYSELF;

    //::::::::: teams
    public allTeamsAnalytics!:ITeamAnalyticsDto;
    public activeTeamAnalytics:ITeamAnalyticsDto = {} as ITeamAnalyticsDto;

    //the teams in the left pane of the teams page
    public teamListing:ListModel<TeamModel> = new ListModel<TeamModel>();

    //::::::::: users
    public allUsersAnalytics!:IUserAnalyticsDto;
    public activeUserAnalytics:IUserAnalyticsDto = {} as IUserAnalyticsDto;

    //the users in the left pane of the users page
    public userListing:ListModel<TeamUserModel> = new ListModel<TeamUserModel>();


    //---------------------------------
    // Singleton Constructor
    //---------------------------------

    private static _instance:AnalyticsModel;

    constructor()
    {
        super();
        if (!AnalyticsModel._instance)
        {
            AnalyticsModel._instance = this;
        }
    }

    public static getInstance():AnalyticsModel
    {
        if (!AnalyticsModel._instance)
        {
            new AnalyticsModel();
        }

        return AnalyticsModel._instance;
    }


    public static resolveLabelTranslation(p_trans:string | IMultiLangString)
    {
        if (p_trans)
        {
            if (typeof p_trans === 'string')
            {
                return i18n.tc(p_trans);
            }
            else
            {
                return languageManager.getTranslationForValue<string>(p_trans as IMultiLangString, AppUserModel.getInstance().langCode)
            }
        }
        else
        {
            return "";
        }
    }

    public static resolveNameTranslation(p_trans:string | IMultiLangString)
    {
        if (p_trans)
        {
            if (typeof p_trans === 'string')
            {
                return p_trans;
            }
            else
            {
                return languageManager.getTranslationForValue<string>(p_trans as IMultiLangString, AppUserModel.getInstance().langCode)
            }
        }
        else
        {
            return "";
        }
    }

}
