<template>
  <div class="data-provider-editor">

    <transition name="fade">
      <div class="h-100 w-100 d-flex flex-column">

        <!-- THE CONTENT -->
        <div v-if="filterValues.length > 0" class="data-provider-editor__content data-provider-editor__content--single">
          <DataFilterItem
            v-for="fv in filterValues"
            :key="fv.propertyDefinition.identifier"
            :filter-value="fv"
            :filter-model="filterModel"
          />
        </div>

        <div class="d-flex flex-column">
          <b-button
            block
            variant="primary"
            class="list-overview-button mb-1 mt-3"
            @click="onApplyFilters"
          >
            {{ $t('AudienceApplyPropertyFilters') }}
          </b-button>

          <b-button
            block
            variant="secondary"
            class="list-overview-button mb-1 mt-1"
            @click="onResetFilters"
          >
            {{ $t('AudienceResetPropertyFilters') }}
          </b-button>
        </div>
      </div>
    </transition>

  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from "vue-property-decorator";
import Hint from "@/help/hint/_view/Hint.vue";
import DataFilterItem from '@/data_tool/data_filter/_view/DataFilterItem.vue';
import {IDataFilter} from '@/data_tool/data_filter/_model/data-filter.dto';
import DataFilterModel from '@/data_tool/data_filter/_model/DataFilterModel';

@Component({
  components: {DataFilterItem, Hint}
})
export default class DataFilter extends Vue {
  @Prop({ required: true }) public readonly filterModel!: DataFilterModel;

  public get filterValues(): IDataFilter[] {
    return this.filterModel.filterValues;
  }

  //---------------------------------
  // Public / lifecycle methods
  //---------------------------------
  public async onApplyFilters() {
    this.$emit('apply-filters');
  }

  public async onResetFilters() {
    this.$emit('reset-filters');
  }
}
</script>
