<template>
    <div class="data-item-select-list">



        <transition name="fade">
            <div v-if="currentState === externalListState.LOADING"
                 class="loading d-flex justify-content-center align-items-center">
                <b-spinner variant="dark"></b-spinner>
            </div>
        </transition>

        <transition name="fade">
            <div v-if="currentState === externalListState.ERROR_LOADING"
                 class="placeholder-text text-danger">
                {{ $t('ErrorConnectionRequired') }}
            </div>
        </transition>

        <transition name="fade">

            <div v-if="currentState === externalListState.LOADED">

                <select v-if="property.propertyDefinition.formElement === componentType.COMBOBOX"
                        class="form-control mb-3"
                        v-model="externalList.selection" :disabled="!property.isEditable">
                    <option v-for="item in externalList.externalItemList" :key="item.__id"
                            :value="item.__id">{{ item.name }}
                    </option>
                </select>

                <div v-if="property.propertyDefinition.formElement === componentType.CHECKBOX_GROUP" class="mb-3">
                  <div v-for="item in externalList.externalItemList" :key="item.__id">
                    <label class="d-inline-flex align-items-center">
                        <input type="checkbox"
                               :value="item.__id" :disabled="!property.isEditable"
                               v-model="externalList.selection"/>
                        <AAImage v-if="getPosterUrl(item)" :imageUrl="getPosterUrl(item)" class="list-image"/>
                        <span>{{ item.name }}</span>
                    </label>
                  </div>
                </div>

                <select v-if="property.propertyDefinition.formElement === componentType.LISTBOX"
                        class="form-control mb-3"
                        multiple
                        v-model="externalList.selection" :disabled="!property.isEditable">
                    <option v-for="item in externalList.externalItemList" :key="item.__id"
                            :value="item.__id">{{ item.name }}
                    </option>
                </select>


                <div v-if="property.propertyDefinition.formElement === componentType.RADIOBUTTON_GROUP">
                    <b-form-radio v-for="item in externalList.externalItemList" :key="item.__id"
                                  v-model="externalList.selection" :name="property.propertyDefinition.identifier"
                                  :value="item.__id">
                        <img v-if="getPosterUrl(item)" :src="getPosterUrl(item)">
                        {{ item.name }}
                    </b-form-radio>
                </div>


            </div>

        </transition>

    </div>
</template>


<script lang="ts">
    import {Component, Prop, Vue, Watch} from "vue-property-decorator";
    import {FormElement} from "@/data_tool/_model/data_tool.constants";
    import DataItemExternalListModel from "@/data_tool/data_item/_model/DataItemExternalListModel";
    import dataProviderController from "@/data_tool/data_provider/_controller/DataProviderController";
    import PropertyModel from "@/data_tool/data_item/_model/PropertyModel";
    import DataItemModel from "@/data_tool/data_item/_model/DataItemModel";
    import languageManager, {IMultiLangString} from "@/__libs/language_manager/LanguageManager";
    import fileManager from "@/_controller/FileManager";
    import AAImage from "@/_view/components/AAImage.vue";


    enum ExternalListState
    {
        IDLE,
        LOADING,
        ERROR_LOADING,
        LOADED
    }

    @Component({
        components: {AAImage}
    })
    export default class DataItemExternalList extends Vue
    {
        //---------------------------------
        // Vue Component props
        //---------------------------------

        @Prop() private property!:PropertyModel;

        @Prop() private externalList!:DataItemExternalListModel;


        //---------------------------------
        // Vue Component data
        //---------------------------------

        private componentType:typeof FormElement = FormElement;

        private externalListState:typeof ExternalListState = ExternalListState;
        private currentState:ExternalListState = ExternalListState.IDLE;


        //---------------------------------
        // Vue Computed properties
        //---------------------------------


        //---------------------------------
        // Public / lifecycle methods
        //---------------------------------



        public async created()
        {
            // console.log(this.property.propertyDefinition.identifier, this.property.propertyDefinition);
        }

        public async mounted()
        {

        }



        //---------------------------------
        // Private / helper methods
        //---------------------------------


        //this is the actual entrypoint of this component, is called after mount and whenever another property is set
        @Watch('property', {immediate: true, deep: false})
        private async _onActivePropertyChange(newProperty:PropertyModel | null, oldProperty:PropertyModel | null)
        {

            await this.loadExternalList(this.property.propertyDefinition.externalListEndpoint!);

        }


        private async loadExternalList(p_endpoint:string)
        {
            this.currentState = ExternalListState.LOADING;
            const listLoaded:boolean = await dataProviderController.fetchExternalList(p_endpoint, this.externalList);
            if (listLoaded)
            {
                this.currentState = ExternalListState.LOADED;
            }
            else
            {
                this.currentState = ExternalListState.ERROR_LOADING;
            }
        }

        private getPosterUrl(p_dataItem:DataItemModel):string | null
        {
            if (p_dataItem.posterProp)
            {
                let posterURI:string;
                if (p_dataItem.posterProp.propertyDefinition.isMultiLingual)
                {
                    posterURI = languageManager.getTranslationForValue<string>(p_dataItem.posterProp.rawValue as IMultiLangString, p_dataItem.dataProvider.activeLangCode);
                }
                else
                {
                    posterURI = p_dataItem.posterProp.rawValue;
                }
                if (posterURI)
                {
                    return fileManager.getFileUrl(posterURI);
                }
            }
            return null;
        }

    }
</script>
