<template>

    <div>


        <b-form-radio v-for="dtp in this.dataProviderList" :key="dtp.ID"
                      v-model="selectedDataProviderID" name="dtps"
                      :value="dtp.ID">
            {{ dataProviderName(dtp) }}
        </b-form-radio>


        <div class="d-flex justify-content-end mt-4">
            <b-button variant="secondary" class="mr-2" @click="_onCancelBtnClick">
                {{ $t('Cancel') }}
            </b-button>
            <b-button type="submit" variant="primary" :disabled="!selectedDataProviderID" @click="_onSelectDtpBtnClick">
                {{ $t('Ok') }}
            </b-button>
        </div>


    </div>

</template>

<script lang="ts">
    import {Component, Prop, Vue} from 'vue-property-decorator';
    import DataProviderListModel from "@/data_tool/data_provider/_model/DataProviderListModel";
    import {IDataProviderOutlineDto} from "@/data_tool/_model/data_tool.dto";
    import DataUtil from "@/data_tool/_controller/DataUtil";
    import languageManager from "@/__libs/language_manager/LanguageManager";
    import AppUserModel from "@/project/user/_model/AppUserModel";



    @Component({})
    export default class SelectDataProviderBox extends Vue
    {



        //---------------------------------
        // Validations
        //---------------------------------

        //---------------------------------
        // Vue Component props
        //---------------------------------

        @Prop() private itemDefinitionURI!:string;

        @Prop() private dataProviderURI!:string;

        //---------------------------------
        // Vue Component data
        //---------------------------------

        private selectedDataProviderID:string | null = null;


        //---------------------------------
        // Vue Computed properties
        //---------------------------------

        get dataProviderList():IDataProviderOutlineDto[]
        {
            return  DataProviderListModel.getInstance().getDataProvidersBySchemaURI(this.itemDefinitionURI);
        }

        //---------------------------------
        // Public / lifecycle methods
        //---------------------------------

        mounted()
        {
            if (this.dataProviderURI)
            {
                const uri = DataUtil.resolveURI(this.dataProviderURI);
                this.selectedDataProviderID = uri.path;
            }
        }

        //---------------------------------
        // Private / helper methods
        //---------------------------------

        private dataProviderName(p_dtp:IDataProviderOutlineDto)
        {
            return languageManager.getTranslationForValue<string>(p_dtp.name, AppUserModel.getInstance().langCode)
        }

        private async _onSelectDtpBtnClick(p_e:Event)
        {
            const uri:string = `dataProvider://${this.selectedDataProviderID}`;
            this.$emit('onFinish', uri);
        }

        private async _onCancelBtnClick(p_e:Event)
        {
            this.$emit('onFinish', null);
        }

    }
</script>
