<template>
  <div class="data-provider-editor">

    <transition name="fade">
      <div v-if="currentState === dataProviderEditorState.LOADING"
           class="loading d-flex justify-content-center align-items-center w-100 h-100">
        <b-spinner variant="dark"></b-spinner>
      </div>
    </transition>

    <transition name="fade">
      <div v-if="currentState === dataProviderEditorState.ERROR_LOADING"
           class="placeholder-text text-danger d-flex justify-content-center align-items-center w-100 h-100">
        {{ $t('ErrorConnectionRequired') }}
      </div>
    </transition>

    <transition name="fade">
      <div v-if="currentState === dataProviderEditorState.LOADED" class="h-100 w-100 data-provider-editor__wrapper" :class="{ 'stand-alone': isStandalone }">

        <!-- HEADER -->
        <div v-if="!isStandalone" class="data-provider-editor__header">
          <div class="data-provider-editor__header__title">
            <b-form-group v-if="dataProvider.isEditable" class="mb-0">
              <b-form-input v-model="validationDataProviderName"
                            :placeholder="$t('Name')"
                            size="lg"
                            :state="calculateInputState($v.validationDataProviderName)"
                            @input="dataProviderName = onFormValidationInput($v.validationDataProviderName, dataProviderName)"
                            tabindex="1"></b-form-input>
              <b-form-invalid-feedback>
                <span class="d-block" v-if="!$v.validationDataProviderName.required">{{
                    $t('FieldRequiredSimple')
                  }}</span>
                <span class="d-block"
                      v-if="!$v.validationDataProviderName.minLength">{{ $t('MinimumFieldLengthSimple', [3]) }}</span>
              </b-form-invalid-feedback>
            </b-form-group>
            <h2 v-if="!dataProvider.isEditable" class="aa-title mb-0" v-line-clamp="1">{{
                dataProviderName
              }}</h2>
          </div>
          <div class="data-provider-editor__header__meta">

            <b-button class="mr-3 text-nowrap" variant="success" block v-if="isExploreOrExplorePlus" target="_blank"
                      :href="youtubeLink">{{ $t('HowToSetupApp') }}
            </b-button>

            <select class="form-control"
                    v-model="dataProvider.activeLangCode" :id="hintIds.DATA2_TRANSLATION">
              <option v-for="langCode in availableLanguages" :key="langCode"
                      :value="langCode">{{ langCode }}
              </option>
            </select>

            <b-popover custom-class="hint-popover" :target="hintIds.DATA2_TRANSLATION"
                       placement="left"
                       boundary="window"
                       triggers="manual">
              <Hint :id="hintIds.DATA2_TRANSLATION"></Hint>
            </b-popover>

            <template v-if="showImportExport">
              <button class="btn btn-primary mr-2 ml-3"
                      @click="exportDataProvider"> {{ $t('ExportToXls') }}
              </button>
              <label class="btn btn-primary mb-0" v-if="dataProvider.isEditable">
                {{ $t('ImportFromXls') }} <input type="file" ref="importFileBtn" accept=".xls,.xlsx"
                                                 v-on:change="importDataProvider()" hidden>
              </label>
            </template>

            <!--                        <button class="btn btn-primary" v-if="dataProvider.isEditable"-->
            <!--                                @click="_saveDataProvider" :disabled="!dataProvider.hasChanges"> {{ $t('Save') }}-->
            <!--                        </button>-->
          </div>
        </div>

        <!-- THE CONTENT -->
        <div v-if="availableDtpEntries.length === 1"
             class="data-provider-editor__content data-provider-editor__content--single">
          <DataProviderEntry :dataProviderEntry="availableDtpEntries[0]"/>
        </div>

        <div v-if="availableDtpEntries.length > 1"
             class="data-provider-editor__content data-provider-editor__content--multiple">
          <b-tabs pills>
            <b-tab v-for="dtpEntry in availableDtpEntries"
                   :key="dtpEntry.itemDefinition.identifier"
                   lazy
            size="small">
              <template #title>
                <span :class="{ 'text-danger': !dtpEntry.isValid }">{{ getEntryName(dtpEntry) }}</span>
                <b-badge v-if="!dtpEntry.isValid" variant="danger">{{
                    dtpEntry.getNrOfInvalidChildren()
                  }}
                </b-badge>
              </template>
              <DataProviderEntry :dataProviderEntry="dtpEntry" :showTitle="false"/>
            </b-tab>
          </b-tabs>
        </div>

      </div>
    </transition>

  </div>
</template>


<script lang="ts">
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import DataProviderModel from "@/data_tool/data_provider/_model/DataProviderModel";
import dataProviderController from "@/data_tool/data_provider/_controller/DataProviderController";
import DataProviderEntry from "@/data_tool/data_provider/_view/DataProviderEntry.vue";
import languageManager, {IMultiLangString} from "@/__libs/language_manager/LanguageManager";
import DataProviderEntryModel from "@/data_tool/data_provider/_model/DataProviderEntryModel";
import {DataProviderEntryViewStyle} from "@/data_tool/_model/data_tool.constants";
import AppUserModel from "@/project/user/_model/AppUserModel";
import {minLength, required} from "vuelidate/lib/validators";
import {RightAction, RIGHTS} from "@/team/_model/role.constants";
import {TierType} from "@/project/_model/project.constants";
import {ExternalUrls} from "@/_model/app.constants";
import Hint from "@/help/hint/_view/Hint.vue";
import {HintGroupIds, HintIds} from "@/help/hint/_model/hint.constants";
import HintListController from "@/help/hint/_controller/HintListController";


enum DataProviderEditorState {
    IDLE,
    LOADING,
    ERROR_LOADING,
    LOADED
}


@Component({
    components: {Hint, DataProviderEntry},
    validations: {
        validationDataProviderName: {
            required,
            minLength: minLength(3)
        }
    }
})
export default class DataProviderEditor extends Vue {
    @Prop() public readonly dataProvider!: DataProviderModel;
    @Prop() public isStandalone!: boolean;

    public hintIds: typeof HintIds = HintIds;

    public readonly dataProviderEditorState: typeof DataProviderEditorState = DataProviderEditorState;
    public currentState: DataProviderEditorState = DataProviderEditorState.IDLE;

    public validationDataProviderName: string = '';
    //---------------------------------
    // Vue Computed properties
    //---------------------------------

    get youtubeLink(): string {
        if (AppUserModel.getInstance().project.tierConfig.tierType === TierType.EXPLORE) {
            return ExternalUrls.YOUTUBE_CHANNEL_EXPLORE;
        } else {
            return ExternalUrls.YOUTUBE_CHANNEL_EXPLORE_PLUS;
        }
    }

    get isExploreOrExplorePlus(): boolean {
        return AppUserModel.getInstance().project.tierConfig.tierType === TierType.EXPLORE || AppUserModel.getInstance().project.tierConfig.tierType === TierType.EXPLORE_PLUS;
    }

    get availableLanguages(): string[] {
        return languageManager.availableLangCodes;
    }

    get dataProviderName() {
        return languageManager.getTranslationForValue<string>(this.dataProvider!.name, AppUserModel.getInstance().langCode)
    }

    set dataProviderName(value: string) {
        this.dataProvider!.name[AppUserModel.getInstance().langCode] = value;
    }

    get showImportExport(): boolean {
        return AppUserModel.getInstance().rights.indexOf(RIGHTS.EXPORT_IMPORT_DATAPROVIDERS.identifier) >= 0;
    }

    get availableDtpEntries(): DataProviderEntryModel[] {
        if (AppUserModel.getInstance().rights.indexOf(RIGHTS.HAS_DEVELOPER_ACCESS.identifier) >= 0) {
            return this.dataProvider.dtpEntries;
        } else {
            return this.dataProvider.dtpEntries.filter(p_dtpEntry => p_dtpEntry.itemDefinition.onlyForDevelopers !== true);
        }
    }

    //---------------------------------
    // Public / lifecycle methods
    //---------------------------------
    public created() {
        window.addEventListener('keydown', this.onKeyDown);
    }

    public destroyed() {
        window.removeEventListener('keydown', this.onKeyDown);
    }

    public onKeyDown(e: KeyboardEvent) {
        if (e.ctrlKey && e.key === 's')  // todo @TomA check on Mac
        {
            e.preventDefault();
            if (this.dataProvider.hasChanges) {
                this._saveDataProvider()
            }
        }
    }

    public getEntryName(p_entry: DataProviderEntryModel) {
        return languageManager.getTranslationForValue<string>(p_entry.itemDefinition.name as IMultiLangString, this.dataProvider.activeLangCode);
    }

    //---------------------------------
    // Private / helper methods
    //---------------------------------

    @Watch('dataProvider', {immediate: true, deep: false})
    private async _onActiveDataProviderChange(newDp: DataProviderModel | null, oldDp: DataProviderModel | null) {
        if (this.dataProvider) {
            this.validationDataProviderName = this.dataProviderName;

            this.currentState = DataProviderEditorState.LOADING;
            const isBuilt: boolean = await dataProviderController.fetchDataProviderDependencies(this.dataProvider);
            if (isBuilt) {
                this.currentState = DataProviderEditorState.LOADED;
                //
                HintListController.startGroup(HintGroupIds.DATA2);
            } else {
                this.currentState = DataProviderEditorState.ERROR_LOADING;
            }
        }

    }

    public calculateInputState(inputValidator: any): any {
        return inputValidator.$invalid ? false : null;
    }

    public onFormValidationInput(inputValidator: any, value: any): any {
        if (!inputValidator.$invalid && this.dataProviderName) {
            value = inputValidator.$model;
            this._onFormInput();
        }
        return value;
    }

    private _onFormInput() {
        this.dataProvider!.hasChanges = true;
    }


    private async _saveDataProvider() {
        await dataProviderController.saveBody(this.dataProvider);
    }


    public async exportDataProvider() {
        await dataProviderController.exportToXls(this.dataProvider);
    }


    public async importDataProvider() {
        const importFileBtn: HTMLInputElement = this.$refs.importFileBtn as HTMLInputElement;
        if (importFileBtn.files && importFileBtn.files.length === 1) {
            const result = await dataProviderController.importFromXls(importFileBtn.files[0], this.dataProvider);
            let msg: string;
            if (result.isSuccessful) {
                msg = "Import successful";
            } else {
                msg = "Import failed: " + result.fatalMessage!.type;
            }
            await this.$bvModal.msgBoxOk(msg, {centered: true});
        }
    }

}
</script>
