<template>
  <div class="playlist-item" tabindex="0"
       :class="{ dropAttract: showDropAttract, noDropAllowed: showNoDropAllowed, dropFinished: dropFinished }" @dragover="_onDragOver"
       @dragenter="_onDragEnter" @dragleave="_onDragLeave" @drop="_onDrop" :id="`playlist-${playlistID}`" @click="_onClick">

    <div class="playlist-item__item-2">
      <AAImage :image-url="item2PosterUrl" v-if="item2PosterUrl"></AAImage>
    </div>

    <div class="playlist-item__item-1">
      <AAImage :image-url="item1PosterUrl" v-if="item1PosterUrl"></AAImage>
    </div>

    <div class="playlist-item__poster">
      <AAImage :image-url="posterUrl"></AAImage>
      <div class="playlist-item__poster__content">
        <div class="playlist-item__poster__content__name" v-line-clamp="2">{{ playlistName }}</div>
      </div>
    </div>

    <div class="playlist-item__dots" @click="_onClickDotsBtn" v-if="online">
      <i class="fas fa-ellipsis-v"></i>
    </div>

    <b-popover :target="`playlist-${playlistID}`" triggers="click blur" placement="right"
               boundary="window">
      <PlaylistActionBox :playlist="playlist"></PlaylistActionBox>
    </b-popover>

  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from "vue-property-decorator";
import ContentModel from "@/content/_model/ContentModel";
import {DeltaStatus, EntityType, LoadingStatus} from "@/entity/_model/entity.constants";
import fileManager from "@/_controller/FileManager";
import AudienceModel from "@/audience/_model/AudienceModel";
import AudienceListModel from "@/audience/_model/AudienceListModel";
import languageManager from "@/__libs/language_manager/LanguageManager";
import EntityActionBox from "@/entity/_view/EntityActionBox.vue";
import EntitySelectionState from "@/entity/_model/EntitySelectionState";
import AAImage from "@/_view/components/AAImage.vue";

import PlaylistModel from "@/playlist/_model/PlaylistModel";
import playlistController from "@/playlist/_controller/PlaylistController";
import PlaylistListModel from "@/playlist/_model/PlaylistListModel";
import contentController from "@/content/_controller/ContentController";
import {IEntityAudienceShareOutlineDto} from "@/entity/_model/entity.dto";
import {EventBus} from "@/__libs/vue/EventBus";
import {EventBusActions} from "@/_model/app.constants";
import MorePopup from "@/_view/components/MorePopup.vue";
import toastManager, {ToastType} from "@/__libs/toast_manager/ToastManager";
import i18n from "@/__plugins/i18n";
import PlaylistActionBox from "@/playlist/_view/PlaylistActionBox.vue";
import ContentOverviewSelectionState from "@/content/_model/ContentOverviewSelectionState";
import NetworkManager from "@/_controller/NetworkManager";
import {IPlaylistEntryDto} from "@/playlist/_model/playlist.dto";
import ContentFolderListModel from "@/content/_model/ContentFolderListModel";
import contentFolderController from "@/content/_controller/ContentFolderController";
import ContentListModel from "@/content/_model/ContentListModel";

@Component({
    components: {
        PlaylistActionBox,
        AAImage,
        EntityActionBox,
        MorePopup
    }
})
export default class PlaylistItemRenderer extends Vue {

    @Prop() public playlist!: PlaylistModel;
    @Prop() private selState!: ContentOverviewSelectionState;

    public networkManager: NetworkManager = NetworkManager.getInstance();
    public dragCount: number = 0;
    public isBeingDraggedOver: boolean = false;
    public dropFinished: boolean = false;

    get online() {
        return this.networkManager.online;
    }

    get playlistName() {
        return languageManager.getTranslationForValue(
          this.playlist.body.name,
          this.audienceLangCode
        );
    }

    get playlistID() {
        return this.playlist.playlistID;
    }

    get posterUrl() {
        const posterUri: string = languageManager.getTranslationForValue(
          this.playlist.body.poster,
          this.audienceLangCode
        );
        return fileManager.getFileUrl(posterUri);
    }

    get item1PosterUrl(): string | null {
        let posterUrl: string | null = null;
        if (this.playlist.entries.length > 0) {
            const content: ContentModel | null = ContentListModel.getInstance().getEntityByID(this.playlist.entries[0].contentID);
            if (content) {
                const posterUri: string = languageManager.getTranslationForValue(
                  content.poster,
                  this.audienceLangCode
                );
                posterUrl = fileManager.getFileUrl(posterUri);
            }
        }
        return posterUrl;
    }

    get item2PosterUrl(): string | null {
        let posterUrl: string | null = null;
        if (this.playlist.entries.length > 1) {
            const content: ContentModel | null = ContentListModel.getInstance().getEntityByID(this.playlist.entries[1].contentID);
            if (content) {
                const posterUri: string = languageManager.getTranslationForValue(
                  content.poster,
                  this.audienceLangCode
                );
                posterUrl = fileManager.getFileUrl(posterUri);
            }
        }
        return posterUrl;
    }

    get audienceLangCode(): string | null {
        const activeAudience: AudienceModel | null = AudienceListModel.getInstance()
          .globalSelState.selected;
        return activeAudience ? activeAudience.langCode : null;
    }

    get showNoDropAllowed() {
        if (ContentFolderListModel.getInstance().currentDragged instanceof ContentModel) {
            return ContentFolderListModel.getInstance().dragInProgress && !this.playlist.isEditable;
        }
        return false;
    }

    get showDropAttract() {
        return this.isBeingDraggedOver && !this.showNoDropAllowed;
    }

    public _onClick(p_e: Event) {
        p_e.stopPropagation();
        this.$emit("onClick", this.playlist);
    }

    public _onClickDotsBtn(p_e: Event) {
        p_e.stopPropagation();
        PlaylistListModel.getInstance().selectedPlaylist = this.playlist;
        this.$root.$emit('bv::show::popover', `playlist-${this.playlistID}`);
        this.$emit("onClickDots", this.playlist);
    }

    public mounted() {
    }

    // DRAGGING

    public _onDragStart() {
        // playlistController.startDrag(this.playlist);
    }

    public _onDragEnd() {
        // playlistController.endDrag();
    }

    public _onDragOver(p_event: DragEvent) {
        let isDroppable: boolean = false;
        let currentDragged = ContentFolderListModel.getInstance().currentDragged;
        if (currentDragged instanceof ContentModel) {
            isDroppable = this.playlist.isEditable;
        }
        if (isDroppable) {
            p_event.preventDefault();
            if (p_event.dataTransfer) {
                p_event.dataTransfer.dropEffect = "move";
            }
        }
    }

    public _onDragEnter(p_event: DragEvent) {
        this.dropFinished = false;
        this.dragCount++;
        this.isBeingDraggedOver = true;
    }

    public _onDragLeave(p_event: DragEvent) {
        this.dragCount--;
        if (this.dragCount == 0) {
            this.isBeingDraggedOver = false;
        }
    }

    public _onDrop() {
        let currentDragged =  ContentFolderListModel.getInstance().currentDragged;
        if (currentDragged instanceof ContentModel) {
            playlistController.addContentToPlaylist(this.playlist, currentDragged);
        }
        contentFolderController.endDrag();
        // reset drop count hack
        this.dragCount = 0;
        this.isBeingDraggedOver = false;
        // do animation
        this.dropFinished = true;
    }
}
</script>
