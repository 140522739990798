import EntityModel from "@/entity/_model/EntityModel";
import SelectionState from "@/__libs/_model/SelectionState";
import {DeltaStatus} from "@/entity/_model/entity.constants";

export default class EntitySelectionState<T extends EntityModel> extends SelectionState<T>
{


    protected _selected:T | null = null;
    get selected():T | null
    {
        return this._selected;
    }
    set selected(value:T | null)
    {
        this._previousSelected = this._selected;
        this._selected = value;
        if(this._selected && this._selected.deltaStatus > DeltaStatus.UNCHANGED)
        {
            this._selected.deltaStatus = DeltaStatus.UNCHANGED;
        }
    }




    //---------------------------------
    // Public Methods
    //---------------------------------


}
