export enum HintEvents {
    SHOW = "HINT::SHOW",
    HIDE = "HINT::HIDE",
}

export enum HintGroupIds {
    HOME = "HOME",
    USERS = "USERS",
    TEAMS = "TEAMS",
    COMPANIES = "COMPANIES",
    TEAMINSIGHTS = "TEAMINSIGHTS",
    USERINSIGHTS = "USERINSIGHTS",
    CONTENTINSIGHTS = "CONTENTINSIGHTS",
    FILES = "FILES",
    SLIDES1 = "SLIDES1",
    SLIDES2 = "SLIDES2",
    DATA1 = "DATA1",
    DATA2 = "DATA2",
}

export enum HintIds {
    HOME_CREATE_AUDIENCE = "home-create-audience",
    HOME_CREATE_CONTENT = "home-create-content",
    HOME_CONTENT_OVERVIEW = "home-content-overview",
    HOME_SHARED_CONTENT = "home-shared-content",
    HOME_TRACKING_DATA = "home-tracking-data",
    USERS_CREATE_USER = "users-create-user",
    TEAMS_CREATE_TEAM = "teams-create-team",
    COMPANIES_OVERVIEW_COMPANIES = "companies-overview-companies",
    TEAMINSIGHTS_TEAMS = "teaminsights-teams",
    TEAMINSIGHTS_USAGE = "teaminsights-usage",
    TEAMINSIGHTS_TIME = "teaminsights-time",
    USERINSIGHTS_USERS = "userinsights-users",
    CONTENTINSIGHTS_CONTENT = "contentinsights-content",
    FILES_DOCUMENTATION = "files-documentation",
    SLIDES1_LIBRARY = "slides1-library",
    SLIDES2_EDITING = "slides2-editing",
    DATA1_CREATE = "data1-create",
    DATA2_TRANSLATION = "data2-translation",
}

export const HINT_LIST = [
    {
        id: HintIds.HOME_CREATE_AUDIENCE,
        groupId: HintGroupIds.HOME,
    },
    {
        id: HintIds.HOME_CREATE_CONTENT,
        groupId: HintGroupIds.HOME,
    },
    {
        id: HintIds.HOME_CONTENT_OVERVIEW,
        groupId: HintGroupIds.HOME,
    },
    {
        id: HintIds.HOME_SHARED_CONTENT,
        groupId: HintGroupIds.HOME,
    },
    {
        id: HintIds.HOME_TRACKING_DATA,
        groupId: HintGroupIds.HOME,
    },
    {
        id: HintIds.USERS_CREATE_USER,
        groupId: HintGroupIds.USERS,
    },
    {
        id: HintIds.TEAMS_CREATE_TEAM,
        groupId: HintGroupIds.TEAMS,
    },
    {
        id: HintIds.COMPANIES_OVERVIEW_COMPANIES,
        groupId: HintGroupIds.COMPANIES,
    },
    {
        id: HintIds.TEAMINSIGHTS_TEAMS,
        groupId: HintGroupIds.TEAMINSIGHTS,
    },
    {
        id: HintIds.TEAMINSIGHTS_USAGE,
        groupId: HintGroupIds.TEAMINSIGHTS,
    },
    {
        id: HintIds.TEAMINSIGHTS_TIME,
        groupId: HintGroupIds.TEAMINSIGHTS,
    },
    {
        id: HintIds.USERINSIGHTS_USERS,
        groupId: HintGroupIds.USERINSIGHTS,
    },
    {
        id: HintIds.CONTENTINSIGHTS_CONTENT,
        groupId: HintGroupIds.CONTENTINSIGHTS,
    },
    {
        id: HintIds.FILES_DOCUMENTATION,
        groupId: HintGroupIds.FILES,
    },
    {
        id: HintIds.SLIDES1_LIBRARY,
        groupId: HintGroupIds.SLIDES1,
    },
    {
        id: HintIds.SLIDES2_EDITING,
        groupId: HintGroupIds.SLIDES2,
    },
    {
        id: HintIds.DATA1_CREATE,
        groupId: HintGroupIds.DATA1,
    },
    {
        id: HintIds.DATA2_TRANSLATION,
        groupId: HintGroupIds.DATA2,
    }
]

