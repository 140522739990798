import axios from 'axios';
import Dispatcher from "@/__libs/utility/Dispatcher";
import JsonUtil from "@/__libs/utility/JsonUtil";
import LocalStorageManager from "@/__libs/offline_storage/LocalStorageManager";
import {LocalStorageKey} from "@/_model/app.constants";
import serviceWorkerController from "@/_controller/ServiceWorkerController";

class NetworkManager extends Dispatcher {

    private static _instance: NetworkManager;

    constructor() {
        super();
        if (!NetworkManager._instance) {
            NetworkManager._instance = this;
        }
    }

    public static getInstance(): NetworkManager {
        if (!NetworkManager._instance) {
            new NetworkManager();
        }

        return NetworkManager._instance;
    }

    private _pollInterval: any;
    private _pollTime: number = 10000;
    private _pollTimeOut: number = 5000;

    public pollUrl: string = process.env.VUE_APP_MASTER_API_URL as string;
    public networkState: string = NetworkState.UNKNOWN;
    public online: boolean = false;
    public roadMode: boolean = false;

    public async init() {
        this.roadMode = !!JsonUtil.parse(LocalStorageManager.retrieveValue(LocalStorageKey.ROAD_MODE) as string);
        if (this.roadMode) {
            serviceWorkerController.enableRoadMode();
        } else {
            await this.startPoll();
        }
    }

    // public async isOnline(): Promise<boolean> {
    //     if (this.networkState === NetworkState.UNKNOWN) {
    //         await this.poll();
    //     }
    //     return this.online;
    // }

    public async startPoll() {
        if (!this._pollInterval) {
            await this.poll();
            this._pollInterval = window.setInterval(() => {
                this.poll();
            }, this._pollTime);
        }
    }

    public async stopPoll() {
        if (this._pollInterval) {
            window.clearInterval(this._pollInterval);
            this._pollInterval = null;
        }
    }

    public async poll() {
        try {
            const result = await axios.get(this.pollUrl, {timeout: this._pollTimeOut});
            if (result && result.status === 200) {
                this.setOnline();
            } else {
                this.setOffline();
            }
        } catch (e) {
            this.setOffline();
        }
    }

    public setOnline() {
        if (this.networkState != NetworkState.ONLINE) {
            this.networkState = NetworkState.ONLINE;
            this.online = true;
            this.dispatch(NetworkEventType.STATE_CHANGED, {state: NetworkState.ONLINE});
        }
    }

    public setOffline() {
        if (this.networkState != NetworkState.OFFLINE) {
            this.networkState = NetworkState.OFFLINE;
            this.online = false;
            this.dispatch(NetworkEventType.STATE_CHANGED, {state: NetworkState.OFFLINE});
        }
    }

    // ROAD MODE

    public async setRoadModeOn() {
        LocalStorageManager.storeValue(LocalStorageKey.ROAD_MODE, JsonUtil.stringify(true));
        serviceWorkerController.enableRoadMode();
        await this.stopPoll();
        this.setOffline();
    }

    public async setRoadModeOff() {
        LocalStorageManager.storeValue(LocalStorageKey.ROAD_MODE, JsonUtil.stringify(false));
        serviceWorkerController.disableRoadMode();
        await this.startPoll();
    }

}

export default NetworkManager;

export enum NetworkEventType {
    STATE_CHANGED = "STATE_CHANGED",
}

export enum NetworkState {
    UNKNOWN = "UNKNOWN",
    ONLINE = "ONLINE",
    OFFLINE = "OFFLINE",
}
