<template>
  <div class="main-sub-page page--analytics">

    <div class="base-menu-bar">
      <div class="base-menu">
        <router-link :to="{ name: routingIdentifiers.ANALYTICS_PROJECT }" v-if="showProjectAnalytics"
                     class="base-menu-item">
          <div class="icon">
            <i class="fas fa-globe fa-2x"></i>
          </div>
          <h6 v-html="$t('ProjectAnalytics')"></h6>
        </router-link>
        <router-link :to="{ name: routingIdentifiers.ANALYTICS_TEAM }" class="base-menu-item" v-if="showTeamAnalytics">
          <div class="icon">
            <i class="fas fa-users fa-2x"></i>
          </div>
          <h6 v-html="$t('TeamAnalytics')"></h6>
        </router-link>
        <router-link :to="{ name: routingIdentifiers.ANALYTICS_USER }" class="base-menu-item" v-if="showUserAnalytics">
          <div class="icon">
            <i class="fas fa-user fa-2x"></i>
          </div>
          <h6 v-html="$t('UserAnalytics')"></h6>
        </router-link>
        <router-link :to="{ name: routingIdentifiers.ANALYTICS_CONTENT }" class="base-menu-item"
                     v-if="showContentAnalytics">
          <div class="icon">
            <i class="fas fa-th fa-2x"></i>
          </div>
          <h6 v-html="$t('ContentAnalytics')"></h6>
        </router-link>
      </div>
      <div :id="hintIds.TEAMINSIGHTS_TIME">
        <date-picker v-model="analyticsModel.range" type="date" range
                     :clearable="false"></date-picker>
      </div>
    </div>

    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>

    <b-popover custom-class="hint-popover" :target="hintIds.TEAMINSIGHTS_TIME"
               placement="right"
               triggers="manual"
               boundary="window">
      <Hint :id="hintIds.TEAMINSIGHTS_TIME"></Hint>
    </b-popover>

  </div>
</template>

<script lang="ts">
import {Component, Vue} from "vue-property-decorator";
import TeamUserModel from "@/team/_model/TeamUserModel";
import CreateTeamUserBox from "@/team/_view/CreateTeamUserBox.vue";
import AppUserModel from "@/project/user/_model/AppUserModel";
import {RoutingIdentifier} from "@/router";
import roleController from "@/team/_controller/RoleController";
import {RIGHTS} from "@/team/_model/role.constants";
import AnalyticsModel from "@/analytics/_model/AnalyticsModel";
import DatePicker from "vue2-datepicker";
import Hint from "@/help/hint/_view/Hint.vue";
import {HintIds} from "@/help/hint/_model/hint.constants";

@Component({
  components: {Hint, CreateTeamUserBox, DatePicker}
})
export default class AnalyticsPage extends Vue {
    private hintIds: typeof HintIds = HintIds;

  public appUser: AppUserModel = AppUserModel.getInstance();
  public analyticsModel: AnalyticsModel = AnalyticsModel.getInstance();

  private routingIdentifiers = RoutingIdentifier;

  get showProjectAnalytics(): boolean {
    return AppUserModel.getInstance().rights.indexOf(RIGHTS.VIEW_PROJECT_ANALYTICS.identifier) >= 0;
  }

  get showTeamAnalytics(): boolean {
    return AppUserModel.getInstance().rights.indexOf(RIGHTS.VIEW_TEAM_ANALYTICS.identifier) >= 0 && AppUserModel.getInstance().project.tierConfig.hasUsageInsights;
  }

  get showUserAnalytics(): boolean {
    return AppUserModel.getInstance().rights.indexOf(RIGHTS.VIEW_USER_ANALYTICS.identifier) >= 0 && AppUserModel.getInstance().project.tierConfig.hasUsageInsights;
  }

  get showContentAnalytics(): boolean {
    return AppUserModel.getInstance().rights.indexOf(RIGHTS.VIEW_CONTENT_ANALYTICS.identifier) >= 0 && AppUserModel.getInstance().project.tierConfig.hasContentInsights;
  }

  public mounted() {
    if (this.showProjectAnalytics) {
      this.$router.push({name: RoutingIdentifier.ANALYTICS_PROJECT})//todo change to ANALYTICS_PROJECT
    } else if (this.showTeamAnalytics) {
      this.$router.push({name: RoutingIdentifier.ANALYTICS_TEAM})
    } else if (this.showUserAnalytics) {
      this.$router.push({name: RoutingIdentifier.ANALYTICS_USER})
    } else if (this.showContentAnalytics) {
      this.$router.push({name: RoutingIdentifier.ANALYTICS_CONTENT})
    }
  }
}
</script>
