<template>
    <div class="asset-folder-picker">

        <AssetFoldersView :selState="selState"></AssetFoldersView>

        <div class="d-flex justify-content-end mt-3">
            <b-button variant="secondary" class="mr-2" @click="_onCancelBtnClick" tabindex="1">
                {{ $t('Cancel') }}
            </b-button>
            <b-button variant="primary" @click="_onPickAssetFileBtnClick" :disabled="!selState.selectedFile" tabindex="2">{{
                $t('Ok') }}
            </b-button>
        </div>

    </div>
</template>


<script lang="ts">
    import {Component, Prop, Vue} from "vue-property-decorator";
    import AssetFoldersOverview from "@/asset_folder/_view/AssetFoldersOverview.vue";
    import AssetFoldersView from "@/asset_folder/_view/AssetFoldersView.vue";
    import AssetFolderModel from "@/asset_folder/_model/AssetFolderModel";
    import AssetFolderListModel from "@/asset_folder/_model/AssetFolderListModel";
    import AssetFolderSelectionState from "@/asset_folder/_model/AssetFolderSelectionState";
    import {FileTargetType, FileType} from "@/asset_folder/_model/asset_folder.constants";
    import assetFolderListController from "@/asset_folder/_controller/AssetFolderListController";

    @Component({
        components: {AssetFoldersView}
    })
    export default class AssetFoldersPicker extends Vue
    {
        //---------------------------------
        // Vue Component props
        //---------------------------------

        @Prop() public boxId!:string;
        @Prop() public selectedFileUri!:string;
        @Prop() public allowedTargetType!:FileTargetType | null;
        @Prop() public allowedFileTypes!:FileType[];


        //---------------------------------
        // Vue Component data
        //---------------------------------

        public selState:AssetFolderSelectionState = AssetFolderListModel.getInstance().pickerSelState;

        //---------------------------------
        // Vue Computed properties
        //---------------------------------

        //---------------------------------
        // Public / lifecycle methods
        //---------------------------------

        public mounted()
        {
            //todo: can only work when picker is mounted every time when asked
            this.selState.allowedFileTypes = this.allowedFileTypes;
            this.selState.allowedTargetType = this.allowedTargetType;
            if (this.selectedFileUri)
            {
                assetFolderListController.selectFileByUri(this.selState, this.selectedFileUri);
            }
        }

        //---------------------------------
        // Private / helper methods
        //---------------------------------

        private async _onCancelBtnClick(p_e:Event)
        {
            this.$emit('onAssetFilePicked', null);
        }

        private async _onPickAssetFileBtnClick(p_e:Event)
        {
            this.$emit('onAssetFilePicked', this.selState.selectedFile);
        }

        private isPickAllowed()
        {
            return this.selState.selectedFile !== null;
        }

    }
</script>
