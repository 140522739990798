import SlideModel from "@/slide/_model/SlideModel";
import EntityListModel from "@/entity/_model/EntityListModel";
import SlideSelectionState from "@/slide/_model/SlideSelectionState";
import {
    SlideViewMode
} from "@/slide/_model/slide.constants";

class SlideListModel extends EntityListModel<SlideModel>
{

    public pageSelState:SlideSelectionState = new SlideSelectionState(SlideViewMode.PAGE);

    public pickerSelState:SlideSelectionState = new SlideSelectionState(SlideViewMode.PICKER);

    public static MAX_UPLOAD_SIZE:number = 25* 1024 *1024; //todo should come from project settings

    //---------------------------------
    // Singleton Constructor
    //---------------------------------

    private static _instance:SlideListModel;

    constructor()
    {
        super();
        if (!SlideListModel._instance)
        {
            SlideListModel._instance = this;
        }
    }

    public static getInstance():SlideListModel
    {
        if (!SlideListModel._instance)
        {
            new SlideListModel();
        }

        return SlideListModel._instance;
    }


    //---------------------------------
    // Public Methods
    //---------------------------------





}

export default SlideListModel;
