export default class IntroductionVideoModel {

    public name: string = '';
    public videoUrl: string = '';
    public imageUrl: string = '';

    constructor(name: string, videoUrl: string, imageUrl: string) {
        this.name = name;
        this.videoUrl = videoUrl;
        this.imageUrl = imageUrl;
    }

}
