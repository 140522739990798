<template>
  <div class="list-item team-user-item"
       :class="{ active: isSelected, 'body-loaded': true }"
       v-on:click="_onClick">

    <div class="list-item__icon">
      <AAProfilePicture :name="teamUser.displayName" :imageUri="teamUser.avatarFileUri"></AAProfilePicture>
    </div>

    <div class="list-item__info">
      <h6 class="list-item__info__primary mb-0" v-line-clamp="1">{{ teamUser.displayName }}</h6>
    </div>

  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from "vue-property-decorator";

import {DeltaStatus, LoadingStatus} from "@/entity/_model/entity.constants";
import fileManager from "@/_controller/FileManager";
import EntitySelectionState from "@/entity/_model/EntitySelectionState";
import MorePopup from "@/_view/components/MorePopup.vue";
import EntityActionBox from "@/entity/_view/EntityActionBox.vue";
import AAProfilePicture from "@/_view/components/AAProfilePicture.vue";
import TeamUserModel from "@/team/_model/TeamUserModel";
import SelectionState from "@/__libs/_model/SelectionState";

@Component({
  components: {
    AAProfilePicture, MorePopup, EntityActionBox
  }
})
export default class TeamUserItemRenderer extends Vue {

  @Prop() public teamUser!: TeamUserModel;

  @Prop() private selState!: SelectionState<TeamUserModel>;

  get teamUserID() {
    return this.teamUser.userID;
  }

  get isSelected() {
    return this.selState.selected === this.teamUser;
  }

  public _onClick(p_e: Event) {
    this.$emit("onClick", this.teamUser);
  }
}
</script>
