<template>
  <div class="create-team">

    <transition name="fade">
      <div v-if="currentFormState === formState.CREATE_INPUT || currentFormState === formState.TRY_SUBMIT">
        <b-form @submit.stop.prevent="_onCreateCompanyBtnClick" novalidate>

          <b-form-group :label="$t('PlaceholderDisplayName')">
            <b-form-input v-model="company.displayName"
                          :state="_calculateInputState($v.company.displayName)"
                          tabindex="1"
                          autofocus></b-form-input>
            <b-form-invalid-feedback>
                        <span class="d-block"
                              v-if="!$v.company.displayName.required">{{ $t('FieldRequiredSimple') }}</span>
              <span class="d-block" v-if="!$v.company.displayName.minLength">{{
                  $t('MinimumFieldLengthSimple', [2])
                }}</span>
            </b-form-invalid-feedback>
          </b-form-group>

          <div class="modal-box-buttons">
            <b-button variant="secondary" @click="_onCancelBtnClick" tabindex="4">
              {{ $t('Cancel') }}
            </b-button>
            <b-button type="submit" variant="primary"
                      :disabled="currentFormState === formState.TRY_SUBMIT && $v.$invalid" tabindex="5">{{
                $t('AddCompany')
              }}
            </b-button>
          </div>
        </b-form>
      </div>
    </transition>

    <transition name="fade">
      <div v-if="currentFormState === formState.CREATING_REMOTE" class="text-center">
        <b-spinner variant="dark" class="my-5"></b-spinner>
      </div>
    </transition>

    <transition name="fade">
      <div v-if="currentFormState === formState.REMOTE_ERROR">
        <p class="mb-4 text-danger">{{ errorMsg }}</p>
        <div class="d-flex justify-content-end">
          <button class="btn btn-secondary mr-2" @click="_onCancelBtnClick">{{ $t('Cancel') }}</button>
        </div>
      </div>
    </transition>

  </div>

</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import i18n from '@/__plugins/i18n';
import CompanyModel from "@/company/_model/CompanyModel";
import languageManager from "@/__libs/language_manager/LanguageManager";
import AppUserModel from "@/project/user/_model/AppUserModel";
import companyListController from "@/company/_controller/CompanyListController";

// validation
import {required, minLength, email} from 'vuelidate/lib/validators';
import {ApiResponse} from "@/_controller/ApiManager";
import {ICreateResultDto} from "@/entity/_model/entity.dto";

enum FormState {
    CREATE_INPUT,
    TRY_SUBMIT,
    CREATING_REMOTE,
    REMOTE_CREATED,
    REMOTE_ERROR
}

@Component({
    validations: {
        company: {
            displayName: {
                required,
                minLength: minLength(2)
            }
        }
    }
})
export default class CreateCompanyBox extends Vue {


    //---------------------------------
    // Validations
    //---------------------------------

    //---------------------------------
    // Vue Component props
    //---------------------------------

    //---------------------------------
    // Vue Component data
    //---------------------------------

    private company: CompanyModel = new CompanyModel();

    private formState: typeof FormState = FormState;

    private currentFormState: FormState = FormState.CREATE_INPUT;

    private errorMsg: string = "";

    //---------------------------------
    // Vue Computed properties
    //---------------------------------


    get availableLanguages(): string[] {
        return languageManager.availableLangCodes;
    }

    //---------------------------------
    // Public / lifecycle methods
    //---------------------------------

    mounted() {
        this.company = new CompanyModel();
        this.company.displayName = "";
        this.currentFormState = FormState.CREATE_INPUT;
    }

    //---------------------------------
    // Private / helper methods
    //---------------------------------

    private _calculateInputState(inputValidator: any): any {
        return this.currentFormState === FormState.TRY_SUBMIT && inputValidator.$invalid ? false : null;
    }

    private async _onCreateCompanyBtnClick(p_e: Event) {

        this.currentFormState = FormState.TRY_SUBMIT;

        if (!this.$v.$invalid) {
            this.currentFormState = FormState.CREATING_REMOTE;

            const createSucceeded: boolean = await companyListController.createEntity(this.company);

            if (createSucceeded) {
                this.$emit('onFinish', this.company);
            } else {
                this.errorMsg = this.$t('CompanyCreationFailed') as string;
                this.currentFormState = FormState.REMOTE_ERROR;
            }
        }
    }

    private async _onCancelBtnClick(p_e: Event) {
        this.$emit('onFinish', null);
    }

}
</script>
