<template>
    <div class="analytics-user-totals">

        <div class="row">
            <div class="col-4">
                <div class="analytics-user-totals__block">
                    <div class="analytics-user-totals__block__number">{{ analytics.totalUsers }}</div>
                    <div class="analytics-user-totals__block__text">{{ $t('AnalyticsTotalUsers') }}</div>
                </div>
            </div>
            <div class="col-4">
                <div class="analytics-user-totals__block">
                    <div class="analytics-user-totals__block__number">{{ analytics.totalTeams }}</div>
                    <div class="analytics-user-totals__block__text">{{ $t('AnalyticsTotalTeams') }}</div>
                </div>
            </div>
            <div class="col-4">
                <div class="analytics-user-totals__block">
                    <div class="analytics-user-totals__block__number">{{ analytics.totalMarkets }}</div>
                    <div class="analytics-user-totals__block__text">{{ $t('AnalyticsTotalMarkets') }}</div>
                </div>
            </div>
        </div>

    </div>
</template>


<script lang="ts">
    import {Component, Vue} from 'vue-property-decorator';
    import AnalyticsModel from "@/analytics/_model/AnalyticsModel";

    @Component({
        components: {},
    })
    export default class AnalyticsUserTotals extends Vue
    {
        //---------------------------------
        // Vue Component props
        //---------------------------------

        //---------------------------------
        // Vue Component data
        //---------------------------------

        //---------------------------------
        // Vue Computed properties
        //---------------------------------

        get analytics() {
            return AnalyticsModel.getInstance();
        }

        //---------------------------------
        // Public / lifecycle methods
        //---------------------------------

        public mounted()
        {
        }

        //---------------------------------
        // Private / helper methods
        //---------------------------------

    }
</script>
