<template>
  <div class="project-colors">
    <ProjectColor :name="colors.PRIMARY" :hex="project.config.ui.primaryColor" v-on:onChange="onChangePrimary" />
    <ProjectColor :name="colors.SECUNDARY" :hex="project.config.ui.secundaryColor" v-on:onChange="onChangeSecundary" />
    <ProjectColor :name="colors.TERTIARY" :hex="project.config.ui.tertiaryColor" v-on:onChange="onChangeTertiary" />
  </div>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import ProjectModel from "@/project/_model/ProjectModel";
import AppUserModel from "@/project/user/_model/AppUserModel";
import {Colors} from "@/project/_model/project.constants";
import ProjectColor from "@/project/_view/ProjectColor.vue";
import projectController from "@/project/_controller/ProjectController";

@Component({
  components: {ProjectColor},
})
export default class ProjectColors extends Vue {

  public project: ProjectModel = AppUserModel.getInstance().project;
  private colors: typeof Colors = Colors;
  private hasChanges: boolean = false;

  public beforeDestroy() {
    this.save();
  }

  private onChangePrimary(hex: string) {
    this.hasChanges = true;
    this.project.config.ui.primaryColor = hex;
  }

  private onChangeSecundary(hex: string) {
    this.hasChanges = true;
    this.project.config.ui.secundaryColor = hex;
  }

  private onChangeTertiary(hex: string) {
    this.hasChanges = true;
    this.project.config.ui.tertiaryColor = hex;
  }

  private save() {
    if (this.hasChanges) {
      projectController.saveProjectConfig();
    }
  }

}
</script>

