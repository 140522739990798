<template>
  <div class="box box--full-height box--scrollable slide-detail-primary slide" :id="hintIds.SLIDES2_EDITING">

    <transition name="fade">
      <div v-if="!selState.selected"
           class="empty-message">
        {{ $t('PleaseSelectSlide') }}
      </div>
    </transition>


    <transition name="fade">
      <div v-if="selState.selected" :key="`${selState.selected.ID}_primary`" class="box-wrapper">

        <h4 class="title mt-1 mb-4" v-html="slideName" v-line-clamp="1"></h4>

        <div class="responsive responsive--16-9 mb-4">
          <div class="responsive__wrapper">
            <PresentationPlayer
                engineMode="SINGLE_SLIDE"
                :slideID="selState.selected.ID"/>
          </div>
        </div>

        <EntityActionBox :entity="slide" edit-allowed="true" class="w-100"></EntityActionBox>

      </div>
    </transition>

    <b-popover custom-class="hint-popover" :target="hintIds.SLIDES2_EDITING"
               placement="bottom"
               boundary="window"
               triggers="manual">
      <Hint :id="hintIds.SLIDES2_EDITING"></Hint>
    </b-popover>

  </div>
</template>


<script lang="ts">
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import SlideSelectionState from "@/slide/_model/SlideSelectionState";
import AssetFolderModel from "@/asset_folder/_model/AssetFolderModel";
import SlideModel from "@/slide/_model/SlideModel";
import slideController from "@/slide/_controller/SlideController";
import languageManager from "@/__libs/language_manager/LanguageManager";
import AppUserModel from "@/project/user/_model/AppUserModel";
import EntityActionBox from "@/entity/_view/EntityActionBox.vue";
import AudienceModel from "@/audience/_model/AudienceModel";
import PresentationPlayer from "@/presentation/player/_view/PresentationPlayer.vue";
import Hint from "@/help/hint/_view/Hint.vue";
import {HintIds} from "@/help/hint/_model/hint.constants";

@Component({
    components: {Hint, EntityActionBox, PresentationPlayer}
})
export default class Slide extends Vue {

    @Prop() private selState!: SlideSelectionState;

    private hintIds: typeof HintIds = HintIds;

    get usedInPresentations() {
        if (this.slide) {
            return this.slide.usedInPresentations;
        } else {
            return [];
        }

    }

    get slide(): SlideModel | null {
        return this.selState.selected;
    }

    get slideName() {
        return languageManager.getTranslationForValue<string>(this.slide!.name, AppUserModel.getInstance().langCode)
    }
}
</script>
