<template>
  <div class="mouse-pointer-layer"
       :style="{ left: offsetLeftTop.left, top:offsetLeftTop.top}">

    <MousePointerItemRenderer v-for="(user, i) in otherUsers" :key="i" :user="user" :room="room" :myScale="myScale"/>

  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from "vue-property-decorator";
import MousePointerItemRenderer from "@/cobrowse/room/_view/MousePointerItemRenderer.vue";
import Room from "@sales-drive/audience-library/lib/cobrowse_manager/room/Room";
import SharedObject from "@sales-drive/audience-library/lib/cobrowse_manager/shared_object/SharedObject";
import {IRoomUsersSoDataDto} from "@sales-drive/audience-library/lib/cobrowse_manager/room/_model/room.dto";
import CobrowseManager from "@sales-drive/audience-library/lib/cobrowse_manager/CobrowseManager";
import {RoomEvent} from "@sales-drive/audience-library/lib/cobrowse_manager/room/_model/room.constants";


@Component({
  components: {MousePointerItemRenderer}
})
export default class MousePointerLayer extends Vue
{

  @Prop() public room!:Room;

  mounted()
  {
    // window.addEventListener('mousemove', this.mouseMove);
    // window.addEventListener('mousedown', this.mouseDown);
  }

  get roomUsersSO():SharedObject<IRoomUsersSoDataDto>
  {
    return this.room.roomUsersSO!;
  }



  get otherUsers()
  {
    return this.roomUsersSO.data.users.filter(user => {
      return (user.cobrowseUserID !== CobrowseManager.getInstance().cobrowseUser.cobrowseUserID);
    });
  }


  public mouseDown(p_event:MouseEvent)
  {
    if (this.room.yourself && this.room.yourself.interactionState && this.room.yourself.interactionState > 0)
    {
      this.room.emitEvent(RoomEvent.MOUSE_DOWN, CobrowseManager.getInstance().cobrowseUser.cobrowseUserID);
    }
  }

  get offsetLeftTop()
  {
    if (this.room.yourself && this.room.yourself.userMouse)
    {
      return {left: this.room.yourself.userMouse.leftOffset + "px", top: this.room.yourself.userMouse.topOffset + "px"}
    }
    else
    {
      return {left: "0px", top: "0px"}
    }
  }

  get myScale(){
    if (this.room.yourself && this.room.yourself.userMouse)
    {
      return this.room.yourself.userMouse.scale;
    }
      return 0;
  }

}
</script>


