<template>
  <div class="welcome-sub-page welcome-color-page">
    <h1 class="welcome-sub-page__title mb-3" v-html="$t('WelcomeColorTitle')"></h1>
    <p class="welcome-sub-page__text" v-html="$t('WelcomeColorText')"></p>
    <div class="welcome-sub-page__content">
      <ProjectColors></ProjectColors>
    </div>
    <WelcomeNavigation :page-index="2"></WelcomeNavigation>
  </div>
</template>

<script lang="ts">
import {Component, Vue} from "vue-property-decorator";
import WelcomeNavigation from "@/project/welcome/_view/WelcomeNavigation.vue";
import ProjectLogos from "@/project/_view/ProjectLogos.vue";
import ProjectColors from "@/project/_view/ProjectColors.vue";

@Component({
  components: {ProjectColors, WelcomeNavigation}
})
export default class WelcomeColorPage extends Vue {
}
</script>
