import {IPropertyDefinitionDto} from '@/data_tool/_model/data_tool.dto';
import {IDataFilter} from '@/data_tool/data_filter/_model/data-filter.dto';
import {FormElement, PropertyType} from '@/data_tool/_model/data_tool.constants';

export default class DataFilterModel {
  public schemaName: string = '';
  public schemaItemDefinitionId: string = '';

  public propertyDefinitions: IPropertyDefinitionDto[] = [];
  public filterValues: IDataFilter[] = [];

  public appliedFilters: IDataFilter[] = [];

  public readonly validPropertyTypes: PropertyType[] = [
    PropertyType.TEXT, PropertyType.NUMBER, PropertyType.DATE,
    PropertyType.ONOFF, PropertyType.FIXED_LIST, PropertyType.SELECT_LIST
  ];

  public readonly validElementTypes: FormElement[] = [
    FormElement.TEXT_INPUT, FormElement.NUMBER_INPUT, FormElement.SLIDER,
    FormElement.CHECKBOX, FormElement.CHECKBOX_GROUP, FormElement.RADIOBUTTON_GROUP,
    FormElement.DATE_PICKER, FormElement.LISTBOX, FormElement.COMBOBOX
  ];

  constructor(propertyDefinitions?: IPropertyDefinitionDto[]) {
    if (propertyDefinitions) {
      this.propertyDefinitions = propertyDefinitions;
    }
  }

  public reset() {
    for (const filterValue of this.filterValues) {
      filterValue.value = '';
    }

    this.appliedFilters = [];
  }
}
