import {ENTITY_CONFIG, EntityType, UploadStatus} from "@/entity/_model/entity.constants";
import EntityController from "@/entity/_controller/EntityController";
import SlideModel from "@/slide/_model/SlideModel";
import apiManager, {ApiRequestType, ApiResponse} from "@/_controller/ApiManager";

import SlideListModel from "@/slide/_model/SlideListModel";
import FileUtil from "@/__libs/utility/FileUtil";
import AppUserModel from "@/project/user/_model/AppUserModel";
import {IFileDto, IUploadFileResultDto} from "@/_model/app.constants";
import SlideSelectionState from "@/slide/_model/SlideSelectionState";
import {IDeleteResultDto, IUpdateResultDto} from "@/entity/_model/entity.dto";
import fileManager from "@/_controller/FileManager";
import AudienceModel from "@/audience/_model/AudienceModel";
import {IAudienceActivityDto} from "@/audience/track/_model/audience_activity.dto";
import {IPresentationOutlineDto} from "@/presentation/_model/presentation.dto";
import PresentationModel from "@/presentation/_model/PresentationModel";
import {ITranslationGroupDto, ITranslationMultiLangDto} from "@/_model/app.dto";
import {ISlideBodyDto} from "@/slide/_model/slide.dto";
import {SlideSource} from "@/presentation/_model/presentation.constants";
import ContentListModel from "@/content/_model/ContentListModel";
import EntityControllerFactory from "@/entity/_controller/EntityControllerFactory";



//contains all (static) controller methods that can be initiated on a single asset folder
class SlideController extends EntityController
{


    //---------------------------------
    // Properties
    //---------------------------------


    //---------------------------------
    // Controller Methods
    //---------------------------------

    public async fetchSlideUsage(p_slide:SlideModel):Promise<boolean>
    {
        const response:ApiResponse<IPresentationOutlineDto[]> = await apiManager.sendApiRequest(ApiRequestType.GET, `/client-api/${ENTITY_CONFIG.SLIDE.apiPath}/${p_slide.ID}/presentations`);

        if (response.hasSucceeded)
        {
            p_slide.usedInPresentations = response.result as IPresentationOutlineDto[];
        }
        return response.hasSucceeded;

    }


    public async getTranslations(p_slide:SlideModel):Promise<ITranslationGroupDto[]>
    {

        const slideTranslations:ITranslationMultiLangDto[] = p_slide.getMultiLangTranslations();
        const slideGroup:ITranslationGroupDto = {
            name        : `Slide`,
            identifier  : p_slide.ID,
            translations: slideTranslations
        };


        return [slideGroup];
    }

    public async setTranslations(p_transGroups:ITranslationGroupDto[]):Promise<boolean>
    {
        const transGroup = p_transGroups[0];
        const response:ApiResponse<IUpdateResultDto> = await apiManager.sendApiRequest(ApiRequestType.GET, `/client-api/slides/${transGroup.identifier}`);
        if (response.hasSucceeded)
        {
            const slideBody = response.result as ISlideBodyDto;
            const slide:SlideModel = new SlideModel(slideBody);
            const slideHasChanges:boolean = slide.setMultiLangTranslations(transGroup.translations);
            if (slideHasChanges)
            {
                await EntityControllerFactory.getController(EntityType.SLIDE).saveBody(slide);
            }
        }
        return true;
    }

//---------------------------------
    // Private Methods
    //---------------------------------


}

//Singleton export
export default new SlideController(ENTITY_CONFIG.SLIDE);
