import AppUserModel from "@/project/user/_model/AppUserModel";
import AppModel from "@/_model/AppModel";
import Dispatcher from "@/__libs/utility/Dispatcher";
import {Workbox} from "workbox-window";
import axios from "axios";
import networkManager from "@/_controller/NetworkManager";
import NetworkManager from "@/_controller/NetworkManager";

class ServiceWorkerController extends Dispatcher {

    public registration: ServiceWorkerRegistration | undefined;

    private workbox!: Workbox;
    private serviceWorker!: ServiceWorker;

    // public workbox!: Workbox;
    // public currentServiceWorker!: ServiceWorker;

    public serviceWorkerAvailable(): boolean {
        return 'serviceWorker' in navigator;
    }

    public async init(): Promise<boolean> {
        if (this.serviceWorkerAvailable()) {
            this.workbox = await new Workbox(`${process.env.BASE_URL}service-worker.js`);
            return true;
        }
        return false;
    }

    public async register(): Promise<boolean> {
        if (this.serviceWorkerAvailable()) {
            // // register service worker
            // this.registration = await navigator.serviceWorker.register(`${process.env.BASE_URL}service-worker.js`);
            // if (this.registration.waiting) {
            //     // newer version available & ready so let's refresh
            //     console.log('ServiceWorkerController :: update found, refreshing no1');
            //     window.location.reload();
            // }
            // if (this.registration) {
            //     console.log('ServiceWorkerController :: service worker registered');
            //     // let's check for a new version, if so, gotta refresh
            //     this.registration.onupdatefound = () => {
            //         const installingWorker: any = this.registration.installing;
            //         if (installingWorker === null) {
            //             return true;
            //         }
            //         installingWorker.onstatechange = () => {
            //             console.log('ServiceWorkerController :: installingWorker state change', installingWorker.state);
            //             if (installingWorker.state === 'installed') {
            //                 if (navigator.serviceWorker.controller) {
            //                     console.log('ServiceWorkerController :: update found, refreshing no2');
            //                     window.location.reload();
            //                 }
            //             }
            //         }
            //     }
            //     return true;
            // } else {
            //     return false;
            // }
            this.workbox.addEventListener('activated', async event => {
                console.log('STEP 2d: service worker responded');
                if (event.sw !== this.serviceWorker) {
                    console.log('STEP 2e: service worker is new so need to reload');
                    window.location.reload();
                }
            });
            console.log('STEP 2a: register service worker');
            this.registration = await this.workbox.register();
            console.log('STEP 2b: activate');
            this.serviceWorker = await this.workbox.active;
            console.log('STEP 2c: activate finished');
            return true;
        }
        return false;
    }

    public setRoutes(): void {
        this.workbox.messageSW({
            type: "REGISTER_ROUTES",
            payload: {
                globalStorageUrl: AppModel.getInstance().global.storageUrl,
                projectStorageUrl: AppUserModel.getInstance().project.storageUrl,
                projectApiUrl: AppUserModel.getInstance().project.apiUrl
            }
        });
    }


    // public async init(): Promise<void> {
    //     if (this.serviceWorkerAvailable()) {
    //         this.workbox = await new Workbox(`${process.env.BASE_URL}service-worker.js`);
    //         // update
    //         this.workbox.addEventListener('waiting', event => {
    //             console.log('ServiceWorkerController :: update available and waiting');
    //             AppModel.getInstance().updateAvailable = true;
    //         });
    //         // activated
    //         this.workbox.addEventListener('activated', event => {
    //             if (event.sw === this.currentServiceWorker) {
    //                 console.log('ServiceWorkerController :: first time activated');
    //                 AppModel.getInstance().serviceWorkerState = ServiceWorkerState.ACTIVATED;
    //                 this.dispatch(ServiceWorkerEvents.EVENT_SERVICEWORKER_INIT_FINISHED);
    //             } else {
    //                 window.location.reload();
    //             }
    //         });
    //         await this.workbox.register();
    //         this.currentServiceWorker = await this.workbox.active;
    //         if (this.currentServiceWorker.state === 'activated') {
    //             console.log('ServiceWorkerController :: existing activated');
    //             AppModel.getInstance().serviceWorkerState = ServiceWorkerState.ACTIVATED;
    //             this.dispatch(ServiceWorkerEvents.EVENT_SERVICEWORKER_INIT_FINISHED);
    //         }
    //     } else {
    //         AppModel.getInstance().serviceWorkerState = ServiceWorkerState.ERROR;
    //         this.dispatch(ServiceWorkerEvents.EVENT_SERVICEWORKER_INIT_FINISHED);
    //     }
    // }
    //
    // public async waitForUpdate(): Promise<void> {
    //     const maxChecks: number = 10;
    //     let check: number = 0;
    //     const interval = window.setInterval(() => {
    //         if (AppModel.getInstance().updateAvailable) {
    //             window.clearInterval(interval);
    //             this.update();
    //             this.dispatch(ServiceWorkerEvents.EVENT_SERVICEWORKER_UPDATE_WAIT_FINISHED);
    //         }
    //         check++;
    //         if (check > maxChecks) {
    //             window.clearInterval(interval);
    //             this.dispatch(ServiceWorkerEvents.EVENT_SERVICEWORKER_UPDATE_WAIT_FINISHED);
    //             return false;
    //         }
    //     }, 1000);
    // }

    // public setRoutes() {
    //     this.workbox.messageSW({
    //         type: "REGISTER_ROUTES",
    //         payload: {
    //             globalStorageUrl: AppModel.getInstance().global.storageUrl,
    //             projectStorageUrl: AppUserModel.getInstance().project.storageUrl,
    //             projectApiUrl: AppUserModel.getInstance().project.apiUrl
    //         }
    //     });
    // }

    //
    // public async checkUpdate() {
    //     this.workbox.update();
    // }
    //
    // public update(): void {
    //     this.workbox.messageSkipWaiting();
    // }

    public enableRoadMode() {
        this.workbox.messageSW({
            type: "ENABLE_ROAD_MODE"
        });
    }

    public disableRoadMode() {
        this.workbox.messageSW({
            type: "DISABLE_ROAD_MODE"
        });
    }

    public async wakeUp() {
        console.log('ServiceWorkerController :: wake up');
    }

}

export default new ServiceWorkerController();
