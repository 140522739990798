<template>
  <div class="create-team-user">
    <transition name="fade">
      <div v-if="currentFormState === formState.CREATE_INPUT || currentFormState === formState.TRY_SUBMIT">
        <b-form @submit.stop.prevent="_onCreateTeamUserBtnClick" novalidate>

          <div style="color: red" v-if="showCreateWarning">
            {{ $t('CreateUserWarning', [nrOfUsers]) }}
          </div>

          <b-form-group :label="$t('PlaceholderDisplayName')">
            <b-form-input v-model="teamUser.displayName"
                          :state="_calculateInputState($v.teamUser.displayName)"
                          tabindex="1"
                          autofocus></b-form-input>
            <b-form-invalid-feedback>
                        <span class="d-block"
                              v-if="!$v.teamUser.displayName.required">{{ $t('FieldRequiredSimple') }}</span>
              <span class="d-block" v-if="!$v.teamUser.displayName.minLength">{{
                  $t('MinimumFieldLengthSimple', [3])
                }}</span>
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group :label="$t('PlaceholderEmail')">
            <b-form-input type="email"
                          v-model="teamUser.email"
                          :state="_calculateInputState($v.teamUser.email)"
                          tabindex="2"></b-form-input>
            <b-form-invalid-feedback>
                            <span class="d-block"
                                  v-if="!$v.teamUser.email.required">{{ $t('FieldRequiredSimple') }}</span>
              <span class="d-block"
                    v-if="!$v.teamUser.email.email">{{ $t('ErrorInvalidEmailAddress') }}</span>
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group :label="$t('PlaceholderPersonLanguage')">
            <select class="form-control" v-model="teamUser.langCode" tabindex="3">
              <option v-for="langCode in availableLanguages" :key="langCode"
                      :value="langCode">{{ langCode }}
              </option>
            </select>
          </b-form-group>

          <b-form-group>
            <label>
              <i class="fas fa-users"></i>
              {{ $t('TeamUserTeam') }}
            </label>
            <b-form-select v-model="teamUser.teamID" tabindex="4">
              <option v-for="team in availableTeams" :key="team.teamID"
                      :value="team.teamID">{{ team.displayName }}
              </option>
            </b-form-select>
          </b-form-group>

          <b-form-group>
            <label>
              <i class="fas fa-chess"></i>
              {{ $t('TeamUserRole') }}
            </label>
            <b-form-select v-model="teamUser.roleID" tabindex="5">
              <option v-for="role in availableRoles" :key="role.roleID"
                      :value="role.roleID">{{
                  $t(`RoleName_${role.identifier}`)
                }} ({{
                  $t(`RoleDescription_${role.identifier}`)
                }})
              </option>
            </b-form-select>
          </b-form-group>

          <div class="modal-box-buttons">
            <b-button variant="secondary" @click="_onCancelBtnClick" tabindex="6">
              {{ $t('Cancel') }}
            </b-button>
            <b-button type="submit" variant="primary"
                      :disabled="currentFormState === formState.TRY_SUBMIT && $v.$invalid" tabindex="7">{{
                $t('DashboardMasterAddTeamUserButton')
              }}
            </b-button>
          </div>
        </b-form>
      </div>
    </transition>

    <transition name="fade">
      <div v-if="currentFormState === formState.CREATING_REMOTE" class="text-center">
        <b-spinner variant="dark" class="my-5"></b-spinner>
      </div>
    </transition>

    <transition name="fade">
      <div v-if="currentFormState === formState.REMOTE_ERROR">
        <p class="mb-4 text-danger">{{ errorMsg }}</p>
        <div class="d-flex justify-content-end">
          <button class="btn btn-secondary mr-2" @click="_onCancelBtnClick">{{ $t('Cancel') }}</button>
        </div>
      </div>
    </transition>

  </div>

</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import TeamUserModel from "@/team/_model/TeamUserModel";
import languageManager from "@/__libs/language_manager/LanguageManager";
import AppUserModel from "@/project/user/_model/AppUserModel";
import teamController from "@/team/_controller/TeamUserController";
// validation
import {email, minLength, required} from 'vuelidate/lib/validators';
import {ApiResponse} from "@/_controller/ApiManager";
import {ICreateResultDto} from "@/entity/_model/entity.dto";
import {TierType} from "@/project/_model/project.constants";
import {IRoleOutlineDto, ITeamOutlineDto} from "@/team/_model/team.dto";
import RoleListModel from "@/team/_model/RoleListModel";

enum FormState {
    CREATE_INPUT,
    TRY_SUBMIT,
    CREATING_REMOTE,
    REMOTE_CREATED,
    REMOTE_ERROR
}

@Component({
    validations: {
        teamUser: {
            displayName: {
                required,
                minLength: minLength(3)
            },
            email: {
                required,
                email
            }
        }
    }
})
export default class CreateTeamUserBox extends Vue {


    //---------------------------------
    // Validations
    //---------------------------------

    //---------------------------------
    // Vue Component props
    //---------------------------------

    //---------------------------------
    // Vue Component data
    //---------------------------------

    private teamUser: TeamUserModel = new TeamUserModel();

    private formState: typeof FormState = FormState;

    private currentFormState: FormState = FormState.CREATE_INPUT;

    private errorMsg: string = "";

    private showCreateWarning: boolean = AppUserModel.getInstance().project.tierConfig.tierType === TierType.EXPLORE || AppUserModel.getInstance().project.tierConfig.tierType === TierType.EXPLORE_PLUS;

    private nrOfUsers: number = AppUserModel.getInstance().project.tierQuota.nrOfUsers;

    //---------------------------------
    // Vue Computed properties
    //---------------------------------


    get availableLanguages(): string[] {
        return languageManager.availableLangCodes;
    }

    get availableTeams(): ITeamOutlineDto[] {
        return AppUserModel.getInstance().shareableTeams;
    }

    get availableRoles(): IRoleOutlineDto[] {
        return RoleListModel.getInstance().list;
    }

    //---------------------------------
    // Public / lifecycle methods
    //---------------------------------

    mounted() {
        this.teamUser = new TeamUserModel();
        this.teamUser.displayName = "";
        this.teamUser.email = "";
        this.teamUser.langCode = AppUserModel.getInstance().langCode;
        this.teamUser.roleID = AppUserModel.getInstance().roleID;
        this.teamUser.teamID = AppUserModel.getInstance().teamID;
        this.currentFormState = FormState.CREATE_INPUT;
    }

    //---------------------------------
    // Private / helper methods
    //---------------------------------

    private _calculateInputState(inputValidator: any): any {
        return this.currentFormState === FormState.TRY_SUBMIT && inputValidator.$invalid ? false : null;
    }

    private async _onCreateTeamUserBtnClick(p_e: Event) {
        this.currentFormState = FormState.TRY_SUBMIT;

        if (!this.$v.$invalid) {
            this.currentFormState = FormState.CREATING_REMOTE;

            const createResponse: ApiResponse<ICreateResultDto> = await teamController.createTeamUser(this.teamUser);

            if (createResponse.hasSucceeded) {
                this.$emit('onFinish', this.teamUser);
            } else {
                this.errorMsg = createResponse.error!.code === 409 ? this.$t('TeamUserCreationFailedEmailExists') as string : this.$t('TeamUserCreationFailed') as string;
                this.currentFormState = FormState.REMOTE_ERROR;
            }
        }
    }

    private async _onCancelBtnClick(p_e: Event) {
        this.$emit('onFinish', null);
    }

}
</script>
