<template>
    <div class="analytics-topusage">

        <h5 class="mb-4">{{ $t('AnalyticsTopUsage') }}</h5>

        <b-table striped :items="analytics.topUsage" :fields="fields" :head-variant="'dark'" :borderless="true">
            <template v-slot:cell(percentage)="data">
                <span v-if="data.value === 0">-</span>
                <span v-if="data.value > 0" class="text-success">+{{ data.value }}%</span>
                <span v-if="data.value < 0" class="text-danger">{{ data.value }}%</span>
            </template>
        </b-table>
    </div>
</template>


<script lang="ts">
    import {Component, Vue} from 'vue-property-decorator';
    import AnalyticsModel from "@/analytics/_model/AnalyticsModel";
    import AnalyticsUserEvolutionGraph from "@/analytics/_view/AnalyticsUserEvolutionGraph.vue";

    @Component({
        components: {},
    })
    export default class AnalyticsTopUsage extends Vue
    {
        //---------------------------------
        // Vue Component props
        //---------------------------------

        //---------------------------------
        // Vue Component data
        //---------------------------------

        //---------------------------------
        // Vue Computed properties
        //---------------------------------

        get analytics():AnalyticsModel {
            return AnalyticsModel.getInstance();
        }

        get fields():any[] {
            return [
                { key: 'displayName', label: this.$t('AnalyticsTopTeamsDisplayName') },
                { key: 'presented', label: this.$t('AnalyticsPresented') },
                { key: 'shared', label: this.$t('AnalyticsShared') },
                { key: 'viewed', label: this.$t('AnalyticsViewed') },
                { key: 'total', label: this.$t('AnalyticsTotal') },
                { key: 'percentage', label: this.$t('AnalyticsEvolution') }
            ];
        }

        //---------------------------------
        // Public / lifecycle methods
        //---------------------------------

        //---------------------------------
        // Private / helper methods
        //---------------------------------

    }
</script>
