<template>
  <div :class="`property property--${filterValue.propertyDefinition.type}`">

    <div class="property__name" :class="{'text-danger': false}">
      <div class="property__name__content">
        <span class="property__name__content__text">{{ name }}:</span>
      </div>
    </div>

    <transition name="fade">

      <div class="property__value">
        <input
          v-if="filterValue.propertyDefinition.formElement === FormElement.TEXT_INPUT"
          v-model="filterValue.value"
          :maxlength="filterValue.propertyDefinition.maxLength"
          type="text"
          class="form-control"
        />

        <input
          v-if="filterValue.propertyDefinition.formElement === FormElement.NUMBER_INPUT"
          v-model="filterValue.value"
          type="number"
          :step="filterValue.propertyDefinition.step"
          :min="filterValue.propertyDefinition.minimum"
          :max="filterValue.propertyDefinition.maximum"
          class="form-control"
        />

        <div v-if="filterValue.propertyDefinition.formElement === FormElement.SLIDER" class="slider">
          <div class="slider__value mb-1 text-center">{{ filterValue.value }}</div>
          <div class="slider__bar">
            <input
              v-model="filterValue.value"
              type="range"
              :step="filterValue.propertyDefinition.step"
              :min="filterValue.propertyDefinition.minimum"
              :max="filterValue.propertyDefinition.maximum"
              class="form-control-range"
            />
          </div>
        </div>

        <input
          v-if="filterValue.propertyDefinition.formElement === FormElement.DATE_PICKER"
          type="date"
          v-model="filterValue.value"
          :step="filterValue.propertyDefinition.step"
          :min="filterValue.propertyDefinition.minDate"
          :max="filterValue.propertyDefinition.maxDate"
          class="form-control"
        />

        <b-form-checkbox
          v-if="filterValue.propertyDefinition.formElement === FormElement.CHECKBOX"
          v-model="filterValue"
          size="lg"
        />

        <FixedList
          v-if="filterValue.propertyDefinition.type === PropertyType.FIXED_LIST"
          :property-definition="filterValue.propertyDefinition"
          v-model="filterValue.value"
        />
      </div>

    </transition>

  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from "vue-property-decorator";
import languageManager from '@/__libs/language_manager/LanguageManager';
import AppUserModel from '@/project/user/_model/AppUserModel';
import {FormElement, PropertyType} from '@/data_tool/_model/data_tool.constants';
import DataFilterModel from '@/data_tool/data_filter/_model/DataFilterModel';
import FixedList from '@/data_tool/data_item/_view/FixedList.vue';
import {IDataFilter} from '@/data_tool/data_filter/_model/data-filter.dto';

@Component({
  components: {FixedList}
})
export default class DataFilterItem extends Vue {
  @Prop({ required: true }) public readonly filterValue!: IDataFilter;
  @Prop({ required: true }) public readonly filterModel!: DataFilterModel;

  public readonly PropertyType: typeof PropertyType = PropertyType;
  public readonly FormElement: typeof FormElement = FormElement;

  //---------------------------------
  // Vue Computed properties
  //---------------------------------
  get name() {
    return languageManager.getTranslationForValue(this.filterValue.propertyDefinition.name, AppUserModel.getInstance().langCode);
  }
}
</script>
