<template>
  <div class="columns data-providers">
    <DataProvidersOverview :selState="selState"></DataProvidersOverview>
    <DataProviderDetail :selState="selState"></DataProviderDetail>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from "vue-property-decorator";
import DataProvidersOverview from "@/data_tool/data_provider/_view/DataProvidersOverview.vue";
import DataProviderSelectionState from "@/data_tool/data_provider/_model/DataProviderSelectionState";
import DataProviderDetail from "@/data_tool/data_provider/_view/DataProviderDetail.vue";
import HintListController from "@/help/hint/_controller/HintListController";
import {HintGroupIds} from "@/help/hint/_model/hint.constants";

@Component({
  components: {DataProvidersOverview, DataProviderDetail}
})
export default class DataProvidersView extends Vue {

  @Prop() private selState!: DataProviderSelectionState;

  public mounted() {
        HintListController.startGroup(HintGroupIds.DATA1);
    }

}
</script>
