<template>
  <div class="road-mode-toggle" v-b-tooltip.hover.top :title="$t('RoadModePopover')">
    <b-form-checkbox v-model="roadMode" switch @change="roadModeChanged"></b-form-checkbox>
  </div>
</template>


<script lang="ts">
import {Component, Prop, Vue} from "vue-property-decorator";
import SyncModel from "@/sync/_model/SyncModel";
import NetworkManager from "@/_controller/NetworkManager";
import AppModel from "@/_model/AppModel";
import {RoutingIdentifier} from "@/router";
import serviceWorkerController from "@/_controller/ServiceWorkerController";
import toastManager, {ToastType} from "@/__libs/toast_manager/ToastManager";
import i18n from "@/__plugins/i18n";

@Component({
    components: {}
})
export default class RoadModeToggle extends Vue {

    private syncModel: SyncModel = SyncModel.getInstance();
    public networkManager: NetworkManager = NetworkManager.getInstance();

    get roadMode(): boolean {
        return this.networkManager.roadMode;
    }

    set roadMode(value: boolean) {
        this.networkManager.roadMode = value;
    }

    private async roadModeChanged() {
        // first check if road mode is possible
        if (!this.syncModel.isOfflineAvailable || this.syncModel.isSyncing) {
            this.roadMode = false;
            await this.$bvModal.msgBoxOk(this.$t('RoadModeErrorNoSync') as string, {
                okVariant: 'outline-primary',
                centered: true
            });
            return;
        }
        // set roadmode
        if (this.roadMode) {
            await this.networkManager.setRoadModeOn();
            if (this.$router.currentRoute.name !== RoutingIdentifier.CONTENT_OVERVIEW) {
                await this.$router.push({name: RoutingIdentifier.CONTENT_OVERVIEW});
            }
            toastManager.showToast(i18n.t('RoadModeStartPopup') as string, ToastType.SUCCESS);
        } else {
            await this.networkManager.setRoadModeOff();
            toastManager.showToast(i18n.t('RoadModeStopPopup') as string, ToastType.SUCCESS);
        }
    }

    public mounted() {
        if (this.roadMode) {
            toastManager.showToast(i18n.t('RoadModeRememberPopup') as string, ToastType.DANGER);
        }
    }

}
</script>
