<template>
  <div class="edit-content-file-box">

    <div v-if="viewableTeamsLoaded && showViewableTeams" class="mb-3">
      <p class="mb-2">{{ $t('MakeFolderVisible') }}:</p>
      <div class="viewable-teams">
        <b-form-checkbox v-for="team in shareableTeams" :key="team.teamID"
                         :value="team.teamID" @input="_onFormInput"
                         v-model="contentFolder.viewableTeams" class="mr-3 mb-1">
          {{ team.displayName }}
        </b-form-checkbox>
      </div>
    </div>

  </div>
</template>


<script lang="ts">
import {Component, Prop, Vue} from "vue-property-decorator";
import AppUserModel from "@/project/user/_model/AppUserModel";
import ContentFolderModel from "@/content/_model/ContentFolderModel";
import contentFolderController from "@/content/_controller/ContentFolderController";
import {ITeamOutlineDto} from "@/team/_model/team.dto";
import {RIGHTS} from "@/team/_model/role.constants";
import NetworkManager from "@/_controller/NetworkManager";

@Component({
    components: {
    },
})
export default class ShareContentFolderWithTeamsBox extends Vue {

    @Prop() private contentFolder!: ContentFolderModel;

    private shareableTeams: ITeamOutlineDto[] = AppUserModel.getInstance().shareableTeams;
    private viewableTeamsLoaded: boolean = false;
    private showViewableTeams: boolean = AppUserModel.getInstance().rights.indexOf(RIGHTS.MANAGE_TEAM_FOLDERS.identifier) >= 0;

    public networkManager: NetworkManager = NetworkManager.getInstance();

    public async mounted() {
        if (!this.contentFolder.viewableTeams) {
            this.viewableTeamsLoaded = await contentFolderController.getViewableTeams(this.contentFolder);
        } else {
            this.viewableTeamsLoaded = true;
        }
    }

    private _onFormInput(p_e: Event | null) {
        if (this.contentFolder) {
            this.contentFolder.hasChanges = true;
        }
    }

}
</script>
