<script lang="ts">
    import {Component, Mixins, Prop, Vue, Watch} from 'vue-property-decorator';
    import {
        IAnalyticsActionCfgDto,
        IAnalyticsActionEvolutionGraphWidgetCfgDto,
        IAnalyticsActionEvolutionGraphWidgetDataDto, IAnalyticsContentTypeTotalsDto, IAnalyticsContentTypeTotalSerieDto,
        IAnalyticsGraphDatePointDto,
        IAnalyticsSubjectDto,
        IAnalyticsSubjectListWithActionsWidgetCfgDto,
        IAnalyticsSubjectListWithActionsWidgetDataDto,
        IAnalyticsUsageOverviewGraphDto
    } from "@/analytics/_model/analytics.dto";
    import languageManager, {IMultiLangString} from "@/__libs/language_manager/LanguageManager";
    import AppUserModel from "@/project/user/_model/AppUserModel";
    import {
        ANALYTICS_LINE_GRAPH_OPTIONS,
        LINE_DATASET_COLORS,
        AnalyticsValueDisplayType, PIE_DATASET_COLORS
    } from "@/analytics/_model/analytics.constants";
    import {Doughnut} from "vue-chartjs";
    import ChartJsPluginDataLabels from 'chartjs-plugin-datalabels';
    import {ChartDataSets, ChartOptions} from "chart.js";
    import AnalyticsModel from "@/analytics/_model/AnalyticsModel";
    import AssetFolderModel from "@/asset_folder/_model/AssetFolderModel";
    import assetFolderController from "@/asset_folder/_controller/AssetFolderController";
    import i18n from "@/__plugins/i18n";

    @Component({
        components: {ChartJsPluginDataLabels}
    })
    export default class AnalyticsContentTypeTotalsGraph extends Mixins(Doughnut)
    {

        //---------------------------------
        // Vue Component props
        //---------------------------------

        @Prop() private contentTypeTotalsDto!:IAnalyticsContentTypeTotalsDto;

        //---------------------------------
        // Vue Component data
        //---------------------------------

        private isMounted:boolean = false;

        //---------------------------------
        // Vue Computed properties
        //---------------------------------



        //---------------------------------
        // Public / lifecycle methods
        //---------------------------------

        public mounted()
        {
            this.isMounted = true;
            this._renderChart();
        }



        //---------------------------------
        // Private / helper methods
        //---------------------------------

        private _renderChart()
        {
            const typeLabels:string[] = [];
            for (let i = 0; i < this.contentTypeTotalsDto.presented.contentTypes.length; i++)
            {
                typeLabels.push(i18n.tc(this.contentTypeTotalsDto.presented.contentTypes[i]))
            }

            const options:ChartOptions =
                {
                    tooltips: {
                        callbacks: {
                            label: function (tooltipItem:any, data:any) {
                                let dataset = data.datasets[tooltipItem.datasetIndex];
                                let index = tooltipItem.index;
                                return dataset.label + " " + i18n.tc(dataset.labels[index]) + ": " + dataset.data[index];
                            }
                        }
                    }
                };

            const dataSets:ChartDataSets[] = [];
            let colorIndex:number = 0;
            for (let action in this.contentTypeTotalsDto)
            {
                if (this.contentTypeTotalsDto.hasOwnProperty(action))
                {
                    const actionSerie:IAnalyticsContentTypeTotalSerieDto = (this.contentTypeTotalsDto as any)[action];
                    const dataSet:any = {
                        label          : i18n.tc(action),
                        labels         : actionSerie.contentTypes,
                        data           : actionSerie.totals,
                        backgroundColor: PIE_DATASET_COLORS
                    };
                    colorIndex++;
                    dataSets.push(dataSet);
                }
            }

            this.renderChart({datasets: dataSets, labels: typeLabels}, options)
        }


        @Watch('usageOverviewDto', {immediate: true, deep: false})
        private _onUsageOverviewDtoChange()
        {
            if (this.isMounted)
            {
                this._renderChart();
            }
        }


    }
</script>

