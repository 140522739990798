<template>
  <div class="box box--full-height list-overview asset-folder-detail">

    <transition name="fade">
      <div v-if="!selState.selected"
           class="empty-message">
        {{ $t('AssetManagementSelectFolder') }}
      </div>
    </transition>

    <transition name="fade">
      <div v-if="selState.selected" :key="selState.selected.ID" class="box-wrapper" v-cloak @drop.prevent="_onDropFile"
           @dragover.prevent @dragenter="_onDragOver" @dragleave="_onDragStop" ref="dropArea">

        <div class="list-overview-filters">
          <b-input-group>
            <template #prepend>
              <b-input-group-text><i class="fas fa-search"></i></b-input-group-text>
            </template>
            <b-form-input v-model="selState.fileSearchFilter"
                          :placeholder="$t('AssetManagementSearchFile')"></b-form-input>
          </b-input-group>
        </div>

        <label class="list-overview-button btn btn-primary btn-block" v-if="isEditable">
          {{ $t('AssetManagmentAddAssetButton') }}
          <input type="file" ref="selectFileBtn" multiple
                 v-on:change="_onSelectFileBtn()" hidden>
        </label>

        <b-form-group class="asset-folder-detail__name" v-if="isEditable"
                      :label="$t('Name')">
          <b-form-input v-model="validationAssetFolderName"
                        size="lg"
                        :state="_calculateInputState($v.validationAssetFolderName)"
                        @input="assetFolderName = _onFormValidationInput($v.validationAssetFolderName, assetFolderName)"
                        tabindex="1"></b-form-input>
          <b-form-invalid-feedback>
                <span class="d-block"
                      v-if="!$v.validationAssetFolderName.required">{{ $t('FieldRequiredSimple') }}</span>
            <span class="d-block" v-if="!$v.validationAssetFolderName.minLength">{{
                $t('MinimumFieldLengthSimple', [3])
              }}</span>
          </b-form-invalid-feedback>
        </b-form-group>

        <div class="list-overview-items">
          <AssetFileItemRenderer
              v-for="assetFile in filteredAssetFiles"
              :assetFile="assetFile"
              :key="assetFile.assetFileID"
              v-on:onClick="_onAssetFileClick"
              :selState="selState"
          />
        </div>

        <div v-if="assetFiles.length === 0" class="empty-message">
          {{ $t('AssetManagementEmpty') }}
        </div>

        <transition name="fade">
          <div class="drop-area" v-if="showDropIndication">
            <i class="fas fa-plus-circle fa-2x"></i>
          </div>
        </transition>

      </div>
    </transition>

  </div>
</template>


<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import assetFolderController from "@/asset_folder/_controller/AssetFolderController";
import AssetFolderListModel from "@/asset_folder/_model/AssetFolderListModel";
import languageManager from "@/__libs/language_manager/LanguageManager";
import AppUserModel from "@/project/user/_model/AppUserModel";
import AssetFolderSelectionState from "@/asset_folder/_model/AssetFolderSelectionState";
import AssetFileItemRenderer from "@/asset_folder/_view/AssetFileItemRenderer.vue";
import AssetFileModel from "@/asset_folder/_model/AssetFileModel";
import {FileType} from "@/asset_folder/_model/asset_folder.constants";
import FileUtil from "@/__libs/utility/FileUtil";
import {RightAction} from "@/team/_model/role.constants";
import fileSize from "filesize";
import {minLength, required} from "vuelidate/lib/validators";
import AssetFolderModel from "@/asset_folder/_model/AssetFolderModel";
import AudienceModel from "@/audience/_model/AudienceModel";
import audienceController from "@/audience/_controller/AudienceController";

@Component({
    components: {
        AssetFileItemRenderer
    },
    validations: {
        validationAssetFolderName: {
            required,
            minLength: minLength(3)
        }
    }
})
export default class AssetFolderDetail extends Vue {

    @Prop() private selState!: AssetFolderSelectionState;

    public validationAssetFolderName: string = '';
    public showDropIndication: boolean = false;
    public dropAreaCounter: number = 0;

    get assetFolder(): AssetFolderModel {
        return this.selState.selected!;
    }

    get assetFolderName() {
        return languageManager.getTranslationForValue<string>(this.assetFolder.name, AppUserModel.getInstance().langCode)
    }

    set assetFolderName(value: string) {
        this.assetFolder.name[AppUserModel.getInstance().langCode] = value;
    }

    get assetFiles(): AssetFileModel[] {
        if (this.selState.selected) {
            return this.selState.selected.assetFiles
        }
        return [];
    }

    get filteredAssetFiles() {
        const doSearchFilter: boolean = this.selState.fileSearchFilter.length > 1;
        const doFileTypeFilter: boolean = this.selState.allowedFileTypes.length > 0;
        const doFileTargetTypeFilter: boolean = this.selState.allowedTargetType !== null;

        if (doSearchFilter || doFileTypeFilter || doFileTargetTypeFilter) {
            return this.assetFiles.filter(assetFile => {
                let isSearchIncluded: boolean = !doSearchFilter;
                let isFileTypeIncluded: boolean = !doFileTypeFilter;
                let isFileTargetTypeIncluded: boolean = !doFileTargetTypeFilter;

                if (doSearchFilter) {
                    isSearchIncluded = languageManager
                      .getTranslationForValue<string>(assetFile.name, AppUserModel.getInstance().langCode)
                      .toLowerCase()
                      .indexOf(this.selState.fileSearchFilter.toLowerCase()) > -1;
                }
                if (doFileTypeFilter) {
                    isFileTypeIncluded = this.selState.allowedFileTypes.indexOf(assetFile.fileType) > -1;
                }
                if (doFileTargetTypeFilter) {
                    isFileTargetTypeIncluded = assetFile.targetTypes.indexOf(this.selState.allowedTargetType!) > -1;
                }

                return (isSearchIncluded && isFileTargetTypeIncluded && isFileTypeIncluded);
            });
        } else {
            return this.assetFiles;
        }
    }

    get inputAccept(): string {
        return AssetFolderListModel.getExtensionsForFileTypes(this.selState.allowedFileTypes).join();
    }

    get isEditable(): boolean {
        return this.selState.selected!.rightActions.indexOf(RightAction.EDIT) >= 0;
    }

    public mounted() {
        this._setInputAccept();
    }

    private _calculateInputState(inputValidator: any): any {
        return inputValidator.$invalid ? false : null;
    }

    private _onFormValidationInput(inputValidator: any, value: any): void {
        if (!inputValidator.$invalid && this.assetFolderName) {
            value = inputValidator.$model;
            this._onFormInput(null);
        }
        return value;
    }

    private _onFormInput(p_e: Event | null) {
        this.assetFolder.hasChanges = true;
    }

    private _onSelectFileBtn(p_e: Event) {
        const selectFileBtn: HTMLInputElement = this.$refs.selectFileBtn as HTMLInputElement;
        if (selectFileBtn.files && selectFileBtn.files.length > 0 && this.selState.selected) {
            this.addFiles(selectFileBtn.files);
        }
    }

    private _onDropFile(e: DragEvent) {
        if (this.isEditable) {
            if (e.dataTransfer && e.dataTransfer.files && e.dataTransfer.files.length > 0 && this.selState.selected) {
                this.addFiles(e.dataTransfer.files);
            }
            this._onDragStop(e);
        }
    }

    private async addFiles(p_files: FileList) {
        if (await this.checkValidFilesSelect(p_files)) {
            await assetFolderController.addFile(this.selState.selected!, p_files, this.selState);
        }
    }

    //todo: WARNING: this code is duplicated in AssetFolderItemRenderer
    private async checkValidFilesSelect(p_files: FileList): Promise<boolean> {
        if (p_files.length > AssetFolderListModel.MAX_SIMULTANEOUS_UPLOADS) {
            await this.$bvModal.msgBoxOk(this.$t('MaxSimultaneousUploads', [AssetFolderListModel.MAX_SIMULTANEOUS_UPLOADS]) as string, {centered: true});

            return false;
        } else {
            for (let i = 0; i < p_files.length; i++) {
                const file: File | null = p_files.item(i);

                if (file) {
                    const fileType: FileType = AssetFolderListModel.getFileTypeForExtension(FileUtil.getFileExtension(file.name));
                    if (fileType > FileType.UNKNOWN) {
                        if (file.size > AppUserModel.getInstance().project.tierConfig.maxFileSize) {
                            await this.$bvModal.msgBoxOk(this.$t('ErrorMaxSize', [fileSize(AppUserModel.getInstance().project.tierConfig.maxFileSize)]) as string, {centered: true});
                            return false;
                        }
                    } else {
                        await this.$bvModal.msgBoxOk(this.$t('ErrorFileType', [this.inputAccept]) as string, {centered: true});
                        return false;
                    }
                }
            }
        }
        return true;
    }

    @Watch('selState', {immediate: true, deep: true})
    private _onSelStateChange() {
        if (this.selState && this.selState.selected) {
            this.validationAssetFolderName = this.assetFolderName;
        }
    }

    private _onDragOver(e: DragEvent) {
        this.dropAreaCounter++;
        if (this.isEditable) {
            this.showDropIndication = true;
        }
    }

    private _onDragStop(e: DragEvent) {
        this.dropAreaCounter--;
        if (this.dropAreaCounter === 0) {
            this.showDropIndication = false;
        }
    }

    private async _onAssetFileClick(p_assetFile: AssetFileModel, p_e: Event) {
        this.selState.selectedFile = p_assetFile;
    }

    private _setInputAccept() {
        const selectFileBtn: HTMLInputElement = this.$refs.selectFileBtn as HTMLInputElement;
        if (selectFileBtn) {
            selectFileBtn.accept = this.inputAccept;
        }
    }

}
</script>

