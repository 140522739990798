import {SortingType} from "@/_model/app.constants";

export default class SelectionState<T>
{


    protected _selected:T | null = null;
    get selected():T | null
    {
        return this._selected;
    }

    set selected(value:T | null)
    {
        this._previousSelected = this._selected;
        this._selected = value;
    }


    protected _previousSelected:T | null = null;
    get previousSelected():T | null
    {
        return this._previousSelected;
    }


    private _isMultiSelect:boolean = false;
    get isMultiSelect():boolean
    {
        return this._isMultiSelect;
    }

    set isMultiSelect(value:boolean)
    {
        this._isMultiSelect = value;
        this._selecteds = [];
        if (this._isMultiSelect && this.selected)
        {
            this._selecteds.push(this.selected)
        }
    }

    get hasMultipleSelected():boolean
    {
        return this._selecteds.length > 1;
    }

    private _selecteds:T[] = [];
    get selecteds():T[]
    {
        return this._selecteds;
    }

    public activeSorting:SortingType = SortingType.NEWEST_FIRST;

    public propsFilter:string[] = [];

    public searchFilter:string = "";



    //---------------------------------
    // Public Methods
    //---------------------------------

    //generic function to decide action based on whether multiselect is true or false, usually called by an itemrenderer
    public setSelected(p_selected:T | null)
    {
        if (this._isMultiSelect)
        {
            if (p_selected !== null)
            {
                this.toggleSelected(p_selected);
            }
        }
        else
        {
            this.selected = p_selected;
        }
        // console.log(this.selected, this._selecteds);

    }

    //used to add or remove an item from the selecteds list when multiselect, or toggle a selected item on or of when single select
    public toggleSelected(p_toggled:T)
    {
        if (this._isMultiSelect)
        {
            const index:number = this._selecteds.indexOf(p_toggled);
            if (index >= 0)
            {
                //remove it
                this._selecteds.splice(index, 1);
                if (p_toggled === this.selected)
                {
                    this.selected = null;
                }
            }
            else
            {
                //add it
                this._selecteds.push(p_toggled);
                this.selected = p_toggled;
            }
        }
        else
        {
            this.selected = (p_toggled === this.selected) ? null : p_toggled;
        }

    }

    //check if a certain item is selected or not
    public checkSelected(p_item:T):boolean
    {
        if (this.isMultiSelect)
        {
            return this._selecteds.indexOf(p_item) >= 0;
        }
        else
        {
            return p_item === this._selected;
        }
    }

    //add to the selecteds if it isn't there already
    public addToSelecteds(p_item:T)
    {
        if (!this.checkSelected(p_item))
        {
            this._selecteds.push(p_item);
        }
    }

    //remove from the selecteds if it is present
    public removeFromSelecteds(p_item:T)
    {
        const index:number = this._selecteds.indexOf(p_item);
        if (index >= 0)
        {
            //remove it
            this._selecteds.splice(index, 1);
        }
    }

}
