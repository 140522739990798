<template>
    <div class="data-item-list">

        <div class="data-item-list__list">
            <button v-if="dataItemList.dataProvider.isEditable" class="btn btn-primary btn-block"
                    :class="{'mb-3': dataItemList.list.length > 0}"
                    @click="_onCreateDataItemBtnClick"
                    ref="addButton">
                {{addItemButtonLabel}}
            </button>
            <draggable v-model="dataItemList.list" :group="groupID" @start="drag=true" @end="drag=false"
                       @sort="_onSort">
                <DataItemRenderer
                        v-for="dataItem in dataItemList.list"
                        :dataItem="dataItem"
                        :key="dataItem.__reference"
                        v-on:onClick="_onDataItemClick"
                        :selState="selState"
                />
            </draggable>
        </div>

<!--      <transition name="fade">-->
        <div class="data-item-list__content" v-if="selState.selected" :style="{marginTop: contentOffset + 'px'}" :key="selState.selected.__id">

<!--            <div v-if="!selState.selected"-->
<!--                 class="placeholder-text text-secondary d-flex align-items-center justify-content-center w-100 h-100 py-4">-->
<!--                {{ $t('PleaseSelectItem') }}-->
<!--            </div>-->

<!--            <div v-if="selState.selected">-->
                <DataItem :dataItem="selState.selected"/>
<!--            </div>-->

        </div>
<!--      </transition>-->
    </div>
</template>


<script lang="ts">
    import {Component, Prop, Vue} from "vue-property-decorator";
    import DataItemModel from "@/data_tool/data_item/_model/DataItemModel";
    import {IDataItemDefinitionDto} from "@/data_tool/_model/data_tool.dto";
    import DataItemRenderer from "@/data_tool/data_item/_view/DataItemRenderer.vue";
    import SelectionState from "@/__libs/_model/SelectionState";
    import DataItem from "@/data_tool/data_item/_view/DataItem.vue";
    import DataProviderModel from "@/data_tool/data_provider/_model/DataProviderModel";
    import {EventBus} from "@/__libs/vue/EventBus";
    import {EventBusActions} from "@/_model/app.constants";
    import DataItemListModel from "@/data_tool/data_item/_model/DataItemListModel";
    import draggable from 'vuedraggable';
    import {v4 as uuidv4} from "uuid";
    import languageManager from "@/__libs/language_manager/LanguageManager";
    import AppUserModel from "@/project/user/_model/AppUserModel";


    @Component({
        components: {DataItemRenderer, DataItem, draggable}
    })
    export default class DataItemList extends Vue
    {
        //---------------------------------
        // Vue Component props
        //---------------------------------

        @Prop() private dataItemList!:DataItemListModel;


        //---------------------------------
        // Vue Component data
        //---------------------------------

        // public selState:SelectionState<DataItemModel> = new SelectionState<DataItemModel>();

        private groupID:string = uuidv4();

        //---------------------------------
        // Vue Computed properties
        //---------------------------------

        get selState()
        {
            return this.dataItemList.selState;
        }

        get addItemButtonLabel()
        {
            if (this.dataItemList.itemDefinition.addItemButtonLabel)
            {
              return languageManager.getTranslationForValue<string>(this.dataItemList.itemDefinition.addItemButtonLabel, AppUserModel.getInstance().langCode)
            }
            else
            {
                return this.$t("AddItem");
            }
        }

        get contentOffset(): number {
            return  (this.selState.element as HTMLElement).offsetTop - 5;
        }

        //---------------------------------
        // Public / lifecycle methods
        //---------------------------------

        public mounted()
        {
            // console.log(this.dataItemList, this.itemDefinition);
        }


        //---------------------------------
        // Private / helper methods
        //---------------------------------

      private async scrollSelectedItemInView() {
        await Vue.nextTick();
        this.selState.element.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'start'});
      }

        private async _onDataItemClick(p_dataItem:DataItemModel, p_e:Event)
        {
            this.selState.selected = p_dataItem;
          await this.scrollSelectedItemInView();
        }

        private async _onCreateDataItemBtnClick(p_e:Event)
        {
            //todo choice: create modal with name and id prop, or just add and invalidate (but problem with id then)

            const newDataItem:DataItemModel = new DataItemModel(this.dataItemList.itemDefinition, null, this.dataItemList.dataProvider, this.dataItemList);

            this.dataItemList.addItem(newDataItem); //todo: ask controller first

            this.selState.selected = newDataItem;

            await this.scrollSelectedItemInView();

            // this.$bvModal.show('create-dataProvider-box');
        }

        // private _onCreateFinish(p_createdDataProvider:DataProviderModel)
        // {
        //     this.$bvModal.hide('create-dataProvider-box');
        //     if (p_createdDataProvider)
        //     {
        //         this.selState.selected = p_createdDataProvider;
        //         EventBus.$emit(EventBusActions.ASK_EDIT_ENTITY, p_createdDataProvider);
        //     }
        // }

        private _onSort(e:Event)
        {
            this.dataItemList.sort();
        }

    }
</script>
