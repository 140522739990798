<template>
    <div class="data-item">

        <Property v-for="property in availableProperties"
                  :property="property"
                  :key="property.identifier"/>

    </div>
</template>


<script lang="ts">
    import {Component, Prop, Vue} from "vue-property-decorator";
    import DataItemModel from "@/data_tool/data_item/_model/DataItemModel";
    import Property from "@/data_tool/data_item/_view/Property.vue";
    import DataProviderEntryModel from "@/data_tool/data_provider/_model/DataProviderEntryModel";
    import AppUserModel from "@/project/user/_model/AppUserModel";
    import {RIGHTS} from "@/team/_model/role.constants";
    import PropertyModel from "@/data_tool/data_item/_model/PropertyModel";



    @Component({
        components: {Property}
    })
    export default class DataItem extends Vue
    {
        //---------------------------------
        // Vue Component props
        //---------------------------------

        @Prop() private dataItem!:DataItemModel;


        //---------------------------------
        // Vue Component data
        //---------------------------------



        //---------------------------------
        // Vue Computed properties
        //---------------------------------



        //---------------------------------
        // Public / lifecycle methods
        //---------------------------------

        public mounted()
        {
            // console.log(this.dataProviderEntry);
        }



        //---------------------------------
        // Private / helper methods
        //---------------------------------

        get availableProperties():PropertyModel[]
        {
            if (AppUserModel.getInstance().rights.indexOf(RIGHTS.HAS_DEVELOPER_ACCESS.identifier) >= 0)
            {
                return this.dataItem.properties;
            }
            else
            {
                return this.dataItem.properties.filter(p_property => p_property.propertyDefinition.onlyForDevelopers !== true);
            }
        }

    }
</script>
