<template>

  <div class="create-asset-folder">

    <transition name="fade">
      <div v-if="currentFormState === formState.CREATE_INPUT || currentFormState === formState.TRY_SUBMIT">
        <b-form @submit.stop.prevent="_onCreateAssetFolderBtnClick" novalidate>

          <b-form-group :label="$t('PlaceholderAssetFolderName')">
            <b-form-input v-model="assetFolderName"
                          :state="_calculateInputState($v.assetFolderName)"
                          tabindex="1"
                          autofocus></b-form-input>
            <b-form-invalid-feedback>
                        <span class="d-block"
                              v-if="!$v.assetFolderName.required">{{ $t('FieldRequiredSimple') }}</span>
              <span class="d-block" v-if="!$v.assetFolderName.minLength">{{
                  $t('MinimumFieldLengthSimple', [3])
                }}</span>
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group :label="$t('PlaceholderIdentifier')" v-if="showIdentifier">
            <b-input-group :prepend="`${projectIdentifier}-`">
              <b-form-input v-model="identifierInput"></b-form-input>
            </b-input-group>
          </b-form-group>

          <div class="modal-box-buttons">
            <b-button variant="secondary" @click="_onCancelBtnClick" tabindex="4">
              {{ $t('Cancel') }}
            </b-button>
            <b-button type="submit" variant="primary"
                      :disabled="currentFormState === formState.TRY_SUBMIT && $v.$invalid" tabindex="5">{{
                $t('AssetManagementCreateFolder')
              }}
            </b-button>
          </div>

        </b-form>
      </div>
    </transition>

    <transition name="fade">
      <div v-if="currentFormState === formState.CREATING_REMOTE" class="text-center">
        <b-spinner variant="dark" class="my-5"></b-spinner>
      </div>
    </transition>

    <transition name="fade">
      <div v-if="currentFormState === formState.REMOTE_ERROR">
        <p class="mb-4 text-danger">{{ $t('AudienceCreationFailed') }}</p>
        <div class="d-flex justify-content-end">
          <button class="btn btn-secondary mr-2" @click="_onCancelBtnClick">{{ $t('Cancel') }}</button>
          <button class="btn btn-primary" @click="_onCreateAudienceBtnClick">{{ $t('Retry') }}</button>
        </div>
      </div>
    </transition>

  </div>

</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import languageManager from "@/__libs/language_manager/LanguageManager";
import AppUserModel from "@/project/user/_model/AppUserModel";
import assetFolderListController from "@/asset_folder/_controller/AssetFolderListController";
import AssetFolderModel from "@/asset_folder/_model/AssetFolderModel";
import {email, minLength, required} from "vuelidate/lib/validators";
import {RIGHTS} from "@/team/_model/role.constants";

enum FormState {

    CREATE_INPUT,
    TRY_SUBMIT,
    CREATING_REMOTE,
    REMOTE_CREATED,
    REMOTE_ERROR
}

@Component({
    validations: {
        assetFolderName: {
            required,
            minLength: minLength(3)
        }
    }
})
export default class CreateAssetFolderBox extends Vue {

    //---------------------------------
    // Vue Component props
    //---------------------------------

    @Prop() public boxId!: string;

    //---------------------------------
    // Vue Component data
    //---------------------------------

    private assetFolder: AssetFolderModel = new AssetFolderModel();

    private formState: typeof FormState = FormState;

    private currentFormState: FormState = FormState.CREATE_INPUT;

    private showIdentifier: boolean = AppUserModel.getInstance().rights.indexOf(RIGHTS.HAS_DEVELOPER_ACCESS.identifier) >= 0;

    private projectIdentifier: string = AppUserModel.getInstance().project.identifier;

    private identifierInput: string = "";

    //---------------------------------
    // Vue Computed properties
    //---------------------------------

    get assetFolderName() {
        return languageManager.getTranslationForValue<string>(this.assetFolder.name, AppUserModel.getInstance().langCode)
    }

    set assetFolderName(value: string) {
        //this.assetFolder.name[AppUserModel.getInstance().langCode] = value;
        Vue.set(this.assetFolder.name, AppUserModel.getInstance().langCode, value);
    }

    //---------------------------------
    // Public / lifecycle methods
    //---------------------------------

    //---------------------------------
    // Private / helper methods
    //---------------------------------

    private _calculateInputState(inputValidator: any): any {
        return this.currentFormState === FormState.TRY_SUBMIT && inputValidator.$invalid ? false : null;
    }

    private async _onCreateAssetFolderBtnClick(p_e: Event) {
        this.currentFormState = FormState.TRY_SUBMIT;

        if (!this.$v.$invalid) {
            this.currentFormState = FormState.CREATING_REMOTE;
            if (this.identifierInput.length > 0) {
                this.assetFolder.identifier = this.projectIdentifier + "-" + this.identifierInput;
            }

            const createSucceeded: boolean = await assetFolderListController.createEntity(this.assetFolder);
            if (createSucceeded) {
                this.$emit('onFinish', this.assetFolder);
            } else {
                this.currentFormState = FormState.REMOTE_ERROR;
            }
        }
    }

    private async _onCancelBtnClick(p_e: Event) {
        this.$emit('onFinish', null);
    }

}
</script>
