<template>
  <div class="sub-page columns audiences-page">
      <AudiencesOverview></AudiencesOverview>

<!--      <AudienceDetail></AudienceDetail>-->

      <div class="box box--full-height box--with-submenu audiences-page__detail-box">
        <div class="sub-menu-bar">
          <div class="sub-menu">
            <router-link :to="{ name: routingIdentifiers.AUDIENCE_DETAIL }" class="sub-menu-item">{{ $t('AudienceDetailTitle') }}</router-link>
            <router-link :to="{ name: routingIdentifiers.AUDIENCE_SHARE }" class="sub-menu-item">{{ $t('DashboardTabTitleShared') }}</router-link>
            <router-link :to="{ name: routingIdentifiers.AUDIENCE_TRACK }" class="sub-menu-item">{{ $t('DashboardTabTitleTrack') }}</router-link>
          </div>
        </div>

        <transition name="fade" mode="out-in">
          <router-view></router-view>
        </transition>
      </div>

  </div>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import AudienceSummary from "@/audience/_view/AudienceSummary.vue";
import AudienceDetail from "@/audience/_view/AudienceDetail.vue";
import AudiencesOverview from "@/audience/_view/AudiencesOverview.vue";
import {RoutingIdentifier} from "@/router";

@Component({
    components: {AudiencesOverview, AudienceDetail}
})
export default class AudiencesPage extends Vue {
    private routingIdentifiers = RoutingIdentifier;
}
</script>
