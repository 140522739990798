import {IDataItemDefinitionDto, IDataURIDto, IXlsColumn, IXlsImportResult} from "@/data_tool/_model/data_tool.dto";
import {DataModel} from "@/data_tool/_model/DataModel";
import DataProviderModel from "@/data_tool/data_provider/_model/DataProviderModel";
import {DtoType, StorageScope} from "@/_model/app.constants";
import DataItemModel from "@/data_tool/data_item/_model/DataItemModel";
import Dictionary from "@/__libs/_model/Dictionary";
import DataItemListModel from "@/data_tool/data_item/_model/DataItemListModel";
import DataUtil from "@/data_tool/_controller/DataUtil";
import {DataResourceScope} from "@/data_tool/_model/data_tool.constants";
import DataItemListSelectionState from "@/data_tool/data_item/_model/DataItemListSelectionState";

export default class DataItemDictionaryModel extends DataModel
{
    public itemKeyDefinition:IDataItemDefinitionDto; //the definition for all the item keys in this dictionary
    public itemDefinition:IDataItemDefinitionDto; //the definition for all the item values in this dictionary

    protected includeInLogPath:boolean = false;

    public values:Dictionary<DataItemModel, string> = new Dictionary<DataItemModel, string>();

    private _dataProviderURI:string | null = null; // the dataprovider used to populate the keys

    public selState:DataItemListSelectionState<DataItemModel> = new DataItemListSelectionState<DataItemModel>();

    get dataProviderURI():string | null
    {
        return this._dataProviderURI;
    }

    set dataProviderURI(value:string | null)
    {
        if (value !== this._dataProviderURI)
        {
            this._dataProviderURI = value;
            //clear the dictionary, trigger validation
            // this.values = new Dictionary<DataItemModel, string>();
            if (this.__parent)
            {
                this.invalidChildren = [];
                this.__parent.childValidityChange(true, this);
                this.__parent.validate();
            }
            this.dataProvider.hasChanges = true;
        }
    }


    public dataItemList?:DataItemListModel; //todo problem: the list of the key items (but can only be fetched through DataProviderController.fetchDataItemList, so we need to fetch all dataitemlist in the beginning)

    //---------------------------------
    // Constructor
    //---------------------------------

    constructor(p_itemKeyDefinition:IDataItemDefinitionDto, p_itemDefinition:IDataItemDefinitionDto, p_data:any, p_dataProvider:DataProviderModel, p_parent:DataModel | null)
    {
        super(p_dataProvider, p_parent);
        this.itemKeyDefinition = p_itemKeyDefinition;
        this.itemDefinition = p_itemDefinition;
        this.build(p_data);
    }

    //---------------------------------
    // Public Methods
    //---------------------------------

    public build(p_data:any)
    {
        if (p_data)
        {
            if (p_data.dataProviderURI)
            {
                this._dataProviderURI = p_data.dataProviderURI;
            }
            if (p_data.values)
            {
                for (let key in p_data.values)
                {
                    if (p_data.values.hasOwnProperty(key))
                    {
                        this.values.setItem(new DataItemModel(this.itemDefinition, p_data.values[key], this.dataProvider, this), key);
                    }
                }
            }
        }

    }


    public mapToDto(p_dtoType:DtoType):any
    {
        if (p_dtoType === DtoType.BODY)
        {
            const dtoValues:any = {};

            //todo: or loop the dataItemList, because if an item gets deleted, it stays in the dictionary (but maybe this is good?)
            for (let key in this.values.dict)
            {
                if (this.values.dict.hasOwnProperty(key))
                {
                    dtoValues[key] = (this.values.dict[key] as DataItemModel).mapToDto(DtoType.BODY);
                }
            }

            return {
                dataProviderURI: this._dataProviderURI,
                values         : dtoValues
            }
        }
    }

    //return the value if it exists, are create a new one if it doesn't
    public getValue(p_keyID:string):DataItemModel
    {
        const dataItem:DataItemModel | null = this.values.getItem(p_keyID);
        if (dataItem)
        {
            return dataItem;
        }
        else
        {
            const newDataItem:DataItemModel = new DataItemModel(this.itemDefinition, null, this.dataProvider, this);
            this.values.setItem(newDataItem, p_keyID);
            return newDataItem;
        }
    }

    public mapToRows(p_rows:any, p_columnPrefix:string, p_startRowIndex:number)
    {

        if (this._dataProviderURI)
        {
            //get the dataItemList
            const uri:IDataURIDto = DataUtil.resolveURI(this._dataProviderURI);
            const dtpID:string = uri.path;
            const dataItemListKey:string = `${dtpID}#${this.itemKeyDefinition.identifier}`;
            const dataItemList:DataItemListModel = this.dataProvider.dataItemListsDict.getItem(dataItemListKey);
            // console.log( dataItemList);
            //


            if (!p_rows[p_startRowIndex])
            {
                p_rows[p_startRowIndex] = {};
            }
            const startRow = p_rows[p_startRowIndex];
            startRow[`${p_columnPrefix}:chosen list`] = this._dataProviderURI;

            for (let key in this.values.dict)
            {
                //todo:  loop the dataItemList instead, so we can show the names next to the _id's (but first fetch all dataItemLists after main build)
                if (this.values.dict.hasOwnProperty(key))
                {
                    if (!p_rows[p_startRowIndex])
                    {
                        p_rows[p_startRowIndex] = {};
                    }

                    p_rows[p_startRowIndex][`${p_columnPrefix}-${this.itemDefinition.identifier}__id`] =  key;

                    const valueItem:DataItemModel = this.values.dict[key] as DataItemModel;
                    valueItem.mapToRows(p_rows, p_columnPrefix + "-", p_startRowIndex);

                    const neededRows:number = valueItem.getNeededRows();
                    p_startRowIndex = Math.max(p_startRowIndex,  neededRows) + 1;
                }
            }

        }
    }

    public getNeededRows():number
    {
        let neededRows:number = 0;
        for (let key in this.values.dict)
        {
            if (this.values.dict.hasOwnProperty(key))
            {
                const valueItem:DataItemModel = this.values.dict[key] as DataItemModel;

                neededRows += Math.max(1, valueItem.getNeededRows());
            }
        }
        // console.log(this.logModelPath(), neededRows);
        return neededRows;
    }



    public static mapFromRows(p_dataProvider:DataProviderModel, p_dataItemDefinition:IDataItemDefinitionDto, p_rows:any[], p_dataProviderURIcolumn:IXlsColumn, p_columns:IXlsColumn[], p_level:number, p_columnPrefix:string, p_result:IXlsImportResult)
    {
        const dto:any = {values: {}};

        dto.dataProviderURI = p_rows[0][p_dataProviderURIcolumn.identifier]; // todo validate

        // console.log(p_rows);

        //try to find out which rows consist of 1 item in this list, and whether it is an existing record(contains an __id), or a new one

        const items:any[] = []; //an array of objects to keep the rows of each item in
        let item:any;


        //the _id column is found on this level, the p_dataProviderURIcolumn is a level higher, because it is on the level of the property this dictionary is in
        const idColumn:IXlsColumn | undefined = p_columns.find(p_column => p_column.level === p_level && p_column.isIDcolumn);

        if (idColumn)
        {
            for (let i = 0; i < p_rows.length; i++)
            {
                const row = p_rows[i];
                //if you find an __id which is filled in, we can assume that it is the start of a new item
                const cellValue:any = row[idColumn.identifier];
                if (cellValue !== null && cellValue !== undefined) //if there is a value in this cell...
                {
                    item = {rows: []}; // it's the beginning of a new item //todo validate if other columns are blank (only columns on this level could be filled in)
                    items.push(item);
                }

                if (item)
                {
                    item.rows.push(row);
                }
                else
                {
                    // p_result.isSuccessful = false;
                    // p_result.fatalMessage = {type:"BLANK_ROW_FOUND"};
                    // console.log("BLANK_ROW_FOUND", row, "was looking in columns:", logColumnNames, p_level);
                    // return;
                }
            }


            // console.log(items);


            //for each key item found, create a new DataItemModel and pass on the rows
            for (let i = 0; i < items.length; i++)
            {
                const item__id:string = items[i].rows[0][idColumn.identifier]; //the _id value will always be in the first row
                dto.values[item__id] = DataItemModel.mapFromRows(p_dataProvider, p_dataItemDefinition, items[i].rows, p_columns, p_level, p_columnPrefix, p_result);
            }
        }
        return dto;
    }

}
