<template>
  <div class="main-sub-page main-sub-page--with-submenu targets-page">

    <div class="sub-menu-bar">
      <div class="sub-menu">
        <router-link :to="{ name: routingIdentifiers.AUDIENCES }" class="sub-menu-item" :class="{ active: isActive(routingIdentifiers.AUDIENCES) }">{{ $t('MainMenuAudiences') }}</router-link>
        <router-link :to="{ name: routingIdentifiers.COMPANIES }" class="sub-menu-item"
                     v-if="showCompanies">{{ $t('Companies') }}</router-link>
      </div>
    </div>

    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>

  </div>
</template>

<script lang="ts">
import {Component, Vue, Watch} from "vue-property-decorator";
import CreateTeamUserBox from "@/team/_view/CreateTeamUserBox.vue";
import AppUserModel from "@/project/user/_model/AppUserModel";
import {RoutingIdentifier} from "@/router";
import roleController from "@/team/_controller/RoleController";
import {RIGHTS} from "@/team/_model/role.constants";
import {IProjectCustomPageDto} from "@/project/_model/project.dto";
import languageManager from "@/__libs/language_manager/LanguageManager";
import {Route} from "vue-router";

@Component({
    components: {}
})
export default class TargetsPage extends Vue {

    private routingIdentifiers = RoutingIdentifier;
    public appUser: AppUserModel = AppUserModel.getInstance();
    // @ts-ignore
    public currentRoute: Route = {};

    get showCompanies(): boolean {
        return AppUserModel.getInstance().rights.indexOf(RIGHTS.VIEW_COMPANIES.identifier) >= 0;
    }

    @Watch('$route', {immediate: true, deep: true})
    onUrlChange(route: Route) {
        this.currentRoute = route;
    }

    public isActive(routeIdentifier: string) {
        if (this.currentRoute) {
            return this.currentRoute.matched.some(route => route.name === routeIdentifier);
        }
        return false;
    }

}
</script>
