<template>

  <div class="entity-info-box">

    <table class="table table-sm table-striped table-borderless">
      <tbody>
      <tr>
        <th scope="row">{{ $t('CreatedBy') }}</th>
        <td>{{ entity.createdByUserName }} ({{ entity.createdByTeamName }})

          <b-button v-if="allowTransferEntity" variant="outline-secondary" block
                    @click="_onTransferEntityBtnClick">
            {{ $t('Transfer') }}
          </b-button>

        </td>
      </tr>
      <tr>
        <th scope="row">{{ $t('CreatedDate') }}</th>
        <td>{{ $d(new Date(entity.createdDate), 'long') }}</td>
      </tr>
      <tr>
        <th scope="row">{{ $t('LastUpdateOn') }}</th>
        <td>{{ $d(new Date(entity.updatedDate), 'long') }}</td>
      </tr>
      <tr>
        <th scope="row">{{ $t('ID') }}</th>
        <td>{{ entity.ID }}</td>
      </tr>
      <tr>
        <th scope="row">{{ $t('Version') }}</th>
        <td>{{ entity.version }}</td>
      </tr>
      </tbody>
    </table>

    <div v-if="showDeeplink" class="mt-4 mb-4">
      <b-input-group>
        <b-form-input :value="getDeeplink" ref="deepLink"></b-form-input>
        <b-input-group-append>
          <b-button variant="success" @click="_onCopyLinkBtnClick">{{
              $t('CopyLinkToContent')
            }}
          </b-button>
        </b-input-group-append>
      </b-input-group>
      <transition name="fade">
        <div v-if="currentCopyToClipboardState === copyToClipboardState.SUCCESS"
             class="text-success small mt-2">{{ $t('ClipboardSuccess') }}
        </div>
      </transition>
      <transition name="fade">
        <div v-if="currentCopyToClipboardState === copyToClipboardState.ERROR"
             class="text-danger small mt-2">{{ $t('ClipboardError') }}
        </div>
      </transition>
    </div>

    <div v-if="showFunnelStages" class="mb-3">
      funnel stages:
      <b-form-checkbox v-for="funnelStage in availableFunnelStages" :key="funnelStage.reference"
                       :value="funnelStage.reference"
                       @input="_onFormInput" v-model="entityAsContent.funnelStages"
                       :disabled="!online"
                       class="mr-3 mb-1">
        {{ translate(funnelStage.name) }}
      </b-form-checkbox>
    </div>


    <div class="d-flex justify-content-end">
      <b-button variant="secondary" @click="_onCloseBtnClick" tabindex="5">
        {{ $t('Ok') }}
      </b-button>
    </div>


    <b-modal id="entity-transfer-box" size="s" :title="$t('Transfer')" hide-footer>
      <EntityTransferBox v-on:onEntityTransfered="_onEntityTransfered" :entity="entity"></EntityTransferBox>
    </b-modal>

  </div>

</template>

<script lang="ts">
import ContentModel from '@/content/_model/ContentModel';
import {Component, Prop, Vue} from 'vue-property-decorator';
import EntityModel from "@/entity/_model/EntityModel";
import {EntityType} from "@/entity/_model/entity.constants";
import AppUserModel from "@/project/user/_model/AppUserModel";
import {RIGHTS} from "@/team/_model/role.constants";
import languageManager, {IMultiLangString} from "@/__libs/language_manager/LanguageManager";
import {IProjectFunnelStageDto} from "@/project/_model/project.dto";
import EntityTransferBox from "@/entity/_view/EntityTransferBox.vue";
import NetworkManager from "@/_controller/NetworkManager";

enum CopyToClipboardState {
    IDLE,
    SUCCESS,
    ERROR
}

@Component({
    components: {EntityTransferBox}
})
export default class EntityInfoBox extends Vue {

    @Prop() public entity!: EntityModel;

    private copyToClipboardState: typeof CopyToClipboardState = CopyToClipboardState;
    private currentCopyToClipboardState: CopyToClipboardState = CopyToClipboardState.IDLE;
    private availableFunnelStages: IProjectFunnelStageDto[] = AppUserModel.getInstance().project.config.funnelStages;
    public networkManager: NetworkManager = NetworkManager.getInstance();

    get online(): boolean {
        return false;
    }

    get entityAsContent(): ContentModel {
        return this.entity as ContentModel;
    }

    get showDeeplink(): boolean {
        const showDeeplink: boolean = AppUserModel.getInstance().rights.indexOf(RIGHTS.DEEPLINK_TO_CONTENT.identifier) >= 0;
        return showDeeplink && (this.entity.entityType === EntityType.PRESENTATION || this.entity.entityType === EntityType.CONTENT_APP);
    }

    get getDeeplink(): string {
        return `${window.location.origin}/?contentid=${this.entity.ID}`;
    }

    get showFunnelStages(): boolean {
        return this.entity.isEditable && (this.entity.entityType === EntityType.PRESENTATION || this.entity.entityType === EntityType.CONTENT_FILE || this.entity.entityType === EntityType.CONTENT_APP);
    }

    get allowTransferEntity(): boolean {
        return (AppUserModel.getInstance().rights.indexOf(RIGHTS.TRANSFER_ENTITIES.identifier) >= 0 && this.entity.entityType !== EntityType.AUDIENCE);
    }

    //---------------------------------
    // Public / lifecycle methods
    //---------------------------------

    mounted() {
    }

    //---------------------------------
    // Private / helper methods
    //---------------------------------

    private translate(p_value: IMultiLangString) {
        return languageManager.getTranslationForValue<string>(p_value, AppUserModel.getInstance().langCode)
    }

    private async _onCloseBtnClick(p_e: Event) {
        this.$emit('onFinish', null);
    }


    private _onCopyLinkBtnClick(p_e: Event) {
        // @ts-ignore
        this.$refs.deepLink.select();
        try {
            document.execCommand('copy');
            this.currentCopyToClipboardState = CopyToClipboardState.SUCCESS;
        } catch (err) {
            this.currentCopyToClipboardState = CopyToClipboardState.ERROR;
        }
        // @ts-ignore
        this.$refs.deepLink.blur();
    }

    private _onFormInput(p_e: Event | null) {
        if (this.entity) {
            this.entity.hasChanges = true;
        }
    }

    private async _onTransferEntityBtnClick(p_e: Event) {
        this.$bvModal.show('entity-transfer-box');
    }

    private _onEntityTransfered() {
        this.$bvModal.hide('entity-transfer-box');

    }

}
</script>
