import AssetFolderModel from "@/asset_folder/_model/AssetFolderModel";
import EntityListModel from "@/entity/_model/EntityListModel";
import AssetFolderSelectionState from "@/asset_folder/_model/AssetFolderSelectionState";
import {
    AssetFolderViewMode,
    FILE_TYPE_CONFIG,
    FileType, IFileTypeConfig
} from "@/asset_folder/_model/asset_folder.constants";
import AppUserModel from "@/project/user/_model/AppUserModel";

class AssetFolderListModel extends EntityListModel<AssetFolderModel>
{

    public pageSelState:AssetFolderSelectionState = new AssetFolderSelectionState(AssetFolderViewMode.PAGE);

    public pickerSelState:AssetFolderSelectionState = new AssetFolderSelectionState(AssetFolderViewMode.PICKER);
    
    public static MAX_SIMULTANEOUS_UPLOADS:number = 10;

    //---------------------------------
    // Singleton Constructor
    //---------------------------------

    private static _instance:AssetFolderListModel;

    constructor()
    {
        super();
        if (!AssetFolderListModel._instance)
        {
            AssetFolderListModel._instance = this;
        }
    }

    public static getInstance():AssetFolderListModel
    {
        if (!AssetFolderListModel._instance)
        {
            new AssetFolderListModel();
        }

        return AssetFolderListModel._instance;
    }


    //---------------------------------
    // Public Methods
    //---------------------------------



    public static getFileTypeForExtension(p_ext:string):FileType
    {
        for (let fileType in FILE_TYPE_CONFIG)
        {
            const fileTypeConfig:IFileTypeConfig = (FILE_TYPE_CONFIG as any)[fileType] as IFileTypeConfig;
            if (fileTypeConfig.fileExtensions.indexOf(p_ext.toLowerCase()) >= 0)
            {
                return Number(fileType);
            }
        }
        return FileType.UNKNOWN;
    }

    public static getDefaultPreviewImageUriForFileType(p_fileType:FileType)
    {
        return `assetFolder://asf-${AppUserModel.getInstance().project.identifier}-system-assets/file_icon_${FILE_TYPE_CONFIG[p_fileType].identifier.toLowerCase()}.png`;
    }

    public static getExtensionsForFileTypes(p_fileTypes:FileType[]):string[]
    {
        let extensions:string[] = [];
        for (let fileType in FILE_TYPE_CONFIG)
        {
            const fileTypeConfig:IFileTypeConfig = (FILE_TYPE_CONFIG as any)[fileType] as IFileTypeConfig;
            if(p_fileTypes.length === 0 || p_fileTypes.indexOf(Number(fileType)) > -1)
            {
                extensions = extensions.concat(fileTypeConfig.fileExtensions);
            }
        }
        return extensions;
    }

}

export default AssetFolderListModel;
