<template>
    <div class="analytics-topteams">

        <h5 class="mb-4">{{ $t('AnalyticsTopTeams') }}</h5>

        <b-table striped :items="analytics.topTeams" :fields="fields" :head-variant="'dark'" :borderless="true">
            <template v-slot:cell(createdDate)="data">
                {{ $d(new Date(data.value), 'short') }}
            </template>
            <template v-slot:cell(percentage)="data">
                <span v-if="data.value === 0">-</span>
                <span v-if="data.value > 0" class="text-success">+{{ data.value }}%</span>
                <span v-if="data.value < 0" class="text-danger">{{ data.value }}%</span>
            </template>
        </b-table>
    </div>
</template>


<script lang="ts">
    import {Component, Vue} from 'vue-property-decorator';
    import AnalyticsModel from "@/analytics/_model/AnalyticsModel";
    import AnalyticsUserEvolutionGraph from "@/analytics/_view/AnalyticsUserEvolutionGraph.vue";

    @Component({
        components: {},
    })
    export default class AnalyticsTopTeams extends Vue
    {
        //---------------------------------
        // Vue Component props
        //---------------------------------

        //---------------------------------
        // Vue Component data
        //---------------------------------

        //---------------------------------
        // Vue Computed properties
        //---------------------------------

        get analytics():AnalyticsModel {
            return AnalyticsModel.getInstance();
        }

        get fields():any[] {
            return [
                { key: 'displayName', label: this.$t('AnalyticsTopTeamsDisplayName') },
                { key: 'totalUsers', label: this.$t('AnalyticsTopTeamsTotalUsers') },
                { key: 'percentage', label: this.$t('AnalyticsEvolution') },
                { key: 'createdDate', label: this.$t('AnalyticsTopTeamsCreatedDate') }
            ];
        }

        //---------------------------------
        // Public / lifecycle methods
        //---------------------------------

        //---------------------------------
        // Private / helper methods
        //---------------------------------

    }
</script>
