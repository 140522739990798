export enum CobrowseRoomType
{
    PROJECT = "PROJECT",
    PORTAL = "PORTAL",
    PWA_CONTENT_COLLABORATION = "PWA_CONTENT_COLLABORATION",
    PWA_SYSTEM_SUPPORT = "PWA_SYSTEM_SUPPORT",
}

export enum RoomStatusType
{
    IDLE = "IDLE",
    ASKED = "ASKED",
    JOINED = "JOINED",
}

export enum RoomEvent
{
    MOUSE_DOWN = "MOUSE_DOWN",
    RAISE_HAND = "RAISE_HAND"
}
