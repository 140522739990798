<template>
  <div class="box box--full-height box--scrollable team-roles-detail">

    <transition name="fade">
      <div v-if="!selState.selected"
           class="empty-message">
        {{ $t('PleaseSelectARole') }}
      </div>
    </transition>

    <transition name="fade">
      <div v-if="role" :key="`${role.identifier}`" class="box-wrapper">

        <div class="detail-header">
          <div class="detail-header__person">
            <div>
              <h4 class="title">{{ $t(`RoleName_${role.identifier}`) }}</h4>
              <h6 class="">{{ $t(`RoleDescription_${role.identifier}`) }}</h6>
            </div>
          </div>
        </div>

        <b-form-group>
          <b-form-checkbox v-for="right in availableRights" :key="right.identifier" :value="right.identifier"
                           @input="_onFormInput" v-model="role.rights">
            {{ $t(`Right_${right.identifier}`) }}
          </b-form-checkbox>
        </b-form-group>

      </div>
    </transition>

  </div>

</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';

import SelectionState from "@/__libs/_model/SelectionState";
import {IRightOutlineDto} from "@/team/_model/team.dto";
import RoleListModel from "@/team/_model/RoleListModel";
import roleController from "@/team/_controller/RoleController";
import RoleModel from "@/team/_model/RoleModel";


@Component({
  components: {}
})
export default class RoleDetail extends Vue {

  //---------------------------------
  // Vue Component props
  //---------------------------------

  @Prop() private selState!: SelectionState<RoleModel>;

  //---------------------------------
  // Vue Component data
  //---------------------------------


  //---------------------------------
  // Vue Computed properties
  //---------------------------------


  get availableRights(): IRightOutlineDto[] {
    return RoleListModel.getInstance().rights;
  }


  get hasChanges() {
    return this.role && this.role.hasChanges;
  }


  get role() {
    return this.selState.selected;
  }


  //---------------------------------
  // Public / lifecycle methods
  //---------------------------------

  mounted() {
    window.addEventListener('beforeunload', this._beforeUnload);
  }

  beforeDestroy() {
    if (this.role && this.role.hasChanges) {
      this._saveRole(this.role);
    }

    window.removeEventListener('beforeunload', this._beforeUnload)
  }

  //---------------------------------
  // Private / helper methods
  //---------------------------------

  private _calculateInputState(inputValidator: any): any {
    return inputValidator.$invalid ? false : null;
  }

  private _beforeUnload(e: BeforeUnloadEvent) {
    //this._saveUser();
    // e.returnValue = "Are you sure you want to leave, changes you made will not be saved.";
    // return e.returnValue;
  }


  private _onFormInput(p_e: Event | null) {
    this.role!.hasChanges = true;
  }


  private _saveRole(p_role: RoleModel) {
    roleController.saveRole(p_role);
  }


  @Watch('role', {immediate: true, deep: false})
  private _onActiveRoleChange(newRole: RoleModel | null, oldRole: RoleModel | null) {
    if (oldRole && oldRole.hasChanges) {
      this._saveRole(oldRole);
    }
  }


}
</script>

