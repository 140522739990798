import Model from "@/__libs/_model/Model";
import {ITeamBodyDto} from "@/team/_model/team.dto";
import {DtoType} from "@/_model/app.constants";
import {IModelDto} from "@/__libs/_model/model.dto";
import {LoadingStatus, SaveStatus} from "@/entity/_model/entity.constants";
import AppUserModel from "@/project/user/_model/AppUserModel";
import DataProviderModel from "@/data_tool/data_provider/_model/DataProviderModel";
import {IDataProviderBodyDto} from "@/data_tool/_model/data_tool.dto";
import {RIGHTS} from "@/team/_model/role.constants";


class TeamModel extends Model implements ITeamBodyDto
{
    public teamID:number = 0;
    public displayName:string = "";
    public parentTeamID:number = 0;
    public avatarFileUri:string = `assetFolder://asf-${AppUserModel.getInstance().project.identifier}-system-assets/team_avatar_default.png`;
    public countryIdentifier:string = "WW";
    public allowPersonalFromMail:boolean = false;
    public createdDate:Date = new Date();
    public availableLanguages:string[] = [];

    //custom properties
    public dataSchemaURI:string = "dataSchema://team_schema"; //can in the future be used to create or change to a different "type" of audience with different custom props
    public customData:any = {};
    public dataDtp?:DataProviderModel;

    //status

    public loadingStatus:LoadingStatus = LoadingStatus.ID_ONLY;

    public saveStatus:SaveStatus = SaveStatus.IDLE;

    public hasChanges:boolean = false;
    public isEditable:boolean = AppUserModel.getInstance().rights.indexOf(RIGHTS.MANAGE_TEAMS.identifier) >= 0;

    //---------------------------------
    // Singleton Constructor
    //---------------------------------



    //---------------------------------
    // Public Methods
    //---------------------------------

    public mapFromDto(p_dto:IModelDto)
    {
        super.mapFromDto(p_dto);
        this.dataDtp = new DataProviderModel({data: this.customData, dataSchemaURI: this.dataSchemaURI, ID: this.teamID}, this as any);
    }

    public mapToDto(p_dtoType:DtoType):IModelDto
    {
        if (p_dtoType === DtoType.BODY)
        {

            if (this.availableLanguages.length === 0) //if no languages are selected, prefill the first one available
            {
                this.availableLanguages.push(AppUserModel.getInstance().project.availableLangCodes[0]);
            }
            const dto:ITeamBodyDto = {
                teamID               : this.teamID,
                displayName          : this.displayName,
                avatarFileUri        : this.avatarFileUri,
                parentTeamID         : this.parentTeamID,
                countryIdentifier    : this.countryIdentifier,
                allowPersonalFromMail: this.allowPersonalFromMail,
                availableLanguages   : this.availableLanguages
            };
            if (this.dataDtp)
            {
                dto.customData = (this.dataDtp.mapToDto(DtoType.BODY) as IDataProviderBodyDto).data;
            }
            return dto;
        }
        else
        {
            return super.mapToDto(p_dtoType);
        }
    }

}

export default TeamModel;
