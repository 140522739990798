<template>
  <div class="sub-page files-page">
    <AssetFoldersView :selState="selState"></AssetFoldersView>
  </div>
</template>

<script lang="ts">
import {Component, Vue} from "vue-property-decorator";
import AssetFoldersView from "@/asset_folder/_view/AssetFoldersView.vue";
import AssetFolderListModel from "@/asset_folder/_model/AssetFolderListModel";
import AssetFolderSelectionState from "@/asset_folder/_model/AssetFolderSelectionState";

@Component({
  components: {AssetFoldersView}
})
export default class AssetFoldersPage extends Vue {

  public selState: AssetFolderSelectionState = AssetFolderListModel.getInstance().pageSelState;

}
</script>
