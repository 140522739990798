<template>
  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
    <path class="cls-1" d="M22.24,3.31c-4-.89-7.5,2.6-6.61,6.61.45,2.02,2.08,3.65,4.09,4.09,4,.89,7.5-2.6,6.61-6.61-.45-2.02-2.08-3.65-4.09-4.09ZM32,24.44v2.39c0,1.1-.9,2-2,2H11.97c-1.1,0-2-.9-2-2v-2.39c0-3.08,1.82-5.15,5.64-6.07,1.49-.37,3.28-.55,5.38-.55,7.51,0,11.01,2.36,11.01,6.62ZM8.36,16.31C2.66,16.31,0,18.09,0,21.33v2.33C0,24.21.45,24.66,1,24.66h5.6v-.22c0-2.38.75-5.79,4.56-7.95-.83-.12-1.76-.18-2.8-.18ZM12.53,9.36c0,.44-.07.85-.19,1.24-.62,2-2.69,3.35-5,2.81-1.48-.35-2.67-1.55-3.02-3.02-.65-2.75,1.41-5.19,4.04-5.19,1.71,0,3.18,1.02,3.82,2.49.22.52.35,1.08.35,1.67Z"/>
  </svg>
</template>

<script lang="ts">
import {Component, Vue} from "vue-property-decorator";
import MainMenu from "@/_view/menu/MainMenu.vue";
import MainLogo from "@/_view/components/MainLogo.vue";
import MainStatus from "@/_view/components/MainStatus.vue";
import NetworkManager from "@/_controller/NetworkManager";

@Component({
  components: {}
})
export default class AudiencesIcon extends Vue {
}
</script>
