<template>
  <div class="root-page content-file-player-page">

    <ContentFilePlayer v-if="appReady" :engineMode="engineMode" :contentFileID="contentFileID" />

  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import {EngineMode} from "@/presentation/player/_model/player.constants";
import {RoutingIdentifier} from "@/router";
import ContentFilePlayer from "@/content_file/player/_view/ContentFilePlayer.vue";
import ContentListModel from "@/content/_model/ContentListModel";
import {LoadingStatus} from "@/entity/_model/entity.constants";
import ContentAppModel from "@/content_app/_model/ContentAppModel";
import ContentFileModel from "@/content_file/_model/ContentFileModel";

@Component({
  components: {
    ContentFilePlayer
  }
})

//This page could also be shown in a popup (on desktops)
export default class ContentFilePlayerPage extends Vue {

  @Prop() public readonly engineMode!: EngineMode;

  @Prop() public readonly contentFileID!: string;

  get appReady(): boolean {
    if (this.contentFileID) {
      const contentFile: ContentFileModel = ContentListModel.getInstance().getEntityByID(this.contentFileID) as ContentFileModel;
      if (contentFile && contentFile.loadingStatus === LoadingStatus.BODY_LOADED) {
        return true;
      }

    }
    return false;
  }

}
</script>
