import * as jsonpatch from 'fast-json-patch';
import {Observer, Operation} from "fast-json-patch";
import CobrowseManager from "../CobrowseManager";
import Room from "../room/Room";
import {ISharedObjectDataDto} from "./shared_object.dto";



export default class SharedObject<T extends ISharedObjectDataDto>
{

    public readonly room:Room;
    public readonly identifier:string;


    public data:T;

    private _observer:Observer<T>;
    private readonly _autoEmitChanges:boolean;

    //---------------------------------
    // Constructor
    //---------------------------------

    constructor(p_room:Room, p_identifier:string, p_data:T, p_autoEmitChanges:boolean = false)
    {
        this.room = p_room;
        this.identifier = p_identifier;
        this._autoEmitChanges = p_autoEmitChanges;
        this.data = p_data;
        if (p_autoEmitChanges)
        {
            this._observer = jsonpatch.observe(this.data, (patch) => {
                console.log("autoemitting", patch);
                this.emitPatch(patch);
            });
        }
        else
        {
            this._observer = jsonpatch.observe(this.data);
        }
    }


    public patch(p_patch:Operation[])
    {
        //first apply patch
        // console.log("patching", p_patch);
        jsonpatch.unobserve(this.data, this._observer); //for some reason the patches otherwise get stacked up (the observer doesn't reset it's patches)
        jsonpatch.applyPatch(this.data, p_patch);
        this._observer = jsonpatch.observe(this.data);
    }

    public emitChanges()
    {
        if (!this._autoEmitChanges)
        {
            let patch = jsonpatch.generate(this._observer);
            this.emitPatch(patch);
        }
    }

    public applyAndEmitPatch(p_patch:Operation[])
    {
        jsonpatch.applyPatch(this.data, p_patch);
        this.emitPatch(p_patch);
    }

    public emitPatch(p_patch:Operation[])
    {
        if (p_patch.length > 0)
        {
            //emit to the correct room
            CobrowseManager.getInstance().socket.emit("cSoChange", this.room.identifier, this.identifier, p_patch);
            // console.log("emitting patch", p_patch);
        }
    }


    //todo could be used to send events that change the data on server side (eg a custom chatSO where the timestamp is set by the server)
    //todo or maybe put this on Room level for subclassed custom Rooms
    //todo these events can be "perishable" or they can change the data and trigger an emitChanges
    public emitEvent(p_eventType:string, p_eventData:any)
    {

    }

}
