<template>
  <div class="welcome-sub-page welcome-font-page">
    <h1 class="welcome-sub-page__title mb-3" v-html="$t('WelcomeFontTitle')"></h1>
    <p class="welcome-sub-page__text" v-html="$t('WelcomeFontText')"></p>
    <div class="welcome-sub-page__content">
      <ProjectFonts></ProjectFonts>
    </div>
    <WelcomeNavigation :page-index="3"></WelcomeNavigation>
  </div>
</template>

<script lang="ts">
import {Component, Vue} from "vue-property-decorator";
import WelcomeNavigation from "@/project/welcome/_view/WelcomeNavigation.vue";
import ProjectLogos from "@/project/_view/ProjectLogos.vue";
import ProjectFonts from "@/project/_view/ProjectFonts.vue";

@Component({
  components: {ProjectFonts, WelcomeNavigation, ProjectLogos}
})
export default class WelcomeFontPage extends Vue {
}
</script>
