import apiManager, {ApiRequestScope, ApiRequestType, ApiResponse} from "@/_controller/ApiManager";
import {IResultDto} from "@/entity/_model/entity.dto";
import {IDomainInfoDto, IErrorDto} from "@/_model/app.dto";
import AppModel from "@/_model/AppModel";
import LocalStorageManager from "@/__libs/offline_storage/LocalStorageManager";
import {EventBusActions, LocalStorageKey, StorageScope} from "@/_model/app.constants";
import JsonUtil from "@/__libs/utility/JsonUtil";
import {IOfflineAvailabilityDto} from "@/sync/_model/sync.dto";
import SyncDb from "@/sync/_model/SyncDb";
import NetworkManager from "@/_controller/NetworkManager";
import fileManager, {FileResponse} from "@/_controller/FileManager";
import syncController from "@/sync/_controller/SyncController";

class AppController {

    private _appModel: AppModel = AppModel.getInstance();
    private _syncDb: SyncDb = SyncDb.getInstance();

    public async setAppEnvironment(): Promise<boolean> {
        apiManager.masterApiUrl = process.env.VUE_APP_MASTER_API_URL as string;
        NetworkManager.getInstance().pollUrl = process.env.VUE_APP_MASTER_API_URL as string;
        return this.setDomainInfoFromStorage();
    }

    public async fetchDomainInfo(): Promise<boolean> {
        const appDomain: string = new URL(window.location.href).host;
        const response: ApiResponse<IDomainInfoDto> = await apiManager.sendApiRequest(ApiRequestType.GET, `/master-api/domains/${appDomain}`, null, ApiRequestScope.MASTER);

        if (response.hasSucceeded) {
            LocalStorageManager.storeValue(LocalStorageKey.DOMAIN_INFO, JsonUtil.stringify(response.result));
        }

        return this.setDomainInfoFromStorage();
    }

    public setDomainInfoFromStorage() {
        const domainInfo: IDomainInfoDto | null = JsonUtil.parse(LocalStorageManager.retrieveValue(LocalStorageKey.DOMAIN_INFO) as string);

        if (domainInfo) {
            apiManager.projectApiUrl = domainInfo.projectApiUrl;
            if (domainInfo.splash) {
                if (domainInfo.splash.bgUrl) {
                    this._appModel.splash.bgUrl = domainInfo.splash.bgUrl;
                }
                if (domainInfo.splash.logoUrl) {
                    this._appModel.splash.logoUrl = domainInfo.splash.logoUrl;
                }
                if (domainInfo.splash.welcomeMessage) {
                    this._appModel.splash.welcomeMessage = domainInfo.splash.welcomeMessage;
                }
                if (domainInfo.splash.aaUserNotFoundMessage) {
                    this._appModel.splash.aaUserNotFoundMessage = domainInfo.splash.aaUserNotFoundMessage;
                }
            }
            return true;
        } else {
            return false;
        }
    }

    public async reportError(p_error: IErrorDto) {
        const endpoint: string = `/dev-api/error-reports/`;
        const response: ApiResponse<IResultDto> = await apiManager.sendApiRequest(ApiRequestType.POST, endpoint, p_error);

        if (!response.hasSucceeded) {
            await this._syncDb.addBackgroundSyncRecord({
                requestType: ApiRequestType.POST,
                endpoint: endpoint,
                data: p_error
            });
        }

    }

    public async checkOfflineAvailability(): Promise<boolean> {
        const isDomainOfflineAvailable = this.setDomainInfoFromStorage();
        if (!isDomainOfflineAvailable) {
            return false;
        }

        const offlineAvailability: IOfflineAvailabilityDto | null = JsonUtil.parse(LocalStorageManager.retrieveValue(LocalStorageKey.OFFLINE_AVAILABILITY) as string);
        if (offlineAvailability && offlineAvailability.lastSuccessfulSync) {
            return true
        }

        return false;
    }

    public async checkUpdateAvailable(): Promise<boolean> {
        const response: FileResponse = await fileManager.fetchFileFromCdn(`/version.json?ignoreCache=${new Date().getTime().toString()}`, StorageScope.APP);
        if (response.hasSucceeded) {
            const newVersion: string = response.result.version;
            return newVersion !== this._appModel.version;
        }
        return false;
    }

    public setUpdateCheckInterval() {
        console.log("AppController :: start hourly update check");
        setInterval(async () => {
            console.log("AppController :: check update");
            const updateAvailable: boolean = await this.checkUpdateAvailable();
            if (updateAvailable) {
                console.log("AppController :: update found");
                // serviceWorkerController.checkUpdate();
                // EventBus.$emit(EventBusActions.ASK_UPDATE);
            }
        }, 1000 * 60 * 60);
    }

    public async checkFirstTimeInUpdate(): Promise<boolean> {
        const versionInLocalStorage: string | null = JsonUtil.parse(LocalStorageManager.retrieveValue(LocalStorageKey.VERSION) as string);
        const firstTimeInUpdate: boolean = versionInLocalStorage !== this._appModel.version;
        if (firstTimeInUpdate) {
            console.log("AppController :: first time in update");
            await syncController.clearStorage();
            LocalStorageManager.storeValue(LocalStorageKey.VERSION, JsonUtil.stringify(this._appModel.version));
        }
        return firstTimeInUpdate;
    }

}

export default new AppController();
