<template>
    <div class="sub-page crm-overview sub-page--scrollable">

        <div class="box crm" v-if="this.appUser.project.crmProviders.XLS_IMPORT">
            <img class="crm__image" src="img/crm/XLS.png"/>
            <div class="crm__details">
                <div>
                    <label v-if="!crmModel.isImportingXls" class="btn btn-primary">
                        {{ $t('ImportFromXls') }} <input type="file" ref="importFileBtn" accept=".xls,.xlsx"
                                                         v-on:change="_onImportFromXlsBtnClick()" hidden>
                    </label>
                </div>
                <b-spinner variant="dark" class="my-5" v-if="crmModel.isImportingXls"></b-spinner>
                <button class="btn btn-outline-secondary" @click="_downloadXlsTemplate()">
                    {{ $t('DownloadXlsxTemplate') }}
                </button>
            </div>
        </div>

        <div class="box crm" v-if="this.appUser.project.crmProviders.PIPEDRIVE">
            <img class="crm__image" src="img/crm/PIPEDRIVE.png"/>
            <div class="crm__details">
                <b-form-group :label="$t('YourApiKey')">
                    <b-form-input v-model="pipeDriveApiKey"
                                  :placeholder="$t('PlaceholderApiKey')"></b-form-input>
                </b-form-group>
                <b-button ref="saveApiKeyBtn" variant="outline-secondary"
                          @click="_onSavePipeDriveApiKeyBtnClick" :disabled="isCrmLoading">
                    {{ $t('SaveAndRefreshContacts') }}
                </b-button>
            </div>
        </div>

        <div class="box crm" v-if="this.appUser.project.crmProviders.CONNECTOR_C4C">
            <img class="crm__image" src="img/crm/CONNECTOR_C4C.png"/>
            <div class="crm__details">
                <button ref="saveApiKeyBtn" class="btn btn-outline-secondary"
                        @click="_onRefreshC4CcontactsBtnClick" :disabled="isCrmLoading">
                    {{ $t('RefreshContacts') }}
                </button>
            </div>
        </div>

        <div class="box crm" v-if="this.appUser.project.crmProviders.HUBSPOT">
            <img class="crm__image" src="img/crm/HUBSPOT.png"/>
            <div class="crm__details">
                <button ref="saveApiKeyBtn" class="btn btn-outline-secondary"
                        @click="_onRefreshHubSpotcontactsBtnClick" :disabled="isCrmLoading">
                    {{ $t('RefreshContacts') }}
                </button>
            </div>
        </div>

<!--        <div class="box crm" v-if="this.appUser.project.crmProviders.SALESFORCE">-->
<!--            <img class="crm__image" src="img/crm/SALESFORCE.png"/>-->
<!--            <div class="crm__details">-->
<!--                <button ref="saveApiKeyBtn" class="btn btn-outline-secondary"-->
<!--                        @click="_onRefreshSalesForceContactsBtnClick" :disabled="isCrmLoading">-->
<!--                    {{ $t('RefreshContacts') }}-->
<!--                </button>-->
<!--                &lt;!&ndash;<iframe class="oauth-frame" ref="oauthFrame"></iframe>&ndash;&gt;-->
<!--            </div>-->
<!--        </div>-->

    </div>
</template>

<script lang="ts">
    import {Component, Vue} from 'vue-property-decorator';
    import AppUserModel from "@/project/user/_model/AppUserModel";
    import crmController from "@/crm/_controller/CrmController";
    import {CrmProvider} from "@/crm/_model/crm.constants";
    import {OAuth2} from 'jsforce';
    import {IEngineMessageDto} from "@/presentation/player/_model/player.dto";
    import {ICrmProviderDto} from "@/crm/_model/crm.dto";
    import dataProviderController from "@/data_tool/data_provider/_controller/DataProviderController";
    import CrmModel from "@/crm/_model/CrmModel";


    @Component({
        components: {},
    })
    export default class CRMView extends Vue
    {

        public appUser:AppUserModel = AppUserModel.getInstance();
        public crmModel:CrmModel = CrmModel.getInstance()


        public isCrmLoading:boolean = false;

        get isImportingXls()
        {
            return CrmModel.getInstance().isImportingXls;
        }


        get pipeDriveApiKey()
        {
            return this.appUser.crmProviders && this.appUser.crmProviders.PIPEDRIVE && this.appUser.crmProviders.PIPEDRIVE.apiKey ? this.appUser.crmProviders.PIPEDRIVE.apiKey : "";
        }

        set pipeDriveApiKey(p_value:string)
        {
            this.appUser.crmProviders.PIPEDRIVE = {apiKey: p_value};
        }

        public mounted()
        {
            window.addEventListener('message', this.listenForCallback);
        }

        public beforeDestroy()
        {
            window.removeEventListener('message', this.listenForCallback);
        }


        private async _onImportFromXlsBtnClick()
        {

            const importFileBtn:HTMLInputElement = this.$refs.importFileBtn as HTMLInputElement;
            if (importFileBtn.files && importFileBtn.files.length === 1)
            {
                const result = await crmController.importFromXls(importFileBtn.files[0]);
            }



        }


        private async _onSavePipeDriveApiKeyBtnClick()
        {
            this.isCrmLoading = true;
            await crmController.saveApiKey(CrmProvider.PIPEDRIVE, this.appUser.crmProviders.PIPEDRIVE.apiKey);
            this.isCrmLoading = false;
        }


        private async _onRefreshC4CcontactsBtnClick()
        {
            this.isCrmLoading = true;
            await crmController.refreshContacts(CrmProvider.CONNECTOR_C4C);
            this.isCrmLoading = false;
        }

        private async _onRefreshHubSpotcontactsBtnClick()
        {
            this.isCrmLoading = true;
            await crmController.refreshContacts(CrmProvider.HUBSPOT);
            this.isCrmLoading = false;
        }

        private async _onRefreshSalesForceContactsBtnClick()
        {
            const sfConfig:ICrmProviderDto = this.appUser.project.crmProviders.SALESFORCE;

            const oauth2 = new OAuth2({
                loginUrl    : sfConfig.apiUrl,
                clientId    : sfConfig.clientID,
                clientSecret: sfConfig.clientSecret,
                redirectUri : 'https://storage.audience-advantage.app/storage/_global/authentication/oAuthRedirect.html'
            });

            const authUrl:string = oauth2.getAuthorizationUrl({scope: 'api id web refresh_token offline_access'});

            // const oauthFrame:HTMLIFrameElement = this.$refs.oauthFrame as HTMLIFrameElement;
            //oauthFrame.src = authUrl;

            window.open(authUrl, "_blank");
        }

        private async listenForCallback(p_messageEvent:MessageEvent)
        {

            const message:IEngineMessageDto = p_messageEvent.data;
            if (message.messageType)
            {

                const code = decodeURIComponent(message.messageValue.split("=")[1]);

                this.isCrmLoading = true;
                await crmController.connectOauthUser(CrmProvider.SALESFORCE, code);
                this.isCrmLoading = false;

            }
        }

        private _downloadXlsTemplate()
        {
            window.open("xls/audiences_template.xlsx")

        }

    }
</script>

