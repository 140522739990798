import apiManager, {ApiRequestType, ApiResponse} from "@/_controller/ApiManager";
import {IUpdateResultDto} from "@/entity/_model/entity.dto";
import {DmsProvider} from "@/dms/_model/dms.constants";
import toastManager, {ToastType} from "@/__libs/toast_manager/ToastManager";
import i18n from "@/__plugins/i18n";
import audienceListController from "@/audience/_controller/AudienceListController";
import AudienceListModel from "@/audience/_model/AudienceListModel";
import FileUtil from "@/__libs/utility/FileUtil";
import {Row, Workbook, Worksheet} from "exceljs";
import Util from "@/__libs/utility/Util";
import AudienceModel from "@/audience/_model/AudienceModel";
import audienceController from "@/audience/_controller/AudienceController";
import {DeltaStatus} from "@/entity/_model/entity.constants";
import languageManager from "@/__libs/language_manager/LanguageManager";
import AppUserModel from "@/project/user/_model/AppUserModel";
import DmsModel from "@/dms/_model/DmsModel";
import {IDmsFileDto, IDmsProviderClientDto} from "@/dms/_model/dms.dto";
import ContentListModel from "@/content/_model/ContentListModel";
import contentListController from "@/content/_controller/ContentListController";


class DmsController
{


    //---------------------------------
    // Properties
    //---------------------------------

    private _audienceListModel:AudienceListModel = AudienceListModel.getInstance();


    //---------------------------------
    // Controller Methods
    //---------------------------------


    public async searchFiles(p_dmsProvider:IDmsProviderClientDto, p_searchInput:string = ""):Promise<IDmsFileDto[]>
    {
        let url:string = `/client-api/dms/files?provider=${p_dmsProvider.provider}`;

        if (p_searchInput.length > 2)
        {
            url += `&searchInput=${p_searchInput}`;
        }


        const response:ApiResponse<IDmsFileDto[]> = await apiManager.sendApiRequest(ApiRequestType.GET, url);
        if (response.hasSucceeded)
        {
            const files:IDmsFileDto[] = response.result as IDmsFileDto[]
            for (let i = 0; i < files.length; i++)
            {
                const file = files[i];
                if(file.remoteIdentifier)
                {
                    file.isExisting =  this._audienceListModel.getAudienceByRemoteID(file.remoteIdentifier) !== null;
                }
            }
            return response.result as IDmsFileDto[];
        }
        else
        {
            return [];
        }
    }

    public async doBackendImport(p_dmsPovider:IDmsProviderClientDto, p_fileRemoteIDs:string[], p_alsoImportParentCompanies:boolean):Promise<boolean>
    {
        const response:ApiResponse<IUpdateResultDto> = await apiManager.sendApiRequest(ApiRequestType.POST, `/client-api/dms/files/import`, {
            provider                 : p_dmsPovider.provider,
            fileRemoteIDs: p_fileRemoteIDs
        });
        if (response.hasSucceeded)
        {
            ContentListModel.getInstance().invalidateFetch();
            await contentListController.fetchAllEntities();
            toastManager.showToast(i18n.t('FilesImported') as string, ToastType.SUCCESS);
        }
        else
        {
            toastManager.showToast(i18n.t('FileImportFailed') as string, ToastType.DANGER);
        }
        return response.hasSucceeded;
    }




}

//Singleton export
export default new DmsController();
