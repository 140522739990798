<template>
  <div class="sub-page team-teams">
    <div class="columns">
      <TeamsOverview :selState="selState"></TeamsOverview>
      <TeamDetail :selState="selState"></TeamDetail>
    </div>
  </div>
</template>

<script lang="ts">
import {Component, Vue} from "vue-property-decorator";
import TeamModel from "@/team/_model/TeamModel";
import SelectionState from "@/__libs/_model/SelectionState";
import TeamListModel from "@/team/_model/TeamListModel";
import TeamDetail from "@/team/_view/TeamDetail.vue";
import TeamsOverview from "@/team/_view/TeamsOverview.vue";

@Component({
    components: {TeamsOverview, TeamDetail}
})
export default class TeamsView extends Vue {
    public selState: SelectionState<TeamModel> = TeamListModel.getInstance().globalSelState;
}
</script>
