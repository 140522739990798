<template>
  <div class="grid-item content-app-resource-item" @click="click">

    <div class="responsive responsive--16-9">
      <div class="responsive__wrapper">

        <AAImage class="grid-item-background" :image-url="posterUrl"></AAImage>

        <div class="grid-item-background-overlay"></div>

        <div class="grid-item-content">
          <h6 v-line-clamp="2">{{ name }}</h6>
        </div>

      </div>
    </div>

  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import ContentAppResourceModel from "@/content_app/resource/_model/ContentAppResourceModel";
import AAImage from "@/_view/components/AAImage.vue";
import languageManager from "@/__libs/language_manager/LanguageManager";
import fileManager from "@/_controller/FileManager";
import AppUserModel from "@/project/user/_model/AppUserModel";

@Component({
  components: {
    AAImage
  }
})

export default class ContentAppResourceItemRenderer extends Vue {

  @Prop() private contentAppResource!: ContentAppResourceModel;
  @Prop() private overrideName!: string;

  get name(): string {
    if (this.overrideName) {
      return this.overrideName;
    } else {
      return languageManager.getTranslationForValue(
          this.contentAppResource.name,
          AppUserModel.getInstance().langCode
      );
    }
  }

  get posterUrl(): string {
    return fileManager.getFileUrl(this.contentAppResource.defaultPoster);
  }

  private click() {
    this.$emit('onClick', this.contentAppResource);
  }

}
</script>
