import apiManager, {ApiRequestType, ApiResponse} from "@/_controller/ApiManager";
import {DeltaStatus, EntityType, IEntityTypeConfig, LoadingStatus} from "@/entity/_model/entity.constants";
import LocalStorageManager from "@/__libs/offline_storage/LocalStorageManager";
import JsonUtil from "@/__libs/utility/JsonUtil";
import EntityListModel from "@/entity/_model/EntityListModel";
import EntityModel from "@/entity/_model/EntityModel";
import {ICreateResultDto, IEntityBodyDto, IEntityMetaDto, IUpdateResultDto} from "@/entity/_model/entity.dto";
import EntityController from "@/entity/_controller/EntityController";
import {DtoType} from "@/_model/app.constants";
import AppUserModel from "@/project/user/_model/AppUserModel";
import {RightAction} from "@/team/_model/role.constants";
import Util from "@/__libs/utility/Util";
import appUserController from "@/project/user/_controller/AppUserController";
import ContentFolderModel from "@/content/_model/ContentFolderModel";
import ContentModel from "@/content/_model/ContentModel";
import ContentFolderListModel from "@/content/_model/ContentFolderListModel";


//contains all controller methods that can be applied on the list of entities
abstract class EntityListController<T extends EntityModel>
{

    protected _appUserModel:AppUserModel = AppUserModel.getInstance();

    protected _entityTypeConfig:IEntityTypeConfig;

    protected _entityListModel:EntityListModel<T>;

    protected _entityController:EntityController;


    protected constructor(p_entityTypeConfig:IEntityTypeConfig, p_entityListModel:EntityListModel<T>, p_entityController:EntityController)
    {
        this._entityTypeConfig = p_entityTypeConfig;
        this._entityListModel = p_entityListModel;
        this._entityController = p_entityController;
    }

    protected _contentFolderListModel:ContentFolderListModel = ContentFolderListModel.getInstance();

    //---------------------------------
    // Properties
    //---------------------------------



    //---------------------------------
    // Controller Methods
    //---------------------------------

    public getAllEntitiesMetaFromCache()
    {
        const entityMeta_str:string | null = LocalStorageManager.retrieveValue(this._entityTypeConfig.localStorageKey);

        if (entityMeta_str)
        {
            const entityMetaDtos:IEntityMetaDto[] | null = JsonUtil.parse<IEntityMetaDto[]>(entityMeta_str);

            if (entityMetaDtos)
            {
                for (let i = 0; i < entityMetaDtos.length; i++)
                {
                    const entity:T = this.__newEntity(entityMetaDtos[i]) as T;
                    entity.loadingStatus = LoadingStatus.META_LOADED;
                    this._entityListModel.list.push(entity);
                }
                this._entityListModel.noMetaCache = entityMetaDtos.length === 0;
            }
        }
    }

    public async fetchAllEntities()
    {
        //first check if you are not fetching already and if enough time has passed between fetches
        if (!this._entityListModel.isFetchListAllowed)
        {
            return;
        }
        //all good, start fetch
        this._entityListModel.startListFetch();

        //keep track of new and updated content
        let nrOfNewContent:number = 0;
        let nrOfUpdatedContent:number = 0;


        //get new meta info from server
        const response:ApiResponse<IEntityMetaDto[]> = await apiManager.sendApiRequest(ApiRequestType.GET, `/client-api/${this._entityTypeConfig.apiPath}?dto=META`);

        if (response.hasSucceeded)
        {
            //first loop over all new meta info and flag new/updated deltas
            //check existence by ID and need for update flag by version
            //map the new meta onto the existing models (will content overview layout change according to ?)

            const entityMetaDtos:IEntityMetaDto[] = response.result as IEntityMetaDto[];
            const entityIDs:string[] = [];
            for (let i = 0; i < entityMetaDtos.length; i++)
            {
                const metaDto:IEntityMetaDto = entityMetaDtos[i];
                const entityInMemory:EntityModel | null = this._entityListModel.getEntityByID(metaDto.ID);
                entityIDs.push(metaDto.ID);

                if (entityInMemory)
                {
                    if (entityInMemory.version < metaDto.version)
                    {
                        // console.log("UPDATED: ", entityInMemory.ID);
                        nrOfUpdatedContent++;
                        entityInMemory.deltaStatus = DeltaStatus.UPDATED;
                    }
                    entityInMemory.mapFromDto(metaDto); //update the new meta info
                }
                else    //it's a new entity
                {
                    // console.log("NEW: ", metaDto.ID);
                    nrOfNewContent++;
                    const entity:T = this.__newEntity(metaDto) as T;
                    entity.loadingStatus = LoadingStatus.META_LOADED;
                    //check if it is the device's first time before setting NEW, otherwise all entities are flagged new
                    //todo, also check if the entity.createdUserID === AppUser.ID, because then you have created it yourself, or just show it (so the user sees it's new)?
                    if (!this._entityListModel.noMetaCache)
                    {
                        entity.deltaStatus = DeltaStatus.NEW;
                    }
                    this._entityListModel.list.push(entity);
                }
            }

            //by default, order them by createdDate DESC
            this._entityListModel.list.sort(function(a:EntityModel,b:EntityModel){
                return new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime() ;
            });

            //deletes (maybe don't just kill deletes but set a DELETED delta, because you may be selecting/presenting them?)
            for (let i = this._entityListModel.list.length - 1; i >= 0; i--)
            {
                if (entityIDs.indexOf(this._entityListModel.list[i].ID) === -1)
                {
                    console.log("killing", this._entityListModel.list[i].ID);
                    this._entityListModel.list.splice(i, 1);
                }
            }

            if (!appUserController.inImpersonationMode)
            {
                //then store new meta state
                LocalStorageManager.storeValue(this._entityTypeConfig.localStorageKey, JsonUtil.stringify(response.result));
            }

        }

        this.organizeEntities();

        // fetch all bodies if not fetched yet or new version

        //todo: choice: we can fetch them all at once, but then the fade-in sequence won't be so cool

        // for (let i = 0; i < this._entityListModel.list.length; i++)
        // {
        //     const entity:T = this._entityListModel.list[i] as T;
        //     if (entity.loadingStatus < LoadingStatus.BODY_LOADED || entity.deltaStatus > DeltaStatus.UNCHANGED)
        //     {
        //
        //         await this._entityController.fetchBody(entity);
        //     }
        // }

        //fetch them all at once tryout
        Promise.all(
            this._entityListModel.list.map(async (entity) => {
                if (entity.loadingStatus < LoadingStatus.BODY_LOADED || entity.deltaStatus > DeltaStatus.UNCHANGED)
                {
                    await this._entityController.fetchBody(entity);
                }
            })
        );

        this._entityListModel.stopListFetch();
    }

    //should be overridden when needed (eg for content to put them into folders)
    public organizeEntities() {}


    public async createEntity(p_entity:T, p_contentFolder?:ContentFolderModel):Promise<boolean>
    {
        const bodyDto:IEntityBodyDto = p_entity.mapToDto(DtoType.BODY) as IEntityBodyDto;

        const response:ApiResponse<ICreateResultDto> = await apiManager.sendApiRequest(ApiRequestType.POST, `/client-api/${this._getEntityConfig(p_entity).apiPath}`, bodyDto);


        if (response.hasSucceeded)
        {
            p_entity.ID = (response.result as ICreateResultDto).ID;

            const entityMeta:IEntityMetaDto =  response.result!.meta;

            //update the metacache
            const entityMeta_str:string | null = LocalStorageManager.retrieveValue(this._entityTypeConfig.localStorageKey);
            if (entityMeta_str)
            {
                const entityMetaDtos:IEntityMetaDto[] | null = JsonUtil.parse<IEntityMetaDto[]>(entityMeta_str);
                if (entityMetaDtos)
                {
                    entityMetaDtos.push(entityMeta);
                    LocalStorageManager.storeValue(this._entityTypeConfig.localStorageKey, JsonUtil.stringify(entityMetaDtos));
                }
            }

            p_entity.mapFromDto(entityMeta);

            p_entity.loadingStatus = LoadingStatus.BODY_LOADED;

            this._entityListModel.list.push(p_entity);

            if (p_contentFolder) //if its content that belongs in a folder
            {
                p_contentFolder.childContents.push(p_entity as any);
                if (p_contentFolder.folderID > 0) //if it is not the rootfolder
                {
                    p_entity.folderID = p_contentFolder.folderID;
                    const endPoint:string = `/client-api/${this._getEntityConfig(p_entity).apiPath}/${p_entity.ID}/folders`;
                    await apiManager.sendApiRequest(ApiRequestType.PUT, endPoint, {folderID: p_contentFolder.folderID});
                }
            }


            this._entityListModel.globalSelState.selected = p_entity;
            if (this._entityListModel.pageSelState)
            {
                this._entityListModel.pageSelState.selected = p_entity;
            }
            if (this._entityListModel.pickerSelState)
            {
                this._entityListModel.pickerSelState.selected = p_entity;
            }

            //todo: add  .json file to idb
        }

        return response.hasSucceeded;

    }


    public async deleteEntity(p_entity:T):Promise<boolean>
    {
        const bodyDto:IEntityBodyDto = p_entity.mapToDto(DtoType.BODY) as IEntityBodyDto;

        const response:ApiResponse<ICreateResultDto> = await apiManager.sendApiRequest(ApiRequestType.DELETE, `/client-api/${this._getEntityConfig(p_entity).apiPath}/${p_entity.ID}`);

        if (response.hasSucceeded)
        {
            if (p_entity.folderID !== null) //is the entity in a folder
            {
                let folder:ContentFolderModel;
                if (p_entity.folderID! > 0)
                {
                    folder = this._contentFolderListModel.contentFoldersDict.getItem(p_entity.folderID!);
                }
                else
                {
                    folder = this._contentFolderListModel.rootContentFolder;
                }
                if (folder)
                {
                    folder.deleteContentFromFolder(p_entity as any);
                }
            }
            return this._entityListModel.removeEntity(p_entity);
        }

        return false;

    }


    public async copyEntity(p_entity:T):Promise<boolean>
    {

        const metaDto:IEntityMetaDto = this._createMetaDto(p_entity.entityType);
        const bodyDto:IEntityBodyDto = Util.deepCopy(p_entity.mapToDto(DtoType.BODY)); //make a deep copy of the body

        const copiedModel:T = this.__newEntity(metaDto);
        copiedModel.mapFromDto(bodyDto);

        if(copiedModel.identifier)
        {
            //can't have 2 entities with the same identifier
            delete copiedModel.identifier;
        }

        copiedModel.renameAsCopy();

        return await this.createEntity(copiedModel, ContentFolderListModel.getInstance().activeContentFolder);

    }



    //---------------------------------
    // Private Methods
    //---------------------------------

    //needs to be overridden
    protected __newEntity(p_dto:IEntityMetaDto):T
    {
        return {} as T;
    }

    //can be overridden (eg when you have to switch between the 3 types of content entities)
    protected _getEntityConfig(p_entity:EntityModel):IEntityTypeConfig
    {
        return this._entityTypeConfig;
    }

    protected _createMetaDto(p_entityType:EntityType):IEntityMetaDto
    {
        const meta:IEntityMetaDto = {

            ID                : "",
            version           : 1,
            entityType        : p_entityType,
            createdByUserID   : this._appUserModel.ID,
            createdByUserName : this._appUserModel.displayName,
            createdByTeamName : this._appUserModel.teamID.toString(),
            createdDate       : new Date(),
            updatedDate       : new Date(),
            latestActivityDate: new Date(),
            sharedWithTeams   : [],
            rightActions      : [RightAction.VIEW, RightAction.DELETE, RightAction.COPY, RightAction.EDIT, RightAction.SHARE_WITH_TEAMS, RightAction.PRESENT, RightAction.COPY, RightAction.SHARE_WITH_AUDIENCES]
        };
        /*
        if (this._getEntityConfig(p_entity).canBePresented)
        {
            meta.rightActions.push(RightAction.PRESENT);
        }
        if (this._getEntityConfig(p_entity).canBeCopied)
        {
            meta.rightActions.push(RightAction.COPY);
        }
        if (this._getEntityConfig(p_entity).canBeSharedWithAudiences)
        {
            meta.rightActions.push(RightAction.SHARE_WITH_AUDIENCES);
        }
         */

        return meta;
    }

}

//Singleton export
export default EntityListController;
