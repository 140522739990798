<template>
  <div class="chat-box">
    <transition name="fade">
      <div class="chat-box__no-messages" v-if="messages.length === 0">
        <div class="boxes">
          <svg width="100%" height="100%" viewBox="0 0 255 172" version="1.1" xmlns="http://www.w3.org/2000/svg"
               xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/"
               style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
    <g transform="matrix(1,0,0,1,-14650.9,-982.502)">
        <g transform="matrix(1,0,0,1,11669.4,143.498)">
            <g transform="matrix(1,0,0,1,-3.59082,1.92373)">
                <g transform="matrix(0.775163,0,0,0.775163,3077.23,813.826)">
                    <path
                        d="M195,30C203.28,30 210,36.715 210,45L210,155C210,163.28 203.28,170 195,170L72.78,170L30,210L30,45C30,36.715 36.715,30 45,30L195,30ZM50,95L50,105L190,105L190,95L50,95ZM50,70L50,80L190,80L190,70L50,70ZM50,120L50,130L130,130L130,120L50,120Z"
                        style="fill:rgb(36,180,131);fill-rule:nonzero;"/>
                </g>
              <g transform="matrix(-0.504152,0,0,0.504152,3090.95,902.733)">
                    <path
                        d="M195,30C203.28,30 210,36.715 210,45L210,155C210,163.28 203.28,170 195,170L72.78,170L30,210L30,45C30,36.715 36.715,30 45,30L195,30ZM50,95L50,105L190,105L190,95L50,95ZM50,70L50,80L190,80L190,70L50,70ZM50,120L50,130L130,130L130,120L50,120Z"
                        style="fill:rgb(128,128,128);fill-rule:nonzero;"/>
                </g>
            </g>
        </g>
    </g>
</svg>
        </div>
        <p>{{ $t('CobrowseEmptyChat') }}</p>
      </div>
    </transition>

    <transition name="fade">
      <div class="chat-box__messages" ref="messages" v-if="messages.length > 0">
        <ChatBoxItemRenderer v-for="(message, i) in messages" :key="i" :message="message"
                             :user="getUser(message.fromUserID)"/>
      </div>
    </transition>

    <div class="chat-box__input">
      <b-form-group class="mb-2">
        <b-form-textarea v-model="currentMessage"></b-form-textarea>
      </b-form-group>

      <div class="text-right">
        <b-button variant="primary" size="sm" @click="sendMessage" :disabled="currentMessage.length === 0">
          {{ $t('CobrowseSendMessage') }}
        </b-button>
      </div>
    </div>

  </div>
</template>

<script lang="ts">

import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import ChatBoxItemRenderer from "@/cobrowse/chat/_view/ChatBoxItemRenderer.vue";
import SharedObject from "@sales-drive/audience-library/lib/cobrowse_manager/shared_object/SharedObject"
import {IChatSoDataDto} from "@sales-drive/audience-library/lib/cobrowse_manager/chat/_model/chat.dto";
import Room from "@sales-drive/audience-library/lib/cobrowse_manager/room/Room";
import {IRoomUsersSoDataDto} from "@sales-drive/audience-library/lib/cobrowse_manager/room/_model/room.dto";
import CobrowseManager from "@sales-drive/audience-library/lib/cobrowse_manager/CobrowseManager";
import {ChatMessageStatus} from "@sales-drive/audience-library/lib/cobrowse_manager/chat/_model/chat.constants";
import {nextTick} from "vue";

@Component({
    components: {ChatBoxItemRenderer}
})
export default class ChatBox extends Vue {

    @Prop() public chatSO!: SharedObject<IChatSoDataDto>;
    @Prop() public room!: Room;

    private currentMessage: string = "";

    get messages() {
        return this.chatSO.data.messages;
    }

    get roomUsersSO(): SharedObject<IRoomUsersSoDataDto> {
        return this.room.roomUsersSO!;
    }

    public getUser(p_userID: string) {
        return this.roomUsersSO.data.users.find(user => {
            return (user.cobrowseUserID === p_userID);
        });
    }

    private sendMessage() {
        this.messages.push({
            fromUserID: CobrowseManager.getInstance().cobrowseUser.cobrowseUserID,
            message: this.currentMessage,
            timeStamp: new Date().toUTCString(),
            messageStatus: ChatMessageStatus.SENT
        });
        this.chatSO.emitChanges();
        this.currentMessage = "";
    }

    @Watch('messages', {immediate: false, deep: false})
    private messagesChangedHandler(newValue: IChatSoDataDto, oldValue: IChatSoDataDto) {
        if (this.messages.length > 0) {
            nextTick(() => {
                const messageDisplay: any = this.$refs.messages;
                messageDisplay.scrollTop = messageDisplay.scrollHeight;
            });
        }
    }

    public mounted() {
        if (this.messages.length > 0) {
            nextTick(() => {
                const messageDisplay: any = this.$refs.messages;
                messageDisplay.scrollTop = messageDisplay.scrollHeight;
            });
        }
    }

    // @Watch('room', {immediate: false, deep: false})
    // private roomChangedHandler(newValue:string, oldValue:string)
    // {
    //     console.log('activeRoom changed', this.activeRoom.identifier);
    // }
}
</script>

