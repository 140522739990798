<template>
  <div class="project-logos">
    <ProjectLogo :file-url="project.logoLightBgUrl" :file-name="systemFiles.LOGO_LIGHT_BG"></ProjectLogo>
    <ProjectLogo :file-url="project.logoDarkBgUrl" :file-name="systemFiles.LOGO_DARK_BG" :dark="true"></ProjectLogo>
  </div>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import ProjectModel from "@/project/_model/ProjectModel";
import AppUserModel from "@/project/user/_model/AppUserModel";
import ProjectLogo from "@/project/_view/ProjectLogo.vue";
import {SystemFiles} from "@/project/_model/project.constants";

@Component({
  components: {ProjectLogo},
})
export default class ProjectLogos extends Vue {

  public project: ProjectModel = AppUserModel.getInstance().project;

  private systemFiles: typeof SystemFiles = SystemFiles;

}
</script>

