<template>
  <div class="list-item data-list-item"
       :class="{ active: isSelected, 'body-loaded': true }"
       v-on:click="_onClick">

    <div class="list-item__icon" v-if="posterUrl">
      <AAImage :imageUrl="posterUrl" />
      <div class="list-item__icon__badges">
        <span class="badge badge-danger" v-if="!dataItem.isValid">{{ dataItem.getNrOfInvalidChildren() }}</span>
      </div>
    </div>

    <div class="list-item__info" v-if="name">
      <h6 class="list-item__info__primary mb-0" v-line-clamp="1">{{ name }}</h6>
      <div class="list-item__info__badges" v-if="!posterUrl">
        <span class="badge badge-danger" v-if="!dataItem.isValid">{{ dataItem.getNrOfInvalidChildren() }}</span>
      </div>
    </div>

    <div v-if="dataItem.dataProvider.isEditable && !this.readOnly" @click="_onDeleteBtnClick" class="delete-button">
      <i class="fas fa-trash-alt"></i>
    </div>

  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from "vue-property-decorator";
import DataItemModel from "@/data_tool/data_item/_model/DataItemModel";
import dataProviderController from "@/data_tool/data_provider/_controller/DataProviderController";
import {IMultiLangString} from "@/__libs/language_manager/LanguageManager";
import languageManager from "@/__libs/language_manager/LanguageManager";
import fileManager from "@/_controller/FileManager";
import AAImage from "@/_view/components/AAImage.vue";
import DataItemListSelectionState from "@/data_tool/data_item/_model/DataItemListSelectionState";


@Component({
  components: {AAImage}
})
export default class DataItemRenderer extends Vue {

  @Prop() public dataItem!: DataItemModel;

  @Prop() private selState!: DataItemListSelectionState<DataItemModel>;

  @Prop() private readOnly!: boolean;


  get name(): string | null {
    if (this.dataItem.nameProp) {
      let tempName: string = "";
      //show the rawvalue because the displayedvalue could be invalid
      if (this.dataItem.nameProp.propertyDefinition.isMultiLingual) {
        tempName = languageManager.getTranslationForValue<string>(this.dataItem.nameProp.rawValue as IMultiLangString, this.dataItem.dataProvider.activeLangCode);
      } else {
        tempName = this.dataItem.nameProp.rawValue;
      }
      if (this.dataItem.nameProp.propertyDefinition.prefix) {
        tempName = languageManager.getTranslationForValue<string>(this.dataItem.nameProp.propertyDefinition.prefix as IMultiLangString, this.dataItem.dataProvider.activeLangCode) + ' ' + tempName;
      }
      if (this.dataItem.nameProp.propertyDefinition.suffix) {
        tempName += ' ' + languageManager.getTranslationForValue<string>(this.dataItem.nameProp.propertyDefinition.suffix as IMultiLangString, this.dataItem.dataProvider.activeLangCode);
      }
      return tempName;
    }
    return null;
  }

  get isSelected() {
    return this.selState.selected === this.dataItem;
  }

  get posterUrl(): string | null {
    if (this.dataItem.posterProp) {
      let posterURI: string;
      if (this.dataItem.posterProp.propertyDefinition.isMultiLingual) {
        posterURI = languageManager.getTranslationForValue<string>(this.dataItem.posterProp.rawValue as IMultiLangString, this.dataItem.dataProvider.activeLangCode);
      } else {
        posterURI = this.dataItem.posterProp.rawValue;
      }
      if (posterURI) {
        return fileManager.getFileUrl(posterURI);
      }
    }
    return null;
  }

  public mounted() {
    // set selectionstate element if is newly created element
    if (this.isSelected) {
      this.selState.element = this.$el;
    }
  }

  public _onClick(p_e: Event) {
    this.selState.element = this.$el;
    this.$emit("onClick", this.dataItem);
  }

  public async _onDeleteBtnClick(p_e: Event) {
    const deleteConfirmed: boolean = await this.$bvModal.msgBoxConfirm(this.$t('DashboardShowDeleteConfirmationMessage') as string, {centered: true});
    if (deleteConfirmed) {
      dataProviderController.deleteDataItem(this.dataItem);
      this.selState.selected = null;
    }
  }
}
</script>
