<template>
    <div class="data-item-select-list">

        <select
          v-if="propertyDefinition.formElement === FormElement.COMBOBOX"
          :value="value"
          :disabled="!isEditable"
          class="form-control mb-3"
          @change="onChangeSelect"
        >
          <option
            v-for="entry in propertyDefinition.list"
            :key="entry.reference"
            :value="entry.reference"
          >
            {{ getEntryName(entry.name) }}
          </option>
        </select>

        <div v-if="propertyDefinition.formElement === FormElement.RADIOBUTTON_GROUP">
            <b-form-radio
              v-for="entry in propertyDefinition.list"
              :key="entry.reference"
              :name="propertyDefinition.identifier"
              :value="value"
              @change="$emit('input', entry.reference)"
            >
              {{ getEntryName(entry.name) }}
            </b-form-radio>
        </div>

    </div>
</template>


<script lang="ts">
  import {Component, Prop, Vue, Watch} from "vue-property-decorator";
  import {FormElement} from "@/data_tool/_model/data_tool.constants";
  import PropertyModel from "@/data_tool/data_item/_model/PropertyModel";
  import languageManager, {IMultiLangString} from "@/__libs/language_manager/LanguageManager";
  import AppUserModel from "@/project/user/_model/AppUserModel";
  import {IPropertyDefinitionDto} from '@/data_tool/_model/data_tool.dto';

  @Component({})
  export default class FixedList extends Vue {
    //---------------------------------
    // Vue Component props
    //---------------------------------
    @Prop({ required: true }) public readonly propertyDefinition!: IPropertyDefinitionDto;
    @Prop({ required: true }) public value!: string;
    @Prop({ required: false, default: true }) public readonly isEditable!: boolean;

    //---------------------------------
    // Vue Component data
    //---------------------------------
    public readonly FormElement: typeof FormElement = FormElement;

    //---------------------------------
    // Public / lifecycle methods
    //---------------------------------
    public getEntryName(p_name: IMultiLangString) {
      return languageManager.getTranslationForValue(p_name, AppUserModel.getInstance().langCode)
    }

    public onChangeSelect(event: any) {
      this.$emit('input', event.target.value)
    }
  }
</script>
