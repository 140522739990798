import {ENTITY_CONFIG, UploadStatus} from "@/entity/_model/entity.constants";
import EntityController from "@/entity/_controller/EntityController";
import AssetFolderModel from "@/asset_folder/_model/AssetFolderModel";
import apiManager, {ApiRequestType, ApiResponse} from "@/_controller/ApiManager";
import {IAssetFileDto, IFileOutlineDto} from "@/asset_folder/_model/asset_folder.dto";
import {
    AssetFolderViewMode,
    FILE_TYPE_CONFIG,
    FileImportSource,
    FileType
} from "@/asset_folder/_model/asset_folder.constants";
import AssetFolderListModel from "@/asset_folder/_model/AssetFolderListModel";
import FileUtil from "@/__libs/utility/FileUtil";
import AppUserModel from "@/project/user/_model/AppUserModel";
import {IFileDto, IUploadFileResultDto} from "@/_model/app.constants";
import AssetFileModel from "@/asset_folder/_model/AssetFileModel";
import AssetFolderSelectionState from "@/asset_folder/_model/AssetFolderSelectionState";
import {IDeleteResultDto} from "@/entity/_model/entity.dto";
import fileManager from "@/_controller/FileManager";
import toastManager, {ToastType} from "@/__libs/toast_manager/ToastManager";
import i18n from "@/__plugins/i18n";



//contains all (static) controller methods that can be initiated on a single asset folder
class AssetFolderController extends EntityController
{


    //---------------------------------
    // Properties
    //---------------------------------


    //---------------------------------
    // Controller Methods
    //---------------------------------

    public async addFile(p_assetFolder:AssetFolderModel, p_files:FileList, p_selState:AssetFolderSelectionState)
    {

        // add toast with uploading message
        // toastManager.showToast(i18n.t('UploadingFiles') as string, ToastType.SECONDARY);

        //todo?: check if file already exists and ask to overwrite or add new? >> no: file gets unique filename serverside


        for (let i = 0; i < p_files.length; i++)
        {
            let file:File | null = p_files.item(i);



            if (file)
            {
                const fileType:FileType = AssetFolderListModel.getFileTypeForExtension(FileUtil.getFileExtension(file.name));

                const newFileDto:IAssetFileDto = {
                    name           : {[AppUserModel.getInstance().langCode]: FileUtil.getFriendlyFileName(file.name)},
                    description    : {},
                    fileURI        : "",
                    previewImageURI: AssetFolderListModel.getDefaultPreviewImageUriForFileType(fileType),
                    size           : file.size,
                    fileType       : fileType,
                    targetTypes    : p_selState.viewMode === AssetFolderViewMode.PICKER && p_selState.allowedTargetType ? [p_selState.allowedTargetType] : [...FILE_TYPE_CONFIG[fileType].defaultTargetTypes],
                    importSource   : FileImportSource.LOCAL_STORAGE,
                    createdDate    : new Date()
                };
                const newFile:AssetFileModel = new AssetFileModel(newFileDto);
                newFile.uploadStatus = UploadStatus.UPLOADING;

                p_assetFolder.assetFiles.push(newFile);

                //select the first file
                if (i === 0)
                {
                    p_selState.selectedFile = newFile;
                }

                //resize image clientside
                if (fileType === FileType.IMAGE)
                {
                    const resizedBlob = await FileUtil.resizeImage(file, 1920, 1920);
                    file = new File([resizedBlob], file.name, {type: `${file.type}`})
                }

                const response:ApiResponse<IUploadFileResultDto> = await apiManager.uploadFile(ApiRequestType.POST, `/client-api/asset-folders/${p_assetFolder.ID}/files`, file);

                if (response.hasSucceeded)
                {
                    const mainFile:IFileDto = response.result!.mainFile;
                    newFile.fileURI = `assetFolder://${p_assetFolder.ID}/${encodeURIComponent(mainFile.fileName)}`;
                    newFile.size = mainFile.size;
                    newFile.createdDate = mainFile.lastModified;
                    p_assetFolder.files.push({
                        path   : mainFile.fileName,
                        version: mainFile.hash,
                        size   : mainFile.size
                    });
                    p_assetFolder.totalFileSize += mainFile.size;
                    if (response.result!.previewFile)
                    {
                        const previewFile:IFileDto = response.result!.previewFile;
                        newFile.previewImageURI = `assetFolder://${p_assetFolder.ID}/${encodeURIComponent(previewFile.fileName)}`;
                        p_assetFolder.files.push({
                            path   : previewFile.fileName,
                            version: previewFile.hash,
                            size   : previewFile.size
                        });
                        p_assetFolder.totalFileSize += previewFile.size;
                    }

                    newFile.uploadStatus = UploadStatus.IDLE;

                    await super.saveBody(p_assetFolder);
                }
                else
                {
                    //clean up failed file
                    p_assetFolder.assetFiles.splice(p_assetFolder.assetFiles.indexOf(newFile), 1);

                    newFile.uploadStatus = UploadStatus.ERROR_UPLOADING;

                    toastManager.showToast(i18n.t('CouldntUploadFile') as string, ToastType.SECONDARY);
                }
            }

        }



    }

    public async deleteFile(p_assetFolder:AssetFolderModel, p_assetFile:AssetFileModel):Promise<boolean>
    {
        const mainFilePath:string = fileManager.getFilePathFromUri(p_assetFile.fileURI);
        let endPoint:string = `/client-api/${ENTITY_CONFIG.ASSET_FOLDER.apiPath}/${p_assetFolder.ID}/files/${mainFilePath}`;
        let response:ApiResponse<IDeleteResultDto> = await apiManager.sendApiRequest(ApiRequestType.DELETE, endPoint);

        if (response.hasSucceeded)
        {
            //delete it from the assetFiles
            const index:number = p_assetFolder.assetFiles.indexOf(p_assetFile);
            if (index >= 0)
            {
                p_assetFolder.assetFiles.splice(index, 1);
            }
            //delete it from the files
            this._removeFileOutline(p_assetFolder, mainFilePath);

            if (p_assetFile.fileType === FileType.IMAGE)
            {
                //it also has a preview, so delete it too
                const previewImagePath:string = fileManager.getFilePathFromUri(p_assetFile.previewImageURI);
                let endPoint:string = `/client-api/${ENTITY_CONFIG.ASSET_FOLDER.apiPath}/${p_assetFolder.ID}/files/${previewImagePath}`;
                apiManager.sendApiRequest(ApiRequestType.DELETE, endPoint);
                this._removeFileOutline(p_assetFolder, previewImagePath);
            }

            super.saveBody(p_assetFolder);
        }
        else
        {
            //todo: show toast
        }

        return response.hasSucceeded;
    }

    public async replaceFile(p_assetFolder:AssetFolderModel, p_assetFile:AssetFileModel, p_file:File):Promise<boolean>
    {
        const rememberMainFileURI:string = p_assetFile.fileURI;
        const rememberPreviewImageURI:string = p_assetFile.previewImageURI;
        const mainFilePath:string = fileManager.getFilePathFromUri(p_assetFile.fileURI);
        const previewImagePath:string = fileManager.getFilePathFromUri(p_assetFile.previewImageURI);


        p_assetFile.uploadStatus = UploadStatus.UPLOADING;
        p_assetFile.fileURI = "";
        if (p_assetFile.fileType === FileType.IMAGE)
        {
            p_assetFile.previewImageURI = AssetFolderListModel.getDefaultPreviewImageUriForFileType(FileType.IMAGE);
        }


        const response:ApiResponse<IUploadFileResultDto> = await apiManager.uploadFile(ApiRequestType.PUT, `/client-api/asset-folders/${p_assetFolder.ID}/files/${mainFilePath}`, p_file);

        if (response.hasSucceeded)
        {
            const mainFileOutline:IFileOutlineDto = {
                path   : response.result!.mainFile.path,
                version: response.result!.mainFile.hash,
                size   : response.result!.mainFile.size
            };
            this._updateFileOutline(p_assetFolder, mainFileOutline);
            p_assetFile.fileURI = rememberMainFileURI; //todo: could get cached by cloudfront, so wait for cache invalidation
            if (response.result!.previewFile)
            {
                const previewFileOutline:IFileOutlineDto = {
                    path   : response.result!.previewFile.path,
                    version: response.result!.previewFile.hash,
                    size   : response.result!.previewFile.size
                };
                this._updateFileOutline(p_assetFolder, previewFileOutline);
                p_assetFile.previewImageURI = rememberPreviewImageURI;
            }
            p_assetFile.uploadStatus = UploadStatus.IDLE;

            super.saveBody(p_assetFolder);
        }

        //todo: p_assetFolder.totalFileSize

        return response.hasSucceeded;
    }

    private _updateFileOutline(p_assetFolder:AssetFolderModel, p_fileOutline:IFileOutlineDto)
    {
        for (let i = 0; i < p_assetFolder.files.length; i++)
        {
            if (p_assetFolder.files[i].path === p_fileOutline.path)
            {
                console.log("udpated ", p_fileOutline.path);
                p_assetFolder.files[i].size = p_fileOutline.size;
                p_assetFolder.files[i].version = p_fileOutline.version;
            }
        }
        return null;
    }

    private _removeFileOutline(p_assetFolder:AssetFolderModel, p_filePath:string)
    {
        for (let i = 0; i < p_assetFolder.files.length; i++)
        {
            if (p_assetFolder.files[i].path === p_filePath)
            {
                p_assetFolder.files.splice(i, 1);
                return;
            }
        }
    }



//---------------------------------
    // Private Methods
    //---------------------------------


}

//Singleton export
export default new AssetFolderController(ENTITY_CONFIG.ASSET_FOLDER);
