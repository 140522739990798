import {
    IDataItemDefinitionDto, IExternalListEntry,
    IPropertyDefinitionDto,
    IXlsColumn,
    IXlsImportResult
} from "@/data_tool/_model/data_tool.dto";
import PropertyModel from "@/data_tool/data_item/_model/PropertyModel";
import {DataModel} from "@/data_tool/_model/DataModel";
import DataProviderModel from "@/data_tool/data_provider/_model/DataProviderModel";
import {DtoType} from "@/_model/app.constants";
import DataItemModel from "@/data_tool/data_item/_model/DataItemModel";
import DataUtil from "@/data_tool/_controller/DataUtil";
import {InValidType, PropertyType} from "@/data_tool/_model/data_tool.constants";
import DataItemListModel from "@/data_tool/data_item/_model/DataItemListModel";
import ListModel from "@/__libs/_model/ListModel";

export default class DataItemExternalListModel extends DataModel
{



    public propertyDefinition:IPropertyDefinitionDto;

    public externalItemList:IExternalListEntry[] = []; //the items loaded from the external source


    private _selection:string | string[] | null = null; //an __id or an array of __ids
    get selection():string | string[] | null
    {
        return this._selection;
    }

    set selection(value:string | string[] | null)
    {
        // console.log("set selection:", value);
        this._selection = value;
        if (this.__parent)
        {
            this.__parent.validate();
        }
        this.dataProvider.hasChanges = true;
    }





    //---------------------------------
    // Constructor
    //---------------------------------

    constructor(p_propertyDefinition:IPropertyDefinitionDto, p_data:any, p_dataProvider:DataProviderModel, p_parent:DataModel | null)
    {
        super(p_dataProvider, p_parent);
        this.propertyDefinition = p_propertyDefinition;
        this.build(p_data);
    }

    //---------------------------------
    // Public Methods
    //---------------------------------

    public build(p_data:any)
    {
        if (p_data)
        {
            if (p_data.selection)
            {
                this._selection = DataUtil.deepCopy(p_data.selection); //these value(s) are bound with the select form element
            }
        }
        if (!this._selection && this.propertyDefinition.isMultipleSelect)
        {
            this._selection = [];
        }
    }


    public mapToDto(p_dtoType:DtoType):any
    {
        if (p_dtoType === DtoType.BODY)
        {
            return {
                selection      : this._selection
            }
        }
    }


    public checkValue():InValidType | null
    {

        if (this.propertyDefinition.isMultipleSelect)
        {
            if (this.selection!.length < this.propertyDefinition.minItems!)
            {
                return InValidType.MAKE_MIN_SELECTION;
            }
            if (this.selection!.length > this.propertyDefinition.maxItems!)
            {
                return InValidType.MAKE_MAX_SELECTION;
            }
        }
        else
        {
            if (!this.selection)
            {
                return InValidType.MAKE_A_SELECTION;
            }
        }



        return null;
    }


    public mapToRows(p_targetRow:any, p_columnPrefix:string)
    {
        if (this._selection)
        {
            p_targetRow[`${p_columnPrefix}`] = this.propertyDefinition.isMultipleSelect ? (this._selection as string[]).join(",") : this._selection;
        }
    }

    public static mapFromRow(p_propDefinition:IPropertyDefinitionDto, p_row:any, p_columns:IXlsColumn[], p_result:IXlsImportResult)
    {
        const dto:any = {};
        const selectionColumn:IXlsColumn = p_columns[0];


        const selectionValue = p_row[selectionColumn.identifier];
        if (selectionValue)
        {
            if (p_propDefinition.isMultipleSelect)
            {
                dto.selection = selectionValue.split(",");
            }
            else
            {
                dto.selection = selectionValue;
            }
        }


        return dto;
    }

}
