import IntroductionVideoModel from "@/help/introduction/_model/IntroductionVideoModel";

export default class IntroductionVideoListModel {

    public name: string = '';
    public videos: IntroductionVideoModel[] = [];

    constructor(name: string) {
        this.name = name;
    }

}
