/*
TODO:

- suffix/prefix langCode dependent
- tags (as a datatype, or fixed prop, or fixed public? > need for "tag picker")
- dataitemrenderer "..." for delete/copy
- When saving dtp if invalids: "Are you sure you want to save: the following fields still need attention: ...."


- nespresso catalogue schemas
- client library (for loading dtp's)
- recursive prevention

- translation rights
- editor in audience detail

 */

/*
KNOWN ISSUES/bugs:
- dictionary keeps previous values/validation errors
- recursive schemas
 */


import Model from "@/__libs/_model/Model";
import {Delta} from "@/data_tool/_model/data_tool.constants";
import DataProviderModel from "@/data_tool/data_provider/_model/DataProviderModel";

export abstract class DataModel extends Model
{

    public __name:string = "";

    public __parent:DataModel | null = null;

    public isValid:boolean = true;

    public invalidChildren:DataModel[] = [];

    public dataProvider:DataProviderModel;

    protected _delta:Delta = Delta.IDLE;
    public get delta():Delta
    {
        return this._delta;
    }

    public set delta(value:Delta)
    {
        if (this._delta !== value)
        {
            const previousDelta = this._delta;
            this._delta = value;
            if (this.__parent)
            {
                this.__parent.checkDelta();
            }
        }
    }

    protected includeInLogPath:boolean = true;

    // ---------------------------------
    // Constructor
    // ---------------------------------


    constructor(p_dataProvider:DataProviderModel, p_parent:DataModel | null)
    {
        super();
        this.dataProvider = p_dataProvider;
        this.__parent = p_parent;
    }


    // ---------------------------------
    // Public Methods
    // ---------------------------------

    //should be overridden
    public checkDelta():void {}

    protected _bumpDelta():void
    {
        if (this._delta === Delta.IDLE || this._delta === Delta.INITIAL)
        {
            this.delta = Delta.CHANGED;
        }
        else if (this._delta === Delta.NEW)
        {
            this.delta = Delta.NEW_CHANGED;
        }
    }

    protected _deBumpDelta():void
    {
        if (this._delta === Delta.CHANGED)
        {
            this.delta = Delta.INITIAL;
        }
        else if (this._delta === Delta.NEW_CHANGED)
        {
            this.delta = Delta.NEW;
        }
    }


    public getParents():DataModel[]
    {
        return this._recurseParents(this, []);
    }

    public getInvalidChildren():DataModel[]
    {
        return this._recurseInvalidChildren(this, []);
    }

    public logInvalidChildren()
    {
        const children:DataModel[] = this.getInvalidChildren();
        for (let i = 0; i < children.length; i++)
        {
            const child = children[i];
            console.log(child.logModelPath());
        }
    }

    public getNrOfInvalidChildren()
    {
        //todo: brute force this one, doesn't cover edge cases
        return Math.max(1, this.getInvalidChildren().length);
    }


    public logModelPath():string
    {
        let breadCrumb:string = "";
        const parents:DataModel[] = this.getParents();
        for (let i = 0; i < parents.length; i++)
        {
            const parentComp:DataModel = parents[i];
            if (parentComp.includeInLogPath)
            {
                breadCrumb += parentComp.getLogName();
                breadCrumb += " -> ";
            }
        }
        breadCrumb += this.getLogName();
        return breadCrumb;
    }

    public getLogName():string
    {
        return "";
    }

    //should be overridden to clear model properties (especially "links" to other deep objects) to clear a path for the Garbage Collector
    public kill()
    {
        this.__parent = null;
    }

    public validate()
    {

    }

    public childValidityChange(p_isValid:boolean, p_child:DataModel) //called by one of the children when it's validity changes, should "bubble up the chain"
    {

        const childIndex = this.invalidChildren.indexOf(p_child);

        if (p_isValid)
        {
            if (childIndex >= 0) //child is found
            {
                this.invalidChildren.splice(childIndex, 1); //remove it from the invalidChildren list
            }
        }
        else
        {
            if (childIndex < 0) //child is not found
            {
                this.invalidChildren.push(p_child);
            }
        }

        const isValid:boolean = this.invalidChildren.length === 0;

        if (isValid !== this.isValid)
        {
            this.isValid = isValid;
            if (this.__parent)
            {
                this.__parent.childValidityChange(this.isValid, this); // bubble up the chain
            }
        }

    }


    //---------------------------------
    // Private Methods
    //---------------------------------


    private _recurseParents(p_childModel:DataModel, p_parents_array:DataModel[])
    {
        let parentModel = p_childModel.__parent;
        if (parentModel)
        {
            p_parents_array.unshift(parentModel);
            this._recurseParents(parentModel, p_parents_array);
        }
        return p_parents_array;
    }

    private _recurseInvalidChildren(p_parentModel:DataModel, p_children_array:DataModel[])
    {
        for (let i = 0; i < p_parentModel.invalidChildren.length; i++)
        {
            const child:DataModel = p_parentModel.invalidChildren[i];
            if (child.invalidChildren.length === 0)
            {
                p_children_array.push(child);
            }
            this._recurseInvalidChildren(child, p_children_array);

        }
        return p_children_array;
    }

}

