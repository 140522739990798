<template>
  <div class="data-provider-detail">

    <transition name="fade">
      <div v-if="!selState.selected"
           class="empty-message">
        {{ $t('PleaseSelectDataProvider') }}
      </div>
    </transition>

    <transition name="fade">
      <template v-if="selState.selected">
        <DataProviderEditor :dataProvider="dataProvider" :isStandalone="false"/>
      </template>
    </transition>

  </div>
</template>


<script lang="ts">
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import DataProviderSelectionState from "@/data_tool/data_provider/_model/DataProviderSelectionState";
import DataProviderModel from "@/data_tool/data_provider/_model/DataProviderModel";
import dataProviderController from "@/data_tool/data_provider/_controller/DataProviderController";
import EntityActionBox from "@/entity/_view/EntityActionBox.vue";
import DataProviderEditor from "@/data_tool/data_provider/_view/DataProviderEditor.vue";


@Component({
  components: {EntityActionBox, DataProviderEditor}
})
export default class DataProviderDetail extends Vue {

  @Prop() private selState!: DataProviderSelectionState;


  get dataProvider(): DataProviderModel | null {
    return this.selState.selected;
  }

  public mounted() {
    window.addEventListener('beforeunload', this._beforeUnload);

  }

  public beforeDestroy() {
    if (this.dataProvider && this.dataProvider.hasChanges) {
      dataProviderController.saveBody(this.dataProvider);
    }
    window.removeEventListener('beforeunload', this._beforeUnload)

  }

  private _beforeUnload(e: BeforeUnloadEvent) {
    if (this.dataProvider && this.dataProvider.hasChanges) {
      e.returnValue = "";
      return e.returnValue;
    }
  }

  @Watch('dataProvider', {immediate: true, deep: false})
  private _onActiveDataProviderChange(newDtp: DataProviderModel | null, oldDtp: DataProviderModel | null) {
    if (oldDtp && oldDtp.hasChanges && oldDtp.isEditable) {
      dataProviderController.saveBody(oldDtp);
    }
  }
}
</script>
