import {CrmProvider} from "@/crm/_model/crm.constants";
import AppUserModel from "@/project/user/_model/AppUserModel";

class CrmModel
{


    public isImportingXls:boolean = false;



    //---------------------------------
    // Singleton Constructor
    //---------------------------------

    private static _instance:CrmModel;

    constructor()
    {
        if (!CrmModel._instance)
        {
            CrmModel._instance = this;
        }
    }

    public static getInstance():CrmModel
    {
        if (!CrmModel._instance)
        {
            new CrmModel();
        }

        return CrmModel._instance;
    }


    //---------------------------------
    // Public Methods
    //---------------------------------


    public getAvailableCrmProvidersForUser():CrmProvider[]
    {
        const allProviders:CrmProvider[] = [CrmProvider.CONNECTOR_C4C, CrmProvider.HUBSPOT, CrmProvider.PIPEDRIVE, CrmProvider.SALESFORCE];
        const availableProviders:CrmProvider[] = [];

        for (let i = 0; i < allProviders.length; i++)
        {
            const crmProvider = allProviders[i];
            if (AppUserModel.getInstance().project.crmProviders[crmProvider])
            {
                availableProviders.unshift(crmProvider);
            }
        }
        return availableProviders;
    }


}

export default CrmModel;
