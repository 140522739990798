import SyncModel from "@/sync/_model/SyncModel";
import {ISyncFileDto, ISyncFileRecord, ISyncItemDto, ISyncItemFileDto} from "@/sync/_model/sync.dto";
import {SyncFileRetrieveStatus, SyncProcessStatus, SyncProcessType} from "@/sync/_model/sync.constants";
import syncController from "@/sync/_controller/SyncController";
import SyncProcess from "@/sync/_controller/process/SyncProcess";
import {IEntityBodyDto} from "@/entity/_model/entity.dto";
import {StorageScope} from "@/_model/app.constants";
import {IMultiLangString, LanguageManager} from "@/__libs/language_manager/LanguageManager";
import fileManager from "@/_controller/FileManager";

abstract class SyncItemProcess extends SyncProcess
{


    //---------------------------------
    // Properties

    //---------------------------------

    protected _syncModel:SyncModel = SyncModel.getInstance();


    protected _syncItems:ISyncItemDto[] = [];
    //a dictionary with the identifier as key, so we can check quickly if the item already exists when we add it to the _syncItems list
    private _syncItemsDict:{ [index:string]:boolean } = {};


    // "ItemFiles" are always json files:
    // - in DATA processes these are the data files themselves
    // - in FILE_GROUP processes these are the cfg.json files
    protected _itemFiles:ISyncItemFileDto[] = [];

    protected _storagePath:string = "";
    protected _storedBehindApi:boolean = false;




    private _filesToRetrieveResolved:boolean = false;


    constructor(p_processType:SyncProcessType, p_dependencyLevel?:number, p_syncItems?:ISyncItemDto[], p_storagePath?:string, p_storageScope?:StorageScope)
    {
        super(p_processType, p_dependencyLevel);
        if (p_syncItems)
        {
            this._syncItems = [...p_syncItems];
        }
        if (p_storagePath)
        {
            this._storagePath = p_storagePath;
        }
        if (p_storageScope)
        {
            this._storageScope = p_storageScope;
        }
    }



    //---------------------------------
    // Public Methods
    //---------------------------------


    public addSyncItem(p_syncItemDto:ISyncItemDto)
    {
        //first check if item already exists by identifier
        if (!this._syncItemsDict[p_syncItemDto.identifier])
        {
            this._syncItems.push(p_syncItemDto);
            this._syncItemsDict[p_syncItemDto.identifier] = true;

            if (this._filesToRetrieveResolved)
            {
                //the syncItem has not been added to the _syncItems list yet (because if this function is called when this process is already running, the _resolveItemFilesToRetrieve has already finished)
                this._addItemFile(p_syncItemDto);
            }
        }
    }

    public startProcess()
    {
        this._resolveDependencyProcesses();
        // console.log("starting", this.processType, this._storagePath, this._storageScope, this._syncItems);

        this._resolveItemFilesToRetrieve();

        this._retrieveNextItemFile();

    }


    public resumeProcess()
    {
        this._retrieveNextItemFile();
    }

    //---------------------------------
    // Private Methods
    //---------------------------------

    private _resolveItemFilesToRetrieve()
    {
        for (let i = 0; i < this._syncItems.length; i++)
        {
            const syncItem:ISyncItemDto = this._syncItems[i];

            this._addItemFile(syncItem);
        }
        this._filesToRetrieveResolved = true;
    }

    private _addItemFile(p_syncItemDto:ISyncItemDto)
    {
        const syncItemFile:ISyncItemFileDto = {
            identifier    : p_syncItemDto.identifier,
            remoteVersion : p_syncItemDto.remoteVersion!,
            path          : this._resolveItemFilePath(p_syncItemDto),
            storageScope  : this._storageScope,
            retrieveStatus: SyncFileRetrieveStatus.IDLE,
            nrOfAttempts  : 0
        };
        this._itemFiles.push(syncItemFile);
    }


    protected _retrieveNextItemFile()
    {
        if (this._syncModel.isSyncing && !this._syncModel.isPaused)
        {
            for (let i = 0; i < this._itemFiles.length; i++)
            {
                const itemFile:ISyncItemFileDto = this._itemFiles[i];
                if (itemFile.retrieveStatus === SyncFileRetrieveStatus.IDLE)
                {
                    this._retrieveItemFile(itemFile);
                    return;
                }
            }
            this._finishProcess();
        }
    }

    protected async _retrieveItemFile(p_itemFile:ISyncItemFileDto)
    {
        let needForFetch:boolean = true;
        let fileBody:IEntityBodyDto | null = null;
        if (p_itemFile.remoteVersion)
        {
            //check idb for local version to see if fetch is needed
            const fileRecord:ISyncFileRecord | null = await this._syncDb.getFileRecord(p_itemFile.path);

            if (fileRecord && fileRecord.localVersion && fileRecord.localVersion === p_itemFile.remoteVersion && fileRecord.body)
            {
                // console.log("FOUND in idb and up to date, extracting from idb:", p_itemFile.path);
                fileBody = fileRecord.body;
                p_itemFile.retrieveStatus = SyncFileRetrieveStatus.RETRIEVED;
                needForFetch = false;
            }
        }

        if (needForFetch)
        {
            fileBody = await syncController.fetchFile(p_itemFile, this._storedBehindApi);
        }

        if (fileBody)
        {
            p_itemFile.body = fileBody;
            this._parseRetrievedItemFile(p_itemFile);
            // await Util.timeOut(500);
            await syncController.storeFile(p_itemFile); //todo: try not awaiting this for faster sync

        }
        this._retrieveNextItemFile();
    }



    //---------------------------------
    // Overridden Methods
    //---------------------------------

    protected _resolveDependencyProcesses() {}

    protected _resolveItemFilePath(p_syncItem:ISyncItemDto):string { return ""}

    protected _parseRetrievedItemFile(p_itemFile:ISyncItemFileDto) {}

}

export default SyncItemProcess;
