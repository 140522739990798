<template>
  <div class="box box--full-height list-overview team-users-overview">
    <div class="list-overview-filters">
      <b-input-group>
        <template #prepend>
          <b-input-group-text><i class="fas fa-search"></i></b-input-group-text>
        </template>
        <b-form-input v-model="selState.searchFilter"
                      :placeholder="$t('SearchUsers')"></b-form-input>
      </b-input-group>
      <div class="filter-button" @click="_orderTeamUsersAlphabetClick"
           :class="{ available: true, active: orderedAlphabet }" v-b-tooltip.hover.top
           :title="$t('ToolTipOrderAlphabet')">
        <i class="fas fa-sort-alpha-down fa-lg"></i>
      </div>
    </div>

    <div class="team-users-overview-shown">
      <b-form-group :label="$t('showUsersFrom')" label-for="team-users-overview-shown">
        <b-form-select
            id="team-users-overview-shown"
            v-model="showUsersFromTeamID">
          <option v-for="team in availableTeams" :key="team.teamID"
                  :value="team.teamID">{{ team.displayName }}
          </option>
        </b-form-select>
      </b-form-group>
    </div>

    <b-button block variant="primary" class="list-overview-button" @click="_onCreateTeamUserBtnClick"
              :id="hintIds.USERS_CREATE_USER">
      {{ $t('DashboardMasterAddTeamUserButton') }}
    </b-button>

    <div class="list-overview-items">

      <transition name="fade">
        <div v-if="currentState === teamUsersViewState.LOADING"
             class="empty-loader">
          <b-spinner variant="dark"></b-spinner>
        </div>
      </transition>

      <transition name="fade">
        <div v-if="currentState === teamUsersViewState.LOADED">
          <TeamUserItemRenderer
              v-for="teamUser in filteredTeamUsers"
              :teamUser="teamUser"
              :key="teamUser.userID"
              v-on:onClick="_onTeamUserClick"
              :selState="selState"
          />
        </div>
      </transition>

    </div>

    <b-modal id="create-teamUser-box" :title="$t('DashboardMasterAddTeamUserButton')"
             hide-footer>
      <CreateTeamUserBox v-on:onFinish="_onCreateTeamUserFinish"></CreateTeamUserBox>
    </b-modal>

    <b-popover custom-class="hint-popover" :target="hintIds.USERS_CREATE_USER"
               placement="right"
               triggers="manual"
               boundary="window">
      <Hint :id="hintIds.USERS_CREATE_USER"></Hint>
    </b-popover>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from "vue-property-decorator";
import CreateTeamUserBox from "@/team/_view/CreateTeamUserBox.vue";
import teamUserController from "@/team/_controller/TeamUserController";
import TeamUserModel from "@/team/_model/TeamUserModel";
import SelectionState from "@/__libs/_model/SelectionState";
import TeamUserListModel from "@/team/_model/TeamUserListModel";
import TeamUserItemRenderer from "@/team/_view/TeamUserItemRenderer.vue";
import {LoadingStatus} from "@/entity/_model/entity.constants";
import TeamUserDetail from "@/team/_view/TeamUserDetail.vue";
import AppUserModel from "@/project/user/_model/AppUserModel";
import Hint from "@/help/hint/_view/Hint.vue";
import {HintGroupIds, HintIds} from "@/help/hint/_model/hint.constants";
import HintListController from "@/help/hint/_controller/HintListController";
import SlideSelectionState from "@/slide/_model/SlideSelectionState";

enum TeamUsersViewState {
    IDLE,
    LOADING,
    ERROR_LOADING,
    LOADED,
    NO_USERS
}

@Component({
    components: {Hint, TeamUserDetail, CreateTeamUserBox, TeamUserItemRenderer}
})
export default class TeamUsersOverview extends Vue {
    @Prop() private selState!: SelectionState<TeamUserModel>;

    private hintIds: typeof HintIds = HintIds;

    private teamUsersViewState: typeof TeamUsersViewState = TeamUsersViewState;
    private currentState: TeamUsersViewState = TeamUsersViewState.IDLE;

    private teamUserListModel: TeamUserListModel = TeamUserListModel.getInstance();

    // public selState: SelectionState<TeamUserModel> = TeamUserListModel.getInstance().globalSelState;

    private teamUsers: TeamUserModel[] = this.teamUserListModel.list;

    private orderedAlphabet: boolean = false;

    private showUsersFromTeamID: number = 0;
    //---------------------------------
    // Vue Computed properties
    //---------------------------------

    get filteredTeamUsers() {
        let shownUsers = this.teamUsers;
        if (this.showUsersFromTeamID > 0) {
            shownUsers = this.teamUsers.filter(teamUser => {
                return teamUser.teamID === this.showUsersFromTeamID
            });
        }

        if (this.selState.searchFilter.length > 1) {
            return shownUsers.filter(teamUser => {
                return teamUser.displayName.toLowerCase().indexOf(this.selState.searchFilter.toLowerCase()) > -1
            });
        } else {
            return shownUsers;
        }
    }

    get activeTeamUser(): TeamUserModel | null {
        return this.selState.selected;
    }

    get bodyLoaded() {
        if (this.activeTeamUser) {
            return this.activeTeamUser.loadingStatus >= LoadingStatus.BODY_LOADED;
        }
        return false;
    }

    get availableTeams() {
        return [{teamID: 0, displayName: this.$t('allTeams')}, ...AppUserModel.getInstance().shareableTeams];
    }

    //---------------------------------
    // Public / lifecycle methods
    //---------------------------------

    public async mounted() {
        this.currentState = TeamUsersViewState.LOADING;
        await teamUserController.fetchAllTeamUsers();
        this.currentState = TeamUsersViewState.LOADED;
        //
        this._orderTeamUsersAlphabet();
        //
        HintListController.startGroup(HintGroupIds.USERS);
    }

    //---------------------------------
    // Private / helper methods
    //---------------------------------

    private async _onTeamUserClick(p_teamUser: TeamUserModel, p_e: Event) {
        this.selState.selected = p_teamUser;

        if (!this.bodyLoaded) {
            await teamUserController.fetchTeamUserBody(this.activeTeamUser!);
        }

    }

    private _onCreateTeamUserBtnClick(p_e: Event) {
        this.$bvModal.show('create-teamUser-box');
    }

    private async _onCreateTeamUserFinish(p_createdTeamUser: TeamUserModel) {
        this.$bvModal.hide('create-teamUser-box');
        if (p_createdTeamUser) {
            this.selState.selected = p_createdTeamUser;

            teamUserController.fetchTeamUserBody(this.activeTeamUser!);


            // order
            if (this.orderedAlphabet) {
                this._orderTeamUsersAlphabet();
            } else {
                this._orderTeamUsersCreatedDate();
            }
        }
    }

    // ordering

    private _orderTeamUsersAlphabetClick() {
        if (this.orderedAlphabet) {
            this._orderTeamUsersCreatedDate();
            this.orderedAlphabet = false;
        } else {
            this._orderTeamUsersAlphabet();
            this.orderedAlphabet = true;
        }
    }

    private _orderTeamUsersAlphabet() {
        this.teamUsers.sort((a: TeamUserModel, b: TeamUserModel) => {
            if (a.displayName.toLowerCase() < b.displayName.toLowerCase()) {
                return -1;
            }
            if (a.displayName.toLowerCase() > b.displayName.toLowerCase()) {
                return 1;
            }
            return 0;
        });
    }

    private _orderTeamUsersCreatedDate() {
        this.teamUsers.sort((a: TeamUserModel, b: TeamUserModel) => {
            let dateA: Date | null = null;
            if (a.createdDate instanceof Date) {
                dateA = a.createdDate;
            } else {
                dateA = new Date(a.createdDate);
            }
            let dateB: Date | null = null;
            if (b.createdDate instanceof Date) {
                dateB = b.createdDate;
            } else {
                dateB = new Date(b.createdDate);
            }
            if (dateA > dateB) {
                return -1;
            }
            if (dateA < dateB) {
                return 1;
            }
            // if date the same, order on dipslay name
            if (a.displayName.toLowerCase() < b.displayName.toLowerCase()) {
                return -1;
            }
            if (a.displayName.toLowerCase() > b.displayName.toLowerCase()) {
                return 1;
            }
            return 0;
        });
    }


}
</script>
