import ContentAppResourceListModel from "@/content_app/resource/_model/ContentAppResourceListModel";
import ContentAppResourceModel from "@/content_app/resource/_model/ContentAppResourceModel";
import contentAppResourceController from "@/content_app/resource/_controller/ContentAppResourceController";

class ContentAppResourceListController {

    public async fetchAll(contentAppResourceIdentifiers: string[]): Promise<boolean> {
        const contentAppResources: ContentAppResourceListModel = ContentAppResourceListModel.getInstance();

        // if already items in the list, no need to recheck
        if (contentAppResources.list.length === 0) {
            for (const contentAppResourceIdentifier of contentAppResourceIdentifiers) {
                const contentAppResource: ContentAppResourceModel | null = await contentAppResourceController.fetch(contentAppResourceIdentifier);
                if (contentAppResource) {
                    contentAppResources.list.push(contentAppResource);
                }
            }
        }

        return true;
    }

}

export default new ContentAppResourceListController();