<template>
  <div class="main-menu-bar">
    <div class="main-menu-bar__menu">
      <MainLogo></MainLogo>
      <MainMenu></MainMenu>
    </div>
    <div class="main-menu-bar__extra">
      <MainStatus></MainStatus>
    </div>
  </div>
</template>

<script lang="ts">
import {Component, Vue} from "vue-property-decorator";
import MainMenu from "@/_view/menu/MainMenu.vue";
import MainLogo from "@/_view/components/MainLogo.vue";
import MainStatus from "@/_view/components/MainStatus.vue";
import NetworkManager from "@/_controller/NetworkManager";

@Component({
  components: {MainMenu, MainLogo, MainStatus}
})
export default class MainBar extends Vue {
}
</script>
