import {IContentBodyDto, IContentMetaDto} from "@/content/_model/content.dto";
import {EntityType} from "@/entity/_model/entity.constants";
import EntityModel from "@/entity/_model/EntityModel";
import languageManager, {
    IMultiLangString,
    IMultiLangTransValue,
    LanguageManager
} from "@/__libs/language_manager/LanguageManager";
import {ITranslationMultiLangDto} from "@/_model/app.dto";

export default class ContentModel extends EntityModel implements IContentBodyDto, IContentMetaDto
{

    public entityType:EntityType = EntityType.CONTENT;

    //body
    public name:IMultiLangString = {};
    public portalName:IMultiLangString = {};
    public poster:any = {};
    public notes:any = {};
    public funnelStages:string[] = [];


    // meta
    public isQuickLaunch:boolean = false;
    public priority:number = 0;



    //---------------------------------
    // Public Methods
    //---------------------------------


    //should be overridden
    public renameAsCopy()
    {
        LanguageManager.renameAsCopy(this.name);
    }

    public getMultiLangTranslations():ITranslationMultiLangDto[]
    {
        return [
            {identifier: "name", multiLangTrans: languageManager.convertMultiLangStringToTrans(this.name)},
            {identifier: "notes", multiLangTrans: languageManager.convertMultiLangStringToTrans(this.notes)}
        ];
    }



}
