<template>
    <div class="data-item-dictionary">

        <transition name="fade">
            <div v-if="currentState === dataItemDictionaryState.NO_DTP_SELECTED && showChooseList">
                <button class="btn btn-secondary btn-block btn-sm select-button" @click="_onSelectDtpBtnClick">
                    {{chooseListButtonLabel}}
                </button>
            </div>
        </transition>

        <transition name="fade">
            <div v-if="currentState === dataItemDictionaryState.LOADING"
                 class="loading d-flex justify-content-center align-items-center">
                <b-spinner variant="dark"></b-spinner>
            </div>
        </transition>

        <transition name="fade">
            <div v-if="currentState === dataItemDictionaryState.ERROR_LOADING"
                 class="placeholder-text text-danger">
                {{ $t('ErrorConnectionRequired') }}
            </div>
        </transition>

        <transition name="fade">
            <div class="data-item-list mt-2" v-if="currentState === dataItemDictionaryState.LOADED">
              <div class="data-item-list__list-wrapper">
                <div class="data-item-list__list">
                  <DataItemRenderer
                      v-for="dataItem in this.property.dataItemList.list"
                      :dataItem="dataItem"
                      :key="dataItem.__reference"
                      v-on:onClick="_onDataItemClick"
                      :selState="selState"
                      :readOnly="true"
                  />
                </div>
                <button v-if="showChooseList" class="btn btn-secondary btn-block btn-sm select-button mt-3"
                        @click="_onSelectDtpBtnClick">{{chooseListButtonLabel}}
                </button>
              </div>


<!--              <transition name="fade">-->
                <div class="data-item-list__content" v-if="selState.selected" :style="{marginTop: contentOffset + 'px'}" :key="selState.selected.__id">

                  <DataItem :dataItem="dataItemDictionary.getValue(selState.selected.__id)"/>

                </div>
<!--              </transition>-->


<!--                    <div v-for="keyDataItem in this.property.dataItemList.list" :key="keyDataItem.__id"-->
<!--                         :class="{ active: isActive(keyDataItem.__id) }" class="data-item-dictionary-item">-->

<!--                        <div class="d-flex align-items-center mb-2 data-item-dictionary-item__name"-->
<!--                             @click="_onClick(keyDataItem.__id)">-->

<!--                            <div class="data-item-dictionary-item__name__button mr-2">-->
<!--                                <i class="fas fa-chevron-circle-down text-secondary"></i>-->
<!--                                <i class="fas fa-chevron-circle-up"></i>-->
<!--                            </div>-->

<!--                            <AAImage v-if="getPosterUrl(keyDataItem)" :imageUrl="getPosterUrl(keyDataItem)"-->
<!--                                     class="list-image mr-2"/>-->

<!--                            <div :class="{ 'text-danger': !dataItemDictionary.getValue(keyDataItem.__id).isValid }">-->
<!--                                <span>{{ keyDataItem.nameProp.displayedValue }}</span>-->
<!--                                <b-badge v-if="!dataItemDictionary.getValue(keyDataItem.__id).isValid" variant="danger">-->
<!--                                    {{ dataItemDictionary.getValue(keyDataItem.__id).getNrOfInvalidChildren() }}-->
<!--                                </b-badge>-->
<!--                            </div>-->
<!--                        </div>-->

<!--                        <DataItem :dataItem="dataItemDictionary.getValue(keyDataItem.__id)"-->
<!--                                  class="data-item-dictionary-item__content"-->
<!--                                  v-if="isActive(keyDataItem.__id)"/>-->
<!--                    </div>-->
<!--                <button v-if="showChooseList" class="btn btn-secondary btn-block btn-sm select-button"-->
<!--                        @click="_onSelectDtpBtnClick">{{chooseListButtonLabel}}-->
<!--                </button>-->
            </div>
        </transition>

        <b-modal :id="this.property.propertyDefinition.itemDefinitionURI" :title="$t('SelectDataList')" hide-footer>
            <SelectDataItemListBox :itemDefinitionURI="this.property.propertyDefinition.itemKeyDefinitionURI"
                                   :dataProviderURI="this.dataItemDictionary.dataProviderURI"
                                   v-on:onFinish="_onSelectDtpFinish">
            </SelectDataItemListBox>
        </b-modal>
    </div>
</template>


<script lang="ts">
    import {Component, Prop, Vue, Watch} from "vue-property-decorator";
    import dataProviderController from "@/data_tool/data_provider/_controller/DataProviderController";
    import PropertyModel from "@/data_tool/data_item/_model/PropertyModel";
    import SelectDataProviderBox from "@/data_tool/data_item/_view/SelectDataItemListBox.vue";
    import DataItemDictionaryModel from "@/data_tool/data_item/_model/DataItemDictionaryModel";
    import languageManager, {IMultiLangString} from "@/__libs/language_manager/LanguageManager";
    import fileManager from "@/_controller/FileManager";
    import DataItemModel from "@/data_tool/data_item/_model/DataItemModel";
    import AAImage from "@/_view/components/AAImage.vue";
    import AppUserModel from "@/project/user/_model/AppUserModel";
    import {RightAction, RIGHTS} from "@/team/_model/role.constants";
    import {EntityType} from "@/entity/_model/entity.constants";
    import ContentAppModel from "@/content_app/_model/ContentAppModel";
    import DataItemRenderer from "@/data_tool/data_item/_view/DataItemRenderer.vue";
    import DataItemListSelectionState from "@/data_tool/data_item/_model/DataItemListSelectionState";

    enum DataItemDictionaryState
    {
        IDLE,
        NO_DTP_SELECTED,
        LOADING,
        ERROR_LOADING,
        LOADED
    }

    @Component({
        components: {
            SelectDataItemListBox: SelectDataProviderBox,
            DataItem             : () => import('@/data_tool/data_item/_view/DataItem.vue'),
            AAImage,
          DataItemRenderer
        }
    })
    export default class DataItemDictionary extends Vue
    {
        //---------------------------------
        // Vue Component props
        //---------------------------------

        @Prop() private property!:PropertyModel;

        @Prop() private dataItemDictionary!:DataItemDictionaryModel;


        //---------------------------------
        // Vue Component data
        //---------------------------------


        private dataItemDictionaryState:typeof DataItemDictionaryState = DataItemDictionaryState;
        private currentState:DataItemDictionaryState = DataItemDictionaryState.IDLE;

        private activeItemID:string | null = null;


      //---------------------------------
        // Vue Computed properties
        //---------------------------------

        get chooseListButtonLabel()
        {
            if (this.dataItemDictionary.itemKeyDefinition.chooseListButtonLabel)
            {
                return languageManager.getTranslationForValue<string>(this.dataItemDictionary.itemKeyDefinition.chooseListButtonLabel, AppUserModel.getInstance().langCode)
            }
            else
            {
                return this.$t("ChooseList");
            }
        }

        get showChooseList():boolean
        {
            return this.property.isEditable && AppUserModel.getInstance().rights.indexOf(RIGHTS.CHOOSE_DATAPROVIDER_LISTS.identifier) >= 0;
        }

        get selState()
        {
          return this.dataItemDictionary.selState;
        }

      get contentOffset(): number {
        return  (this.selState.element as HTMLElement).offsetTop - 5;
      }

        //---------------------------------
        // Public / lifecycle methods
        //---------------------------------


        public async created()
        {
            // console.log(this.property.propertyDefinition.identifier, this.property.propertyDefinition);
        }

        public async mounted()
        {

        }




        //---------------------------------
        // Private / helper methods
        //---------------------------------

      private async scrollSelectedItemInView() {
        await Vue.nextTick();
        this.selState.element.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'start'});
      }

      private async _onDataItemClick(p_dataItem:DataItemModel, p_e:Event)
      {
        this.selState.selected = p_dataItem;
        await this.scrollSelectedItemInView();
      }

        private isActive(p_ID:string)
        {
            return p_ID === this.activeItemID;
        }

        //this is the actual entrypoint of this component, is called after mount and whenever another property is set
        @Watch('property', {immediate: true, deep: false})
        private async _onActivePropertyChange(newProperty:PropertyModel | null, oldProperty:PropertyModel | null)
        {
            //check if a dtp needs to be selected or loaded
            if (this.dataItemDictionary.dataProviderURI)
            {
                await this.loadDtp(this.dataItemDictionary.dataProviderURI);
            }
            else
            {
                this.currentState = DataItemDictionaryState.NO_DTP_SELECTED;
            }
        }


        private async loadDtp(p_dataProviderURI:string)
        {
            this.currentState = DataItemDictionaryState.LOADING;
            const dependenciesFetched:boolean = await dataProviderController.fetchDataItemList(p_dataProviderURI, this.property);
            if (dependenciesFetched)
            {
                this.currentState = DataItemDictionaryState.LOADED;
            }
            else
            {
                this.currentState = DataItemDictionaryState.ERROR_LOADING;
            }
        }


        private async _onSelectDtpBtnClick(p_e:Event)
        {
            let changeConfirmed:boolean = true;
            if (this.dataItemDictionary.dataProviderURI) //there is need for confirmation
            {
                changeConfirmed = await this.$bvModal.msgBoxConfirm(this.$t("SureChangeData") as string, {centered: true});
            }
            if (changeConfirmed)
            {
                this.$bvModal.show(this.property.propertyDefinition.itemDefinitionURI!);
            }
        }


        private async _onSelectDtpFinish(p_selectedDtpURI:string)
        {
            this.$bvModal.hide(this.property.propertyDefinition.itemDefinitionURI!);

            if (p_selectedDtpURI && p_selectedDtpURI !== this.dataItemDictionary.dataProviderURI)
            {
                this.dataItemDictionary.dataProviderURI = p_selectedDtpURI;
                await this.loadDtp(p_selectedDtpURI);
            }
        }

        private getPosterUrl(p_dataItem:DataItemModel):string | null
        {
            if (p_dataItem.posterProp)
            {
                let posterURI:string;
                if (p_dataItem.posterProp.propertyDefinition.isMultiLingual)
                {
                    posterURI = languageManager.getTranslationForValue<string>(p_dataItem.posterProp.rawValue as IMultiLangString, p_dataItem.dataProvider.activeLangCode);
                }
                else
                {
                    posterURI = p_dataItem.posterProp.rawValue;
                }
                if (posterURI)
                {
                    return fileManager.getFileUrl(posterURI);
                }
            }
            return null;
        }

        public _onClick(p_ID:string)
        {
            if (this.activeItemID === p_ID)
            {
                this.activeItemID = null;
            }
            else
            {
                this.activeItemID = p_ID
            }
        }

    }
</script>
