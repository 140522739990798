import apiManager, {ApiRequestType, ApiResponse} from "@/_controller/ApiManager";
import {
    IGamificationLeaderBoardUserDto,
    IGamificationTargetValueDto,
    IGamificationUserStateDto
} from "@/gamification/_model/gamification.dto";
import AppUserModel from "@/project/user/_model/AppUserModel";

class GamificationController {

    public async getUserState(): Promise<boolean> {
        const response: ApiResponse<IGamificationUserStateDto> = await apiManager.sendApiRequest(ApiRequestType.GET, `/client-api/gamification/user/state`);
        if (response.hasSucceeded) {
            const userState: IGamificationUserStateDto = response.result as IGamificationUserStateDto;
            AppUserModel.getInstance().gamificationState = userState;
        }
        return response.hasSucceeded;
    }

    public async getUserTargetValues(p_yearNr: number, p_monthNr: number): Promise<boolean> {
        const response: ApiResponse<IGamificationTargetValueDto[]> = await apiManager.sendApiRequest(ApiRequestType.GET, `/client-api/gamification/user/targets/${p_yearNr}/${p_monthNr}`);
        if (response.hasSucceeded) {
            const targetValues: IGamificationTargetValueDto[] = response.result as IGamificationTargetValueDto[];
            for (const targetValue of targetValues) {
                targetValue.yearNr = p_yearNr;
                targetValue.monthNr = p_monthNr;
            }
            AppUserModel.getInstance().gamificationTargets = targetValues;
        }
        return response.hasSucceeded;
    }

    public async getLeaderBoard(p_teamIDs: number[], p_yearNr?: number, p_monthNr?: number): Promise<boolean> {
        let endpointUrl: string = `/client-api/gamification/leaderboard/${p_teamIDs.join("-")}/`;
        if (p_yearNr !== undefined && p_monthNr !== undefined) {
            endpointUrl += `${p_yearNr}/${p_monthNr}/`;
        }
        const response: ApiResponse<IGamificationLeaderBoardUserDto[]> = await apiManager.sendApiRequest(ApiRequestType.GET, endpointUrl);
        if (response.hasSucceeded) {
            const leaderBoard: IGamificationLeaderBoardUserDto[] = response.result as IGamificationLeaderBoardUserDto[];
            AppUserModel.getInstance().gamificationLeaderboard = leaderBoard;
        }
        return response.hasSucceeded;
    }
}

//Singleton export
export default new GamificationController();
