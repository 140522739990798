import Vue from 'vue';
import VueI18n, {DateTimeFormats} from 'vue-i18n';
import CS from '@/__app_assets/i18n/pwa_cs.json';
import DE from '@/__app_assets/i18n/pwa_de.json';
import EN from '@/__app_assets/i18n/pwa_en.json';
import ES from '@/__app_assets/i18n/pwa_es.json';
import FR from '@/__app_assets/i18n/pwa_fr.json';
import IT from '@/__app_assets/i18n/pwa_it.json';
import NL from '@/__app_assets/i18n/pwa_nl.json';
import PT from '@/__app_assets/i18n/pwa_pt.json';
import RO from '@/__app_assets/i18n/pwa_ro.json';
import SK from '@/__app_assets/i18n/pwa_sk.json';
import ZH from '@/__app_assets/i18n/pwa_zh.json';
import TR from '@/__app_assets/i18n/pwa_tr.json';

Vue.use(VueI18n);

const dateTimeFormats: DateTimeFormats | undefined = {
    'cs'   : {
        short: {
            year: 'numeric', month: '2-digit', day: '2-digit'
        },
        long : {
            year   : 'numeric', month: 'short', day: 'numeric',
            weekday: 'short', hour: 'numeric', minute: 'numeric'
        }
    },
    'de'   : {
        short: {
            year: 'numeric', month: '2-digit', day: '2-digit'
        },
        long : {
            year   : 'numeric', month: 'short', day: 'numeric',
            weekday: 'short', hour: 'numeric', minute: 'numeric'
        }
    },
    'en'   : {
        short: {
            year: 'numeric', month: '2-digit', day: '2-digit'
        },
        long : {
            year   : 'numeric', month: 'short', day: 'numeric',
            weekday: 'short', hour: 'numeric', minute: 'numeric'
        }
    },
    'es'   : {
        short: {
            year: 'numeric', month: '2-digit', day: '2-digit'
        },
        long : {
            year   : 'numeric', month: 'short', day: 'numeric',
            weekday: 'short', hour: 'numeric', minute: 'numeric'
        }
    },
    'fr'   : {
        short: {
            year: 'numeric', month: '2-digit', day: '2-digit'
        },
        long : {
            year   : 'numeric', month: 'short', day: 'numeric',
            weekday: 'short', hour: 'numeric', minute: 'numeric'
        }
    },
    'it'   : {
        short: {
            year: 'numeric', month: '2-digit', day: '2-digit'
        },
        long : {
            year   : 'numeric', month: 'short', day: 'numeric',
            weekday: 'short', hour: 'numeric', minute: 'numeric'
        }
    },
    'nl'   : {
        short: {
            year: 'numeric', month: '2-digit', day: '2-digit'
        },
        long : {
            year   : 'numeric', month: 'short', day: 'numeric',
            weekday: 'short', hour: 'numeric', minute: 'numeric'
        }
    },
    'pt'   : {
        short: {
            year: 'numeric', month: '2-digit', day: '2-digit'
        },
        long : {
            year   : 'numeric', month: 'short', day: 'numeric',
            weekday: 'short', hour: 'numeric', minute: 'numeric'
        }
    },
    'ro'   : {
        short: {
            year: 'numeric', month: '2-digit', day: '2-digit'
        },
        long : {
            year   : 'numeric', month: 'short', day: 'numeric',
            weekday: 'short', hour: 'numeric', minute: 'numeric'
        }
    },
    'sk'   : {
        short: {
            year: 'numeric', month: '2-digit', day: '2-digit'
        },
        long : {
            year   : 'numeric', month: 'short', day: 'numeric',
            weekday: 'short', hour: 'numeric', minute: 'numeric'
        }
    },
    'zh-tw': {
        short: {
            year: 'numeric', month: '2-digit', day: '2-digit'
        },
        long : {
            year   : 'numeric', month: 'short', day: 'numeric',
            weekday: 'short', hour: 'numeric', minute: 'numeric'
        }
    },
    'tr': {
        short: {
            year: 'numeric', month: '2-digit', day: '2-digit'
        },
        long : {
            year   : 'numeric', month: 'short', day: 'numeric',
            weekday: 'short', hour: 'numeric', minute: 'numeric'
        }
    }
};

// @ts-ignore
const i18n = new VueI18n({
    dateTimeFormats,
    locale        : 'en',
    fallbackLocale: 'en',
    // fallbackUnset:'en',
    messages      : {
        'cs'   : CS,
        'de'   : DE,
        'en'   : EN,
        'es'   : ES,
        'fr'   : FR,
        'it'   : IT,
        'nl'   : NL,
        'pt'   : PT,
        'ro'   : RO,
        'sk'   : SK,
        "zh-tw": ZH,
        "tr": TR
    }
});

export default i18n;
