<template>

  <div class="entity-info-box folder-info-box">

    <table class="table table-sm table-striped table-borderless">
      <tbody>
      <transition name="fade">
        <tr v-if="infoLoaded">
          <th scope="row">{{ $t('CreatedBy') }}</th>
          <td>{{ contentFolder.userName }} ({{ contentFolder.teamName }})</td>
        </tr>
      </transition>
      <tr>
        <th scope="row">{{ $t('CreatedDate') }}</th>
        <td>{{ $d(new Date(contentFolder.createdDate), 'long') }}</td>
      </tr>
      <tr>
        <th scope="row">{{ $t('ID') }}</th>
        <td>{{ contentFolder.folderID }}</td>
      </tr>
      </tbody>
    </table>

    <div v-if="showDeeplink" class="mt-4 mb-4">
      <b-input-group>
        <b-form-input :value="getDeeplink" ref="deepLink"></b-form-input>
        <b-input-group-append>
          <b-button variant="success" @click="_onCopyLinkBtnClick">{{
              $t('CopyLinkToContent')
            }}
          </b-button>
        </b-input-group-append>
      </b-input-group>
      <transition name="fade">
        <div v-if="currentCopyToClipboardState === copyToClipboardState.SUCCESS"
             class="text-success small mt-2">{{ $t('ClipboardSuccess') }}
        </div>
      </transition>
      <transition name="fade">
        <div v-if="currentCopyToClipboardState === copyToClipboardState.ERROR"
             class="text-danger small mt-2">{{ $t('ClipboardError') }}
        </div>
      </transition>
    </div>

    <div class="d-flex justify-content-end">
      <b-button variant="secondary" @click="_onCloseBtnClick" tabindex="5">
        {{ $t('Ok') }}
      </b-button>
    </div>

  </div>

</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import AppUserModel from "@/project/user/_model/AppUserModel";
import languageManager from "@/__libs/language_manager/LanguageManager";
import ContentFolderModel from "@/content/_model/ContentFolderModel";
import {RIGHTS} from "@/team/_model/role.constants";
import contentFolderController from "@/content/_controller/ContentFolderController";

enum CopyToClipboardState {
    IDLE,
    SUCCESS,
    ERROR
}

@Component({
    components: {}
})
export default class FolderInfoBox extends Vue {

    @Prop() private contentFolder!: ContentFolderModel;

    private copyToClipboardState: typeof CopyToClipboardState = CopyToClipboardState;
    private currentCopyToClipboardState: CopyToClipboardState = CopyToClipboardState.IDLE;
    private infoLoaded: boolean = false;

    get contentFolderName() {
        return languageManager.getTranslationForValue<string>(this.contentFolder.body.name, AppUserModel.getInstance().langCode)
    }

    get showDeeplink(): boolean {
        return AppUserModel.getInstance().rights.indexOf(RIGHTS.DEEPLINK_TO_CONTENT.identifier) >= 0;
    }

    get getDeeplink(): string {
        return `${window.location.origin}/?folderid=${this.contentFolder.folderID}`;
    }

    async mounted() {
        this.infoLoaded = await contentFolderController.getFolderInfo(this.contentFolder);
    }

    private _onCopyLinkBtnClick(p_e: Event) {
        // @ts-ignore
        this.$refs.deepLink.select();
        try {
            document.execCommand('copy');
            this.currentCopyToClipboardState = CopyToClipboardState.SUCCESS;
        } catch (err) {
            this.currentCopyToClipboardState = CopyToClipboardState.ERROR;
        }
        // @ts-ignore
        this.$refs.deepLink.blur();
    }

    private async _onCloseBtnClick(p_e: Event) {
        this.$emit('onFinish', null);
    }

}
</script>
