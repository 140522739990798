<template>
  <div class="list-item asset-file-item" :class="{ active: isSelected }" v-on:click="_onClick">

    <div class="list-item__icon">
      <div class="responsive aa-responsive--square">
        <div class="responsive__wrapper">
          <AAImage :imageUrl="previewUrl"></AAImage>
        </div>
      </div>
    </div>

    <div class="list-item__info">
      <div class="asset-file-item__name" v-line-clamp="2">{{ assetFileName }}</div>
      <span class="badge file-type" :class="fileTypeClass">{{ fileTypeLabel }}</span>
    </div>

    <b-spinner v-if="assetFile.uploadStatus === uploadStatus.UPLOADING"></b-spinner>

  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from "vue-property-decorator";

import {DeltaStatus, LoadingStatus, UploadStatus} from "@/entity/_model/entity.constants";
import fileManager from "@/_controller/FileManager";
import AppUserModel from "@/project/user/_model/AppUserModel";
import languageManager from "@/__libs/language_manager/LanguageManager";
import AssetFolderSelectionState from "@/asset_folder/_model/AssetFolderSelectionState";
import {FILE_TYPE_CONFIG} from "@/asset_folder/_model/asset_folder.constants";
import AssetFileModel from "@/asset_folder/_model/AssetFileModel";
import AAImage from "@/_view/components/AAImage.vue";

@Component({
  components: {
    AAImage
  },
})
export default class AssetFileItemRenderer extends Vue {

  @Prop() public assetFile!: AssetFileModel;

  @Prop() private selState!: AssetFolderSelectionState;

  private uploadStatus: typeof UploadStatus = UploadStatus;

  get assetFileName() {
    return languageManager.getTranslationForValue<string>(this.assetFile.name, AppUserModel.getInstance().langCode)
  }

  get fileTypeLabel() {
    return this.$t('FileType_' + FILE_TYPE_CONFIG[this.assetFile.fileType].identifier) as string;
  }

  get fileTypeClass() {
    return `file-type--${FILE_TYPE_CONFIG[this.assetFile.fileType].identifier.toLowerCase()}`;
  }

  get previewUrl() {
    return fileManager.getFileUrl(this.assetFile.previewImageURI);
  }

  get isSelected() {
    return this.selState.selectedFile === this.assetFile;
  }

  public _onClick(p_e: Event) {
    this.$emit("onClick", this.assetFile);
  }
}
</script>
