<template>
  <div class="box box--full-height list-overview data-providers-overview">

    <div class="list-overview-filters">
      <b-input-group>
        <template #prepend>
          <b-input-group-text><i class="fas fa-search"></i></b-input-group-text>
        </template>
        <b-form-input v-model="selState.searchFilter"
                      :placeholder="$t('SearchDataProvider')"></b-form-input>
      </b-input-group>
    </div>

    <b-button block variant="primary" class="list-overview-button" @click="_onCreateDataProviderBtnClick" :id="hintIds.DATA1_CREATE">
      {{ $t('CreateDataProvider') }}
    </b-button>

    <div class="list-overview-items">
      <DataProviderItemRenderer
          v-for="dataProvider in filteredDataProviders"
          :dataProvider="dataProvider"
          :key="dataProvider.dataProviderID"
          v-on:onClick="_onDataProviderClick"
          :selState="selState"
      />
    </div>

    <b-modal id="create-dataProvider-box" :title="$t('CreateDataProvider')" hide-footer>
      <CreateDataProviderBox :allowedSchema="selState.allowedSchema"
                             v-on:onFinish="_onCreateFinish"></CreateDataProviderBox>
    </b-modal>

    <b-popover custom-class="hint-popover" :target="hintIds.DATA1_CREATE"
               placement="right"
               triggers="manual"
               boundary="window">
      <Hint :id="hintIds.DATA1_CREATE"></Hint>
    </b-popover>

  </div>
</template>


<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import DataProviderItemRenderer from "@/data_tool/data_provider/_view/DataProviderItemRenderer.vue";
import DataProviderModel from "@/data_tool/data_provider/_model/DataProviderModel";
import dataProviderListController from "@/data_tool/data_provider/_controller/DataProviderListController";
import DataProviderListModel from "@/data_tool/data_provider/_model/DataProviderListModel";
import {LoadingStatus} from "@/entity/_model/entity.constants";
import languageManager from "@/__libs/language_manager/LanguageManager";
import AppUserModel from "@/project/user/_model/AppUserModel";
import DataProviderSelectionState from "@/data_tool/data_provider/_model/DataProviderSelectionState";
import CreateDataProviderBox from "@/data_tool/data_provider/_view/CreateDataProviderBox.vue";
import {DataProviderViewMode} from "@/data_tool/_model/data_tool.constants";
import Hint from "@/help/hint/_view/Hint.vue";
import {HintGroupIds, HintIds} from "@/help/hint/_model/hint.constants";
import HintListController from "@/help/hint/_controller/HintListController";

@Component({
    components: {
        Hint,
        DataProviderItemRenderer, CreateDataProviderBox
    },
})
export default class DataProvidersOverview extends Vue {

    @Prop() private selState!: DataProviderSelectionState;

    private hintIds: typeof HintIds = HintIds;
    private dataProviderListModel: DataProviderListModel = DataProviderListModel.getInstance();
    private dataProviders: DataProviderModel[] = this.dataProviderListModel.list;
    private showCreateModal: boolean = false;

    get filteredDataProviders() {
        let dataProviders = [...this.dataProviders];
        if (this.selState.viewMode === DataProviderViewMode.PICKER && this.selState.allowedSchema) {
            dataProviders = dataProviders.filter(dataProvider => {
                return (dataProvider.dataSchemaURI === this.selState.allowedSchema);
            });
        }

        if (this.selState.searchFilter.length > 1) {
            dataProviders = dataProviders.filter(dataProvider => {
                return (
                  languageManager
                    .getTranslationForValue<string>(dataProvider.name, AppUserModel.getInstance().langCode)
                    .toLowerCase()
                    .indexOf(this.selState.searchFilter.toLowerCase()) > -1
                );
            });
        }

        return dataProviders;

    }

    public mounted() {
        dataProviderListController.fetchAllEntities();
    }

    private async _onDataProviderClick(p_dataProvider: DataProviderModel, p_e: Event) {
        if (p_dataProvider.loadingStatus === LoadingStatus.BODY_LOADED) {
            this.selState.selected = p_dataProvider;
        }
    }

    private _onCreateDataProviderBtnClick(p_e: Event) {
        this.$bvModal.show('create-dataProvider-box');
    }

    private _onCreateFinish(p_createdDataProvider: DataProviderModel) {
        this.$bvModal.hide('create-dataProvider-box');
        if (p_createdDataProvider) {
            this.selState.selected = p_createdDataProvider;
        }
    }

}
</script>
