<template>
  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
    <path class="cls-1" d="M31.97,6.89c.27,2.04-1.18,3.91-3.22,4.17-.48.06-.95.03-1.39-.08l-3.79,5.87c.42.5.7,1.15.79,1.84.27,2.04-1.17,3.91-3.21,4.17s-3.92-1.17-4.18-3.21c-.05-.36-.05-.72.01-1.07l-3.55-1.86c-.55.55-1.29.92-2.11,1.03-.52.07-1.01.02-1.48-.1l-3.16,4.7c.39.51.66,1.12.75,1.8.27,2.04-1.18,3.91-3.22,4.17-2.04.27-3.91-1.17-4.17-3.21s1.17-3.91,3.21-4.17c.5-.07,1-.02,1.47.11h.01s3.17-4.73,3.17-4.73c-.4-.5-.66-1.11-.75-1.78-.27-2.04,1.17-3.91,3.21-4.17s3.91,1.17,4.17,3.21c.05.36.05.72-.02,1.06l3.57,1.86c.55-.54,1.29-.91,2.11-1.01.48-.06.95-.03,1.39.09l3.79-5.87c-.42-.52-.7-1.14-.79-1.84-.27-2.04,1.17-3.91,3.21-4.17s3.92,1.17,4.18,3.21Z"/>
  </svg>
</template>

<script lang="ts">
import {Component, Vue} from "vue-property-decorator";
import MainMenu from "@/_view/menu/MainMenu.vue";
import MainLogo from "@/_view/components/MainLogo.vue";
import MainStatus from "@/_view/components/MainStatus.vue";
import NetworkManager from "@/_controller/NetworkManager";

@Component({
  components: {}
})
export default class InsightsIcon extends Vue {
}
</script>
