<template>
    <div class="data-item-dictionary">

        <transition name="fade">
            <div v-if="currentState === dataProviderPickerState.NO_DTP_SELECTED && property.isEditable">
              <button class="btn btn-secondary btn-block btn-sm" @click="_onSelectDtpBtnClick">Select data</button>
            </div>
        </transition>

        <transition name="fade">
            <div v-if="currentState === dataProviderPickerState.LOADING"
                 class="loading d-flex justify-content-center align-items-center">
                <b-spinner variant="dark"></b-spinner>
            </div>
        </transition>

        <transition name="fade">
            <div v-if="currentState === dataProviderPickerState.ERROR_LOADING"
                 class="placeholder-text text-danger">
                {{ $t('ErrorConnectionRequired') }}
            </div>
        </transition>

        <transition name="fade">
            <div v-if="currentState === dataProviderPickerState.LOADED">
              <h5 class="mb-3">{{dtpName()}}</h5>
              <button class="btn btn-secondary btn-block btn-sm" @click="_onSelectDtpBtnClick">Change data</button>
            </div>
        </transition>

        <b-modal :id="this.property.propertyDefinition.dataSchemaURI" :title="$t('SelectDataProvider')" hide-footer>
            <SelectDataItemListBox :itemDefinitionURI="this.property.propertyDefinition.dataSchemaURI"
                                   :dataProviderURI="this.dataItemProviderLinkModel.dataProviderURI"
                                   v-on:onFinish="_onSelectDtpFinish">
            </SelectDataItemListBox>
        </b-modal>
    </div>
</template>


<script lang="ts">
    import {Component, Prop, Vue, Watch} from "vue-property-decorator";
    import dataProviderController from "@/data_tool/data_provider/_controller/DataProviderController";
    import PropertyModel from "@/data_tool/data_item/_model/PropertyModel";
    import SelectDataProviderBox from "@/data_tool/data_item/_view/SelectDataItemListBox.vue";
    import DataItemDictionaryModel from "@/data_tool/data_item/_model/DataItemDictionaryModel";
    import languageManager, {IMultiLangString} from "@/__libs/language_manager/LanguageManager";
    import fileManager from "@/_controller/FileManager";
    import DataItemModel from "@/data_tool/data_item/_model/DataItemModel";
    import DataItemProviderLinkModel from "@/data_tool/data_item/_model/DataItemProviderLinkModel";

    enum DataProviderPickerState
    {
        IDLE,
        NO_DTP_SELECTED,
        LOADING,
        ERROR_LOADING,
        LOADED
    }

    @Component({
        components: {
            SelectDataItemListBox: SelectDataProviderBox,
            DataItem             : () => import('@/data_tool/data_item/_view/DataItem.vue')
        }
    })
    export default class DataProviderPicker extends Vue
    {
        //---------------------------------
        // Vue Component props
        //---------------------------------

        @Prop() private property!:PropertyModel;

        @Prop() private dataItemProviderLinkModel!:DataItemProviderLinkModel;


        //---------------------------------
        // Vue Component data
        //---------------------------------


        private dataProviderPickerState:typeof DataProviderPickerState = DataProviderPickerState;
        private currentState:DataProviderPickerState = DataProviderPickerState.IDLE;

        //---------------------------------
        // Vue Computed properties
        //---------------------------------


        //---------------------------------
        // Public / lifecycle methods
        //---------------------------------


        public async created()
        {
            // console.log(this.property.propertyDefinition.identifier, this.property.propertyDefinition);
        }

        public async mounted()
        {

        }


        //---------------------------------
        // Private / helper methods
        //---------------------------------


        //this is the actual entrypoint of this component, is called after mount and whenever another property is set
        @Watch('property', {immediate: true, deep: false})
        private async _onActivePropertyChange(newProperty:PropertyModel | null, oldProperty:PropertyModel | null)
        {
            //check if a dtp needs to be selected or loaded
            if (this.dataItemProviderLinkModel.dataProviderURI)
            {
                await this.loadDtp(this.dataItemProviderLinkModel.dataProviderURI);
            }
            else
            {
                this.currentState = DataProviderPickerState.NO_DTP_SELECTED;
            }
        }


        private async loadDtp(p_dataProviderURI:string)
        {
            this.currentState = DataProviderPickerState.LOADING;
            const dependenciesFetched:boolean = await dataProviderController.fetchLinkedDataProvider(p_dataProviderURI, this.property);
            if (dependenciesFetched)
            {
                this.currentState = DataProviderPickerState.LOADED;
            }
            else
            {
                this.currentState = DataProviderPickerState.ERROR_LOADING;
            }
        }


        private async _onSelectDtpBtnClick(p_e:Event)
        {
            this.$bvModal.show(this.property.propertyDefinition.dataSchemaURI!);
        }


        private async _onSelectDtpFinish(p_selectedDtpURI:string)
        {
            this.$bvModal.hide(this.property.propertyDefinition.dataSchemaURI!);

            if (p_selectedDtpURI && p_selectedDtpURI !== this.dataItemProviderLinkModel.dataProviderURI)
            {
                this.dataItemProviderLinkModel.dataProviderURI = p_selectedDtpURI;
                await this.loadDtp(p_selectedDtpURI);
            }
        }



        private dtpName()
        {
            return languageManager.getTranslationForValue<string>(this.property.linkedDataProviderBodyDto!.name as IMultiLangString, this.property.dataProvider.activeLangCode);
        }

    }
</script>
