<template>

  <div class="analytics-block">
    <div class="aa-responsive aa-responsive--widescreen">
      <div class="prime-box aa-responsive__wrapper" v-bind:style="{ background: 'url(\'' + posterUrl + '\') center center / cover no-repeat #ebebeb' }">
        <div class="overlay"></div>
        <div class="content d-flex flex-column p-1 align-items-center justify-content-center">
          <h5 class="aa-title">{{ label }}</h5>
          <small>{{ subLabel }}</small>
          <small class="name" v-line-clamp="2">{{ subjectName }}</small>
        </div>
      </div>
    </div>
  </div>

</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {IAnalyticsPrimeBoxDto, ITeamAnalyticsDto} from "@/analytics/_model/analytics.dto";
import {AnalyticsUsageBoxType} from "@/analytics/_model/analytics.constants";
import i18n from "@/__plugins/i18n";
import languageManager, {IMultiLangString} from "@/__libs/language_manager/LanguageManager";
import fileManager from "@/_controller/FileManager";
import AppUserModel from "@/project/user/_model/AppUserModel";
import AnalyticsModel from "@/analytics/_model/AnalyticsModel";


@Component({
    components: {}
})
export default class AnalyticsContentPrimeBox extends Vue
{

    //---------------------------------
    // Vue Component props
    //---------------------------------

    @Prop() private primeBox!:IAnalyticsPrimeBoxDto;

    //---------------------------------
    // Vue Component data
    //---------------------------------


    //---------------------------------
    // Vue Computed properties
    //---------------------------------

    get label()
    {
        return AnalyticsModel.resolveLabelTranslation(this.primeBox.cfg.label)
    }

    get subLabel()
    {
        const labelStr:string = AnalyticsModel.resolveLabelTranslation(this.primeBox.cfg.subLabel as string);
        return labelStr.split("[x]").join(this.primeBox.boxValue );
    }

    get subjectName()
    {
        return AnalyticsModel.resolveNameTranslation(this.primeBox.name);
    }

    get posterUrl()
    {
        if (this.primeBox.poster)
        {
            let posterUri:string;
            if (typeof this.primeBox.poster === 'string')
            {
                posterUri = this.primeBox.poster as string;
            }
            else
            {
                posterUri = languageManager.getTranslationForValue(this.primeBox.poster as IMultiLangString, AppUserModel.getInstance().langCode)
            }

            return fileManager.getFileUrl(posterUri);
        }
        else
        {
            return "";
        }


    }

    //---------------------------------
    // Public / lifecycle methods
    //---------------------------------

    mounted()
    {
    }



    //---------------------------------
    // Private / helper methods
    //---------------------------------



}
</script>

