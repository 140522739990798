<template>
  <div class="columns slides">
    <SlidesOverview :selState="selState"></SlidesOverview>
    <Slide :selState="selState"></Slide>
    <SlideDetail :selState="selState"></SlideDetail>
  </div>
</template>


<script lang="ts">
import {Component, Prop, Vue} from "vue-property-decorator";
import SlidesOverview from "@/slide/_view/SlidesOverview.vue";
import SlideModel from "@/slide/_model/SlideModel";
import SlideSelectionState from "@/slide/_model/SlideSelectionState";
import SlideDetail from "@/slide/_view/SlideDetail.vue";
import AssetFileDetail from "@/slide/_view/AssetFileDetail.vue";
import HintListController from "@/help/hint/_controller/HintListController";
import {HintGroupIds} from "@/help/hint/_model/hint.constants";
import Slide from "@/slide/_view/Slide.vue";

@Component({
    components: {SlidesOverview, SlideDetail, Slide}
})
export default class SlidesView extends Vue {
    @Prop() private selState!: SlideSelectionState;

    public mounted() {
        HintListController.startGroup(HintGroupIds.SLIDES1);
    }
}
</script>
