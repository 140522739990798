<template>
  <div class="gamification-flow box box--full-height">
    <div class="gamification-flow__start">
      <StartBox></StartBox>
    </div>
    <div class="gamification-flow__evolution">
      <FlowLevels></FlowLevels>
      <FlowStatus></FlowStatus>
    </div>
  </div>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import UserInfo from "@/gamification/summary/_view/UserInfo.vue";
import UserCoins from "@/gamification/summary/_view/UserCoins.vue";
import UserBadge from "@/gamification/summary/_view/UserStatus.vue";
import UserRanking from "@/gamification/summary/_view/UserRanking.vue";
import UserStatus from "@/gamification/summary/_view/UserStatus.vue";
import {IGamificationProjectConfigDto} from "@/gamification/_model/gamification.dto";
import AppUserModel from "@/project/user/_model/AppUserModel";
import StartBox from "@/gamification/flow/_view/StartBox.vue";
import FlowLevels from "@/gamification/flow/_view/FlowLevels.vue";
import FlowStatus from "@/gamification/flow/_view/FlowStatus.vue";

@Component({
  components: {
    FlowStatus,
    StartBox, FlowLevels
  }
})
export default class GamificationFlow extends Vue {

  private gamificationConfig: IGamificationProjectConfigDto = AppUserModel.getInstance().project.config.gamification;

}
</script>

