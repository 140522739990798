import SyncDataProcess from "@/sync/_controller/process/SyncDataProcess";
import syncController from "@/sync/_controller/SyncController";
import SyncModuleProcess from "@/sync/_controller/process/SyncModuleProcess";
import {SyncFileRetrieveStatus, SyncProcessType} from "@/sync/_model/sync.constants";
import SyncSlideProcess from "@/sync/_controller/process/SyncSlideProcess";
import {IPresentationBodyDto, ISlideOccurrenceData} from "@/presentation/_model/presentation.dto";
import {ISyncFileDto, ISyncItemFileDto} from "@/sync/_model/sync.dto";
import SyncConstructProcess from "@/sync/_controller/process/SyncConstructProcess";
import SyncSchemaProcess from "@/sync/_controller/process/SyncSchemaProcess";
import {IMultiLangString, LanguageManager} from "@/__libs/language_manager/LanguageManager";
import fileManager from "@/_controller/FileManager";
import {SlideSource} from "@/presentation/_model/presentation.constants";
import {IContainerData, ISlideBodyDto} from "@/slide/_model/slide.dto";
import {ContainerType} from "@/slide/_model/slide.constants";
import SyncDataProviderProcess from "@/sync/_controller/process/SyncDataProviderProcess";


class SyncPresentationProcess extends SyncDataProcess
{

    //---------------------------------
    // Properties
    //---------------------------------

    protected _storagePath:string = "presentations";
    protected _storedBehindApi:boolean = true;

    public dependencyLevel:number = 0;

    private _slideProcess!:SyncSlideProcess;
    private _constructProcess!:SyncConstructProcess;
    private _schemaProcess!:SyncSchemaProcess;
    private _dtpProcess!:SyncDataProviderProcess;


    protected _moduleProcess!:SyncModuleProcess; //also used by SyncSlideProcess


    //---------------------------------
    // Controller Methods
    //---------------------------------



    //---------------------------------
    // Private Methods
    //---------------------------------

    protected _resolveDependencyProcesses()
    {
        this._slideProcess = syncController.resolveDependencyProcess(SyncProcessType.SLIDE) as SyncSlideProcess;
        this._moduleProcess = syncController.resolveDependencyProcess(SyncProcessType.MODULE) as SyncModuleProcess;
        this._constructProcess = syncController.resolveDependencyProcess(SyncProcessType.CONSTRUCT) as SyncConstructProcess;
        this._schemaProcess = syncController.resolveDependencyProcess(SyncProcessType.SCHEMA) as SyncSchemaProcess;
        this._dtpProcess = syncController.resolveDependencyProcess(SyncProcessType.DATA_PROVIDER) as SyncDataProviderProcess;

    }

    protected _parseRetrievedItemFile(p_itemFile:ISyncItemFileDto)
    {
        const body:IPresentationBodyDto = p_itemFile.body;

        //poster asset files
        if (body.poster)
        {
            this._parseAssetFilesFromMultiLangString(body.poster);
        }


        //construct
        if (body.construct)
        {
            this._constructProcess.addSyncItem({identifier: body.construct});
            this._constructProcess.addSyncItem({identifier: "_shared"}); //todo: should be removed when all shared stuff is in assetfolders
        }


        //customParamsURI
        if (body.customParamsURI)
        {
            const identifier:string | null = this._getSchemaIdentifierFromURI(body.customParamsURI);
            if (identifier)
            {
                this._schemaProcess.addSyncItem({identifier: identifier});
            }
        }

        //library slides
        if (body.slideOccurrences)
        {
            for (let i = 0; i < body.slideOccurrences.length; i++)
            {
                const slideOcc:ISlideOccurrenceData = body.slideOccurrences[i];
                if (slideOcc.slideSource === SlideSource.LIBRARY)
                {
                    this._slideProcess.addSyncItem({identifier: slideOcc.slideID as string})
                }
            }
        }

        //internal slides
        if (body.slides)
        {
            for (let i = 0; i < body.slides.length; i++)
            {
                this._parseSlide(body.slides[i]);
            }
        }

    }

    protected _parseSlide(p_slide:ISlideBodyDto)
    {
        //backgroundContent
        if (p_slide.backgroundContent)
        {
            this._parseAssetFilesFromMultiLangString(p_slide.backgroundContent);
        }

        //containers
        if (p_slide.containers)
        {
            for (let i = 0; i < p_slide.containers.length; i++)
            {
                const container:IContainerData = p_slide.containers[i];

                if (container.type === ContainerType.MODULE)
                {
                    this._moduleProcess.addSyncItem({identifier: container.moduleIdentifier!});
                    if(container.content && typeof container.content === "string" &&  container.content.indexOf("dataProvider://") >= 0)
                    {
                        this._dtpProcess.addSyncItem({identifier: container.content.split("://")[1]})
                    }
                }
                else if (container.type === ContainerType.IMAGE || container.type === ContainerType.VIDEO)
                {
                    this._parseAssetFilesFromMultiLangString(container.content);
                }
            }
        }

    }




    private _getSchemaIdentifierFromURI(p_URI:string):string | null
    {
        const d:string = p_URI.split("://")[1];
        if (d.length > 0)
        {
            const e:string = d.split("#")[0];
            if (e.length > 0)
            {
                return e;
            }
        }
        return null;
    }
}


export default SyncPresentationProcess;
