<template>
  <div class="main-sub-page main-sub-page--with-submenu people-page">

    <div class="sub-menu-bar">
      <div class="sub-menu">
        <router-link :to="{ name: routingIdentifiers.TEAMUSERS }" v-if="showUsers" class="sub-menu-item">{{ $t('Users') }}</router-link>
        <router-link :to="{ name: routingIdentifiers.TEAMS }" v-if="showTeams" class="sub-menu-item">{{ $t('Teams') }}</router-link>
        <router-link :to="{ name: routingIdentifiers.ROLES }" v-if="showRoles" class="sub-menu-item">{{ $t('Roles') }}</router-link>
      </div>
    </div>

    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>

  </div>
</template>

<script lang="ts">
import {Component, Vue} from "vue-property-decorator";
import CreateTeamUserBox from "@/team/_view/CreateTeamUserBox.vue";
import AppUserModel from "@/project/user/_model/AppUserModel";
import {RoutingIdentifier} from "@/router";
import roleController from "@/team/_controller/RoleController";
import {RIGHTS} from "@/team/_model/role.constants";
import {HintIds} from "@/help/hint/_model/hint.constants";
import Hint from "@/help/hint/_view/Hint.vue";

@Component({
    components: {Hint, CreateTeamUserBox}
})
export default class PeoplePage extends Vue {

    public appUser: AppUserModel = AppUserModel.getInstance();
    private routingIdentifiers = RoutingIdentifier;

    get showUsers(): boolean {
        return AppUserModel.getInstance().rights.indexOf(RIGHTS.VIEW_USERS.identifier) >= 0;
    }

    get showTeams(): boolean {
        return AppUserModel.getInstance().rights.indexOf(RIGHTS.MANAGE_TEAMS.identifier) >= 0;
    }

    get showRoles(): boolean {
        return AppUserModel.getInstance().rights.indexOf(RIGHTS.MANAGE_ROLES.identifier) >= 0;
    }

    public mounted() {
        roleController.fetchAllRoles();
    }

}
</script>
