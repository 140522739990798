<template>
  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
    <path class="cls-1" d="M12.29,14.51H2.21c-1.22,0-2.21-.99-2.21-2.21V2.21C0,.99.99,0,2.21,0h10.08c1.22,0,2.21.99,2.21,2.21v10.08c0,1.22-.99,2.21-2.21,2.21ZM32,12.29V2.21c0-1.22-.99-2.21-2.21-2.21h-10.08c-1.22,0-2.21.99-2.21,2.21v10.08c0,1.22.99,2.21,2.21,2.21h10.08c1.22,0,2.21-.99,2.21-2.21ZM14.51,29.79v-10.08c0-1.22-.99-2.21-2.21-2.21H2.21c-1.22,0-2.21.99-2.21,2.21v10.08c0,1.22.99,2.21,2.21,2.21h10.08c1.22,0,2.21-.99,2.21-2.21ZM32,29.79v-10.08c0-1.22-.99-2.21-2.21-2.21h-10.08c-1.22,0-2.21.99-2.21,2.21v10.08c0,1.22.99,2.21,2.21,2.21h10.08c1.22,0,2.21-.99,2.21-2.21Z"/>
  </svg>
</template>

<script lang="ts">
import {Component, Vue} from "vue-property-decorator";
import MainMenu from "@/_view/menu/MainMenu.vue";
import MainLogo from "@/_view/components/MainLogo.vue";
import MainStatus from "@/_view/components/MainStatus.vue";
import NetworkManager from "@/_controller/NetworkManager";

@Component({
  components: {}
})
export default class ContentIcon extends Vue {
}
</script>
