import Model from "@/__libs/_model/Model";
import {IRoleOutlineDto} from "@/team/_model/team.dto";
import {LoadingStatus, SaveStatus} from "@/entity/_model/entity.constants";

class RoleModel extends Model implements IRoleOutlineDto
{
    public roleID:number = 0;
    public identifier:string = "";
    public level:number = 0;

    public rights:string[] = [];

    //status
    public loadingStatus:LoadingStatus = LoadingStatus.ID_ONLY;

    public saveStatus:SaveStatus = SaveStatus.IDLE;

    public hasChanges:boolean = false;

}
export default RoleModel;
