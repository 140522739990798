

export enum SharedObjectType
{
    ROOM_USERS = "ROOM_USERS",
    CHAT = "CHAT",
    PRESENTED_CONTENT = "PRESENTED_CONTENT",
    PRESENTATION = "PRESENTATION",
    CUSTOM = "CUSTOM",
}

