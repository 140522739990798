<template>
  <div class="welcome-sub-page welcome-logo-page">
    <h1 class="welcome-sub-page__title mb-3" v-html="$t('WelcomeLogoTitle')"></h1>
    <p class="welcome-sub-page__text" v-html="$t('WelcomeLogoText')"></p>
    <div class="welcome-sub-page__content">
      <ProjectLogos></ProjectLogos>
    </div>
    <WelcomeNavigation :page-index="1"></WelcomeNavigation>
  </div>
</template>

<script lang="ts">
import {Component, Vue} from "vue-property-decorator";
import WelcomeNavigation from "@/project/welcome/_view/WelcomeNavigation.vue";
import ProjectLogos from "@/project/_view/ProjectLogos.vue";

@Component({
  components: {WelcomeNavigation, ProjectLogos}
})
export default class WelcomeLogoPage extends Vue {
}
</script>
