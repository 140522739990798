<template>
  <div class="base-page analytics-team">

    <div class="aa-container-fluid h-100">
      <div class="row h-100">

        <div class="col-3 h-100">
          <div class="box h-100" :id="hintIds.TEAMINSIGHTS_TEAMS">

            <div class="analytics-team__overview">

              <div v-if="currentAllState === loadingState.LOADED"
                   class="aa-list-item team-item d-flex align-items-center mb-3"
                   :class="{ active: showAllTeams, 'body-loaded': true }"
                   v-on:click="_selectAllTeams">
                <div class="team-item__avatar">
                  <i class="fas fa-users fa-2x"></i>
                </div>
                <div class="team-item__info flex-grow-1">
                  <h6 class="team-item__info__display-name" v-line-clamp="1">{{ $t('AllTeams') }}</h6>
                </div>
              </div>

              <div class="analytics-team__filters d-flex align-items-center mb-3">
                <div class="input-group input-group--search input-group--search--dark">
                  <div class="input-group-prepend">
                    <div class="input-group-text">
                      <i class="fas fa-search"></i>
                    </div>
                  </div>
                  <input
                      type="text"
                      class="form-control"
                      v-model="teamSelState.searchFilter"
                      :placeholder="$t('SearchTeams')"
                  />
                </div>
                <div class="order-button" @click="_orderTeamsAlphabetClick"
                     :class="{ available: true, active: orderedAlphabet }">
                  <i class="fas fa-sort-alpha-down fa-lg"></i>
                </div>
              </div>

              <div>
                <transition name="fade">
                  <div v-if="currentAllState === loadingState.LOADING"
                       class="loading d-flex justify-content-center align-items-center">
                    <b-spinner variant="dark"></b-spinner>
                  </div>
                </transition>

                <transition name="fade" v-if="currentAllState === loadingState.ERROR_LOADING">
                  <div class="placeholder-text text-danger">
                    {{ $t('CouldntLoadAnalytics') }}
                  </div>
                </transition>

                <transition name="fade">
                  <div v-if="currentAllState === loadingState.LOADED">
                    <TeamItemRenderer
                        v-for="team in filteredTeams"
                        :team="team"
                        :key="team.teamID"
                        v-on:onClick="_selectTeam"
                        :selState="teamSelState"
                    />
                  </div>
                </transition>
              </div>


            </div>

          </div>
        </div>

        <div class="col-5 h-100 position-relative" :id="hintIds.TEAMINSIGHTS_USAGE">
          <div class="box h-100" v-if="currentAllState === loadingState.LOADED">

            <transition name="fade" v-if="currentSingleState === loadingState.LOADING">
              <div class="placeholder-text text-secondary d-flex align-items-center justify-content-center h-100">
                <b-spinner variant="dark"></b-spinner>
              </div>
            </transition>

            <transition name="fade" v-else-if="currentSingleState === loadingState.ERROR_LOADING">
              <div class="placeholder-text text-danger">
                {{ $t('CouldntLoadAnalytics') }}
              </div>
            </transition>

            <transition
                v-else-if="currentAllState === loadingState.LOADED || currentSingleState === loadingState.LOADED"
                name="fade">
              <div>
                <h5 class="aa-title mt-1 mb-3">{{ $t('UsageOverview') }}</h5>
                <AnalyticsUsageOverviewGraph :usageOverviewDto="activeAnalytics.usageOverview"/>

                <h5 class="aa-title mt-5 mb-3" v-if="showAllTeams">{{ $t('UsageNumbers') }}</h5>
                <h5 class="aa-title mt-5 mb-3" v-else>{{ $t('ComparedTo') }}</h5>

                <AnalyticsUsageTable :usageData="activeAnalytics.teams" :isComparison="!showAllTeams"/>
              </div>
            </transition>

          </div>
        </div>

        <div class="col-4 h-100" v-if="currentAllState === loadingState.LOADED">
          <div class="box h-100">

            <transition name="fade" v-if="currentSingleState === loadingState.LOADING">
              <div class="placeholder-text text-secondary d-flex align-items-center justify-content-center h-100">
                <b-spinner variant="dark"></b-spinner>
              </div>
            </transition>

            <transition
                v-else-if="(currentAllState === loadingState.LOADED || currentSingleState === loadingState.LOADED) && currentSingleState !== loadingState.ERROR_LOADING"
                name="fade">
              <div>
                <h5 class="aa-title mt-1 mb-3">{{ $t('UsageDetail') }}</h5>

                <div class="row mb-4 justify-content-center">
                  <div class="col-4">
                    <AnalyticsUsageDetailBlock :activeAnalytics="activeAnalytics"
                                               :boxType="analyticsBlockType.USAGE_EVOLUTION_VS_LAST_PERIOD"/>
                  </div>
                  <div class="col-4">
                    <AnalyticsUsageDetailBlock :activeAnalytics="activeAnalytics"
                                               :boxType="analyticsBlockType.AVERAGE_USAGE"/>
                  </div>
                </div>

                <div class="row mb-4 justify-content-center">
                  <div class="col-12 col-md-10 col-lg-8">
                    <AnalyticsContentTypeTotalsGraph :contentTypeTotalsDto="activeAnalytics.contentTypeTotals"/>
                  </div>
                </div>

                <div class="row pt-2 justify-content-center">
                  <div class="col-4">
                    <AnalyticsUsageDetailBlock :activeAnalytics="activeAnalytics"
                                               :boxType="analyticsBlockType.NR_OF_USERS"/>
                  </div>
                  <div class="col-4">
                    <AnalyticsUsageDetailBlock :activeAnalytics="activeAnalytics"
                                               :boxType="analyticsBlockType.NR_OF_CREATED_USERS"/>
                  </div>
                  <div class="col-4">
                    <AnalyticsUsageDetailBlock :activeAnalytics="activeAnalytics"
                                               :boxType="analyticsBlockType.NR_OF_DELETED_USERS"/>
                  </div>
                </div>

              </div>
            </transition>
          </div>
        </div>

      </div>
    </div>

    <b-popover custom-class="hint-popover" :target="hintIds.TEAMINSIGHTS_TEAMS"
               placement="right"
               triggers="manual"
               boundary="window">
      <Hint :id="hintIds.TEAMINSIGHTS_TEAMS"></Hint>
    </b-popover>
    <b-popover custom-class="hint-popover" :target="hintIds.TEAMINSIGHTS_USAGE"
               placement="bottom"
               boundary="window"
               triggers="manual">
      <Hint :id="hintIds.TEAMINSIGHTS_USAGE"></Hint>
    </b-popover>

  </div>
</template>

<script lang="ts">
import {Component, Vue, Watch} from "vue-property-decorator";
import SelectionState from "@/__libs/_model/SelectionState";
import TeamModel from "@/team/_model/TeamModel";
import TeamItemRenderer from "@/team/_view/TeamItemRenderer.vue";
import analyticsController from "@/analytics/_controller/AnalyticsController";
import AnalyticsModel from "@/analytics/_model/AnalyticsModel";
import AnalyticsUsageOverviewGraph from "@/analytics/_view/AnalyticsUsageOverviewGraph.vue";
import AnalyticsUsageTable from "@/analytics/_view/AnalyticsUsageTable.vue";
import AnalyticsContentTypeTotalsGraph from "@/analytics/_view/AnalyticsContentTypeTotalsGraph.vue";
import AnalyticsUsageDetailBox from "@/analytics/_view/AnalyticsUsageDetailBox.vue";
import {AnalyticsUsageBoxType} from "@/analytics/_model/analytics.constants";
import HintListController from "@/help/hint/_controller/HintListController";
import {HintGroupIds, HintIds} from "@/help/hint/_model/hint.constants";
import Hint from "@/help/hint/_view/Hint.vue";

enum LoadingState {
    IDLE,
    LOADING,
    ERROR_LOADING,
    LOADED
}

@Component({
    components: {
        Hint,
        TeamItemRenderer,
        AnalyticsUsageOverviewGraph,
        AnalyticsUsageTable,
        AnalyticsContentTypeTotalsGraph,
        AnalyticsUsageDetailBlock: AnalyticsUsageDetailBox
    }
})
export default class AnalyticsTeamPage extends Vue {
    private hintIds: typeof HintIds = HintIds;

    private analyticsBlockType: typeof AnalyticsUsageBoxType = AnalyticsUsageBoxType;

    private loadingState: typeof LoadingState = LoadingState;
    private currentAllState: LoadingState = LoadingState.IDLE;
    private currentSingleState: LoadingState = LoadingState.IDLE;

    private showAllTeams: boolean = true;

    public teamSelState: SelectionState<TeamModel> = AnalyticsModel.getInstance().teamListing.globalSelState;

    private teams: TeamModel[] = AnalyticsModel.getInstance().teamListing.list;

    private orderedAlphabet: boolean = false;

    //---------------------------------
    // Vue Computed properties
    //---------------------------------

    get activeAnalytics() {
        return AnalyticsModel.getInstance().activeTeamAnalytics;
    }

    get filteredTeams() {
        if (this.teamSelState.searchFilter.length > 1) {
            return this.teams.filter(team => {
                return team.displayName.toLowerCase().indexOf(this.teamSelState.searchFilter.toLowerCase()) > -1
            });
        } else {
            return this.teams;
        }
    }

    get activeTeam(): TeamModel | null {
        return this.teamSelState.selected;
    }

    get range() {
        return AnalyticsModel.getInstance().range;
    }

    //---------------------------------
    // Public / lifecycle methods
    //---------------------------------

    public async mounted() {
        await this._loadAllTeams();
        //
        HintListController.startGroup(HintGroupIds.TEAMINSIGHTS);
    }

    private async _loadAllTeams() {
        this.currentAllState = LoadingState.LOADING;
        const allLoaded: boolean = await analyticsController.getAllTeamsAnalytics();
        if (allLoaded) {
            this.showAllTeams = true;
            this.currentAllState = LoadingState.LOADED;
            this._orderTeamsCreatedDate();
            this._selectAllTeams();

        } else {
            this.currentAllState = LoadingState.ERROR_LOADING;
        }
    }

    //---------------------------------
    // Private / helper methods
    //---------------------------------

    private async _selectAllTeams() {
        this.teamSelState.selected = null;
        AnalyticsModel.getInstance().activeTeamAnalytics = AnalyticsModel.getInstance().allTeamsAnalytics;
        this.showAllTeams = true;
        this.currentAllState = LoadingState.LOADED;
        this.currentSingleState = LoadingState.IDLE;
    }

    private async _selectTeam(p_team: TeamModel) {
        this.currentSingleState = LoadingState.LOADING;
        this.teamSelState.selected = p_team;
        const singleLoaded = await analyticsController.getSingleTeamAnalytics(p_team.teamID);
        if (singleLoaded) {
            this.showAllTeams = false;
            this.currentSingleState = LoadingState.LOADED;
        } else {
            this.currentSingleState = LoadingState.ERROR_LOADING;
        }
    }

    @Watch('range', {immediate: false, deep: false})
    private async _getContentWidgetData() {
        await this._loadAllTeams();
        /*
        //todo needs to refresh all
        if (this.showAllTeams)
        {
            console.log("todo");
        }
        else
        {
            this._selectTeam(this.teamSelState.selected as TeamModel);
        }

*/
    }


    // ordering
    private _orderTeamsAlphabetClick() {
        if (this.orderedAlphabet) {
            this._orderTeamsCreatedDate();
            this.orderedAlphabet = false;
        } else {
            this._orderTeamsAlphabet();
            this.orderedAlphabet = true;
        }
    }

    private _orderTeamsAlphabet() {
        this.teams.sort((a: TeamModel, b: TeamModel) => {
            if (a.displayName.toLowerCase() < b.displayName.toLowerCase()) {
                return -1;
            }
            if (a.displayName.toLowerCase() > b.displayName.toLowerCase()) {
                return 1;
            }
            return 0;
        });
    }

    private _orderTeamsCreatedDate() {
        this.teams.sort((a: TeamModel, b: TeamModel) => {
            let dateA: Date | null = null;
            if (a.createdDate instanceof Date) {
                dateA = a.createdDate;
            } else {
                dateA = new Date(a.createdDate);
            }
            let dateB: Date | null = null;
            if (b.createdDate instanceof Date) {
                dateB = b.createdDate;
            } else {
                dateB = new Date(b.createdDate);
            }
            if (dateA > dateB) {
                return -1;
            }
            if (dateA < dateB) {
                return 1;
            }
            return 0;
        });
    }


}
</script>
