<template>
  <div class="root-page presentation-player-page">

    <PresentationPlayer v-if="presentationReady"
                        :engineMode="engineMode"
                        :presentationID="presentationID"
                        :slideID="slideID"
                        :audienceSessionIdentifier="audienceSessionIdentifier"
                        ref="presentationPlayer"/>

  </div>
</template>


<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import PresentationPlayer from "@/presentation/player/_view/PresentationPlayer.vue";
import {EngineMode} from "@/presentation/player/_model/player.constants";
import {RoutingIdentifier} from "@/router";
import {Route} from "vue-router";
import ContentListModel from "@/content/_model/ContentListModel";
import PresentationModel from "@/presentation/_model/PresentationModel";
import {LoadingStatus} from "@/entity/_model/entity.constants";

@Component({
  components: {
    PresentationPlayer
  },
  beforeRouteLeave(to: Route, from: Route, next: any) {
    const player: any = this.$refs.presentationPlayer as PresentationPlayer;
    if (player.exitAllowed) {
      next();
    } else {
      player.checkNeedForSaveAndExit();
    }
  }
})

export default class PresentationPlayerPage extends Vue {

  @Prop() private engineMode!: EngineMode;
  @Prop() private presentationID?: string;
  @Prop() private slideID?: string;
  @Prop() private audienceSessionIdentifier?: string;

  get presentationReady(): boolean {
    if (this.engineMode === EngineMode.SLIDE_EDITOR) {
      return true;
    }

    if (this.presentationID) {
      const presentation: PresentationModel = ContentListModel.getInstance().getEntityByID(this.presentationID) as PresentationModel;
      if (presentation && presentation.loadingStatus === LoadingStatus.BODY_LOADED) {
        return true;
      }

    }
    return false;
  }

}
</script>
