<template>
  <div class="root-page redirect-page">

    <img class="redirect-page__background" :src="splashBg" alt="splash background" />

    <div class="redirect-page__content container">
      <img class="redirect-page__content__logo" src="img/logo-salesdrive.png" alt="logo" />

      <h1 class="redirect-page__content__title">
        {{ title }}
      </h1>
      <p class="redirect-page__content__intro" v-html="introduction"></p>

      <b-button class="btn-sd-primary shadow" @click="onClick">
        <span>{{ $t('RedirectSplashContinueButton') }}</span>
        <svg width="2rem" height="1.5rem" class="mt-n1 ml-2" viewBox="0 0 33 24" xmlns="http://www.w3.org/2000/svg" xml:space="preserve" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
          <g transform="matrix(-0.297422,-3.64237e-17,3.64237e-17,-0.297422,52.0488,47.5875)">
            <path d="M115,130L115,152.826C115,154.151 114.24,155.359 113.046,155.934C111.851,156.508 110.433,156.346 109.398,155.518C98.942,147.154 76.756,129.405 68.336,122.669C67.525,122.02 67.053,121.038 67.053,120C67.053,118.962 67.525,117.98 68.336,117.331C76.756,110.595 98.942,92.846 109.398,84.482C110.433,83.654 111.851,83.492 113.046,84.066C114.24,84.641 115,85.849 115,87.174C115,96.049 115,110 115,110L171.626,110C172.521,110 173.379,110.355 174.012,110.988C174.645,111.621 175,112.479 175,113.374C175,117.062 175,122.938 175,126.626C175,127.521 174.645,128.379 174.012,129.012C173.379,129.645 172.521,130 171.626,130C158.965,130 115,130 115,130Z" style="fill:white;"/>
          </g>
        </svg>
      </b-button>

      <p class="redirect-page__content__more" v-html="$t('RedirectSplashLearnMore')"></p>

      <footer>
        <span>{{ $t('SalesdriveSlogan') }}</span>

        <div class="d-flex justify-content-center mt-3">
          <b-button class="btn-linkedin" href="https://be.linkedin.com/company/salesdrive-platform" target="_blank">
            <i class="fa-brands fa-linkedin"></i>
            <span>{{ $t('RedirectSplashLinkedInButton') }}</span>
          </b-button>

          <b-button class="btn-sd-primary btn-website" href="https://sales-drive.com" target="_blank">
            <svg class="mr-1" width="1.125rem" height="1rem" viewBox="0 0 19 17" xmlns="http://www.w3.org/2000/svg" xml:space="preserve" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
              <g transform="matrix(0.102774,0,0,0.102774,-0.822196,-1.64439)">
                <path d="M96,16C66.464,16 40.684,32.128 26.828,56L46.359,56C53.559,47.104 62.955,40.064 73.891,36.016C71.387,41.84 69.38,48.64 67.844,56L84.297,56C88.129,40.184 93.496,32 96,32C98.504,32 103.871,40.184 107.703,56L124.156,56C122.62,48.64 120.605,41.84 118.109,36.016C129.037,40.064 138.441,47.104 145.641,56L165.172,56C151.316,32.128 125.536,16 96,16ZM8,72L17.938,120L28.406,120L35.203,92.234L41.984,120L52.406,120L62.344,72L50.313,72L46.016,98.922L39.625,72L30.719,72L24.313,98.969L20.063,72L8,72ZM68.828,72L78.766,120L89.234,120L96.031,92.234L102.812,120L113.234,120L123.172,72L111.156,72L106.844,98.922L100.469,72L91.547,72L85.156,98.969L80.891,72L68.828,72ZM129.656,72L139.594,120L150.062,120L156.859,92.234L163.641,120L174.062,120L184,72L171.969,72L167.672,98.922L161.281,72L152.375,72L145.969,98.969L141.719,72L129.656,72ZM26.828,136C40.684,159.872 66.464,176 96,176C125.536,176 151.316,159.872 165.172,136L145.641,136C138.441,144.896 129.045,151.936 118.109,155.984C120.605,150.16 122.605,143.36 124.141,136L107.688,136C103.856,151.816 98.488,160 95.984,160C93.48,160 88.129,151.816 84.297,136L67.844,136C69.38,143.36 71.395,150.16 73.891,155.984C62.963,151.936 53.559,144.896 46.359,136L26.828,136Z" style="fill:white;fill-rule:nonzero;"/>
              </g>
            </svg>
            <span>{{ $t('RedirectSplashWebsiteButton') }}</span>
          </b-button>
        </div>
      </footer>
    </div>
  </div>
</template>

<script lang="ts">
import {Vue, Component} from 'vue-property-decorator';
import {TranslateResult} from 'vue-i18n';

@Component({})
export default class RedirectSplashPage extends Vue {
  private get isNespresso(): boolean {
    return window.location.origin.includes('nespresso');
  }

  public get splashBg(): string {
    if (this.isNespresso) {
      return 'img/redirect-splash-bg-nespresso.jpg';
    }
    return 'img/redirect-splash-bg.png';
  }

  public get title(): TranslateResult {
    if (this.isNespresso) {
      return this.$t('RedirectSplashTitleNespresso');
    }
    return this.$t('RedirectSplashTitle');
  }

  public get introduction(): TranslateResult {
    if (this.isNespresso) {
      return this.$t('RedirectSplashIntroductionNespresso');
    }
    return this.$t('RedirectSplashIntroduction');
  }

  public onClick() {
    const currentUrl = window.location.origin;
    const newUrl = currentUrl.replace('audience-advantage.app', 'sales-drive.app');
    window.open(newUrl, '_blank');
  }
}
</script>
