import {DataResourceScope, FormElement, PropertyType} from "@/data_tool/_model/data_tool.constants";
import {IDataURIDto, IPropertyDefinitionDto} from "@/data_tool/_model/data_tool.dto";

class DataUtil
{
    //---------------------------------
    // Public Static Methods
    //---------------------------------



    public static resolveURI(p_dataURI:string):IDataURIDto
    {
        const dataURIDto:IDataURIDto = {} as IDataURIDto;
        const base:string[] = p_dataURI.split("#");
        dataURIDto.baseURI = base[0];
        dataURIDto.definitionIdentifier = base[1];
        const head:string[] = dataURIDto.baseURI.split("://");
        dataURIDto.scheme = head[0].toUpperCase();
        dataURIDto.path = head[1];

        if (dataURIDto.scheme.indexOf("CURRENT") >= 0)
        {
            dataURIDto.scope = DataResourceScope.CURRENT;
        }
        else if (dataURIDto.scheme.indexOf("GLOBAL") >= 0)
        {
            dataURIDto.scope = DataResourceScope.GLOBAL;
        }
        else
        {
            dataURIDto.scope = DataResourceScope.PROJECT;
        }
        return dataURIDto;
    }

    public static deepCopy(p_data:any):any
    {
        if (p_data !== undefined)
        {
            return JSON.parse(JSON.stringify(p_data));
        }
        return null;
    }

    public static getDefaultFormElement(p_propDef:IPropertyDefinitionDto):FormElement
    {


        switch (p_propDef.type)
        {
            case PropertyType.TEXT:
                if (p_propDef.maxLength && p_propDef.maxLength > 500)
                {
                    return FormElement.TEXT_AREA;
                }
                else
                {
                    return FormElement.TEXT_INPUT;
                }

            case PropertyType.NUMBER:
                return FormElement.NUMBER_INPUT;

            case PropertyType.DATE:
                return FormElement.DATE_PICKER;

            case PropertyType.ONOFF:
                return FormElement.CHECKBOX;

            case PropertyType.SELECT_LIST:
            case PropertyType.FIXED_LIST:
            case PropertyType.EXTERNAL_LIST:
                if (p_propDef.isMultipleSelect)
                {
                    return FormElement.CHECKBOX_GROUP;
                }
                else
                {
                    return FormElement.COMBOBOX;
                }

            case PropertyType.COLOR:
                return FormElement.COLOR_PICKER;

            case PropertyType.ASSET_FILE:
                return FormElement.ASSET_FILE_INPUT;

            default:
                return FormElement.TEXT_INPUT;
        }

    }

    public static columnToLetter(columnNumber:number)
    {
        // To store result (Excel column name)
        let columnName = [];

        while (columnNumber > 0)
        {
            // Find remainder
            let rem = columnNumber % 26;

            // If remainder is 0, then a
            // 'Z' must be there in output
            if (rem == 0)
            {
                columnName.push("Z");
                columnNumber = Math.floor(columnNumber / 26) - 1;
            }
            else // If remainder is non-zero
            {
                columnName.push(String.fromCharCode((rem - 1) + 'A'.charCodeAt(0)));
                columnNumber = Math.floor(columnNumber / 26);
            }
        }

        // Reverse the string and print result
        return columnName.reverse().join("");
    }

}

export default DataUtil;
