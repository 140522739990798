<template>
  <div class="update-box">
    <div class="update-box__header">
      <AAImage imageUrl="img/update/update.jpg"></AAImage>
    </div>
    <div class="update-box__body text-center p-5">
      <h5 class="mb-2">{{ $t('UpdateBoxTitle') }}</h5>
      <p class="mb-4">{{ $t('UpdateBoxText') }}</p>
      <div class="buttons">
        <b-button variant="success" @click="updateNow">{{ $t('UpdateBoxButtonNow') }}</b-button>
        <a class="skip" v-html="$t('UpdateBoxButtonLater')" @click="updateLater" v-if="!appModel.updateSkipped"></a>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import serviceWorkerController from "@/_controller/ServiceWorkerController";
import AAImage from "@/_view/components/AAImage.vue";
import AppModel from "@/_model/AppModel";

@Component({
  components: {AAImage}
})
export default class UpdateBox extends Vue {

  public appModel: AppModel = AppModel.getInstance();

  private updateNow() {
    // serviceWorkerController.update();
  }

  private updateLater() {
    // this.appModel.updateSkipped = true;
    this.$bvModal.hide('update-box');
  }

}
</script>
