<template>
  <div class="project-fonts">
    <ProjectFont :file-url="project.fontLightUrl" :file-name="systemFiles.FONT_LIGHT" :name="fonts.LIGHT"></ProjectFont>
    <ProjectFont :file-url="project.fontRegularUrl" :file-name="systemFiles.FONT_REGULAR" :name="fonts.REGULAR"></ProjectFont>
    <ProjectFont :file-url="project.fontBoldUrl" :file-name="systemFiles.FONT_BOLD" :name="fonts.BOLD"></ProjectFont>
  </div>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import ProjectModel from "@/project/_model/ProjectModel";
import AppUserModel from "@/project/user/_model/AppUserModel";
import ProjectLogo from "@/project/_view/ProjectLogo.vue";
import {Colors, Fonts, SystemFiles} from "@/project/_model/project.constants";
import ProjectFont from "@/project/_view/ProjectFont.vue";

@Component({
  components: {ProjectFont},
})
export default class ProjectFonts extends Vue {

  public project: ProjectModel = AppUserModel.getInstance().project;

  private systemFiles: typeof SystemFiles = SystemFiles;
  private fonts: typeof Fonts = Fonts;

}
</script>

