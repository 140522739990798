import ContentAppResourceModel from "@/content_app/resource/_model/ContentAppResourceModel";
import fileManager, {FileResponse} from "@/_controller/FileManager";
import {IContentAppResourceDto} from "@/content_app/resource/_model/content_app_resource.dto";

class ContentAppResourceController {

    public async fetch(contentAppResourceIdentifier: string): Promise<ContentAppResourceModel | null> {
        let contentAppResource: ContentAppResourceModel | null = null;
        const fileResponse: FileResponse = await fileManager.fetchFileFromCdn(`contentAppResources/${contentAppResourceIdentifier}/cfg.json`);
        if (fileResponse.hasSucceeded) {
            contentAppResource = new ContentAppResourceModel();
            contentAppResource.mapFromDto(fileResponse.result as IContentAppResourceDto);
        }
        return contentAppResource;
    }

}

export default new ContentAppResourceController();