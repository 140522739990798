import PlaylistModel from "@/playlist/_model/PlaylistModel";
import JsonUtil from "@/__libs/utility/JsonUtil";
import LocalStorageManager from "@/__libs/offline_storage/LocalStorageManager";
import {LocalStorageKey} from "@/_model/app.constants";
import {IPlaylistDto, IPlaylistEntryDto} from "@/playlist/_model/playlist.dto";


class PlaylistListModel
{
    private _selectedQueueEntry:IPlaylistEntryDto | null = null;
    get selectedQueueEntry():IPlaylistEntryDto | null
    {
        return this._selectedQueueEntry;
    }

    set selectedQueueEntry(value:IPlaylistEntryDto | null)
    {
        this._selectedQueueEntry = value;
        if (value)
        {
            this.playlistPlayerCollapsed = true;
        }
    }



    public playlistOverviewCollapsed:boolean = false;
    public playlistPlayerCollapsed:boolean = false;

    public playlists:PlaylistModel[] = [];
    public selectedPlaylist:PlaylistModel | null = null;

    public entryQueue:IPlaylistEntryDto[] = []; //the queue of entries that is used while presenting (a playlist or a single piece of content)


    //---------------------------------
    // Singleton Constructor
    //---------------------------------

    private static _instance:PlaylistListModel;

    constructor()
    {
        if (!PlaylistListModel._instance)
        {
            PlaylistListModel._instance = this;
        }

        this.playlistOverviewCollapsed = JsonUtil.parse(LocalStorageManager.retrieveValue(LocalStorageKey.PLAYLIST_COLLAPSED) as string) ? true : false;
    }

    public static getInstance():PlaylistListModel
    {
        if (!PlaylistListModel._instance)
        {
            new PlaylistListModel();
        }

        return PlaylistListModel._instance;
    }


    //---------------------------------
    // Public Methods
    //---------------------------------


    public mapPlaylistsFromDto(p_playlists:IPlaylistDto[])
    {
        for (let i = 0; i < p_playlists.length; i++)
        {
            this.playlists.push(new PlaylistModel(p_playlists[i]))
        }
    }

    public togglePlaylistOverview()
    {
        this.playlistOverviewCollapsed = !this.playlistOverviewCollapsed;
        LocalStorageManager.storeValue(LocalStorageKey.PLAYLIST_COLLAPSED, JsonUtil.stringify(this.playlistOverviewCollapsed));
    }


    public togglePlaylistPlayer(p_isCollapsed?:boolean)
    {
        if (p_isCollapsed !== undefined)
        {
            this.playlistPlayerCollapsed = p_isCollapsed;
        }
        else
        {
            this.playlistPlayerCollapsed = !this.playlistPlayerCollapsed;
        }
    }

    public deletePlaylist(p_playlist:PlaylistModel)
    {
        const index:number = this.playlists.indexOf(p_playlist);
        if (index >= 0)
        {
            this.playlists.splice(index, 1);
        }
    }


    public addQueueEntry(p_contentID:string)
    {
        if (this._selectedQueueEntry)
        {
            const currentEntryIndex:number = this.entryQueue.indexOf(this._selectedQueueEntry);

            const existingEntry = this.entryQueue.find(entry => entry.contentID === p_contentID);
            if (existingEntry)
            {
                this.selectedQueueEntry = existingEntry;
            }
            else
            {
                const newEntry:IPlaylistEntryDto = {contentID: p_contentID};
                this.entryQueue.splice(currentEntryIndex + 1, 0, newEntry);
                this.selectedQueueEntry = newEntry;
            }
        }
    }



}

export default PlaylistListModel;
