import EntityModel from "@/entity/_model/EntityModel";
import SelectionState from "@/__libs/_model/SelectionState";
import {DeltaStatus} from "@/entity/_model/entity.constants";
import SlideModel from "@/slide/_model/SlideModel";
import {SlideViewMode} from "@/slide/_model/slide.constants";
import EntitySelectionState from "@/entity/_model/EntitySelectionState";

export default class SlideSelectionState extends EntitySelectionState<SlideModel>
{

    protected _selected:SlideModel | null = null;
    get selected():SlideModel | null
    {
        return this._selected;
    }

    set selected(value:SlideModel | null)
    {
        this._previousSelected = this._selected;
        this._selected = value;
    }

    public viewMode:SlideViewMode;




    public fileSearchFilter:string = "";


    constructor(viewMode:SlideViewMode)
    {
        super();
        this.viewMode = viewMode;
    }

    //---------------------------------
    // Public Methods
    //---------------------------------


}
