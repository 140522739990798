<template>
  <div class="modal-box introduction-box">

    <transition name="fade">
      <div class="introduction-box__intro" v-if="currentState === introductionBoxState.STEP_1">
        <AAImage imageUrl="img/introduction/box-introduction.jpg"></AAImage>
        <div class="introduction-box__intro__content">
          <h4 class="mb-3" v-html="$t('IntroductionTitlePart1')"></h4>
          <h1 class="mb-5" v-html="$t('IntroductionTitlePart2')"></h1>
          <div>
            <p class="mb-4" v-html="$t('IntroductionText')"></p>
            <b-button variant="success" @click="goToStep2">{{ $t('IntroductionStepButton') }}</b-button>
          </div>
        </div>
      </div>
    </transition>

    <transition name="fade">
      <div v-if="currentState === introductionBoxState.STEP_2" class="introduction-box__content">
        <div class="introduction-box__content__menu">
          <AAImage class="introduction-box__content__menu__bg" imageUrl="img/introduction/box-introduction.jpg" :style="{ objectPosition: `${indexPercentage}% 0` }"></AAImage>
          <div class="position-relative">
            <h4 v-html="$t('IntroductionPart2Title')" class="mb-3"></h4>
            <AAImage :imageUrl="logo" class="introduction-box__content__menu__icon"></AAImage>
            <div class="introduction-box__content__menu__item" v-for="(videoList, index) in videoLists" :key="index" :class="{ active: index === stateIndex }" @click="goto(index)">
              <span v-html="$t(videoList.name)"></span>
            </div>
            <b-button variant="success" @click="next">{{ $t('IntroductionStepButton') }}</b-button>
          </div>
        </div>
        <div class="introduction-box__content__content">
          <transition name="fade">
            <div :key="currentVideo.name">
              <h3 v-html="$t(currentVideo.name)" class="mb-3"></h3>
              <div class="responsive responsive--16-9">
                <div class="responsive__wrapper">
                  <video controls>
                    <source :src="currentVideo.videoUrl" type="video/mp4">
                  </video>
                </div>
              </div>
            </div>
          </transition>
          <transition name="fade">
            <div class="introduction-box__content__content__thumbs mt-4" :key="currentVideoList.name" v-if="currentVideoList.videos.length > 1">
              <div class="introduction-box__content__content__thumbs__thumb" v-for="(video, index) in currentVideoList.videos" :key="index" :class="{ active: index === videoIndex }" @click="playVideo(index)">
                <div class="responsive responsive--16-9">
                  <div class="responsive__wrapper">
                    <AAImage :imageUrl="video.imageUrl"></AAImage>
                  </div>
                </div>
                <div class="name" v-html="$t(video.name)"></div>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </transition>

  </div>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import AAImage from "@/_view/components/AAImage.vue";
import AppUserModel from "@/project/user/_model/AppUserModel";
import {TierType} from "@/project/_model/project.constants";
import IntroductionVideoListModel from "@/help/introduction/_model/IntroductionVideoListModel";
import introductionBoxController from "@/help/introduction/_controller/IntroductionBoxController";
import IntroductionVideoModel from "@/help/introduction/_model/IntroductionVideoModel";

enum IntroductionBoxState {
    STEP_1,
    STEP_2
}

@Component({
    components: {AAImage}
})
export default class IntroductionBox extends Vue {

    private introductionBoxState: typeof IntroductionBoxState = IntroductionBoxState;
    private currentState: IntroductionBoxState = IntroductionBoxState.STEP_1;
    private stateIndex: number = 0;
    private videoLists: IntroductionVideoListModel[] = [];
    private videoIndex: number = 0;

    get tierType(): string {
        return AppUserModel.getInstance().project.tierConfig.tierType;
    }

    get logo(): string {
        console.log(this.tierType);
        return this.tierType === TierType.EXPLORE ? 'img/logo-explore.png' : 'img/logo-explore-plus.png';
    }

    get indexPercentage(): number {
        // return (this.stateIndex) / this.videoLists.length * 100;
        return this.stateIndex * 20;
    }

    get currentVideoList(): IntroductionVideoListModel {
        return this.videoLists[this.stateIndex];
    }

    get currentVideo(): IntroductionVideoModel {
        return this.currentVideoList.videos[this.videoIndex];
    }

    private playVideo(index: number) {
        this.videoIndex = index;
    }

    private goToStep2() {
        this.currentState = this.introductionBoxState.STEP_2;
    }

    private goto(index: number) {
        this.stateIndex = index;
        this.videoIndex = 0;
    }

    private next() {
        if (this.stateIndex + 1 >= this.videoLists.length) {
            this.$bvModal.hide('introduction-box');
        } else {
            this.stateIndex++;
            this.videoIndex = 0;
        }
    }

    public mounted() {
      this.videoLists = introductionBoxController.init(this.tierType);
    }

}
</script>
