import {IAudienceSessionDto, IAudienceSessionEventDto} from "@/audience/session/_model/audience_session.dto";
import {SessionEnvironment, SessionMode} from "@/audience/session/_model/audience_session.constants";
import {ActivityType} from "@/audience/track/_model/audience_activity.constants";
import {GamificationTargetIdentifier} from "@/gamification/_model/gamification.constants";
import Model from "@/__libs/_model/Model";
import ContentModel from "@/content/_model/ContentModel";
import PresentationModel from "@/presentation/_model/PresentationModel";
import {ISlideBodyDto} from "@/slide/_model/slide.dto";
import languageManager, {IMultiLangString} from "@/__libs/language_manager/LanguageManager";

export default class AudienceSessionModel extends Model implements IAudienceSessionDto {
    public userID: number = 0;
    public identifier: string = "";
    public parentIdentifier: string = "";
    public audienceID: string = "";
    public shareID: number = 0;
    public clientStartedTimeStamp: Date = new Date();
    public clientEndedTimeStamp?: Date;
    public length: number = 0;
    public sessionEnvironment: SessionEnvironment = SessionEnvironment.APP;
    public sessionMode: SessionMode = SessionMode.PRESENT;
    public activityType: ActivityType = ActivityType.PRESENTATION_SESSION;
    public subjectIdentifier: string = "";
    public payload: any = {};
    public events: IAudienceSessionEventDto[] = [];
    public sessions: IAudienceSessionDto[] = [];
    public gamificationTargetIdentifier?: GamificationTargetIdentifier;

    public subject?: ContentModel;

    public getTotalDuration(subActivityType: ActivityType): number {
        let totalDuration: number = 0;
        for (let i: number = 0; i < this.sessions.length; i++) {
            if (this.sessions[i].activityType === subActivityType) {
                totalDuration += this.sessions[i].length ?? 0;
            }
        }
        return totalDuration;
    }

    public getLongestSessionDuration(subActivityType: ActivityType): number {
        let longestSessionDuration: number = 0;
        for (let i: number = 0; i < this.sessions.length; i++) {
            if (this.sessions[i].activityType === subActivityType) {
                longestSessionDuration = Math.max(longestSessionDuration, this.sessions[i].length ?? 0);
            }
        }
        return longestSessionDuration;
    }

    public getPercentageOfContentViewsViewed(): number {
        let percentageOfContentViewsViewed: number = 0;
        if (this.activityType === ActivityType.PRESENTATION_SESSION && this.subject) {
            const numSlides: number = (this.subject as PresentationModel).slideOccurrences.length;
            // get amount of unique slides viewed
            const uniqueSlidesViewed: string[] = [];
            for (let i: number = 0; i < this.sessions.length; i++) {
                if (this.sessions[i].activityType === ActivityType.SLIDE_SESSION && this.sessions[i].subjectIdentifier) {
                    if (uniqueSlidesViewed.indexOf(this.sessions[i].subjectIdentifier!) === -1) {
                        uniqueSlidesViewed.push(this.sessions[i].subjectIdentifier!);
                    }
                }
            }
            if (numSlides > 0) {
                percentageOfContentViewsViewed = Math.round(uniqueSlidesViewed.length / numSlides * 100);
            }
        }
        return percentageOfContentViewsViewed;
    }
}
