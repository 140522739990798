export default class JsonUtil
{
    public static parse<T>(text: string): T | null
    {
        try
        {
            return JSON.parse(text) as T;
        }
        catch (error)
        {
            console.log('The string "' + text + '" is not a valid JSON string: ' + error.message);
            return null;
        }
    }

    public static stringify(object: any): string
    {
        try
        {
            return JSON.stringify(object);
        }
        catch (error)
        {
            console.log('The object "' + object + '" is not a stringifiable JSON object' + error.message);
            return "";
        }
    }
}
