<template>
  <div :class="{ collapsed: collapsed}"
       class="playlist-entry-overview">
    <div class="list-overview">
      <h5 class="aa-title playlist-entry-overview__title mb-5 text-center">{{ selectedPlaylistName }}</h5>
      <div class="list-overview-items mb-5">
        <PlaylistEntryItemRenderer
            v-for="(entry, index) in playlistListModel.entryQueue"
            :playlistEntry="entry"
            :key="index"
            v-on:onClick="_onPlaylistEntryClick"
            :inPlayerMode="true"
        />
      </div>
      <div class="playlist-entry-overview__buttons">
        <b-button :disabled="!enablePrevious" class="action-btn action-btn--rev" @click="_onPreviousBtnClick">
          <i class="fa-solid fa-chevron-left"></i>
          {{ $t('Prev') }}
        </b-button>
        <b-button :disabled="!enableNext" class="action-btn" @click="_onNextBtnClick">
          {{ $t('Next') }}
          <i class="fa-solid fa-chevron-right"></i>
        </b-button>
      </div>
    </div>
    <div class="playlist-entry-overview__toggle" @click="_onCollapseBtnClick">
      <svg width="25px" height="26px" version="1.1" xmlns="http://www.w3.org/2000/svg"
           xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/"
           style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
    <g transform="matrix(1,0,0,1,-18974.4,-17.0047)">
        <g id="Playlists" transform="matrix(1.14766,0,0,0.687171,17304.1,-127.62)">
            <path
                d="M1477.16,224.55L1477.16,244.269C1477.16,245.781 1476.43,247.008 1475.52,247.008L1457.02,247.008C1456.11,247.008 1455.38,245.781 1455.38,244.269L1455.38,224.55C1455.38,223.039 1456.11,221.811 1457.02,221.811L1475.52,221.811C1476.43,221.811 1477.16,223.039 1477.16,224.55ZM1469.98,233.428L1465.06,227.744C1464.83,227.477 1464.53,227.446 1464.28,227.664C1464.03,227.882 1463.87,228.313 1463.87,228.782L1463.87,240.037C1463.87,240.506 1464.03,240.937 1464.28,241.155C1464.53,241.373 1464.83,241.343 1465.06,241.076L1469.98,235.392C1470.18,235.169 1470.29,234.802 1470.29,234.41C1470.29,234.018 1470.18,233.651 1469.98,233.428ZM1471.75,210.465C1472.04,210.465 1472.31,210.654 1472.51,210.992C1472.72,211.33 1472.83,211.788 1472.83,212.265L1472.83,214.182L1459.71,214.182L1459.71,212.265C1459.71,211.788 1459.82,211.33 1460.03,210.992C1460.23,210.654 1460.5,210.465 1460.79,210.465L1471.75,210.465ZM1473.96,216.138C1474.24,216.138 1474.51,216.324 1474.7,216.655C1474.9,216.986 1475.02,217.435 1475.02,217.903L1475.02,219.856L1457.52,219.856L1457.52,217.903C1457.52,217.435 1457.63,216.986 1457.83,216.655C1458.03,216.324 1458.3,216.138 1458.58,216.138L1473.96,216.138Z"
                style="fill:white;"/>
        </g>
    </g>
</svg>
    </div>
  </div>
</template>


<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import PlaylistListModel from "@/playlist/_model/PlaylistListModel";
import PlaylistEntryItemRenderer from "@/playlist/_view/PlaylistEntryItemRenderer.vue";
import {IPlaylistEntryDto} from "@/playlist/_model/playlist.dto";
import languageManager from "@/__libs/language_manager/LanguageManager";
import AppUserModel from "@/project/user/_model/AppUserModel";


@Component({
    components: {
        PlaylistEntryItemRenderer
    },
})
export default class PlaylistPlayerEntries extends Vue {

    public playlistListModel: PlaylistListModel = PlaylistListModel.getInstance();

    get collapsed() {
        return this.playlistListModel.playlistPlayerCollapsed;
    }

    get enablePrevious() {
        if (this.playlistListModel.selectedQueueEntry && this.playlistListModel.entryQueue.indexOf(this.playlistListModel.selectedQueueEntry) > 0) {
            return true;
        }
        return false;
    }

    get enableNext() {
        if (this.playlistListModel.selectedQueueEntry && this.playlistListModel.entryQueue.indexOf(this.playlistListModel.selectedQueueEntry) < this.playlistListModel.entryQueue.length - 1) {
            return true;
        }
        return false;
    }

    get selectedPlaylistName() {
        if (this.playlistListModel.selectedPlaylist) {
            return languageManager.getTranslationForValue(this.playlistListModel.selectedPlaylist.body.name, AppUserModel.getInstance().langCode)
        }
        return "";
    }

    //---------------------------------
    // Public / lifecycle methods
    //---------------------------------

    public async mounted() {
        // this.playlistListModel.togglePlaylistPlayer(true); //collapse it on start
    }

    public async _onPreviousBtnClick(p_e: Event) {
        if (this.enablePrevious) {
            this.playlistListModel.selectedQueueEntry = this.playlistListModel.entryQueue[this.playlistListModel.entryQueue.indexOf(this.playlistListModel.selectedQueueEntry!) - 1]
        }
    }

    public async _onNextBtnClick(p_e: Event) {
        if (this.enableNext) {
            this.playlistListModel.selectedQueueEntry = this.playlistListModel.entryQueue[this.playlistListModel.entryQueue.indexOf(this.playlistListModel.selectedQueueEntry!) + 1]
        }
    }

    public async _onPlaylistEntryClick(p_playlistEntry: IPlaylistEntryDto) {
        this.playlistListModel.selectedQueueEntry = p_playlistEntry;
    }

    public _onCollapseBtnClick() {
        this.playlistListModel.togglePlaylistPlayer();
    }

}
</script>
