import SyncDataProcess from "@/sync/_controller/process/SyncDataProcess";
import syncController from "@/sync/_controller/SyncController";
import {SyncFileRetrieveStatus} from "@/sync/_model/sync.constants";
import {IAudienceBodyDto} from "@/audience/_model/audience.dto";
import {ISyncFileDto, ISyncItemFileDto} from "@/sync/_model/sync.dto";
import fileManager from "@/_controller/FileManager";
import {StorageScope} from "@/_model/app.constants";
import {ICompanyBodyDto} from "@/company/_model/company.dto";

class SyncCompanyProcess extends SyncDataProcess
{

    //---------------------------------
    // Properties
    //---------------------------------

    protected _storagePath:string = "companies";
    protected _storedBehindApi:boolean = true;

    public dependencyLevel:number = 0;



    //---------------------------------
    // Controller Methods
    //---------------------------------



    //---------------------------------
    // Private Methods
    //---------------------------------



    protected _parseRetrievedItemFile(p_itemFile:ISyncItemFileDto)
    {

        const body:ICompanyBodyDto = p_itemFile.body;

        if (body.companyLogo)
        {
            const filePath:string = fileManager.getAssetFileBasePath(body.companyLogo);

            if (filePath.length > 0)
            {
                const syncFile:ISyncFileDto = {
                    path          : filePath,
                    storageScope : this._storageScope,
                    retrieveStatus: SyncFileRetrieveStatus.IDLE,
                    nrOfAttempts  : 0
                };
                syncController.fileGroupFileProcess.addFileGroupFile(syncFile);
            }
        }



    }
}


export default SyncCompanyProcess;
