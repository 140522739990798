import {IAnalyticsValueDisplayType} from "@/analytics/_model/analytics.constants";
<template>

    <div>
        <b-table striped :items="subjects" :fields="fields" :head-variant="'dark'" :borderless="true">
            <!--            <template v-slot:cell(createdDate)="data">-->
            <!--                {{ $d(new Date(data.value), 'short') }}-->
            <!--            </template>-->
            <!--            <template v-slot:cell(percentage)="data">-->
            <!--                <span v-if="data.value === 0">-</span>-->
            <!--                <span v-if="data.value > 0" class="text-success">+{{ data.value }}%</span>-->
            <!--                <span v-if="data.value < 0" class="text-danger">{{ data.value }}%</span>-->
            <!--            </template>-->
        </b-table>

    </div>

</template>

<script lang="ts">
    import {Component, Prop, Vue} from 'vue-property-decorator';
    import {
        IAnalyticsActionCfgDto,
        IAnalyticsSubjectDto,
        IAnalyticsSubjectListWithActionsWidgetCfgDto,
        IAnalyticsSubjectListWithActionsWidgetDataDto, IAnalyticsSubjectsListDto
    } from "@/analytics/_model/analytics.dto";
    import languageManager, {IMultiLangString} from "@/__libs/language_manager/LanguageManager";
    import AppUserModel from "@/project/user/_model/AppUserModel";
    import {AnalyticsValueDisplayType} from "@/analytics/_model/analytics.constants";
    import AnalyticsModel from "@/analytics/_model/AnalyticsModel";


    @Component({
        components: {}
    })
    export default class AnalyticsContentSubjectList extends Vue
    {

        //---------------------------------
        // Vue Component props
        //---------------------------------

        @Prop() private subjectList!:IAnalyticsSubjectsListDto;


        //---------------------------------
        // Vue Component data
        //---------------------------------



        //---------------------------------
        // Vue Computed properties
        //---------------------------------


        get fields():any[]
        {
            const fields:any[] = [{key: "name", label: ""}];

            for (let i = 0; i < this.subjectList.cfg.actionColumns.length; i++)
            {
                const action:IAnalyticsActionCfgDto = this.subjectList.cfg.actionColumns[i];
                const field:any = {
                    key     : action.identifier, label: AnalyticsModel.resolveLabelTranslation(action.name as any),
                    sortable: true
                };

                fields.push(field);
            }

            return fields;
        }

        get subjects():any[]
        {
            for (let i = 0; i < (this.subjectList.data as IAnalyticsSubjectDto[]).length; i++)
            {
                const subject:IAnalyticsSubjectDto = (this.subjectList.data as IAnalyticsSubjectDto[])[i];
                subject.name = AnalyticsModel.resolveNameTranslation(subject.name);
                for (let j = 0; j < this.subjectList.cfg.actionColumns.length; j++)
                {
                    console.log(subject);
                    const action:IAnalyticsActionCfgDto = this.subjectList.cfg.actionColumns[j];

                    if (subject[action.identifier])
                    {
                        if (action.valueDisplayType === AnalyticsValueDisplayType.DATE)
                        {
                            subject[action.identifier] = this.$d(new Date(subject[action.identifier]), 'short')
                        }
                        else if (action.valueDisplayType === AnalyticsValueDisplayType.FLAG)
                        {
                            subject[action.identifier] = "v";
                        }
                        else if (action.valueDisplayType === AnalyticsValueDisplayType.EVOLUTION_PERCENTAGE)
                        {
                            let valueStr = "";
                            if (subject[action.identifier] > 0)
                            {
                                valueStr = "+";
                            }
                            else if (subject[action.identifier] < 0)
                            {
                                valueStr = "-";
                            }
                            subject[action.identifier] = valueStr + subject[action.identifier] + "%";
                        }
                    }
                    else
                    {
                        subject[action.identifier] = "-";
                    }
                }
            }
            return (this.subjectList.data as IAnalyticsSubjectDto[]);
        }

        //---------------------------------
        // Public / lifecycle methods
        //---------------------------------

        mounted()
        {
        }



        //---------------------------------
        // Private / helper methods
        //---------------------------------



    }
</script>

