import {IContainerData, ISlideBodyDto, ISlideMetaDto} from "@/slide/_model/slide.dto";
import EntityModel from "@/entity/_model/EntityModel";
import {IModelDto} from "@/__libs/_model/model.dto";
import {EntityType} from "@/entity/_model/entity.constants";
import {DtoType} from "@/_model/app.constants";
import languageManager, {
    IMultiLangString,
    IMultiLangTransValue,
    LanguageManager
} from "@/__libs/language_manager/LanguageManager";
import {IPresentationOutlineDto} from "@/presentation/_model/presentation.dto";
import {ITranslationMultiLangDto} from "@/_model/app.dto";
import {ContainerType} from "@/slide/_model/slide.constants";

export default class SlideModel extends EntityModel implements ISlideBodyDto, ISlideMetaDto
{
    public entityType:EntityType = EntityType.SLIDE;


    //body
    public name:IMultiLangString = {"en": ""};
    public notes?:IMultiLangString;
    public containers:IContainerData[] = [];
    public isPrivate:boolean = false;
    public originalTemplate?:string;
    public originalConstruct:string = "default";
    public backgroundContent?:IMultiLangString;
    public backgroundPosition?:string;

    //meta
    public usedInPresentations:IPresentationOutlineDto[] = [];

    //---------------------------------
    // Constructor
    //---------------------------------

    constructor(p_dto?:IModelDto)
    {
        super();
        if (p_dto)
        {
            this.mapFromDto(p_dto);
        }
    }

    //---------------------------------
    // Public Methods
    //---------------------------------

    public mapFromDto(p_dto:IModelDto)
    {
        super.mapFromDto(p_dto);



    }

    // until typescript has proper reflection, we will have to do it this way:
    public mapToDto(p_dtoType:DtoType):IModelDto
    {
        if (p_dtoType === DtoType.BODY)
        {

            const dto:ISlideBodyDto = {
                ID                : this.ID,
                version           : this.version,
                name              : this.name,
                notes             : this.notes,
                containers        : this.containers,
                isPrivate         : this.isPrivate,
                originalTemplate  : this.originalTemplate,
                originalConstruct : this.originalConstruct,
                backgroundContent : this.backgroundContent,
                backgroundPosition: this.backgroundPosition
            };
            if (this.identifier)
            {
                dto.identifier = this.identifier;
            }

            return dto;
        }
        else
        {
            return super.mapToDto(p_dtoType);
        }
    }

    public renameAsCopy()
    {
        LanguageManager.renameAsCopy(this.name);
    }


    public getMultiLangTranslations():ITranslationMultiLangDto[]
    {
        const trans:ITranslationMultiLangDto[] = [
            {identifier: "name", multiLangTrans: languageManager.convertMultiLangStringToTrans(this.name)},
            {identifier: "notes", multiLangTrans: languageManager.convertMultiLangStringToTrans(this.notes!)}
        ];
        for (let i = 0; i < this.containers.length; i++)
        {
            const container = this.containers[i];
            if (container.type === ContainerType.TEXT)
            {
                const containerTrans:ITranslationMultiLangDto = {
                    identifier    : `CONTAINER:${container.identifier}`,
                    multiLangTrans: languageManager.convertMultiLangStringToTrans(container.content)
                };
                trans.push(containerTrans);
            }
        }

        return trans;
    }

    public setMultiLangTranslations(p_translations:ITranslationMultiLangDto[]):boolean
    {
        let hasChanges:boolean = false;

        for (let i = 0; i < p_translations.length; i++)
        {
            const trans:ITranslationMultiLangDto = p_translations[i];
            for (let langCode in trans.multiLangTrans)
            {
                if (trans.multiLangTrans.hasOwnProperty(langCode))
                {
                    const transValue:IMultiLangTransValue = trans.multiLangTrans[langCode];
                    if (transValue.hasChanges)
                    {
                        hasChanges = true;
                        const target:string = trans.identifier.split(":")[0];
                        const ID:string = trans.identifier.split(":")[1];
                        if (target === "CONTAINER")
                        {
                            let container = this.containers.find(container => container.identifier === ID);
                            if(container)
                            {
                                // console.log(target, ID, container.content[langCode], ">", transValue.text);
                                container.content[langCode] = transValue.text;
                            }
                        }
                        else
                        {
                            // console.log(target, ID, (this as any)[trans.identifier][langCode], ">", transValue.text);
                            //it is "name" or "notes"
                            (this as any)[trans.identifier][langCode] = transValue.text;
                        }
                    }
                }
            }
        }

        return hasChanges;


    }

}
