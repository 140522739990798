<template>
  <div class="list-item cobrowse-user-item" :class="{self: isSelf}">

    <div class="list-item__icon">
      <AAProfilePicture :name="user.displayName" :imageUri="user.avatarUri"></AAProfilePicture>
    </div>

    <div class="list-item__info">
      <!--        <input v-if="isSelf" type="color" v-model="user.color" @change="_onFormChange" class="form-color-input">-->
      <!--        <span v-else class="userColor" :style="{ background: user.color}"></span>-->


      <div class="cobrowse-user-item__line-1">
        <div class="cobrowse-user-item__line-1__name" v-line-clamp="1">{{ user.displayName }}</div>
        <div>|</div>
        <div class="cobrowse-user-item__line-1__type">({{ user.type }})</div>
      </div>

<!--      <b-button-group class="mt-2 mr-2">-->
<!--        <b-button :pressed="isVideoEnabled" @click="_toggleVideoActive"><i-->
<!--                class="fa-solid fa-video"></i></b-button>-->
<!--        &lt;!&ndash;                <b-button :pressed="user.audioActive" @click="_toggleAudioActive"><i&ndash;&gt;-->
<!--        &lt;!&ndash;                        class="fa-solid fa-microphone"></i></b-button>&ndash;&gt;-->
<!--      </b-button-group>-->

      <b-button-group v-if="showInteractionBtns" class="mt-2">
        <b-button :pressed="user.interactionState === interactionState.NONE"
                  @click="_setState(interactionState.NONE)"><i class="fa-solid fa-ban"></i></b-button>
        <b-button :pressed="user.interactionState === interactionState.SHOW_MOUSE"
                  @click="_setState(interactionState.SHOW_MOUSE)"><i class="fa-solid fa-arrow-pointer"></i>
        </b-button>
        <b-button :pressed="user.interactionState === interactionState.INTERACTIVE"
                  @click="_setState(interactionState.INTERACTIVE)"><i class="fa-regular fa-object-group"></i>
        </b-button>
      </b-button-group>

<!--      <video  ref="vid" src="" id="video-container" width="150px" height="100px"></video>-->

    </div>

  </div>
</template>

<script lang="ts">
  import CobrowseManager from "@sales-drive/audience-library/lib/cobrowse_manager/CobrowseManager";
  import {
    CobrowseUserInteractionState,
    CobrowseUserType
  } from "@sales-drive/audience-library/lib/cobrowse_manager/cobrowse_user/_model/cobrowse_user.constants";
  import {
    ICobrowseRoomUserDto
  } from "@sales-drive/audience-library/lib/cobrowse_manager/cobrowse_user/_model/cobrowse_user.dto";
  import {IRoomUsersSoDataDto} from "@sales-drive/audience-library/lib/cobrowse_manager/room/_model/room.dto";
  import SharedObject from "@sales-drive/audience-library/lib/cobrowse_manager/shared_object/SharedObject";
  import {Component, Prop, Vue} from "vue-property-decorator";
  import AAProfilePicture from "@/_view/components/AAProfilePicture.vue";



  @Component({
    components: {AAProfilePicture}
  })
  export default class CobrowseUserBoxItemRenderer extends Vue
  {

    @Prop() public user!:ICobrowseRoomUserDto;
    @Prop() public roomUsersSO!:SharedObject<IRoomUsersSoDataDto>;

    public cobrowseManager:CobrowseManager = CobrowseManager.getInstance();

    public isVideoEnabled:boolean = false;

    private interactionState:typeof CobrowseUserInteractionState = CobrowseUserInteractionState;

    get isSelf():boolean
    {
      return CobrowseManager.getInstance().cobrowseUser.cobrowseUserID === this.user.cobrowseUserID;
    }

    get showInteractionBtns():boolean
    {
      return CobrowseManager.getInstance().cobrowseUser.type === CobrowseUserType.USER;
    }

    private _setState(state:CobrowseUserInteractionState):void
    {
      this.user.interactionState = state;
      this.roomUsersSO.emitChanges();
    }

    async mounted()
    {
      /*
      if (this.isSelf)
      {
        const myStream = await this.cobrowseManager.getStream();
        if (myStream)
        {
          this.addVideoStream(myStream); //add the video directly at the start, its the only way to catch the camera stream right away for the moment
        }
      }
      this.cobrowseManager.myPeer!.on('call', call => { // When we join someone's room we will receive a call from them
        if (call.peer === this.user.cobrowseUserID)
        {
          console.log(call.peer, "is calling");
          call.answer(this.cobrowseManager.myStream!); // Stream them our video/audio
          call.on('stream', hisVideoStream => { // When we recieve their stream
            this.addVideoStream(hisVideoStream);
          })
        }
      })
      */
    }

/*
    private async _toggleVideoActive()
    {
      if (!this.isVideoEnabled)
      {
        const myStream = await this.cobrowseManager.getStream();
        if (myStream)
        {
          if (this.isSelf)
          {
            //show your own video
            this.addVideoStream(myStream);
          }
          else
          {
            //call the other user
            console.log("calling", this.user.cobrowseUserID);
            const callToHim = this.cobrowseManager.myPeer!.call(this.user.cobrowseUserID, myStream);
            callToHim.on('stream', hisVideoStream => {
              console.log("callToHim stream ", hisVideoStream);
              this.addVideoStream(hisVideoStream);
            });
          }
        }
      }
      else
      {
        this.isVideoEnabled = false;

      }
      // this.user.videoActive = this.user.videoActive !== true;
      // this.roomUsersSO.emitChanges();

    }

    private _toggleAudioActive():void
    {
      this.user.audioActive = this.user.audioActive !== true;
      this.roomUsersSO.emitChanges();
    }

    public addVideoStream(stream:MediaStream)
    {
      this.isVideoEnabled = true;
      const video:HTMLVideoElement = (this.$refs.vid as HTMLVideoElement);
      video.srcObject = stream;
      video.addEventListener('loadedmetadata', () => { // Play the video as it loads
        video.play();
      });



    }
    */
  }

</script>
