import SyncDataProcess from "@/sync/_controller/process/SyncDataProcess";
import syncController from "@/sync/_controller/SyncController";
import SyncModuleProcess from "@/sync/_controller/process/SyncModuleProcess";
import {SyncFileRetrieveStatus, SyncProcessType} from "@/sync/_model/sync.constants";
import SyncSlideProcess from "@/sync/_controller/process/SyncSlideProcess";
import {IEntityBodyDto} from "@/entity/_model/entity.dto";
import {IPresentationBodyDto} from "@/presentation/_model/presentation.dto";
import {ISyncFileDto, ISyncItemFileDto} from "@/sync/_model/sync.dto";
import SyncConstructProcess from "@/sync/_controller/process/SyncConstructProcess";
import {IDataProviderBodyDto, IDataSchemaDto} from "@/data_tool/_model/data_tool.dto";
import DataSchemaUtil from "@/data_tool/_controller/DataSchemaUtil";
import fileManager from "@/_controller/FileManager";

class SyncDataProviderProcess extends SyncDataProcess
{

    //---------------------------------
    // Properties
    //---------------------------------

    protected _storagePath:string = "data-providers";
    protected _storedBehindApi:boolean = true;

    public dependencyLevel:number = 2;



    //---------------------------------
    // Controller Methods
    //---------------------------------



    //---------------------------------
    // Private Methods
    //---------------------------------



    protected _parseRetrievedItemFile(p_itemFile:ISyncItemFileDto)
    {
        const dtpBody:IDataProviderBodyDto = p_itemFile.body as IDataProviderBodyDto;

        const dtpStr:string = JSON.stringify(dtpBody);

        //get dependent dataproviders
        const dtpRegEx:RegExp = /dataProvider:\/\/(.*?)"/g;
        let match = dtpRegEx.exec(dtpStr);
        while (match != null)
        {
            // matched text: match[0]
            // match start: match.index
            // capturing group n: match[n]

            this.addSyncItem({identifier: match[1]});
            match = dtpRegEx.exec(dtpStr);
        }


        //get dependent asset files
        const asfRegEx:RegExp = /assetFolder:\/\/(.*?)"/g;
        match = asfRegEx.exec(dtpStr);
        while (match != null)
        {
            const filePath:string = "/assetFolders/" + match[1];
            const syncFile:ISyncFileDto = {
                path          : filePath,
                storageScope  : this._storageScope,
                retrieveStatus: SyncFileRetrieveStatus.IDLE,
                nrOfAttempts  : 0
            };
            syncController.fileGroupFileProcess.addFileGroupFile(syncFile);
            match = asfRegEx.exec(dtpStr);
        }
    }

}


export default SyncDataProviderProcess;
