<template>
  <div class="preview-visitor-page-box">

    <iframe ref="previewFrame"></iframe>

  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
export default class PreviewVisitorPageBox extends Vue {

    @Prop() public portalLink!: string;

    mounted() {
        const previewFrame: HTMLIFrameElement = this.$refs.previewFrame as HTMLIFrameElement;
        previewFrame.src = this.portalLink;
    }

}
</script>
