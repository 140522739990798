<template>
  <div class="coin-icon">
    <svg width="100%" height="100%" viewBox="0 0 51 60" version="1.1" xmlns="http://www.w3.org/2000/svg"
         xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/"
         style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
    <g transform="matrix(1,0,0,1,-663.064,-103.375)">
        <g transform="matrix(0.15013,0,0,0.15013,130.699,50.4099)">
            <g transform="matrix(0.993705,-0.343531,0.425352,1.23038,-15510.9,6453.49)">
                <clipPath id="_clip1">
                    <circle cx="19115.8" cy="540.837" r="155.943"/>
                </clipPath>
              <g clip-path="url(#_clip1)">
                    <g transform="matrix(5.98752,1.67176,-2.06993,4.83576,19026.2,353.874)">
                        <use xlink:href="#_Image2" x="0" y="0" width="51px" height="60px"/>
                    </g>
                </g>
            </g>
        </g>
      <g transform="matrix(0.15013,0,0,0.15013,130.699,50.4099)">
            <g transform="matrix(0.88361,-0.30547,0.389099,1.12552,-13377.5,5776.43)">
                <clipPath id="_clip3">
                    <circle cx="19115.8" cy="540.837" r="155.943"/>
                </clipPath>
              <g clip-path="url(#_clip3)">
                    <g transform="matrix(6.73355,1.82752,-2.32784,5.28631,19003.6,338.856)">
                        <use xlink:href="#_Image4" x="4.087" y="1.671" width="46px" height="55px"/>
                    </g>
                </g>
            </g>
        </g>
      <g transform="matrix(0.15013,0,0,0.15013,130.699,50.4099)">
            <g transform="matrix(0.770113,-0.266233,0.334195,0.9667,-11171.7,5111.25)">
                <clipPath id="_clip5">
                    <circle cx="19115.8" cy="540.837" r="155.943"/>
                </clipPath>
              <g clip-path="url(#_clip5)">
                    <g transform="matrix(7.72592,2.12775,-2.67091,6.15479,18979.1,304.548)">
                        <use xlink:href="#_Image6" x="8.014" y="5.346" width="40px" height="47px"/>
                    </g>
                </g>
            </g>
        </g>
      <g transform="matrix(0.15013,0,0,0.15013,130.699,50.4099)">
            <g transform="matrix(0.275403,-0.0860972,0.0397677,0.292696,3643.09,430.972)">
                <path
                    d="M150.426,744.499L29.425,744.499L50.467,683.141L75.467,668.295L56.759,724.911L155.884,724.911L150.426,744.499ZM434.842,634.703L461.675,725.749L537.093,725.749L329.801,41.919L349.426,41.919L562.468,744.499L447.634,744.499L414.635,634.086L434.842,634.703ZM257.55,106.174L391.967,559.128L200.467,648.461L205.551,667.953L415.468,569.261L258.968,42.357L211.675,186.099L221.926,220.282L257.55,106.174ZM239.593,278.311L228.3,305.928L293.676,514.632L115.426,620.869L120.634,641.582L317.676,524.165L239.593,278.311ZM34.51,440.278C70.76,298.42 132.884,227.549 132.884,227.549L222.259,475.061L12.134,632.478C12.134,632.478 2.968,563.807 34.51,440.278ZM57.134,431.791C32.134,519.291 32.009,593.449 32.009,593.449L198.175,468.874L127.051,270.961C127.051,270.961 82.134,344.286 57.134,431.791Z"
                    style="fill:white;fill-rule:nonzero;"/>
            </g>
        </g>
    </g>
      <defs>
        <image id="_Image2" width="51px" height="60px"
               xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADMAAAA8CAYAAADL94L/AAAACXBIWXMAAA7EAAAOxAGVKw4bAAAEsklEQVRogc2avYrWQBSGz7d89yBY2GxjYyGCNnaWdno3/lyGYGWhjTY2WoiNIAh6CXoDIgiCICi6cyySzJw5/5Mv7hpYNiaTyfue95nMZM0OBjf8eAcBEAAR2m8AgAKAAN050W7Zn9sDGOf5PgBggd2VxztP2z5n4G4zgMURuRjzzmvGvPNkP9hMM9UAIACezAe96hkphcbm9rT/lWa62PDTPYxjh8AM30+IJCjNO+Y1u6vPTNT2k4Glg5IU6d/wIJQwusbejqZqnEwoYUn+JNrDYPvuB/X2UADf3zId7cWAphynKwoDFU8m66Y0ZOYAlFJmBvETRdO3oxxKBiIaShAgJK7xzvNjU3t8d1N11JJJocQeoSOP6DUphQ8hYWaeQ9I3NMbS5iiNmzniEaawAKe9idKJcV5HKcIP394Qjvbd3DJcncTg32BNJsihOk0zq1FaOs6iNICfq4ljZqI0OtGNoBQgpfaLoj2+ud45kvPMqa/JBpIlOhCx3sY2c2YoZa6B2QTOTXs3R//XmsxGDxEBSwEsUyqI0/6f19eqIzZpAoyvyUYn0mxKQFBCtj/dD6tm1cwBKG21JsPljYSgVH/P+/Wx3YOmrM3OaE22oIQ4oVQaSogFoB5b2jT0fr28glMyoE2ah6JEUA2WLmmUusTaPtaU1UnTwmJ0Ih1FiR3jKM2J1GOkWMvhHX64jXmRh6UkxMxtm7AlKWLMSK9PbNpnyRgigwlsDUqamBAlnhg1C92kuQalpfoDKFnCqMBqnKOkJEoM+a/NK1LSBnEepT7JKL3OLETLmWGUxsT4KPXpCbxYen0y/xilSEwWJa9IA38D2A6lrt0ISkp69Nh+em8BiNdkHkqasJUosf45SqJ/ch15NG+EkiOmXteJ8VCyi6RNoMZrc4ASx+YUURJzFimAmDTFy88GKGkFWIuSLFLbr2ZyVfLEsNhpCsSQJ4anp832nq49Fmxi+I21FDIo0RunxOjjRkPJG297xCKrpIjZDKVVRWLYL8OCaa3JpFGyhP0jlKIi0f53AAC/X13FVAorUNKfihmUtCJpxDRD+z5ejet8lSKUzHGjIG6h5CE+mSnsBcpAIoVSV73tUfJ01f+5/fniMqZRoiJGUEoVyUdJJtOuq98BtEf0IsYTNooSE5ZEKVqF1D7nvpqZ+RGtD35dTIRSfkkUoxSNtw4zAIAfzy/hmaJEzabHW+u/+9xkeRCoYjIoKSIESqkiKShZ6ZG2vRkkjakIVcwoSrGwaLyJ/tl14juU708voja4dMb79EwkqIkkSvHKY+mz7YuvmrITaIRSj8FhKEVFau8z3AyfQEWVpJiuCEPCZJFclLriSV3q507fnhyjL8YT5qCkirELpqHk6VI/nsuupFWUuvG2PUp9kfqC6Waq8DxK9oCWN45QasUgiVFjII8BGJgBAHx9dAHXoiTFbIeSKBjZ7G80C+tUVEkRRo0NjTfDLE2UjTdts80gF3MgShaqokisWKyttR3f/7VzvxP+8vA85sX0KYygpOFrocQN0H+73zXXlXSAUihmAKUoBW6Abm4yAACfH5xD8bisYpIpUBMEoQ6llQboFn5xjqUQMaeHUtYA3f4C54Pfxax0sE8AAAAASUVORK5CYII="/>
        <image id="_Image4" width="46px" height="55px"
               xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC4AAAA3CAYAAABzYHhkAAAACXBIWXMAAA7EAAAOxAGVKw4bAAADUElEQVRogc2avYpVMRDH/zP3lG4jvoGFhY2NjdiIlWBnK4JY+Q6+wPoiFlZbWYkoWgiC4AOI+ggiCBbGwiRnkkw+zjn35CawnGw+Jv+Z+WXuvXuX0NC+n58ZEAAiEOyTCPYX3yfYMTcP2DkC2bWy723EdgFcefKDSpqmouDnZ0YerguxY7Bjbl510ImUgoUN6VClJSu+nV8yFB0eCokilxwuHXRZyDioBEJm4fKjr1kPJi82iIIWrRwOwoGFOMgzUgfLbToFDs5W6KDLQpiRXGMiAjEDzHB9YifG9plBsq+spdxaomifnC/sY8LPF9dMNuLEfFIc1EwDfl0+4oHXrs9RZOcoQIkWXF9mQUZZO4MI2ezZcWbGr5fX1ahPIBbRzEVhSXUI9yWMx7wX7bqsKsKZKVy0FAcVs2U4zGNRVZHIxahgNQ4SgW04zGtJXfv74kaCy0TFy9MRh1qmE+HMu+OQdzDEwdvwZ4R2A1R2w0GrKgUcgn3Ka8afVzcDXCYSVeX0OIisKpc4QmUcHGaROqoRKuPgAOUMiLV/X9/yuARVpQcOqd2opkf7fN+tm1HhOg4xizviQJH92W7IOZdxyGC0Iw7uXSJk3/8cYN7eMRYVEfFRcPD7M09XVY6Dg3L4ahxYiJRj9hkwnkShIVpJFnIO6hdf2kijmo+4eX/P/I94bxycg0UcynMh4yUcYqc24aCJjHEoOzMzPhgO9YjzPDgaDuWIE+ejuxsObeIqqHAqZAkO0sEj41BnfFAcwifbo1wd95WjBQfh4BYcjoGK+7A8Ig5VVJpw2HzYfJdacaigEv9BqAWHzhFXxsRHt/FwKM0RAJg3t83+OITv7lpw8E952S2607xgPByKqATCB8OhNEY2FzDv7pq2zf1wyM+R+NaNDsPhUEdF4jIQDiUbHhUAMB/uG5/qVTjk5tbhEDoQrgm/oKXa5gGy0Cy8k5BNqACA+fjArMdBO2QJDmsjDiCpLifEYaHwhlfR7k6lxSJBBQDMp4dzdZGpruJwyog7EZ0u2XJbVqIqHID5/Nj0ESIue4SDJti1wj/aHE6OQ6nlhXNBZCcc4kZXn/nJolvmy1OzPw5yrCxWtuL/ZGXfMXbAISe4UfjGmr4Qh5pY2f4BIPRMt8e7jGcAAAAASUVORK5CYII="/>
        <image id="_Image6" width="40px" height="47px"
               xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAvCAYAAACR+4jVAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAEYUlEQVRYhb1ZO24UQRB91QtkDjgAGxARIHEIEiRin8S+BycgIUJGHAMRkMAdCBGysUhdBNP17eqZ9dqmrbV7e3q6Xr3XXc+7Q1hpfz+/ZGoN8mqNlv6ugUj+EviGwcwAA8zSZ/ANrM++v1zTPgMnp9+pwvCoGvzz8QXTroEZIDDQX4xlDeL+ixkgAggg0AIAAAHLXGLrs/T7DFp6fWDaFODvD8+5daY6FQAxmAngvp5jAEwag0BgMKAcLIBCk2uskBdsRJ2EutGv9/suIzkpTVY/Tq2h7Yrxmcw3WVrX79dMZsbJ6Y9B5mZ7RpUEu5/YbDGbrzTCr06dHRDiBZ2QbignAU0DK6AE1m16D4q5SCAAsoAe7JjEco1qfGjI7AVArJvYADlQTh4aAMEBojQ+wAQAXH96NWzGpvsiBZdzqwcGVQLutiClAzCRkggpiXSfABRQEhOJPb+xA1CfgEuMHKDAkzCXAAWZB3hAQwLlcYAtuAKSsZAAep1zoUtAjqhq09Eo83KKw6wOpY/n/Vgz3deYymzvw293oGYyN4lalg8BJWQGV0k71hVmohyuM+mYCzNmpQj9kKyXD5PV2LMExEtlTLnQQ1BLObIKLTfXFyZzizVQMBmjOYEAyiWhKwRlDSxVMpPHTwGoAczs5fJxsKs4mauCvCblUIqsNfYLD4DWinhKbBZ001XGIu5lbgqJtwDxA7lKlBmpG6wuuIrbZOHcPoCraLdwlZbLRyejq13VQF8MPelxjQXP3Vzl+uIVt1n58K7CYYIDNHGVQeYjXQWUrG7mKto70FV8gIG/oVivu0qLBcTXuVQ+Mqi7uApwsKu0mSOsM/r/XKVFBEVJvo2riCXeo6s0rYFJ0qmrDF7txyN4A1TIfKCrqNUFQBKochXk+UVier8PjCCl9jZcpeXadStX4Qmjfew+XMU+kyRHOMhV3OCWq+Tzuu4qxqpaXVU+OhkrNbBILCTAQ9AEZVrEhdXGjpdZ+ShBZVcJ1Ptbt1ylLuLSmre0eQ1MrE4ZzUmxC1xJicTq6Cptf35FEmHuKmuMJgU0DVvD2Msyb7tKU0qQ2JswavHWt4WsIazShpSRUQO7/MM61EBhY3QVICdQJObmCqtHuYowuMgMVwOrwKl8lK7iEkhrGKDaVeovj0i+3ZKFsA6odJVqfjpoQQaUrrKMj6dbAdoXSJ6PYz48JTR3dBUFuD+/oi1XCZ8AcwI2eLyrFKXIJO7ZrrmKjsxsceIqQxIzV3HXZDwAZIOAWfmwJDyjHABF5DGJ27pKALg/u6TDXSUxCg/e6t9dXSVKLCE3XGUMnraF1tHE6hGuMgJ05WPN6uRtZJVV1Tg/slrKnJsv1L49O7uke3WVuGcGV/E0Vq5SPgoTQGBepEiBwQxiMlCw/8KNHQHUQ3mwBzw+e/L2GwGTZ3W5+JaAIIAY4L6fyvk+MXt8lgkEEx6/+TpoXTogAPx89zQ86dx6VFY+PtutPFbr7LXXX6YYAOAfYHU9XnBXhWgAAAAASUVORK5CYII="/>
      </defs>
    </svg>
  </div>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import AppUserModel from "@/project/user/_model/AppUserModel";
import {IGamificationProjectConfigDto} from "@/gamification/_model/gamification.dto";
import gamificationController from "@/gamification/_controller/GamificationController";
import {RIGHTS} from "@/team/_model/role.constants";
import {ITeamOutlineDto} from "@/team/_model/team.dto";
import GamificationSummary from "@/gamification/summary/_view/GamificationSummary.vue";

@Component({
  components: {},
})
export default class CoinIcon extends Vue {
}
</script>

