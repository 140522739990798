<script lang="ts">
    import {Component, Mixins, Vue} from 'vue-property-decorator';
    import AnalyticsModel from "@/analytics/_model/AnalyticsModel";
    import {Line} from "vue-chartjs";
    import {ANALYTICS_LINE_GRAPH_OPTIONS} from "@/analytics/_model/analytics.constants";
    import ChartJsPluginDataLabels from 'chartjs-plugin-datalabels';

    @Component({
        components: { ChartJsPluginDataLabels },
    })
    export default class AnalyticsPresentationEvolutionGraph extends Mixins(Line)
    {
        //---------------------------------
        // Vue Component props
        //---------------------------------

        //---------------------------------
        // Vue Component data
        //---------------------------------

        //---------------------------------
        // Vue Computed properties
        //---------------------------------

        get analytics() {
            return AnalyticsModel.getInstance();
        }

        //---------------------------------
        // Public / lifecycle methods
        //---------------------------------

        public mounted()
        {
            this.renderChart({
                labels: this.analytics.presentationEvolution.dates,
                datasets: [
                    {
                        label: this.$t('AnalyticsPresented') as string,
                        borderColor: '#2e2e2e',
                        data: this.analytics.presentationEvolution.presented
                    },
                    {
                        label: this.$t('AnalyticsShared') as string,
                        borderColor: '#c7a258',
                        data: this.analytics.presentationEvolution.shared
                    },
                    {
                        label: this.$t('AnalyticsViewed') as string,
                        borderColor: '#71601e',
                        data: this.analytics.presentationEvolution.viewed
                    }
                ]
            }, ANALYTICS_LINE_GRAPH_OPTIONS)
        }

        //---------------------------------
        // Private / helper methods
        //---------------------------------

    }
</script>
