import ContentListModel from "@/content/_model/ContentListModel";
import {ENTITY_CONFIG, EntityType, IEntityTypeConfig} from "@/entity/_model/entity.constants";
import PresentationModel from "@/presentation/_model/PresentationModel";
import contentController from "@/content/_controller/ContentController";
import ContentModel from "@/content/_model/ContentModel";
import EntityListController from "@/entity/_controller/EntityListController";
import {IEntityAudienceShareOutlineDto, IEntityMetaDto} from "@/entity/_model/entity.dto";
import EntityModel from "@/entity/_model/EntityModel";
import apiManager, {ApiRequestType, ApiResponse} from "@/_controller/ApiManager";
import AudienceModel from "@/audience/_model/AudienceModel";
import ContentAppModel from "@/content_app/_model/ContentAppModel";
import ContentFileModel from "@/content_file/_model/ContentFileModel";
import AppUserModel from "@/project/user/_model/AppUserModel";
import ContentFolderListModel from "@/content/_model/ContentFolderListModel";
import ContentFolderModel from "@/content/_model/ContentFolderModel";
import JsonUtil from "@/__libs/utility/JsonUtil";
import LocalStorageManager from "@/__libs/offline_storage/LocalStorageManager";
import {LocalStorageKey} from "@/_model/app.constants";


//contains all controller methods that can be applied on the list of contents
class ContentListController extends EntityListController<ContentModel>
{

    constructor()
    {
        super(ENTITY_CONFIG.CONTENT, ContentListModel.getInstance(), contentController);
        // liked content
        (this._entityListModel as ContentListModel).liked = JsonUtil.parse(LocalStorageManager.retrieveValue(LocalStorageKey.LIKED_CONTENT) as string) as string[] ?? [];
    }

    //---------------------------------
    // Properties
    //---------------------------------



    //---------------------------------
    // Controller Methods
    //---------------------------------

    public likeContent(p_content: ContentModel): void {
        if ((this._entityListModel as ContentListModel).liked.indexOf(p_content.ID) === -1) {
            (this._entityListModel as ContentListModel).liked.push(p_content.ID);
            LocalStorageManager.storeValue(LocalStorageKey.LIKED_CONTENT, JsonUtil.stringify((this._entityListModel as ContentListModel).liked));
        }
    }

    public unlikeContent(p_content: ContentModel): void {
        const index: number = (this._entityListModel as ContentListModel).liked.indexOf(p_content.ID);
        if (index !== -1) {
            (this._entityListModel as ContentListModel).liked.splice(index, 1);
            LocalStorageManager.storeValue(LocalStorageKey.LIKED_CONTENT, JsonUtil.stringify((this._entityListModel as ContentListModel).liked));
        }
    }

    public contentIsLiked(p_content: ContentModel): boolean {
        return (this._entityListModel as ContentListModel).liked.indexOf(p_content.ID) !== -1;
    }

    //

    public async fetchAllContentSharesForAudience(p_audience:AudienceModel):Promise<boolean>
    {
        const response:ApiResponse<IEntityAudienceShareOutlineDto[]> = await apiManager.sendApiRequest(ApiRequestType.GET, `/client-api/${this._entityTypeConfig.apiPath}/audience-share/?audience=${p_audience.ID}`);

        if (response.hasSucceeded)
        {
            p_audience.areSharesStale = false;
            p_audience.latestSharesRetrieval = new Date();
            p_audience.contentShares = response.result as IEntityAudienceShareOutlineDto[];
        }
        else
        {
            p_audience.areSharesStale = true;
        }
        return response.hasSucceeded;

    }

    public organizeEntities()
    {

        // assign all content to the right foldermodel
        for (let i = 0; i < this._entityListModel.list.length; i++)
        {
            const content:ContentModel = this._entityListModel.list[i];

            //check if folder is present, assign to folder,  if not, assign to root folder
            let folder:ContentFolderModel | null = null;
            if (content.folderID)
            {
                folder = this._contentFolderListModel.contentFoldersDict.getItem(content.folderID);
            }
            if (folder)
            {
                if (folder.childContents.indexOf(content) < 0)
                {
                    folder.childContents.push(content);
                }
            }
            else
            {
                content.folderID = 0;
                if (this._contentFolderListModel.rootContentFolder.childContents.indexOf(content) < 0)
                {
                    this._contentFolderListModel.rootContentFolder.childContents.push(content);
                }
            }
        }
        //at this point, if activeViewType is FOLDER, it will start filling up with the this._contentFolderListModel.rootContentFolder
    }

    //---------------------------------
    // Private Methods
    //---------------------------------

    protected __newEntity(p_dto:IEntityMetaDto):ContentModel
    {
        //switch between PresentationModel, ContentAppModel and ContentFileModel
        if (p_dto.entityType === EntityType.PRESENTATION)
        {
            return new PresentationModel(p_dto)
        }
        else if (p_dto.entityType === EntityType.CONTENT_APP)
        {
            return new ContentAppModel(p_dto)
        }
        else if (p_dto.entityType === EntityType.CONTENT_FILE)
        {
            return new ContentFileModel(p_dto)
        }
        else
        {
            return new ContentModel();
        }

    }

    protected _getEntityConfig(p_entity:EntityModel):IEntityTypeConfig
    {
        return ENTITY_CONFIG[p_entity.entityType];
    }



}

//Singleton export
export default new ContentListController();
